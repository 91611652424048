import { mapFlag } from '@/shared/flag';
import { createUrlArgsOnclickStr } from '../../../comm';

import { mapGetters, mapState } from 'vuex';
import { logDog } from '@/shared/log';
import { isRecommendProductListModule } from '@/modules/type-mapping';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    props: ['noLazyLoad'],
    data() {
        return {
            SYS_PRICE: 2, // 废弃的市场价
            SYS_MALL_PRICE: 11, // 商城价(实际交易价)
            SYS_MALL_MARKET_PRICE: 12, // 市场价
            SYS_SALEPROMOTIONPRICE: -1, // 促销价
            _originShowPropList: this.info.extInfo.showPropList,
        };
    },
    computed: {
        ...mapState(['product']),
        ...mapFlag({
            isNewAddModule: 0x4,
        }),
        ...mapGetters('app', ['hashRemoved']),
        colmumType() {
            return this.module.content.ct;
        },
        _topInfo() {
            let topName = 'productListTopIcon';
            let topSwitch = 'on';
            let choosePro = this.module.content.ma.mt;
            if (isRecommendProductListModule(this.module.id)) {
                choosePro = this.module.extInfo.productSelect ? 0 : 1;
            }
            const top = this.info.top;
            if (manageMode && choosePro == 1 && top != 0) {
                topName = 'top2';
                topSwitch = 'off';
            } else if (manageMode && choosePro == 0) {
                topName = 'top2';
                topSwitch = 'off';
            }
            return { topName, topSwitch };
        },
        _isBigCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 1;
            } else {
                return this.module.content.t == '33';
            }
        },
        _isTwoCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 2;
            } else {
                return this.module.content.t == '1';
            }
        },
        _isThreeCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 3;
            } else {
                return this.module.content.t == '31';
            }
        },
        _productSelect() {
            const choosePro = this.module.content.ma.mt;
            if (manageMode && choosePro == 1) {
                return false;
            }
            return true;
        },
        _showMallCart() {
            let mCart = false;
            let mallCart2 = false;

            if (this.module.content.ml && this.module.extInfo.mallOpen) {
                if (manageMode) {
                    mCart = true;
                    mallCart2 = !this._extInfo.hasMallPrice;
                } else {
                    if (!this.info.extInfo.MemberPrivileges) {
                        if (this.module.extInfo._sessionMemberId != 0) {
                            mCart = true;
                            mallCart2 = !this._extInfo.hasMallPrice;
                        }
                    } else {
                        mCart = true;
                        mallCart2 = !this._extInfo.hasMallPrice;
                    }
                }

                if (this.module.content.t == '33' && this.module.content.proStyle == 3) {
                    mCart = false;
                }
            }

            if (this._showBookCart) {
                mCart = true;
                mallCart2 = !this._extInfo.hasMallPrice;
            }

            if (this.module.content.t == '33' && this.module.content.proStyle == 3) {
                mCart = false;
                mallCart2 = false;
            }

            return { mCart, mallCart2 };
        },
        _showBookCart() {
            // bookCart 包括表单按钮 和 预约按钮两种，互斥的，看情况
            let show = false;
            if (!(this.module.content.t == '33' && this.module.content.proStyle == 3)) {
                if (this.module.content.bk && this.module.extInfo.bookingOpen) {
                    show = true;
                } else if (this.module.extInfo.productFormOpen && this.module.content.pf.s) {
                    // 开启表单按钮 且 显示表单按钮
                    show = true;
                }
            }
            return show;
        },
        _showAllLibPrice() {
            if (manageMode) {
                let productLid = this.info.lid; //产品库未上线前 lid 可能为null
                let hasMallPrice = false;
                this.module.content.dp.pp.forEach((item) => {
                    if (item.id == this.SYS_MALL_PRICE && item.lid == productLid) {
                        hasMallPrice = true;
                    }
                });
                let allProductShowPrize = false;
                if (this.module.content.ml && this.module.content.ma.mt == 1) {
                    // 购买按钮开启 且 使用筛选条件时，所有产品库的价格均需显示
                    allProductShowPrize = true;
                }
                return (
                    allProductShowPrize ||
                    (this.module.extInfo.mallOpen &&
                        ((this.module.content.ml &&
                            !(this.module.content.proStyle == 3 && this.module.content.t == 33)) ||
                            hasMallPrice) &&
                        this.module.content.ma.mt == 0)
                );
            }
            return false;
        },
        _showPropList() {
            let fix = {};
            let copyPropList = [];
            let productLid = this.info.lid; //产品库未上线前 lid 可能为null
            let showPropList = this.$data._originShowPropList;

            if (this.isLinkProduct) {
                return [];
            }

            const libPropListObj = manageMode ? this.product.libPropListObj : {};
            if (typeof productLid == 'undefined') {
                productLid = 1;
            }

            if (manageMode && Object.keys(libPropListObj).length > 0) {
                // 访客态参数 排序、显示 实时改变
                showPropList = [];

                const showAllLibPrice = this._showAllLibPrice;
                const propList = libPropListObj['libProp_' + productLid] || [];
                const This = this;
                this.module.content.dp.pp.forEach(function (item) {
                    if ((item.lid == productLid && item.show) || (item.id == This.SYS_MALL_PRICE && showAllLibPrice)) {
                        for (var i = 0; i < propList.length; i++) {
                            var propInfo = propList[i];
                            if (propInfo.id == item.id) {
                                showPropList.push(propInfo);
                            }
                        }
                        // 促销价
                        if (item.id == This.SYS_MALL_PRICE) {
                            This.$data._originShowPropList.forEach(function (item) {
                                if (item.id == This.SYS_SALEPROMOTIONPRICE) {
                                    showPropList.push(item);
                                }
                            });
                        }
                    }
                });
            }

            if (this.module.content.proStyle == 3 && this.module.content.t == 33) {
                // 极简模式只显示 价格\促销价 和 市场价
                showPropList.forEach((item) => {
                    if (item.id == this.SYS_SALEPROMOTIONPRICE) {
                        copyPropList.push(item);
                    } else if (
                        item.lid == productLid &&
                        (item.id == this.SYS_MALL_PRICE || item.id == this.SYS_MALL_MARKET_PRICE)
                    ) {
                        copyPropList.push(item);
                    }
                });
            } else {
                showPropList.forEach((item) => {
                    if (item.lid == productLid || item.id == this.SYS_SALEPROMOTIONPRICE) {
                        copyPropList.push(item);
                    }
                });
            }

            // 把特定参数固定在尾部(市场价，实际交易价格，促销价)
            if (this.isNewAddModule) {
                for (let i = copyPropList.length - 1; i >= 0; i--) {
                    let type = copyPropList[i].type;
                    if (type == this.SYS_MALL_PRICE) {
                        fix['mallPrize'] = copyPropList.splice(i, 1)[0];
                    } else if (type == this.SYS_PRICE) {
                        fix['price'] = copyPropList.splice(i, 1)[0];
                    } else if (type == this.SYS_MALL_MARKET_PRICE) {
                        fix['marketPrize'] = copyPropList.splice(i, 1)[0];
                    } else if (type == this.SYS_SALEPROMOTIONPRICE) {
                        fix['salePromotionPrize'] = copyPropList.splice(i, 1)[0];
                    }
                }
            } else {
                for (let i = copyPropList.length - 1; i >= 0; i--) {
                    let type = copyPropList[i].type;
                    if (type == this.SYS_MALL_PRICE) {
                        fix['mallPrize'] = copyPropList.splice(i, 1)[0];
                    } else if (type == this.SYS_MALL_MARKET_PRICE) {
                        fix['marketPrize'] = copyPropList.splice(i, 1)[0];
                    } else if (type == this.SYS_SALEPROMOTIONPRICE) {
                        fix['salePromotionPrize'] = copyPropList.splice(i, 1)[0];
                    }
                }
            }

            if (fix['mallPrize']) {
                copyPropList.push(fix['mallPrize']);
            }

            if (fix['marketPrize']) {
                copyPropList.push(fix['marketPrize']);
            }
            if (this.isNewAddModule) {
                if (fix['price']) {
                    copyPropList.push(fix['price']);
                }
            }

            if (fix['salePromotionPrize']) {
                copyPropList.push(fix['salePromotionPrize']);
            }

            return copyPropList;
        },
        _extInfo() {
            let info = {
                isShowMarketPrice: false,
                isShowPromotionPrice: false,
                isShowMallPrice: false,
                hasMallPrice: false,
            };

            const showAllLibPrice = this._showAllLibPrice;

            this._showPropList.forEach((item) => {
                if (item.type == this.SYS_MALL_MARKET_PRICE && item.allowed) {
                    info.isShowMarketPrice = true;
                } else if ((item.type == this.SYS_MALL_PRICE || showAllLibPrice) && item.allowed) {
                    info.isShowMallPrice = true;
                }

                if (item.type == this.SYS_MALL_PRICE || showAllLibPrice) {
                    info.hasMallPrice = true;
                }

                if (
                    item.allowed &&
                    (showAllLibPrice || item.type == this.SYS_MALL_PRICE || item.type == this.SYS_MALL_MARKET_PRICE)
                ) {
                    info.isShowPromotionPrice = true;
                }
            });

            return info;
        },
        _title2() {
            let title = '';
            const choosePro = this.module.content.ma.mt;
            const top = this.info.top;
            if (manageMode && choosePro == 1 && top != 0) {
                title = '该产品已置顶';
            }
            return title;
        },
        _showMallCartHolder() {
            return this._showMallCart.mallCart2 && ['8', '9', '32'].includes(this.module.content.t);
        },
        _imgBgStyle() {
            var background = {};
            if (!this.isNewAddModule) {
                background.backgroundRepeat = 'no-repeat';
                background.backgroundSize = 'cover';
                background.backgroundPosition = 'center';
            }
            //if (!this.module.extInfo.imgLazyLoad) {
            background.backgroundImage = `url(${this.noLazyLoad ? this._imgSrc : this.options.loadingPath})`;
            //}
            return background;
        },
        _isTransparentStyle() {
            return (
                this.isNewAddModule &&
                (this.module.content.t != 3 || this.module.content.t != 32) &&
                (this.module.content.proStyle == 11 ||
                    this.module.content.proStyle == 3 ||
                    this.module.content.proStyle == 7)
            );
        },
        _imgBorderRadiusClass() {
            return this._isTransparentStyle ? 'imgBorderRadius' : '';
        },
        _wrapBorderRadiusClass() {
            return !this._isTransparentStyle ? 'imgBorderRadius' : '';
        },
        _warpProRadiusStyle() {
            return this._isTransparentStyle ? {} : this._proRadiusStyle;
        },
        _imgBgStyleStr() {
            var background = `background-repeat:no-repeat;background-size:cover;background-position:center;`;
            background += `background-image:url(${this.noLazyLoad ? this._imgSrc : this.options.loadingPath});`;
            return background;
        },
        _originImgSrc() {
            return this.info.extInfo.imgPath;
        },
        _imgSrc() {
            // data-origin，管理态只懒加载一次
            return this.info.extInfo.imgPath;
        },
        _imgSrcManage() {
            // 为了访客态改动时不触发懒加载效果，直接赋予 src
            return this.info.extInfo.imgPath;
        },
        proStyle() {
            return this.module.content.proStyle;
        },
        imgScale() {
            return this.module.content.is;
        },
        showProName() {
            const type = this.module.content.t;
            return (
                this.module.content.showProName &&
                (this._isTwoCol || this._isThreeCol || type == '7' || type == '8' || type == '9')
            );
        },
        _showParamCotent() {
            return this.showProName && this.module.content.dp.pp.length != 0;
        },
        _contentStyle() {
            var contentgStyle = '';
            if (this.proStyle == 8) {
                contentgStyle = ' shadowStyle';
            } else if (this.proStyle == 9) {
                contentgStyle = ' borderStyle';
            } else if (this.proStyle == 10) {
                contentgStyle = 'backgroundStyle';
            } else if (this.proStyle == 11) {
                contentgStyle = 'transparentStyle';
            }
            return contentgStyle;
        },
        _imgScaleClass() {
            var imgScaleClass = '';
            if (this.imgScale == 0) {
                imgScaleClass = ' coverSize';
            } else if (this.imgScale == 1) {
                imgScaleClass = ' containSize';
            } else if (this.imgScale == 2) {
                imgScaleClass = ' stretchSize';
            } else if (this.imgScale == 3) {
                imgScaleClass = ' unsetSize';
            }
            return imgScaleClass;
        },
        _proRadiusStyle() {
            var borderStyle = {};
            if (!manageMode) {
                return;
            }
            var proRadius = this.module.content.proRadius;
            var proRadiusType = this.module.content.proRadius.proRadiusType;
            var needBorderFixed =
                (this.module.content.t != 3 || this.module.content.t != 32) &&
                this.module.content.proStyle != 11 &&
                this.module.content.proStyle != 3 &&
                this.module.content.proStyle != 7;
            if (proRadiusType == 1) {
                borderStyle.borderRadius = proRadius.proRadius + 'px';
            } else {
                if (needBorderFixed) {
                    borderStyle.borderTopLeftRadius = '0.2rem';
                    borderStyle.borderTopRightRadius = '0.2rem';
                }
            }
            return borderStyle;
        },
        _fixProRadiusStyle() {
            var borderStyle = {};
            if (!manageMode) {
                return;
            }
            var proRadiusType = this.module.content.proRadius.proRadiusType;
            var needBorderFixed =
                (this.module.content.t != 3 || this.module.content.t != 32) &&
                this.module.content.proStyle != 11 &&
                this.module.content.proStyle != 3 &&
                this.module.content.proStyle != 7;
            if (proRadiusType == 0) {
                if (needBorderFixed) {
                    borderStyle.borderTopLeftRadius = '0.2rem';
                    borderStyle.borderTopRightRadius = '0.2rem';
                }
            }
            return borderStyle;
        },
        _wrapRadiusStyle() {
            if (!manageMode) {
                return;
            }
            var proRadius = this.module.content.proRadius;
            var proRadiusType = this.module.content.proRadius.proRadiusType;
            if (proRadiusType == 1) {
                if (this._showParamCotent) {
                    return {
                        borderTopLeftRadius: proRadius.proRadius + 'px',
                        borderTopRightRadius: proRadius.proRadius + 'px',
                    };
                } else {
                    return {
                        borderRadius: proRadius.proRadius + 'px',
                    };
                }
            }
        },
        _wrapBorderRadius() {
            return !this._showParamCotent ? 'imgBorderRadius' : '';
        },
        _proNameWrapClass() {
            if (this.module.content.proNameWrap == 0) {
                return 'defaultProName';
            } else if (this.module.content.proNameWrap == 1) {
                return 'wrapProName';
            } else if (this.module.content.proNameWrap == 2) {
                return 'noWrapProName';
            }
        },
        _resizeOptions() {
            var handles = 's';
            var moduleStyle = this.module.content.t;
            var notAllow = false;
            var alsoResizeParentSelector = null;
            var alsoResizeChildSelector = null;
            var maxHeight = 1000;
            var minHeight = 50;
            var maxWidth = null;
            var minWidth = null;

            if (!this.isNewAddModule && moduleStyle == 1 && this.module.content.proStyle == 4) {
                notAllow = true;
            }

            if (moduleStyle == 3) {
                handles = 's,e';
                alsoResizeParentSelector = '.J_img_wrap';
                maxHeight = 200;
                maxWidth = 200;
                minWidth = 2;
            }

            return {
                notAllow: notAllow,
                handles: handles,
                alsoResizeParentSelector: alsoResizeParentSelector,
                alsoResizeChildSelector: alsoResizeChildSelector,
                fixPosition: true,
                outlineInBody: true,
                beforeStart: this._resizeBeforeStart,
                resize: this._resizeing,
                stop: this._resizeStop,
                maxHeight: maxHeight,
                maxWidth: maxWidth,
                minHeight: minHeight,
                minWidth: minWidth,
            };
        },
        _contentBgStyle() {
            if (
                !manageMode ||
                this.module.content.proStyle == 3 ||
                this.module.content.proStyle == 11 ||
                this.module.content.proStyle == 7
            ) {
                return;
            }
            var contentBgType = this.module.content.contentBg.contentBgType;
            var contentBgColor = this.module.content.contentBg.contentBgColor;
            if (contentBgType == 1) {
                return {
                    background: contentBgColor,
                };
            }
        },
        _openKnowPayment() {
            return this.module.extInfo.openKnowPayment;
        },
        _isKnowPayProduct() {
            return this.info.isKnowPayProduct;
        },
        _isShowQualityContentLabel() {
            return this._openKnowPayment && this._isKnowPayProduct && this.module.content.cts;
        },
        _knowPayLabelText() {
            return this.info.knowPayLabelText || '';
        },
        onclickStr() {
            const { pdReqArgs } = this.info.extInfo;
            return (
                "sessionStorage.setItem('clickPdFrom', '产品展示模块');" +
                createUrlArgsOnclickStr(pdReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved)
            );
        },
        isLinkProduct() {
            return this.info.productType == 4;
        },
    },
    methods: {
        _resizeBeforeStart(event) {
            const moduleStyle = this.module.content.t;
            if (moduleStyle == 1 || moduleStyle == 33) {
                const $target = jm(event.target);
                $target.css({
                    paddingTop: '0px',
                    height: $target.offset().height + 'px',
                });
            } else if (moduleStyle == 9) {
                this.__maxHeight = jm(`#module${this.module.id} .productSlide`).height();
            }
        },
        _resizeing(event, ui) {
            const moduleStyle = this.module.content.t;
            let { height } = { ...ui.size };
            if (moduleStyle == 9) {
                if (height > ui.originalSize.height) {
                    jm(`#module${this.module.id} .productSlide`).height(
                        this.__maxHeight + height - ui.originalSize.height
                    );
                }
            }
        },
        _resizeStop(event, ui) {
            const moduleStyle = this.module.content.t;
            let { width, height } = { ...ui.size };
            let transWidth = parseInt(width);
            let transHeight = parseInt(height);

            if (transWidth % 2 != 0) {
                transWidth -= 1;
            }
            if (transHeight % 2 != 0) {
                transHeight -= 1;
            }

            const gcd = this.__getGreatestCommonDivisor(transWidth, transHeight);
            let rateWidth = transWidth / gcd;
            let rateHeight = transHeight / gcd;

            if (moduleStyle == 3) {
                this.module.content.proPhotoSize.pw = transWidth;
                this.module.content.proPhotoSize.ph = transHeight;
            } else if (moduleStyle == 7 || moduleStyle == 9 || moduleStyle == 1 || moduleStyle == 33) {
                this.module.content.proSize.proWidth = rateWidth;
                this.module.content.proSize.proHeight = rateHeight;
            } else if (moduleStyle == 8) {
                if (this.index == 0 || this.index == this.$parent.productList.length - 1) {
                    // 首尾图
                    this.module.content.endsSize.endsWidth = rateWidth;
                    this.module.content.endsSize.endsHeight = rateHeight;
                } else {
                    this.module.content.commonSize.commonWidth = rateWidth;
                    this.module.content.commonSize.commonHeight = rateHeight;
                }
            }

            // 自定义设置
            if (moduleStyle == 3) {
                this.module.content.proPhotoSize.pt = 1;
            } else if (moduleStyle == 7 || moduleStyle == 9 || moduleStyle == 1 || moduleStyle == 33) {
                this.module.content.proSize.proSizeType = 2;
            } else if (moduleStyle == 8) {
                if (this.index == 0 || this.index == this.$parent.productList.length - 1) {
                    this.module.content.endsSize.endsSizeType = 2;
                } else {
                    this.module.content.commonSize.commonSizeType = 2;
                }
            }

            this.$designer.onContentChange(this.module.id);
            logDog(201218, 1);
        },
        __getGreatestCommonDivisor(a, b) {
            // 取最大公约数
            var result = 1;
            for (var i = 1; i <= a && i <= b; i++) {
                if (a % i == 0 && b % i == 0) {
                    result = i;
                }
            }
            return result;
        },
        _proMarginStyle(index) {
            var proMargin = this.module.content.proMargin;
            var proMarginType = this.module.content.proMargin.proMarginType;
            var colmumType = this.colmumType;
            var noNeedTopFlag =
                (colmumType == 1 && index == 0) ||
                (colmumType == 2 && (index == 0 || index == 1)) ||
                (colmumType == 3 && (index == 0 || index == 1 || index == 2))
                    ? true
                    : false;
            if (proMarginType == 1) {
                if (this.module.content.t == '1') {
                    if (noNeedTopFlag) {
                        return {
                            marginLeft: proMargin.proMarginX + 'px',
                            marginRight: proMargin.proMarginX + 'px',
                        };
                    } else {
                        return {
                            marginTop: proMargin.proMarginY + 'px',
                            marginLeft: proMargin.proMarginX + 'px',
                            marginRight: proMargin.proMarginX + 'px',
                        };
                    }
                } else {
                    return {
                        marginLeft: proMargin.proMarginX + 'px',
                        marginRight: proMargin.proMarginX + 'px',
                    };
                }
            }
        },
    },
};
