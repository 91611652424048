import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ProductGroupView from './module/product-group.vue';

class _ProductGroup extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PRODUCT_GROUP, options);
    }

    render() {
        return ProductGroupView;
    }
}

export const ProductGroup = new _ProductGroup();

export const editGrop = ({ beforePopupCheck, callback }) => {
    Fai.top.bizManageShared.managePages.productGroupEdit.openPopup({
        beforePopupCheck,
        onSuccess: (args) => {
            callback && callback(args);
        },
    });
};
