<template>
    <div class="u_custom_search_entry--multiLine_wrap">
        <textArea
            v-model="formItem.value"
            :maxlength="maxlength"
            class="u_custom_search_entry--textArea u_custom_search_entry--text"
            @input="inputItemText"
        ></textArea>
    </div>
</template>

<script>
export default {
    name: 'CustomSearchMultiLine',
    props: {
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        maxlength: {
            type: Number,
            default: 50,
        },
    },
    data() {
        return {
            inputText: '',
        };
    },
    methods: {
        inputItemText(e) {
            var value = e.target.value;
            this.inputText = value;
            this.formItem.value = value;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.inputText);
        },
    },
};
</script>

<style lang="scss" scoped>
.u_custom_search_entry--multiLine_wrap .u_custom_search_entry--textArea {
    display: block;
    background-color: #fff;
    padding: 0.413rem 0.6rem;
    margin: 0;
    margin-top: 0.7rem;
    height: 3.25rem;
    outline: 0;
    resize: none;
    line-height: normal;
    font-family: inherit;
}
</style>
