<template>
    <div class="m_mobi_form_validate_code">
        <input
            type="text"
            class="m_mobi_form_input_text"
            :value="currentValue"
            maxlength="4"
            :style="_mixinsInputStyle"
            :placeholder="module.extInfo.placeEnterValidateCode"
            @input="handleInput"
        />
        <img class="validateCode_img" alt=" " :src="validateCodeSrc" @click="refreshValidateCode" />
        <i class="form_validate_refresh" @click="refreshValidateCode"></i>
    </div>
</template>
<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'FormValidateCode',
    mixins: [Mixins],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        formInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            random: Math.random() * 1000,
        };
    },
    computed: {
        validateCodeSrc() {
            return `/validateCode.jsp?${this.random}&vCodeId=${this.module.id}${this.formInfo.id}`;
        },
    },
    methods: {
        resetValue() {
            this.currentValue = '';
        },
        handleInput(event) {
            const value = event.target.value;
            this.currentValue = value;
        },
        refreshValidateCode() {
            this.random = Math.random() * 1000;
        },
        validate() {
            if (this.currentValue === '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
    },
};
</script>

<style>
.m_mobi_form_validate_code {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m_mobi_form_validate_code .m_mobi_form_input_text {
    width: 9.125rem;
}

.m_mobi_form_validate_code .validateCode_img {
    width: 3.475rem;
    height: 1.55rem;
}
</style>
