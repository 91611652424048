<template>
    <div v-if="isPicStyle" :class="fixedPicConetent" class="searchResultLine pic">
        <a :href="hrefStr" :onclick="reqArg" hidefocus="true" :class="picSizeClass" class="pic">
            <span v-if="showKnowPdLabel" class="quality_content_label">{{ knowPdLabelText }}</span>
            <img
                class="J_img_lazyload"
                width="100%"
                height="100%"
                alt=""
                :src-original="renderImgOpts.srcOriginal"
                :src="renderImgOpts.loadingPath"
                :data-picId="renderImgOpts.dataPicId"
                @click="photoSlidesshow(item.index)"
            />
            <!-- 产品获取到名称和价钱 -->
            <div v-if="searchFlag == 1" class="resultDetail">
                <span class="detailSpan proName" hidefocus="true" v-html="getHighLightWord"></span>
                <span v-if="showPrice && !isLinkProduct" :class="priceClass" class="detailSpan proPrice"
                    >{{ g_price }}：<span v-if="siteCurrencyRight" class="g_mainColor g_main_color_v3"
                        >{{ printNewStyleOption.mallPrice }}{{ printNewStyleOption.choiceCurrencyVal }}</span
                    ><span v-else class="g_mainColor g_main_color_v3"
                        >{{ printNewStyleOption.choiceCurrencyVal }}{{ printNewStyleOption.mallPrice }}</span
                    >
                </span>
            </div>
            <!-- 图片获取到名称 -->
            <div v-else-if="searchFlag == 3">
                <div class="resultDetail">
                    <span class="detailSpan picName" hidefocus="true" v-html="getHighLightWord"></span>
                </div>
            </div>
            <!-- 图册获取到名称和总照片数 -->
            <div v-else-if="searchFlag == 5">
                <div class="resultDetail picDetailAb">
                    <span class="detailSpan detailSpanInline phgName" hidefocus="true" v-html="getHighLightWord"></span>
                    <span class="detailSpan detailSpanInline phgSize" hidefocus="true"
                        >{{ item.picGrounpSize }}&nbsp;张</span
                    >
                </div>
            </div>
        </a>
    </div>
    <div v-else class="searchResultLine text">
        <div class="textItemContent" :class="textType">
            <a :href="hrefStr" hidefocus="true" :onclick="reqArg" class="word">
                <span class="textSpan" v-html="getHighLightWord"></span>
            </a>
        </div>
    </div>
</template>

<script>
import { highLightKeyword } from '../../util';

import { mapState } from 'vuex';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'NewTextItem',
    inject: ['isSpider', 'keyword', 'moduleId'],
    props: ['item'],
    data() {
        let { groupName = '', groupNameStr = '' } = this.item;

        return {
            groupName,
            groupNameStr,
            manageMode,
        };
    },
    computed: {
        ...mapState('app', ['LS']),
        g_price() {
            return this.LS.g_price;
        },
        contentText() {
            let name = this.item.title;
            if (this.searchFlag == 6) {
                let { info } = this.item;
                let { file } = info;
                name = file.name;
            }
            return name;
        },
        href() {
            return this.item.href;
        },
        isLinkProduct() {
            return this.item.productType == 4;
        },
        photo() {
            return this.item.photo;
        },
        searchFlag() {
            return this.item.searchFlag;
        },
        textType() {
            return this.searchFlag == 6 ? 'icon-download' : 'icon-gline';
        },
        isPicStyle() {
            return [1, 3, 5].includes(this.searchFlag); //产品，图册，图片的图文样式
        },
        printNewStyleOption() {
            return this.item.printNewStyleOption || {};
        },
        templateId() {
            return this.printNewStyleOption.templateId;
        },
        knowPdLabelText() {
            return this.printNewStyleOption.knowPdLabelText;
        },
        showKnowPdLabel() {
            return this.knowPdLabelText != '' && this.searchFlag == 1;
        },
        fixedPicConetent() {
            return [1030, 1020, 1022].includes(this.templateId) ? 'fixedPicConetent' : '';
        },
        picSizeClass() {
            return [3, 5].includes(this.searchFlag) ? 'smallSizePic' : 'bigSizePic';
        },
        isLowLevel() {
            return this.searchFlag == 6 && this.href.startsWith('lowLevel');
        },
        hrefStr() {
            return this.isLowLevel
                ? this.isSpider
                    ? null
                    : 'javascript:void(0)'
                : this.hrefUrl == ''
                ? null
                : this.hrefUrl;
        },
        renderImgOpts() {
            let isSearchFlag1Or5 = [1, 5].includes(this.searchFlag),
                { coverPicPathTra, loadingPath } = this.printNewStyleOption,
                srcOriginal = isSearchFlag1Or5 ? coverPicPathTra : this.photo.picPath;
            return {
                loadingPath,
                srcOriginal,
                dataPicId: !isSearchFlag1Or5,
            };
        },
        getHighLightWord() {
            return highLightKeyword(this.contentText, this.keyword);
        },
        showPrice() {
            return this.showPriceForManage || this.showPriceForGuest;
        },
        showPriceForManage() {
            return manageMode && !this.printNewStyleOption.mallPriceHidden;
        },
        showPriceForGuest() {
            return !manageMode && !this.printNewStyleOption.mallPriceHidden && this.printNewStyleOption.gradeMach;
        },
        priceClass() {
            return this.showPriceForGuest ? 'mallPrice' : '';
        },
        hrefUrl() {
            return this.item.hrefUrl;
        },
        reqArg() {
            return this.item.reqArg === '' ? null : this.item.reqArg;
        },
        siteCurrencyRight() {
            return this.printNewStyleOption.siteCurrencyRight && this.printNewStyleOption.choiceCurrencyVal != '￥';
        },
    },
    methods: {
        photoSlidesshow(index) {
            if (!this.isSpider) {
                return;
            }
            this.$modulesEventBus.$emit(`searchResult-slidesshow${this.moduleId}`, index);
        },
    },
};
</script>

<style lang="scss">
.formStyle24 .quality_content_label {
    top: 6rem;
    bottom: auto;
}
</style>
