import linkSetting from '@/features/global-mobi-function/methods/linkSetting';
import { logDog } from '@/shared/log';
import { mobiShowScroll, isPC, mobiHideScroll } from '@/shared/util';
import { linkToForm } from '@/modules/mobiForm/util';
import { createPopupWindow } from '@/shared/popupWindow/popupWindowVersionTwo';
const { openQQ, showWeChatService, showBaiduBridge } = linkSetting;

import { mobiAlert } from '@/shared/tips';
function setServiceTool() {
    createPopupWindow({
        title: '设置客服',
        frameSrcUrl: 'mobiServiceOnline.jsp',
        width: 750,
        height: 600,
        version: 2,
        closeFunc: closeServiceOnline,
    });
    Mobi.logDog(200166, 2);
}

function closeServiceOnline() {
    jm('.serviceFullBg').remove();
    jm('.serviceSettingEntrance').remove();
    jm('.serviceListPanel').remove();
    jm(window).off('scroll');
    // 重新更新客服DOM
    bindServiceBtnEvent();
}
export function bindServiceBtnEvent() {
    var serviceBtn = jm('.serviceBtn');

    if (serviceBtn.length < 1) {
        return;
    }
    serviceBtn.off('click.ser').on('click.ser', function () {
        jm('body').attr('ontouchmove', 'return false;');
        createMallServicePanel();
    });

    serviceBtn.off('mousemove.ser').on('mousemove.ser', function () {
        var serviceJson = [
            {
                text: '编辑客服',
                display: 'text',
                className: 'edit',
                onClick: () => {
                    setServiceTool();
                },
            },
        ];

        if (VITE_APP_MODE !== 'visitor') {
            Vue.prototype.$layer.addModuleLayer(jm(serviceBtn).attr('id'), serviceJson, 4, true);
        }
    });

    serviceBtn.off('mouseleave.ser').on('mouseleave.ser', function () {
        if (VITE_APP_MODE !== 'visitor') {
            setTimeout(() => {
                Vue.prototype.$layer.removeModuleLayer(jm(this).attr('id'));
            }, 500);
        }
    });
}

export function createMallServicePanel() {
    if (Fai.top._mallService == 2 || Fai.top._openThemeV3) {
        mallServiceList();
    } else {
        notMallServiceList();
    }
}

function mallServiceList() {
    var serviceList = Fai.top._serviceList;
    var showServiceList = [];
    var justOneService;

    serviceList.forEach(function (value) {
        if (value.showType == true) {
            showServiceList.push(value);
        }
    });

    if (showServiceList.length == 1) {
        justOneService = showServiceList[0];
        if (justOneService.serviceType == 0) {
            openQQ(justOneService.serviceAccount);
        } else if (justOneService.serviceType == 1) {
            showWeChatService(justOneService.code);
        } else if (justOneService.serviceType == 2) {
            showBaiduBridge();
        } else {
            if (isPC()) {
                mobiAlert('该功能无法在桌面浏览器中启动！');
                return;
            }
            window.location.href = 'tel:' + justOneService.serviceAccount;
        }
        return;
    }
    mobiHideScroll();
    var creatHtml = "<div class='serviceFullBg'></div>",
        i,
        l,
        item,
        showType,
        iconClassName = '',
        iconColor = '',
        bgImg = '',
        colIconType, //图片类型， 1，icon，  2 背景图片
        iconFilePath; //背景图片路径;

    creatHtml += "<div id='J_serviceListPanel' class='serviceListPanel'>";

    for (i = 0, l = showServiceList.length; i < l; i++) {
        item = showServiceList[i];
        showType = item.showType;
        iconClassName = item.classname;
        colIconType = item.isFontIcon;
        if (colIconType == 'true') {
            //图标
            iconClassName = item.classname;
            bgImg = '';
        } else {
            //图片
            iconFilePath = item.filePath;
            iconClassName = '';
            bgImg = 'background-image:url(' + iconFilePath + ');';
        }
        iconColor = item.color ? 'color:' + item.color + ';' : '';
        if (showType) {
            if (item.serviceType == 0) {
                creatHtml += "<div class='serviceItem qqServiceLine'>";
                creatHtml +=
                    "<a id='qqServiceItem" + i + "' href='javascript: Mobi.openQQ(\"" + item.serviceAccount + '");\'>';
                creatHtml +=
                    "<div class='serviceIcon'><span style='" +
                    bgImg +
                    iconColor +
                    ";' class=' serviceIconText " +
                    iconClassName +
                    "'></span></div>";
                creatHtml +=
                    "<div class='serviceName'><span class='serviceNameText'>" + item.showFont + '</span></div>';
                creatHtml +=
                    "<div class='serviceNumber'><span class='serviceNumberText'>" +
                    item.serviceAccount +
                    '</span></div>';
                creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
                creatHtml += '</a>';
                creatHtml += '</div>';
            } else if (item.serviceType == 1) {
                creatHtml += "<div class='serviceItem weChatServiceLine'>";
                creatHtml +=
                    "<a id='weChatServiceItem" +
                    i +
                    "' href='javascript:;' onclick='Mobi.showWeChatService(\"" +
                    item.code +
                    '")\'>';
                creatHtml +=
                    "<div class='serviceIcon'><span style='" +
                    bgImg +
                    iconColor +
                    "' class=' serviceIconText " +
                    iconClassName +
                    "'></span></div>";
                creatHtml +=
                    "<div class='serviceName'><span class='serviceNameText'>" + item.showFont + '</span></div>';
                creatHtml +=
                    "<div class='serviceNumber'><span class='serviceNumberText'>" + LS.js_consult + '</span></div>';
                creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
                creatHtml += '</a>';
                creatHtml += '</div>';
            } else if (item.serviceType == 2) {
                creatHtml += "<div class='serviceItem baiduBridgeServiceLine'>";
                creatHtml +=
                    "<a id='baiduBridgeServiceItem" + i + "' href='javascript:;' onclick='Mobi.showBaiduBridge()'>";
                creatHtml +=
                    "<div class='serviceIcon'><span style='" +
                    bgImg +
                    iconColor +
                    ";' class=' serviceIconText " +
                    iconClassName +
                    "'></span></div>";
                creatHtml +=
                    "<div class='serviceName'><span class='serviceNameText'>" + item.showFont + '</span></div>';
                creatHtml +=
                    "<div class='serviceNumber'><span class='serviceNumberText'>" + LS.js_consult + '</span></div>';
                creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
                creatHtml += '</a>';
                creatHtml += '</div>';
            } else {
                creatHtml += "<div class='serviceItem phoneServiceLine'>";
                // creatHtml += "<a id='phoneServiceItem" + i + "' href='javasc:" + item.serviceAccount + "'>";
                creatHtml +=
                    "<a id='phoneServiceItem" +
                    i +
                    "' href='javascript:Mobi.triggerServiceNumber(1, \"" +
                    item.serviceAccount +
                    '");\'>';
                creatHtml +=
                    "<div class='serviceIcon'><span style='" +
                    bgImg +
                    iconColor +
                    "' class=' serviceIconText " +
                    iconClassName +
                    "'></span></div>";
                creatHtml +=
                    "<div class='serviceName'><span class='serviceNameText'>" + item.showFont + '</span></div>';
                creatHtml +=
                    "<div class='serviceNumber'><span class='serviceNumberText'>" +
                    item.serviceAccount +
                    '</span></div>';
                creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
                creatHtml += '</a>';
                creatHtml += '</div>';
            }
        }
    }

    creatHtml += "<div id='cancleSer' class='cancleSer'>" + LS.cancel + '</div>';
    creatHtml += '</div>';

    jm(creatHtml).appendTo(jm('#g_body'));
    jm('.serviceFullBg, .cancleSer')
        .off('click.ser')
        .on('click.ser', function () {
            jm('#g_web').off('scroll');
            jm('.serviceFullBg').remove();
            jm('.serviceSettingEntrance').remove();
            jm('.serviceListPanel').remove();
            jm('#g_body').removeAttr('ontouchmove');
            mobiShowScroll();
            if (VITE_APP_MODE !== 'visitor') {
                Vue.prototype.$layer.removeEditLayer(jm('.serviceListPanel'));
            }
        });
    //数据统计
    jm('.serviceListPanel .qqServiceLine')
        .off('click.log')
        .on('click.log', function () {
            logDog(200171, 1);
        });
    jm('.serviceListPanel .baiduBridgeServiceLine')
        .off('click.log')
        .on('click.log', function () {
            logDog(200171, 3);
        });
    jm('.serviceListPanel .phoneServiceLine')
        .off('click.log')
        .on('click.log', function () {
            logDog(200171, 2);
        });
    if (isPC()) {
        jm('.phoneServiceLine')
            .off('click.ser')
            .on('click.ser', function () {
                mobiAlert('该功能无法在桌面浏览器中启动！');
                return;
            });
    }
}

function notMallServiceList() {
    var phoneData = Fai.top._onlineServiceJson.phone,
        qqData = Fai.top._onlineServiceJson.qq;

    var hasPhone = phoneData && phoneData.phoneInfo && phoneData.phoneInfo.length > 0,
        hasQq = qqData && qqData.qqInfo && qqData.qqInfo.length > 0;

    if (!hasPhone && !hasQq && !phoneData.open && !qqData.open) {
        return;
    }
    mobiHideScroll();
    var creatHtml = "<div class='serviceFullBg'></div>",
        i,
        l,
        item,
        iconClassName = '',
        iconColor = '',
        bgImg = '',
        colIconType, //图片类型， 1，icon，  2 背景图片
        iconFilePath; //背景图片路径;

    creatHtml += "<div id='J_serviceListPanel' class='serviceListPanel'>";
    if (hasPhone && phoneData.open) {
        iconClassName = phoneData.baseSetting.classname;
        colIconType = phoneData.baseSetting.colIconType;

        if (colIconType != 2) {
            //图标
            iconClassName = phoneData.baseSetting.classname + ' icon-phoneServiceIcon';
        } else {
            //图片
            iconFilePath = phoneData.baseSetting.iconFilePath;
            bgImg = 'background-image:url(' + iconFilePath + ');';
        }

        iconColor = phoneData.baseSetting.color ? 'background-color:' + phoneData.baseSetting.color + ';' : '';
        for (i = 0, l = phoneData.phoneInfo.length; i < l; i++) {
            item = phoneData.phoneInfo[i];
            creatHtml += "<div class='serviceItem phoneServiceLine'>";
            creatHtml += "<a id='phoneServiceItem" + i + "' href='tel:" + item.number + "'>";
            creatHtml +=
                "<div class='serviceIcon'><span style='" +
                bgImg +
                iconColor +
                "' class='phoneServiceIcon serviceIconText " +
                iconClassName +
                "'></span></div>";
            creatHtml += "<div class='serviceName'><span class='serviceNameText'>" + item.name + '</span></div>';
            creatHtml += "<div class='serviceNumber'><span class='serviceNumberText'>" + item.number + '</span></div>';
            creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
            creatHtml += '</a>';
            creatHtml += '</div>';
        }
    }
    iconClassName = '';
    colIconType = 0;
    iconClassName = '';
    iconFilePath = '';
    bgImg = '';
    iconColor = '';
    if (hasQq && qqData.open) {
        iconClassName = qqData.baseSetting.classname;
        colIconType = qqData.baseSetting.colIconType;

        if (colIconType != 2) {
            //图标
            iconClassName = qqData.baseSetting.classname + ' icon-qqServiceIcon';
        } else {
            //图片
            iconFilePath = qqData.baseSetting.iconFilePath;
            bgImg = 'background-image:url(' + iconFilePath + ');';
        }

        iconColor = qqData.baseSetting.color ? 'background-color:' + phoneData.baseSetting.color + ';' : '';

        for (i = 0, l = qqData.qqInfo.length; i < l; i++) {
            item = qqData.qqInfo[i];
            creatHtml += "<div class='serviceItem qqServiceLine'>";
            creatHtml += "<a id='qqServiceItem" + i + "' href='javascript: Mobi.openQQ(\"" + item.number + '");\'>';
            creatHtml +=
                "<div class='serviceIcon'><span style='" +
                bgImg +
                iconColor +
                ";' class='qqServiceIcon serviceIconText " +
                iconClassName +
                "'></span></div>";
            creatHtml += "<div class='serviceName'><span class='serviceNameText'>" + item.name + '</span></div>';
            creatHtml += "<div class='serviceNumber'><span class='serviceNumberText'>" + item.number + '</span></div>';
            creatHtml += "<div class='operationBox'><div class='operationIcon icon-operation'></div></div>";
            creatHtml += '</a>';
            creatHtml += '</div>';
        }
    }

    creatHtml += "<div id='cancleSer' class='cancleSer'>" + LS.cancel + '</div>';
    creatHtml += '</div>';

    jm(creatHtml).appendTo(jm('#g_body'));
    jm('.serviceFullBg, .cancleSer')
        .off('click.ser')
        .on('click.ser', function () {
            jm('#g_web').off('scroll');
            jm('.serviceFullBg').remove();
            jm('.serviceSettingEntrance').remove();
            jm('.serviceListPanel').remove();
            mobiShowScroll();
            jm('#g_body').removeAttr('ontouchmove');
            if (VITE_APP_MODE !== 'visitor') {
                Vue.prototype.$layer.removeEditLayer(jm('.serviceListPanel'));
            }
        });
    if (isPC()) {
        jm('.phoneServiceLine')
            .off('click.ser')
            .on('click.ser', function () {
                mobiAlert('该功能无法在桌面浏览器中启动！');
                return;
            });
    }

    jm('.serviceListPanel').mouseover(function () {
        var serviceJson = [
            {
                text: '编辑客服',
                display: 'text',
                className: 'edit',
                evalScript: 'Mobi.getPreviewWindow().Mobi.setServiceTool();',
            },
        ];

        if (VITE_APP_MODE !== 'visitor') {
            Vue.prototype.$layer.addModuleLayer(jm(this).attr('id'), serviceJson, -1, true);
        }
    });

    jm('.serviceListPanel').mouseout(function () {
        if (VITE_APP_MODE !== 'visitor') {
            Vue.prototype.$layer.removeModuleLayer(jm(this).attr('id'));
        }
    });
}

export function productFormBtnClick(formId, pid) {
    linkToForm(formId, pid, true);
}
