<template>
    <div class="m_mobi_form_multi_dropdown_wrap">
        <div class="select_wrap">
            <select-component
                :list="firstLevelList"
                icon-class="arrow"
                :value="currentVal.firstLevel.val"
                :placeholder="placeHolder"
                content-key="value"
                :disabled="currentVal.firstLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.firstLevel.disabled,
                }"
                @input="selectFirstLevel($event)"
            />
        </div>
        <div v-show="(level >= 2 && secondLevelList.length) || shortLevel >= 2" class="select_wrap">
            <select-component
                :list="secondLevelList"
                icon-class="arrow"
                :value="currentVal.secondLevel.val"
                :placeholder="placeHolder"
                content-key="value"
                :disabled="currentVal.secondLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.secondLevel.disabled,
                }"
                @input="selectSecondLevel($event)"
            />
        </div>
        <div v-show="(level >= 2 && thirdLevelList.length) || shortLevel >= 3" class="select_wrap">
            <select-component
                :list="thirdLevelList"
                icon-class="arrow"
                :value="currentVal.thirdLevel.val"
                :placeholder="placeHolder"
                content-key="value"
                :disabled="currentVal.thirdLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.thirdLevel.disabled,
                }"
                @input="selectThirdLevel($event)"
            />
        </div>
        <div v-show="(level >= 2 && fourthLevelList.length) || shortLevel >= 4" class="select_wrap">
            <select-component
                :list="fourthLevelList"
                icon-class="arrow"
                :value="currentVal.fourthLevel.val"
                :placeholder="placeHolder"
                content-key="value"
                :disabled="currentVal.fourthLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.fourthLevel.disabled,
                }"
                @input="selectFourthLevel($event)"
            />
        </div>
    </div>
</template>

<script>
import SelectComponent from './SelectComponent.vue';
import { getMultiDropDownInfo, getMultiDropDownLevel, getMultiDropDownLevelData } from '@jz/biz-shared';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'CustomSearchMutliDropdown',
    components: {
        SelectComponent,
    },
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        placeHolder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            noneValue: 'none',
            manageVal: {
                firstLevel: {
                    val: '',
                    disabled: false,
                },
                secondLevel: {
                    val: '',
                    disabled: true,
                },
                thirdLevel: {
                    val: '',
                    disabled: true,
                },
                fourthLevel: {
                    val: '',
                    disabled: true,
                },
            },
            submitVal: '',
        };
    },
    computed: {
        shortLevel() {
            return getMultiDropDownLevel(this.formItem.input, true);
        },
        level() {
            return getMultiDropDownLevel(this.formItem.input);
        },
        currentVal() {
            return this.manageVal;
        },
        dropDownInfo() {
            const input = this.formItem.input || '';
            return getMultiDropDownInfo(input);
        },
        firstLevelList() {
            try {
                return getMultiDropDownLevelData(this.dropDownInfo, 0) || [];
            } catch (e) {
                return [];
            }
        },
        secondLevelList() {
            try {
                return getMultiDropDownLevelData(this.dropDownInfo, 1, this.currentVal.firstLevel.val) || [];
            } catch (e) {
                return [];
            }
        },
        thirdLevelList() {
            try {
                return (
                    getMultiDropDownLevelData(
                        this.dropDownInfo,
                        2,
                        this.currentVal.firstLevel.val,
                        this.currentVal.secondLevel.val
                    ) || []
                );
            } catch (e) {
                return [];
            }
        },
        fourthLevelList() {
            try {
                return (
                    getMultiDropDownLevelData(
                        this.dropDownInfo,
                        3,
                        this.currentVal.firstLevel.val,
                        this.currentVal.secondLevel.val,
                        this.currentVal.thirdLevel.val
                    ) || []
                );
            } catch (e) {
                return [];
            }
        },
    },
    methods: {
        changeOtherLevel(key, disabled = false) {
            this.currentVal[key].val = '';
            this.currentVal[key].disabled = disabled;
        },
        selectItem(item, level = '') {
            if (item instanceof Object) {
                this.currentVal[level].val = item.value;
            } else {
                this.currentVal[level].val = item;
            }
            const { firstLevel = {}, secondLevel = {}, thirdLevel = {}, fourthLevel = {} } = this.currentVal || {};
            let result = '';
            result = firstLevel.val || '';
            !!secondLevel.val && (result += `-${secondLevel.val}`);
            !!thirdLevel.val && (result += `-${thirdLevel.val}`);
            !!fourthLevel.val && (result += `-${fourthLevel.val}`);
            this.submitVal = result;
            this.$emit('selectChange', this.currentVal);
        },
        selectFirstLevel(item) {
            this.selectItem(item, 'firstLevel');
            this.changeOtherLevel('secondLevel');
            this.changeOtherLevel('thirdLevel', true);
            this.changeOtherLevel('fourthLevel', true);
        },
        selectSecondLevel(item) {
            this.selectItem(item, 'secondLevel');
            this.changeOtherLevel('thirdLevel');
            this.changeOtherLevel('fourthLevel', true);
        },
        selectThirdLevel(item) {
            this.selectItem(item, 'thirdLevel');
            this.changeOtherLevel('fourthLevel');
        },
        selectFourthLevel(item) {
            this.selectItem(item, 'fourthLevel');
        },
        validate() {
            if (this.formItem.must && this.currentVal.firstLevel.val.length === 0) {
                Mobi.ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss">
.m_mobi_form_multi_dropdown_wrap {
    .select_wrap:not(:last-of-type) {
        margin-bottom: 0.68rem;
    }
}
</style>
