<template>
    <input
        ref="inputRef"
        type="text"
        class="m_mobi_form_input m_mobi_form_input_text"
        :value="showValue"
        :placeholder="formItem.placeholder"
        :style="_mixinsInputStyle"
        @input="inputHandler"
        @focus="focusHandler"
        @blur="blurHandler"
    />
</template>
<script>
import Mixins from '../mixins';
import formDetailEditMixin from '../mixins/formDetailEdit.js';
import { ing } from '@/shared/tips';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'FormNumber',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: 0,
            isFocus: false, // 是否在focus状态
            value: this.formItem.numberSetting.defaultNum, // 应需求，需要在初始状态时保持空值，故引入String类型
            // 最大值
            max: this.formItem.numberSetting.type === 1 ? this.formItem.numberSetting.max : 999999,
            // 最小值
            min: this.formItem.numberSetting.type === 1 ? this.formItem.numberSetting.min : 0,
            // 支持负数
            nagative: false,
            // 单位
            unit: undefined,
            // 小数位数
            digitis: this.formItem.numberSetting.type === 1 ? this.formItem.numberSetting.decimalCnt : 1,
            // 禁止
            // 支持保留空状态
            empty: true,
        };
    },
    computed: {
        decimal() {
            return this.digitis !== 0;
        },
        showValue() {
            const val = this.currentValue;
            const unit = this.unit;
            const isFocus = this.isFocus;
            const digitis = this.digitis;
            const decimal = this.decimal;

            let showValue = val;
            if (!isFocus) {
                if (decimal && digitis > 0 && showValue !== '') {
                    showValue = showValue.toFixed(digitis);
                }
                if (unit) {
                    showValue += unit;
                }
            }
            return showValue;
        },
        validatorMRE() {
            var decimal = this.decimal,
                nagative = this.nagative,
                digitis = this.digitis;

            var nonnagativeIntegerMRE = /^(?:0|[1-9]\d*)$/,
                integerMRE = /^(?:0|-?([1-9]\d*)?)$/,
                nonnagativeFloatMRE = new RegExp(
                    '^(?:0|[1-9]\\d*)(\\.\\d' + (digitis > 0 ? '{0,' + digitis + '}' : '*') + ')?$'
                ),
                floatMRE = new RegExp(
                    '(^\\-$)|(^(?:0|[1-9]\\d*|\\-\\d+)(\\.\\d' + (digitis > 0 ? '{0,' + digitis + '}' : '*') + ')?$)'
                );
            return decimal && nagative
                ? floatMRE
                : decimal
                ? nonnagativeFloatMRE
                : nagative
                ? integerMRE
                : nonnagativeIntegerMRE;
        },
        validatorRE() {
            var decimal = this.decimal,
                nagative = this.nagative;

            var nonnagativeIntegerRE = /^(?:0|[1-9]\d*)$/,
                integerRE = /^(?:0|-?[1-9]\d*)$/,
                nonnagativeFloatRE = /^(?:0|[1-9]\d*)(\.\d+)?$/,
                floatRE = /^(?:0|\?\d+)(\.\d+)?$/;
            return decimal && nagative
                ? floatRE
                : decimal
                ? nonnagativeFloatRE
                : nagative
                ? integerRE
                : nonnagativeIntegerRE;
        },
        submitVal() {
            return `${this.showValue}`;
        },
    },
    watch: {
        currentValue(val) {
            if (val !== this.value) {
                this.$emit('input', val);
            }
        },
        value(val) {
            this.setCurrentValue(val);
        },
        'formItem.numberSetting.defaultNum'() {
            this.setCurrentValue(this.formItem.numberSetting.defaultNum);
        },
    },
    created() {
        this.setCurrentValue(this.value);
    },
    async mounted() {
        this.initDefaultValue();
    },
    methods: {
        inputHandler(e) {
            var val = e.target.value;
            if (val === '') return;

            if (this.validatorMRE.test(val)) {
                this.validatorRE.test(val) && this.setCurrentValue(val);
            } else {
                this.$forceUpdate();
            }
        },
        focusHandler() {
            this.isFocus = true;
            this.$emit('focus');

            this.$emit('on-undo-focus', this.currentValue);
        },
        blurHandler(event) {
            if (this.currentValue < this.min || (this.$refs.inputRef && this.$refs.inputRef.value === '')) {
                this.setCurrentValue(this.min);
                this.$emit('error');
            }
            this.isFocus = false;
            this.$forceUpdate();
            this.$emit('blur', this.currentValue);
            this.$emit('on-blur', this.currentValue, event);
            this.$emit('selectChange', {
                value: this.currentValue,
                formItem: this.formItem,
            });
            this.$emit('on-undo-blur', this.currentValue);
        },
        setCurrentValue(val) {
            var parseVal = parseFloat(val),
                curVal = isNaN(parseVal) ? '' : parseVal;

            if (curVal > this.max) {
                this.currentValue = this.max;
                this.$emit('error');
                this.$forceUpdate();
            } else {
                this.currentValue = curVal;
            }
        },
        async initDefaultValue() {
            const initialValue = this.inMemberDetailEdit
                ? this.getEditingItemSubmitValue()
                : this.formItem.numberSetting.defaultNum;
            if (initialValue !== undefined && initialValue !== '') {
                await this.$nextTick();
                this.setCurrentValue(initialValue);
                this.$emit('selectChange', {
                    value: initialValue,
                    formItem: this.formItem,
                });
            }
        },
        validate() {
            if (this.formItem.must && this.currentValue.length === 0) {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
    },
};
</script>

<style></style>
