<template>
    <div class="m_mobi_form_select_comp_wrap">
        <input
            ref="input"
            readonly
            :placeholder="placeholder"
            :value="getContentText(value)"
            class="select_input m_mobi_form_select m_mobi_form_input_text"
            :style="itemInputStyle"
            :disabled="disabled"
            @click.stop="toggleList"
        />
        <i :class="iconClass" class="faisco-icons-S000112" @click.stop="toggleList"></i>
        <PopupLayerComponent v-model="mobiShowList" :is-link-to-form="isLinkToForm">
            <div class="p_mobi_picker">
                <div class="header">
                    <div class="btn cancel" @click="mobiCancel">取消</div>
                    <div class="btn confirm" @click="mobiConfirm">确定</div>
                </div>
                <ul ref="list" class="list" @touchstart="onTouchStart" @mousedown="onTouchStart">
                    <li v-for="(item, index) of list" :key="index" class="item">{{ getContentText(item) }}</li>
                </ul>
            </div>
        </PopupLayerComponent>
    </div>
</template>

<script>
import { isFunction, context } from '@jz/utils';
const isWindow = (obj) => obj != null && obj == obj.window;
const access = function (elems, fn, key, value, chainable, emptyGet, pass) {
    var exec,
        bulk = key == null,
        i = 0,
        length = elems.length;

    // Sets many values
    if (key && typeof key === 'object') {
        for (i in key) {
            if (Object.hasOwnProperty.call(key, i)) {
                access(elems, fn, i, key[i], 1, emptyGet, value);
            }
        }
        chainable = 1;

        // Sets one value
    } else if (value !== undefined) {
        // Optionally, function values get executed if exec is true
        exec = pass === undefined && isFunction(value);

        if (bulk) {
            // Bulk operations only iterate when executing function values
            if (exec) {
                exec = fn;
                fn = function (elem, key, value) {
                    return exec.call(jm(elem), value);
                };

                // Otherwise they run against the entire set
            } else {
                fn.call(elems, value);
                fn = null;
            }
        }

        if (fn) {
            for (; i < length; i++) {
                fn(elems[i], key, exec ? value.call(elems[i], i, fn(elems[i], key)) : value, pass);
            }
        }

        chainable = 1;
    }

    return chainable
        ? elems
        : // Gets
        bulk
        ? fn.call(elems)
        : length
        ? fn(elems[0], key)
        : emptyGet;
};
// Create scrollLeft and scrollTop methods
context?.jm?.each({ scrollLeft: 'pageXOffset', scrollTop: 'pageYOffset' }, function (method, prop) {
    var top = /Y/.test(prop);

    jm.fn[method] = function (val) {
        return access(
            this,
            function (elem, method, val) {
                var win = getWindow(elem);

                if (val === undefined) {
                    return win ? (prop in win ? win[prop] : win.document.documentElement[method]) : elem[method];
                }

                if (win) {
                    win.scrollTo(!top ? val : jm(win).scrollLeft(), top ? val : jm(win).scrollTop());
                } else {
                    elem[method] = val;
                }
            },
            method,
            val,
            arguments.length,
            null
        );
    };
});

function getWindow(elem) {
    return isWindow(elem) ? elem : elem.nodeType === 9 ? elem.defaultView || elem.parentWindow : false;
}
import PopupLayerComponent from './PopupLayerComponent.vue';
export default {
    components: {
        PopupLayerComponent,
    },
    props: {
        iconClass: {
            type: [String, Object, Array],
            default: 'drop_down_icon',
        },
        value: {
            default: '',
        },
        disabled: Boolean,
        list: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        contentKey: {
            type: String,
            default: 'content',
        },
        isLinkToForm: Boolean,
        itemInputStyle: {
            type: [Object, String],
            default: () => ({}),
        },
        residueMap: {
            type: Object,
            deafult() {
                return null;
            },
        },
    },
    data() {
        return {
            showList: false,
            mobiShowList: false,
            oldTouchY: 0, //上一次的位置
            speedTouchY: 0, //移动速度
            moveTimer: null,
        };
    },
    methods: {
        toggleList() {
            if (!this.list.length || this.disabled) {
                return;
            }
            this.showList = false;
            this.mobiShowList = true;
            Vue.nextTick(() => {
                if (this.value) {
                    jm(this.$refs.list).scrollTop(this.list.indexOf(this.value) * 38);
                }
            });
            return;
        },
        onTouchStart(e) {
            clearInterval(this.moveTimer);

            this.oldTouchY = this.getTouchEventY(e);

            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('mousemove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
            window.addEventListener('mouseup', this.onTouchEnd);
        },
        onTouchMove(e) {
            const touchY = this.getTouchEventY(e);
            const $list = jm(this.$refs.list);

            this.speedTouchY = this.oldTouchY - touchY;

            $list.scrollTop($list.scrollTop() + this.speedTouchY);

            this.oldTouchY = touchY;
        },
        onTouchEnd() {
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('mousemove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
            window.removeEventListener('mouseup', this.onTouchEnd);

            const $list = jm(this.$refs.list);

            //惯性滑动
            let speed = this.speedTouchY;
            this.moveTimer = setInterval(() => {
                speed *= 0.8;
                $list.scrollTop($list.scrollTop() + speed);
                if (Math.abs(speed) < 1) {
                    clearInterval(this.moveTimer);
                    finish(speed);
                }
            }, 1000 / 60);

            const finish = () => {
                const scrollTop = $list.scrollTop();
                const currentIndex = Math.round(scrollTop / 38);

                $list.scrollTop(currentIndex * 38);
            };
        },
        getTouchEventY(e) {
            if (e.changedTouches && e.changedTouches[0]) {
                return e.changedTouches[0].pageY;
            } else {
                return e.pageY;
            }
        },
        getContentText(value) {
            if (value instanceof Object) {
                return value[this.contentKey] + this.getResidueText(value[this.contentKey]);
            } else {
                return value + this.getResidueText(value);
            }
        },
        mobiCancel() {
            this.mobiShowList = false;
        },
        mobiConfirm() {
            const scrollTop = jm(this.$refs.list).scrollTop();
            const currentIndex = Math.round(scrollTop / 38);

            if (this.isZeroResidue(this.list[currentIndex])) {
                return;
            }
            this.mobiShowList = false;

            this.$emit('input', this.list[currentIndex], currentIndex);
        },

        isZeroResidue(item) {
            const name = typeof item === 'object' ? item.value : item;
            if (this.residueMap != null) {
                let data = this.residueMap[name];
                return data != null ? data.s <= 0 : false;
            } else {
                return false;
            }
        },

        getResidueText(name) {
            if (this.residueMap != null) {
                let data = this.residueMap[name];
                return data != null && data.nd === false ? data.text : '';
            } else {
                return '';
            }
        },
    },
};
</script>

<style lang="scss">
.m_mobi_form_select_comp_wrap {
    position: relative;
    .select_input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 0.825rem;
    }
    .arrow {
        position: absolute;
        right: 0.65rem;
        top: 0;
        font-size: 0.55rem;
        line-height: 2rem;
        color: #666;
        cursor: pointer;
    }
    .drop_down_icon {
        position: absolute;
        right: 0.85rem;
        color: #b7b7b7;
        font-size: 0.45rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

/* 手机选择器 */
.p_mobi_picker {
    position: relative;
    background: #fff;
    user-select: none;
}
.p_mobi_picker:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 76px;
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e3e2e8;
    border-left: 0;
    border-right: 0;
    pointer-events: none;
}
.p_mobi_picker:after {
    content: '';
    display: block;
    position: absolute;
    top: 42px;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.95), hsla(0, 0%, 100%, 0.6)),
        linear-gradient(0deg, hsla(0, 0%, 100%, 0.95), hsla(0, 0%, 100%, 0.6));
    background-position: top, bottom;
    background-size: 100% 76px;
    background-repeat: no-repeat;
    pointer-events: none;
}
.p_mobi_picker .header {
    height: 41px;
    line-height: 41px;
    border-bottom: 1px solid #eee;
    padding: 0;
    text-align: left;
}
.p_mobi_picker .list {
    height: 190px;
    list-style: none;
    overflow: hidden;
}
.p_mobi_picker .list:before,
.p_mobi_picker .list:after {
    content: '';
    display: block;
    height: 76px;
}
.p_mobi_picker .list .item {
    text-align: center;
    font-size: 18px;
    height: 38px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.p_mobi_picker .header .btn {
    font-size: 16px;
    color: #333;
    display: inline-block;
    padding: 0 30px;
    cursor: pointer;
}
.p_mobi_picker .header .confirm {
    color: #5874d8;
    float: right;
}
</style>
