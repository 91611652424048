<template>
    <p
        class="g_link paramName g_productName g_newProductName"
        :class="[classes, _proNameWrapClass]"
        :style="productName_style"
    >
        <span
            v-if="info.isTimedAdded"
            class="g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag"
            :class="_proNameWrapClass"
            >即将开售</span
        >
        {{ info.name }}
    </p>
</template>
<script>
import { mapFlag } from '@/shared/flag';
import { mapState } from 'vuex';

const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'ProductName',
    inject: ['module', 'options'],
    props: ['info'],
    computed: {
        ...mapState(['htmlFontSize']),
        ...mapFlag({
            showParamHasEdit: 0x2,
            isNewAddModule: 0x4,
        }),
        classes() {
            const wrap = this.module.content.nameWrap
                ? 'paramWrap'
                : 'paramNowrap';
            let textAlign =
                this.module.content.ta == 0
                    ? 'textAlign_center'
                    : 'textAlign_left';
            if (!(this.module.extInfo.newUser || this.module.extInfo.isEdit)) {
                textAlign = '';
            }
            return `${wrap} ${textAlign}`;
        },
        productName_style() {
            var style = '';
            if (!manageMode) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductNameStyle = this.module.content.mhighs.npn;
                if (newProductNameStyle.type == 1) {
                    var fontWeight =
                        newProductNameStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle =
                        newProductNameStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration =
                        newProductNameStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductNameStyle.lfs + 'rem;';
                    style += 'line-height:' + newProductNameStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductNameStyle.lfc + ';';
                }
            } else {
                var productNameStyle = this.module.content.mhighs.pn;
                if (productNameStyle.type == 1) {
                    if (productNameStyle.lfs) {
                        style +=
                            'font-size:' +
                            productNameStyle.lfs * this.htmlFontSize +
                            'px;';
                    } else {
                        style += 'font-size:12px;';
                    }
                    if (productNameStyle.lfct == 1) {
                        style += 'color:' + productNameStyle.lfc + ';';
                    }
                    if (productNameStyle.lfd == 1) {
                        style += 'text-decoration:underline;';
                    } else {
                        style += 'text-decoration:none;';
                    }
                }
            }
            return style;
        },
        _proNameWrapClass() {
            if (this.module.content.proNameWrap == 0) {
                return 'defaultProName';
            } else if (this.module.content.proNameWrap == 1) {
                return 'wrapProName';
            } else if (this.module.content.proNameWrap == 2) {
                return 'noWrapProName';
            }
            return '';
        },
    },
};
</script>
