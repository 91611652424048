<template>
    <div class="J_photoListCard fk-photoListCard" :style="cartContainerStyle">
        <ul class="J_cardContainer f-cardContainer" :style="cartListStyle">
            <li
                v-for="(item, index) in contentList"
                :key="index"
                :class="(index == 0 ? 'showPic_1 ' : '') + 'J_imgContainerWrap'"
                :style="cartItemStyle"
            >
                <CartItem
                    :photo-item="item"
                    :module-id="module.id"
                    :module-content="module.content"
                    :link-type="linkType"
                    :img-href="imgHref(item.jumpUrl)"
                    :img-index="index + 1"
                    :slides-index="item.index"
                    :loading-path="options.loadingPath"
                    :no-lazy-load="noLazyLoad"
                />
            </li>
        </ul>
    </div>
</template>
<script>
import CartItem from './cart-item.vue';
import { optionsMixins } from '../mixins/optionsMixins';
import { photoTextAndImgStyleMap } from '../../utils';
export default {
    name: 'CartList',
    components: {
        CartItem,
    },
    mixins: [optionsMixins],
    inject: ['module', 'options'],
    props: {
        contentList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        photoRadius() {
            return this.module.content.mhighs.iradius;
        },
        textStyleType() {
            return this.module.content.istyle;
        },
        photoSize() {
            return this.module.content.mhighs.isize;
        },
        isNewModule() {
            return this.module.content.inm == 1;
        },
        cartItemStyle() {
            let style = {};
            style['width'] = '100%';
            if (this.isNewModule) {
                // 设置radius
                style['border-radius'] =
                    this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
                if (this.textStyleType == 0) {
                    style['border-width'] = 0;
                }
                let photoTextAndImgStyle = photoTextAndImgStyleMap[this.textStyleType] || {};
                // Object.assign(style, photoTextAndImgStyleMap[this.textStyleType]);
                style = { ...style, ...photoTextAndImgStyle };
            } else {
                style['border-radius'] = '4px';
                style['background-size'] = 'cover';
            }

            return style;
        },
        cartListStyle() {
            let style = {};
            style['box-sizing'] = 'border-box';
            if (this.isNewModule) {
                style['margin'] = '0rem';
                style['margin-top'] = '0.75rem';
                style['margin-bottom'] = '0.75rem';
            }
            if (this.photoSize.wtype == 1) {
                style['width'] = this.photoSize.width + '%';
            } else {
                style['width'] = this.isNewModule ? '100%' : '13.55rem';
            }
            return style;
        },
        cartContainerStyle() {
            let style = {};
            style['box-sizing'] = 'border-box';
            style['padding-left'] = '0.75rem';
            style['padding-right'] = '0.75rem';
            style['display'] = 'flex';
            style['justify-content'] = 'center';
            return style;
        },
    },
};
</script>
<style></style>
