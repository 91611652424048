<template>
    <tab-container :module-id="moduleId" />
</template>

<script>
import { componentsTable } from '@/modules/visitor-interface';
import TabContainer from './tabContainer.vue';

export default {
    name: 'TabContainerVisitor',
    components: {
        TabContainer,
    },
    provide() {
        return {
            componentsTable,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            componentsTable,
        };
    },
};
</script>

<style></style>
