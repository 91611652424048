import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ScrollNoticeView from './module/scrollNotice.vue';

class _ScrollNotice extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.SCROLL_NOTICE, options);
    }

    render() {
        return ScrollNoticeView;
    }
}

export const ScrollNotice = new _ScrollNotice();
