<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" class="newsGroupPanel">
            <template v-if="!dataList.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板中添加分类~</div>
                    <button-component active @click.stop="addGroup">马上添加</button-component>
                </div>
            </template>
            <template v-else-if="!dataList.length && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加分类</div>
                </div>
            </template>
            <template v-else>
                <div :id="'pgList' + id" class="sortPanelList">
                    <template v-for="(item, index) in dataList">
                        <div :key="item.name" class="line icon-gline j-lineH J_lineH">
                            <template v-if="!item.children || (item.children && !item.children.length)">
                                <a class="pgName g_news_sort_name" :href="item.href" :style="new_sort_style">{{
                                    item.name
                                }}</a>
                            </template>
                            <div v-else @click="MobipgItemJump(item, index)">
                                <div class="pgName g_news_sort_name" :style="new_sort_style">{{ item.name }}</div>
                            </div>
                        </div>
                        <div :key="item.name + 'sep'" class="g_separator separatorLine"></div>
                    </template>
                </div>
            </template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { installNewsGroupPanel } from './newsGroupPanel/installPanel';
export default {
    name: 'NewsGroup',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            manageMode: VITE_APP_MODE !== 'visitor',
            newsGroupPanelVm: null,
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        ...mapGetters(['getModuleById']),
        id() {
            return this.moduleId;
        },
        module() {
            return this.getModuleById(this.id);
        },
        options() {
            return this.module.renderOptions;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        modulePattern() {
            return this.module.pattern;
        },
        styleId() {
            return this.module.style;
        },
        type: function () {
            return this.module.content.type;
        },
        aid: function () {
            return this.module.content.aid;
        },
        dataList: function () {
            if (this.module.content.type === 0) {
                return this.module.content.ngList.filter((item) => {
                    return item.id != 0;
                });
            } else if (this.module.content.type === 1) {
                return this.module.content.ngChooseList;
            } else {
                return this.module.content.catalogList;
            }
        },
        new_sort_style() {
            var style = '';
            if ((this.type == 1 || this.type == 2) && !this.nameWrap) {
                style = 'white-space:nowrap;';
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var newSortName = this.module.content.mhighs.nsn;
            if (newSortName.type == 1) {
                if (newSortName.lfs) {
                    style += 'font-size:' + newSortName.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (newSortName.lfct == 1) {
                    style += 'color:' + newSortName.lfc + ';';
                }
                if (newSortName.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
    },
    watch: {
        module: {
            handler: function () {
                this.newsGroupPanelVm && this.newsGroupPanelVm.$forceUpdate();
                var _this = this;
                if (!_this.dataList) return;
                _this.render = false;
                this.$nextTick(() => {
                    _this.render = true;
                    this.$nextTick(() => {
                        _this.bindEvent();
                    });
                });
            },
            deep: true,
        },
    },
    mounted() {
        this.bindEvent();
    },
    methods: {
        addGroup(e) {
            e.stopPropagation();
            this.$designer.open({
                panelType: 'pattern news-group',
                moduleId: this.id,
                styleId: this.styleId,
            });
        },
        bindEvent: function () {},
        MobipgItemJump: function (item, index) {
            this.newsGroupPanelVm = installNewsGroupPanel({
                group: item,
                isShowPanel: true,
                oneList: this.dataList,
                pg_startX: index,
                openThemeV3: this.openThemeV3,
            });
            // createFullScreenDiv(
            //     'pgListDiv',
            //     'getWafNotCk_ngCatalogList',
            //     item.reqDatas
            // );
        },
    },
};
</script>

<style lang="scss" scoped>
/*****************    文章分类  ******************/
.ngLine {
    cursor: pointer;
    overflow: hidden;
}
.ngLine-bg {
    background-color: white;
    transition: all 0.35s ease;
}
.ngGroupDiv {
    position: relative;
    background-color: white;
    width: 60%;
    float: right;
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.35s ease;
}
.ngName {
    transition: all 0.35s ease;
}
.ngName-w {
    width: 33%;
}
.ngMark-f {
    float: left;
}
.ngMark-c {
    color: white;
}
/*****************    文章分类  ******************/
</style>
