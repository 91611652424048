<template>
    <div
        :id="'productPicListForm' + info.id + '_module' + module.id"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="J_product_item"
        :class="itemWrapClasses"
        :title="_title2"
        :style="_proMarginStyle(index)"
    >
        <a :href="info.extInfo.href" :onclick="onclickStr">
            <div
                class="jz_productBigPicItem"
                :class="[_contentStyle, _wrapBorderRadiusClass]"
                :style="_warpProRadiusStyle"
            >
                <div class="J_productPho jz_productPho">
                    <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag">置顶</div>
                    <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                    <div
                        v-drag-resize="_resizeOptions"
                        :alt="info.name"
                        :src-original="info.extInfo.imgPath"
                        class="J_img_lazyload style33Img J_style33Img"
                        :style="proPhotoStyle"
                        :class="[_imgScaleClass, _imgBorderRadiusClass]"
                    ></div>
                    <quality-content-label
                        v-if="_isShowQualityContentLabel"
                        :text="_knowPayLabelText"
                    ></quality-content-label>
                </div>
                <div v-if="showContent(info)" class="jz_productScr" :style="_contentBgStyle" :class="contentBgClass">
                    <div v-if="showProName" :class="proNameClasses">
                        <p
                            class="g_link paramName g_productName g_newProductName"
                            :style="productName_style"
                            :class="_proNameWrapClass"
                        >
                            <template v-if="module.content.showProName">
                                <span v-if="info.isTimedAdded" class="g_oldThemeBgColor m_timedAddTag g_main_bgColor_v3"
                                    >即将开售</span
                                >
                                {{ info.name }}
                            </template>
                            <mall-cart
                                v-if="
                                    !isNewAddModule &&
                                    _showMallCart.mCart &&
                                    !_extInfo.hasMallPrice &&
                                    module.content.proStyle != 3
                                "
                                :info="info"
                            ></mall-cart>
                        </p>
                    </div>
                    <prop-panel
                        :info="info"
                        :list="_showPropList"
                        :ext-info="_extInfo"
                        :show-mall-cart="_showMallCart"
                        :text-align-class="textAlignClass"
                        :no-name-wrap="noNameWrap"
                    >
                    </prop-panel>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'BigPicListItem',
    components: { saleFlag, mallCart, propPanel, qualityContentLabel },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index'],
    data() {
        return {
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        salePromotionOpen() {
            return this.info.extInfo.salePromotionOpen && this.info.extInfo.salePromotionParam.saleType != 2;
        },
        showMallCartFlag() {
            var simpleFlag = this._isBigCol && this.module.content.proStyle == 3 ? false : true;
            return this._showMallCart.mCart && !this.salePromotionOpen && simpleFlag;
        },
        showProName() {
            return this.module.content.showProName;
        },
        proPhotoStyle() {
            if (this._isTransparentStyle) {
                return { ...this.photoStyle, ...this._proRadiusStyle };
            } else {
                return { ...this.photoStyle, ...this._fixProRadiusStyle };
            }
        },
        itemWrapClasses() {
            let classes = 'jz_productBigPicListForm J_productBigPicListForm ';
            if (this.module.content.proStyle == 2) {
                classes += ' jz_productBigPicCardListForm ';
            } else if (this.module.content.proStyle == 3) {
                classes += ' jz_productBigPicSimpleListForm ';
            }
            return classes;
        },
        photoStyle() {
            const proSize = this.module.content.proSize;
            const proType = proSize.proSizeType;
            let proScaleWidth = proSize.proWidth;
            let proScaleHeight = proSize.proHeight;

            if (!this.isNewAddModule) {
                if (proType == 1) {
                    proScaleWidth = 1;
                    proScaleHeight = 1;
                } else if (proType == 3) {
                    proScaleWidth = 3;
                    proScaleHeight = 2;
                }
            }

            if (this.isNewAddModule) {
                if (proType == 1) {
                    return {
                        paddingTop: '59%',
                        backgroundImage: `url(${
                            this.noLazyLoad ? this.info.extInfo.imgPath : this.options.loadingPath
                        })`,
                    };
                } else {
                    return {
                        paddingTop: `${(proScaleHeight * 100) / proScaleWidth}%`,
                        backgroundImage: `url(${
                            this.noLazyLoad ? this.info.extInfo.imgPath : this.options.loadingPath
                        })`,
                    };
                }
            } else {
                return {
                    paddingTop: `${(proScaleHeight * 100) / proScaleWidth}%`,
                    backgroundImage: `url(${this.noLazyLoad ? this.info.extInfo.imgPath : this.options.loadingPath})`,
                };
            }
        },
        proNameClasses() {
            return `${this.wrapClass} ${this.textAlignClass}`;
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            if (this.module.content.proStyle == 3) {
                textAlign = 'textAlign_center'; // 参数居中
            }
            return textAlign;
        },
        wrapClass() {
            let wrap = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
            if (this.module.content.proStyle == 3) {
                wrap = 'paramNowrap'; // 名称打点
            }
            return wrap;
        },
        noNameWrap() {
            let classes = this.module.content.nameWrap ? 'paramWrap' : 'paramNowrap';
            if (this.module.content.proStyle == 3) {
                classes = 'paramNowrap'; // 除名称外的其他参数，没有打点
            }
            return classes;
        },
        contentBgClass() {
            return this.isNewAddModule && this.module.content.proStyle != 3 && this.module.content.proStyle != 11
                ? 'contentBgClass'
                : '';
        },
        productName_style() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            if (this.isNewAddModule) {
                var newProductNameStyle = this.module.content.mhighs.npn;
                if (newProductNameStyle.type == 1) {
                    var fontWeight = newProductNameStyle.nlfb == 1 ? 'bold' : 'normal';
                    var fontStyle = newProductNameStyle.nlfi == 1 ? 'italic' : 'normal';
                    var textDecoration = newProductNameStyle.lfd == 1 ? 'underline' : 'none';
                    style += 'font-size:' + newProductNameStyle.lfs + 'rem;';
                    style += 'line-height:' + newProductNameStyle.nlflh + 'px;';
                    style += 'font-weight:' + fontWeight + ';';
                    style += 'text-decoration:' + textDecoration + ';';
                    style += 'font-style:' + fontStyle + ';';
                    style += 'color:' + newProductNameStyle.lfc + ';';
                }
            } else {
                var productNameStyle = this.module.content.mhighs.pn;
                if (productNameStyle.type == 1) {
                    if (productNameStyle.lfs) {
                        style += 'font-size:' + productNameStyle.lfs + 'rem;';
                    } else {
                        style += 'font-size:12px;';
                    }
                    if (productNameStyle.lfct == 1) {
                        style += 'color:' + productNameStyle.lfc + ';';
                    }
                    if (productNameStyle.lfd == 1) {
                        style += 'text-decoration:underline;';
                    } else {
                        style += 'text-decoration:none;';
                    }
                }
            }
            return style;
        },
    },
    methods: {
        showContent(info) {
            var hasParamCon = this.module.content.dp.pp.length;
            return (
                !this.isNewAddModule ||
                (this.isNewAddModule && (this.showProName || hasParamCon || info.isTimedAdded || this.showMallCartFlag))
            );
        },
    },
};
</script>
<style lang="scss">
.isNewAddModule .mProductList.fk-mProductList33 .g_productName {
    font-size: 0.7rem;
}
.isNewAddModule .mProductList .jz_productBigPicSimpleListForm .jz_productScr {
    background-color: transparent;
}
.mProductList .jz_productBigPicItem.transparentStyle .J_img_lazyload,
.mProductList .jz_productBigPicSimpleListForm .jz_productBigPicItem .J_img_lazyload {
    border-radius: 0.2rem;
}
</style>
