<template>
    <div class="photoModule newCarouselMultiPhoto styleForm8" :class="photoSwipeCls">
        <div
            :id="'photoSwipe' + module.id"
            ref="photoSwipe8"
            class="J_photoSwipe photoSwipe"
            :style="photoSwipeStyle"
            @touchstart="touchstart"
            @touchmove="touchmove"
            @touchend="touchend"
        >
            <div
                v-if="
                    mhighs.carouselSet.carouselType == 1 && (carouselAni == 3 || carouselAni == 4 || carouselAni == 5)
                "
                id="autoSwipeBox"
                class="autoSwipeBox"
                :style="autoSwipeBoxStyle"
            >
                <new-carousel-image-item
                    v-for="(item, index) in cycleList"
                    ref="photoImageItem"
                    :key="item.picid"
                    :item="item"
                    :item-index="index"
                    :style="autoSwipeItemStyle"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :class="autoSwipeClass(index)"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
            </div>
            <div
                v-else
                id="photoSwipeBox"
                ref="photoSwipeBox"
                class="photoSwipeBox"
                :class="photoSwipeReset"
                :style="photoSwipeBoxStyle"
            >
                <new-carousel-image-item
                    v-for="(item, index) in cycleList"
                    ref="photoImageItem"
                    :key="item.picid"
                    :item="item"
                    :item-index="index"
                    :style="itemStyle"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
                <new-carousel-image-item
                    :item="cycleList[0]"
                    :item-index="0"
                    :style="itemStyle"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
            </div>
            <img-bullet-group
                v-if="content.td == 1 && cycleList.length"
                :current-index="currentIndex"
                :bullet-length="cycleList.length"
                :bullet-group-style="bulletGroupStyle"
                @bullet-click="upDateSwipeIndex"
            ></img-bullet-group>
        </div>
        <img-bullet-group
            v-if="content.td == 0 && cycleList.length > 1"
            :current-index="currentIndex"
            :bullet-length="cycleList.length"
            @bullet-click="upDateSwipeIndex"
        ></img-bullet-group>
    </div>
</template>
<script>
import NewCarouselImageItem from '../components/NewCarouselImageItem.vue';
import ImgBulletGroup from '../components/ImgBulletGroup.vue';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';
export default {
    name: 'CarouselType8',
    components: {
        NewCarouselImageItem,
        ImgBulletGroup,
    },
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            oldIndex: 0,
            currentIndex: 0,
            swipeIndex: 0,
            htmlFontSize: NEW_MANAGE_HTML_FONTSIZE,
            startPos: {},
            endPos: {},
            isScrolling: 0,
            touchingEnd: true,
            reset: false,
            startAni: false,
            maxItemHeight: 0,
            hasInitDom: false, //用来初始化节点的排版，为了解决闪烁的问题
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        carouselType() {
            return this.mhighs.carouselSet.carouselType;
        },
        carouselAni() {
            return this.mhighs.carouselSet.carouselAni;
        },
        carouseAniType() {
            if (this.carouselAni == 3 || this.carouselAni == 4 || this.carouselAni == 5) {
                return 2;
            } else {
                return 1;
            }
        },
        bulletGroupStyle() {
            var bgs = this.mhighs.bgs;
            if (bgs < 3) {
                return this.mhighs.bgs + 1;
            } else if (bgs == 3) {
                return 1;
            } else {
                return this.mhighs.bgs;
            }
        },
        photoSwipeStyle() {
            var style = {};
            if (this.maxItemHeight) {
                style['height'] = this.maxItemHeight + 'px';
            }
            return style;
        },
        photoSwipeCls() {
            var cls = '';
            if (this.content.td == 1) {
                cls = 'innerBox';
            } else {
                cls += 'outerBox outerBox' + (parseInt(this.content.itp) + 1);
                cls += ' imgTextType' + (parseInt(this.content.its) + 1);
            }
            return cls;
        },
        itemStyle() {
            var style = {};
            if (this.carouselAni != 2 || this.carouselType != 1) {
                style['width'] = 100 / (this.cycleList.length + 1) + '%';
            }
            if (this.maxItemHeight) {
                style['height'] = this.maxItemHeight + 'px';
            }
            return style;
        },
        autoSwipeItemStyle() {
            var style = {
                'animation-duration': this.mhighs.carouselSet.switchTime + 'ms',
            };
            if (this.maxItemHeight) {
                style['height'] = this.maxItemHeight + 'px';
            }
            return style;
        },
        photoSwipeBoxStyle() {
            var style = {
                'transition-duration': this.mhighs.carouselSet.switchTime + 'ms',
            };
            if (!this.hasInitDom) {
                style['width'] = (this.cycleList.length + 1) * 100 + '%';
                style['display'] = 'flex';
            } else {
                if (this.carouselAni == 2 && this.carouselType == 1) {
                    style['height'] = (this.cycleList.length + 1) * 100 + '%';
                    style['transform'] = 'translateY(' + (-this.swipeIndex * 100) / (this.cycleList.length + 1) + '%)';
                } else {
                    style['display'] = 'flex';
                    style['width'] = (this.cycleList.length + 1) * 100 + '%';
                    // if(!this.touchingEnd && this.endPos.x){
                    // 	style['transform'] = "translateX(calc(" + (-this.swipeIndex  * 100 / (this.cycleList.length + 1))  + "% + " + (this.endPos.x) + "px))";
                    // }else{
                    style['transform'] = 'translateX(' + (-this.swipeIndex * 100) / (this.cycleList.length + 1) + '%)';
                    // }
                }
            }
            return style;
        },
        autoSwipeBoxStyle() {
            var style = {};
            if (!this.hasInitDom) {
                style['width'] = this.cycleList.length * 100 + '%';
                style['left'] = 0;
                style['display'] = 'flex';
            }
            return style;
        },
        photoSwipeReset() {
            return this.reset ? 'photoSwipeReset' : '';
        },
    },
    mounted() {
        //用flex布局初始化节点，避免出现闪烁的情况，再开始执行动画
        this.initDom();
    },
    methods: {
        initDom() {
            var self = this;
            setTimeout(function () {
                self.htmlFontSize = Fai.top._htmlFontSize;
                var maxItemHeight = 0;
                if (self.$refs.photoImageItem.length) {
                    self.$refs.photoImageItem.forEach(function (val) {
                        var itemHeight = val.$el.offsetHeight;
                        if (maxItemHeight < itemHeight) {
                            maxItemHeight = itemHeight;
                        }
                    });
                    if (maxItemHeight) {
                        self.maxItemHeight = maxItemHeight;
                    }
                }
                self.$nextTick(() => {
                    self.hasInitDom = true;
                    if (self.carouselType == 0 || self.carouselType == 1 || self.cycleList.length > 1) {
                        self.setAutoplay();
                    }
                });
            }, 0);
        },
        autoSwipeClass(index) {
            if (!this.hasInitDom) {
                return '';
            }
            // eslint-disable-next-line no-unused-vars
            var carouselType = this.carouselType;
            var carouselAni = this.carouselAni;
            let photoSwipeCls = '';
            if (carouselAni == 3) {
                photoSwipeCls = 'photoSwipeItem_3';
            } else if (carouselAni == 4) {
                photoSwipeCls = 'photoSwipeItem_4';
            } else if (carouselAni == 5) {
                photoSwipeCls = 'photoSwipeItem_5';
            } else {
                return '';
            }
            var cls = 'photoSwipeAnim ';

            if (!this.startAni && index == 0) {
                cls += photoSwipeCls + '_start ';
                return cls;
            }
            cls += photoSwipeCls;
            if (this.startAni) {
                if (index == this.currentIndex) {
                    cls += ' ' + photoSwipeCls + '_on';
                } else if (index == this.oldIndex) {
                    cls += ' ' + photoSwipeCls + '_off';
                }
            }

            return cls;
        },
        upDateSwipeIndex(index) {
            if (this.carouselType == 2) {
                var prev = index < this.currentIndex;
                this.switchPhoto(index, prev);
            }
        },
        setAutoplay() {
            var _this = this;
            window.clearInterval(this.timer);
            if (this.carouselType != 2 && this.cycleList.length > 1) {
                var aniTime = this.carouselType == 0 ? 4000 : this.mhighs.carouselSet.aniTime;
                var switchTime = this.carouselType == 0 ? 500 : this.mhighs.carouselSet.switchTime;
                var delay = aniTime + switchTime;
                this.timer = window.setInterval(function () {
                    _this.next();
                }, delay);
            }
        },
        cleanAutoPlay() {
            window.clearInterval(this.timer);
        },
        touchstart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            this.isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            this.startPos = {
                x: touch.pageX,
                y: touch.pageY,
                time: +new Date(),
            }; //取第一个touch的坐标值
            this.touchingEnd = false;
            this.cleanAutoPlay();
        },
        touchmove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];
            this.endPos = {
                x: touch.pageX - this.startPos.x,
                y: touch.pageY - this.startPos.y,
                time: +new Date(),
            };
            this.isScrolling = Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0;
            if ((this.carouselType == 1 && this.carouselAni == 2) || this.isScrolling === 0) {
                event.preventDefault();
            }
        },
        touchend() {
            if (this.carouselType == 1 && this.carouselAni == 2) {
                if (this.isScrolling === 1) {
                    if (Number(this.endPos.y) > 10 && Number(this.endPos.x) < 40) {
                        this.prev();
                    } else if (Number(this.endPos.y) < -10 && Number(this.endPos.x) < 40) {
                        this.next();
                    }
                }
            } else if (this.isScrolling === 0) {
                if (Number(this.endPos.x) > 10 && Number(this.endPos.y) < 40) {
                    this.prev();
                } else if (Number(this.endPos.x) < -10 && Number(this.endPos.y) < 40) {
                    this.next();
                }
                this.endPos.x = 0;
                this.endPos.y = 0;
            }
            this.touchingEnd = true;
            this.setAutoplay();
        },
        next() {
            var tmpIndex = this.currentIndex + 1;
            var _this = this;
            if (tmpIndex == this.cycleList.length) {
                tmpIndex = 0;
            }
            if (this.carouseAniType == 1 && this.swipeIndex == this.cycleList.length) {
                this.swipeIndex = 0;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, false);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, false);
            }
        },
        prev() {
            var tmpIndex = this.currentIndex - 1;
            var _this = this;
            if (tmpIndex < 0) {
                tmpIndex = this.cycleList.length - 1;
            }
            if (this.carouseAniType == 1 && this.swipeIndex == 0) {
                this.swipeIndex = this.cycleList.length;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, true);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, true);
            }
        },
        switchPhoto(currentIndex, prev) {
            if (this.carouseAniType == 2) {
                this.startAni = true;
                this.oldIndex = this.currentIndex;
                this.currentIndex = currentIndex;
            } else {
                if (currentIndex == 0 && !prev) {
                    this.swipeIndex = this.cycleList.length;
                } else {
                    this.swipeIndex = currentIndex;
                }
                this.currentIndex = currentIndex;
            }
        },
    },
};
</script>

<style lang="scss">
.formStyle42 .styleForm8 {
    width: 100%;
}
.formStyle42 .styleForm8 .photoSwipe {
    text-align: center;
    overflow: hidden;
    position: relative;
    display: block;
    font-size: 0;
    width: 100%;
}
.formStyle42 .styleForm8 .photoImageItem {
    flex: 1;
}
.formStyle42 .styleForm8.outerBox .photoImageItem {
    overflow: hidden;
}
.formStyle42 .styleForm8.outerBox .photoImageItem .photoImageItemContent {
    margin: 0.65rem 0.75rem;
    width: auto;
    height: calc(100% - 1.5rem);
}
.formStyle42 .styleForm8 .photoSwipeBox,
.formStyle42 .styleForm8 .autoSwipeBox {
    transition: all;
    transition-timing-function: ease;
    width: 100%;
    height: 100%;
    font-size: 0;
    text-align: left;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}
.formStyle42 .styleForm8 .photoSwipeBox {
    transition: transform;
}
.formStyle42 .styleForm8.innerBox .photoImageItem {
    margin: 0;
}
.formStyle42 .styleForm8 .imgBulletGroup {
    margin: 0 0 0.75rem 0;
}
.formStyle42 .styleForm8.innerBox .photoSwipe .imgBulletGroup {
    width: auto;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    height: 0.7rem;
    margin-bottom: 0;
    z-index: 88;
    display: flex;
    align-items: center;
}
.formStyle42 .styleForm8 .photoSwipeAnim {
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    position: absolute;
    clear: both;
}
.formStyle42 .photoSwipeBox.photoSwipeReset {
    transition: none !important;
}

.formStyle42 .photoSwipeItem_3 *,
.photoSwipeItem_5 * {
    backface-visibility: hidden;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_3 {
    display: none;
    transform: rotateX(90deg);
}
.formStyle42 .photoSwipeAnim.photoSwipeItem_3_start {
    transform: rotateX(0deg);
    position: relative;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_3_on {
    display: inline-block;
    animation-name: photoSwipeItem_3_on;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_3_off {
    display: inline-block;
    animation-name: photoSwipeItem_3_off;
}
@keyframes photoSwipeItem_3_on {
    0% {
        transform: rotateX(-90deg);
    }
    50% {
        transform: rotateX(-90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
@keyframes photoSwipeItem_3_off {
    0% {
        transform: rotateX(0deg);
    }
    50% {
        transform: rotateX(90deg);
    }
    100% {
        transform: rotateX(90deg);
    }
}

.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_4 {
    opacity: 0;
    display: none;
}
.formStyle42 .photoSwipeAnim.photoSwipeItem_4_start {
    opacity: 1;
    position: relative;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_4_on {
    display: inline-block;
    animation-name: photoSwipeItem_4_on;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_4_off {
    display: inline-block;
    animation-name: photoSwipeItem_4_off;
}
@keyframes photoSwipeItem_4_on {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes photoSwipeItem_4_off {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_5 {
    display: none;
    transform: rotateY(90deg);
}
.formStyle42 .photoSwipeAnim.photoSwipeItem_5_start {
    transform: rotateY(0deg);
    position: relative;
}
.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_5_on {
    display: inline-block;
    animation-name: photoSwipeItem_5_on;
}

.formStyle42 .newCarouselMultiPhoto.styleForm8 .photoSwipeItem_5_off {
    display: inline-block;
    animation-name: photoSwipeItem_5_off;
}

@keyframes photoSwipeItem_5_on {
    0% {
        transform: rotateY(-90deg);
    }
    50% {
        transform: rotateY(-90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
@keyframes photoSwipeItem_5_off {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(90deg);
    }
}

.formStyle42 .styleForm8.newCarouselMultiPhoto.innerBox .photoImageItem .imgDescBox .imgDesc {
    width: 75%;
}

.formStyle42 .styleForm8.newCarouselMultiPhoto.innerBox .photoImageItem .imgDescBox.noDesc .imgName {
    width: 75%;
}
</style>
