<template>
    <module-frame :module-id="moduleId">
        <div class="jz_mobi_form" :class="formClass" @click="editItemEnd">
            <template v-if="module.extInfo.notAllowPrint">{{ module.extInfo.noSiteFormTip }}</template>
            <template v-else-if="!formEmpty && !formItemEmpty">
                <module-alert v-if="permissionWarning" type="warning">
                    {{ permissionWarning }}
                </module-alert>
                <div v-if="module.content.sn" class="form_name" :style="formNameStyle">{{ formInfo.name }}</div>
                <p
                    v-if="openSubmitLimitTime && formLimitTimeTips"
                    v-show="showSubmitLimitTime"
                    class="form_limit_time_tips"
                    :style="limitTimeTipsStyle"
                >
                    <svg class="form_limit_time_tips_icon">
                        <use xlink:href="#icon_time"></use>
                    </svg>
                    <span class="form_limit_time_tips_text">{{ formLimitTimeTips }}</span>
                </p>

                <div class="form_items_wrap" :class="formItemsWrapClass">
                    <div
                        v-for="item in filterContentList"
                        :key="'form_' + currentFormId + '_' + item.id"
                        class="item_wrap J_item_wrap"
                        :style="itemStyle"
                        @click.stop="editItem($event, item)"
                    >
                        <template v-if="item.type != 8">
                            <form-item :form-item="item">
                                <component
                                    :is="componentMap[item.type]"
                                    :form-item="item"
                                    :form-info="formInfo"
                                    @selectChange="handleSelectChange"
                                ></component>
                            </form-item>
                        </template>
                        <template v-else>
                            <FormMultiItem :form-item="item"></FormMultiItem>
                        </template>
                    </div>
                    <div v-if="validation || needCode" class="item_wrap" :style="itemStyle" @click.stop="openFunEdit">
                        <form-item :form-item="validateCodeItem">
                            <form-validate-code
                                :form-item="validateCodeItem"
                                :module="module"
                                :form-info="formInfo"
                                @click.native.stop=""
                            />
                        </form-item>
                    </div>
                    <!-- 表单支付 -->
                    <div
                        v-show="isOpenPay && !openMultiPdInquiry"
                        class="pay_wrap"
                        @click.stop="openFunEdit(isOpenPay)"
                    >
                        <span class="pay_text">{{ formInfo.payTxt }}:</span>
                        <div class="pay_money_wrap">
                            <span :class="themeColorClass" style="font-size: 0.5rem; font-weight: bold">{{
                                module.extInfo.currencySymbol || '￥'
                            }}</span>
                            <span :class="themeColorClass" style="font-size: 0.65rem; font-weight: bold"
                                >{{ formatedMoney[0] }}.</span
                            >
                            <span :class="themeColorClass" style="font-size: 0.5rem; font-weight: bold">{{
                                formatedMoney[1]
                            }}</span>
                        </div>
                        <div class="pay_type_wrap">
                            <svg class="wx_pay_icon">
                                <use xlink:href="#jzm-vicon_online_pay" />
                            </svg>
                            <span class="wx_pay_text">{{ LS.g_onlinePay || '在线支付' }}</span>
                        </div>
                    </div>
                    <div v-if="manageMode && isOpenPay && !hasOpenPay && !openMultiPdInquiry" class="managePayTips"
                        >在线支付需设置支付方式<span class="redStrong" @click.stop="jumpToSet">前往设置</span>或<span
                            class="redStrong"
                            @click.stop="jumptoOpen"
                            >关闭在线支付</span
                        ></div
                    >
                </div>

                <div v-if="isOpenFormProtocol && allowFormProtocol" class="form_protocol">
                    <check-box v-model="isAgree" class="form_protocol_agree"></check-box>
                    <div>
                        <span class="form_protocol_text" :style="formProtocolTextStyle" @click="isAgree = !isAgree">{{
                            formProtocolText
                        }}</span>
                        <span class="form_protocol_title" :style="formProtocolTitleStyle" @click="previewFormProtocol">
                            {{ formProtocolTitle }}</span
                        >
                    </div>
                </div>
                <div
                    v-if="manageMode && module.content.ms === 0 && module.extInfo.submitSuccessTipCustom"
                    class="managePayTips"
                    >自定义样式下的表单提交成功提示正常展示需<span class="redStrong" @click.stop="module.content.ms = 1"
                        >切换新样式</span
                    >
                </div>
                <div class="submit_btn" :class="submitBtnClasses" :style="submitBtnStyle" @click.stop="submitForm">{{
                    submittingTip || module.content.sut || formInfo.buttonName
                }}</div>
            </template>

            <template v-else-if="manageMode && !formEmpty && formItemEmpty">
                <div class="empty_tips_panel">
                    <div class="text">请从“编辑表单”悬浮面板中，添加表单项。</div>
                    <button-component active @click.stop="addForm">马上添加</button-component>
                </div>
            </template>
            <template v-else-if="formEmpty">{{ notFoundFormTip }}</template>
            <template v-else-if="formItemEmpty"><div class="form_item_empty">当前表单没有任何表单项</div></template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapFlag } from '@/shared/flag';
import { parseBackgroundCss } from '@/shared/util';
import { mapGetters, mapState } from 'vuex';
import { fontStyle } from './utils';
import { logDog } from '@/shared/log';
import { logJZFdp } from '@/api/logDog';
import { ing } from '@/shared/tips';
import FormPopup from '@/system/formPopup/module/formPopup';
import { getFormLotteryByFormIdApi, addSubmitApi, memberModifySubmit } from '@/api/form';
import { setUrlArgToCookieAndJump, getIsOpenRemoveUrlArg, initModuleTransformTrack } from '@/modules/comm';
import FormPanel from '../panel/form_edit_panel';
import { MODULE_STYLE } from '@/def/module';
import { COL } from '@/def/col';
import { setModuleStyleCssList } from '@/shared/style';
import { showTopManageFrame } from '@/shared/frame';
import { jzPayUtils } from '@/shared/jzPayUtils';
import { Monitor, MonitorDef } from '@/features/monitor';
import { browser } from '@jz/utils';
import lottery from '@/features/global-mobi-function/methods/lottery/index';
import { changeHref } from '@/features/router/universal';
import FormItem from './components/form-item.vue';
import FormInput from './components/form-input.vue';
import FormRadio from './components/form-radio.vue';
import FormTextArea from './components/form-textArea.vue';
import FormCheckbox from './components/form-checkbox.vue';
import FormSelect from './components/form-select.vue';
import FormTextDescription from './components/form-text-description.vue';
import FormUpload from './components/form-upload.vue';
import FormPhone from './components/form-phone.vue';
import FormEmail from './components/form-email.vue';
import FormIdentity from './components/form-identity.vue';
import FormValidateCode from './components/form-validate-code.vue';
import FormDate from './components/form-date.vue';
import FormCity from './components/form-city.vue';
import FormMultiItem from './components/form-muti-item.vue';
import FormMultiDropdown from './components/form-multi-dropdown.vue';
import { Clue, clueReuqestVm, countDownFormLimitTime } from '@jz/biz-shared';
import { getServerTime } from '@/api/serverTime';
import checkBox from './components/checkbox.vue';
import FormNumber from './components/form-number.vue';
import FormSinglePhoto from './components/form-single-photo.vue';
import FormMultiplePhoto from './components/form-multiple-photo.vue';
import { isOpenFormProtocol } from '../flag';
import { getFormProtocolDetail } from '@/api/form';
import { encodeHtml } from '@/shared/util';
import formSubmitSuccessTipCustom from './components/form-submit-success-tip-custom.vue';
import ModuleAlert from '@/components/moduleAlert.vue';
import { format } from '@/shared/fai';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'MobiForm',
    components: {
        FormItem,
        FormInput,
        FormRadio,
        FormTextArea,
        FormCheckbox,
        FormSelect,
        FormTextDescription,
        FormPhone,
        FormIdentity,
        FormValidateCode,
        FormDate,
        FormCity,
        FormMultiItem,
        ModuleFrame,
        FormMultiDropdown,
        checkBox,
        FormNumber,
        FormSinglePhoto,
        FormMultiplePhoto,
        ModuleAlert,
    },
    provide() {
        return {
            module: this.module,
            options: this.options,
            moduleInfo: this.moduleInfo,
            renderOptions: this.renderOptions,
            inMemberDetailEdit: this.inMemberDetailEdit,
        };
    },
    inject: {
        moduleInfo: { default: void 0 },
        renderOptions: { default: void 0 },
        inMemberDetailEdit: { default: void 0 },
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            id: this.moduleId,
            manageMode,
            formItems: [],
            componentMap: {
                0: FormInput, // 单行文本
                1: FormTextArea, // 多行文本
                2: FormRadio, // 单选按钮
                3: FormCheckbox, // 多选按钮
                4: FormSelect, // 下拉选项
                5: FormTextDescription, // 文本说明
                6: FormDate, // 日期时间
                7: FormUpload, // 图片上传
                8: FormMultiItem, // 手机号码 + 短信验证
                9: FormEmail, // 邮件验证
                10: FormIdentity, // 身份验证
                11: FormCity, // 省市县区
                12: FormMultiDropdown, // 省市县区
                13: FormNumber,
                14: FormSinglePhoto,
                15: FormMultiplePhoto,
            },
            needCode: false,
            submittingTip: '',
            submitting: false,
            addGbutton: false,
            ruleMap: {}, // 规则选项
            formItemIsShow: {}, // 表单项
            currentFormId: -1, // 用于记录表单id
            themeColorClass: '',
            calculatePrice: 0,
            variableIdValue: {},
            formItemTypeNameArr: [
                '单行文本',
                '多行文本',
                '单选按钮',
                '多选按钮',
                '下拉选项',
                '文本说明',
                '日期选项',
                '文件上传',
                '手机号',
                '邮箱验证',
                '身份验证',
                '省市县区',
                '多级下拉',
                '数字',
                '图片单选',
                '图片多选',
            ],
            formLimitTimeTips: '',
            showSubmitLimitTime: false,
            cleanCountDown: null,
            isAgree: false,
            openMultiPdInquiry: false,
        };
    },
    computed: {
        ...mapState(['app']),
        ...mapState('app', ['LS', 'memberName', 'isLogin']),
        ...mapGetters(['getModuleById']),
        ...mapState('form', ['editingSubmitResultId']),
        newFileUploadStyle: {
            get() {
                return this.isFormPopup ? this.newFormFileUploadStyle : this.newFormModuleFileUploadStyle;
            },
            set(val) {
                if (this.isFormPopup) {
                    this.newFormFileUploadStyle = val;
                } else {
                    this.newFormModuleFileUploadStyle = val;
                }
            },
        },
        ...mapFlag(
            {
                newFormModuleFileUploadStyle: 0x1,
            },
            'module.flag'
        ),
        ...mapFlag(
            {
                newFormFileUploadStyle: 0x4000,
            },
            'formInfo.flag'
        ),
        isFormPopup() {
            return this.module.style === 84;
        },
        formItemsWrapClass() {
            return this.newFileUploadStyle ? 'file_upload_style2' : '';
        },
        module() {
            return this.moduleInfo || this.getModuleById(this.moduleId);
        },
        advance() {
            return this.module.content.mhighs;
        },
        styleId() {
            return this.module.style;
        },
        options() {
            return this.renderOptions || this.module.renderOptions;
        },
        notFoundFormTip() {
            return manageMode ? '表单已被删除，请选择表单或者新增表单' : this.module.extInfo.notFoundFormTip;
        },
        filterContentList() {
            return this.formInfo.contentList.filter((item) => {
                return !item.hide && this.formItemIsShow[item.id];
            });
        },
        formInfo() {
            let moduleContent = this.module.content;
            let formId = moduleContent.f_id;
            let fi = this.module.content.fi;
            if (manageMode) {
                const form = Fai.top._formList.find((form) => form.id === formId);
                if (form) {
                    if (typeof fi == 'undefined') {
                        fi = {};
                        Object.assign(fi, JSON.parse(JSON.stringify(form)));
                    }
                }
            }
            return fi;
        },
        formEmpty() {
            return !this.formInfo || Object.keys(this.formInfo).length < 1;
        },
        formItemEmpty() {
            return !this.formEmpty && this.formInfo.contentList.length < 1;
        },
        formClass() {
            return [
                `mobi_form_style_${this.module.content.ms}`,
                { manageMode: manageMode },
                { is_horizon: this.module.content.st === 0 },
            ];
        },
        itemClass() {
            return manageMode ? 'manageMode_item' : '';
        },
        validateCodeItem() {
            return {
                id: 'validateCode',
                name: this.module.extInfo.verificationCodeName,
                type: -1,
            };
        },
        ...mapFlag(
            {
                permission: 0x1,
                validation: 0x2,
                notRepeat: 0x4,
                openOnlinePay: 0x40,
                openSubmitLimitTime: 0x2000,
                isOpenFormProtocol,
            },
            'formInfo.flag'
        ),
        formNameStyle() {
            return fontStyle(this.advance.fn);
        },
        limitTimeTipsStyle() {
            return fontStyle(this.advance.lttt);
        },
        submitBtnStyle() {
            const backgroundCss = parseBackgroundCss({
                type: this.advance.sbg.t,
                repeat: this.advance.sbg.r,
                picScale: this.advance.sbg.ps,
                fileId: this.advance.sbg.fi,
                filePath: this.advance.sbg.fp,
                color: this.advance.sbg.c,
            });

            const fontCss = fontStyle(this.advance.sf, 'str');

            return backgroundCss + fontCss;
        },
        itemStyle() {
            if (this.advance.is.t === 1) {
                return `margin-top:${this.advance.is.v}rem`;
            }
            return '';
        },
        validateVm() {
            const validateVm = this.formItems.filter((content) => {
                return content.formItem.type === -1;
            });
            return validateVm[0] || {};
        },
        submitBtnClasses() {
            let classes = [this.app.openThemeV3 ? 'g_main_bgColor_v3' : 'g_oldThemeBgColor'];
            if (this.addGbutton) {
                classes.push('g_button');
            }

            return classes;
        },

        formItemIdList() {
            if (this.formInfo == null) return [];
            return this.formInfo.contentList.map((item) => item.id);
        },

        createdFormItemIdList() {
            return this.formItems.map((item) => {
                return item.formItem.id;
            });
        },
        resultItems() {
            return this.formItems.filter((formItemNode) => {
                if (formItemNode.formItem.type === -1) return true; // 校验码
                return this.formItemIsShow[formItemNode.formItem.id] == true;
            });
        },

        // 表单规则
        formRules() {
            if (this.formInfo == null) return [];
            if (this.formInfo.other == null) return [];
            return this.formInfo.other.rules || [];
        },

        // 选中规则所要展示的表单项
        formRuleAllSelectList() {
            let allSelectList = [];
            this.formRules.forEach((rule) => {
                rule.showList.forEach((item) => {
                    if (allSelectList.indexOf(item.key) == -1) allSelectList.push(item.key);
                });
            });
            return allSelectList;
        },
        allowOnlinePay() {
            return this.module.extInfo.allowOnlinePay;
        },
        isOpenPay() {
            return this.allowOnlinePay && this.openOnlinePay;
        },
        hasOpenPay() {
            return this.module.extInfo.hasOpenPay;
        },
        payType() {
            if (this.formInfo == null) return 0;
            return this.formInfo.other.payType;
        },
        formulaData() {
            if (this.formInfo == null) return {};
            return this.formInfo.other.formulaData;
        },
        // 计算公式中，变量的值 selectList  匹配对应的项id  方便取值
        vSelectListParam() {
            var vSelectListParam = {};
            var variableList = this.formulaData.variableList;
            if (variableList.length) {
                variableList.forEach((val) => {
                    vSelectListParam[val.id] = val.selectList;
                });
            }
            return vSelectListParam;
        },
        formatedMoney() {
            if (this.payType == 0) {
                return this.splitNumberWithDot(this.formInfo.price);
            } else {
                return this.splitNumberWithDot(this.calculatePrice);
            }
        },
        isOpenWXpay() {
            return this.module.extInfo.isOpenWXpay;
        },
        formProtocolText() {
            return this.LS.memberProtocol_labelMsg;
        },
        formProtocolTitle() {
            return `《${this.formInfo.formProtocolTitle}》`;
        },
        formProtocolTextStyle() {
            return fontStyle(this.advance.pt);
        },
        formProtocolTitleStyle() {
            return fontStyle(this.advance.ht);
        },
        allowFormProtocol() {
            return this.module.extInfo.allowFormProtocol;
        },
        isPopupForm() {
            return this.styleId === MODULE_STYLE.SYS_FORM_POPUP;
        },
        isInquiryPopupForm() {
            return this.isPopupForm && this.module.extInfo.formIsProductForm;
        },
        permissionWarning() {
            if (this.isLogin === false) return '';
            if (this.module.extInfo.checkFormAuth === -307) {
                return format(this.LS.memberLevelPermission, this.memberName, this.module.extInfo.memberLevel ?? '');
            }
            if (this.module.extInfo.checkFormAuth === -308) {
                return format(this.LS.memberGroupPermission, this.memberName);
            }
            return '';
        },
    },
    watch: {
        'formInfo.contentList': {
            handler: function (val, oldVal) {
                if (val && oldVal && val.length > oldVal.length) {
                    this.initFormIdListen(); // 新增加一个监听属性
                }
                this.removeFailRule();
                this.initRuleFormItemList();
                this.initFormContentList();
            },
            deep: true,
        },
        currentFormId: {
            handler: function () {
                // 说明表单切换了，重置选项
                this.ruleMap = {}; // 置空
                this.variableIdValue = {};
                this.calculatePrice = 0;
                this.removeFailRule();
                this.initRuleFormItemList();
                this.initVariableIdValue();
                this.initFormIdListen();
                this.initFormContentList();
                this.initSubmitLimitTime();
            },
        },
        formulaData: {
            handler: function () {
                this.calculatePrice = 0;
                this.initVariableIdValue();
                this.formulaCalculatePrice();
            },
            deep: true,
        },
        formInfo() {
            let moduleContent = this.module.content;
            let formId = moduleContent.f_id;
            this.currentFormId = formId;
            if (manageMode) {
                const form = Fai.top._formList.find((form) => form.id === formId);
                if (form) {
                    this.module.content.fi = typeof this.module.content.fi == 'undefined' ? {} : this.module.content.fi;
                    Object.assign(this.module.content.fi, JSON.parse(JSON.stringify(form)));
                }
            }
        },
    },
    created() {
        this.removeFailRule();
        this.initRuleFormItemList();
        this.initVariableIdValue();
        // this.formulaCalculatePrice();
        this.initFormIdListen();
        this.initFormContentList();

        this.$on('on-form-item-add', (item) => {
            // 这里判断之前是否存在过，有的话则删除（表单规则新加）
            if (item) {
                let index = this.createdFormItemIdList.indexOf(item.formItem.id);
                if (index != -1) {
                    this.formItems.splice(index, 1);
                }
                this.formItems.push(item);
            }
        });
    },
    mounted() {
        this.initSubmitLimitTime();
        // cookie中存在_lotteryId，即提交表单后需要抽奖，抽奖后清空_lotteryId
        if (jm.getCookie('_lotteryId')) {
            let lotteryId = jm.getCookie('_lotteryId');
            let _phoneNumber = jm.getCookie('_phoneNumber');
            let allowForm = true;
            lottery.getLotteryInfo(lotteryId, _phoneNumber, null, allowForm);
            jm.cookie('_lotteryId', '', { path: '/' });
            jm.cookie('_phoneNumber', '', { path: '/' });
            // 修改页面url不刷新
            window.history.pushState(null, '', location.origin + location.pathname);
        }

        if (!this.mall) {
            this.getLotteryId();
        }
        if (Fai.top._other.templateInfo.templateDesignType === 0) {
            this.addGbutton = true;
        }

        if (manageMode) {
            this.saveNotRightTempList();
        }
        if (this.styleId == MODULE_STYLE.SYS_FORM_POPUP) {
            if (this.module.content.mhighs.if.type == 1) {
                this.initInputFontSetting();
            }
        }
        this.themeColorClass = Fai.top._openThemeV3 ? 'g_main_color_v3' : 'g_oldThemeColor';

        if (!this.manageMode) {
            initModuleTransformTrack(this.module.id, this.module.style, this.formInfo?.other?.tts);
        }

        if (window.$store.state.app.openMultiPdInquiry && this.isPopupForm) {
            this.openMultiPdInquiry = FormPopup.fromBtn;
        }
        this.formulaCalculatePrice();
    },
    methods: {
        async initSubmitLimitTime() {
            if (!this.formInfo) {
                return;
            }
            if (!this.openSubmitLimitTime) {
                return;
            }
            const result = await getServerTime();
            const nowTime = result.timeStamp ?? 0;
            this.showSubmitLimitTime = true;
            this.cleanCountDown && this.cleanCountDown();
            const { startTime = 0, endTime = 0 } = this.formInfo.other;
            const clientNowTime = new Date().getTime();
            // 如果客户端时间跟服务端时间相差不超过5s，则用客户端的时间，否则用服务端时间
            const nowTimestamp = nowTime === 0 || Math.abs(nowTime - clientNowTime) < 5000 ? clientNowTime : nowTime;
            const { cleanCountDown } = countDownFormLimitTime({
                startTimestamp: startTime,
                endTimestamp: endTime,
                nowTimestamp,
                LS: window.LS || {},
                onCountDown: ({ tips }) => {
                    this.formLimitTimeTips = tips;
                },
            });
            this.cleanCountDown = cleanCountDown;
        },
        logFormSubmitByJz(formItems) {
            if (manageMode === false) {
                logJZFdp('jz_c_form_submit', {
                    jz_version: Fai.top.jzVersion,
                    jz_content_terminal: 'mobi',
                    jz_free_text1: formItems,
                    jz_site_id: Fai.top._siteId,
                });
            }
        },
        transformFormItemText(contentList) {
            if (contentList.length === 0) {
                return '';
            }

            const arr = contentList.map((contentItem) => {
                return this.formItemTypeNameArr[contentItem.type] || '';
            });
            const dedupeArr = Array.from(new Set(arr));

            return dedupeArr.join(',');
        },
        // 获取抽奖活动id
        async getLotteryId() {
            const that = this,
                formId = manageMode
                    ? this.module.content.f_id
                    : (this.formInfo && this.formInfo.id) || this.module.content.f_id,
                postData = {
                    formId,
                    kkk: jm.toJSON([1, 2, 3]),
                };
            let associateLottery = manageMode
                ? this.module.content.fi && this.module.content.fi.associateLottery
                : (this.formInfo && this.formInfo.associateLottery != undefined) ||
                  (this.module.content.fi && this.module.content.fi.associateLottery);
            // 关联抽奖的才需要请求
            if (!this.inMemberDetailEdit && associateLottery) {
                let result = await getFormLotteryByFormIdApi(postData);
                let { success, msg, data } = result;
                if (success) {
                    that.module.lotteryId = data.info.id;
                    that.module.lotteryName = data.info.name;
                    that.module.lotteryOpen = data.info.flag;
                    that.module.allowLottery = data.lotterySettingInfo.allowLottery;
                    that.module.isOpenLottery = data.lotterySettingInfo.isOpenLottery;
                    if (
                        data.info.flag &&
                        data.lotterySettingInfo.allowLottery &&
                        data.lotterySettingInfo.isOpenLottery
                    ) {
                        jm(`#module${that.module.id}`).find('.lottery-need-open').css('visibility', 'visible');
                    }
                } else {
                    console.log(msg);
                }
            }
        },
        formulaCalculatePrice() {
            if (this.payType == 0 || !this.formulaData.formula) {
                return;
            }
            let result = 0;
            if (this.formulaData.variableList.length) {
                let variableIdValue = this.variableIdValue,
                    allSelect = true,
                    _this = this,
                    tmpcalcStr = this.formulaData.formula,
                    notFoundVariable = false;

                for (var key in variableIdValue) {
                    if (variableIdValue[key] === '') {
                        allSelect = false;
                        break;
                    }
                }
                //如果公司内没用变量的话，这个也是true，显示公式编辑器的常量
                if (allSelect) {
                    tmpcalcStr = tmpcalcStr.replace(/prop(\d+)/g, (...args) => {
                        let propString = args[0];
                        let choiceIndex = variableIdValue[propString];
                        let itemId = propString.replace('prop', '');
                        let selectValList = _this.vSelectListParam[itemId];
                        let variableVal = '';

                        if (selectValList && selectValList.length) {
                            if (_this.formItemIsShow[itemId]) {
                                variableVal = selectValList[choiceIndex];
                            } else {
                                variableVal = 0;
                            }
                        } else {
                            const formItem = this.resultItems.find((item) => item.formItem.id === choiceIndex);
                            variableVal = formItem.currentValue;
                        }

                        if (variableVal !== '') {
                            return variableVal;
                        } else {
                            notFoundVariable = true;
                            return 0;
                        }
                    });
                    if (notFoundVariable) {
                        ing('价格计算有误，请联系管理员');
                        return;
                    }
                    //计算结果
                    let calculateResult = jm.fkEval(tmpcalcStr);
                    if (calculateResult) {
                        result = calculateResult;
                    }
                }
            } else {
                let calculateResult = jm.fkEval(this.formulaData.formula);
                if (calculateResult) {
                    result = calculateResult;
                }
            }

            this.calculatePrice = this.formatMoney(result);
        },
        formatMoney(number) {
            //保留2位小数
            //处理千分位分隔符
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = 2,
                s = '';

            function toFixedFix(n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k, 10) / k;
            }
            s = toFixedFix(n, prec);
            return Number(s);
        },
        addForm() {
            let afterOpenFn = null;
            if (this.styleId === 13) {
                afterOpenFn = this.$designer.open({
                    panelType: 'pattern form basis',
                    styleId: this.styleId,
                    moduleId: this.id,
                });
            } else if (this.isPopupForm) {
                afterOpenFn = this.$designer.open({
                    panelType: 'mobiFormPopup',
                    styleId: this.styleId,
                    moduleId: this.id,
                });
            }
            // 渲染表单悬浮弹窗
            if (afterOpenFn && afterOpenFn.then) {
                afterOpenFn.then(() => {
                    FormPanel.render({
                        styleId: this.styleId,
                        moduleId: this.id,
                    });
                });
            }
        },
        editItem(evt, item) {
            if (manageMode) {
                const notEditTagAry = [];
                if (notEditTagAry.includes(evt.target.tagName.toLocaleLowerCase())) {
                    return;
                }
                let panelType = '';

                this.$store.commit('manage/updateCurFormItemEdit', {
                    formId: this.formInfo.id,
                    itemId: item.id,
                    moduleId: this.id,
                    styleId: this.styleId,
                });

                switch (item.type) {
                    case 0:
                        panelType = 'JzFormEditInput';
                        break;
                    case 1:
                        panelType = 'JzFormEditTextArea';
                        break;
                    case 2:
                        panelType = 'JzFormEditRadio';
                        break;
                    case 3:
                        panelType = 'JzFormEditCheckbox';
                        break;
                    case 4:
                        panelType = 'JzFormEditSelect';
                        break;
                    case 5:
                        panelType = 'JzFormEditTextDescription';
                        break;
                    case 6:
                        panelType = 'JzFormEditDate';
                        break;
                    case 7:
                        panelType = 'JzFormEditUpload';
                        break;
                    case 8:
                        panelType = 'JzFormEditPhone';
                        break;
                    case 9:
                        panelType = 'JzFormEditEmail';
                        break;
                    case 10:
                        panelType = 'JzFormEditIdentify';
                        break;
                    case 11:
                        panelType = 'JzFormEditCity';
                        break;
                    case 12:
                        panelType = 'JzFormEditMultiDropdown';
                        break;
                    case 13:
                        panelType = 'JzFormEditNumber';
                        break;
                    case 14:
                        panelType = 'JzFormEditSinglePhoto';
                        break;
                    case 15:
                        panelType = 'JzFormEditMultiplePhoto';
                        break;
                }
                if (panelType) {
                    this.$designer.open({
                        styleId: this.styleId,
                        panelType,
                        isFormPanel: true,
                    });
                }

                logJZFdp('jz_form_item_edit', {
                    jz_version: Fai.top.jzVersion,
                    jz_content_terminal: 'mobi',
                    jz_free_text_0: '编辑',
                    jz_free_text1: [
                        '单行文本',
                        '多行文本',
                        '单选按钮',
                        '多选按钮',
                        '下拉选项',
                        '文本说明',
                        '日期选项',
                        '文件上传',
                        '手机号',
                        '邮箱验证',
                        '身份验证',
                        '省市县区',
                        '多级下拉',
                        '数字',
                        '图片单选',
                        '图片多选',
                    ][item.type],
                });
                jm('.jz_mobi_form .item_editing').removeClass('item_editing');
                jm(evt.currentTarget).addClass('item_editing');

                logDog(200914, 40);
            }
        },
        openFunEdit(isOpenPay) {
            if (manageMode) {
                this.$store.commit('manage/updateFormFunEdit', {
                    formId: this.module.content.f_id,
                    moduleId: this.module.id,
                });
                this.$designer.open({
                    styleId: this.styleId,
                    panelType: 'JzFormEditFun',
                    isFormPanel: true,
                });
                setTimeout(() => {
                    if (isOpenPay) {
                        jm('.J_head_tab_高级设置').click();
                    }
                }, 300);
            }
        },
        editItemEnd() {
            if (manageMode) {
                jm('.jz_mobi_form .item_editing').removeClass('item_editing');
            }
        },
        logClueSubmitForm(clueContentList, form) {
            let sourceModule = null;
            const { clueKey, logClueSubmitFormSourceContent } = Clue;
            switch (window._colId) {
                case COL.SYS_INDEX:
                    sourceModule = clueKey.INDEX;
                    break;
                case COL.SYS_PRODUCT_RESULT:
                    sourceModule = clueKey.PRODUCT_RESULT;
                    break;
                default:
                    break;
            }
            clueReuqestVm.changeSourceContentValue(clueKey.SUBMIT_FORM, {
                expand_content: {
                    infoExtend: clueContentList,
                },
            });
            clueReuqestVm?.changeSourceContentDataId(clueKey?.SUBMIT_FORM, form?.id || 0);
            if (sourceModule) {
                clueReuqestVm.logClueSourceContent(sourceModule, clueKey.SUBMIT_FORM);
            } else {
                logClueSubmitFormSourceContent();
            }
        },

        async submitForm() {
            const lotteryId = this.module.lotteryId,
                lotteryOpen = this.module.lotteryOpen,
                allowLottery = this.module.allowLottery,
                isOpenLottery = this.module.isOpenLottery,
                phoneNumber = this.module.content.fi.phoneNumber,
                associateLottery = this.module.content.fi && this.module.content.fi.associateLottery;

            const addFinish = Monitor.startMonitor(MonitorDef.MonitorId.SUBMIT_FORM);

            const isInquiryProduct =
                window.$store.state.app.openMultiPdInquiry && this.isPopupForm && FormPopup.fromBtn;

            let jumpToUrl = '';

            if (this.submitting) {
                return;
            }

            if (manageMode) {
                ing('当前为管理状态，提交无效');
                return;
            }

            if (this.permissionWarning.length > 0) {
                ing(this.permissionWarning);
                return;
            }

            // 表单支付不支持PC端
            if (this.isPc() && this.isOpenPay && jzPayUtils.isWeChatPay() && !isInquiryProduct) {
                ing('请在手机上访问和提交支付');
                return;
            }

            // 聚合支付只能在微信浏览器微信支付，其他均提示
            if (this.isOpenPay && jzPayUtils.isFaiWeChatPay() && !jzPayUtils.isWechat() && !isInquiryProduct) {
                ing('非微信环境不支持支付，请在微信浏览器下进行操作');
                return;
            }

            // 若无支付权限，则不应该校验其权限
            if (this.payType == 1 && this.calculatePrice <= 0 && this.isOpenPay && !isInquiryProduct) {
                if (!this.validateNeedSelect()) {
                    return;
                }
                if (this.calculatePrice < 0) {
                    ing(this.LS.paymentAmountCalcError || '表单支付金额计算有误，请联系管理员');
                    return;
                }
            }

            if (this.module.extInfo.isJuly3FreeUser) {
                logDog(200964, 9);
                ing('免费版暂不支持此功能');
                return;
            }

            if (this.module.content.lt == 1) {
                jumpToUrl = this.module.extInfo.href;
            }

            if (!this.validateSuccess()) {
                return;
            } // 验证失败

            // 访客态没有开启验证码时开启防刷
            if (!manageMode && this.module.extInfo.validation && !this.validation) {
                return;
            }

            let submitInfoList = [];
            let fileList = [];
            let validateCode = '';
            let phoneValidateCodes = []; // 手机是否开启验证码
            const clueContentList = [];
            const resultItems = this.resultItems;
            this.resultItems.forEach((content) => {
                if (content.formItem.type === -1) {
                    validateCode = content.currentValue;
                    return;
                }

                // 文本说明则跳过
                if (content.formItem.type == 5) {
                    return;
                }

                submitInfoList.push({
                    id: content.formItem.id,
                    type: content.formItem.type,
                    val: Array.isArray(content.submitVal) ? jm.toJSON(content.submitVal) : content.submitVal,
                });
                const clueItem = {
                    type: 1,
                    value: content.submitVal,
                    key: content.formItem.name,
                };

                if (content.formItem.type == 8 && content.formItem.smsVerify && Fai.top._isOpenPhoneSms) {
                    // 获取手机验证码
                    phoneValidateCodes.push({
                        id: content.formItem.id,
                        phoneValidateCode: content.msgCode,
                    });
                }

                if (content.file && content.file.fileId !== '') {
                    fileList.push(content.file);
                    clueItem.value = content.file.tmpFileName;
                }
                if (content?.tmpFileList?.length) {
                    fileList = fileList.concat(content?.tmpFileList);
                    const clueArr = [];
                    content?.tmpFileList?.forEach(({ tmpFileName }) => {
                        clueArr.push(tmpFileName);
                    });
                    clueItem.value = clueArr;
                }
                clueContentList.push(clueItem);
            });

            if (submitInfoList.length === 0) {
                ing('无可提交的表单项');
                return;
            }

            // 登录逻辑应在表单验证之后
            const needLogin = this.formInfo.other.fas.fvmt !== 0 && !this.isLogin;
            if (needLogin) {
                ing('登录后才能提交');
                // 纠正 extInfo.loginReturnUrl 中的returnUrl 参数
                let url = `${location.href}`;
                let tempData = this.module.extInfo.loginReturnUrl.split('?');
                if (tempData[1]) {
                    let tempURLSearchParam = new URLSearchParams(tempData[1]);
                    tempURLSearchParam.set('returnUrl', url);
                    tempData[1] = tempURLSearchParam.toString();
                }
                let returnUrl = tempData.join('?');

                if (lotteryId) {
                    let loginReturnUrl = returnUrl + `&lotteryId=${lotteryId}&phoneNumber=${phoneNumber}`;
                    changeHref(loginReturnUrl);
                    return;
                }
                changeHref(returnUrl);
                return;
            }

            // 最后才校验开启了表单协议的是否点击了同意
            if (this.isOpenFormProtocol && this.allowFormProtocol && !this.isAgree) {
                ing(`${LS.read_and_agree}${this.formProtocolTitle}`);
                return;
            }

            // 这里才是提交交互逻辑
            let pageUrl = document.location.href;
            if (pageUrl.length > 500) {
                pageUrl = pageUrl.substring(0, 500);
            }
            if (Fai.top.pageName.endsWith(' - ')) {
                Fai.top.pageName = Fai.top.pageName.substring(0, Fai.top.pageName.lastIndexOf(' - '));
            }

            const InquiryProductName =
                this.module.extInfo.inquiryProductList
                    ?.map((inquiryProduct) => `产品${inquiryProduct.name}`)
                    .join('、') ?? [];

            let submitOrigin = {
                pageUrl: pageUrl,
                pageName: `手机网站-${isInquiryProduct ? InquiryProductName : Fai.top.pageName}`,
            };

            if (isInquiryProduct) {
                const inquiryProductList = JSON.parse(localStorage.getItem('inquiry_product_list') || '[]');
                submitOrigin.pdIdList = inquiryProductList;
                logJZFdp('jz_products_inquiry_use', {
                    jz_content_terminal: 'mobi',
                    jz_free_int1: inquiryProductList.length,
                });
            }

            // 如果是会员中心编辑表单，则需弹出提示框
            let confirmSubmitModal = () => {
                return new Promise((resolve) => {
                    this.$modal.confirm({
                        title: window.LS.confirmEditFormResult,
                        content: window.LS.coverOldFormResult,
                        confirmButtonText: window.LS.confirm,
                        cancelButtonText: window.LS.cancel,
                        onConfirm() {
                            resolve(true);
                        },
                        onCancel() {
                            resolve(false);
                        },
                    });
                });
            };
            if (this.inMemberDetailEdit) {
                const confirmSuccess = await confirmSubmitModal();
                if (confirmSuccess === false) {
                    return;
                }
            }

            let postData = {
                formId: this.formInfo.id,
                vCodeId: `${this.module.id}${this.formInfo.id}`,
                submitContentList: jm.toJSON(submitInfoList),
                tmpFileList: jm.toJSON(fileList),
                validateCode: validateCode,
                colId: Fai.top._pageId,
                submitOrigin: jm.toJSON(submitOrigin),
                phoneValidateCodes: jm.toJSON(phoneValidateCodes),
            };

            if (this.inMemberDetailEdit && this.editingSubmitResultId !== -1) {
                postData.id = this.editingSubmitResultId;
            }

            this.submitting = true;
            this.submittingTip = LS.mobiFormSubmitTip;

            const submitApi = this.inMemberDetailEdit ? memberModifySubmit : addSubmitApi;
            let result = await submitApi(postData);

            let { hasFW, success, msg, rt, id, needCode } = result || {};
            if (success) {
                this.logClueSubmitForm(clueContentList, this.formInfo);
                this.logFormSubmitByJz(this.transformFormItemText(submitInfoList));

                this.$emit('submit-success');

                const price = this.payType == 0 ? this.formInfo.price : this.calculatePrice;
                if (this.isOpenPay && price > 0 && !isInquiryProduct) {
                    logDog(201217, 1);
                    this.payAction(result);
                    addFinish(true);
                    return;
                }

                let SubmitOktips = encodeHtml(this.formInfo.other.submitSuccessTips);

                if (this.module.extInfo.openFormRemarkSubmitOk) {
                    SubmitOktips = this.module.extInfo.openFormRemarkSubmitOk;
                }

                if (this.inMemberDetailEdit) {
                    SubmitOktips = LS.submitSuccess;
                }

                // 活动开启：抽奖表单且需要会员登录
                const autoClose = 1000;
                if (lotteryOpen && allowLottery && isOpenLottery) {
                    let allowForm = true;
                    if (lotteryId && this.module.extInfo.memberId === 0) {
                        await this.submitOktipsAction(SubmitOktips);
                        setTimeout(() => {
                            let loginReturnUrl =
                                location.origin +
                                location.pathname +
                                `&lotteryId=${lotteryId}&phoneNumber=${phoneNumber}`;
                            lottery.getLotteryInfo(lotteryId, phoneNumber, loginReturnUrl, allowForm);
                        }, autoClose);
                    } else if (lotteryId) {
                        await this.submitOktipsAction(SubmitOktips);
                        setTimeout(() => {
                            lottery.getLotteryInfo(lotteryId, phoneNumber, null, allowForm);
                        }, autoClose);
                    }
                } else {
                    await this.submitOktipsAction(SubmitOktips);
                }

                if (this.isPopupForm) {
                    FormPopup.destroy();
                }

                this.formItems.forEach((item) => item.resetValue && item.resetValue());
                if (isInquiryProduct) {
                    localStorage.setItem('inquiry_product_list', '[]');
                }
                if (jumpToUrl && !this.inMemberDetailEdit) {
                    // 关联抽奖的表单一律不允许跳转
                    setTimeout(() => {
                        if (!associateLottery) {
                            if (this.module.extInfo.reqArgs && getIsOpenRemoveUrlArg()) {
                                setUrlArgToCookieAndJump(jumpToUrl, this.module.extInfo.reqArgs);
                            } else {
                                changeHref(jumpToUrl);
                            }
                        }
                    }, 2500);
                }
                if (this.validateVm.refreshValidateCode) {
                    this.validateVm.refreshValidateCode();
                }

                if (price !== 0) {
                    this.ruleMap = {}; // 置空
                    this.variableIdValue = {};
                    this.calculatePrice = 0;
                    this.removeFailRule();
                    this.initRuleFormItemList();
                    addFinish(true);
                }
            } else {
                this.$emit('submit-error');
                if (hasFW) {
                    ing(msg);
                    return;
                }
                if (rt == -4) {
                    ing(LS.siteFormSubmitCountLimit);
                } else if (rt == -8) {
                    ing(msg);
                } else if (rt == -20) {
                    ing(msg);
                } else if (rt == -28) {
                    ing(LS.hasSubmitThisSiteForm);
                } else if (rt == -401) {
                    ing(LS.siteFormSubmitValidateCodeErr);
                    this.validateVm.currentValue = '';
                    this.validateVm.refreshValidateCode();
                } else if (rt == -302) {
                    ing(LS.siteFormSubmitNotLogin + LS.login + LS.period);
                } else if (rt == -1002) {
                    ing(LS.phoneValidateCodes);
                } else if (rt == -1003) {
                    ing(LS.phoneValidateCode);
                } else if (rt == -301) {
                    ing(LS.formErrorCode);
                } else if (rt == -2) {
                    const notAllowItem = resultItems.find((i) => i.formItem.id == id) || {};
                    ing(`${notAllowItem?.formItem?.name || ''}：${msg}`);
                } else if (rt == -1000) {
                    ing(LS.paymentNotOpen || '支付功能未开启，请联系管理员', 0);
                } else if (rt == -303 || rt == -304 || rt == -306) {
                    ing(msg);
                } else if (rt === -307 || rt === -308) {
                    ing(this.permissionWarning);
                } else if (rt != null || msg != null) {
                    ing(msg);
                } else {
                    addFinish(false); // 执行异常
                    ing(LS.js_systemError, 2);
                }
                if (needCode) {
                    ing(msg);
                    this.needCode = true;
                }
            }

            if (this.hasResidue() && !this.inMemberDetailEdit) {
                let { data: formContentListDataResult } = await jzRequest.get(
                    '/ajax/mobiForm_h.jsp?cmd=getWafNotCk_getFormContentListById',
                    {
                        params: {
                            id: this.formInfo.id,
                        },
                    }
                );

                if (formContentListDataResult.success) {
                    this.formInfo.contentList = formContentListDataResult.contentList;
                }
            }

            this.submittingTip = '';
            this.submitting = false;
            this.initFormIdListen();
            this.initFormContentList();
        },
        hasResidue() {
            let contentList = this.formInfo.contentList || [];
            return contentList.some((item) => {
                return item.ol && item.nl.length && this.module.extInfo.allowedFormNumLimit;
            });
        },
        validateSuccess() {
            const defaultValidate = () => true;
            return this.resultItems.every((formItemComponent) => {
                return (formItemComponent.validate ?? defaultValidate)();
            });
        },
        validateNeedSelect() {
            let success = true;
            let _this = this;
            for (let i = 0; i < this.resultItems.length; i++) {
                let item = this.resultItems[i];
                let itemId = item.formItem.id;
                let propString = 'prop' + itemId;
                if (propString in _this.variableIdValue) {
                    if (!(item.validate && item.validate(true))) {
                        success = false;
                        break;
                    }
                }
            }
            return success;
        },

        saveNotRightTempList() {
            let msgTemplateList = this.module.extInfo.SmsTmplateInfo.smsTemplateList || [];
            // 将短信模板数据放到全局变量中，mobiFormTemplateList
            let tempArr = msgTemplateList
                .filter((item) => {
                    return item.template.indexOf('#code#') != -1;
                })
                .map((item) => item.key);
            let allTemplateList = msgTemplateList.map((item) => {
                return item.key;
            });
            Fai.top._mobiFormTemplateList = tempArr;
            Fai.top._mobiFormAllTemplate = allTemplateList;
        },
        initInputFontSetting() {
            var word = this.advance.if;
            var color = word.nfct == 1 ? word.nfc : '';
            var fontSize = word.nfs + 'rem';
            var id = this.id;
            setModuleStyleCssList(id, [
                {
                    cls: '.m_mobi_form_input_text::-webkit-input-placeholder',
                    key: 'color',
                    value: color,
                },
                {
                    cls: '.m_mobi_form_input_text:-moz-placeholder',
                    key: 'color',
                    value: color,
                },
                {
                    cls: '.m_mobi_form_input_text::-moz-placeholder',
                    key: 'color',
                    value: color,
                },
                {
                    cls: '.m_mobi_form_input_text::-ms-input-placeholder',
                    key: 'color',
                    value: color,
                },
                { cls: '.m_mobi_form_input_text', key: 'color', value: color },
                {
                    cls: '.m_mobi_form_input_text',
                    key: 'font-size',
                    value: fontSize,
                },
            ]);
        },

        // 选项改变事件
        handleSelectChange(val) {
            let selectItemInfo = val.formItem;
            let seletValue = val.value;
            let contentId = selectItemInfo.id;
            let inputArr = val.formItem.input.split('\n');
            let type = val.formItem.type;
            let self = this;
            this.resetRuleMapData(contentId);
            if (type == 3) {
                // 多选框要注意
                seletValue.forEach((item) => {
                    if (inputArr.indexOf(item) != -1) {
                        if (self.ruleMap[contentId] != null) {
                            self.ruleMap[contentId].selected.push(inputArr.indexOf(item));
                        }
                    }
                });
            } else if (type == 13) {
                // this.ruleMap[contentId].selected = contentId
            } else {
                if (this.ruleMap[contentId] != null) {
                    this.ruleMap[contentId].selected = inputArr.indexOf(seletValue);
                }
            }

            this.initFormContentList();
            if (type == 2 || type == 4 || type == 13) {
                //记录选择选项的位置
                if (this.variableIdValue['prop' + contentId] != undefined) {
                    let selectValList = this.vSelectListParam[contentId];

                    let choiceIndex = inputArr.indexOf(seletValue);

                    this.variableIdValue['prop' + contentId] = selectValList.length === 0 ? contentId : choiceIndex;
                }

                this.formulaCalculatePrice();
            }
        },

        // 重新设置 ruleMap 相关 contentId 对应下的 showList 项
        resetRuleMapData(contentId, isClear) {
            let mapItem = this.ruleMap[contentId];
            if (mapItem != null) {
                let selected = mapItem.selected;
                let self = this;

                if (selected instanceof Array) {
                    // 数组（多选）
                    selected.forEach((select) => {
                        let findResult = this.formRules.find(
                            (item) => item.contentId == contentId && item.selected == select
                        );
                        if (findResult != null && findResult.showList != null && findResult.showList.length > 0) {
                            findResult.showList.forEach((showItem) => {
                                self.resetRuleMapData(showItem.key, true);
                            });
                        }
                    });
                    this.ruleMap[contentId].selected = [];
                } else {
                    // 单选、下拉
                    let findResult = this.formRules.find(
                        (item) => item.contentId == contentId && item.selected == selected
                    );
                    if (findResult != null) {
                        // 递归 showList，将其设置为 默认值
                        findResult.showList.forEach((showItem) => {
                            self.resetRuleMapData(showItem.key, true);
                        });
                    }
                    this.ruleMap[contentId].selected = -1;
                }

                if (isClear) {
                    // 清空当前选中的项
                    let findResult = this.formItems.find((formInfoItem) => {
                        return formInfoItem.formItem.id == contentId;
                    });
                    if (findResult != null) {
                        findResult.currentValue instanceof Array
                            ? (findResult.currentValue = [])
                            : (findResult.currentValue = '');
                    }
                }
            }
        },

        // 初始化 ruleMap，即规则项
        initRuleFormItemList() {
            this.formRules.forEach((item) => {
                if (this.ruleMap[item.contentId] == null) {
                    this.ruleMap[item.contentId] = {
                        contentId: item.contentId,
                        type: item.type,
                        selected: item.type == 3 ? [] : -1,
                    };
                }
            });
        },

        // 初始化展示的表单项
        initFormContentList() {
            let self = this;
            if (this.formInfo != null) {
                let selectList = this.getSelectRuleList();
                this.formInfo.contentList.forEach((item) => {
                    let isRule = self.formRuleAllSelectList.indexOf(item.id) != -1;
                    if (isRule) {
                        let index = selectList.indexOf(item.id);
                        self.formItemIsShow[item.id] = index != -1 ? true : false;
                    } else {
                        self.formItemIsShow[item.id] = true;
                    }
                });

                this.updateVariableValue();
            }
        },

        // 更新动态计算的变量值（由于加入了规则，需要进行必要的规则判断）
        updateVariableValue() {
            let id = 0;
            let hasIdVal = false;
            let self = this;
            this.formInfo.contentList.forEach((item) => {
                id = item.id;
                // 当选项原先被选中但现在被隐藏的情况，去掉该选项
                hasIdVal = self.variableIdValue[`prop${id}`] != null && self.variableIdValue[`prop${id}`] !== '';
                if (!self.formItemIsShow[id] && hasIdVal) {
                    self.variableIdValue[`prop${id}`] = '';
                }
            });
        },

        // 初始化表单项展示字段监听
        initFormIdListen() {
            if (this.formInfo != null) {
                this.formInfo.contentList.forEach((item) => {
                    if (this.formItemIsShow[item.id] == null) {
                        this.$set(this.formItemIsShow, item.id, true);
                    }
                });
            }
        },

        // 获取选择的规则展示表单项
        getSelectRuleList() {
            let selectIdList = [];
            let self = this;
            let keys = Object.keys(this.ruleMap);
            keys.forEach((key) => {
                let mapItem = this.ruleMap[key];
                if (mapItem.type == 3) {
                    mapItem.selected.forEach((select) => {
                        let findResult = self.formRules.find((rule) => {
                            return rule.contentId == mapItem.contentId && rule.selected == select;
                        });
                        if (findResult != null) {
                            selectIdList.push(...findResult.showList.map((item) => item.key));
                        }
                    });
                } else {
                    let findResult = self.formRules.find((rule) => {
                        return rule.contentId == mapItem.contentId && rule.selected == mapItem.selected;
                    });
                    if (findResult != null) {
                        selectIdList.push(...findResult.showList.map((item) => item.key));
                    }
                }
            });
            return selectIdList;
        },

        // 移除失效的规则
        removeFailRule() {
            let self = this;
            this.formRules.forEach((item) => {
                let { contentId, showList } = item;
                let mainIndex = self.formItemIdList.indexOf(contentId);

                item.showList = showList.filter((item) => {
                    return self.formItemIdList.indexOf(item.key) > mainIndex;
                });
            });
        },
        payAction(result) {
            // 此次提交表单ID
            const { id: bssId } = result;
            const bssType = this.module.extInfo.bssType;
            const formId = this.formInfo.id;

            // 是否关联抽奖
            const associateLottery = this.module.content.fi && this.module.content.fi.associateLottery;

            // 处理URL参数
            const curUrlSearch = window.location.search;
            const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            const { isPopupForm } = this;

            const locationHref = window.location.href;
            const origin = locationHref.split('?')[0];

            // 拼接成功URL
            const sucUrlParams = new URLSearchParams(curUrlSearch),
                lotteryId = this.module.lotteryId,
                phoneNumber = this.module.content.fi.phoneNumber || '';

            if (lotteryId && associateLottery) {
                localStorage.setItem('pay_lotteryId', lotteryId);
                localStorage.setItem('pay_phoneNumber', phoneNumber);
                sucUrlParams.set('_lotteryId', lotteryId);
                sucUrlParams.set('_phoneNumber', phoneNumber);
            }

            sucUrlParams.delete('openId');

            // 支付状态 1： 成功
            sucUrlParams.set('payStatus', '1');
            // 当前页面滚动条位置
            sucUrlParams.set('scrollTop', scrollTop);

            sucUrlParams.set('isPopupForm', isPopupForm);
            sucUrlParams.set('formId', formId);
            sucUrlParams.set('formModuleId', this.module.id);
            sucUrlParams.set('bssId', bssId);

            // 这里必须编码，否则服务器会截断URL
            const sucUrl = encodeURIComponent(`${origin}?${sucUrlParams}`);

            // 拼接失败URL
            const failUrlParams = new URLSearchParams(curUrlSearch);

            failUrlParams.delete('openId');
            // 支付状态 0: 失败
            failUrlParams.set('payStatus', '0');
            // 当前页面滚动条位置
            failUrlParams.set('scrollTop', scrollTop);

            failUrlParams.set('isPopupForm', isPopupForm);

            failUrlParams.set('formId', formId);

            // 这里必须编码，否则服务器会截断URL
            const failUrl = encodeURIComponent(`${origin}?${failUrlParams}`);

            // 跳转前设置flag到localStorage
            window.sessionStorage.setItem('from_mobi_pay', 'true');
            logDog(201217, 2);
            // 存储cookie，用于支付宝跳转回原来的页面（清除之前相应的cookie）

            const cookieKey = `payReturnUrl_${bssType}_${bssId}`;
            let pathArr = location.pathname.split('/').filter((item) => item != window._curLanCode);
            const cookieVal = `/${pathArr.join('/')}?${sucUrlParams}`;
            jm.cookie(cookieKey, cookieVal, { path: '/', expires: 1 });
            let mallPaymentUrl = `/mallPayment.jsp?id=${formId}&bssId=${bssId}&bssType=${bssType}&returnUrl=${failUrl}&sucUrl=${sucUrl}`;
            if (Fai.top._curLanCode !== 'cn' || (!Fai.top.defaultCn && Fai.top._curLanCode === 'cn')) {
                mallPaymentUrl = `/${Fai.top._curLanCode}${mallPaymentUrl}`;
            }
            changeHref(mallPaymentUrl);
        },
        splitNumberWithDot(number) {
            if (typeof number !== 'number') {
                throw new Error('请传入数字类型参数');
            }

            // 返回值为数组 [a, b] a为整数部分 b为小数部分，这部分对自动补0
            let numStr = number.toString();

            // 划分为整数部分和小数部分  小数部分不存在时值为""，此时对其进行自动补零即可
            let [iPart, fPart = ''] = numStr.split('.');

            // 小数指定长度
            const DEFAULT_FLOAT_LENGTH = 2;

            const fPartLength = fPart.length;

            let paddingLength = DEFAULT_FLOAT_LENGTH - fPartLength;
            // 补零
            while (paddingLength--) {
                fPart += '0';
            }
            // 有序返回
            return [iPart, fPart];
        },
        jumpToSet() {
            logDog(201364, 4);
            showTopManageFrame('manageFrameSitePaySetting', Fai.top.backUrl, Fai.top.lanCode);
        },
        jumptoOpen() {
            logDog(201364, 5);
            this.$store.commit('manage/updateFormFunEdit', {
                formId: this.module.content.f_id,
                moduleId: this.module.id,
            });

            this.$designer.open({
                styleId: this.styleId,
                panelType: 'JzFormEditFun',
                isFormPanel: true,
            });
        },
        initVariableIdValue() {
            var variableIdValue = {};
            var formula = this.formulaData.formula;
            if (!formula || this.payType == 0) {
                return;
            }
            formula = formula.replace(/prop(\d+)/g, (...args) => {
                variableIdValue[args[0]] = '';
                return args[0];
            });
            this.variableIdValue = variableIdValue;
        },
        isPc() {
            return browser.isPc();
        },
        async previewFormProtocol() {
            let {
                data: {
                    protocolDetail: { formProtocolContent, formProtocolTitle },
                },
            } = await getFormProtocolDetail({
                formId: this.formInfo.id,
            });

            const { default: formProtocol } = await import('./components/form-protocol.vue');
            jm("<div id='formProtocol'></div>").appendTo('#g_body');

            new Vue({
                components: {
                    'form-protocol': formProtocol,
                },
                data() {
                    return {
                        formProtocolContent,
                        formProtocolTitle,
                    };
                },
                methods: {
                    close() {
                        this.$destroy();
                        $(`#formProtocol`).remove();
                    },
                },
                template: `<form-protocol :content="formProtocolContent" :contentTitle="formProtocolTitle" :close="close"></form-protocol>`,
            }).$mount('#formProtocol');
        },
        async submitOktipsAction(SubmitOktips) {
            return new Promise((resolve) => {
                let submitSuccessTipCustom = this.formInfo.submitSuccessTipCustom;
                let popupContent = this.formInfo.popupContent;
                if (!submitSuccessTipCustom) {
                    ing(SubmitOktips, 1, false);
                    resolve();
                } else {
                    //新弹窗
                    logJZFdp('jz_form_success_custom_popup_c', {
                        jz_version: Fai.top.jzVersion,
                        jz_content_terminal: 'mobi',
                    });
                    jm("<div id='formSubmitSuccessTipCustom'></div>").appendTo('#g_body');

                    new Vue({
                        components: {
                            'form-submit-success-tip-custom': formSubmitSuccessTipCustom,
                        },
                        data() {
                            return {
                                popupContent,
                            };
                        },
                        methods: {
                            close() {
                                this.$destroy();
                                $(`#formSubmitSuccessTipCustom`).remove();
                                resolve();
                            },
                        },
                        template: `<form-submit-success-tip-custom :content="popupContent" :close="close"></form-submit-success-tip-custom>`,
                    }).$mount('#formSubmitSuccessTipCustom');
                }
            });
        },
    },
};
</script>

<style lang="scss">
.jz_mobi_form {
    padding: 0.75rem;
    font-size: 0.6rem;
}
.jz_mobi_form .form_name {
    text-align: center;
    margin-bottom: 0.6rem;
}
.jz_mobi_form .item_wrap {
    margin-top: 0.776rem;
}
.jz_mobi_form.manageMode .item_wrap:hover,
.jz_mobi_form.manageMode .pay_wrap:hover {
    outline: 1px dashed rgb(153, 153, 153);
}
.jz_mobi_form .item_wrap:first-child {
    margin-top: 0 !important;
}
.jz_mobi_form .submit_btn {
    margin-top: 1.425rem;
    height: 2.125rem;
    border-radius: 4px;
    text-align: center;
    line-height: 2.25rem;
    color: #fff;
    font-size: 0.7rem;
}
.jz_mobi_form.manageMode .form_items_wrap .item_editing {
    outline: 1px dashed rgba(89, 116, 213, 1);
}
.jz_mobi_form .choice_box_wrap {
    overflow: hidden;
    margin-top: -0.345rem;
}
.jz_mobi_form .choice_box {
    margin-top: 0.35rem;
    float: left;
}
.jz_mobi_form .form_item_empty {
    height: 6.9rem;
    line-height: 6.9rem;
    text-align: center;
    width: 100%;
    color: #666;
    font-size: 0.6rem;
}
.m_mobi_form_input_text {
    padding: 0 0.725rem;
    width: 100%;
    height: 1.9rem;
    line-height: 1.9rem;
    border: 0.025rem solid rgba(227, 226, 232, 1);
    font: inherit;
    box-sizing: border-box;
    border-radius: 0.086rem;
    background-color: #fff;
    -webkit-appearance: none;
}
.jz_mobi_form .choice_box .active {
    border: none;
}
.mobi_form_style_1 .m_mobi_form_input_text {
    border: none;
    outline: none;
    background: rgba(246, 246, 246, 1);
}
.mobi_form_style_1 .m_mobi_form_input_text:focus {
    outline: none;
}
.mobi_form_style_2 .m_mobi_form_input_text {
    outline: none;
    background: #fff;
}
.mobi_form_style_2 .m_mobi_form_input_text:focus {
    border: 1px solid rgba(227, 226, 232, 1);
}
.mobi_form_style_3 .m_mobi_form_input_text {
    padding: 0;
    outline: none;
    border: none;
    text-align: left;
}
.mobi_form_style_3 .m_mobi_form_date .m_mobi_form_input_text,
.mobi_form_style_3 .m_mobi_form_date .m_mobi_form_input_text {
    border-bottom: 1px solid #eeeeee;
}
.mobi_form_style_3 .choice_box_wrap:not(.form_protocol_agree) {
    margin-top: 0;
}
.mobi_form_style_3 .m_mobi_form_select {
    background: #fff;
}
.mobi_form_style_3 .m_mobi_form_input_text {
    height: 2rem;
    line-height: 2rem;
}
.mobi_form_style_3 .m_mobi_form_upload_wrap .uploadify_button_decorate {
    text-indent: 1.466rem;
}
.form_validate_refresh {
    display: none;
}

/* 解决 border:1px 粗细不一或者看不到问题*/
.mobi_form_style_3 .form_item_content {
    --border-color: rgb(238, 238, 239);
}
.mobi_form_style_3 .form_item_content:after {
    content: ' ';
    display: block;
    border-style: none;
    margin: 1px 0;
    font-size: 0px;
    height: 1px;
    line-height: 1px;
    border-width: 1px;
    height: 1px;
    background: var(--border-color, rgb(238, 238, 239));
}
.mobi_form_style_3 .file_upload_style2 .form_item_wrap_7 .form_item_content:after {
    display: none;
}

.mobi_form_style_3 .form_item_wrap_2 .form_item_content::after,
.mobi_form_style_3 .form_item_wrap_3 .form_item_content::after,
.mobi_form_style_3 .form_item_wrap_6 .form_item_content::after,
.mobi_form_style_3 .form_item_wrap_5 .form_item_content::after {
    display: none;
}

/* 输入框样式 */
/* 调整输入框提示文本颜色为#888 */
/* Firefox浏览器会比较浅，所以用更深的颜色 */
.jz_mobi_form ::-webkit-input-placeholder {
    color: #888;
}
.jz_mobi_form :-moz-placeholder {
    color: #222;
}
.jz_mobi_form ::-moz-placeholder {
    color: #222;
}
.jz_mobi_form :-ms-input-placeholder {
    color: #888;
}

/* 旧样式 start */
.mobi_form_style_0 .m_mobi_form_input_text {
    height: 1.6rem;
    margin-top: 0.6rem;
    width: 85%;
    border: 1px solid #dadada;
    background-color: #f7f7f7;
    -webkit-appearance: none;
    border-radius: 4px;
    font-size: 0.6rem;
    text-indent: 0.25rem;
    padding: 0;
}
.mobi_form_style_0 .m_form_item {
    position: relative;
}
.mobi_form_style_0 .item_wrap {
    margin-top: 29px;
}
.mobi_form_style_0 .form_name {
    font-weight: 600;
    font-size: 0.7rem;
}
.mobi_form_style_0 .form_item_label {
    font-size: 0.7rem;
}
.mobi_form_style_0 .m_form_item .form_item_require:after {
    position: absolute;
    right: 0;
    top: 2rem;
}
.mobi_form_style_0 .m_form_item .form_item_content {
    margin-top: 0;
}
.mobi_form_style_0 .m_mobi_form_phone {
    justify-content: initial;
    position: relative;
}
.mobi_form_style_0 .m_mobi_form_phone .form_phone_cn_warp {
    position: absolute;
    top: calc(50% + 0.3rem);
    transform: translateY(-50%);
    right: 1.6rem;
}
.mobi_form_style_0 .m_mobi_form_phone .drop_down_icon {
    right: 0.85rem;
}
.mobi_form_style_0 .m_mobi_form_textArea {
    height: 2.5rem;
}
.mobi_form_style_0 .choice_box_wrap {
    margin-top: 0;
}
.mobi_form_style_0 .mobi_form_radio .m_radio_inner {
    background: none;
}
.mobi_form_style_0 .mobi_form_radio .m_radio_input {
    opacity: 1;
}
.mobi_form_style_0 .mobi_form_checkbox .m_checkbox_input {
    opacity: 1;
}
.mobi_form_style_0 .m_checkbox_inner {
    background: none;
}
.mobi_form_style_0 .subHeadline {
    font-size: 0.6rem;
    line-break: 1rem;
}
.mobi_form_style_0 .m_mobi_form_text_descrition_wrap .headline {
    line-height: 1.5rem;
}
.mobi_form_style_0 .m_mobi_form_upload_btn {
    width: 6rem;
    text-align: left;
    text-indent: 1rem;
    border: none;
    background: none;
    line-height: 1.6rem;
    color: #666666;
}
.mobi_form_style_0 .m_mobi_form_validate_code .m_mobi_form_input_text {
    width: 7rem;
}
.mobi_form_style_0 .m_mobi_form_validate_code {
    display: block;
}
.mobi_form_style_0 .validateCode_img {
    width: auto;
    height: 1.6rem;
}
.mobi_form_style_0 .validateCode_img {
    margin-top: 0.6rem;
    margin-left: 25px;
}
.mobi_form_style_0 .form_validate_refresh {
    display: inline-block;
    line-height: 1.75rem;
    background-size: contain;
    background-image: url(/image/refurbish.png?v=201711250607);
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    background-position: 80% 50%;
    position: relative;
    left: 0.15rem;
    top: 0.175rem;
}
.mobi_form_style_0 .m_mobi_form_date .fk-dateRangeUtil {
    width: auto;
    margin-left: -1rem;
}

.mobi_form_style_0.is_horizon {
    padding: 0;
    padding-top: 0.5rem;
}
.mobi_form_style_0.is_horizon .m_form_item {
    display: flex;
    align-content: center;
}
.mobi_form_style_0.is_horizon .form_item_label {
    width: 25%;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.6rem;
    margin-right: 0.3rem;
}
.mobi_form_style_0.is_horizon .form_item_content {
    width: 85%;
}
.mobi_form_style_0.is_horizon .m_mobi_form_input_text {
    margin-top: 0;
}
.mobi_form_style_0.is_horizon .m_mobi_form_phone .drop_down_icon {
    right: 0.5rem;
}
.mobi_form_style_0.is_horizon .m_form_item .form_item_require:after {
    top: 50%;
    transform: translateY(-50%);
}
.mobi_form_style_0.is_horizon .form_item_wrap_5 {
    margin-left: 0.75rem;
}
.mobi_form_style_0.is_horizon .m_form_item .form_item_require:after {
    right: 0.5rem;
}
.mobi_form_style_0.is_horizon .form_item_wrap_3,
.mobi_form_style_0.is_horizon .form_item_wrap_2 {
    align-items: flex-start;
}
.mobi_form_style_0.is_horizon .form_item_wrap_3 .form_item_label,
.mobi_form_style_0.is_horizon .form_item_wrap_2 .form_item_label {
    margin-top: 0.35rem;
}
.mobi_form_style_0.is_horizon .validateCode_img {
    margin-left: 0;
    margin-top: 0;
    width: 3.7rem;
}
.mobi_form_style_0.is_horizon .m_mobi_form_validate_code .m_mobi_form_input_text {
    width: 6rem;
}
.mobi_form_style_0.is_horizon .form_phone_cn_warp {
    top: 50%;
}
.mobi_form_style_0.is_horizon .form_validate_refresh {
    left: 0;
}
.mobi_form_style_0 .m_mobi_form_select_wrap .drop_down_icon {
    display: none;
}
.mobi_form_style_0 .mobi_form_radio .m_radio_inner,
.mobi_form_style_0 .mobi_form_checkbox .m_checkbox_inner {
    border: none;
}
.mobi_form_style_0.is_horizon .form_item_label {
    line-height: 1.6rem;
}
.mobi_form_style_0.is_horizon .form_item_wrap_2 .form_item_label,
.mobi_form_style_0.is_horizon .form_item_wrap_3 .form_item_label {
    line-height: normal;
}
.mobi_form_style_0.is_horizon .submit_btn {
    margin: 0.5rem auto;
    width: 90%;
}
.mobi_form_style_0 .submit_btn {
    margin: 1.425rem 0 0.5rem 0;
}

.mobi_form_style_0 .choice_box {
    color: #666;
}
.mobi_form_style_0 .m_mobi_form_phone .m_mobi_form_phone_input.m_mobi_form_input_text {
    background-color: #f7f7f7;
}

/* 旧样式 end */

.jz_mobi_form .form_item_vertical {
    display: block;
    margin-right: 0;
    float: none;
}

/* 表单支付样式 */

.jz_mobi_form .pay_wrap {
    display: flex;
    align-items: center;
    margin-top: 0.776rem;
}

.jz_mobi_form .pay_text {
    font-size: 0.6rem;
    margin-right: 0.24rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}

.jz_mobi_form .pay_money_wrap {
    flex-shrink: 0;
    margin-right: 0.24rem;
    font-size: 0;
}

.jz_mobi_form .pay_type_wrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;
}

.jz_mobi_form .wx_pay_icon {
    width: 0.75rem;
    height: 0.75rem;
    fill: #fdb251;
    margin-right: 0.15rem;
    font-size: 0.65rem;
}
.jz_mobi_form .managePayTips {
    width: 100%;
    min-height: 41px;
    background: rgba(255, 250, 239, 1);
    border: 1px solid rgba(244, 228, 187, 1);
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    font-size: 13px;
    line-height: 40px;
    text-align: center;
    margin: 20px 0 -10px 0;
}
.jz_mobi_form .managePayTips .redStrong {
    color: #f43839;
    text-decoration: underline;
    cursor: pointer;
}
.mobi_form_style_0.is_horizon .managePayTips {
    margin: 20px 0.8rem 1.425rem 0.8rem;
    width: auto;
}

.mobi_form_style_0.is_horizon .pay_wrap {
    padding: 0 0.8rem;
}

.form_limit_time_tips {
    font-size: 0.55rem;
    color: #666;
    text-align: center;
    margin: 0 0 1rem;
}

.form_limit_time_tips_icon {
    width: 0.55rem;
    height: 0.55rem;
    vertical-align: middle;
    transform: translateZ(0);
}

.form_limit_time_tips_text {
    vertical-align: middle;
    line-height: 1.2;
}

.form_protocol {
    display: flex;
    align-items: center;
    margin-top: 0.776rem;
    .mobi_form_checkbox {
        .m_checkbox_wrap {
            margin-right: 0.3rem;
        }
    }
    &_text {
        font-size: 0.6rem;
        color: #666;
        cursor: pointer;
    }
    &_title {
        font-size: 0.6rem;
        color: #0879ff;
        cursor: pointer;
    }
    &_agree {
        flex-shrink: 0;
    }
}
</style>
