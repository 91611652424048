export const LinkType = {
    H_TEXT: 1,
    V_TEXT: 2,
    PIC_TEXT: 3,
};

export const Link = {
    M_TYPE: 'moduleType',
    DATALIST: 'dataList',
    TEXTINFO: 'textInfo',
};

export const DataList = {
    TEXT: 'text',
    IMG: 'img',
    HREF: 'href',
    LINK_OPEN_TYPE: 'openType',
    ON_LINE_MODE: 'linkType',
    COLUMN: 'columnId',
    IDE: 'ide',
    NOFOLLOW: 'nf',
};

export const TextData = {
    T_TYPE: 'textType',
    UNDERLINE: 'underline',
    BOLD: 'bold',
    COLOR: 'color',
};
