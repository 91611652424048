import { copyDataToClipboard } from '@/shared/clipboard';
import { ing } from '@/shared/tips';
export function copyAccountAndOpenWX(account) {
    if (!Fai.top.isWXAddFansAuthAllow) {
        ing('网站版本已过期，请联系管理员', 0);
        return;
    }

    const UA = window.navigator.userAgent;
    const IS_WX = /MicroMessenger/.test(UA);
    copyDataToClipboard({
        data: account,
        sCb() {
            if (IS_WX) {
                ing(LS.js_wxCopySuccess, 1);
            } else {
                // 唤起App
                let dialogStr = `
            <div id="wx_addFans_popup_wrap" class="wx_addFans_popup_wrap">
              <div class="wx_addFans_popup">
                <div class="text_wrap">
                  <span class="success_text">${LS.js_wxCopySuccess}</span>
                  <span class="countdown">${LS.js_wxJump}</span>
                </div>
                <div class="btn_wrap">
                  <div class="cancel_btn">${LS.js_cancel}</div>
                  <div class="open_btn">${LS.js_open}</div>
                </div>
              </div>
            </div>
          `;
                let $dialog = jm(dialogStr);
                jm('body').append($dialog);
                // 阻止滚动穿透
                $dialog.on('touchmove', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                });

                $dialog.find('.cancel_btn').on('click', () => {
                    $dialog.remove();
                });

                $dialog.find('.open_btn').on('click', () => {
                    window.location.href = 'weixin://';
                    $dialog.remove();
                });
            }
        },
        fCb() {
            ing('复制微信号失败，请手动复制', 1);
        },
    });
}
