<template>
    <div :id="'pagenation' + moduleId" :class="pagenationClass">
        <div :class="pagePrevClass" @click="changeSelected(1)">
            <a v-if="preEnable && !ajaxPagenation" :href="pageData.preUrl"></a>
        </div>
        <div :id="'pagenationSelectDiv' + moduleId" class="pagenationSelectDiv">
            <select v-if="ajaxPagenation" ref="select" class="pageSelect J_noOpenDesigner" @change="changeSelected(3)">
                <option v-for="(item, index) in totalP" :key="index" :class="optionClass(item)" :value="item">
                    {{ item }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ totalPage }}
                </option>
            </select>
            <select
                v-if="pageData.pageList.length && !ajaxPagenation"
                ref="select"
                class="pageSelect J_noOpenDesigner"
                onchange="location = this.options[this.selectedIndex].value;"
            >
                <option
                    v-for="(item, index) in pageData.pageList"
                    :key="index"
                    :class="optionClass(item)"
                    :value="item"
                >
                    {{ index }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ totalPage }}
                </option>
            </select>
            <span v-if="openThemeV3" class="currentPageV3Cover g_main_color_v3"
                >{{ pageno }}&nbsp;&nbsp;<span class="totalPageV3">/&nbsp;&nbsp;{{ totalPage }}</span></span
            >
        </div>
        <div :class="pageNextClass" @click="changeSelected(2)">
            <a v-if="nextEnable && !ajaxPagenation" :href="pageData.nextUrl"></a>
        </div>
    </div>
</template>

<script>
import { AnimationApis } from '@jz/biz-shared';

export default {
    name: 'Pagenation',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        moduleId: Number,
        // eslint-disable-next-line vue/require-default-prop
        totalSize: Number,
        // eslint-disable-next-line vue/require-default-prop
        pageSize: Number,
        pageno: {
            type: Number,
            default: 1,
        },
        ajaxPagenation: {
            type: Boolean,
            default: false,
        },
        openThemeV3: {
            type: Boolean,
            default: false,
        },
        pageData: {
            type: Object,
            default: () => {
                return {
                    pageList: [],
                    preUrl: '',
                    nextUrl: '',
                };
            },
        },
    },
    data() {
        var pageno = this.pageno;
        var prePage = pageno - 1 <= 0 ? 1 : pageno - 1; //上一页的页码
        var nextPage = pageno + 1 >= this.totalPage ? this.totalPage : pageno + 1; //下一页的页码

        return {
            showHowManyPage: 2, //页面展示的翻页数字的个数
            prePage: prePage,
            nextPage: nextPage,
            pagenationClass: this.openThemeV3 ? 'pagenation theme_v3_pagenation' : 'pagenation',
            pagenStyle: 'icon-cnListP',
            pagenStyle2: 'icon-cnListN',
        };
    },
    computed: {
        nextEnable() {
            return !(this.pageno >= this.totalPage);
        },
        preEnable() {
            return this.pageno > 1;
        },
        pagePrevClass() {
            var tmpClass = 'pagePrev icon-pagePrev J_noOpenDesigner';
            if (this.pagenStyle != '') {
                tmpClass += ' ' + this.pagenStyle;
            }
            if (!this.preEnable) {
                tmpClass += ' pageDisable';
            }
            return tmpClass;
        },
        pageNextClass() {
            var tmpClass = 'pageNext icon-pageNext J_noOpenDesigner';
            if (this.pagenStyle2 != '') {
                tmpClass += ' ' + this.pagenStyle2;
            }
            if (!this.nextEnable) {
                tmpClass += ' pageDisable';
            }
            return tmpClass;
        },
        totalP() {
            var totalP = [];
            for (var j = 1; j < this.totalPage + 1; j++) {
                totalP.push(j);
            }
            return totalP;
        },
        totalPage() {
            if (this.pageSize <= 0) {
                return 0;
            }
            const totalPage = Math.ceil(
                this.totalSize - (this.totalSize / this.pageSize) * this.pageSize > 0
                    ? this.totalSize / this.pageSize + 1
                    : this.totalSize / this.pageSize
            );
            return Math.max(totalPage, 1);
        },
        currentPage() {
            return this.pageno;
        },
    },
    watch: {
        pageno(newVal) {
            this.$refs.select.value = newVal;
        },
        totalPage() {
            this.toPage(1);
        },
        pageSize() {
            this.toPage(1);
        },
    },
    mounted() {
        if (!this.openThemeV3) {
            this.$refs.select.value = this.pageno; // 处理2.0主题下分页初始化不更新问题
        }
    },
    methods: {
        optionClass(item) {
            return item == 1 ? 'pageOptions currentPageV3 J_noOpenDesigner' : 'pageOptions J_noOpenDesigner';
        },
        optionSelect(item) {
            return item == 1 ? 'selected' : '';
        },
        changeSelected(type) {
            if (this.ajaxPagenation) {
                var pageno = 0;
                if (type == 1 && this.preEnable) {
                    pageno = this.pageno - 1;
                    this.$emit('on-change', pageno);
                } else if (type == 2 && this.nextEnable) {
                    pageno = this.pageno + 1;
                    this.$emit('on-change', pageno);
                } else if (type == 3) {
                    pageno = parseInt(this.$refs.select.value);
                    this.$emit('on-change', pageno);
                }

                this.scrollToModuleHead(this.moduleId);
                AnimationApis.getAllAnimationManageMap().delete(this.moduleId);
            }
        },
        toPage(val) {
            this.$emit('on-change', val);
        },
        scrollToModuleHead(moduleId) {
            if (moduleId == null) return;
            const $gweb = $('#g_web');
            const $module = $(`#module${moduleId}`);
            const offsetTop = $module.offset().top;
            const headerHeight = $('#web_header_fixed_box').height() || 0;
            const gwebScrollTop = $gweb.scrollTop();
            if (VITE_APP_MODE !== 'visitor') {
                const topBarHeight = $('#fk-toolTopBar').height();
                // 辅助虚线的高度
                const lineHeight = 1;
                const previewTop = parseInt($('#preview').css('top')) || 0;
                $gweb.scrollTop(gwebScrollTop + offsetTop - previewTop - headerHeight - topBarHeight - lineHeight);
            } else {
                $gweb.scrollTop(gwebScrollTop + offsetTop - headerHeight);
            }
        },
        // 当前模块是否在折叠/标签容器内
        getTabOrFoldId(moduleId, type) {
            if (moduleId == null) return;

            let $module = jm('#module' + moduleId);
            type = type || 'tab';

            if (type == 'fold') {
                let $foldModule = $module.parents('.J_formFoldContentList');
                let inFold = 0;
                if ($foldModule.length > 0) {
                    inFold = parseInt($foldModule.attr('id').split('formFoldContentList')[1]);
                }
                if (inFold > 0) return inFold;
            } else if (type == 'tab') {
                let $tabModule = $module.parents('.J_formTabContentList');
                let inTab = 0;
                if ($tabModule.length > 0) {
                    inTab = parseInt($tabModule.attr('id').split('formTabContentList')[1]);
                }
                if (inTab > 0) return inTab;
            } else {
                return 0;
            }
        },
    },
};
</script>

<style lang="scss">
.pagenation_btn {
    width: 100%;
    display: inline-block;
    height: 100%;
}
</style>
