<template>
    <div
        :id="itemId"
        :topClassName="_topInfo.topName"
        :topSwitch="_topInfo.topSwitch"
        :productId="info.id"
        :productName="info.name"
        class="mProductTileForm cardWrap J_product_item"
        :class="[newProductTileFormClass, _contentStyle, _wrapBorderRadiusClass]"
        :title="_title2"
        :style="_warpProRadiusStyle"
    >
        <a :href="info.extInfo.href" :onclick="onclickStr">
            <div :id="imgWrapId" class="imgClass_160" :style="img160Style">
                <span class="imageMiddle"></span>
                <div class="fk-productListImgWrap" :class="picScaleClass">
                    <div v-if="manageMode && info.top != 0 && !_productSelect" class="f-productListTopFlag"> 置顶 </div>
                    <sale-flag v-if="info.extInfo.salePromotionOpen" :info="info"></sale-flag>
                    <div
                        v-if="!isImg"
                        v-drag-resize="_resizeOptions"
                        class="style1Img photoListImg imgItem J_img_lazyload"
                        :title="info.name"
                        :src-original="_originImgSrc"
                        :style="proPhotoStyle"
                        :class="[_imgScaleClass, _imgBorderRadiusClass]"
                    >
                    </div>
                    <img
                        v-else
                        v-drag-resize="_resizeOptions"
                        v-ban-copy
                        :alt="info.name"
                        class="style1Img photoListImg J_img_lazyload"
                        :src-original="_originImgSrc"
                        :src="noLazyLoad ? _originImgSrc : options.loadingPath"
                        :style="imgPhotoStyle"
                        :class="_imgBorderRadiusClass"
                    />
                    <quality-content-label
                        v-if="_isShowQualityContentLabel"
                        :text="_knowPayLabelText"
                    ></quality-content-label>
                </div>
            </div>
            <product-name
                v-if="module.content.showProName && module.content.picSetting.picSizeSet == 1"
                :info="info"
            ></product-name>
            <mall-cart
                v-if="!isNewAddModule && _showMallCart.mallCart2 && module.content.picSetting.picSizeSet == 1"
                :info="info"
                :holder="_showMallCartHolder"
            ></mall-cart>
            <prop-panel
                :info="info"
                :list="_showPropList"
                :ext-info="_extInfo"
                :show-mall-cart="_showMallCart"
                :text-align-class="textAlignClass"
                :no-name-wrap="noNameWrap"
            >
            </prop-panel>
        </a>
    </div>
</template>

<script>
import productItemMixin from '../../mixins/productItemMixin';
import saleFlag from '../comm/sale-flag.vue';
import propPanel from '../comm/prop-panel.vue';
import productName from '../comm/product-name.vue';
import mallCart from '../comm/mall-cart.vue';
import qualityContentLabel from '../comm/quality-content-label.vue';

const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'MutiColumnItem',
    components: {
        saleFlag,
        productName,
        mallCart,
        propPanel,
        qualityContentLabel,
    },
    mixins: [productItemMixin],
    inject: ['module', 'options'],
    props: ['info', 'index', 'rowMaxHeight'],
    data() {
        return {
            itemId: `mProductTileForm${this.info.id}_module${this.module.id}`,
            imgWrapId: `imgClass${this.info.id}_module${this.module.id}`,
            manageMode,
        };
    },
    computed: {
        //已抢光标志图片
        soldOutImg() {
            // return this.module.extInfo.isCN
            //     ? 'http://ma.faisys.com/image/soldOut.png'
            //     : this.options.resRoot + '/image/soldOut_en.png';
            return `${this.options.resRoot}/image/soldOut${this.module.extInfo.isCN ? '' : '_en'}.png`;
        },
        newProductTileFormClass() {
            if (this.isNewAddModule) {
                return !this.module.content.nameWrap ? 'mProductTileFormForStyle1' : 'mProductTileFormForStyle2';
            } else {
                return '';
            }
        },
        picScaleClass() {
            const picScale = this.module.content.scale;
            let picScaleClass = '';
            if (this.module.content.picSetting.picSizeSet != 1) {
                if (picScale == 2) {
                    picScaleClass = 'imgScale';
                } else if (picScale == 3) {
                    picScaleClass = 'imgStretch';
                }
            }
            return picScaleClass;
        },
        proPhotoStyle() {
            if (this._isTransparentStyle) {
                let specialStyle = {};
                if (this.isSpecialStyle) {
                    specialStyle['background-color'] = 'transparent';
                }
                return {
                    ...specialStyle,
                    ...this.photoStyle,
                    ...this._proRadiusStyle,
                };
            } else {
                return { ...this.photoStyle, ...this._fixProRadiusStyle };
            }
        },
        imgPhotoStyle() {
            if (this.isNewAddModule) {
                return { ...this.photoScale, ...this._proRadiusStyle };
            }
            return {};
        },
        photoScale() {
            const proSize = this.module.content.proSize;
            const proType = proSize.proSizeType;
            const proScaleWidth = proSize.proWidth;
            const proScaleHeight = proSize.proHeight;

            if (proType == 2) {
                return {
                    height: (174 * proScaleHeight) / proScaleWidth + 'px',
                    width: '100%',
                };
            }
            return {};
        },
        photoStyle() {
            var photoStyle = {};
            var proSize = this.module.content.proSize;
            var proType = proSize.proSizeType;
            var proScaleWidth = proSize.proWidth;
            var proScaleHeight = proSize.proHeight;

            if (proType == 2) {
                photoStyle.paddingTop = (proScaleHeight * 100) / proScaleWidth + '%';
                photoStyle.height = 'auto';
            }

            photoStyle.backgroundImage = `url(${this.noLazyLoad ? this._originImgSrc : this.options.loadingPath})`;
            return photoStyle;
        },
        textAlignClass() {
            let textAlign = this.module.content.ta == 0 ? 'textAlign_center' : 'textAlign_left';
            if (!(this.module.extInfo.newUser || this.module.extInfo.isEdit)) {
                textAlign = '';
            }
            return textAlign;
        },
        noNameWrap() {
            return 'paramWrap';
        },
        isImg() {
            let picSizeSet = this.module.content.picSetting.picSizeSet;
            let proStyle = this.module.content.proStyle;
            let style1ProStyleList = [4];
            if (this.isNewAddModule) {
                return false;
            } else {
                let isStyle1ProStyle = style1ProStyleList.indexOf(proStyle) != -1;
                return !(picSizeSet == 0 && !(isStyle1ProStyle || this._isThreeCol));
            }
        },
        img160Style() {
            if (this.isNewAddModule) return;
            let style = '';
            let proStyleList = [5, 6, 7];
            let type = this.module.content.t;
            let proStyle = this.module.content.proStyle;
            let style1ProStyleList = type == 1 && proStyleList.includes(proStyle);
            if (this.isImg) {
                style += `height:${this.rowMaxHeight}px`;
            } else if (style1ProStyleList) {
                style += `height:auto`;
            }
            return style;
        },
        isSpecialStyle() {
            let proStyle = this.module.content.proStyle;
            let moduleType = this.module.content.t;
            let proStyleList = [11]; //无底色
            let exitProStyle = proStyleList.indexOf(proStyle) != -1;
            // 样式一 且 风格为无底色(fix bug: ID1048686，空白间隙问题)
            if (exitProStyle && moduleType == 1) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
.isNewAddModule.colmum2 .fk-mProductList1 {
    display: flex;
    flex-wrap: wrap;
}
.isNewAddModule.colmum2 .squareStyle .mProductTileForm.cardWrap {
    margin-bottom: 0;
    padding-top: 0.75rem;
}
.isNewAddModule.colmum2 .mProductTileForm.cardWrap {
    display: inline-flex;
    flex-shrink: 0;
    vertical-align: top;
    flex-grow: 1;
    width: 5.25rem;
    margin: 0;
    margin-bottom: 0.75rem;
    padding-bottom: 0;
}
.isNewAddModule.colmum2 .jz_mergeBorderStyle .mProductTileForm.cardWrap > a {
    align-items: center;
}
.isNewAddModule.colmum2 .jz_cardStyle .mProductTileForm.cardWrap {
    max-width: none;
}
.isNewAddModule.colmum3 .mProductTileForm.cardWrap {
    padding-bottom: 0;
}
.isNewAddModule.colmum3 .fk-mProductList31 {
    display: flex;
    flex-wrap: wrap;
}
.isNewAddModule.colmum3 .fk-mProductList31 .mProductTileForm {
    flex-shrink: 0;
    flex-grow: 1;
    width: 26%;
    margin: 0;
    margin-bottom: 0.75rem;
    display: flex;
}
.isNewAddModule.colmum3 .fk-mProductList31,
.isNewAddModule.colmum2 .fk-mProductList1 {
    margin: 0.75rem 0.5rem;
    padding: 0;
    margin-bottom: 0;
}
.isNewAddModule.colmum2 .fk-mProductList1.jz_mergeBorderStyle {
    margin-top: 0;
}

.isNewAddModule .fk-productListTitle .fk-productListImgWrap {
    width: 100%;
}

/* 解决占位图片太小，交叉检查需要再最下面时才能出现的问题 1083866 */
.fk-productListTitle .fk-productListImgWrap .J_img_lazyload[src$='/transparent.png'] {
    height: 400px;
}

.isNewAddModule.colmum2 .mProductTileForm.cardWrap .J_img_lazyload {
    width: 100%;
    height: 7.25rem;
    background-position: center;
    background-repeat: no-repeat;
}
.isNewAddModule.colmum2 .imgItem {
    padding-top: 0;
}
.isNewAddModule.colmum3 .mProductTileForm.cardWrap .J_img_lazyload {
    width: 100%;
    height: 4.25rem;
    background-position: center;
    background-repeat: no-repeat;
}
.isNewAddModule div.fk-mProductList31 .mProductTileForm .paramCollection {
    width: 100%;
}
.form .isNewAddModule.colmum3 .fk-mProductList31 .g_productName,
.form .isNewAddModule.colmum2 .fk-mProductList1 .g_productName {
    font-weight: normal;
}
</style>
