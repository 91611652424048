import { ing } from '@/shared/tips';
import { addRequestPrefix } from '@/shared/util';
import { bindTouchAndClickEvent } from '@/shared/event';
import { preprocessUrl } from '@/shared/url';
import { changeHref } from '@/features/router/universal';
export class initMbPdCollection {
    constructor(sessionMid, collectionList, productId, _manageMode) {
        this.sessionMid = sessionMid;
        this.collectionList = collectionList;
        this.pid = productId;
        this._manageMode = _manageMode;
    }
    init() {
        this.initEvent();
        if (
            jm('#_TOKEN').length != 0 &&
            !this._manageMode &&
            jm.getCookie('m_collectID') == this.pid + '' &&
            !jm('.shareBlock .icon-collection ').hasClass('icon-collectSelect g_main_color_v3')
        ) {
            if (this.collectionList.indexOf(this.pid) == -1) {
                this.collectionList.push(this.pid);
                jm('.shareBlock .icon-collection').addClass('icon-collectSelect g_main_color_v3');
                this.updateMbCollection(true);
                this.cookieCollectIDTimes++;
            }
        }
        if (this.cookieCollectIDTimes === 1) {
            //用户未登录的时候点击收藏的情况
            jm('.shareBlock .icon-collection').addClass('icon-collectSelect g_main_color_v3');
            if (this.collectionList.indexOf(this.pid) == -1) {
                this.collectionList.push(this.pid);
            }
        }
        jm.cookie('m_collectID', '', { expires: -1, path: '/' });
    }
    updateMbCollection(isCollection) {
        jm.ajax({
            url:
                addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
                '/member_h.jsp?cmd=setWafCk_setMemberCollection',
            data: 'mid=' + this.sessionMid + '&pid=' + this.pid + '&collection=' + isCollection,
            type: 'POST',
            dataType: 'json',
            success(result) {
                if (result.success) {
                    if (isCollection) {
                        ing(LS.sucCollection, 1);
                    } else {
                        ing(LS.cancelledCollection, 2);
                    }
                }
            },
        });
    }
    initEvent() {
        bindTouchAndClickEvent(jm('.shareBlock'), () => {
            if (this.sessionMid == 0) {
                if (this._manageMode) {
                    ing(LS.isManageFailCollection);
                } else {
                    changeHref(
                        preprocessUrl({ path: '', oldPath: '' }) +
                            'login.jsp?returnUrl=' +
                            jm.encodeUrl(window.location.href)
                    );
                    jm.cookie('m_collectID', this.pid, { path: '/' });
                }
            } else if (this.collectionList.indexOf(this.pid) == -1) {
                this.collectionList.push(this.pid);
                jm('.shareBlock .icon-collection').addClass('icon-collectSelect g_main_color_v3');
                this.updateMbCollection(true);
            } else if (this.collectionList.indexOf(this.pid) != -1) {
                this.collectionList.splice(this.collectionList.indexOf(this.pid), 1);
                jm('.shareBlock .icon-collection').removeClass('icon-collectSelect g_main_color_v3');
                this.updateMbCollection(false);
            }
        });
    }
}
