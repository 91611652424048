<template>
    <div :id="'productMallOptionPanel_mcs_' + moduleId" style="display: none">
        <div class="productMallOptionPanel" :moduleId="moduleId">
            <div class="productMallOptionContentPanel">
                <div class="productMallOptionContent">
                    <div
                        class="
                            productDetailClose
                            icon-Off
                            g_iconMiddle
                            icon-defaultColor
                        "
                        style="cursor: default; z-index: 2"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { showMallCartScreen } from '@/modules/shared/product/mallCart';
export default {
    name: 'CartPanel',
    props: [
        'moduleId',
        'mallShoppingOptions',
        'choiceCurrencyVal',
        'levelDiscount',
        'mallCartCountText',
    ],
    mounted: function () {
        const html = `
            <div class='productMallOptionHead'>
                <div class='productMallOptionImage'>
                    <div class='productMallOptionImageContent'>
                        <span class="imageMiddleSpan"></span>
                        <img class='mallOptionImg' src='' />
                    </div>
                </div>
                <div class='productDetail'>
                    <div class='productDetailName'>
                    </div>
                    <div class='productDetailMallAmount'>
                        <div class='productMallAmount' style='float:left'></div>
                        <div class='productMallWeight' style='float:left;margin-left:0.5rem;'></div>
                    </div>
                </div>
            </div>
            <div class='g_separator separatorLine'></div>
            <div class='mallOptionAndNumBox' id='mallOptionAndNumContainer' >
                <div id='mallOptionAndNumBox' >
                    <div class='mallNewOptionBox mallOptionBox J-op-box'></div>
                    <div class='g_separator separatorLine' style='margin-left: 0.5rem;'></div>
                    <div class='productNumberPanel g_middleColor'><span>${this.mallCartCountText}<span>
                        <div id='limitAmountDiv' style='line-height:0.7rem;color:#828282;width:9rem;'></div>
                        <div class='productNumberContent J_productNumberContent_simple'>
                            <span id='g_decrease' class='g_decrease g_opacity50 icon-decrease g_iconMiddle'></span>
                            <input type='number' class='g_buyNumber' id='productNum' autocomplete='off' value='1' min='1' max='9999999' />
                            <span id='g_increase' class='g_increase icon-increase g_iconMiddle'></span>
                        </div>
                        <div id='limitAmountDiv_pt' style='line-height:0.7rem;color:#ff4e45;width:9rem;font-size: 0.55rem;display:none;'></div>
                        <div class='productNumberContent J_productNumberContent_pt' style='display:none;'>
                            <span id='g_decrease_pt' class='g_decrease g_opacity50 icon-decrease g_iconMiddle'></span>
                            <input type='number' class='g_buyNumber' id='productNum_pt' autocomplete='off' value='1' min='1' max='9999999' />
                            <span id='g_increase_pt' class='g_increase icon-increase g_iconMiddle'></span>
                        </div>
                        <div id='limitAmountDiv_im' style='line-height:0.7rem;color:#ff4e45;width:9rem;font-size: 0.55rem;display:none;'></div>
                        <div class='productNumberContent J_productNumberContent_im' style='display:none;'>
                            <span id='g_decrease_im' class='g_decrease g_opacity50 icon-decrease g_iconMiddle'></span>
                            <input type='number' class='g_buyNumber' id='productNum_im' autocomplete='off' value='1' min='1' max='9999999' />
                            <span id='g_increase_im' class='g_increase icon-increase g_iconMiddle'></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='productMallShop'></div>
        `;
        showMallCartScreen(
            this.moduleId,
            this.choiceCurrencyVal,
            this.mallShoppingOptions,
            this.levelDiscount,
            html,
            '产品展示模块'
        );
    },
};
</script>
