<template>
    <module-frame :module-id="moduleId">
        <div v-if="!isNewModule">
            <div
                :id="'searchContainer' + module.id"
                :class="searchCls"
                :style="moduleColorStyle"
                @click.stop="handlePopupSearch"
            >
                <div class="icon-mSearchIcon"></div>
                <div class="mSearchInput" :style="textStyle">
                    {{ searchTip }}
                </div>
                <div :class="['icon-mSearchBtn', colorCls]" :style="moduleColorStyleIcon"></div>
            </div>
        </div>
        <div v-else>
            <template v-if="!centerText">
                <div
                    :id="'searchContainer' + module.id"
                    class="search newSearch"
                    :class="[styleTypeClass, contentStyle, textAlign, searchFlag]"
                    :style="moduleColorStyle"
                    @click.stop="handlePopupSearch"
                >
                    <template v-if="showDropMenu">
                        <div class="hide-flex-block">
                            <div class="module-defaultTitle sr-defaultTitle searchRange">{{ defaultTitle }}</div>
                            <div class="module-arrow-svgDiv">
                                <svg class="module-icon-arrow">
                                    <use xlink:href="#jzm-vicon_arrow_panel" />
                                </svg>
                            </div>
                        </div>
                    </template>
                    <div :class="showDropMenu ? 'new-mSearchInput' : 'mSearchInput'" :style="textStyle">
                        {{ searchTip }}
                    </div>
                    <div class="svgDiv" :class="[svgBgColor, defaultColor]" :style="newModuleColorStyleIcon">
                        <svg class="v-searchSvg g_oldThemefillColor g_main_fillColor_v3" :style="{ fill: svgIcon }">
                            <use xlink:href="#jzm-v19"></use>
                        </svg>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    :id="'searchContainer' + module.id"
                    class="search newSearch centerText"
                    :class="[styleTypeClass, contentStyle, textAlign, searchFlag]"
                    :style="moduleColorStyle"
                    @click.stop="handlePopupSearch"
                >
                    <div class="svgDiv" :class="[svgBgColor, defaultColor]" :style="newModuleColorStyleIcon">
                        <svg class="v-searchSvg g_oldThemefillColor g_main_fillColor_v3" :style="{ fill: svgIcon }">
                            <use xlink:href="#jzm-v19"></use>
                        </svg>
                    </div>
                    <div class="mSearchInput" :style="textStyle">
                        {{ searchTip }}
                    </div>
                </div>
            </template>
        </div>
    </module-frame>
</template>

<script>
import { mapFlag } from '@/shared/flag';
import { popupSearch } from '@/system/header/module/v3/search';
import { mapGetters, mapState } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'TotalSearch',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            render: true,
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3']),
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        style() {
            return this.module.style;
        },
        id() {
            return this.module.id;
        },
        moduleColor() {
            return this.module.moduleColor;
        },
        modulePatternV3() {
            return this.module.modulePatternV3;
        },
        modulePattern() {
            return this.module.modulePattern;
        },
        defaultTitle() {
            return (this.module.content.dropMenuList && this.module.content.dropMenuList[0].value) || '全站搜索';
        },
        dropMenuList() {
            return this.module.content.dropMenuList;
        },
        dropListType() {
            return this.module.content.dropListType;
        },
        showDropMenu() {
            if (!this.dropListType) {
                return false;
            }
            // 前4种样式且显示两端 || 居左且后2种样式
            const moduleType_status = this.moduleType == 4 || this.moduleType == 5;
            return this.searchGraphicLocation == 2 || (this.searchGraphicLocation == 0 && moduleType_status);
        },
        isNewModule() {
            if (this.module.extInfo) {
                return this.module.extInfo.isNewModule;
            } else {
                return false;
            }
        },
        searchGraphicLocation() {
            return this.module.content.searchGraphicLocation;
        },
        moduleType() {
            return this.module.content.moduleType;
        },
        searchTip() {
            return this.module.content.st;
        },
        searchType() {
            return 'searchType' + this.module.content.moduleType;
        },
        searchCls() {
            var cls = {};
            cls['search'] = true;
            cls[this.searchType] = true;
            if (this.module.content.moduleType === 5) {
                cls['g_main_bgColor_v3'] = true;
            }
            return cls;
        },
        colorCls() {
            if (this.openThemeV3) {
                let moduleType = this.module.content.moduleType;
                if (moduleType === 0 || moduleType === 3) {
                    return 'g_main_color_v3';
                } else if (moduleType === 1 || moduleType === 4 || moduleType === 5) {
                    return 'g_main_bgColor_v3';
                } else if (moduleType === 2) {
                    return 'g_main_bgColor_v3 g_main_bdColor_v3';
                }
            } else {
                return '';
            }
            return '';
        },
        centerText() {
            var searchGraphicLocation = this.module.content.searchGraphicLocation;
            if (searchGraphicLocation === 1) {
                if (this.moduleType != 4 && this.moduleType != 5) {
                    return true;
                }
            } else {
                return false;
            }
            return false;
        },
        fillStyle() {
            var style = {};
            style.fill = this.svgIcon;
            return style;
        },
        //高级设置input背景
        inputBgType() {
            if (this.openThemeV3) {
                return this.module.patternV3.ibg.t === 1;
            } else {
                return this.module.pattern.ti.b.y === 1;
            }
        },
        inputBgColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.ibg.t === 1 ? this.module.patternV3.ibg.c : '';
            } else {
                return this.module.pattern.ti.b.y === 1 ? this.module.pattern.ti.b.bc : '';
            }
        },
        //高级设置input边框
        inputBdType() {
            if (this.openThemeV3) {
                return this.module.patternV3.ib.t === 1;
            } else {
                return this.module.pattern.ti.d.y === 1;
            }
        },
        inputBdColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.ib.t === 1 ? this.module.patternV3.ib.c : '';
            } else {
                return this.module.pattern.ti.d.y === 1 ? this.module.pattern.ti.d.c : '';
            }
        },
        //高级设置input文字样式
        inputTsType() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.t === 1;
            } else {
                return this.module.pattern.ti.pt.y === 1;
            }
        },
        inputTcType() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.c.t === 1;
            } else {
                return this.module.pattern.ti.pt.y === 1;
            }
        },
        inputSize() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.t === 1 ? this.module.patternV3.it.s + 'rem' : '';
            } else {
                return this.module.pattern.ti.pt.y === 1 ? this.module.pattern.ti.pt.fs + 'px' : '';
            }
        },
        inputColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.c.t === 1 ? this.module.patternV3.it.c.c : '';
            } else {
                return this.module.pattern.ti.pt.y === 1 ? this.module.pattern.ti.pt.fc : '';
            }
        },
        styleTypeClass() {
            var searchType = this.module.content.searchStyleType;
            if (searchType === 1) {
                return 'shadow';
            } else if (searchType === 2) {
                return 'fullbg';
            } else {
                return 'border';
            }
        },
        contentStyle() {
            var moduleType = this.module.content.moduleType;
            if (moduleType === 0 || moduleType === 2 || moduleType === 4) {
                return 'regContent';
            } else {
                return 'radiusContent';
            }
        },
        defaultColor() {
            var moduleType = this.module.content.moduleType;
            if (moduleType === 4 || moduleType === 5) {
                return 'defaultColor';
            }
            return '';
        },
        textAlign() {
            var searchGraphicLocation = this.module.content.searchGraphicLocation;
            if (searchGraphicLocation === 0) {
                return 'left';
            } else if (searchGraphicLocation === 1) {
                return 'center';
            } else {
                return '';
            }
        },
        searchFlag() {
            return 'search' + this.moduleType;
        },
        moduleColorStyle() {
            let style = {};
            if (this.module.content.moduleType === 5 && !this.isNewModule) {
                style.backgroundColor = this.module.content.moduleColor;
            }
            if (this.inputBgType) {
                style.backgroundColor = this.inputBgColor;
            }
            if (this.inputBdType) {
                style.borderColor = this.inputBdColor;
            } else {
                style.borderColor = '';
            }
            return style;
        },
        moduleColorStyleIcon() {
            let style = {};
            let moduleType = this.module.content.moduleType;
            if (moduleType === 0 || moduleType === 3) {
                style.color = this.module.content.moduleColor;
            } else if (moduleType === 1 || moduleType === 4) {
                style.backgroundColor = this.module.content.moduleColor;
            } else if (moduleType === 2) {
                style.backgroundColor = this.module.content.moduleColor;
                style.borderColor = this.module.content.moduleColor;
            } else if (moduleType === 5) {
                style.backgroundColor = 'transparent';
                style.borderColor = '#fff';
            }
            return style;
        },
        newModuleColorStyleIcon() {
            let style = {};
            let moduleType = this.module.content.moduleType;
            let searchIconBgColorSetting = this.module.content.sibcs;
            if (searchIconBgColorSetting === 0) {
                if (moduleType === 4 || moduleType === 5) {
                    style.background = this.module.content.moduleColor;
                    style.borderColor = this.module.content.moduleColor;
                }
            } else {
                if (moduleType === 4 || moduleType === 5) {
                    style.background = this.module.content.sibc;
                    style.borderColor = this.module.content.sibc;
                }
            }
            return style;
        },
        svgBgColor() {
            if (this.moduleType === 4 || this.moduleType === 5) {
                return 'g_oldThemeBgColor g_main_bgColor_v3 g_oldThemeBdColor g_main_bdColor_v3';
            }
            return '';
        },
        svgIcon() {
            let color = '';
            let moduleType = this.module.content.moduleType;
            let searchIconColorSetting = this.module.content.sics;
            if (searchIconColorSetting === 0) {
                if (moduleType === 0 || moduleType === 1) {
                    color = '#aaa';
                } else if (moduleType === 2 || moduleType === 3) {
                    color = this.module.content.moduleColor;
                } else if (moduleType === 4 || moduleType === 5) {
                    color = '#fff';
                }
            } else {
                color = this.module.content.sic;
            }
            return color;
        },
        textStyle() {
            let style = {};
            if (this.inputTsType) {
                style.fontSize = this.inputSize;
            }

            if (this.inputTsType && this.inputTcType) {
                style.color = this.inputColor;
            } else {
                style.color = '';
            }
            return style;
        },
        ...mapFlag({
            hasEditSearch: 0x2,
        }),
    },
    mounted() {
        this.noSearchListCheck();
        this.repairColorData();
    },
    methods: {
        handlePopupSearch() {
            popupSearch({
                moduleId: this.id,
                lanCode: this.module.lanCode,
                nSL: this.module.content.noList,
                hotTopic: this.encodeHtml(this.module.content.hotTopicList),
                searchTip: this.encodeHtml(this.module.content.st),
                moduleType: this.module.content.moduleType,
                historySearch: this.module.content.historyType,
                rangeType: this.module.content.rangeType,
                isNewSearch: this.isNewModule,
                showDropMenu: this.showDropMenu,
                dropMenuList: this.dropMenuList,
                fromModule: true,
                searchTitle: jm('.module-defaultTitle').text(),
            });
            this.$nextTick(function () {
                let topicListDomList = jm('.hotTopicWrap .topicItem');
                let dataList = this.module.content.hotTopicList;
                for (let i = 0, len = dataList.length; i < len; i++) {
                    if (jm(topicListDomList[i]).text() !== dataList[i]) {
                        jm(topicListDomList[i]).text(dataList[i]);
                    }
                }
            });
        },
        //兼容旧数据默认moduleColor存值，清空
        repairColorData() {
            if (this.module.content.moduleColorIndex === 0) {
                this.module.content.moduleColor = '';
            }
        },
        //初始化使用hasEditSearch判断，为了兼容旧用户，所以要在noList加入8，12，因为这两项以前是没有的
        noSearchListCheck() {
            if (this.module.content.rangeType == 1) {
                if (!this.hasEditSearch) {
                    this.module.content.noList.push(8);
                    this.module.content.noList.push(12);
                }
            }
        },
        encodeHtml,
    },
};
</script>

<style lang="scss">
.module-icon-arrow {
    color: #808080;
}
.search {
    position: relative;
    cursor: pointer;
    border: 1px solid #d7d7d7;
    background-color: #fff;
}
.newSearch.border {
    border-color: #e5e5e5;
}
.new-mSearchInput {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.83rem;
    line-height: 1.83rem;
    font-size: 0.5625rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(188, 188, 188);
    -webkit-appearance: none;
    box-sizing: border-box;
    padding-left: 0.3rem;
}
.mSearchInput {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.83rem;
    line-height: 1.83rem;
    color: #919191;
    font-size: 0.6rem;
    -webkit-appearance: none;
    box-sizing: border-box;
    padding-left: 0.3rem;
}
.newSearch .mSearchInput {
    width: 88%;
    padding-left: 0.65rem;
}
.icon-mSearchIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.83rem;
    height: 1.83rem;
    color: #bfbfbf;
}
.icon-mSearchIcon::before {
    content: '\e6de';
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.icon-mSearchBtn {
    width: 1.83rem;
    height: 1.83rem;
    position: absolute;
    right: 0;
    top: 0;
    color: #666;
    text-align: center;
}
.icon-mSearchBtn::before {
    content: '\e6de';
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}
.svgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 1.83rem;
    box-sizing: content-box;
    font-size: 0.7rem;
}
.v-searchSvg {
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
}
.searchType0,
.searchType1,
.searchType2 {
    border-radius: 3px;
}
.searchType1 .icon-mSearchBtn,
.searchType2 .icon-mSearchBtn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.searchType0 .icon-mSearchIcon::before {
    content: none;
}
.search.shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    // border: 0.025rem solid transparent;
}
.search.regContent {
    border-radius: 0.15rem;
}
.newSearch.search0 .svgDiv,
.newSearch.search2 .svgDiv,
.newSearch.search4 .svgDiv {
    border-radius: 0 0.15rem 0.15rem 0;
}
.newSearch.border.search4 .svgDiv,
.newSearch.border.search5 .svgDiv {
    border-top-style: solid;
    border-top-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 1px;
    box-sizing: content-box;
    top: -0.04rem;
}
.newSearch.border.search5 .svgDiv {
    top: -0.046rem;
    right: -0.1rem;
}
.newSearch.search5 .svgDiv {
    right: -0.1rem;
}
.newSearch.search4 .svgDiv {
    right: -0.1rem;
}
.newSearch.search0,
.newSearch.search2,
.newSearch.search4 {
    border-radius: 0.15rem;
}
.search.radiusContent {
    border-radius: 1rem;
}
.search.fullbg {
    background: rgba(246, 246, 246, 1);
    border: none;
}
.searchType1 .mSearchInput {
    width: 83%;
    padding-left: 1.45rem;
}
.searchType1 .icon-mSearchBtn {
    width: 2.5rem;
}
.searchType1 .icon-mSearchBtn::before {
    content: '搜索';
    color: #fff;
    font-size: 0.7rem;
    height: 1.83rem;
    line-height: 1.83rem;
}

.searchType2 .mSearchInput {
    width: 86%;
    padding-left: 1.45rem;
}
.searchType2 .icon-mSearchBtn {
    width: 1.75rem;
}
.searchType2 .icon-mSearchBtn::before {
    content: 'GO';
    color: #fff;
    font-size: 0.7rem;
}
.searchType2 .icon-mSearchBtn::after {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: -0.4rem;
    width: 0;
    height: 0;
    border-right: solid 0.4rem;
    border-right-color: inherit;
    border-top: solid 0.4rem transparent;
    border-bottom: solid 0.4rem transparent;
}

.searchType3 {
    border-radius: 1rem;
}
.searchType3 .mSearchInput {
    padding-left: 0.8rem;
}
.searchType3 .icon-mSearchBtn {
    right: 0.25rem;
}
.searchType3 .icon-mSearchIcon::before {
    content: none;
}

.searchType4 {
    border-radius: 1rem;
}
.searchType4 .mSearchInput {
    width: 83%;
    padding-left: 0.8rem;
}
.searchType4 .icon-mSearchBtn {
    color: #fff;
    width: 2.8rem;
    border-radius: 1rem;
}
.searchType4 .icon-mSearchIcon::before {
    content: none;
}

.searchType5 {
    border-radius: 1rem;
}
.searchType5 .mSearchInput {
    padding-left: 0.8rem;
    color: #fff;
}
.searchType5 .icon-mSearchBtn {
    color: #fff;
    right: 0.25rem;
    border-radius: 1rem;
}
.searchType5 .icon-mSearchIcon::before {
    content: none;
}
.icon_type5 {
    color: #fff;
    right: 0.25rem;
    border-radius: 1rem;
}
.search0.left .svgDiv,
.search1.left .svgDiv,
.search2.left .svgDiv,
.search3.left .svgDiv {
    left: 0;
}
.search0.left .mSearchInput,
.search1.left .mSearchInput,
.search2.left .mSearchInput,
.search3.left .mSearchInput {
    margin-left: 1.4rem;
}
.search4.left .mSearchInput {
    text-align: left;
}
.search5 .svgDiv {
    border-radius: 0 1rem 1rem 0;
}
.search5.left .svgDiv {
    text-align: left;
}
.search5 .svg {
    border-radius: 0 1rem 1rem 0;
}
.search4.center .mSearchInput,
.search5.center .mSearchInput {
    text-align: center;
    padding-left: 0.65rem;
    padding-right: 0.65rem;
    position: relative;
    left: 0;
}
.centerText.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.centerText .svgDiv {
    position: static;
    width: auto;
}
.centerText .mSearchInput {
    width: auto;
    padding-left: 0.3rem;
}
// 新增下拉搜索框
.newSearch {
    display: flex;
    height: 1.83rem;
    line-height: 1.83rem;
}
.icon-arrow {
    width: 0.8rem;
    height: 1.83rem;
}

.hide-flex-block {
    display: flex;
}
.defaultColor {
    background-color: #666666;
}
</style>
