import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { getPreviewObject } from '@/shared/getPreviewObject';
import { showResizeDockTip, hideResizeDockTip } from '@/shared/tips';
import absAlignLine from '@/features/global-mobi-function/methods/dragAlignLine';
/**
 * 浮动图片 Resize 把手生成
 */
export const floatImgResize = function (modules) {
    var $m = modules;
    var undoArgs = [];

    if (!$m.length) {
        return;
    }

    if ($m.find('.J_float_img').hasClass('float_img_default')) {
        //默认图片不需要resize
        return;
    }

    var moduleId = $m.attr('id').replace('module', '');
    // 这里判断是否是2.0，并且是否在弹窗内部，弹窗内部预览态不需要把手
    if (isModuleV2(53)) {
        let moduleData = window.$store.state.currentPageModuleIdMap[moduleId] || {};
        let otherInfo = moduleData.otherInfo || {};
        let onlyLoadVisitScript = otherInfo.onlyLoadVisitScript || false;
        if (onlyLoadVisitScript) return;
    }

    var fixLineNum = 8;
    var resizeAlign = absAlignLine.resize;
    var content = $m.data('contentInfo') ? $m.data('contentInfo') : {};
    var imgShapeEftData = content.shapeEffect ? content.shapeEffect : {};

    var $packDom,
        packWidth = 328;
    var packId = +$m.attr('_inpack') || 0;
    var popupZoneId = +$m.attr('_inpopupzone') || 0;
    var floatZoneId = +$m.attr('_infloatzone') || 0;

    if (packId) {
        $packDom = $('#fk-packContent' + packId);
        packWidth = $packDom.width(); // 2：空隙
    } else if (popupZoneId) {
        $packDom = $('#module' + popupZoneId).find('.J_formPopupZoneContent');
        packWidth = $packDom.width(); // 2：空隙
    } else if (floatZoneId) {
        $packDom = $('#module' + floatZoneId).find('.float_zone_wrap');
        packWidth = $packDom.width(); // 2：空隙
    }
    packWidth == 0 && (packWidth = 328);

    $m.resizable('destroy');
    $m.resizable({
        handles: 'n,e,s,w,ne,nw,sw,se',
        minWidth: 2,
        minHeight: 1,
        autoHide: true,
        aspectRatio: true,
        alsoResize: $m,
        create() {
            var $self = $(this);

            // 添加边框线
            addResizableImgHandles($m, $self, 0, fixLineNum);
            if ($m.width() < 35 || $m.height() < 35) {
                $m.find('.ui-resizable-floatImgTopRight').css({
                    width: '8px',
                    height: '8px',
                    top: '-4px',
                    right: '-4px',
                });
                $m.find('.ui-resizable-floatImgTopLeft').css({
                    width: '8px',
                    height: '8px',
                    top: '-4px',
                    left: '-4px',
                });
                $m.find('.ui-resizable-floatImgBottomRight').css({
                    width: '8px',
                    height: '8px',
                    bottom: '-3px',
                    right: '-4px',
                });
                $m.find('.ui-resizable-floatImgBottomLeft').css({
                    width: '8px',
                    height: '8px',
                    bottom: '-3px',
                    left: '-4px',
                });
            }
        },
        start() {
            content = $m.data('contentInfo') ? $m.data('contentInfo') : {};
            imgShapeEftData = content.shapeEffect ? content.shapeEffect : {};

            Fai.top.currentResizeId = moduleId;
            resizeMask(true); //生成遮罩层
            $m.children('.ui-resizable-handle').show();

            // 隐藏工具条
            Fai.top.$('#fkEditor-toolBar-' + moduleId).hide();

            //判断是否按等比例进行调整  -- 这个暂时没使用到，后面都是直接取axis 判断
            if ($(this).attr('aspectRatio') == '1') {
                $(this).resizable('option', 'aspectRatio', true);
            } else {
                $(this).resizable('option', 'aspectRatio', false);
            }

            var handleLine_width = Math.floor(($m.width() - fixLineNum - 1) / 2), // 1：减1是由于rem 小数点约进，缓冲
                handleLine_Height = Math.floor(($m.height() - fixLineNum - 1) / 2);

            //拖动框线长
            $m.children('.ui-resizable-s')
                .children('.imgResizeLine')
                .css({ width: handleLine_width + 'px' });
            $m.children('.ui-resizable-n')
                .children('.imgResizeLine')
                .css({ width: handleLine_width + 'px' });
            $m.children('.ui-resizable-e')
                .children('.imgResizeLine')
                .css({ height: handleLine_Height + 'px' });
            $m.children('.ui-resizable-w')
                .children('.imgResizeLine')
                .css({ height: handleLine_Height + 'px' });

            var moduleWidth = $(this).width();
            var moduleHeight = $(this).height();

            var moduleLeft, moduleRight, maxWidth, maxHeight;
            var axis = $m.data('resizable') ? $m.data('resizable').axis : '';

            //这里做一层保护的原因: 有可能取不到resize取不到inpack,或 被重绑resize，但此时自由容器宽为 0
            if ((!popupZoneId && !packId && !floatZoneId) || packWidth == 0) {
                packId = $m.attr('_inpack');
                $packDom = $('#fk-packContent' + packId);
                packWidth = $packDom.width(); // 2：空隙
                !packWidth && (packWidth = 328);
            }

            moduleLeft = $m.position().left;
            moduleRight = moduleLeft + moduleWidth;
            // west
            if (axis == 'nw' || axis == 'w' || axis == 'sw') {
                maxWidth = moduleRight;
                maxHeight = (maxWidth * moduleHeight) / moduleWidth;
                $m.resizable('option', 'maxWidth', maxWidth);
                $m.resizable('option', 'maxHeight', maxHeight);
            } else if (axis == 'ne' || axis == 'e' || axis == 'se') {
                // east
                maxWidth = packWidth - moduleLeft;
                maxHeight = (maxWidth * moduleHeight) / moduleWidth;
                $m.resizable('option', 'maxWidth', maxWidth);
                $m.resizable('option', 'maxHeight', maxHeight);
            } else {
                $m.resizable('option', 'maxHeight', null);
            }

            resizeAlign.begin = true;

            undoArgs = [$m, $m.width(), $m.height(), packWidth, true];
        },
        resize(event, ui) {
            // 隐藏工具条
            Fai.top.$('#fkEditor-toolBar-' + moduleId).hide();
            $m.children('.ui-resizable-handle').hide();

            var moduleWidth = $(this).width() - 1;
            var moduleHeight = $(this).height();

            // 更新图片尺寸
            refreshModuleSize($m, moduleWidth, moduleHeight, packWidth, false);
            refreshPicShape(moduleId, imgShapeEftData); //更新图片形状

            var direction = ['top', 'right', 'bottom', 'left'];
            resizeAlign.init($(this), $m, event, direction);
            showResizeDockTip(event, ui.helper);
        },
        stop() {
            var moduleWidth = $m.width(),
                moduleHeight = $m.height();

            _floatImgResizeStop($m, moduleWidth, moduleHeight, packWidth, true);

            Vue.prototype.$undo.undoRun(function () {}, {
                type: 'module',
                doArgs: [$m, moduleWidth, moduleHeight, packWidth, true],
                undoArgs,
                undoSame: false,
                redoSame: false,
                undoFn: resizeUnfo,
                redoFn: resizeUnfo,
            });
        },
    });
};

function resizeUnfo($module, moduleWidth, moduleHeight, packWidth, resizeStop) {
    $module.css({
        width: moduleWidth,
        height: moduleHeight,
    });

    _floatImgResizeStop($module, moduleWidth, moduleHeight, packWidth, resizeStop);
}

function _floatImgResizeStop($m, moduleWidth, moduleHeight, packWidth) {
    var moduleTop = $m[0].offsetTop,
        moduleLeft = $m[0].offsetLeft,
        moduleId = $m.attr('id').replace('module', ''),
        extSpace = 5,
        content = $m.data('contentInfo') ? $m.data('contentInfo') : {},
        imgShapeEftData = content.shapeEffect ? content.shapeEffect : {},
        packId = $m.attr('_inpack');
    $('#absAlignTop, #absAlignRight, #absAlignBottom, #absAlignLeft').hide();

    var topUnSee = -moduleTop > moduleHeight - extSpace,
        leftUnSee = -moduleLeft > moduleWidth - extSpace;

    //保护，resize,不能把模块伸缩到不可见的地方
    if (topUnSee) {
        $m.css('top', '0');
    }

    if (leftUnSee) {
        $m.css('left', '0');
    }
    Fai.top.currentResizeId = null;
    resizeMask(false); //显示遮罩层
    // 更新图片尺寸
    refreshModuleSize($m, moduleWidth, moduleHeight, packWidth, true);

    // 对应 resize start -- 暂时不太理解，跟随site
    if (moduleWidth < 35 || moduleHeight < 35) {
        $m.find('.ui-resizable-floatImgTopRight').css({
            width: '8px',
            height: '8px',
            top: '-4px',
            right: '-4px',
        });
        $m.find('.ui-resizable-floatImgTopLeft').css({
            width: '8px',
            height: '8px',
            top: '-4px',
            left: '-4px',
        });
        $m.find('.ui-resizable-floatImgBottomRight').css({
            width: '8px',
            height: '8px',
            bottom: '-3px',
            right: '-4px',
        });
        $m.find('.ui-resizable-floatImgBottomLeft').css({
            width: '8px',
            height: '8px',
            bottom: '-3px',
            left: '-4px',
        });
    } else {
        $m.find('.ui-resizable-floatImgTopRight').css({
            width: '20px',
            height: '20px',
            top: '-9px',
            right: '-9px',
        });
        $m.find('.ui-resizable-floatImgTopLeft').css({
            width: '20px',
            height: '20px',
            top: '-9px',
            left: '-9px',
        });
        $m.find('.ui-resizable-floatImgBottomRight').css({
            width: '20px',
            height: '20px',
            bottom: '-9px',
            right: '-9px',
        });
        $m.find('.ui-resizable-floatImgBottomLeft').css({
            width: '20px',
            height: '20px',
            bottom: '-9px',
            left: '-9px',
        });
    }

    //停止时候显示拖动把手 -- 对应start
    $m.children('.ui-resizable-handle').show();
    //更新图片形状
    refreshPicShape(moduleId, imgShapeEftData);

    // 再取一次
    moduleWidth = $m.width();
    moduleHeight = $m.height();

    if (packId !== undefined) {
        var moduleAttr = window.$store.getters.currentModuleForId(packId);
        moduleAttr.content = moduleAttr.content || {};
        moduleAttr.contentChange = true;
    }

    Vue.prototype.$designer.styleChanged();

    $m.attr('_autoHeight', 0).attr('_height', moduleHeight);
    $m.attr('_autoWidth', 0).attr('_width', moduleWidth);
    hideResizeDockTip();
    // 更新热区视图 qjie
    updateArea(moduleId);

    // 图片模块2.0
    if (isModuleV2(53)) {
        let moduleData = window.$store.getters.currentModuleForId(moduleId) || {};
        let pattern = moduleData.pattern || {};
        pattern.w = moduleWidth;
        pattern.h = moduleHeight;
    }
}

// 更新resize后的新样式
function refreshModuleSize($module, moduleWidth, moduleHeight, packWidth, resizeStop) {
    // resizeStop : true,是stop事件触发 needCheckSize,1:窗口左，2:右
    var content = $module.data('contentInfo') ? $module.data('contentInfo') : {};
    var fontSize, $fontIcon;
    var fixLineNum = 8;
    var handleLine_width = Math.floor((moduleWidth - fixLineNum - 1) / 2) - 1;
    var handleLine_Height = Math.floor((moduleHeight - fixLineNum - 1) / 2) - 1; //这里减1 是因为等比例 动态改变图片大小时候 拖动线有可能是奇数 造成总宽比模块大1px 而换行重叠

    $module.find('.J_float_img').css({
        width: moduleWidth,
        height: moduleHeight,
    });

    // 字体图标改变
    if (content.ifi) {
        $fontIcon = $module.find('.J_fonticon');
        fontSize = moduleWidth > moduleHeight ? moduleHeight : moduleWidth;
        if ($fontIcon.attr('class').indexOf('mr') != -1 && moduleWidth < 260) {
            // mr,260？？？是啥意思，没看懂
            fontSize = moduleWidth / 2;
        }
        $fontIcon.css({
            'font-size': fontSize + 'px',
            'line-height': moduleHeight + 'px',
        });
    }

    if (!resizeStop) {
        //resize情况，一直需要 拖动框线长
        $module.children('.ui-resizable-s').children('.imgResizeLine').width(handleLine_width);
        $module.children('.ui-resizable-n').children('.imgResizeLine').width(handleLine_width);
        $module.children('.ui-resizable-e').children('.imgResizeLine').height(handleLine_Height);
        $module.children('.ui-resizable-w').children('.imgResizeLine').height(handleLine_Height);
    }
}

//浮动图片的hover时resize的辅助框
function addResizableImgHandles($m, $self, borderW, fixLineNum, changedIndex, lineAjust) {
    changedIndex = changedIndex || false;
    lineAjust = lineAjust || false;

    //恢复默认时，会重绘图片的边框线，所以要先清除掉边框线
    $m.find('.imgResizeLine-first').remove();
    $m.find('.imgResizeLine-second').remove();

    var html =
            "<div class='imgResizeLine imgResizeLine-first'></div><div class='imgResizeLine imgResizeLine-second'></div>",
        $sw = $m.children('.ui-resizable-sw'),
        $se = $m.children('.ui-resizable-se'),
        $nw = $m.children('.ui-resizable-nw'),
        $ne = $m.children('.ui-resizable-ne'),
        $s = $m.children('.ui-resizable-s'),
        $w = $m.children('.ui-resizable-w'),
        $n = $m.children('.ui-resizable-n'),
        $e = $m.children('.ui-resizable-e');

    var resizableLinePos = -4 - borderW;
    var resizableBoxPos = -9 - borderW;
    var ZIndex = changedIndex ? '9031' : '5';

    $sw.addClass('ui-resizable-imgBottomLeftTip ui-resizable-floatImgBottomLeft').css({
        bottom: resizableBoxPos,
        left: resizableBoxPos,
        'z-index': ZIndex,
    });
    $se.addClass('ui-resizable-imgBottomRightTip ui-resizable-floatImgBottomRight').css({
        bottom: resizableBoxPos,
        right: resizableBoxPos,
        'z-index': ZIndex,
    });
    $nw.addClass('ui-resizable-imgTopLeftTip ui-resizable-floatImgTopLeft').css({
        top: resizableBoxPos,
        left: resizableBoxPos,
        'z-index': ZIndex,
    });
    $ne.addClass('ui-resizable-imgTopRightTip ui-resizable-floatImgTopRight').css({
        top: resizableBoxPos,
        right: resizableBoxPos,
        'z-index': ZIndex,
    });
    $s.addClass('ui-resizable-imgBottomTip ui-resizable-floatImgBottom')
        .css({ bottom: resizableLinePos, 'z-index': ZIndex })
        .append(html);
    $w.addClass('ui-resizable-imgLeftTip ui-resizable-floatImgLeft')
        .css({ left: resizableLinePos, 'z-index': ZIndex })
        .append(html);
    $n.addClass('ui-resizable-imgTopTip ui-resizable-floatImgTop')
        .css({ top: resizableLinePos, 'z-index': ZIndex })
        .append(html);
    $e.addClass('ui-resizable-imgRightTip ui-resizable-floatImgRight')
        .css({ right: resizableLinePos, 'z-index': ZIndex })
        .append(html);

    var handleLine_width = parseInt(($m.width() - fixLineNum - 1) / 2), // 1：减1是由于rem 小数点约进，缓冲
        handleLine_Height = parseInt(($m.height() - fixLineNum - 1) / 2);

    //拖动框线长
    if (lineAjust) {
        $s.children('.imgResizeLine-second').css({
            width: handleLine_width + 'px',
        });
        $n.children('.imgResizeLine-second').css({
            width: handleLine_width + 'px',
        });
        $e.children('.imgResizeLine-second').css({
            height: handleLine_Height + 'px',
        });
        $w.children('.imgResizeLine-second').css({
            height: handleLine_Height + 'px',
        });

        $s.children('.imgResizeLine-first').css({
            width: handleLine_width - 1 + 'px',
        });
        $n.children('.imgResizeLine-first').css({
            width: handleLine_width - 1 + 'px',
        });
        $e.children('.imgResizeLine-first').css({
            height: handleLine_Height - 1 + 'px',
        });
        $w.children('.imgResizeLine-first').css({
            height: handleLine_Height - 1 + 'px',
        });
    } else {
        $s.children('.imgResizeLine').css({ width: handleLine_width + 'px' });
        $n.children('.imgResizeLine').css({ width: handleLine_width + 'px' });
        $e.children('.imgResizeLine').css({ height: handleLine_Height + 'px' });
        $w.children('.imgResizeLine').css({ height: handleLine_Height + 'px' });
    }

    if (!$self) {
        return;
    }
    //用于识别是否按等比例调整大小
    $sw.mouseover(function () {
        $self.attr('aspectRatio', '1');
    });
    $se.mouseover(function () {
        $self.attr('aspectRatio', '1');
    });
    $ne.mouseover(function () {
        $self.attr('aspectRatio', '1');
    });
    $nw.mouseover(function () {
        $self.attr('aspectRatio', '1');
    });
    $n.mouseover(function () {
        $self.attr('aspectRatio', '0');
    });
    $e.mouseover(function () {
        $self.attr('aspectRatio', '0');
    });
    $s.mouseover(function () {
        $self.attr('aspectRatio', '0');
    });
    $w.mouseover(function () {
        $self.attr('aspectRatio', '0');
    });
}

// 更新图片形状
function refreshPicShape(moduleId, refreshShapeEft) {
    var $module = $('#module' + moduleId),
        isInit = $module.data('isInit'),
        content = $module.data('contentInfo') ? $module.data('contentInfo') : {};
    removePicShapeStyle(moduleId);
    if (isInit || content.ifi) {
        return;
    }
    picShape(moduleId, refreshShapeEft);
}

//
const removePicShapeStyle = function (id) {
    var $module = $('#module' + id),
        $floatImgWrap = $module.find('.J_floatImgWrap'),
        $img = $floatImgWrap.find('.J_float_img');
    $floatImgWrap.css({
        width: '',
        height: '',
        margin: '',
        'border-radius': '',
    });
    $img.css({
        margin: '',
    });
};

//浮动图片图片形状 -- 字体图标暂不支持
const picShape = function (id, imgShapeEftData) {
    var $module = jm('#module' + id),
        $floatImgWrap = $module.find('.J_floatImgWrap'),
        $img = $floatImgWrap.find('.J_float_img'),
        $visibleImg = $floatImgWrap.find('.J_photo'), //$floatImgWrap.find(".J_float_img:visible"),$img.eq(0).is(':visible') ? $img.eq(0) : $img.eq(1) -- 字体图标暂不支持img.eq(0)
        borderRadius = imgShapeEftData.borderRadius ? imgShapeEftData.borderRadius : 20,
        imgshapetype = imgShapeEftData.imgShapeType ? imgShapeEftData.imgShapeType : 0,
        imgHeight = $visibleImg.offset().height,
        imgWidth = $visibleImg.offset().width,
        minSize,
        maxSize,
        _htmlFontSize = window._htmlFontSize,
        isWidthLarge = false;
    if (imgshapetype == 1 || imgshapetype == 3) {
        if (imgshapetype == 3) {
            $floatImgWrap.css('border-radius', '50%');
        }
        if (imgHeight == imgWidth) {
            return;
        }
        if (imgHeight < imgWidth) {
            minSize = imgHeight;
            maxSize = imgWidth;
            isWidthLarge = true;
        } else {
            minSize = imgWidth;
            maxSize = imgHeight;
        }
        $floatImgWrap.css({
            width: minSize / _htmlFontSize + 'rem',
            height: minSize / _htmlFontSize + 'rem',
        });
        $img.css('margin', '0');
        $floatImgWrap.css('margin', '0');
        if (isWidthLarge) {
            $img.css('margin-left', -(maxSize - minSize) / (_htmlFontSize * 2) + 'rem'); // 2*20.5 = 41 20.5：管理态htmlfontsize
            $floatImgWrap.css('margin-left', (maxSize - minSize) / (_htmlFontSize * 2) + 'rem');
        } else {
            $img.css('margin-top', -(maxSize - minSize) / (_htmlFontSize * 2) + 'rem');
            $floatImgWrap.css('margin-top', (maxSize - minSize) / (_htmlFontSize * 2) + 'rem');
        }
    } else if (imgshapetype == 2) {
        $floatImgWrap.css('border-radius', borderRadius / _htmlFontSize + 'rem');
    }
};

// 浮动图片热区链接生成area qjie
const updateArea = function (moduleId) {
    if (isModuleV2(53)) {
        return;
    }

    var $module = $('#module' + moduleId),
        img = $module.find('img'),
        imgHeight = parseInt($module.data('imgHeight')),
        moduleWidth = parseInt($module.attr('_width')),
        moduleHeight = parseInt($module.attr('_height')),
        hotArea = $module.data('hotAreaOldList') || [],
        $a = $module.find('.J_floatImg_jump'),
        linkInfo = $module.data('linkInfo'),
        jMode = $module.data('jMode') || 0;

    var hotAreaWidthScale = moduleWidth / 440, // 编辑热区图片区域宽度
        hotAreaHeightScale = moduleHeight / imgHeight;

    // 若area存在，则删除
    var $map = $module.find('map').get(0);
    var $container = $module.find('.J_floatImgWrap');
    if ($map) {
        $map.remove();
    }

    if (jMode == 1) {
        // 移除原先单链接事件
        $a.attr('href', 'javascript:;');
        $a.removeAttr('onclick');
        // 说明是选择了热区链接
        var hotAreaMap = "<map name='hotAreaMap" + moduleId + "'>";
        var top, left, width, height, wl, ht, hotAreaArea, hideFocus, areaLists;
        hideFocus = "onfocus='this.blur()'";
        // 将数组倒置一下渲染，area标签按顺序层级逐渐递减，由于后来居上原则，所以要倒一下渲染
        hotArea.reverse();
        hotArea.forEach(function (item) {
            top = (!item.t ? 0 : item.t) * hotAreaHeightScale;
            left = (!item.l ? 0 : item.l) * hotAreaWidthScale;
            width = (!item.w ? 0 : item.w) * hotAreaWidthScale;
            height = (!item.h ? 0 : item.h) * hotAreaHeightScale;
            wl = width + left;
            ht = height + top;
            hotAreaArea =
                "<area title='" +
                item.tip +
                "' shape='rect' coords='" +
                left +
                ',' +
                top +
                ',' +
                wl +
                ',' +
                ht +
                "' " +
                hideFocus +
                ' />';
            hotAreaMap += hotAreaArea;
        });
        hotAreaMap += '</map>';
        $container.append(hotAreaMap);

        // 这里采用jquery将jurl塞到href中
        areaLists = Array.from($container.find('area'));
        areaLists.forEach(function (item, index) {
            $(item).attr('href', hotArea[index].jurl);
        });

        // 再次将数组倒置回原样
        hotArea.reverse();

        if (!img.attr('usemap')) {
            img.attr('usemap', '#hotAreaMap' + moduleId);
        }
        setTimeout(function () {
            areaLists.reverse().forEach(function (item, index) {
                // 问题： 大保存后应该删除事件弹窗
                $(item).bind('click', function (event) {
                    if (Fai.top._changeStyleNum > 0) {
                        event.preventDefault();
                        Vue.prototype.$designer.popupStyleChangeBodyWindow('您的网站设计已经更改，是否立即保存？', {
                            fun() {
                                // Fai.top.saveReloadPreview = true;
                            },
                        });
                    } else {
                        // 外链不支持跳转
                        if (hotArea[index].jType == 103) {
                            event.preventDefault();
                            Fai.top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                        }
                    }
                });
            });
        });
    } else {
        if (linkInfo.jUrl == '' || linkInfo.jUrl == null) {
            $a.attr('href', 'javascript:;');
        } else {
            var href = '',
                onclickEvt = '';
            if (linkInfo.jUrl.length > 12 && linkInfo.jUrl.substring(0, 10) == 'javascript') {
                href += ' javascript:; ';
                onclickEvt += linkInfo.jUrl.substring(11) + 'return false;';
                $a.attr('onclick', onclickEvt);
            } else {
                href += linkInfo.jUrl;
            }
            $a.attr('href', href);
        }
    }
};

// 图片模块2.0更新图片热区
export const updateAreaVer2 = function (moduleId, imgHeight, hotList) {
    // 这里做撤销逻辑
    try {
        let moduleData = window.$store.getters.currentModuleForId(moduleId) || {};
        if (moduleData != null) {
            var undoArgs = {
                imgHeight: moduleData.content.imgHeight,
                hotList: JSON.parse(JSON.stringify(moduleData.content.hotAreaList)),
            };

            var doArgs = {
                imgHeight,
                hotList,
            };

            moduleData.content.imgHeight = imgHeight;
            moduleData.content.hotAreaList = hotList;

            Vue.prototype.$undo.undoRun(
                function (options) {
                    moduleData.content.imgHeight = options.imgHeight;
                    moduleData.content.hotAreaList = options.hotList;
                },
                {
                    type: 'hotAreaType',
                    detail: 'hotAreaDetail',
                    doArgs: [doArgs],
                    undoArgs: [undoArgs],
                }
            );
        }
    } catch (e) {
        console.log(e);
    }
};

// 生成或删除遮罩层 -- 暂时用于图片模块
const resizeMask = function (flag) {
    //true:添加，false删除
    var $mobiReview = Fai.top.$('#g_web');
    var mobiReviewLeft = $mobiReview.offset().left;
    var mobiReviewWidth = $mobiReview.outerWidth();
    var mobiReviewRight = mobiReviewLeft + mobiReviewWidth;
    var windowWidth = Fai.top.$(Fai.top).width();
    var windowHeight = Fai.top.$(Fai.top).height();
    var html = '';
    var $resizeMask = Fai.top.$('#maskForResize');
    if (flag) {
        if ($resizeMask && $resizeMask.length > 0) {
            return;
        }
        html =
            '<div class="fk-maskForResize" id="maskForResize">' +
            '<div class="f-maskResizeLeft f-maskResize" style="width:' +
            mobiReviewLeft +
            'px;height:' +
            windowHeight +
            'px;"></div>' +
            '<div class="f-maskResizeRight f-maskResize" style="width:' +
            (windowWidth - mobiReviewRight) +
            'px;left:' +
            mobiReviewRight +
            'px;height:' +
            windowHeight +
            'px;"></div>' +
            '</div>';
        Fai.top.$('body').append(html);
        Fai.top
            .$('#maskForResize')
            .off('mouseup.resizeImg')
            .on('mouseup.resizeImg', function () {
                if (Fai.top.currentResizeId) {
                    var $module = $(getPreviewObject('module' + Fai.top.currentResizeId));
                    $module.resizable('widget').trigger('mouseup');
                }
            })
            .off('click.resizeImg')
            .on('click.resizeImg', function () {
                //这个是防止考虑不到的情况下，去除遮罩层
                $resizeMask.remove();
            });
    } else {
        $resizeMask.remove();
    }
};
