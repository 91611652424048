<template>
    <div :style="columnsStyle">
        <VerticalItem
            v-for="(item, index) in contentList"
            :key="item.picId"
            :module-content="module.content"
            :module-id="module.id"
            :photo-item="item"
            :link-type="linkType"
            :img-href="imgHref(item.jumpUrl)"
            :img-index="index + 1"
            :is-empty="isEmptyText"
            :slides-index="item.index"
            :max-height="imgStyleHeight(index)"
            :loading-path="options.loadingPath"
            :no-lazy-load="noLazyLoad"
            :list-length="contentList.length"
        ></VerticalItem>
    </div>
</template>
<script>
import VerticalItem from './vertical-item.vue';
import { optionsMixins } from '../mixins/optionsMixins';

export default {
    name: 'VerticalList',
    components: {
        VerticalItem,
    },
    mixins: [optionsMixins],
    inject: ['module', 'options'],
    props: {
        contentList: {
            type: Array,
            default() {
                return [];
            },
        },
        photoSwipeList: {
            type: Array,
            default() {
                return [];
            },
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        columnsType() {
            return this.module.content.icolumns;
        },
        picSpaceContent() {
            return this.module.content.mhighs.ispace;
        },
        isNewModule() {
            return this.module.content.inm == 1;
        },
        picScaleType() {
            return this.module.content.picScale;
        },
        columnsStyle() {
            let style = {};
            if (this.isNewModule) {
                if (this.columnsType == 0) {
                    style['width'] = '100%';
                    style['box-sizing'] = 'border-box';
                    style['padding'] = '0 0.75rem';
                } else if (this.columnsType == 1) {
                    style['display'] = 'flex';
                    style['flex-wrap'] = 'wrap';
                    style['width'] = '100%';
                    style['box-sizing'] = 'border-box';
                    style['padding-left'] = '0.75rem';
                    style['padding-right'] = '0.75rem';
                } else if (this.columnsType == 2) {
                    style['display'] = 'flex';
                    style['flex-wrap'] = 'wrap';
                    style['width'] = '100%';
                    style['box-sizing'] = 'border-box';
                    style['padding-left'] = '0.75rem';
                    style['padding-right'] = '0.75rem';
                }
            } else {
                style['display'] = 'flex';
                style['flex-wrap'] = 'wrap';
                style['box-sizing'] = 'border-box';
            }

            return style;
        },

        imgDivWidth() {
            let width = 0;
            switch (this.columnsType) {
                case 0:
                    width = 14.5;
                    break;
                case 1:
                    width = this.isNewModule ? 7 : 7.2;
                    break;
                case 2:
                    width = 4.5;
                    break;
            }
            return width;
        },
        imgDivMaxHeight() {
            let maxHeight = 0;
            let self = this;
            let htmlFontSize = this.$store.state.htmlFontSize; // 同管理态一致。一列纵向管理态展示是rem形式，访客态应该是一样的rem
            if (self.photoSwipeList.length) {
                self.photoSwipeList.forEach(function (value) {
                    var imgHeight = 0;

                    if (value.height / htmlFontSize < self.imgDivWidth && self.picScaleType == 0) {
                        imgHeight = parseFloat(value.height / htmlFontSize);
                    } else {
                        imgHeight = parseFloat((value.height * self.imgDivWidth) / value.width);
                    }
                    if (imgHeight > maxHeight) {
                        maxHeight = imgHeight;
                    }
                });
            }
            return maxHeight;
        },
    },
    methods: {
        imgStyleHeight(index) {
            let height = this.imgDivMaxHeight;
            if (this.photoSwipeList.length) {
                let item = this.photoSwipeList[index];
                var scaleInit = Number(item.width / item.height).toFixed(5);
                var scaleLast = Number(this.imgDivWidth / this.imgDivMaxHeight).toFixed(5);
                if (this.picScaleType == 2) {
                    if (scaleLast <= scaleInit) {
                        height = this.imgDivMaxHeight;
                    } else {
                        height = this.imgDivWidth / scaleInit;
                    }
                }
            }
            return height;
        },
    },
};
</script>
