<template>
    <div
        :id="photoItem.picId"
        :style="waterFallItemContainerStyle"
        class="waterfall_item"
        :data-picid="photoItem.picId"
    >
        <a
            class="photo_wf_item"
            :style="waterFallItemStyle"
            :href="imgHref"
            :rel="photoItem.nf ? 'nofollow' : ''"
            :onclick="jumpOnclickStr"
            @click="photoSlidesshow"
        >
            <template v-if="waterFallHeightType == 0">
                <div :style="photoItemStyle">
                    <img
                        class="J_img_lazyload"
                        :src-original="photoItem.picThumbPath"
                        :src="noLazyLoad ? photoItem.picThumbPath : loadingPath"
                        width="100%"
                        :style="photoItemImgStyle"
                    />
                </div>
            </template>
            <template v-else>
                <div
                    ref="itemFix"
                    v-drag-resize="_resizeOptions"
                    class="J_img_lazyload"
                    :src-original="photoItem.picThumbPath"
                    :style="photoItemFixStyle"
                ></div>
            </template>
            <div class="photo_wf_item_text" :style="waterFallTextStyle">
                <div
                    v-show="showTitle"
                    class="photo_wf_item_name"
                    :style="waterFallTitleStyle"
                    v-html="titleData"
                ></div>
                <div v-show="showDesc" class="photo_wf_item_desc" :style="waterFallDescStyle" v-html="descData"></div>
            </div>
        </a>
    </div>
</template>
<script>
import { photoMixins } from '../../mixins';
import { photoImgScaleStyleMap, photoTextAndImgStyleMap } from '../../utils';
export default {
    name: 'WaterFallItem',
    mixins: [photoMixins],
    props: {
        photoItem: {
            type: Object,
            default() {
                return {};
            },
        },
        imgHref: {
            type: String,
        },
        isHeadOrTail: {
            type: Boolean,
            default: false,
        },
        isLeft: {
            type: Boolean,
            default: true,
        },
        imgIndex: {
            type: Number,
            default: 0,
        },
        listLength: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        waterFallItemContainerStyle() {
            let style = {};
            if (this.picSpace.type == 1) {
                style['box-sizing'] = 'border-box';
                this.isLeft
                    ? (style['paddingRight'] = `${(this.picSpace.horizontal * 0.5) / this.htmlFontSize}rem`)
                    : (style['paddingLeft'] = `${(this.picSpace.horizontal * 0.5) / this.htmlFontSize}rem`);
            }

            return style;
        },

        nameAndDescIsHidden() {
            return !this.showTitle && !this.showDesc;
        },

        waterFallItemStyle() {
            let style = {};
            style = {
                ...photoTextAndImgStyleMap[this.textStyleType],
                ...this.borderRadiusStyle,
            };

            style.width = '100%';
            if (this.picSpace.type == 0) {
                style['margin-bottom'] = this.nameAndDescIsHidden ? '0.5rem' : '0.65rem';
            } else {
                let spaceHeightRem = this.picSpace.vertical / this.htmlFontSize;
                if (this.imgIndex < this.listLength) {
                    // style['margin-bottom'] = this.nameAndDescIsHidden ? (spaceHeightRem + 'rem') : (2 * spaceHeightRem + 'rem');
                    style['margin-bottom'] = spaceHeightRem + 'rem';
                }
            }
            // style['padding'] = '1px';	// 处理边框border可能被消除情况
            style = { ...style, ...this.textOriginColorStyle };
            return style;
        },

        photoItemStyle() {
            let style = {};
            style['border-radius'] =
                this.textStyleType != 3
                    ? `${this.borderRadiusSize}rem ${this.borderRadiusSize}rem 0 0`
                    : `${this.borderRadiusSize}rem`;
            if (this.isEmpty) {
                style['border-radius'] = `${this.borderRadiusSize}rem`;
            }
            return style;
        },

        waterFallTextStyle() {
            let style = {};
            // Object.assign(style, this.textContentStyle);
            style = { ...this.textContentStyle };
            if (this.textStyleType != 3) {
                style.paddingLeft = '0.375rem';
                style.paddingRight = '0.625rem';
                style.paddingBottom = '0.525rem';
            } else {
                style.paddingLeft = '0.05rem';
                style.paddingRight = '0.375rem';
            }
            if (this.nameAndDescIsHidden) {
                style.display = 'none';
            }
            if (this.isEmpty) {
                style['display'] = 'none';
            }
            return style;
        },

        photoItemImgStyle() {
            let style = {};
            // 圆角
            style.borderRadius =
                this.textStyleType != 3
                    ? `${this.borderRadiusSize}rem ${this.borderRadiusSize}rem 0 0`
                    : `${this.borderRadiusSize}rem`;

            if (this.nameAndDescIsHidden || this.isEmpty) {
                style.borderRadius = `${this.borderRadiusSize}rem`;
            }
            // // 图片高度
            // if(this.picSize.htype == 1) {
            // 	style['height'] = `${this.picSize.height}px`
            // }

            return style;
        },

        waterFallDescStyle() {
            let style = {};
            if (this.showTitle) {
                style.paddingTop = '0.3rem';
            }
            if (this.nameLink.type == 0) {
                style.fontSize = '0.55rem';
                style['color'] = '#666';
            }
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.nameLink));

            let wrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.nameLink);
            style = { ...style, ...wrapStyle, ...linkStyle };

            style.textAlign = this.textAlignStyle;
            return style;
        },
        waterFallTitleStyle() {
            let style = {};
            style.fontWeight = 'bold';

            if (this.titleLink.type == 0) {
                style.fontSize = '0.65rem';
                style.color = '#222';
            }
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));
            let wrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.titleLink);

            style = { ...style, ...wrapStyle, ...linkStyle };
            style.textAlign = this.textAlignStyle;
            return style;
        },
        borderRadiusStyle() {
            let style = {};
            style.borderRadius =
                this.photoRadius.type == 1 ? `${this.photoRadius.size / this.htmlFontSize}rem` : '0.15rem';
            return style;
        },
        borderRadiusSize() {
            return this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize : 0.15;
        },
        textOriginColorStyle() {
            let style = {};
            if (this.textStyleType != 3) {
                if (this.textStyleType != 2) {
                    style.background = '#fff';
                }
                if (this.textOriginColor.type == 1) {
                    style.background = this.textOriginColor.color;
                }
            }
            return style;
        },

        photoItemFixStyle() {
            let style = {};
            style.backgroundImage = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;
            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            style = { ...style, ...photoImgScaleStyleMap[this.picScaleType] };
            if (this.photoRadius.type == 1) {
                style.borderTopLeftRadius = this.photoRadius.size / this.htmlFontSize + 'rem';
                style.borderTopRightRadius = this.photoRadius.size / this.htmlFontSize + 'rem';
            }

            style.borderRadius =
                this.textStyleType != 3
                    ? `${this.borderRadiusSize}rem ${this.borderRadiusSize}rem 0 0`
                    : `${this.borderRadiusSize}rem`;

            // style['margin-bottom'] = this.nameAndDescIsHidden ? '0' : '0.6rem';
            if (this.nameAndDescIsHidden || this.isEmpty) {
                style.borderRadius = `${this.borderRadiusSize}rem`;
            }
            this.$nextTick(() => {
                this.setPhotoItemHeight();
            });

            return style;
        },

        textContentStyle() {
            let style = {};
            style['padding-top'] = this.nameAndDescIsHidden ? '0' : '0.4rem';
            style['width'] = '100%';
            style['box-sizing'] = 'border-box';
            return style;
        },

        headTailContent() {
            return this.moduleContent.mhighs.htprop;
        },

        normalContent() {
            return this.moduleContent.mhighs.nprop;
        },
    },
    watch: {
        headTailContent: {
            handler() {
                if (this.isHeadOrTail) {
                    this.$nextTick(() => {
                        this.setPhotoItemHeight();
                    });
                }
            },
            deep: true,
        },
        normalContent: {
            handler() {
                if (!this.isHeadOrTail) {
                    this.$nextTick(() => {
                        this.setPhotoItemHeight();
                    });
                }
            },
            deep: true,
        },
    },
    methods: {
        setPhotoItemHeight() {
            let itemFixDom = this.$refs['itemFix'];
            if (itemFixDom != null) {
                let width = itemFixDom.clientWidth;
                let height = 0;
                if (this.isHeadOrTail) {
                    height =
                        this.headTailContent.type == 1
                            ? (width * this.headTailContent.h) / this.headTailContent.w
                            : width;
                } else {
                    height =
                        this.normalContent.type == 1
                            ? (width * this.normalContent.h) / this.normalContent.w
                            : (width * 4) / 3;
                }

                itemFixDom.style.height = height + 'px';
            }
        },
    },
};
</script>
<style>
.photo_wf_item {
    display: inline-block;
    width: 7rem;
    box-sizing: border-box;
}
</style>
