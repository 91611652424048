<template>
    <div class="select_style">
        <input
            readonly
            unselectable="on"
            :placeholder="placeHolder"
            :value="currentVal"
            class="select_input input_style"
            @click.stop="toggleList"
        />
        <span class="arrow faisco-icons-S000112" @click.stop="toggleList" />
        <div v-show="list.length && showList" class="select_list_wrap">
            <ul class="select_list">
                <li
                    v-for="item in list"
                    class="select_item J_noOpenDesigner"
                    :class="{ select_active: value === item }"
                    @click="selectItem(item)"
                    >{{ item }}</li
                >
            </ul>
        </div>
    </div>
</template>

<style lang="scss">
.p_popup_layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
}
.p_popup_layer .popup_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
}
.p_popup_layer .popup_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s;
    transform: translate(0, 100%);
}
.p_popup_layer .popup_content.active {
    transform: none;
}
/* 手机选择器 */
.p_mobi_picker {
    position: relative;
    background: #fff;
    user-select: none;
}
.p_mobi_picker:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 76px;
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #e3e2e8;
    border-left: 0;
    border-right: 0;
    pointer-events: none;
}
.p_mobi_picker:after {
    content: '';
    display: block;
    position: absolute;
    top: 42px;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.95), hsla(0, 0%, 100%, 0.6)),
        linear-gradient(0deg, hsla(0, 0%, 100%, 0.95), hsla(0, 0%, 100%, 0.6));
    background-position: top, bottom;
    background-size: 100% 76px;
    background-repeat: no-repeat;
    pointer-events: none;
}
.p_mobi_picker .header {
    height: 41px;
    line-height: 41px;
    border-bottom: 1px solid #eee;
}
.p_mobi_picker .list {
    height: 190px;
    list-style: none;
    overflow: hidden;
}
.p_mobi_picker .list:before,
.p_mobi_picker .list:after {
    content: '';
    display: block;
    height: 76px;
}
.p_mobi_picker .list .item {
    text-align: center;
    font-size: 18px;
    height: 38px;
    line-height: 38px;
}
.p_mobi_picker .header .btn {
    font-size: 16px;
    color: #333;
    display: inline-block;
    padding: 0 30px;
    cursor: pointer;
}
.p_mobi_picker .header .confirm {
    color: #5874d8;
    float: right;
}
.select_style {
    position: relative;
}
.select_style .arrow {
    position: absolute;
    right: 0.65rem;
    top: 0;
    font-size: 0.55rem;
    line-height: 2rem;
    color: #666;
    cursor: pointer;
}
.select_style .select_input {
    cursor: pointer;
    text-indent: 0.5rem;
    outline: none;
}
.select_style .select_item {
    font-size: 0.6rem;
    color: #333;
    line-height: 1.8rem;
    padding-left: 0.6rem;
    box-sizing: border-box;
    cursor: pointer;
}
.select_style .select_item:hover {
    background: #f0f0f0;
}
.select_style .select_list {
    overflow: auto;
}
.select_style .select_list::-webkit-scrollbar {
    width: 0.2rem;
}
.select_style .select_list::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}
.select_style .select_list::-webkit-scrollbar-track {
    background-color: #f7f7f7;
}
.select_style .select_list_wrap {
    margin-top: 10px;
    padding: 8px 6px;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 999;
}
</style>

<script>
import { context } from '@jz/utils';
export default {
    name: 'CustomSearchSelect',
    props: {
        value: String,
        list: {
            type: Array,
            default: () => [],
        },
        placeHolder: {
            type: String,
            default: '',
        },
        contentKey: {
            type: String,
            default: 'content',
        },
    },
    data() {
        return {
            oldTouchY: 0, //上一次的位置
            speedTouchY: 0, //移动速度
            moveTimer: null,
            showList: false,
            currentVal: this.value,
        };
    },
    methods: {
        selectItem(item) {
            this.$emit('input', item);
            this.currentVal = item;
            this.showList = false;
        },
        toggleList() {
            this.showList = !this.showList;
            if (this.showList) {
                Vue.nextTick(() => {
                    jm(context.document).one('click', () => {
                        this.showList = false;
                    });
                });
            }
        },
    },
};
</script>
