<template>
    <div class="m_custom_search_field_content">
        <template v-if="item.type == 0">
            <custom-search-input
                v-model="item.value"
                class="u_custom_search_entry--form"
                :place-holder="item.placeholder"
            ></custom-search-input>
        </template>
        <template v-if="funcType === 1 && item.type == 1">
            <custom-search-multi-line
                class="u_custom_search_entry--form"
                :form-item="item"
                :maxlength="1000"
                :place-holder="inputPlaceHolder"
            ></custom-search-multi-line>
        </template>
        <template v-else-if="(item.type == 1 && funcType === 0) || (funcType === 1 && item.type == 2)">
            <custom-search-radio
                v-model="item.value"
                class="u_custom_search_entry--form"
                :options="item.options"
            ></custom-search-radio>
        </template>
        <template v-else-if="(item.type == 2 && funcType === 0) || (funcType === 1 && item.type == 3)">
            <custom-search-checkbox
                v-model="item.value"
                class="u_custom_search_entry--form"
                :options="item.options"
            ></custom-search-checkbox>
        </template>
        <template v-else-if="(item.type == 3 && funcType === 0) || (funcType === 1 && item.type == 4)">
            <custom-search-select
                class="u_custom_search_entry--form"
                :list="item.options"
                :value="item.value"
                :place-holder="item.placeholder || selectPlaceHolder"
                @input="onInput(item, 'value', $event)"
            ></custom-search-select>
        </template>
        <template v-else-if="funcType === 1 && item.type == 6">
            <custom-search-date
                :place-holder="selectPlaceHolder"
                :module="module"
                :form-info="formInfo"
                class="u_custom_search_entry--form"
                :form-item="item"
            ></custom-search-date>
        </template>
        <template v-else-if="funcType === 1 && item.type == 8">
            <custom-search-phone
                class="u_custom_search_entry--form"
                :mobile-ct-list="mobileCtList"
                :form-item="item"
            ></custom-search-phone>
        </template>
        <template v-else-if="funcType === 1 && item.type == 9">
            <custom-search-mail class="u_custom_search_entry--form" :form-item="item"></custom-search-mail>
        </template>
        <template v-else-if="funcType === 1 && item.type == 10">
            <custom-search-identity class="u_custom_search_entry--form" :form-item="item"></custom-search-identity>
        </template>
        <template v-else-if="funcType === 1 && item.type == 11">
            <custom-search-address
                :place-holder="selectPlaceHolder"
                :form-info="formInfo"
                class="u_custom_search_entry--form"
                :form-item="item"
            ></custom-search-address>
        </template>
        <template v-else-if="funcType === 1 && item.type == 12">
            <custom-search-mutli-dropdown
                :place-holder="selectPlaceHolder"
                :form-info="formInfo"
                class="u_custom_search_entry--form"
                :form-item="item"
                @selectChange="onInput(item, 'value', $event)"
            ></custom-search-mutli-dropdown>
        </template>
        <template v-else-if="funcType === 1 && item.type == 13">
            <custom-search-input
                v-model="item.value"
                class="u_custom_search_entry--form"
                :place-holder="item.placeholder"
            ></custom-search-input>
        </template>
    </div>
</template>

<script>
import CustomSearchInput from './CustomSearchInput.vue';
import CustomSearchRadio from './CustomSearchRadio.vue';
import CustomSearchCheckbox from './CustomSearchCheckbox.vue';
import CustomSearchSelect from './CustomSearchSelect.vue';
import CustomSearchMultiLine from './CustomSearchMultiLine.vue';
import CustomSearchPhone from './CustomSearchPhone.vue';
import CustomSearchDate from './CustomSearchDate.vue';
import CustomSearchMail from './CustomSearchMail.vue';
import CustomSearchAddress from './CustomSearchAddress.vue';
import CustomSearchIdentity from './CustomSearchIdentity.vue';
import CustomSearchMutliDropdown from './CustomSearchMutliDropdown.vue';
/* eslint-disable vue/no-mutating-props */
export default {
    name: 'CustomSearchItem',
    components: {
        CustomSearchInput,
        CustomSearchRadio,
        CustomSearchCheckbox,
        CustomSearchSelect,
        CustomSearchMultiLine,
        CustomSearchPhone,
        CustomSearchDate,
        CustomSearchMail,
        CustomSearchAddress,
        CustomSearchIdentity,
        CustomSearchMutliDropdown,
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        funcType: {
            type: Number,
            default: 0,
        },
        mobileCtList: {
            type: Array,
            default() {
                return [];
            },
        },
        module: {
            type: Object,
            default() {
                return {};
            },
        },
        formInfo: {
            type: Object,
            default() {
                return {};
            },
        },
        selectPlaceHolder: {
            type: String,
            default: '',
        },
        inputPlaceHolder: {
            type: String,
            default: '',
        },
    },
    methods: {
        onInput(item, key, value) {
            this.$emit('on-input', { item, key, value });
        },
    },
};
</script>

<style lang="scss">
.u_custom_search_entry--form {
    font-size: 0.6rem;
}

.m_custom_search {
    /*** 通用select样式 ***/
    .select_style {
        position: relative;
    }
    .select_style .arrow {
        position: absolute;
        right: 0.65rem;
        font-size: 0.55rem;
        line-height: 2rem;
        color: #666;
        cursor: pointer;
    }
    .select_style .select_input {
        cursor: pointer;
        text-indent: 0.5rem;
        outline: none;
    }
    .select_style .select_item {
        font-size: 0.6rem;
        color: #333;
        line-height: 1.8rem;
        padding-left: 0.6rem;
        box-sizing: border-box;
        cursor: pointer;
    }
    .select_style .select_item:hover {
        background: #f0f0f0;
    }
    .select_style .select_list {
        overflow: auto;
    }
    .select_style .select_list::-webkit-scrollbar {
        width: 0.2rem;
    }
    .select_style .select_list::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
    }
    .select_style .select_list::-webkit-scrollbar-track {
        background-color: #f7f7f7;
    }
    .select_style .select_list_wrap {
        margin-top: 10px;
        padding: 8px 6px;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #eee;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        z-index: 999;
    }
    /*** 多选按钮 ***/
    .item_type_checkbox {
        padding-top: 2px;
    }
    .item_type_checkbox.required {
        padding-left: 20px;
    }
    .form_item .item_type_checkbox .required_icon {
        line-height: 21px;
    }
    .m_custom_search .checkbox_style .checkbox_item:not(.checkbox_active) .checkbox_inner {
        background: #fff;
    }
    .checkbox_style .checkbox_item {
        display: inline-block;
        font-size: 0.65rem;
        line-height: 0.8rem;
        color: #333;
        list-style-type: none;
        margin-right: 0.9rem;
        cursor: pointer;
    }
    .checkbox_style .checkbox_inner {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.4rem;
        font-size: 0.6rem;
        line-height: 0.8rem;
        text-align: center;
        color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 0.1rem;
        box-sizing: border-box;
        position: relative;
    }
    .checkbox_style .checkbox_active .checkbox_inner:before {
        content: '\b0121';
        color: #000000;
        font-weight: bold;
        font-size: 0.6rem;
    }

    .radio_style .radio_active .radio_inner:after {
        opacity: 1;
    }
    .checkbox_style .checkbox_item:nth-last-child(1) {
        margin-right: 0;
    }
    /*** 单选按钮 ***/
    .item_type_radio {
        padding-top: 0.1rem;
    }
    .radio_style {
        margin-top: -0.8rem;
    }
    .radio_style .radio_item:not(.radio_active) .radio_inner {
        background: #fff;
    }
    .radio_style .radio_item {
        display: inline-block;
        list-style-type: none;
        margin: 0.8rem 0.8rem 0 0;
        cursor: pointer;
    }
    .radio_style .radio_inner {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.4rem;
        font-size: 0.6rem;
        line-height: 0.8rem;
        color: #fff;
        text-align: center;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        box-sizing: border-box;
        position: relative;
        vertical-align: middle;
    }
    .radio_style .radio_text {
        font-size: 0.65rem;
        line-height: 1;
        color: #333;
        vertical-align: middle;
    }

    .radio_style .radio_inner:after {
        position: absolute;
        width: 0.3rem;
        height: 0.3rem;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        display: table;
        border-top: 0;
        border-left: 0;
        content: ' ';
        background-color: #828282;
        opacity: 0;
    }
    .radio_style .radio_active .radio_inner:after {
        opacity: 1;
    }
    .radio_style .radio_item:nth-last-child(1) {
        margin-right: 0;
    }
    /*** 通用input样式 ***/
    .input_style {
        color: #333;
        width: 100%;
        height: 1.8rem;
        line-height: 1.2rem;
        box-sizing: border-box;
        background: none;
        border-radius: 0;
        font-size: 0.6rem;
        text-indent: 0.25rem;
        padding: 0;
        -webkit-appearance: none;
        text-indent: 0.5rem;
        border: 1px solid #e0e0e0;
        border-radius: 0.1rem;
        background: #fff;
    }
    .required .input_style {
        padding-left: 1.8rem;
    }
    .u_custom_search_entry--text {
        padding: 0 0.725rem;
        width: 100%;
        height: 1.9rem;
        font-size: 0.6rem;
        line-height: 1.9rem;
        border: 0.025rem solid #e3e2e8;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 0.086rem;
        background-color: #fff;
        -webkit-appearance: none;
    }
}
</style>
