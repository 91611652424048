import { mapState } from 'vuex';

export const photoListMixins = {
    computed: {
        ...mapState(['currentPageModuleIdMap']),
        module() {
            return this.currentPageModuleIdMap[this.moduleId];
        },
        options() {
            return this.module.renderOptions;
        },
        content() {
            return this.module.content;
        },
    },
};
