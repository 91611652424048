import { initWebPage } from '@/shared/misc/init-webpage';

let showProductCacheList = []; //缓存产品数据，免得每次都从ajax拿相同数据
export const showMallCartScreen = function (moduleId, choiceCurrencyVal, mallShoppingOptions, levelDiscount, dom) {
    var panel = jm('.productMallOptionPanel');

    //为每个购物车按钮绑定事件，点击时动态生成对应的弹层dom
    jm('#module' + moduleId + ' .J_fk-mallCart')
        .off('click.cart')
        .bind('click.cart', function (e) {
            e.stopPropagation();
            var domHtml =
                dom && dom.replace
                    ? dom
                          .replace(/&nbsp;/gi, ' ')
                          .replace(/&lt;/gi, '<')
                          .replace(/&gt;/g, '>')
                          .replace(/&#92;/gi, '\\')
                          .replace(/&#39;/gi, "'")
                          .replace(/&quot;/gi, '"')
                          .replace(/<br\/>/gi, '\n')
                          .replace(/&amp;/gi, '&')
                    : dom;
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent').append(domHtml);
            panel = jm('.productMallOptionPanel');
            var pid = jm(this).attr('id') || '';
            var isInCache = false; //判断缓存中是否有该产品
            var cacheInfo = {};
            var cacheHtml = '';
            var cacheScript = '';
            var cacheOtherHtml = {};
            var cacheIsPtLimitBuy = false;
            var cacheIsImLimitBuy = false;
            jm.each(showProductCacheList, function (i, n) {
                if (n.id == pid) {
                    isInCache = true;
                    cacheInfo = n.pinfo;
                    cacheHtml = n.showOptionHtml;
                    cacheScript = n.script;
                    cacheOtherHtml = n.otherHtml;
                    cacheIsPtLimitBuy = n.isPtLimitBuy;
                    cacheIsImLimitBuy = n.isImLimitBuy;
                    return false;
                }
            });

            productShowCart();
            jm('#productMallOptionPanel_mcs_' + moduleId)
                .find('.productMallOptionPanel')
                .hide(); //没load出产品数据前先隐藏
            if (isInCache) {
                initAndShow(
                    cacheInfo,
                    cacheHtml,
                    cacheScript,
                    cacheOtherHtml,
                    cacheIsPtLimitBuy,
                    cacheIsImLimitBuy,
                    cacheIsImLimitBuy
                );
            } else {
                jm.ajax({
                    type: 'POST',
                    url: '/ajax/product_h.jsp?cmd=getWafNotCk_getProductInfoAndDom',
                    data: {
                        pid,
                        moduleId,
                        mallShoppingOptions,
                    },
                    error() {
                        Mobi.ing(LS.js_systemError);
                    },
                    success(result) {
                        var res = jm.parseJSON(result);
                        if (res.success) {
                            var info = res.pinfo;
                            var optionHtml = res.showOptionHtml;
                            var script = res.script;
                            var otherHtml = res.otherHtml;
                            var isPtLimitBuy = res.isPtLimitBuy;
                            var isImLimitBuy = res.isImLimitBuy;
                            var isEarnestSale = res.isEarnestSale;
                            showProductCacheList.push({
                                id: pid,
                                pinfo: info,
                                showOptionHtml: optionHtml,
                                script,
                                otherHtml,
                                isPtLimitBuy,
                                isImLimitBuy,
                                isEarnestSale,
                            });

                            initAndShow(info, optionHtml, script, otherHtml, isPtLimitBuy, isImLimitBuy, isEarnestSale);
                        }
                    },
                });
            }

            return false;
        });

    var productShowCart = initWebPage({
        triggerId: 'f' + moduleId,
        panelId: 'productMallOptionPanel_mcs_' + moduleId,
        pageBg: true,
        returnSeltFun: true,
        direction: 'bottom',
        closeWebPage: '#productMallOptionPanel_mcs_' + moduleId + ' .productDetailClose.icon-Off',
        closeCallBack() {
            jm('.productMallOptionPanel .productMallOptionContentPanel').removeClass('mallThemeProductContentPanel');
            jm(
                '#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .productMallOptionHead'
            ).remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .mallOptionAndNumBox').remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .productMallShop').remove();
            jm('#productMallOptionPanel_mcs_' + moduleId + ' .productMallOptionContent .separatorLine').remove();
        },
    });

    function initAndShow(info, optionHtml, script, otherHtml, isPtLimitBuy, isImLimitBuy, isEarnestSale) {
        panel = jm('.productMallOptionPanel');
        var pid = info.id;
        panel.attr('id', 'productMallOptionPanel' + pid); //修改panel的id
        var mallAmountHtml = otherHtml.mallAmountHtml || '';
        var mallWeightHtml = otherHtml.mallWeightHtml || '';
        var mallPriceHtml = otherHtml.mallPriceHtml || '';
        var productMallShopHtml = otherHtml.productMallShopHtml || '';
        var siteFormPleaseSelect = otherHtml.siteFormPleaseSelect || '请选择';
        //s----为了显示价格
        var value = info.mallPrice;
        var propValue = value.toFixed(2);
        var tempSaleProValue = propValue;
        var memberMaxPrice = '';
        var memberMinPrice = '';

        if (info.openPriceCalendar) {
            panel.addClass('withPriceCalendar');
            panel.find('.productMallOptionHead .productMallOptionImage').hide();
            panel.find('.productMallOptionHead .productDetail').hide();
            panel.find('.productMallOptionHead').append("<div class='indicator'>" + siteFormPleaseSelect + '</div>');
        } else {
            panel.removeClass('withPriceCalendar');
        }

        if (mallShoppingOptions) {
            //存在购物选项时
            var minPrice = propValue;
            var maxPrice = propValue;
            var optionsAmountInfo = info.optionsAmountList || {};
            var optionsAmountList = optionsAmountInfo.d || [];
            jm.each(optionsAmountList, function (i, n) {
                var data = n;
                var oPrice = data.oPrice;
                if (oPrice == null || oPrice == '') {
                    return;
                }
                var oFlag = data.flag || 0;
                if (oFlag == 0) {
                    return;
                }
                var tmp = oPrice || 0.0;
                minPrice = minPrice < tmp ? minPrice : tmp;
                maxPrice = maxPrice > tmp ? maxPrice : tmp;
                minPrice = parseFloat(minPrice) || 0.0;
                maxPrice = parseFloat(maxPrice) || 0.0;
                if (minPrice == maxPrice) {
                    propValue = minPrice.toFixed(2);
                } else {
                    propValue = minPrice.toFixed(2) + '~' + maxPrice.toFixed(2);
                    memberMaxPrice = maxPrice.toFixed(2);
                    memberMinPrice = minPrice.toFixed(2);
                    //priceScope = minPrice + "~" + maxPrice;		报错。这段代码看起来是没用的，else逻辑用来获取最小价格和最大价格
                }
            });
        }
        if (memberMinPrice != '' || memberMaxPrice != '') {
            var maxpriceFormat = parseFloat(memberMaxPrice) * levelDiscount * 0.01;
            var minpriceFormat = parseFloat(memberMinPrice) * levelDiscount * 0.01;
            tempSaleProValue = minpriceFormat + '~' + maxpriceFormat;
        }
        jm('#choiceCurrency').text(choiceCurrencyVal);
        jm('#mallPrice').text(tempSaleProValue);

        //e----为了显示价格

        //显示其他简单信息
        if (Fai.top._openThemeV3) {
            panel.find('.mallOptionImg').css('background-image', 'url(' + info.picPath + ')');
        } else {
            panel.find('.mallOptionImg').attr('src', info.picPath);
        }
        panel.find('.productMallAmount').html(mallAmountHtml);
        panel.find('.productMallWeight').html(mallWeightHtml);
        if (!isEarnestSale) {
            panel
                .find('.productDetailName')
                .html(
                    "<div class='productName g_middleColor'><div class='productNameWrap' style='word-break:break-all;white-space: normal;overflow:hidden;text-overflow:ellipsis;'></div></div>" +
                        mallPriceHtml
                );
        }
        panel.find('.productNameWrap').text(info.name);

        panel.find('.J-op-box').html(optionHtml); //显示购物选项

        panel.find('.productMallShop').html(productMallShopHtml);

        var suffix = isPtLimitBuy ? '_pt' : isImLimitBuy ? '_im' : '';
        jm('#g_increase' + suffix)
            .unbind('click.ic')
            .bind('click.ic', function () {
                Mobi.decrease2(pid, suffix);
            });
        jm('#g_decrease' + suffix)
            .unbind('click.dc')
            .bind('click.dc', function () {
                Mobi.increase2(pid, suffix);
            });

        //以生成script标签的方式执行js，完毕就remove掉标签
        var newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.innerHTML = script;
        newScript.id = 'opScript_' + moduleId;
        document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript);
        jm('#opScript_' + moduleId).remove();

        //拼团限购相关
        if (isPtLimitBuy) {
            jm('#limitAmountDiv').hide();
            jm('.J_productNumberContent_simple').hide();

            jm('#limitAmountDiv_pt').show();
            jm('.J_productNumberContent_pt').show();
        } else {
            jm('#limitAmountDiv_pt').hide();
            jm('.J_productNumberContent_pt').hide();

            jm('#limitAmountDiv').show();
            jm('.J_productNumberContent_simple').show();
        }

        //积分商城限购相关
        if (isImLimitBuy) {
            jm('#limitAmountDiv').hide();
            jm('.J_productNumberContent_simple').hide();

            jm('#limitAmountDiv_im').show();
            jm('.J_productNumberContent_im').show();
        } else {
            jm('#limitAmountDiv_im').hide();
            jm('.J_productNumberContent_im').hide();

            jm('#limitAmountDiv').show();
            jm('.J_productNumberContent_simple').show();
        }

        jm('#productMallOptionPanel_mcs_' + moduleId + ' #productBuy')
            .unbind('click.msc')
            .bind('click.msc', function () {
                Mobi.mallBuy(pid, 0, 1, moduleId, null, null, {
                    cb: function () {},
                });
            });
        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmediatelyBug')
            .unbind('click.msc')
            .bind('click.msc', function () {
                Mobi.mallBuy(pid, 1, 1, moduleId);
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeSecKill')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var secKillId = parseInt(jm(this).attr('secKillId') || 0);
                Mobi.mallBuy(pid, 1, null, moduleId, null, null, {
                    isSecKill: true,
                    secKillId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeGroupBuy')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var groupBuyId = parseInt(jm(this).attr('groupBuyId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isGroupBuy: true,
                    groupBuyId,
                    groupBuyDetailId: 0,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeCutPrice')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var cutPriceId = parseInt(jm(this).attr('cutPriceId') || 0);
                Mobi.cutPrice(cutPriceId, pid);
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeIntegralMall')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var integralMallId = parseInt(jm(this).attr('integralMallId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isIntegralMall: true,
                    integralMallId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId + ' .J_productImmeEarnestSale')
            .unbind('click.msc')
            .bind('click.msc', function () {
                var earnestSaleId = parseInt(jm(this).attr('earnestSaleId') || 0);
                Mobi.mallBuy(pid, 1, null, null, null, null, {
                    isEarnestSale: true,
                    earnestSaleId,
                });
            });

        jm('#productMallOptionPanel_mcs_' + moduleId)
            .find('.productMallOptionPanel')
            .show();

        if (Fai.top._templateFrameId >= 2000 || Fai.top._templateFrameId < 3000) {
            jm('#mallPrice').css({ height: '', lineHeight: '' });
        }

        jm('#productMallOptionPanel_mcs_' + moduleId)
            .find('.mallOptionImg')
            .click(function () {
                var picSrc = jm(this).attr('src');
                jm('#module' + moduleId)
                    .find('#J_acct_pic')
                    .attr('src', picSrc)

                    .click();
            });

        //对于一些限购的情况，在每次进入该页面时都要调用。
        //有一种情况是起购为3，在点立即购买后返回，数量会变成1。
        //这是因为手机浏览器返回会读取缓存的视图，通过设置pageshow事件可以在每次显示页面都执行想要的代码
        var newScript2 = document.createElement('script');
        newScript2.type = 'text/javascript';
        newScript2.innerHTML = 'jm(window).bind("pageshow", function(){jm("#productNum' + suffix + '").change();});';
        newScript2.id = 'opScript2_' + moduleId;
        document.getElementById('productMallOptionPanel_mcs_' + moduleId).appendChild(newScript2);
    }
};
