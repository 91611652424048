<template>
    <ModuleFrame :module-id="moduleId">
        <Entrance :module-id="moduleId"></Entrance>
    </ModuleFrame>
</template>

<script>
import Entrance from './Entrance.vue';
import ModuleFrame from '@/modules/frame/index.vue';

export default {
    components: { Entrance, ModuleFrame },
    props: {
        moduleId: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style></style>
