import { mapState } from 'vuex';
import { getFileType } from '@jz/biz-shared';

export default {
    inject: {
        inMemberDetailEdit: {
            default: false,
        },
    },
    computed: {
        ...mapState('form', ['editingSubmitResultId', 'formDetails']),
    },
    methods: {
        getEditingSubmitInfo() {
            if (this.editingSubmitResultId !== -1) {
                return this.formDetails[this.editingSubmitResultId];
            }
        },
        getEditingItemSubmitValue() {
            const editingFormDetail = this.getEditingSubmitInfo();
            const submitContents = editingFormDetail?.submitInfo?.submitContentList ?? [];
            return submitContents.find((item) => item.id === this.formItem.id)?.val ?? '';
        },
        getEditingFormSubmitFiles() {
            const editingFormDetail = this.getEditingSubmitInfo();
            return editingFormDetail?.submitInfo?.siteFormFileList ?? [];
        },
        getEditingFormInitialFiles() {
            const fileIds = this.getEditingItemSubmitValue();
            const files = this.getEditingFormSubmitFiles();

            if (!fileIds) return [];

            return fileIds
                .map((id) => {
                    const file = files.find((file) => file.id === id);
                    if (file) {
                        return {
                            src: file.downloadUrl,
                            key: file.id,
                            title: file.name,
                            fileType: getFileType(file.name),
                        };
                    }
                })
                .filter(Boolean);
        },
    },
};
