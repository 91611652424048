<template>
    <div class="u_custom_search_entry--phone_wrap">
        <input
            type="number"
            class="u_custom_search_entry--input u_custom_search_entry--text"
            :value="currentValue"
            @input="handleInput"
        />
        <div v-if="formItem.openAreaCode" class="form_phone_cn_warp">
            <i class="drop_down_icon faisco-icons-S000112"></i>
            <div class="cn_value">{{ currentCtCode }}</div>
            <select v-model="currentCtCode" class="cn_select" @input="handleChangeCurrentCtCode">
                <option
                    v-for="(item, index) in mobileCtList"
                    :key="item.ctName"
                    :selected="index === 0"
                    :value="item.ctCode"
                    >{{ item.ctShowName + item.ctCode }}</option
                >
            </select>
        </div>
    </div>
</template>
<script>
import { debounce } from '@jz/utils';
import { ing } from '@/shared/tips';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'CustomSearchPhone',
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        mobileCtList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            currentValue: '',
            currentCtCode: '+86',
        };
    },
    computed: {
        submitVal() {
            const val = this.getCurCn();
            const areaCode = val.ctName || '';
            if (this.formItem.openAreaCode && this.currentValue !== '') return areaCode + this.currentValue;
            return this.currentValue;
        },
    },
    methods: {
        resetValue() {
            this.currentValue = '';
        },

        handleInput: debounce(function (event) {
            let value = event.target.value,
                that = this;

            if (value.length > 11) {
                value = value.slice(0, 11);
                event.target.value = value;
            }
            this.currentValue = value;
            this.formItem.value = this.submitVal;
            that.$emit('chanage', this.currentValue);
        }, 200),
        handleChangeCurrentCtCode() {
            this.$nextTick(() => {
                const val = this.getCurCn();
                const areaCode = val.ctName || '';
                if (this.formItem.openAreaCode && this.currentValue !== '') {
                    this.formItem.value = areaCode + this.currentValue;
                }
            });
        },

        getCurCn() {
            return this.mobileCtList.filter((item) => item.ctCode === this.currentCtCode)[0] || {};
        },
        validate() {
            const phoneNumber = this.currentValue || '';
            const phoneNumberLength = (this.formItem.phoneNumberLength || '7-11').split('-');
            const minLength = phoneNumberLength[0];
            const maxLength = phoneNumberLength[1] || minLength;

            if (this.formItem.must && phoneNumber == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            if (phoneNumber) {
                if (!jm.isLimitedRangeNationMobile(phoneNumber, minLength, maxLength)) {
                    ing(jm.format(LS.newMobileNumRegular, phoneNumberLength));
                    return false;
                }
            }

            return true;
        },
    },
};
</script>

<style lang="scss">
.u_custom_search_entry--phone_wrap {
    display: flex;
    justify-content: space-around;
    border: 1px solid #e3e2e8;
    .u_custom_search_entry--text:focus {
        outline: none;
    }
}

.u_custom_search_entry--phone_wrap.u_custom_search_entry--text {
    padding: 0;
}
.u_custom_search_entry--phone_wrap .u_custom_search_entry--input {
    &.u_custom_search_entry--text {
        border: 0;
    }
}

.u_custom_search_entry--input::-webkit-inner-spin-button,
.u_custom_search_entry--input::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

.mobi_form_style_2 .u_custom_search_entry--text:focus {
    border: 0px;
}

.u_custom_search_entry--phone_wrap .form_phone_cn_warp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u_custom_search_entry--phone_wrap .cn_value {
    position: absolute;
    right: 1.64rem;
    top: 0;
    height: 100%;
    width: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.u_custom_search_entry--phone_wrap .cn_select {
    opacity: 0;
}

.u_custom_search_entry--phone_wrap .drop_down_icon {
    position: absolute;
    right: 0.85rem;
    color: rgba(183, 183, 183, 1);
    font-size: 0.45rem;
}
</style>
