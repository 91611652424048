import { mapState } from 'vuex';
export function residueMixins() {
    return {
        computed: {
            ...mapState('app', ['LS']),
            residueText() {
                let residueTextMap = {};
                const { nl, ol, nd } = this.formItem;
                if (nd === false && ol && this.allowedFormNumLimit) {
                    nl.forEach((item) => {
                        residueTextMap[item.v] = {
                            text: `(${this.LS?.remain || '余'}${item.s <= 0 ? 0 : item.s})`,
                            s: item.s,
                        };
                    });
                }
                return residueTextMap;
            },
            isOpenResidue() {
                return this.formItem.ol || false;
            },
            extInfo() {
                return this.module.extInfo || {};
            },
            allowedFormNumLimit() {
                return this.extInfo.allowedFormNumLimit;
            },
        },
        methods: {
            getResidueText(name) {
                let data = this.residueText[name];
                return data != null ? data.text : '';
            },
            isZeroResidue(name) {
                const { nl } = this.formItem;
                let disabled = false;
                if (this.isOpenResidue && this.allowedFormNumLimit) {
                    let residueItem = nl.find((residueItem) => {
                        return residueItem.v === name && residueItem.s <= 0;
                    });
                    disabled = residueItem != null;
                }
                return disabled;
            },
            getMutilDropDownResidueData(options) {
                let {
                    level,
                    firstLevelVal,
                    secondLevelVal,
                    thirdLevelVal,
                    firstLevelList = [],
                    secondLevelList = [],
                    thirdLevelList = [],
                    fourthLevelList = [],
                } = options;
                let residueMap = {};
                const { nl, ol, nd } = this.formItem;
                if (ol && this.allowedFormNumLimit) {
                    let prefix = '';
                    let suffix = [];
                    switch (level) {
                        case 1:
                            prefix = '';
                            suffix = firstLevelList;
                            break;
                        case 2:
                            prefix = firstLevelVal;
                            suffix = secondLevelList;
                            break;
                        case 3:
                            prefix = `${firstLevelVal}-${secondLevelVal}`;
                            suffix = thirdLevelList;
                            break;
                        case 4:
                            prefix = `${firstLevelVal}-${secondLevelVal}-${thirdLevelVal}`;
                            suffix = fourthLevelList;
                            break;
                    }

                    // 拼接完整选项
                    let optionsList = suffix.map((item) => {
                        return prefix == '' ? item : `${prefix}-${item.value}`;
                    });

                    nl.filter((item) => {
                        // 命中设置数量的选项
                        return optionsList.includes(item.v);
                    }).forEach((item) => {
                        let nameList = item.v.split('-');
                        let name = nameList[nameList.length - 1];
                        if (name != null) {
                            residueMap[name] = {
                                text: `(${this.LS?.remain || '余'}${item.s <= 0 ? 0 : item.s})`,
                                s: item.s,
                                nd,
                            };
                        }
                    });
                }
                return residueMap;
            },
        },
    };
}
