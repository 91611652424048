<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="[wrapClasses, picTextStyle, bigItemClasses, contentRadiusClass]"
        :picnum="8"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="isOnlyTitle">
            <a hidefocus="true" :href="_newsUrl" :target="_jumpTarget" :class="hideElem" :onclick="onclickStr">
                <div class="upDownPicText" :class="picTextPositionFlag">
                    <div v-if="module.content.ptp == 1" :class="[picTextPosition, textAlignClasses]" :style="contentBg">
                        <div class="g_news_title newTitle" :class="[titleLine, noDesClass]" :style="news_title_style">
                            <div :class="_topFlagClass"></div>
                            <top-component
                                v-if="
                                    (_topFlagClass != '' && isNewAddModule && module.content.naw == 2) ||
                                    (_topFlagClass != '' && isNewAddModule && module.content.naw == 1)
                                "
                            ></top-component
                            >{{ info.title }}
                        </div>
                        <div v-if="!module.content.des" class="felxTextItem">
                            <div
                                class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine"
                                :class="[descriptionLine, isOnlyTitleAndDes]"
                                :style="news_abstract_style"
                            >
                                {{ info.summary }}
                            </div>
                        </div>
                    </div>
                    <div class="headPicTopWrap">
                        <div
                            v-drag-resize="_resizeOptions"
                            class="upDownPicText_Pic picScaleClass J_img_lazyload"
                            :src-original="info.realPicId ? info.bigPicPath : ''"
                            :alt="altName"
                            :style="picStyle"
                            :class="[bigPicClasses, picRadiusClass]"
                        ></div>
                        <top-component
                            v-if="
                                _topFlagClass != '' &&
                                isNewAddModule &&
                                module.content.naw == 1 &&
                                module.content.ptp == 0
                            "
                        ></top-component>
                    </div>
                    <div v-if="module.content.ptp == 0" :class="[picTextPosition, textAlignClasses]" :style="contentBg">
                        <div class="g_news_title newTitle" :class="[titleLine, noDesClass]" :style="news_title_style">
                            <div :class="_topFlagClass"></div>
                            <top-component
                                v-if="_topFlagClass != '' && isNewAddModule && module.content.naw == 2"
                            ></top-component
                            >{{ info.title }}
                        </div>
                        <div v-if="!module.content.des" class="felxTextItem">
                            <div
                                class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine"
                                :class="[descriptionLine, isOnlyTitleAndDes]"
                                :style="news_abstract_style"
                            >
                                {{ info.summary }}
                            </div>
                        </div>
                    </div>
                    <div :style="contentBg" :class="[textAlignClasses, noDesFlag]">
                        <new-info :info="info" :index="index" />
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import NewInfo from '../picTextList/new-info.vue';
import TopComponent from '../topComponent/top-component.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'UpDownPicTextItem',
    components: { NewInfo, TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    data() {
        return {
            imgStyle: `clip-path:url(#clippath${this.module.id}${this.index}`,
        };
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        bigItemClasses() {
            return `${this._lineClass} mixNewsStyleLine newsLine J_picNum`;
        },
        wrapClasses() {
            return `${this._lineClass}`;
        },
        divSize() {
            let divWidth = 100;
            let divHeight = 8;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth / 20;
                divHeight = tSize.picHeight / 20;
            }
            return { divWidth, divHeight };
        },
        picStyle() {
            let picStyle = {};
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            picStyle.height = `${this.divSize.divHeight}rem`;
            return picStyle;
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        noDesClass() {
            return this.showDes ? '' : 'noDesClass';
        },
        picTextPosition() {
            return this.module.content.ptp === 0 ? ' textTop' : ' textBottom';
        },
        bigPicClasses() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        contentRadiusClass() {
            return this.module.content.pts != 3 ? 'picRadiusClass' : '';
        },
        picRadiusClass() {
            var pts = this.module.content.pts;
            var ptp = this.module.content.ptp;

            if (pts == 3) {
                return this.module.content.pts == 3 ? 'picRadiusClass' : '';
            } else {
                if (ptp == 0) {
                    return 'picTopRadiusClass';
                } else {
                    return this.showOtherInfo ? 'picNoRadiusClass' : 'picBottomRadiusClass';
                }
            }
        },
        picTextStyle() {
            const picTextStyle = this.module.content.pts;
            var desClass = '';
            switch (picTextStyle) {
                case 0:
                    desClass = 'shadow';
                    break;
                case 1:
                    desClass = 'border';
                    break;
                case 2:
                    desClass = 'backgroundColor';
                    break;
                case 3:
                    desClass = 'transparentBg';
                    break;
            }
            return ` ${desClass}`;
        },
        textAlignClasses() {
            return this.module.content.nta == 0 ? 'leftTextAlign ' : 'centerTextAlign ';
        },
        contentBg() {
            let style = {};
            if (this.module.content.cbt === 1) {
                style.background = this.module.content.cb;
            }
            return style;
        },
        picTextPositionFlag() {
            return this.module.content.ptp === 0 ? ' textTopFlag' : ' textBottomFlag';
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        hideElem() {
            return this.module.content.ptp === 0 && (this.showDes || this.showOtherInfo) ? 'hideElem' : '';
        },
        noDesFlag() {
            return this.showDes ? '' : 'noDesFlag';
        },
    },
};
</script>

<style></style>
