export function fontStyle(font = {}) {
    let fontSize = '';
    let color = '';
    let style = '';

    if (font.type === 1) {
        fontSize = (font.nfs || 0.6) + 'rem';
        if (font.nfct == 1) {
            color = font.nfc;
        }
    }

    if (fontSize) {
        style += `font-size:${fontSize};`;
    }
    if (color) {
        style += `color:${color};`;
    }
    return style;
}

export function getFontStyleObject(font = {}) {
    let fontSize = '';
    let color = '';
    let style = {};

    if (font.type === 1) {
        fontSize = (font.nfs || 0.6) + 'rem';
        if (font.nfct == 1) {
            color = font.nfc;
        }
    }

    if (fontSize) {
        style.fontSize = fontSize;
    }
    if (color) {
        style.color = color;
    }
    return style;
}

export function parseInputBdCss(border) {
    let color = '';
    let style = '';

    if (border.t == 1) {
        color = border.c;
    }

    if (color) {
        style += `border-color:${color};`;
        style += `--border-color:${color};`;
    }
    return style;
}

export function parseInputBgCss(bg) {
    let color = '';
    let style = '';

    if (bg.t == 1) {
        color = bg.c;
    }
    if (color) {
        style += `background:${color};`;
    }
    return style;
}

export function parseInputFontCss(font) {
    let fontSize = '';
    let color = '';

    if (font.type === 1) {
        fontSize = (font.nfs || 0.6) + 'rem';
        if (font.nfct == 1) {
            color = font.nfc;
        }
    }

    return `font-size:${fontSize};color:${color};`;
}
