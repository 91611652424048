<template>
    <ModuleFrame :module-id="moduleId">
        <div v-if="!isNewModule">
            <div v-if="module.type === 22" class="sysSearch">
                <a class="searchBackBtn faisco-icons-back5" @click.stop="back"></a>
                <div class="searchBar" @click.stop="handlePopupSearchSys">
                    <span class="searchTip">{{ palceholder }}</span>
                    <div :class="['icon-mSearchBtn', colorCls]"></div>
                </div>
            </div>
            <div
                v-else
                :id="'searchContainer' + module.id"
                :class="searchCls"
                :style="moduleColorStyle"
                @click.stop="handlePopupSearch"
            >
                <div class="icon-mSearchIcon"></div>
                <div class="mSearchInput" :style="textStyle">{{ searchTip }}</div>
                <div :class="['icon-mSearchBtn', colorCls]" :style="moduleColorStyleIcon"></div>
            </div>
        </div>
        <div v-else>
            <template v-if="!centerText">
                <div
                    :id="'searchContainer' + module.id"
                    class="search newProductSearch"
                    :class="[styleTypeClass, contentStyle, textAlign, searchFlag]"
                    :style="moduleColorStyle"
                    @click.stop="handlePopupSearch"
                >
                    <div class="mSearchInput" :style="textStyle">{{ searchTip }}</div>
                    <div class="svgDiv" :class="[svgBgColor, defaultColor]" :style="newModuleColorStyleIcon">
                        <svg class="searchSvg g_oldThemefillColor g_main_fillColor_v3" :style="{ fill: svgIcon }">
                            <use xlink:href="#jzm-v19"></use>
                        </svg>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    :id="'searchContainer' + module.id"
                    class="search newProductSearch centerText"
                    :class="[styleTypeClass, contentStyle, textAlign, searchFlag]"
                    :style="moduleColorStyle"
                    @click.stop="handlePopupSearch"
                >
                    <div class="svgDiv" :class="[svgBgColor, defaultColor]" :style="newModuleColorStyleIcon">
                        <svg class="searchSvg g_oldThemefillColor g_main_fillColor_v3" :style="{ fill: svgIcon }">
                            <use xlink:href="#jzm-v19"></use>
                        </svg>
                    </div>
                    <div class="mSearchInput" :style="textStyle">{{ searchTip }}</div>
                </div>
            </template>
        </div>
    </ModuleFrame>
</template>

<script>
import { getLanCode } from '@/shared/fai';
import { mapGetters, mapState } from 'vuex';
import { popupSearch } from '@/system/header/module/v3/search';
import ModuleFrame from '@/modules/frame/index.vue';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'ProductSearch',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        moduleIdAttr() {
            return `ProductSearch` + this.moduleId;
        },
        modulePattern() {
            return this.module.pattern;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        moduleColor() {
            return this.module.content.moduleColor;
        },
        id() {
            return this.module.id;
        },
        styleId() {
            return this.module.styleId;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        isNewModule() {
            if (this.module.extInfo) {
                return this.module.extInfo.isNewModule;
            } else {
                return false;
            }
        },
        palceholder() {
            return this.module.extInfo.palceholder;
        },
        moduleType() {
            return this.module.content.moduleType;
        },
        type() {
            return this.module.type;
        },
        searchTip() {
            return this.module.content.st;
        },
        searchType() {
            return 'searchType' + this.module.content.moduleType;
        },
        searchCls() {
            var cls = {};
            cls['search'] = true;
            cls[this.searchType] = true;
            if (this.module.content.moduleType === 5) {
                cls['g_main_bgColor_v3'] = true;
            }
            return cls;
        },
        centerText() {
            var searchGraphicLocation = this.module.content.searchGraphicLocation;
            if (searchGraphicLocation === 1) {
                if (this.moduleType != 4 && this.moduleType != 5) {
                    return true;
                }
            } else {
                return false;
            }

            return false;
        },
        colorCls() {
            if (this.openThemeV3) {
                let moduleType = this.module.content.moduleType;
                if (moduleType === 0 || moduleType === 3) {
                    return 'g_main_color_v3';
                } else if (moduleType === 1 || moduleType === 4 || moduleType === 5) {
                    return 'g_main_bgColor_v3';
                } else if (moduleType === 2) {
                    return 'g_main_bgColor_v3 g_main_bdColor_v3';
                }
            } else {
                return '';
            }
            return '';
        },
        //高级设置input背景
        inputBgType() {
            if (this.openThemeV3) {
                return this.module.patternV3.ibg.t === 1;
            } else {
                return this.module.pattern.ti.b.y === 1;
            }
        },
        inputBgColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.ibg.t === 1 ? this.module.patternV3.ibg.c : '';
            } else {
                return this.module.pattern.ti.b.y === 1 ? this.module.pattern.ti.b.bc : '';
            }
        },
        //高级设置input边框
        inputBdType() {
            if (this.openThemeV3) {
                return this.module.patternV3.ib.t === 1;
            } else {
                return this.module.pattern.ti.d.y === 1;
            }
        },
        inputBdColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.ib.t === 1 ? this.module.patternV3.ib.c : '';
            } else {
                return this.module.pattern.ti.d.y === 1 ? this.module.pattern.ti.d.c : '';
            }
        },
        //高级设置input文字样式
        inputTsType() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.t === 1;
            } else {
                return this.module.pattern.ti.pt.y === 1;
            }
        },
        inputTcType() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.c.t === 1;
            } else {
                return this.module.pattern.ti.pt.y === 1;
            }
        },
        inputSize() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.t === 1 ? this.module.patternV3.it.s + 'rem' : '';
            } else {
                return this.module.pattern.ti.pt.y === 1 ? this.module.pattern.ti.pt.fs + 'px' : '';
            }
        },
        inputColor() {
            if (this.openThemeV3) {
                return this.module.patternV3.it.c.t === 1 ? this.module.patternV3.it.c.c : '';
            } else {
                return this.module.pattern.ti.pt.y === 1 ? this.module.pattern.ti.pt.fc : '';
            }
        },
        styleTypeClass() {
            var searchType = this.module.content.searchStyleType;
            if (searchType === 1) {
                return 'shadow';
            } else if (searchType === 2) {
                return 'fullbg';
            } else {
                return 'border';
            }
        },
        contentStyle() {
            var moduleType = this.module.content.moduleType;
            if (moduleType === 0 || moduleType === 2 || moduleType === 4) {
                return 'regContent';
            } else {
                return 'radiusContent';
            }
        },
        defaultColor() {
            var moduleType = this.module.content.moduleType;
            if (moduleType === 4 || moduleType === 5) {
                return 'defaultColor';
            }
            return '';
        },
        textAlign() {
            var searchGraphicLocation = this.module.content.searchGraphicLocation;
            if (searchGraphicLocation === 0) {
                return 'left';
            } else if (searchGraphicLocation === 1) {
                return 'center';
            } else {
                return '';
            }
        },
        searchFlag() {
            return 'search' + this.moduleType;
        },
        moduleColorStyle() {
            let style = {};
            if (this.module.content.moduleType === 5 && !this.isNewModule) {
                style.backgroundColor = this.module.content.moduleColor;
            }
            if (this.inputBgType) {
                style.backgroundColor = this.inputBgColor;
            }
            if (this.inputBdType) {
                style.borderColor = this.inputBdColor;
            } else {
                style.borderColor = '';
            }
            return style;
        },
        moduleColorStyleIcon() {
            let style = {};
            let moduleType = this.module.content.moduleType;
            if (moduleType === 0 || moduleType === 3) {
                style.color = this.module.content.moduleColor;
            } else if (moduleType === 1 || moduleType === 4) {
                style.backgroundColor = this.module.content.moduleColor;
            } else if (moduleType === 2) {
                style.backgroundColor = this.module.content.moduleColor;
                style.borderColor = this.module.content.moduleColor;
            } else if (moduleType === 5) {
                style.backgroundColor = 'transparent';
                style.borderColor = '#fff';
            }
            return style;
        },
        textStyle() {
            let style = {};
            if (this.inputTsType) {
                style.fontSize = this.inputSize;
            }

            if (this.inputTsType && this.inputTcType) {
                style.color = this.inputColor;
            } else {
                style.color = '';
            }
            return style;
        },
        newModuleColorStyleIcon() {
            let style = {};
            let moduleType = this.module.content.moduleType;
            let searchIconBgColorSetting = this.module.content.sibcs;
            if (searchIconBgColorSetting === 0) {
                if (moduleType === 4 || moduleType === 5) {
                    style.background = this.module.content.moduleColor;
                    style.borderColor = this.module.content.moduleColor;
                }
            } else {
                if (moduleType === 4 || moduleType === 5) {
                    style.background = this.module.content.sibc;
                    style.borderColor = this.module.content.sibc;
                }
            }
            return style;
        },
        svgBgColor() {
            if (this.moduleType === 4 || this.moduleType === 5) {
                return 'g_oldThemeBgColor g_main_bgColor_v3 g_oldThemeBdColor g_main_bdColor_v3';
            }
            return '';
        },
        svgIcon() {
            let color = '';
            let moduleType = this.module.content.moduleType;
            let searchIconColorSetting = this.module.content.sics;
            if (searchIconColorSetting === 0) {
                if (moduleType === 0 || moduleType === 1) {
                    color = '#aaa';
                } else if (moduleType === 2 || moduleType === 3) {
                    color = this.module.content.moduleColor;
                } else if (moduleType === 4 || moduleType === 5) {
                    color = '#fff';
                }
            } else {
                color = this.module.content.sic;
            }
            return color;
        },
    },
    mounted() {
        this.repairColorData();
    },
    methods: {
        handlePopupSearch() {
            popupSearch({
                //这里应该调用搜索结果页
                moduleId: this.id,
                lanCode: getLanCode(),
                searchProduct: 1,
                hotTopic: this.encodeHtml(this.module.content.hotTopicList),
                searchTip: this.encodeHtml(this.module.content.st),
                moduleType: this.module.content.moduleType,
                moduleColor: this.module.content.moduleColor,
                historySearch: this.module.content.historyType,
                isNewSearch: this.isNewModule,
            });
            this.$nextTick(function () {
                let topicListDomList = jm('.hotTopicWrap .topicItem');
                let dataList = this.module.content.hotTopicList;
                for (let i = 0, len = dataList.length; i < len; i++) {
                    if (jm(topicListDomList[i]).text() !== dataList[i]) {
                        jm(topicListDomList[i]).text(dataList[i]);
                    }
                }
            });
        },
        handlePopupSearchSys() {
            popupSearch({
                moduleId: this.id,
                lanCode: getLanCode(),
                searchProduct: 1,
                hotTopic: this.module.content.hotTopicList,
                searchTip: this.palceholder,
                moduleType: this.module.content.moduleType,
                moduleColor: this.module.content.moduleColor,
                historySearch: this.module.content.historyType,
                isSysProductGroupCol: true,
            });
        },
        //初始化使用hasEditSearch判断，为了兼容旧用户，所以要在noList加入8，12，因为这两项以前是没有的
        noSearchListCheck() {
            if (this.module.content.rangeType == 1) {
                if (!this.hasEditSearch) {
                    this.module.content.noList.push(8);
                    this.module.content.noList.push(12);
                }
            }
        },
        encodeHtml,
        //兼容旧数据默认moduleColor存值，清空
        repairColorData() {
            if (this.module.content.moduleColorIndex === 0) {
                this.module.content.moduleColor = '';
            }
        },
        back() {
            history.back();
            return false;
        },
    },
};
</script>

<style lang="scss">
.search {
    margin: 0.5rem;
    position: relative;
    cursor: pointer;
    border: 1px solid #d7d7d7;
    background-color: #fff;
}
.mSearchInput {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.6rem;
    line-height: 1.6rem;
    color: rgb(145, 145, 145);
    font-size: 0.6rem;
    -webkit-appearance: none;
    box-sizing: border-box;
    padding-left: 0.3rem;
}
.icon-mSearchIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6rem;
    height: 1.6rem;
    color: #bfbfbf;
}
.icon-mSearchIcon::before {
    content: '\e6de';
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.icon-mSearchBtn {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 0;
    top: 0;
    color: #666;
    text-align: center;
}
.icon-mSearchBtn::before {
    content: '\e6de';
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}
.searchType0,
.searchType1,
.searchType2 {
    border-radius: 3px;
}
.searchType1 .icon-mSearchBtn,
.searchType2 .icon-mSearchBtn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.searchType0 .icon-mSearchIcon::before {
    content: none;
}

.searchType1 .mSearchInput {
    width: 83%;
    padding-left: 1.45rem;
}
.searchType1 .icon-mSearchBtn {
    width: 2.5rem;
}
.searchType1 .icon-mSearchBtn::before {
    content: '搜索';
    color: #fff;
    font-size: 0.7rem;
    height: 1.6rem;
    line-height: 1.6rem;
}

.searchType2 .mSearchInput {
    width: 86%;
    padding-left: 1.45rem;
}
.searchType2 .icon-mSearchBtn {
    width: 1.75rem;
}
.searchType2 .icon-mSearchBtn::before {
    content: 'GO';
    color: #fff;
    font-size: 0.7rem;
}
.searchType2 .icon-mSearchBtn::after {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: -0.4rem;
    width: 0;
    height: 0;
    border-right: solid 0.4rem;
    border-right-color: inherit;
    border-top: solid 0.4rem transparent;
    border-bottom: solid 0.4rem transparent;
}

.searchType3 {
    border-radius: 1rem;
}
.searchType3 .mSearchInput {
    padding-left: 0.8rem;
}
.searchType3 .icon-mSearchBtn {
    right: 0.25rem;
}
.searchType3 .icon-mSearchIcon::before {
    content: none;
}

.searchType4 {
    border-radius: 1rem;
}
.searchType4 .mSearchInput {
    width: 83%;
    padding-left: 0.8rem;
}
.searchType4 .icon-mSearchBtn {
    color: #fff;
    width: 2.8rem;
    border-radius: 1rem;
}
.searchType4 .icon-mSearchIcon::before {
    content: none;
}

.searchType5 {
    border-radius: 1rem;
}
.searchType5 .mSearchInput {
    padding-left: 0.8rem;
    color: #fff;
}
.searchType5 .icon-mSearchBtn {
    color: #fff;
    right: 0.25rem;
    border-radius: 1rem;
}
.searchType5 .icon-mSearchIcon::before {
    content: none;
}
.icon_type5 {
    color: #fff;
    right: 0.25rem;
    border-radius: 1rem;
}
.sysSearch {
    border: none;
    border-bottom: 0.025rem solid #eeeeee;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #ffffff;
}
.sysSearch .searchBackBtn {
    display: inline-block;
    width: 1.5rem;
    height: 1.65rem;
    line-height: 1.65rem;
    vertical-align: top;
    text-align: center;
    cursor: pointer;
    position: relative;
    color: #3c414a;
    margin-left: 0.2rem;
    margin-right: 0.5rem;
}
.sysSearch .searchBar {
    height: 1.25rem;
    line-height: 1.25rem;
    margin-right: 1rem;
    border-radius: 4rem;
    background: #f0f1f3;
    border: none;
    display: inline-block;
    width: 12.9rem;
    position: relative;
    color: #979797;
    font-size: 0.6rem;
    padding-left: 0.3rem;
}
.sysSearch .searchBar .searchTip {
    padding-left: 1.4rem;
    font-size: 0.6rem;
    color: #bcbcbc;
    cursor: pointer;
}
.sysSearch .icon-mSearchBtn {
    height: 1.25rem;
    line-height: 1.25rem;
    position: absolute;
    left: 0.25rem;
    color: #979797;
}
.sysSearch .icon-mSearchBtn::before {
    content: '\b0290';
    font-size: 0.6rem;
    color: #868686;
}
/*样式升级*/
.svgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 1.6rem;
    box-sizing: content-box;
}
.searchSvg {
    width: 0.8rem;
    height: 0.8rem;
}
.newProductSearch.border {
    border-color: #e5e5e5;
}
.newProductSearch .mSearchInput {
    width: 88%;
    padding-left: 0.65rem;
}

.search.shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 0;
}
.search.regContent {
    border-radius: 0.15rem;
}
.newProductSearch.search0 .svgDiv,
.newProductSearch.search2 .svgDiv,
.newProductSearch.search4 .svgDiv {
    border-radius: 0 0.15rem 0.15rem 0;
}
.defaultColor {
    background-color: #666666;
}
.newProductSearch.search0,
.newProductSearch.search2,
.newProductSearch.search4 {
    border-radius: 0.15rem;
}
.search.radiusContent {
    border-radius: 1rem;
}
.form .formMiddle .search.fullbg {
    background: rgba(246, 246, 246, 1);
    border: 0;
}

.newProductSearch.search0.left .svgDiv,
.newProductSearch.search1.left .svgDiv,
.newProductSearch.search2.left .svgDiv,
.newProductSearch.search3.left .svgDiv {
    left: 0;
}
.newProductSearch.search0.left .mSearchInput,
.newProductSearch.search1.left .mSearchInput,
.newProductSearch.search2.left .mSearchInput,
.newProductSearch.search3.left .mSearchInput {
    margin-left: 1.4rem;
}
.newProductSearch.search4.left .mSearchInput {
    text-align: left;
}
.newProductSearch.search5 .svgDiv {
    border-radius: 0 1rem 1rem 0;
}
.newProductSearch.search5.left .svgDiv {
    text-align: left;
}
.newProductSearch.search5 .svg {
    border-radius: 0 1rem 1rem 0;
}
.newProductSearch.border.search4 .svgDiv,
.newProductSearch.border.search5 .svgDiv {
    border-top-style: solid;
    border-top-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-style: solid;
    border-left-width: 1px;
    box-sizing: content-box;
    top: -0.05rem;
    right: -0.03rem;
}
.newProductSearch.border.search5 .svgDiv {
    top: -0.039rem;
    right: -0.1rem;
}
.newProductSearch.search5 .svgDiv {
    right: -0.1rem;
}
.newProductSearch.search5 .svgDiv {
    right: -0.025rem;
}
.newProductSearch.search4.center .mSearchInput,
.newProductSearch.search5.center .mSearchInput {
    text-align: center;
    padding-left: 0.65rem;
    padding-right: 0.65rem;
    position: relative;
    left: 0;
}
.centerText.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.centerText .svgDiv {
    position: static;
    width: auto;
}
.centerText .mSearchInput {
    width: auto;
    padding-left: 0.3rem;
}
.searchBackBtn:before {
    font-size: 1.3rem;
}
/*样式升级*/
</style>
