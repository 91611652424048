<template>
    <div>
        <div class="m_form_item" :class="itemClass">
            <label
                v-if="!hidden"
                class="form_item_label"
                :class="{ form_item_require: isRequired }"
                :style="formItemStyle"
                >{{ formItem.name }}</label
            >
            <div class="form_item_content" :style="formItemContentStyle">
                <FormPhone :form-item="formItem" @chanage="handlePhoneChange"></FormPhone>
            </div>
        </div>
        <div v-if="verionControl && !inMemberDetailEdit" class="m_form_item item_wrap" :class="itemClass">
            <div class="form_item_content" :style="formItemContentStyle">
                <FormMessageCode
                    :form-item="formItem"
                    :disabled="msgDisabled"
                    :phone="phoneNumber"
                    @change="getMsgCode"
                ></FormMessageCode>
            </div>
        </div>
    </div>
</template>
<script>
import { fontStyle, parseInputBdCss } from '../utils';

import FormMessageCode from './form-message-code.vue';
import FormPhone from './form-phone.vue';

import formDetailEdit from '../mixins/formDetailEdit';

export default {
    name: 'MobiFormMutiItem',
    components: {
        FormMessageCode,
        FormPhone,
    },
    mixins: [formDetailEdit],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            msgDisabled: true,
            phoneNumber: this.formItem.input,
            msgCode: '',
            version: this.module.extInfo.isOpenPhoneSms,
        };
    },
    computed: {
        itemClass() {
            return [`form_item_wrap_${this.formItem.type}`];
        },
        hidden() {
            return this.module.content.inh || typeof this.formItem.name === 'undefined' || this.formItem.type === 5;
        },
        formItemStyle() {
            return fontStyle(this.module.content.mhighs.fin);
        },
        formItemContentStyle() {
            if (this.module.content.ms == 3) {
                return parseInputBdCss(this.module.content.mhighs.ibd);
            }
            return '';
        },
        isRequired() {
            return this.formItem.must;
        },
        submitVal() {
            return this.$children[0].submitVal;
        },

        verionControl() {
            // 直销专业版: 40， 分销对应：140
            return this.version && this.formItem.smsVerify;
        },
    },
    mounted() {
        this.dispatch('MobiForm', 'on-form-item-add', this);
    },
    methods: {
        dispatch(componentName, eventName, params) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.name;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.name;
                }
            }
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(params));
            }
        },

        resetValue() {
            this.handlePhoneChange('');
            this.$children.forEach((child) => child.resetValue && child.resetValue());
        },

        validate() {
            var isValidate = false;
            let phone = this.$children[0];
            let msgCode = this.$children[1];

            isValidate = phone && phone.validate();

            //必填 或者 有输入手机号码，需要验证验证码
            if (((isValidate && this.formItem.must) || phone.currentValue) && msgCode) {
                isValidate = msgCode.validate();
            }
            return isValidate;
        },

        isMobile(mobile) {
            var pattern = /^1[3456789]\d{9}$/;
            return pattern.test(mobile);
        },

        handlePhoneChange(val) {
            if (this.isMobile(val)) {
                this.msgDisabled = false;
            } else {
                this.msgDisabled = true;
            }
            this.phoneNumber = val;
            this.module.content.fi.phoneNumber = val;
        },

        getMsgCode(val) {
            this.msgCode = val;
        },
    },
};
</script>
<style></style>
