<template>
    <div
        :photoId="photoItem.picId"
        :data-picid="photoItem.picId"
        :style="slideItemStyle"
        class="newPhotoCrossedSlideSec"
    >
        <a :href="imgHref" :rel="photoItem.nf ? 'nofollow' : ''" :onclick="jumpOnclickStr" @click="photoSlidesshow">
            <div
                v-drag-resize="_resizeOptions"
                :src-original="photoItem.picThumbPath"
                :style="photoItemStyle"
                class="slideItemImage J_img_lazyload"
            ></div>
            <div :style="slideTextStyle">
                <div v-show="showTitle" :style="slideNameStyle" v-html="titleData"></div>
                <div v-show="showDesc" :style="slideDescStyle" v-html="descData"></div>
            </div>
        </a>
    </div>
</template>
<script>
import { photoMixins } from '../../mixins';
import { photoImgScaleStyleMap, photoTextAndImgStyleMap } from '../../utils';

export default {
    name: 'SlideItem',
    mixins: [photoMixins],
    props: {
        maxHeight: {
            type: Number,
        },
    },
    computed: {
        nameAndDescIsHidden() {
            return !this.showTitle && !this.showDesc;
        },
        slideItemStyle() {
            let style = {};
            style['width'] = this.columnsTypeWidthLength + 'rem';
            style['margin-left'] =
                this.picSpace.type == 0 ? '0.5rem' : this.picSpace.horizontal / this.htmlFontSize + 'rem';
            style['box-sizing'] = 'border-box';
            // 设置border
            // Object.assign(style, photoTextAndImgStyleMap[this.textStyleType]);
            // Object.assign(style, this.picLinkStyle);
            style = {
                ...style,
                ...photoTextAndImgStyleMap[this.textStyleType],
                ...this.picLinkStyle,
            };

            if (this.isNewModule) {
                style['border-radius'] =
                    this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            } else {
                style['border-radius'] = '4px';
            }

            // style['padding'] = '1px';	// 处理边框border可能被消除情况
            return style;
        },
        slideNameStyle() {
            let style = {};
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));

            let wrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.titleLink);

            style = { ...wrapStyle, ...linkStyle };

            if (this.titleLink.type == 0) {
                if (this.columnsType == 2) {
                    style['font-size'] = '0.6rem';
                } else {
                    style['font-size'] = '0.65rem';
                }
                style['color'] = '#333';
            }

            style['text-align'] = this.textAlignStyle;
            if (this.titleLink.type == 0) {
                style['font-weight'] = 'bold';
            }

            if (this.showDesc) style['margin-bottom'] = '0.3rem';
            return style;
        },
        slideDescStyle() {
            let style = {};
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.nameLink));
            let wrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.nameLink);

            style = { ...wrapStyle, ...linkStyle };

            if (this.nameLink.type == 0) {
                style['font-size'] = '0.5rem';
                style['line-height'] = '0.75rem';
                style['color'] = '#666';

                if (!this.isNewModule) {
                    style['font-size'] == '0.6rem';
                }
            }

            if (this.textStyleType == 3) {
                style['margin-bottom'] = '0';
            }
            style['text-align'] = this.textAlignStyle;

            return style;
        },
        slideTextStyle() {
            let style = {};
            if (this.textAlign == 2) {
                style['padding-left'] = '0.375rem';
                style['padding-right'] = '0.375rem';
            } else if (this.textAlign == 0) {
                style['padding-left'] = '0.375rem';
                style['padding-right'] = '0.375rem';
            } else {
                style['padding-left'] = '0.375rem';
                style['padding-right'] = '0.375rem';
            }

            if (this.textStyleType == 3) {
                style['padding-left'] = '0.125rem';
                style['padding-right'] = '0.125rem';
            }
            style['padding-top'] = this.nameAndDescIsHidden ? '0' : '0.45rem';
            // Object.assign(style, this.spacePaddingStyle);
            style = { ...style, ...this.spacePaddingStyle };
            if (this.isEmpty) {
                style['display'] = 'none';
            }
            return style;
        },

        borderRadiusSize() {
            return this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize : 0.2;
        },

        photoItemStyle() {
            let style = {};
            style.backgroundImage = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;
            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            style = { ...style, ...photoImgScaleStyleMap[this.picScaleType] };
            style['background-position'] = 'bottom center';
            if (this.isNewModule) {
                style['border-radius'] =
                    this.textStyleType != 3
                        ? `${this.borderRadiusSize}rem ${this.borderRadiusSize}rem 0 0`
                        : `${this.borderRadiusSize}rem`;

                if (this.nameAndDescIsHidden) {
                    style['border-radius'] = `${this.borderRadiusSize}rem`;
                }

                if (this.textOriginColor.type == 1) {
                    style['border-radius'] = `${this.borderRadiusSize}rem ${this.borderRadiusSize}rem 0 0`;
                }

                if (this.isEmpty) {
                    style['border-radius'] = `${this.borderRadiusSize}rem`;
                }
            } else {
                style['border-radius'] = '4px 4px 0 0';
            }

            // 设置图片高度
            if (this.picSize.htype == 0) {
                // 默认高度
                // Object.assign(style, this.columnsHeightStyle);
                if (this.picScaleType == 1) {
                    style['height'] = '8rem';
                } else {
                    style['height'] = this.maxHeight + 'rem';
                }
            } else {
                style['height'] = this.picSize.rh + 'rem';
            }
            return style;
        },

        columnsHeightStyle() {
            let style = {};
            if (this.columnsType == 0) {
                style['height'] = '6rem';
            } else if (this.columnsType == 1) {
                style['height'] = '6rem';
            } else if (this.columnsType == 2) {
                style['height'] = '4.6rem';
            }
            return style;
        },

        slideItemLinkStyle() {
            let style = {};
            style = {
                ...photoTextAndImgStyleMap[this.textStyleType],
                ...this.picLinkStyle,
            };
            // Object.assign(style, photoTextAndImgStyleMap[this.textStyleType]);
            // Object.assign(style, this.picLinkStyle);
            // 设置radius
            style['border-radius'] =
                this.photoRadius.type == 1 ? this.photoRadius.size / this.htmlFontSize + 'rem' : '0.2rem';
            return style;
        },
        columnsTypeWidthLength() {
            let length = 6.1;
            switch (this.columnsType) {
                case 0:
                    length = 6.1;
                    break;
                case 1:
                    length = 8;
                    break;
                case 2:
                    length = 5.95;
                    break;
            }
            return length;
        },
        spacePaddingStyle() {
            let style = {};
            if (this.textStyleType != 3) style['padding-bottom'] = '0.65rem';

            if (!this.showDesc && !this.showTitle) {
                style['padding-bottom'] = 0;
            }
            return style;
        },
    },
};
</script>
