<template>
    <div class="big_pic_text_list">
        <template v-for="(info, index) in newsList">
            <big-pic-text-list-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            <div v-if="index === 0 && !isNewAddModule" :key="'s_' + info.id" class="g_separator separatorLine"></div>
        </template>
    </div>
</template>

<script>
import BigPicTextListItem from './big-pic-text-list-item.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'BigPicTextList',
    components: { BigPicTextListItem },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
    },
};
</script>

<style></style>
