<template>
    <module-frame :module-id="moduleId">
        <div :class="marqueeClass">
            <template v-if="isEmpty && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请点击下方按钮或在右侧面板添加内容</div>
                    <button-component active @click.stop="addContent">添加内容</button-component>
                </div>
            </template>
            <template v-if="isEmpty && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有内容</div>
                </div>
            </template>
            <image-viewer
                v-if="showMobiViewer"
                :z-index="zIndex"
                :initial-index="initialIndex"
                :on-close="onClose"
                :url-list="urlList"
                :is-mobi="true"
                :content-terminal="'mobi'"
                :manage-mode="manageMode"
                :log-event-config="logEventConfig"
                :document-event-el="documentEventEl"
                append-to-container-id="g_body"
                :append-to-body="false"
                :disable-long-press="banCopyTextImg"
            />
            <div v-if="!isEmpty" :class="richMarqueeClass">
                <div
                    class="f-contentPanel"
                    :class="['J_richContentPanel' + moduleId, { richImgMargin: addBottomStyle }]"
                >
                    <template v-if="picParam != null && picParam != '' && richType != 0">
                        <div :class="['textImg' + richType]" :style="textImgStyle">
                            <a
                                ref="richImgLink"
                                hidefocus="true"
                                :rel="nf"
                                :style="textImgHrefStyle"
                                :onclick="imageJumpOnclick"
                                @click="photoSlidesshow(0)"
                            >
                                <img
                                    class="J_img_lazyload"
                                    :alt="picParam.i"
                                    :data-source="other.picPath"
                                    :src-original="other.picPath"
                                    :src="noLazyLoad ? other.picPath : options.loadingPath"
                                    :style="richImgStyle"
                                    :vheight="picParam.oh"
                                    :picWidth="picParam.ow"
                                    :picHeight="picParam.oh"
                                    @click="imgLinkClick"
                                />
                            </a>
                        </div>
                    </template>
                    <template v-if="richContentText != null && richContentText != ''">
                        <html-render
                            class="richContent g_rich_content"
                            :class="['richContent' + richType, 'richContent' + moduleId]"
                            :html="richContentText"
                        >
                        </html-render>
                    </template>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </module-frame>
</template>
<script>
import { createUrlArgsOnclickStr, setHrefEventHasReqArgs } from '../../comm';
import { slidesshowMixins } from '../../moduleMixins';
import { isBrowserEnv } from '@jz/utils';
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';
import { logDog } from '@/shared/log';
import { createPopupWindow } from '@/shared/popupWindow/popupWindowVersionTwo';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { bindTapEvent } from '@/shared/event';
import { richMarquee } from '../../shared/richMarquee';

import { initPictureSlidesV4 } from '@/shared/photo/slides';
import { decodeHtml } from '@/shared/util';
import HtmlRender from '@/components/html-render/index.vue';

export default {
    name: 'Rich',
    components: {
        ModuleFrame,
        HtmlRender,
    },
    mixins: [slidesshowMixins],
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            addBottomStyle: false,
            noLazyLoad: false,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-图文展示',
                    jz_version: isBrowserEnv() ? Fai.top.jzVersion : 0,
                },
            },
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        ...mapGetters('app', ['hashRemoved']),
        options() {
            return this.module.renderOptions;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        urlList() {
            let images = [
                {
                    src: this.other.picPath,
                },
            ];
            return images;
        },
        isMarqueeShow: function () {
            return this.module.content.m.s;
        },
        marqueeClass: function () {
            if (this.isMarqueeShow) {
                return ['J_marqueeContainer' + this.moduleId, 'fk-marqueeContainer'];
            } else {
                return ['J_marqueeContainer' + this.moduleId];
            }
        },
        richMarqueeClass: function () {
            return ['J_richMarqueePanel' + this.moduleId];
        },
        picParam: function () {
            return this.module.content.pic;
        },
        picIde: function () {
            return this.module.content.pic.ide;
        },
        richType: function () {
            return this.module.content.t;
        },
        nf: function () {
            return this.module.content.pic.nf ? 'nofollow' : '';
        },
        clickPic: function () {
            return this.module.content.cp;
        },
        moreParam: function () {
            return this.module.content.mr;
        },
        isMoreShow: function () {
            return this.module.content.showMore;
        },
        moreLink: function () {
            return this.module.content.mr.l;
        },

        spaceContent: function () {
            return this.module.content.st;
        },
        textContentSpaceStyle() {
            let style = {};
            if (this.richType == 0 || this.richType == 3) {
                style = { ...style, ...this.spaceStyle };
            }
            return style;
        },
        spaceStyle: function () {
            let style = {};
            switch (this.spaceContent.type) {
                case 0:
                    break;
                case 1:
                    style.paddingLeft = 0;
                    style.paddingRight = 0;
                    break;
                case 2:
                    style.paddingLeft = this.spaceContent.space / 40 + 'rem';
                    style.paddingRight = this.spaceContent.space / 40 + 'rem';
                    break;
            }
            return style;
        },

        radiusContent: function () {
            return this.module.content.mhighs.rs;
        },

        radiusStyle: function () {
            let style = {};
            if (this.radiusContent.type == 1) {
                style.borderRadius = this.radiusContent.radius / 40 + 'rem';
            }
            return style;
        },
        scaleType: function () {
            return this.module.content.pic.scaleType;
        },
        textImgStyle: function () {
            let style = {};
            let scale = this.module.content.pic.scale;
            let width = this.module.content.pic.w;
            let height = this.module.content.pic.h;
            if (this.richType == 1 || this.richType == 4) {
                if (this.scaleType == 1) {
                    style.width = scale + '%';
                } else if (this.scaleType == 2) {
                    style.width = width + 'px';
                    style.height = height + 'px';
                }
            }

            if (this.richType == 0 || this.richType == 3) {
                style = { ...style, ...this.spaceStyle };
                style.boxSizing = 'border-box';
            }

            style = { ...style, ...this.radiusStyle };
            // Object.assign(style, this.radiusStyle);
            return style;
        },
        textImgHrefStyle() {
            let style = {};
            style['width'] = '100%';
            if (this.richType == 3) {
                // style['display'] = 'flex';
                // style['justify-content'] = 'center';
            }
            return style;
        },
        picLinkStyle: function () {
            return this.picParam.ot;
        },
        other: function () {
            return this.module.other;
        },
        isEmpty: function () {
            if (this.richType == 0 && this.module.content.c == '') {
                return true;
            }
            return false;
        },
        richImgStyle: function () {
            let style = {};
            // style.width = '100%';
            if (this.richType == 3) {
                style.width = '100%';
            }
            style = { ...style, ...this.radiusStyle };
            return style;
        },
        richContentText() {
            return this.module.other.htmlStr;
        },
        imageJumpOnclick() {
            return createUrlArgsOnclickStr(
                this.module.content.pic.reqArgs,
                this.module._openRemoveUrlArgs,
                this.hashRemoved
            );
        },
        richContentHasIframe() {
            return this.richContentText.indexOf('iframe') != -1;
        },
    },
    watch: {
        'module.content': {
            handler: function () {
                this.$nextTick(() => {
                    if (VITE_APP_MODE !== 'visitor') {
                        this.noLazyLoad = true;
                    }
                    this.settingHref(this.other.imgFinalLink);
                    bindEvent(this);
                });
            },
            deep: true,
        },
        'module.content.c': {
            handler: function (val) {
                this.module.other.htmlStr = this.changeHrefByRich(val);
                this.$designer.onContentChange(this.module.id);
                this.$nextTick(() => {
                    bindEvent(this);
                    window.bizShared.renderSingleMap(`#module${this.module.id}`);
                });
            },
        },
        'module.content.showMore': {
            handler: function () {
                // 获取到更多的节点
                this.moreSetting();
            },
            deep: true,
        },
        'other.imgFinalLink': {
            handler: function (val) {
                this.settingHref(val);
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (VITE_APP_MODE !== 'visitor') {
                this.moreSetting();
            }
            this.settingHref(this.other.imgFinalLink);
            setTimeout(() => {
                bindEvent(this);
            }, 0);
        });
        this.changeRichContentImgWidth();
        setHrefEventHasReqArgs(jm(`#module${this.module.id}`));
    },
    methods: {
        photoSlidesshow(index = 0) {
            this.module.content.cp === 0 &&
                (!this.other.imgFinalLink ||
                    (this.other.imgFinalLink.includes('javascript') &&
                        !this.other.imgFinalLink.includes('管理态不支持跳转外部链接'))) &&
                this.openPhotoSlidesshow(index);
        },
        imgLinkClick() {},

        // 更多设置
        moreSetting() {
            if (this.isMoreShow.ism) {
                if (this.module.other.moreJumpType == 103 && this.module.content.showMore.u != '') {
                    this.options.args.bannerMore =
                        'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                } else if (this.module.other.moreJumpType == 0 || this.module.content.showMore.u == '') {
                    this.options.args.bannerMore = 'javascript:;';
                } else {
                    this.options.args.bannerMore = this.isMoreShow.u;
                }
                if (this.module.content.showMore.reqArgs) {
                    this.options.args.reqArgs = this.module.content.showMore.reqArgs;
                } else {
                    this.options.args.reqArgs = '';
                }
            } else {
                this.options.args.bannerMore = '';
            }
        },

        // 添加内容
        addContent() {
            if (Fai.top._onlyHasBannerEditAuth) {
                return;
            }
            var _this = this;
            logDog(200987, 2);
            createPopupWindow({
                title: '编辑文本内容',
                frameSrcUrl: '/manage/mobiRichTextArea.jsp?moduleId=' + _this.module.id,
                frameScrolling: 'no',
                width: 880,
                height: 700,
                version: 2,
                popUpWinZIndex: 9032,
            });
        },

        // 设置a标签的href
        settingHref(val) {
            var el = jm(this.$refs['richImgLink']);
            if (el != null) {
                if (val != '' && this.module.content.cp == 0) {
                    el.attr('href', val);
                } else {
                    el.removeAttr('href');
                }
            }
        },

        // 富文本内容改变时，重新设置a标签的href
        changeHrefByRich(val) {
            if (VITE_APP_MODE !== 'visitor') {
                return this.replaceSpace(val);
            } else {
                return val;
            }
        },

        // 进行html解码
        decodeHtml,

        // 去掉空格
        replaceSpace(html) {
            return html && html.replace ? html.replace(/&nbsp;/g, ' ') : html;
        },

        changeRichContentImgWidth() {
            jm('#module' + this.moduleId + ' img').forEach((el) => {
                let $el = jm(el);
                let width = $el.attr('width') || 0;
                if (width == 0) {
                    width = $el.attr('vwidth') || 0;
                }
                if (parseInt(width) > 0) {
                    $el.css('width', width + 'px');
                }
            });
        },
    },
};

function bindEvent(context) {
    var moduleContent = JSON.parse(JSON.stringify(context.module.content));
    var moduleId = context.module.id;
    var module = $('#module' + moduleId)[0];
    var richContentPanel = module.querySelectorAll('.J_richContentPanel' + moduleId + '')[0];
    var richContentPanelParent;
    if (richContentPanel != null) {
        richContentPanelParent = richContentPanel.parentNode;
    }

    // 这里进行元素的动画移除
    jm(richContentPanelParent).removeClass('fk-marqueeMoveInStyle');
    jm(richContentPanelParent).css('animation', '');
    // 向下|向上 会生成多余节点，将其移除
    var removeEl = module.querySelectorAll('.J_contentPanelCopy' + moduleId)[0];
    if (removeEl != null) {
        removeEl.remove();
    }

    var images = [];
    var options = { fromCurentInx: true, contentTerminal: 'mobi-图文展示-ue' };
    var selector = '.J_richContentPanel' + moduleId + ' .richContent img';

    if (moduleContent.cp == 0) {
        if (Fai.top._windowLoaded) {
            images = dataHandle(selector);
            initPictureSlidesV4(selector, images, options);
        } else {
            jm(window).on('load', function () {
                images = dataHandle(selector);
                initPictureSlidesV4(selector, images, options);
            });
        }
        // 重新绑定事件
    } else {
        // 选择无效果时，全部图片点击效果取消
        bindTapEvent(selector, 'tap', function () {});
    }

    if (moduleContent.m.s) {
        // 加定时器包裹，不加的话会比原先的快一点，猜测可能是dom没有完全渲染，导致其中速度变快
        setTimeout(() => {
            richMarquee.init(moduleId, '.J_richContentPanel', moduleContent.m);
        }, 100);
    }

    // 这里进行样式修改
    if (context.openThemeV3) {
        var text = module.querySelectorAll('.richContent' + context.module.id);
        if (context.content == '') {
            context.addBottomStyle = true;
            return;
        }
        if (context.richType == 1 || context.richType == 4) {
            var img1 = module.querySelectorAll('.J_richContentPanel' + context.module.id + '.textImg1 img');
            var img4 = module.querySelectorAll('.J_richContentPanel' + context.module.id + '.textImg4 img');
            var img = img1[0] == null ? img4[0] : img1[0];
            if (text != null && img != null) {
                if (text[0].clientHeight > img.clientHeight) {
                    context.addBottomStyle = false;
                    return;
                } else {
                    context.addBottomStyle = true;
                    return;
                }
            }
        }
        context.addBottomStyle = true;
    } else {
        context.addBottomStyle = false;
    }

    if (!context.noLazyLoad) {
        bindImgLazyLoad(`module${context.module.id}`);
    }
}

function dataHandle(selector) {
    let result = [];
    jm(selector).forEach(function (el, index) {
        var openLink = false;
        var imageId = 'photoswipe_' + index;

        openLink = validateHasLink(el);
        var elSrc = jm(el).attr('data-source') || jm(el).attr('src-original') || jm(el).attr('src');
        if (!elSrc) {
            return;
        }
        result.push({
            src: elSrc,
            width: jm(el).attr('vwidth') || jm(el).attr('picWidth') || el.naturalWidth,
            height: jm(el).attr('vheight') || jm(el).attr('picHeight') || el.naturalHeight,
            title: '',
            description: '',
            detail: false,
            id: imageId,
            openLink: openLink,
        });

        el.setAttribute('data-picId', imageId);
    });
    return result;
}

function validateHasLink(el) {
    let hasLink = false;
    if (el != null) {
        let $el = jm(el);
        while (!$el.hasClass('richContent') && !$el.hasClass('f-contentPanel')) {
            $el = $el.parent();
            let href = $el.attr('href');
            if ($el[0].tagName === 'A' && href != '' && href != null && href != 'javascript:void(0)') {
                hasLink = true;
                break;
            }
        }
    }
    return hasLink;
}
</script>
<style lang="scss">
/********************** 图文详情动画 **********************/
.fk-marqueeContainer {
    position: relative;
    overflow: hidden;
}
.fk-marqueeMoveInStyle {
    opacity: 0;
}
.fk-marqueeContainer .f-richContentPanel {
    overflow: hidden;
    padding-top: 0.5rem;
}
.fk-marqueeContainer .f-richContentPanel .richContent {
    margin: 0;
} /*因为父级元素有overflow：hidden。margin没有与子元素的合并，因此这里把子元素的margin去掉，在父元素上加padding达到相同的视角效果*/

.fk-marqueeContainer {
    position: relative;
    overflow: hidden;
    height: auto !important;
}

.fk-marqueeMoveInStyle {
    opacity: 0;
}
.fk-marqueeContainer .f-contentPanel .richContent {
    margin: 0; /*因为父级元素有overflow：hidden。margin没有与子元素的合并，因此这里把子元素的margin去掉，在父元素上加padding达到相同的视角效果*/
}

.fk-marqueeContainer .f-contentPanel {
    overflow: hidden;
    height: auto !important;
}

.formStyle2 .fk-marqueeContainer .f-contentPanel {
    padding-top: 0.5rem;
} /*这里是使copy的div与原div样式一致*/

.ue-map-wrapper {
    max-width: 100%;
}

/********移出 & 连续滚动********/

@keyframes buttomToTop {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes topToButtom {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rightToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes buttomToTop_moveIn {
    0% {
        transform: translateY(50%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes topToButtom_moveIn {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(-50%);
    }
}

@keyframes rightToLeft_moveIn {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftToRight_moveIn {
    0% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-100%);
    }
}

/*******移出 & 不连续滚动********/

@keyframes buttomToTop_discontinuous {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes topToButtom_discontinuous {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes rightToLeft_discontinuous {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes leftToRight_discontinuous {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

/*******移入 & 不连续滚动********/

@keyframes buttomToTop_moveIn_discontinuous {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes topToButtom_moveIn_discontinuous {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes rightToLeft_moveIn_discontinuous {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes leftToRight_moveIn_discontinuous {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

/********************** 图文详情动画  end ***************************/
.textImg3 {
    float: none;
    width: 100%;
    display: block;
    text-align: center;
}
/* 2019.4.9 添加用于解决图文展示模块图片有间隙问题 */
.formStyle2 .textImg3 img {
    display: block;
}
.textImg1 {
    float: left;
    margin: 0.25rem 0.5rem 0.25rem 0.25rem;
    width: 50%;
    overflow: hidden;
    position: relative;
    z-index: 10;
}
.textImg4 {
    float: right;
    width: 50%;
    margin: 0.25rem 0.5rem 0.25rem 0.25rem;
    overflow: hidden;
}
.richContent {
    line-height: 1.6;
    padding: 0 0.35rem;
    white-space: normal;
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
    text-align: left;
    margin: 0.5rem 0px;
    font-size: 0.8rem;
}
.richContent ol {
    padding-left: 2rem;
}
.richContent ul {
    list-style: inherit;
    padding-left: 1.35rem;
}
.richContent li {
    list-style: inherit;
}

.richContent table {
    clear: both;
    border-collapse: collapse;
    word-break: break-all;
    td {
        word-break: break-word !important;
    }
}
.richContent4 {
    overflow: visible;
    width: auto;
}
.richContent1 {
    width: auto;
    padding-left: 0.25rem;
    overflow: visible;
}

.clear {
    clear: both;
}
.formStyle2 .textImg1 + .richContent1 ul,
.formStyle2 .textImg1 + .richContent1 ol {
    list-style-position: outside;
    margin-left: -1em;
}
.formStyle2 .textImg1 + .richContent1 ul li,
.formStyle2 .textImg1 + .richContent1 ol li {
    position: relative;
    left: 1em;
    padding-right: 1em;
}

.formStyle2 .richContent {
    font-size: 0.604rem;
}
/*ie9+ hack*/
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
    .formStyle2 .textImg1 + .richContent1 ul,
    .formStyle2 .textImg1 + .richContent1 ol {
        list-style-position: inside;
    }
    .formStyle2 .textImg1 + .richContent1 ul li > p,
    .formStyle2 .textImg1 + .richContent1 ol li > p {
        display: inline;
    }
}

.richContent1 img {
    margin-top: 0px;
}

.richImgMargin {
    margin-bottom: 0.75rem;
}
.g_rich_content .audioDiv {
    max-width: 100%;
    audio {
        max-width: 100%;
    }
}
</style>
