<template>
    <div class="progress_ring_wrap">
        <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
            <circle
                :cx="radius"
                :cy="radius"
                :r="normalizedRadius"
                fill="transparent"
                :stroke="bottomRingStrokeColor"
                stroke-miterlimit="10"
                stroke-dashoffset="0"
                :stroke-width="strokeWidth"
                :stroke-dasharray="strokeDasharray"
            />
            <circle
                :cx="radius"
                :cy="radius"
                :r="normalizedRadius"
                fill="transparent"
                :stroke="upRingStrokeColor"
                stroke-miterlimit="10"
                :stroke-width="strokeWidth"
                :stroke-dasharray="strokeDasharray"
                :style="{ strokeDashoffset: strokeDashoffset }"
                class="progress_ring"
            />
        </svg>
        <div class="progress_ring_text_wrap">
            <span :style="topTextStyle" class="progress_ring_top">{{ numberText }}</span>
            <span :style="bottomStyle" class="progress_ring_bottom">{{ unitText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressRing',
    props: {
        radius: {
            type: Number,
            default: 65,
        },
        progress: {
            type: Number,
            required: true,
        },
        strokeWidth: {
            type: Number,
            default: 3,
        },
        bottomRingStrokeColor: {
            type: String,
            required: true,
        },
        upRingStrokeColor: {
            type: String,
            required: true,
        },
        topTextStyle: {
            type: Object,
            default() {
                return {
                    'font-size': '0.9rem',
                };
            },
        },
        bottomStyle: {
            type: Object,
            default() {
                return {
                    'font-size': '0.45rem',
                };
            },
        },
        // eslint-disable-next-line vue/require-default-prop
        numberText: {
            type: String,
        },
        // eslint-disable-next-line vue/require-default-prop
        unitText: {
            type: String,
        },
    },
    data() {
        return {};
    },
    computed: {
        normalizedRadius() {
            return this.radius - this.strokeWidth / 2;
        },
        circumference() {
            return this.normalizedRadius * 2 * Math.PI;
        },
        strokeDashoffset() {
            return this.circumference - (this.progress / 100) * this.circumference;
        },
        viewBox() {
            return `0 0 ${this.radius * 2} ${this.radius * 2}`;
        },
        strokeDasharray() {
            return `${parseInt(this.circumference)} ${parseInt(this.circumference)}`;
        },
    },
};
</script>

<style>
/* font-size:0 用于处理svg高度间隙问题 */
.progress_ring_wrap {
    position: relative;
    font-size: 0;
}
.progress_ring {
    /* transition: stroke-dashoffset 0.35s; */
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.progress_ring_text_wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}
.progress_ring_top {
    font-size: 0.9rem;
}
.progress_ring_bottom {
    font-size: 0.45rem;
}
</style>
