import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import TabContainerView from './module/visitor.vue';

class _TabContainer extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.HORIZON_TAB, options);
    }

    render() {
        return TabContainerView;
    }
}

export const TabContainer = new _TabContainer();
