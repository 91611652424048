<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="itemClasses"
    >
        <a
            class="lineBodyLink"
            :href="_newsUrl"
            hidefocus="true"
            :target="_jumpTarget"
            style="width: auto"
            :class="[isNewAddModuleClass, isOnlyTitle]"
            :onclick="onclickStr"
        >
            <div :id="'lineBody' + info.id" class="lineBody lineBodyPic">
                <div v-if="_topFlagClass != ''" class="topFlagTableCell">
                    <div :class="_topFlagClass"></div>
                </div>
                <div v-if="module.content.tPosition === 1" :alt="altName" class="picTableCell" :style="picTextStyle">
                    <div
                        :id="'newsImg' + info.id"
                        :alt="altName"
                        :class="picTextClasses"
                        :src-original="info.realPicId ? info.pic160Path : ''"
                        :style="picItemStyle"
                        class="picRadiusClass picScaleClass J_img_lazyload"
                    ></div>
                </div>
                <div class="fontTableCell">
                    <div class="fontHiddenTable">
                        <div class="twoLineHidden g_link newTitle">
                            <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>{{ info.title }}
                        </div>
                    </div>
                    <div v-if="isNewAddModule && !module.content.des" class="felxTextItem">
                        <div
                            class="twoLine articleSummray g_text description g_news_abstract newDescriptionLine newDescriptionLine"
                            :style="news_abstract_style"
                            :class="[descriptionLine, isOnlyTitleAndDes]"
                        >
                            {{ info.summary }}
                        </div>
                    </div>
                </div>
                <!-- 与上面的一样，只是位置不一样 -->
                <div v-if="module.content.tPosition === 2" :alt="altName" class="picTableCell" :style="picTextStyle">
                    <div
                        :id="'newsImg' + info.id"
                        :alt="altName"
                        :class="picTextClasses"
                        :src-original="info.realPicId ? info.pic160Path : ''"
                        :style="picItemStyle"
                        class="picRadiusClass picScaleClass J_img_lazyload"
                    ></div>
                </div>
            </div>
        </a>
        <div
            v-if="showOtherInfo"
            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo"
            @click="otherInfoClick"
        >
            <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                <span v-else class="newsCommentCount g_newsInfo"
                    >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
                >
            </div>
            <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
            </div>
            <div
                v-if="(_showAuthor || _showComment) && (_showSort || _showDate)"
                class="ne_separatorLine g_ne_separatorLine"
            ></div>
            <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                {{ info.groupName }}
            </div>
            <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                {{ newsDate }}
            </div>
        </div>
        <div class="g_separator separatorLine"></div>
    </div>
</template>

<script>
// 与big-pic-text-list-item基本相同，可以优化抽成一个组件 @ken
import { mapFlag } from '@/shared/flag';
import ListItem from '../../mixins/ListItem';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'PicTextItemV1',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default() {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                cusNewsListPic: 0x8,
            },
            'info.flag'
        ),
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        indexPicHeight() {
            // 首图高度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            return indexPicHeightType == 2 ? picHeightSet.indexPicHeight / 20 : 160 / 20;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        itemClasses() {
            return `newsLine line icon-gline ${this._lineClass}One ${this._topFlagLineClass}`;
        },
        divSize() {
            let divWidth = 50;
            let divHeight = 50;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth || 50;
                divHeight = tSize.picHeight || 50;
            }
            return { divWidth, divHeight };
        },
        picTextStyle() {
            return {
                width: `${this.divSize.divWidth}px`,
                height: `${this.divSize.divHeight}px`,
            };
        },
        picTextClasses() {
            return this.info.realPicId === '' ? 'newsLeftImg icon-noFigureS' : 'newsLeftImg';
        },
        picItemStyle() {
            let picStyle = {
                width: `${this.divSize.divWidth}px`,
                height: `${this.divSize.divHeight}px`,
            };
            if (this.info.realPicId) {
                picStyle['background'] = `url(${
                    this.noLazyLoad ? this.info.pic160Path : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
    },
};
</script>

<style></style>
