<template>
    <module-frame :module-id="moduleId">
        <Type1 v-if="styleType == 1" :list="list" @imgClick="photoSlidesshow(index)" />
        <Type6 v-else-if="styleType == 6" :list="list" @imgClick="photoSlidesshow(index)" />
        <Type7 v-else-if="styleType == 7" :list="list" @imgClick="photoSlidesshow(index)" />
        <image-viewer
            v-if="showMobiViewer"
            :z-index="zIndex"
            :initial-index="initialIndex"
            :on-close="onClose"
            :url-list="photoSwipeList"
            :is-mobi="true"
            :content-terminal="'mobi'"
            :manage-mode="manageMode"
            :document-event-el="documentEventEl"
            append-to-container-id="g_body"
            :append-to-body="false"
            :disable-long-press="banCopyTextImg"
        />
        <pagenation
            v-if="showPageComponent"
            :module-id="moduleId"
            :total-size="totalSize"
            :page-size="content.count"
            :pageno="pageno"
            :open-theme-v3="openThemeV3"
            :ajax-pagenation="true"
            @on-change="changePage"
            @click.native.stop=""
        >
        </pagenation>
    </module-frame>
</template>

<script>
import { mapState } from 'vuex';

import { scrollToModule } from '@/modules/comm';
import { photoListMixins } from './mixins';
import { bindEvent } from '../utils';
import { ing } from '@/shared/fai';
import { getPhotoListData, getPhotoGroupData } from '@/api/photo';
import { slidesshowMixins } from '../../moduleMixins';
import ModuleFrame from '@/modules/frame/index.vue';
import Type1 from './components/type1.vue';
import Type6 from './components/type6.vue';
import Type7 from './components/type7.vue';
import Pagenation from '@/components/pagenation.vue';

// const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'PhotoList',
    components: {
        ModuleFrame,
        Pagenation,
        Type1,
        Type6,
        Type7,
    },
    mixins: [photoListMixins, slidesshowMixins],
    provide() {
        return {
            moduleId: this.moduleId,
            manageMode: VITE_APP_MODE !== 'visitor',
            lcid: this.lcid,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            manageMode: VITE_APP_MODE !== 'visitor',
            list: [],
            pageno: 0,
            totalSize: 0,
            photoSwipeList: [],
        };
    },
    computed: {
        ...mapState('app', ['openThemeV3', 'lcid', 'LS']),
        ...mapState(['currentPageModuleIdMap']),
        styleType() {
            return this.content.type;
        },
        showPageComponent() {
            return this.content.type == 1 && this.content.showPage == 0 ? true : false;
        },
        options() {
            let options = this.module.renderOptions || {};
            options.newPhotoSlidesGrayTest = true;
            return options;
        },
        noResultFound() {
            return this.LS.noResultFound;
        },
    },
    watch: {
        module: {
            handler: function () {
                this.noLazyLoad = true;
                this.refreshModuleData();
            },
            deep: true,
        },
    },
    created() {
        this.initData();
    },
    mounted() {
        this.refreshModuleData(true);
        setTimeout(() => {
            bindEvent(this);
        }, 50);
    },
    methods: {
        initData() {
            this.list = this.module.extInfo.list || [];
            this.totalSize = this.module.extInfo.totalSize;
            this.pageno = this.module.extInfo.pageno;
        },
        changePage(pageno) {
            this.ajaxGetPageData(pageno);
        },
        ajaxGetPageData(pageno = 1) {
            //修改相关的只能是弹窗中修改，因此，在这里不用处理这块
            let self = this;
            let paramData = {
                pageno,
                pageSize: this.content.count,
                groupId: this.content.groupId,
                colId: Fai.top._colId,
                extId: Fai.top._extId,
                id: this.moduleId,
            };
            getPhotoListData(paramData)
                .catch(function () {
                    self.errTips();
                })
                .then(function (response) {
                    let { data } = response;
                    if (data.success) {
                        self.totalSize = data.totalSize;
                        self.pageno = parseInt(pageno);
                        self.list = data.list;
                        scrollToModule(self.moduleId);
                        self.$nextTick(() => {
                            bindEvent(self);
                        });
                    } else {
                        self.errTips();
                    }
                });
        },
        errTips() {
            if (VITE_APP_MODE !== 'visitor') {
                Fai.ing('系统繁忙，请稍候重试', false);
            } else {
                ing('系统繁忙，请稍候重试', false);
            }
        },
        photoSlidesshow(index) {
            let self = this;
            getPhotoGroupData({ groupId: this.content.groupId })
                .catch(function () {
                    self.errTips();
                })
                .then(function (response) {
                    let { data } = response;
                    if (data.success) {
                        self.photoSwipeList = data.photoList.map((item) => {
                            let {
                                name: title,
                                pic: id,
                                basic: description,
                                basic: desc,
                                detailContent,
                                groupId,
                                picThumbPath: src,
                                width,
                                height,
                            } = item;
                            return {
                                title,
                                id,
                                description,
                                desc,
                                detailContent,
                                groupId,
                                src,
                                width,
                                height,
                                index,
                                openLink: false,
                            };
                        });
                        self.openPhotoSlidesshow(index);
                    } else {
                        self.errTips();
                    }
                });
        },
        refreshModuleData() {},
    },
};
</script>

<style lang="scss">
.photoModule.styleForm1 {
    margin-top: 0.5rem;
    /* overflow: hidden; */
    /* 暂时注释掉，先测试 */
}
.form .styleForm1 .photoListImg {
    display: inline-block;
    vertical-align: bottom;
}
.form .formMiddle .imgClass_160 {
    text-align: center;
    font-size: 0;
}
.photoModule.styleForm1 .photoDiv {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}
.photoModule.styleForm1 li {
    text-align: center;
}
.photoModule.styleForm1 .photoDiv {
    display: inline-block;
    margin-bottom: 1rem;
    vertical-align: top;
    -webkit-backface-visibility: hidden; /*避免banner滚动的时候造成闪屏*/
}

.photoModule.styleForm1 .imgDiv {
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
}
.photoModule.styleForm1 .photoDiv .imgName {
    word-break: normal; /*word-wrap:normal;*/
    max-width: 13rem;
    margin: 0 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #676767;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.2rem;
}
.mPhotoList .photoSlideList .photoCrossedSlideSec .imgName {
    padding: 0.25rem;
    word-break: normal;
    max-width: 13rem;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}
.mPhotoList .photoSlideList a {
    font-size: 0.6rem;
}
.imgDetailDiv {
    overflow: scroll;
    display: none;
    top: 0px;
    transition: all 500ms;
    -webkit-transition: all 500ms;
    -ms-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    position: fixed;
    height: 100%;
    z-index: 99;
    background: black;
}

.exitButton {
    background: url(/image/PicClose.png?v=201711250607) no-repeat;
    width: 1rem;
    height: 1rem;
    right: 1rem;
    top: 0.9rem;
    position: absolute;
    background-size: 100%;
    cursor: pointer;
    position: fixed;
}
.showImg {
    width: 100%;
}
.imgDetailArea {
    margin-top: 2rem;
    color: white;
    line-height: 1.8;
    padding: 0 0.5rem;
    white-space: normal;
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
    text-align: left;
}
.imgDetailDiv .imgName {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    overflow: hidden;
    word-wrap: break-word;
}
.imgDesc {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    color: white;
}

.g_background {
    background: #fff;
}

.photoModule.styleForm7 {
    position: relative;
    margin: 0.5rem auto;
    overflow: hidden;

    width: 100%;
}

.photoModule.styleForm7 ul {
    position: absolute;
    padding: 0px;
    left: 0;
    transition: all 500ms;
}

.photoModule.styleForm7 ul li {
    width: 15rem;
    list-style-type: none;
    float: left;
    text-align: center;
    overflow: hidden;
    background: #fff;
}

.photoModule.styleForm7 .imgDiv2 {
    overflow: hidden;
    display: inline-block;
    background: #fff;
}

.photoModule.styleForm7 .prevPhoto {
    background: url(/image/PicLeft.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    width: 1.75rem;
    height: 1.75rem;
    float: left;
    top: 30%;
    cursor: pointer;
    position: absolute;
    background-size: 80%;
    background-position: 0.25rem;
    border-radius: 4px;
    left: 0.75rem;
}
.photoModule.styleForm7 .nextPhoto {
    background: url(/image/PicRight.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    width: 1.75rem;
    height: 1.75rem;
    float: right;
    top: 30%;
    cursor: pointer;
    position: absolute;
    background-size: 80%;
    background-position: 0.25rem;
    border-radius: 4px;
    right: 0.75rem;
}
.photoModule .styleForm7 .imgContainer {
    display: table-cell;
    vertical-align: middle;
}
.imageMiddleSpan {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.photoModule .photoModuleImageSwipeContainer {
    overflow: hidden;
    position: relative;
}

.photoModule.styleForm7 .imgName {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1.2rem;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
}
.photoModule.styleForm6 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.photoModule.styleForm6 .fk-photoBulletStyle .f-imgName {
    display: none;
}

.photoModule.styleForm6 .photoSwipe {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    text-align: center;
    visibility: visible;
}
.photoModule.styleForm6 .photoSwipeBox {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; /*免图片各占据一行：优化为将第一节点外的节点先隐藏*/
}
.photoModule.styleForm6 .photoSwipeBox .swipImgArea {
    height: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    font-size: 0;
}
.imageDiv img {
    vertical-align: middle;
}
.photoModule.styleForm6 .photoBullet {
    position: absolute;
    width: 100%;
    height: 1.2rem;
    top: auto;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);
    line-height: 1.2rem;
}

.photoModule.styleForm6 .fk-photoBulletStyle {
    background: none;
}

.photoModule.styleForm6 .noBG {
    background: none;
    text-align: center;
}
.photoModule.styleForm6 .photoBullet .bullets {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.photoModule.styleForm6 .noBG .bullets {
    text-align: center;
}

.photoModule.styleForm6 .fk-photoBulletStyle .f-bullets {
    text-align: center;
}

.photoModule.styleForm6 .photoBullet .bullets li {
    display: inline-block;
    width: 0.35rem;
    height: 0.35rem;
    border-radius: 100%;
    margin: 0 0.1rem;
    cursor: pointer;
    background: #adb1b1;
}
.photoModule.styleForm6 .noBG .bullets li {
    background: rgba(170, 170, 170, 0.5);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}
.photoModule.styleForm6 .photoBullet .bullets li.on {
    background: #fff;
}
.photoModule .photoBullet .imgName {
    width: 100%;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    line-height: 0.9rem;
    font-size: 0.6rem;
    text-align: center;
    margin-bottom: -0.2rem;
    padding-top: 0.2rem;
}
/* .tableFixColor{
  background-color: rgba(0, 0, 0, .4);
} */
.tdUl,
.tdUl .bullets.f-bullets {
    line-height: 1rem;
    font-size: 0;
}
.tdUl {
    height: 1rem;
    padding: 0;
}
.photoModule.styleForm6 .photoBullet .bullets li {
    vertical-align: middle;
}
/* .photoModule.styleForm6 .photoBullet{
  background-color: transparent;
} */
.tableFix td {
    padding: 0;
}
.photoModule .photoBullet .tableFix {
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
}

.photoModule .photoBullet .tableFix .tdUl {
    vertical-align: bottom;
}

.nextAndPreviousIcon {
    width: 1.75rem;
    height: 1.75rem;
    float: right;
    top: 50%;
    cursor: pointer;
    position: absolute;
    background-position: 0.25rem;
    border-radius: 4px;
}
.imageSliderNext {
    background: url(/image/PicRight.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    background-size: 100%;
    right: 0.75rem;
}
.imageSliderPrevious {
    background: url(/image/PicLeft.png?v=201711250607) rgba(0, 0, 0, 0.4) no-repeat;
    background-size: 100%;
    left: 0.75rem;
}
</style>
