<template>
    <div
        v-if="showOtherInfo"
        class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo"
        @click="otherInfoClick"
    >
        <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
            <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
            <span v-else class="newsCommentCount g_newsInfo"
                >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
            >
        </div>
        <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
            <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
        </div>
        <div
            v-if="!isNewAddModule && (_showAuthor || _showComment) && (_showSort || _showDate)"
            class="ne_separatorLine g_ne_separatorLine"
        ></div>
        <div v-if="_showSort" class="ne_newsSort g_newsInfo">
            {{ info.groupName }}
        </div>
        <div v-if="_showDate" class="ne_newsTime g_newsInfo">
            {{ newsDate }}
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'NewInfo',
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
    },
};
</script>

<style></style>
