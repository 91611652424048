<template>
    <div :id="_listId" class="fk-productListText mProductList" :class="_listClass" :mType="module.content.t">
        <template v-for="(info, index) in productList">
            <text-item :key="'p_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            <div v-if="!isNewAddModule" :key="'p1_' + info.id" class="g_separator separatorLine f-productListSep"></div>
        </template>
    </div>
</template>

<script>
import TextItem from './text-item.vue';
import productMixin from '../../mixins/productMixin';
import { mapFlag } from '@/shared/flag';
export default {
    name: 'TextList',
    components: { TextItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
    computed: {
        ...mapFlag({
            hasEditTwoColumnStyle: 0x2,
            isNewAddModule: 0x4,
        }),
    },
};
</script>

<style></style>
