<template>
    <popup-zone :module-id="moduleId" :module-info="moduleInfo" />
</template>

<script>
import PopupZone from './popup-zone.vue';
import { componentsTable } from '@/modules/visitor-interface';

export default {
    name: 'PopupZoneVisitor',
    components: {
        PopupZone,
    },
    provide() {
        return {
            componentsTable: this.componentsTable,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
        moduleInfo: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            componentsTable,
        };
    },
};
</script>

<style></style>
