<template>
    <div ref="dateContainer" class="u_custom_search_entry--date_wrap" :class="classes" @click.stop="handlerClick"></div>
</template>
<script>
import { initmobiFormTimeBtn } from '@/modules/mobiForm/util';
export default {
    name: 'CustomSearchDate',
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        module: {
            type: Object,
            default: () => ({}),
        },
        formInfo: {
            type: Object,
            default: () => ({}),
        },
        placeHolder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            currentValue: '',
        };
    },
    computed: {
        submitVal() {
            return this.currentValue;
        },
        classes() {
            return {
                is_dateRange: this.formItem.dateSetting.type == 1,
            };
        },
    },
    watch: {
        formItem: {
            deep: true,
            handler() {
                this.bindEvent();
            },
        },
    },
    mounted() {
        this.bindEvent();
    },
    methods: {
        validate() {
            var moduleId = this.module.id,
                formItemId = this.formItem.id,
                tmpInput = jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Pluginsinput').val(),
                dateType = this.formItem.dateSetting ? this.formItem.dateSetting.type : 0,
                temInputEnd =
                    dateType == 1 ? jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Plugins_endinput').val() : '';

            if (dateType == 1) {
                tmpInput += ' 至 ' + temInputEnd;
            }

            // 验证时才赋值
            this.currentValue = tmpInput;
            this.formItem.value = tmpInput;

            return true;
        },
        bindEvent() {
            const dateRange = this.formItem.dateSetting.type == 1;
            const classes = dateRange ? 'fk-startDateInput' : '';
            const moduleId = this.module.id;
            const formItemId = this.formItem.id;

            if (VITE_APP_MODE !== 'visitor') {
                jm(this.$el).find('.fk-dateInputMaskWrap').remove();
                jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).remove();
                jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).remove();
                jm(`#Mobi${moduleId}CalendarInput${formItemId}`).remove();
                if (
                    jm.inArray(7, this.formItem.dateSetting.banSingleDateList) > -1 &&
                    jm.inArray(0, this.formItem.dateSetting.banSingleDateList) === -1
                ) {
                    this.formItem.dateSetting.banSingleDateList.push(0);
                }
            }

            let template = `<div class="mobiCalendarPlugins ${classes}" id="Mobi${moduleId}Calendar${formItemId}Plugins"></div>`;

            // 结束日期输入框
            if (dateRange) {
                if (this.module.content.ms != 3) {
                    template += `<span class='fk-dateRangeUtil' id="Mobi${moduleId}CalendarInput${formItemId}">-</span>`;
                }

                template += `<div class="fk-endDateInput mobiCalendarPlugins" id="Mobi${moduleId}Calendar${formItemId}Plugins_end"></div>`;
            }
            jm(this.$refs.dateContainer).html(template);

            initmobiFormTimeBtn({
                moduleId,
                siteFormItemId: formItemId,
                minDate: new Date(),
                yearChangeable: true,
                monthChangeable: true,
                swipeable: true,
                placeholder: this.placeHolder,
                dateSetting: this.formItem.dateSetting,
                onAfterSelectDate: () => {
                    this.validate();
                },
            });

            jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).addClass('m_mobi_form_input_text');

            if (dateRange) {
                jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).addClass('m_mobi_form_input_text');
            }

            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins`).remove();
            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_end`).remove();
        },
        handlerClick(e) {
            e.stopPropagation();
        },
        resetValue() {
            var moduleId = this.module.id,
                formItemId = this.formItem.id;
            jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Pluginsinput').val(''),
                jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Plugins_endinput').val('');
            this.currentValue = '';
        },
    },
};
</script>

<style>
.u_custom_search_entry--date_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .u_custom_search_entry--date_wrap .fk-dateInputMaskWrap,.u_custom_search_entry--date_wrap .fk-dateInputMaskWrap {width: 6.75rem;} */

.u_custom_search_entry--date_wrap .fk-dateRangeUtil {
    text-align: center;
    width: 1.25rem;
}
</style>
