<template>
    <div class="three_pic_text" :class="column2">
        <template v-for="(info, index) in newsList">
            <three-pic-text-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            <div :key="'n_line_' + info.id" class="g_separator separatorLine"></div>
        </template>
    </div>
</template>

<script>
import threePicTextItem from './three-pic-text-item.vue';
import ListItem from '../../mixins/ListItem';
export default {
    name: 'UpAndDownPicText',
    components: { threePicTextItem },
    mixins: [ListItem],
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        isColumn2() {
            return this.module.content.naw == 2;
        },
        column2() {
            return this.module.content.naw == 2 ? 'isColumn2' : '';
        },
    },
};
</script>

<style></style>
