<template>
    <input
        type="text"
        class="m_mobi_form_input_text m_mobi_form_email"
        :value="currentValue"
        :placeholder="formItem.placeholder"
        :style="_mixinsInputStyle"
        @input="handleInput"
    />
</template>
<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormEmail',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
        };
    },
    computed: {
        submitVal() {
            return this.currentValue;
        },
    },
    watch: {
        value(val) {
            this.currentValue = val;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.currentValue = value;
        },
        resetValue() {
            this.currentValue = '';
        },
        validate() {
            if (this.formItem.must && this.currentValue === '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            } else if (this.currentValue !== '' && !jm.isCardNo(this.currentValue)) {
                this.$mobiIng(jm.format(LS.memberSignupUserAddItemCorrect, encodeHtml(this.formItem.name)));
                return false;
            }

            return true;
        },
        initDefaultValue() {
            if (this.inMemberDetailEdit) {
                const initialValue = this.getEditingItemSubmitValue();
                if (initialValue !== undefined) {
                    this.currentValue = initialValue;
                }
            }
        },
    },
};
</script>

<style></style>
