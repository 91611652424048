import { MODULE_STYLE } from '@/def/module';
export function initModuleCubeNavItemHeight(rowStlye, moduleId) {
    if (rowStlye == 5 || rowStlye == 6 || rowStlye == 8 || rowStlye == 11 || rowStlye == 15 || rowStlye == 16) {
        return;
    }
    var rowListStr = '.cubeLink_row';
    if (rowStlye == 14) {
        rowListStr = '.cubeLink_row_head';
    }
    var rowList = $('#module' + moduleId).find(rowListStr);
    if (rowList.length > 0) {
        rowList.each(function () {
            var rowCubeList = $(this).find('.cubeLink');
            var rowMaxHeight = 0;
            rowCubeList.each(function () {
                var height = Number($(this).height());
                if (height > rowMaxHeight) {
                    rowMaxHeight = height;
                }
            });

            $(this)
                .find('.cubeLink_a')
                .css('height', rowMaxHeight + 'px');
            if (rowStlye != 13 && rowStlye != 14) {
                rowCubeList.css('height', rowMaxHeight + 'px');
            }
        });
    }
}

export function triggerAllLazyLoad() {
    jm(window).trigger('scroll');
}

export function CubeNavOpenItemManage(moduleId, id) {
    const currentEdit = window.$store.state.manage.cubeNavigation.curCubeNavigationItemEdit;
    if (currentEdit.moduleId !== moduleId || currentEdit.itemId !== id) {
        window.$store.commit('manage/cubeNavigation/updateCurCubeNavigationItemEdit', {
            moduleId,
            itemId: id,
        });
    }

    Vue.prototype.$designer.open({
        moduleId,
        panelType: 'JzCubeNavigationEditItem',
        styleId: MODULE_STYLE.CUBE_NAVIGATION,
    });
}
