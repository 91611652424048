import { onTouchAndClickEvent } from '@/shared/event';
export function setStarSelect() {
    onTouchAndClickEvent('.J-star-list li', function () {
        var bgColor = jm('.userCommentPanel').css('background-color');
        if (typeof bgColor != 'string') {
            bgColor = '#FFF';
        }
        var index = jm(this).index();
        if (index >= 5) {
            return true;
        }
        var list = jm(this).parent();
        list.attr('_star', index + 1);
        for (var i = 0; i < 5; i++) {
            var tmpLi = list.find('li').eq(i);
            if (i < index + 1) {
                if (index < 2) {
                    tmpLi.removeClass('select_more');
                    tmpLi.addClass('select_less');
                } else {
                    tmpLi.removeClass('select_less');
                    tmpLi.addClass('select_more');
                }
            } else {
                tmpLi.removeClass('select_more');
                tmpLi.removeClass('select_less');
            }
        }
        list.find('li.score-tip').show();
        var level = '';
        var color = '';

        if (index == 0) {
            level = LS.badComment;
            color = '#b7b6b6';
        } else if (index == 1) {
            level = LS.moderateComment;
            color = '#b7b6b6';
        } else if (index == 2) {
            level = LS.moderateComment;
            color = '#ffb600';
        } else {
            level = LS.js_highComment;
            color = '#ffb600';
        }
        list.find('li.score-tip').html('<em></em><span></span>' + (index + 1) + LS.score + level);
        list.find('li.score-tip').css({
            'border-color': color,
            color,
            display: 'inline-block',
            'max-width': '6.6rem',
        });
        list.find('li.score-tip em').css({
            borderColor: 'transparent ' + color + ' transparent transparent',
        });
        list.find('li.score-tip span').css({
            borderColor: 'transparent ' + bgColor + ' transparent transparent',
        });
    });
}
