import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import NewsView from './module/news.vue';

class _NewsList extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.NEWS_LIST, options);
    }

    render() {
        return NewsView;
    }
}

export const NewsList = new _NewsList();
