<template>
    <div id="calculationModalFrame" class="calculationModalFrame">
        <div class="calculationModalFrameBg" @click="frameBgClickHandler">
            <div class="content_box consultWechatQrCodeBounceIn">
                <svg class="close_btn" @click="closeHandle">
                    <use xlink:href="#jzm-v26" />
                </svg>
                <div class="result_content">
                    <div v-for="item in calculateReultList" class="formula_result">
                        <div class="formula_result_text" v-html="encodeHtml(item.name)"></div>
                        <div class="formula_result_money"
                            >{{ formatMoney(item.value)
                            }}<span v-if="item.uHide == 0" class="formula_result_unit">{{ item.unit }}</span></div
                        >
                    </div>
                    <div class="variable_list">
                        <div v-for="item in variableData" class="vaiable_item">
                            <div class="variable_name" v-html="encodeHtml(item.name)"></div>
                            <div v-if="item.type == 0" class="variable_value"
                                >{{ formatMoney(item.value)
                                }}<span v-if="item.uHide == 0" class="variable_unit">{{ item.unit }}</span></div
                            >
                            <div v-else class="variable_value">{{ item.valueKey }}</div>
                        </div>
                    </div>
                    <a :href="imgHref" :onclick="jumpOnclickStr" class="result_img">
                        <img :src="imgSrc" />
                    </a>
                    <div class="result_note_text" v-html="encodeHtml(resultText)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { calculationModalFrame } from '../service';

import { createUrlArgsOnclickStr } from '@/modules/comm';
import { encodeHtml } from '@/shared/util';
export default {
    el: '#calculationModalFrame',
    computed: {
        imgSrc() {
            var src = '';
            if (this.resultMsg.mip) {
                src = this.resultMsg.mip;
            } else {
                return Fai.top._resRoot + '/image/v2/calculation_default_img.jpg?v=202003021225';
            }
            return src;
        },
        imgHref() {
            if (this.resultMsg.jumpData.t == '103' && VITE_APP_MODE !== 'visitor') {
                return "javascript:top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true)";
            }
            if (this.resultMsg.jumpData.u) {
                return this.resultMsg.jumpData.u;
            } else {
                return 'javascript:void(0)';
            }
        },
        jumpOnclickStr() {
            return createUrlArgsOnclickStr(this.resultMsg.jumpData.reqArgs, false) || null;
        },
        resultText() {
            return this.resultMsg.mt ? this.resultMsg.mt : ' ';
        },
    },
    methods: {
        encodeHtml(html) {
            return encodeHtml(html);
        },
        frameBgClickHandler(e) {
            if (e.target) {
                if (e.target.classList.contains('calculationModalFrameBg')) {
                    this.closeHandle();
                }
            }
        },
        closeHandle() {
            calculationModalFrame.destroy();
        },
        formatMoney(number) {
            //保留2位小数
            //处理千分位分隔符
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = 2,
                sep = ',', //千分位分隔符
                dec = '.', //小数点
                s = '';

            function toFixedFix(n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k, 10) / k;
            }

            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            var re = /(-?\d+)(\d{3})/;
            while (re.test(s[0])) {
                s[0] = s[0].replace(re, '$1' + sep + '$2');
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
    },
};
</script>

<style>
.calculationModalFrame {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9998;
    top: 0;
}
.calculationModalFrameBg {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
.calculationModalFrame .content_box {
    width: 13.5rem;
    height: auto;
    background: #fff;
    min-height: 10rem;
    border-radius: 0.25rem;
    position: relative;
    max-height: 22.75rem;
    overflow-y: auto;
}
.calculationModalFrame .close_btn {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    fill: #bdbdbd;
    fill: #bdbdbd;
    right: 0.4rem;
    top: 0.4rem;
}
.calculationModalFrame .result_content {
    box-sizing: border-box;
    padding: 0.75rem;
    text-align: center;
}
.calculationModalFrame .result_content .formula_result {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
}
.calculationModalFrame .formula_result .formula_result_text {
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 1.5rem;
    font-size: 0.6rem;
}
.calculationModalFrame .formula_result .formula_result_money {
    font-size: 1.2rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 1.2rem;
    width: 100%;
    word-break: break-all;
}
.calculationModalFrame .variable_list {
    width: 10.5rem;
    display: inline-block;
    padding: 0.3rem 0.75rem 0.4rem 0.75rem;
    border-bottom: 0.025rem dashed #e0e0e0;
}
.calculationModalFrame .variable_list .vaiable_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}
.calculationModalFrame .vaiable_item .variable_name {
    color: #666;
    font-size: 0.6rem;
    max-width: 60%;
}
.calculationModalFrame .vaiable_item .variable_value {
    color: #333333;
    font-size: 0.6rem;
}
.calculationModalFrame .result_content .result_img {
    width: 100%;
    margin-top: 0.75rem;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}
.calculationModalFrame .result_content .result_img img {
    max-width: 100%;
    max-height: 4.5rem;
}
.calculationModalFrame .result_content .result_note_text {
    font-size: 0.6rem;
    color: #999999;
    margin: 0.6rem 0 0.5rem 0;
}
</style>
