<template>
    <div ref="cityContainer" class="m_mobi_form_city">
        <input
            :id="id"
            type="text"
            class="m_mobi_form_input_text m_mobi_form_city"
            :value="currentValue"
            :placeholder="module.extInfo.siteFormPleaseSelect"
            readonly="true"
            :style="_mixinsInputStyle"
        />
    </div>
</template>
<script>
import Mixins from '../mixins';
import { siteFormSelectArea } from '../../util';
import { ing } from '@/shared/tips';
import { encodeHtml } from '@/shared/util';
import { site_cityUtil } from '@/shared/city';
import formDetailEditMixin from '../mixins/formDetailEdit.js';
import { mapState } from 'vuex';

export default {
    name: 'FormCity',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        formInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            id: `M${this.module.id}F${this.formInfo.id}siteFormAddressInput${this.formItem.id}`,
        };
    },
    computed: {
        ...mapState('app', ['lcid']),
        level() {
            let openCode = this.formItem.openMunicipalCode;
            return openCode ? 3 : 2;
        },
        submitVal() {
            return this.currentValue;
        },
    },
    watch: {
        formItem: {
            deep: true,
            handler() {
                jm(`#M${this.module.id}F${this.formInfo.id}siteFormAddressInput${this.formItem.id}`).off('click');
                siteFormSelectArea(this.formItem.id, this.formInfo.id, this.module.id, Fai.top._lcid, this.level);
            },
        },
    },
    async mounted() {
        this.initDefaultValue();
        const initialValueOptions = await this.getInitialAreaValue();

        siteFormSelectArea(this.formItem.id, this.formInfo.id, this.module.id, Fai.top._lcid, this.level, null, {
            ...initialValueOptions,
        });
    },
    methods: {
        validate() {
            let tmpAddressInput = jm(
                `#M${this.module.id}F${this.formInfo.id}siteFormAddressInput${this.formItem.id}`
            ).val();
            if (this.formItem.must && tmpAddressInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            // 因为是jq实现，提交时才赋值
            this.currentValue = tmpAddressInput;
            return true;
        },
        resetValue() {
            this.currentValue = '';
        },
        initDefaultValue() {
            if (this.inMemberDetailEdit) {
                const initialValue = this.getEditingItemSubmitValue() ?? '';

                if (initialValue) {
                    this.currentValue = initialValue;
                }
            }
        },
        async getInitialAreaValue() {
            if (!this.currentValue) return Promise.resolve({});

            const initialAreaValue = await site_cityUtil.getDataFromLocationString(this.currentValue, this.lcid);
            // 包装成日期选择控件 value 和 text 的格式
            return {
                value: initialAreaValue.reduce((acc, area, index) => {
                    // 构造'60000,101000,132000' 的字符串
                    if (index !== initialAreaValue.length - 1) {
                        return acc + (area.id + ',');
                    }
                    return acc + String(area.id);
                }, ''),
                text: initialAreaValue.map((area) => area.name),
            };
        },
    },
};
</script>

<style></style>
