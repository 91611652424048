<template>
    <div class="photoModule styleForm7 g_background">
        <div :id="'photoModuleImageSwipe' + moduleId" class="swipe">
            <div class="bannerSwipeContainer photoModuleImageSwipeContainer">
                <div v-for="(item, index) in list" :key="index" class="photoImageItem">
                    <a :href="item.href || undefined" @click="imgClick(index)">
                        <span class="imageMiddleSpan"></span>
                        <img
                            :data-picid="item.picId"
                            class="photoImageItem J_img_lazyload"
                            :vwidth="item.vwidth"
                            :vheight="item.vheight"
                            :src-original="item.picThumbPath"
                            :src="item.picThumbPath"
                            :alt="item.alt"
                            :photoIndex="index"
                            :linkType="item.pLinkType"
                        />
                    </a>
                </div>
            </div>
            <div v-if="list.length > 0 && showName" class="imgName" :style="imgNameStyle">{{ list[0].imgName }}</div>
        </div>
    </div>
</template>

<script>
import { photoListMixins } from '../mixins';
export default {
    name: 'Type1',
    mixins: [photoListMixins],
    inject: ['moduleId', 'manageMode'],
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        imgNameStyle() {
            return this.nameWordWrap ? {} : { whiteSpace: 'nowrap' };
        },
        loadingPath() {
            return this.module.extInfo.loadingPath;
        },
        photoDivClass() {
            return this.showName ? 'photoBg' : '';
        },
        showName() {
            return this.content.showName == 0 ? true : false;
        },
        scale() {
            return this.content.picScale;
        },
        imgClass() {
            return this.scale == 0 ? '' : 'stretchImg';
        },
        nameWordWrap() {
            return this.content.newNameWrap == 0 ? true : false;
        },
    },
    methods: {
        imgClick(index) {
            this.$emit('imgClick', index);
        },
    },
};
</script>
