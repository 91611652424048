<template>
    <div class="head_word_pic_text_list">
        <head-word-pic-item v-if="!!headPicItemInfo" :info="headPicItemInfo" :no-lazy-load="noLazyLoad" />
        <div v-if="isNewAddModule || (!isNewAddModule && !isColumn2)" class="g_separator separatorLine"></div>
        <text-list :news-list="newsList2"></text-list>
    </div>
</template>

<script>
import HeadWordPicItem from './head-word-pic-item.vue';
import TextList from '../textList/text-list.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'HeadWordPicTextList',
    components: { HeadWordPicItem, TextList },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        headPicItemInfo() {
            return !!this.newsList && this.newsList[0];
        },
        newsList2() {
            return this.newsList ? this.newsList.slice(1, this.newsList.length) : [];
        },
        isColumn2() {
            return this.module.content.naw == 2;
        },
    },
};
</script>

<style></style>
