import { getModuleAttr, getModuleAttrPatternStg } from '@/features/global-mobi-function/methods/util';

import { mergeFonts, analysisCustomFontFaceFamily } from '@/shared/fontFace';
import { getSimpleTextContent, getFloagBtnContent } from '@/manage/utils/module';

export function initDragble(elem) {
    var module = $(elem);

    if (module.length < 1) return;

    var style = parseInt(module.attr('_modulestyle')),
        id = parseInt(module.attr('id').replace('module', ''));

    $(elem)
        .draggable('destroy')
        .draggable({
            containment: 'parent',
            cursor: 'move',
            axis: 'x,y',
            scroll: false,
            distance: 3,
            tolerance: 2,
            smartguides: '.form',
            cancel: '.formLockEdit, input, textarea, select, .ui-resizable-handle',
            start() {},
            drag() {
                if (style == 52 || style == 53 || style == 54) {
                    window.$('#fkEditor-toolBar-' + id).hide();
                }
            },
            stop() {
                var module = $('#module' + id);
                var moduleAttr = getModuleAttr(id);

                module.data('dragChange', true);
                moduleAttr.pattern.changed = true;
                Vue.prototype.$designer.styleChanged();
            },
        });
}

export function getPopupZoneContent(pZone) {
    let module = $(`#module${pZone.moduleId}`),
        oldContent = pZone.moduleInfo.content,
        moduleList = oldContent.moduleList,
        tmpIdArray = [],
        canSet = true,
        moduleInZoneData = [],
        content = {},
        result;

    module.find('.J_formPopupZoneContent .form').each(function (index, el) {
        var oldInModuleInfo = null;
        var inZoneModuleId = parseInt($(el).attr('id').replace('module', ''));
        var inModule = $(el);
        var dragChange = inModule.data('dragChange');

        // 判断是否为已经存在的模块
        for (var i = 0; i < moduleList.length; i++) {
            var inModuleInfo2 = $.parseJSON(moduleList[i]);
            if (inModuleInfo2.id == inZoneModuleId) {
                oldInModuleInfo = inModuleInfo2;
                break;
            }
        }

        // 添加新模块会找不到moduleInfo
        if (!oldInModuleInfo) {
            dragChange = true;
        }

        result = {};
        result.id = inZoneModuleId;
        result.t = dragChange ? inModule[0].offsetTop : oldInModuleInfo.t;
        result.l = dragChange ? inModule[0].offsetLeft : oldInModuleInfo.l;
        result.s = inModule.is(':visible');

        if (dragChange) {
            // 获取弹窗下的字体大小
            let moduleInfo = window.$store.getters.getModuleById(inZoneModuleId);
            let renderOptions = moduleInfo.renderOptions || {};
            let moduleInPopupZone = renderOptions.moduleInPopupZone;
            if (moduleInPopupZone.htmlFontSize) {
                result.t = Math.floor((result.t * moduleInPopupZone.htmlFontSize) / window._htmlFontSize);
            }
        }

        if ($.inArray(inZoneModuleId, tmpIdArray) != -1) {
            canSet = false;
        } else {
            tmpIdArray.push(inZoneModuleId);
        }

        if (!isNaN(result.id) && !isNaN(result.t) && !isNaN(result.l) && canSet) {
            moduleInZoneData.push(result);
        }

        inModule.data('dragChange', false); // 解决没有修改位置的模块，编辑后和编辑前位置不一样bug
    });

    // 原本隐藏的子模块（防止因为dom没吐出，导致上一步没有获取到dom）
    for (var i = 0; i < moduleList.length; i++) {
        var inModuleInfo = window.$.parseJSON(moduleList[i]),
            inModuleId = inModuleInfo.id,
            inModuleDom = module.find('#module' + inModuleId);

        if (!inModuleInfo.s && inModuleDom.length < 1) {
            moduleInZoneData.push(inModuleInfo);
        }
    }

    // 去重
    var finalModuleInZoneData = [];
    var tmpHash = {};
    var tmpModule;
    for (let i = 0; i < moduleInZoneData.length; i++) {
        tmpModule = moduleInZoneData[i];
        if (!tmpHash['module' + tmpModule.id]) {
            finalModuleInZoneData.push(window.$.toJSON(tmpModule));
            tmpHash['module' + tmpModule.id] = tmpModule;
        }
    }

    content.moduleList = finalModuleInZoneData;
    content.cmi = oldContent.cmi;
    return content;
}

export function getInModuleContent(pZone) {
    if (typeof window.$store.state.manage.moduleAttr == 'undefined') return;

    var moduleInfoList = [],
        content;

    $('#module' + pZone.moduleId)
        .find('.form')
        .each(function (index, el) {
            var inModule = $(el),
                inModuleId = parseInt($(el).attr('id').replace('module', '')),
                isFloatBtnV2 = inModule.hasClass('formStyle54'),
                isFloatImgV2 = inModule.hasClass('formStyle53');

            if (typeof window.$store.state.manage.moduleAttr['module' + inModuleId] == 'undefined') {
                return;
            }

            var FaiAttr = window.$store.state.manage.moduleAttr['module' + inModuleId];
            var moduleInfo = {};
            var moduleChanged = false;
            var moduleAttr = getModuleAttr(inModuleId);

            // if (isFloatBtnV2) {
            //     FaiAttr = window.$store.getters.getModuleById(inModuleId);
            // }
            let module = window.$store.getters.getModuleById(inModuleId);
            if (window._openThemeV3 && (isFloatImgV2 || isFloatBtnV2)) {
                var moduleAttrV3 = module.allAttrV3;
                if (moduleAttrV3.patternChanged) {
                    moduleInfo.patternV3 = moduleAttrV3.patternV3;
                    moduleChanged = true;
                }
            }

            if (moduleAttr.pattern.changed) {
                moduleInfo.pattern = $.toJSON(getModuleAttrPatternStg(inModuleId));
                moduleChanged = true;
            }

            if (FaiAttr.contentChange || module.contentChange) {
                content = module.content || {};

                // 简易文本
                if (inModule.hasClass('formStyle52')) {
                    let oldFontList = content.fontList || [];
                    let oldFontResIdList = oldFontList.map((fontRes) => fontRes.resId);
                    content = getSimpleTextContent(inModule);
                    // 获取文本字体
                    let editor = inModule.find('.fkEditor');
                    let fontList = mergeFonts(editor);
                    // 将信息保存到content字段里
                    content.fontList = fontList;
                    content.oldFontResIdList = oldFontResIdList;

                    const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                    content.jumpMode = moduleData.content.jumpMode;
                    content.jumpWxAppData = moduleData.content.jumpWxAppData;

                    // 保存是否选择了主题色
                    content.tcc = content.text.indexOf('_themecolor') == -1 ? 0 : 1;
                    if (content.tcc == 1) {
                        content.themeColor = window._themeColor;
                    }
                }

                // 按钮模块
                if (inModule.hasClass('formStyle54')) {
                    content = getFloagBtnContent(inModule);
                    const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                    content.jumpMode = moduleData.content.jumpMode;
                    content.jumpWxAppData = moduleData.content.jumpWxAppData;
                }

                moduleInfo.content = content;
                moduleChanged = true;
            }
            // 特殊处理按钮模块  保存字体有变化的按钮
            if (moduleAttr.pattern.changed && inModule.hasClass('formStyle54')) {
                // 获取改变前的fontList
                content = module.content;
                let oldFontList = content.fontList;
                let oldFontResIdList = oldFontList.map((fontRes) => fontRes.resId);
                content = getFloagBtnContent(inModule);
                // 获取模块设置的字体
                let flBtn = inModule.find('.floatBtn');
                let fontFamily = flBtn.css('font-family') || '';
                // 将信息保存到content的字段中去
                let fontList = [];
                const { flag, id, random } = analysisCustomFontFaceFamily(fontFamily);
                if (flag === 'fontface') {
                    const fontText = flBtn.text();
                    fontList.push({
                        id: random,
                        fontId: +id,
                        fontFamily,
                        substring: Fai.unique(fontText),
                    });
                }
                // 保存fontList oldFontResIdList
                content.fontList = fontList;
                content.oldFontResIdList = oldFontResIdList;
                const moduleData = window.$store.state.currentPageModuleIdMap[inModuleId] || {};
                content.jumpMode = moduleData.content.jumpMode;
                content.jumpWxAppData = moduleData.content.jumpWxAppData;
                moduleInfo.content = content;
                moduleChanged = true;
            }

            if (moduleChanged) {
                moduleInfo.id = inModuleId;
                moduleInfoList.push(moduleInfo);
            }
        });

    return moduleInfoList;
}

export function initHistoryModules(moduleInfo, childrenList = []) {
    // 我的模块中 过滤关闭按钮模块
    let cmi = moduleInfo.content.cmi; // 关闭按钮模块id
    let finalModuleList = childrenList.filter((child) => {
        return child.id != cmi;
    });
    // 进行排序，显示在前，隐藏在后

    return finalModuleList;
}

export function removeModuleTips(id) {
    var module = $('#module' + id);
    module.find('.J_addContent').remove();
}
