<template>
    <div ref="cityContainer" class="m_mobi_form_city">
        <input
            :id="id"
            type="text"
            class="m_mobi_form_input_text m_mobi_form_city"
            :value="currentValue"
            :placeholder="placeHolder"
            readonly="true"
            @input="input"
        />
    </div>
</template>
<script>
import { siteFormSelectArea } from '@/modules/mobiForm/util';
export default {
    name: 'CustomSearchAddress',
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
        placeHolder: {
            type: String,
            default: '',
        },
        formInfo: {
            type: Object,
            default: () => ({}),
        },
        module: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
        };
    },
    computed: {
        level() {
            let openCode = this.formItem.openMunicipalCode;
            return openCode ? 3 : 2;
        },
        submitVal() {
            return this.currentValue;
        },
        id() {
            return `M${this.module.id}F${this.formInfo.id}siteFormAddressInput${this.formItem.id}`;
        },
    },
    watch: {
        formItem: {
            deep: true,
            handler() {
                jm(`#${this.id}`).off('click');
                siteFormSelectArea(
                    this.formItem.id,
                    this.formInfo.id,
                    this.module.id,
                    Fai.top._lcid,
                    this.level,
                    () => {
                        this.validate();
                    }
                );
            },
        },
    },
    mounted() {
        siteFormSelectArea(this.formItem.id, this.formInfo.id, this.module.id, Fai.top._lcid, this.level, () => {
            this.validate();
        });
    },
    methods: {
        input() {},
        validate() {
            let tmpAddressInput = jm(`#${this.id}`).val();
            if (tmpAddressInput == '') {
                return false;
            }

            // 因为是jq实现，提交时才赋值
            this.currentValue = tmpAddressInput;
            this.formItem.value = this.currentValue;
            return true;
        },
        resetValue() {
            this.currentValue = '';
        },
    },
};
</script>

<style></style>
