<template>
    <div :id="'photoModule' + module.id" class="photoModule newCarouselMultiPhoto styleForm9" :class="photoSwipeCls">
        <div :id="'photoSwipe' + module.id" class="photoSwipe">
            <div
                :id="'photoSwipeBox' + module.id"
                ref="photoSwipeBox"
                class="photoSwipeBox"
                :class="photoSwipeReset"
                :style="photoSwipeBoxStyle"
                @touchstart="touchstart"
                @touchmove="touchmove"
                @touchend="touchend"
            >
                <new-carousel-image-item
                    v-for="(item, index) in cycleList"
                    :key="item.picId"
                    :item="item"
                    :item-index="index"
                    :style="itemStyle"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
                <new-carousel-image-item
                    :item="cycleList[0]"
                    :style="itemStyle"
                    :item-index="0"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
                <new-carousel-image-item
                    v-if="cycleList.length > 1"
                    :item="cycleList[1]"
                    :item-index="-1"
                    :style="itemStyle"
                    :outer-box="content.td == 0"
                    :text-pos="content.itp"
                    :no-lazy-load="noLazyLoad"
                ></new-carousel-image-item>
            </div>
        </div>
    </div>
</template>

<script>
import NewCarouselImageItem from '../components/NewCarouselImageItem.vue';
export default {
    name: 'CarouselType9',
    components: {
        NewCarouselImageItem,
    },
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            oldIndex: 0,
            currentIndex: 0,
            swipeIndex: 0,
            startPos: {},
            endPos: {},
            isScrolling: 0,
            reset: false,
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        itemStyle() {
            var style = {};
            style['width'] = 100 / (this.cycleList.length + 2) + '%';
            return style;
        },
        photoSwipeBoxStyle() {
            var style = {
                'transition-duration': this.mhighs.carouselSet.switchTime + 'ms',
            };
            var widthPerct = 85;
            if (this.mhighs.imgWidthSet.t == 1) {
                widthPerct = this.mhighs.imgWidthSet.wp;
            }
            style['display'] = 'flex';
            style['width'] = (this.cycleList.length + 2) * widthPerct + '%';
            style['transform'] = 'translateX(' + (-this.swipeIndex * 100) / (this.cycleList.length + 2) + '%)';
            return style;
        },
        photoSwipeCls() {
            var cls = '';
            if (this.content.td == 1) {
                cls = 'innerBox';
            } else {
                cls += 'outerBox outerBox' + (parseInt(this.content.itp) + 1);
                cls += ' imgTextType' + (parseInt(this.content.its) + 1);
            }
            return cls;
        },
        photoSwipeReset() {
            return this.reset ? 'photoSwipeReset' : '';
        },
    },
    mounted() {
        if (this.mhighs.carouselSet.carouselType != 2 && this.cycleList.length > 2) {
            this.setAutoplay();
        }
    },
    methods: {
        setAutoplay() {
            var _this = this;
            window.clearInterval(this.timer);
            if (this.mhighs.carouselSet.carouselType != 2 && this.cycleList.length > 2) {
                var aniTime = this.carouselType == 0 ? 4000 : this.mhighs.carouselSet.aniTime;
                var switchTime = this.carouselType == 0 ? 500 : this.mhighs.carouselSet.switchTime;
                var delay = aniTime + switchTime;
                this.timer = window.setInterval(function () {
                    _this.next();
                }, delay);
            }
        },
        cleanAutoPlay() {
            window.clearInterval(this.timer);
        },
        touchstart(event) {
            var touch = event.targetTouches[0]; //touches数组对象获得屏幕上所有的touch，取第一个touch
            this.isScrolling = 0; //这个参数判断是垂直滚动还是水平滚动
            this.startPos = {
                x: touch.pageX,
                y: touch.pageY,
                time: +new Date(),
            }; //取第一个touch的坐标值
            this.touchingEnd = false;
            this.cleanAutoPlay();
        },
        touchmove(event) {
            //当屏幕有多个touch或者页面被缩放过，就不执行move操作
            if (!event) {
                return;
            }
            if (event.targetTouches.length > 1 || (event.scale && event.scale !== 1)) {
                return;
            }
            var touch = event.targetTouches[0];
            this.endPos = {
                x: touch.pageX - this.startPos.x,
                y: touch.pageY - this.startPos.y,
                time: +new Date(),
            };
            this.isScrolling = Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0;
            if (this.isScrolling === 0) {
                event.preventDefault();
            }
        },
        touchend() {
            if (this.isScrolling === 0) {
                if (Number(this.endPos.x) > 10 && Number(this.endPos.y) < 40) {
                    this.prev();
                } else if (Number(this.endPos.x) < -10 && Number(this.endPos.y) < 40) {
                    this.next();
                }
                this.endPos.x = 0;
                this.endPos.y = 0;
            }
            this.setAutoplay();
            this.touchingEnd = true;
        },
        next() {
            var tmpIndex = this.currentIndex + 1;
            var _this = this;
            if (tmpIndex == this.cycleList.length) {
                tmpIndex = 0;
            }
            if (this.swipeIndex == this.cycleList.length) {
                this.swipeIndex = 0;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, false);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, false);
            }
        },
        prev() {
            var tmpIndex = this.currentIndex - 1;
            var _this = this;
            if (tmpIndex < 0) {
                tmpIndex = this.cycleList.length - 1;
            }
            if (this.swipeIndex == 0) {
                this.swipeIndex = this.cycleList.length;
                this.reset = true;
                this.$nextTick(() => {
                    setTimeout(function () {
                        _this.reset = false;
                        _this.switchPhoto(tmpIndex, true);
                    }, 50);
                });
            } else {
                this.switchPhoto(tmpIndex, true);
            }
        },
        switchPhoto(currentIndex, prev) {
            if (currentIndex == 0 && !prev) {
                this.swipeIndex = this.cycleList.length;
            } else {
                this.swipeIndex = currentIndex;
            }
            this.currentIndex = currentIndex;
        },
    },
};
</script>

<style lang="scss">
.formStyle42 .styleForm9 {
    width: 100%;
}
.formStyle42 .styleForm9 .photoSwipe {
    text-align: center;
    position: relative;
    display: block;
    font-size: 0;
    margin-left: 2.0625%;
    overflow: hidden;
    padding-left: 2%;
}
.formStyle42 .styleForm9 .photoSwipeBox {
    position: relative;
    transition: all;
    transition-timing-function: ease;
    width: 100%;
    transition-property: -webkit-transform;
    transition-duration: 0ms;
    display: flex;
    margin: 0.5rem 0;
    transform-style: preserve-3d;
    perspective: unset;
}
.formStyle42 .newCarouselMultiPhoto.styleForm9 .photoSwipeBox .photoImageItem {
    margin: 0;
    padding-right: 0.5rem;
    border-radius: 0.2rem;
}
.formStyle42 .styleForm9 .photoImageItem.outerBox {
    margin-right: 0.5rem;
    border-radius: 0.2rem;
}
.formStyle42 .autoSwipeBox,
.formStyle42 .photoSwipeBox {
    font-size: 0;
    text-align: left;
}
.formStyle42 .innerBox.styleForm9 .photoImageItem .photoImageItemContent {
    border-radius: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.35rem 0.35rem -0.2rem;
    /*height: 99%;*/
}
</style>
