var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("module-frame", { attrs: { "module-id": _vm.moduleId } }, [
    _vm.render
      ? _c(
          "div",
          {
            staticClass: "multi_photo_list",
            class: _vm.moduleCls,
            attrs: { id: _vm.moduleIdAttr },
          },
          [
            !_vm.picDataList.length && _vm.manageMode
              ? [
                  _c(
                    "div",
                    { staticClass: "empty_tips_panel" },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v("请在右侧面板中编辑图片"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button-component",
                        {
                          staticClass: "addPhoto",
                          attrs: { active: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.addMultiPhotoList.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("添加图片")]
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.picDataList.length && !_vm.manageMode
              ? [
                  _c("div", { staticClass: "cus_Empty_tips_panel" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("没有添加图片"),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.picDataList.length
              ? [
                  _c(_vm.componentMap[_vm.type], {
                    tag: "component",
                    attrs: {
                      "content-list": _vm.picDataList,
                      "photo-swipe-list": _vm.photoSwipeList,
                      "no-lazy-load": _vm.noLazyLoad,
                    },
                  }),
                  _vm._v(" "),
                  _vm.showMobiViewer
                    ? _c("image-viewer", {
                        attrs: {
                          "z-index": _vm.zIndex,
                          "initial-index": _vm.initialIndex,
                          "on-close": _vm.onClose,
                          "url-list": _vm.imageViewerList,
                          "is-mobi": true,
                          "content-terminal": "mobi",
                          "manage-mode": _vm.manageMode,
                          "log-event-config": _vm.logEventConfig,
                          "document-event-el": _vm.documentEventEl,
                          "append-to-container-id": "g_body",
                          "append-to-body": false,
                          "disable-long-press": _vm.banCopyTextImg,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showPageComponent
                    ? _c("pagenation", {
                        attrs: {
                          "module-id": _vm.moduleId,
                          "total-size": _vm.totalSize,
                          "page-size": _vm.module.content.count,
                          pageno: _vm.pageno,
                          "open-theme-v3": _vm.openThemeV3,
                          "ajax-pagenation": true,
                        },
                        on: { "on-change": _vm.changePage },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }