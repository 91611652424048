import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PhotoGroupNavView from './module/photo-group-nav.vue';

class _PhotoGroupNav extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.PHOTO_GROUP_NAV, options);
    }

    render() {
        return PhotoGroupNavView;
    }
}

export const PhotoGroupNav = new _PhotoGroupNav();
