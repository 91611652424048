<template>
    <div class="f-fromTabLoading">
        <div class="f-new-spinner f-spinner">
            <div class="loading-circle-item"></div>
            <div class="loading-circle-item"></div>
            <div class="loading-circle-item"></div>
            <div class="loading-circle-item"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModuleLoading',
    data() {
        return {};
    },
    mounted() {},
};
</script>

<style lang="scss">
.f-fromTabLoading {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
}
.f-fromTabLoading .f-new-spinner.f-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: -20px 0 0 -20px;
}
.f-new-spinner .loading-circle-item {
    width: 10px;
    height: 10px;
    background-color: #c2c2c2;
    border-radius: 100%;
    position: absolute;
}

.loading-text {
    font-size: 16px;
}

.fk-loading-ball-container .loading-text {
    text-align: center;
    width: 240px;
    left: 50%;
    top: 50%;
    transform: translate(-120px, 33px);
    color: #fff;
    position: absolute;
}

.f-new-spinner .loading-circle-item:nth-child(1) {
    left: 0;
    animation: fk-bouncedelay1 2s infinite 0.5s cubic-bezier(0.11, 0.93, 0.2, 1.01);
}

.f-new-spinner .loading-circle-item:nth-child(2) {
    opacity: 0.9;
    animation: fk-bouncedelay2 2s infinite 0.5s cubic-bezier(0.11, 0.93, 0.2, 1.01);
    right: 0;
}

.f-new-spinner .loading-circle-item:nth-child(3) {
    animation: fk-bouncedelay3 2s infinite 0.5s cubic-bezier(0.11, 0.93, 0.2, 1.01);
    left: 0;
    bottom: 0;
    opacity: 0.7;
}

.f-new-spinner .loading-circle-item:nth-child(4) {
    animation: fk-bouncedelay4 2s infinite 0.5s cubic-bezier(0.11, 0.93, 0.2, 1.01);
    right: 0;
    bottom: 0;
    opacity: 0.5;
}

.f-new-spinner {
    animation: fk-loadingdelay 2s infinite ease-in-out;
}

@keyframes fk-loadingdelay {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1080deg);
    }
}

@keyframes fk-bouncedelay1 {
    0%,
    100% {
        transform: translate3d(0);
        top: 0;
        left: 0;
    }

    20% {
        transform: translate3d(0);
        top: 50%;
        left: 50%;
    }
}

@keyframes fk-bouncedelay2 {
    0%,
    100% {
        transform: translate3d(0);
        top: 0;
        right: 0;
    }

    20% {
        transform: translate3d(0);
        top: 50%;
        right: 50%;
    }
}

@keyframes fk-bouncedelay3 {
    0%,
    100% {
        transform: translate3d(0);
        bottom: 0;
        left: 0;
    }

    20% {
        transform: translate3d(0);
        bottom: 50%;
        left: 50%;
    }
}

@keyframes fk-bouncedelay4 {
    0%,
    100% {
        transform: translate3d(0);
        bottom: 0;
        right: 0;
    }

    20% {
        transform: translate3d(0);
        bottom: 50%;
        right: 50%;
    }
}
</style>
