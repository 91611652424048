import { createUrlArgsOnclickStr } from '@/modules/comm';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';

import { encodeHtml } from '@/shared/util';
import { decodeHtml } from '@/shared/util';
import { mapGetters } from 'vuex';
export const photoMixins = {
    data() {
        return {
            htmlFontSize: NEW_MANAGE_HTML_FONTSIZE,
            cartContainerWidth: 335,
        };
    },
    props: {
        moduleContent: {
            type: Object,
            default: function () {
                return {};
            },
        },
        photoItem: {
            type: Object,
            default: function () {
                return {};
            },
        },
        linkType: {
            type: Number,
            default: 0,
        },
        imgHref: {
            type: String,
            default: 'javascript:;',
        },
        imgIndex: {
            type: Number,
        },
        slidesIndex: {
            type: Number,
        },
        moduleId: {
            type: Number,
        },
        isEmpty: {
            type: Boolean,
            default: false,
        },
        loadingPath: {
            type: String,
            default: '',
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        linkTypeStyle() {
            return this.linkType == 0 ? 'cursor:default;' : '';
        },
        linkFocus() {
            return this.linkType == 0 ? '' : 'true';
        },
        moduleStyle() {
            return this.moduleContent.type || 1;
        },
        columnsType() {
            return this.moduleContent.icolumns || 0;
        },
        textStyleType() {
            return this.moduleContent.istyle || 0;
        },
        picScaleType() {
            return this.moduleContent.picScale || 0;
        },
        picSize() {
            return this.moduleContent.mhighs.isize;
        },
        picSpace() {
            return this.moduleContent.mhighs.ispace;
        },
        photoRadius() {
            return this.moduleContent.mhighs.iradius;
        },
        titleLink() {
            return this.moduleContent.mhighs.tmain;
        },
        nameLink() {
            return this.moduleContent.mhighs.idf;
        },
        textOriginColor() {
            return this.moduleContent.mhighs.ocolor;
        },
        showTitle() {
            return this.moduleContent.showTitle;
        },
        showDesc() {
            return this.moduleContent.showName;
        },
        titleIsWrap() {
            return this.moduleContent.mhighs.wname;
        },
        descIsWrap() {
            return this.moduleContent.mhighs.wdesc;
        },
        textAlign() {
            return this.moduleContent.talign;
        },
        waterFallHeightType() {
            return this.moduleContent.wlength;
        },
        picLinkStyle() {
            let style = {};
            if (this.textStyleType != 3) {
                if (this.textStyleType != 2) {
                    style.background = '#fff';
                }
                if (this.textOriginColor.type == 1) {
                    style.background = this.textOriginColor.color;
                }
            }
            return style;
        },
        textAlignStyle() {
            let align = 'left';
            switch (this.textAlign) {
                case 0:
                    align = 'left';
                    break;
                case 1:
                    align = 'center';
                    break;
                case 2:
                    align = 'right';
                    break;
            }
            return align;
        },
        titleData() {
            return this.photoItem.imgTitle;
        },
        descData() {
            return this.encodeHtml(this.photoItem.imgName);
        },
        isNewModule() {
            return this.moduleContent.inm == 1;
        },
        encodeTitleData() {
            return this.encodeHtml(this.photoItem.imgTitle);
        },
        encodeDescData() {
            return this.encodeHtml(this.photoItem.imgName);
        },
        _resizeOptions() {
            var handles = 's';
            var notAllow = false;
            var fixPosition = true;
            var alsoResizeParentSelector = null;
            var alsoResizeChildSelector = null;
            var maxWidth = null;

            if (this.moduleStyle == 5) {
                handles = this.moduleContent.iposition == 0 ? 's,e' : 's,w';
                fixPosition = this.moduleContent.iposition == 0;
                alsoResizeParentSelector = '.J_img_warp';
                maxWidth = 200;
            }

            if (this.moduleStyle == 6) {
                alsoResizeChildSelector = '.J_img_lazyload';
            } else if (this.moduleStyle == 3) {
                handles = 's,e';
                maxWidth = 336;
            }

            return {
                notAllow: notAllow,
                handles: handles,
                alsoResizeParentSelector: alsoResizeParentSelector,
                alsoResizeChildSelector: alsoResizeChildSelector,
                fixPosition: fixPosition,
                outlineInBody: true,
                stop: this._resizeStop,
                resize: this._resizeing,
                maxWidth: maxWidth,
            };
        },
        jumpOnclickStr() {
            return createUrlArgsOnclickStr(
                this.photoItem.reqArgs || '',
                this.module ? this.module._openRemoveUrlArgs : false,
                this.hashRemoved
            );
        },
    },
    methods: {
        photoSlidesshow(e) {
            if (VITE_APP_MODE !== 'visitor') {
                if (Vue.prototype.$designer.checkSaveBar()) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }
            const { jumpUrl = '' } = this.photoItem;
            if (this.linkType === 2 && !(jumpUrl === '' || jumpUrl === 'javascript:void(0)')) {
                return;
            }
            if (this.linkType === 0) {
                return;
            }
            this.$modulesEventBus.$emit(
                `photo-slidesshow${this.moduleId || this.$parent.module.id}`,
                this.slidesIndex,
                this.photoItem
            );
        },
        wrapStyle(wrapStyle) {
            let style = {};
            if (wrapStyle == 2) {
                // 不换行
                style['word-break'] = 'break-word';
            } else if (wrapStyle == 1) {
                // 自动换行
                style['text-overflow'] = 'ellipsis';
                style['overflow'] = 'hidden';
                style['white-space'] = 'nowrap';
            } else if (wrapStyle == 0) {
                // 默认样式，每个模块样式可能不一样，这里分开处理
                style['display'] = '-webkit-box';
                style['-webkit-box-orient'] = 'vertical';
                style['-webkit-line-clamp'] = 2;
                style['overflow'] = 'hidden';
                style['word-break'] = 'break-word';
            }
            return style;
        },
        textLinkStyle(textObj, unit) {
            let style = {};
            unit = unit || 'rem';

            if (textObj.type == 1) {
                style.fontSize = textObj.lfs == 0 ? '0.512rem' : textObj.lfs + unit;
                if (textObj.lfd == 1) style.textDecoration = 'underline';
                style.color = textObj.lfc;
                style.fontWeight = textObj.nlfb == 1 ? 'bold' : 'initial';
                style.fontStyle = textObj.nlfi == 1 ? 'italic' : 'initial';
            }
            return style;
        },
        decodeHtml,
        encodeHtml,
        _resizeStop(event, ui) {
            let { width, height } = { ...ui.size };
            let transWidth = parseInt(width);
            let transHeight = parseInt(height);

            if (transWidth % 2 != 0) {
                transWidth -= 1;
            }
            if (transHeight % 2 != 0) {
                transHeight -= 1;
            }

            const gcd = this.__getGreatestCommonDivisor(transWidth, transHeight);
            let rateWidth = transWidth / gcd;
            let rateHeight = transHeight / gcd;

            if (this.moduleStyle == 5) {
                this.moduleContent.mhighs.lrss.width = width;
                this.moduleContent.mhighs.lrss.height = height;
            } else if (this.moduleStyle == 4) {
                if (this.isHeadOrTail) {
                    this.moduleContent.mhighs.htprop.w = rateWidth;
                    this.moduleContent.mhighs.htprop.h = rateHeight;
                } else {
                    this.moduleContent.mhighs.nprop.w = rateWidth;
                    this.moduleContent.mhighs.nprop.h = rateHeight;
                }
            } else {
                this.picSize.height = height;
                this.picSize.rh = Number((height / this.htmlFontSize).toFixed(5));
            }

            if (this.moduleStyle == 3) {
                if (ui.originalSize.width != width) {
                    const wrap = ui.originalElement.closest('.J_cardContainer')[0];
                    if (wrap) {
                        this.moduleContent.mhighs.isize.wtype = 1;
                        this.moduleContent.mhighs.isize.width = parseInt(
                            (wrap.offsetWidth / this.cartContainerWidth) * 100
                        );
                    }
                }
            }

            if (this.moduleStyle == 5) {
                this.moduleContent.mhighs.lrss.type = 1;
            } else if (this.moduleStyle == 4) {
                if (this.isHeadOrTail) {
                    this.moduleContent.mhighs.htprop.type = 1;
                } else {
                    this.moduleContent.mhighs.nprop.type = 1;
                }
            } else {
                this.picSize.htype = 1;
            }

            this.$designer.onContentChange(this.moduleId || this.$parent.module.id);
            Mobi.logDog(201218, 3);
        },
        _resizeing(event, ui) {
            if (this.moduleStyle == 3) {
                let height = 0;
                const $imgContainer = jm(event.target).parent();
                const $imgContainerWrap = $imgContainer.closest('.J_imgContainerWrap');
                const originHeight = $imgContainerWrap.offset().height;
                $imgContainer.children().forEach((item) => {
                    height += jm(item).offset().height;
                });

                if (height > originHeight) {
                    $imgContainer.closest('.J_imgContainerWrap ').css('height', height + 'px');
                    $imgContainerWrap.closest('.J_cardContainer').height(parseInt(height) + 20 + 'px');
                }

                if (ui.originalSize.width != ui.size.width) {
                    ui.originalElement.closest('.J_cardContainer').css('width', ui.size.width + 'px');
                }
            } else if (this.moduleStyle == 5) {
                if (ui.size.width != ui.originalSize.width) {
                    const style = this.moduleContent.iposition == 0 ? 'padding-left' : 'padding-right';
                    ui.originalElement.find('~ .horizion_text').css(style, ui.size.width + 'px');
                }
            }
        },
        __getGreatestCommonDivisor(a, b) {
            // 取最大公约数
            var result = 1;
            for (var i = 1; i <= a && i <= b; i++) {
                if (a % i == 0 && b % i == 0) {
                    result = i;
                }
            }
            return result;
        },
    },
    mounted() {
        this.htmlFontSize = Fai.top._htmlFontSize;
    },
};
