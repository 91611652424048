import { logDog, logDogAfterSave } from '@/shared/log';
import { mapFlag } from '@/shared/flag';
import FormPopup from '@/system/formPopup/module/formPopup';
import { logJZFdp } from '@/api/logDog';
import { getSiteMultiLanguageUrl } from '@/shared/url';
import popupWindow from '@/shared/popupWindow';
import { deepExtendClone } from '@jz/utils';
let FormPanel = {};
let _panelCash = {
    showTypeList: false,
    pos: null,
};
let PanelComponent = {
    el: '#jz_form_edit_panel_app',
    data() {
        let currentPageModuleIdMap = window.$store.state.currentPageModuleIdMap;
        let designer = window.$store.state.manage.designer;
        let { moduleId, styleId } = designer;
        let module = null;
        if (styleId === 84) {
            module = FormPopup.module;
        } else {
            // 关闭面板时，moduleId变成0会触发这里的逻辑，导致拿不到模块的数据
            module = currentPageModuleIdMap[moduleId];
        }
        return {
            showTypeList: _panelCash.showTypeList,
            formNameCash: '',
            lastFormModuleId: 0,
            formList: Fai.top._formList,
            tableRender: true,
            columns: [
                {
                    title: '项名称',
                    width: 76,
                    align: 'center',
                    type: 'render',
                    render(createElem, params) {
                        return createElem(TableItemNameComp, {
                            props: { row: params.row, index: params.index },
                        });
                    },
                },
                {
                    title: '必填',
                    width: 37,
                    align: 'center',
                    type: 'render',
                    render(createElem, params) {
                        return createElem(TableItemMustComp, {
                            props: { row: params.row, index: params.index },
                        });
                    },
                },
                {
                    title: '排序',
                    width: 37,
                    align: 'center',
                    type: 'sortable',
                },
                {
                    title: '操作',
                    width: 37,
                    align: 'center',
                    type: 'render',
                    render(createElem, params) {
                        return createElem(TableItemOperateComp, {
                            props: { row: params.row, index: params.index },
                        });
                    },
                },
            ],
            formItemTypeList: [
                { type: 0, text: '单行文本', keyword: 'singleLine' },
                { type: 2, text: '单选按钮', keyword: 'radio' },
                { type: 3, text: '多选按钮', keyword: 'multipleChoice' },
                { type: 1, text: '多行文本', keyword: 'multiLine' },
                { type: 8, text: '手机号码', keyword: 'phoneNumber' },
                { type: 7, text: '文件上传', keyword: 'fileUpload' },
                { type: 4, text: '下拉选项', keyword: 'dragDownOption' },
                { type: 13, text: '数字', keyword: 'number' },
                { type: 14, text: '图片单选', keyword: 'singlePhoto' },
                { type: 15, text: '图片多选', keyword: 'multiplePhoto' },
                { type: 12, text: '多级下拉', keyword: 'multiDropdown' },
                { type: 6, text: '日期时间', keyword: 'dateSetting' },
                { type: 11, text: '省市县区', keyword: 'city' },

                {
                    type: 10,
                    text: '身份验证',
                    keyword: 'identityVerification',
                },
                { type: 9, text: '邮箱验证', keyword: 'mailVerification' },
                { type: 5, text: '文本说明', keyword: 'textDescription' },
            ],
            isMall: false,
            initFinish: false,
            module,
            designer,
        };
    },
    template:
        '<div id="jz_form_edit_panel" class="jz_form_edit_panel" style="position: absolute;" v-if="formInfo">' +
        '<div id="jz_form_edit_panel_header" class="p_header">编辑表单</div>' +
        '<div class="p_content">' +
        '<div class="line">' +
        '<span class="title">表单名称：</span>' +
        '<input-component v-model="formInfo.name" ref="formTitle" @on-focus="cashName" @on-blur="checkName" v-undo.inputText maxlength="100" pattern="width: 125px;"></input-component>' +
        '</div>' +
        '<div class="form_items_wrap" id="form_edit_panel_table">' +
        '<div style="margin-bottom:9px;">表单项内容：</div>' +
        '<table-component' +
        ' v-if=tableRender' +
        ' ref="formItemsTable"' +
        ' :width="188"' +
        ' :columns="columns"' +
        ' :data="formItemsFilter"' +
        ' :maxBodyHeight="150"' +
        ' class="form_items_table"' +
        ' no-data-text="暂无表单项"' +
        ' @on-sort-up-click="handlePreSort" @on-sort-down-click="handleNextSort"' +
        ' @on-item-must-change="handleItemMustChange"' +
        ' @on-item-delete="handleItemDelete"' +
        ' sortDisableAlias="Up"' +
        '></table-component>' +
        '</div>' +
        '<transition v-if="initFinish" name="form_item_type" mode="out-in">' +
        '<div class="form_item_type_list_wrap" v-show="showTypeList">' +
        '<div v-for="item in formItemTypeList" :class="item.keyword" @click="addNewItem(item)" :key="item.keyword" class="u_item">{{item.text}}</div>' +
        '</div>' +
        '</transition>' +
        '<div v-else class="form_item_type_list_wrap" v-show="showTypeList">' +
        '<div v-for="item in formItemTypeList" :class="item.keyword" @click="addNewItem(item)" :key="item.keyword" class="u_item">{{item.text}}</div>' +
        '</div>' +
        '<div class="add_new_item_entry" :class="addIconClass" @click="handlerEntryClick"> {{ entryText }} </div>' +
        '<div class="edit_form_fun_setting" @click="handlerRuleSetClick">规则设置</div>' +
        '<div  class="edit_form_fun_setting" @click="handlerFunSetClick">表单功能设置</div>' +
        '</div>' +
        '</div>',
    computed: {
        formInfo() {
            var formId = this.module.content.f_id;
            return this.formList.find(function (form) {
                return form.id === formId;
            });
        },
        formItems() {
            return this.formInfo.contentList;
        },
        mailNotify4Client() {
            return this.formInfo?.mailNotify4Client || {};
        },
        payType() {
            return this.formInfo.other.payType;
        },
        formItemsFilter() {
            var _this = this;
            return this.formItems.filter(function (item) {
                let showTips = _this.variableIdList.indexOf(item.id) != -1 && _this.payType == 1;
                item.showTips = showTips;
                item.isLinkToMailNotify =
                    item.id == _this.mailNotify4Client.notifyFormItemId && _this.mailNotify4Client.open;
                return !item.hide;
            });
        },
        entryText() {
            return this.showTypeList ? '收起' : '添加表单项';
        },
        ...mapFlag(
            {
                validation: 0x2,
                notRepeat: 0x4,
            },
            'formInfo.flag'
        ),

        addIconClass() {
            return this.showTypeList ? 'slide_up' : 'slide_down';
        },
        variableIdList() {
            let tmpVariableList = JSON.parse(JSON.stringify(this.formInfo.other.formulaData.variableList));
            let idList = [];
            tmpVariableList.forEach((val) => {
                idList.push(val.id);
            });

            return idList;
        },
    },
    watch: {
        formInfo: {
            deep: true,
            handler() {
                Fai.top._mobiFormChange = true;
                this.formInfo.change = true;
                this.$designer.styleChanged();
                if (this.designer.styleId === 84 && Fai.top.saving) return;
                this.updateModule();
            },
        },
    },
    methods: {
        repeatTypeChange(val) {
            if (!val) {
                logDogAfterSave('nr', 200914, 31);
            } else {
                logDogAfterSave('nr', 200914, 32);
            }
        },
        validationTypeChange(val) {
            if (val) {
                logDogAfterSave('vtc', 200914, 33);
            } else {
                logDogAfterSave('vtc', 200914, 34);
            }
        },
        handlePreSort(index) {
            // tanble绑定的是formItemsFilter，但是我们实际要操作的是formItemList
            var formItemList = this.formItems;
            var tmp = this.formItemsFilter[index];
            var startIdx;
            for (var i = 0; i < formItemList.length; i++) {
                if (formItemList[i].id === tmp.id) {
                    startIdx = i;
                }
            }

            if (!startIdx) return;

            var startsItem = formItemList[startIdx];

            // 向上
            for (var endInx = startIdx - 1; endInx > 0; endInx--) {
                if (!formItemList[endInx].hide) {
                    break;
                }
            }

            formItemList[startIdx] = formItemList[endInx];
            formItemList[endInx] = startsItem;
            formItemList.splice(formItemList.length);
            this.updateTable();
            logDog(200914, 37);
        },
        handleNextSort(index) {
            var formItemList = this.formItems;
            var tmp = this.formItemsFilter[index];
            var startIdx;
            for (var i = 0; i < formItemList.length; i++) {
                if (formItemList[i].id === tmp.id) {
                    startIdx = i;
                }
            }

            if (!startIdx && startIdx != 0) return;

            var startsItem = formItemList[startIdx];

            // 向下
            for (var endInx = startIdx + 1; endInx < formItemList.length; endInx++) {
                if (!formItemList[endInx].hide) {
                    break;
                }
            }

            formItemList[startIdx] = formItemList[endInx];
            formItemList[endInx] = startsItem;
            formItemList.splice(formItemList.length);
            this.updateTable();
            logDog(200914, 37);
        },
        addNewItem(item) {
            if (this.formInfo.contentList.length >= 100) {
                Fai.ing('最多只能添加100条表单项。', true);
                return;
            }

            var newFormItemInfo = getFormItemDefData(item, this.formInfo.contentList, this.module);
            this.formInfo.contentList.push(newFormItemInfo);

            var $module = $(`#module${this.module.id}`);
            this.$nextTick(function () {});

            // 定位到新项底部
            setTimeout(function () {
                var documentDom = document.documentElement || document.body;
                var scrollTop = $module.offset().top + $module.height() - documentDom.clientHeight;
                documentDom.scrollTop = Math.max(scrollTop, 0);
            }, 200);

            // 打开表单项编辑面板
            new Promise((resolve) => {
                setTimeout(() => resolve(), 200);
            })
                .then(() => {
                    var $el = $(this.$el);
                    _panelCash.showTypeList = this.showTypeList;
                    _panelCash.pos = {
                        top: $el.css('top'),
                        left: $el.css('left'),
                    };
                    $(`#module${this.module.id} .J_item_wrap:last`).trigger('click');
                })
                .then(() => {
                    return new Promise((resolve) => {
                        setTimeout(() => resolve(), 200);
                    });
                })
                .then(() => {
                    _panelCash.showTypeList = false;
                    _panelCash.pos = null;
                });
            logDog(200914, 41);
            this.logFormAddByJz(newFormItemInfo?.type);
            this.updateTable();
        },
        logFormAddByJz(type = 0) {
            logJZFdp('jz_form_item_edit', {
                jz_version: Fai.top.jzVersion,
                jz_content_terminal: 'mobi',
                jz_free_text_0: '新增',
                jz_free_text1: [
                    '单行文本',
                    '多行文本',
                    '单选按钮',
                    '多选按钮',
                    '下拉选项',
                    '文本说明',
                    '日期选项',
                    '文件上传',
                    '手机号',
                    '邮箱验证',
                    '身份验证',
                    '省市县区',
                    '多级下拉',
                    '数字',
                    '图片单选',
                    '图片多选',
                ][type],
            });
        },
        logFormDeleteByJz(type, hide) {
            logJZFdp('jz_form_item_delete', {
                jz_version: Fai.top.jzVersion,
                jz_content_terminal: 'mobi',
                jz_free_text_0: hide ? '隐藏' : '删除',
                jz_free_text1: [
                    '单行文本',
                    '多行文本',
                    '单选按钮',
                    '多选按钮',
                    '下拉选项',
                    '文本说明',
                    '日期选项',
                    '文件上传',
                    '手机号',
                    '邮箱验证',
                    '身份验证',
                    '省市县区',
                    '多级下拉',
                    '数字',
                    '图片单选',
                    '图片多选',
                ][type],
            });
        },
        handlerEntryClick() {
            this.showTypeList = !this.showTypeList;
            if (this.showTypeList) {
                logDog(200914, 27);
            } else {
                logDog(200914, 28);
            }
        },
        handlerRuleSetClick() {
            const ruleList = $.extend(true, [], this.formInfo.other.rules);
            const contentList = $.extend(true, [], this.formInfo.contentList);
            logDog(201023, 33);
            logDog(200914, 46);
            var _this = this;
            popupWindow({
                title: '表单项显示规则',
                frameSrcUrl: getSiteMultiLanguageUrl() + '/manage_v2/siteFormRule.jsp?ram=' + Math.random(),
                width: 600,
                height: 650,
                version: 2,
                saveBeforePopup: false,
                popUpWinZIndex: 9034,
                callArgs: { ruleList, contentList },
                closeFunc: (dataStr) => {
                    if (!dataStr) return;
                    const { ruleList } = JSON.parse(dataStr);
                    _this.formInfo.other.rules = ruleList;
                },
            });
        },
        handlerFunSetClick() {
            logDog(200914, 43);
            window.$store.commit('manage/updateFormFunEdit', {
                formId: this.module.content.f_id,
                moduleId: this.designer.moduleId,
            });

            this.$designer.open({
                panelType: 'JzFormEditFun',
                styleId: this.designer.styleId,
                moduleId: this.designer.moduleId,
                isFormPanel: true,
            });
        },
        checkName(event) {
            logDog(200914, 17);
            logDogAfterSave('cn', 200914, 29);
            if (!event.target.value) {
                Fai.ing('表单标题不能为空。', true);
                this.formInfo.name = this.formNameCash;
            }
        },
        cashName(event) {
            this.formNameCash = event.target.value;
        },
        findFormItem(id) {
            return this.formInfo.contentList.find(function (item) {
                return item.id === id;
            });
        },
        handleItemMustChange(formItem) {
            var item = this.findFormItem(formItem.id);
            item.must = formItem.must;
        },
        handleItemDelete(formItem) {
            const curFormItem = this.formInfo.contentList.find((item) => item.id == formItem.id);
            const hide = curFormItem.hide;
            const deleteItem = () => {
                var idx = this.formInfo.contentList.findIndex((item) => item.id == formItem.id);

                if (idx != -1) {
                    this.formInfo.contentList.splice(idx, 1);
                    this.updateTable();
                    logDog(200914, 38);
                }
                this.logFormDeleteByJz(curFormItem.type, false);
            };
            this.$createDialog({
                content: `<p style="color:#333;font-size:16px;">确定删除"${
                    curFormItem.name
                }"？</p><div style="color:#666;font-size:14px;">将同时删除该表单项的所有数据，无法恢复。${
                    hide ? '' : '</br>隐藏表单项可保留数据，提交时不需填写。'
                }</div>`,
                confirmButton: {
                    text: hide ? '确定' : '隐 藏',
                },
                cancelButton: {
                    text: hide ? '取消' : '仍要删除',
                },
                onConfirm: () => {
                    if (hide) {
                        deleteItem();
                    } else {
                        this.logFormDeleteByJz(curFormItem.type, true);
                        curFormItem.hide = true;
                    }
                },
                onCancel: () => {
                    if (!hide) {
                        deleteItem();
                    }
                },
            });
        },
        updateModule() {
            // 处理数据没有响应的情况。
            // contentList改变了，mobiForm.vue监听不到，这里用content.fi做跳板\,assign 复制一份是为了不改变原有的地址
            this.module.content.fi = typeof this.module.content.fi == 'undefined' ? {} : this.module.content.fi;
            Object.assign(this.module.content.fi, JSON.parse(JSON.stringify(this.formInfo)));
        },
        updateTable() {
            return;
        },
    },
    mounted() {
        var needInit = this.formItemsFilter < 1;
        if (needInit) {
            this.showTypeList = true;
            var _this = this;
            this.$nextTick(() => {
                _this.initFinish = true;
                if (!Fai.top._haveRunFormGuide) {
                    Fai.top._formModuleGuide?.render();
                }
            });
        } else {
            this.initFinish = true;
        }
        Fai.top.saving = false;
        var $el = $('#jz_form_edit_panel');
        $el.draggable({
            handle: '#jz_form_edit_panel',
            stop() {
                logDog(200914, 39);
            },
        });
        if (_panelCash.pos) {
            $el.css({ top: _panelCash.pos.top, left: _panelCash.pos.left });
        }
        $(document)
            .off('beforeunload.formPanel')
            .on('beforeunload.formPanel', function () {
                FormPanel.destroy();
            });

        if (!Fai.top._haveRunFormGuide && !needInit) {
            Fai.top._formModuleGuide?.render();
        }
    },
};

// 点击表单项名称
const TableItemNameComp = {
    name: 'TableItemNameComp',
    props: ['row', 'index'],
    data() {
        return {};
    },
    template: `
            <div class="cubeTableText" @click.stop="handleClick">
                <span>{{row.name}}</span>
            </div>
		`,
    methods: {
        handleClick() {
            var editFormData =
                this.designer.styleId == 84
                    ? FormPopup.module
                    : window.$store.getters.currentModuleForId(this.designer.moduleId);
            var formId = editFormData.content.f_id;
            var styleId = this.designer.styleId;
            window.$store.commit('manage/updateCurFormItemEdit', {
                moduleId: this.designer.moduleId,
                itemId: this.row.id,
                formId,
                styleId,
            });
            let panelType = null;
            switch (this.row.type) {
                case 0:
                    panelType = 'JzFormEditInput';
                    break;
                case 1:
                    panelType = 'JzFormEditTextArea';
                    break;
                case 2:
                    panelType = 'JzFormEditRadio';
                    break;
                case 3:
                    panelType = 'JzFormEditCheckbox';
                    break;
                case 4:
                    panelType = 'JzFormEditSelect';
                    break;
                case 5:
                    panelType = 'JzFormEditTextDescription';
                    break;
                case 6:
                    panelType = 'JzFormEditDate';
                    break;
                case 7:
                    panelType = 'JzFormEditUpload';
                    break;
                case 8:
                    panelType = 'JzFormEditPhone';
                    break;
                case 9:
                    panelType = 'JzFormEditEmail';
                    break;
                case 10:
                    panelType = 'JzFormEditIdentify';
                    break;
                case 11:
                    panelType = 'JzFormEditCity';
                    break;
                case 12:
                    panelType = 'JzFormEditMultiDropdown';
                    break;
                case 13:
                    panelType = 'JzFormEditNumber';
                    break;
                case 14:
                    panelType = 'JzFormEditSinglePhoto';
                    break;
                case 15:
                    panelType = 'JzFormEditMultiplePhoto';
                    break;
            }
            if (panelType) {
                this.$designer.open({
                    panelType,
                    styleId: this.designer.styleId,
                    moduleId: this.designer.moduleId,
                    isFormPanel: true,
                });
            }
        },
    },
    computed: {
        designer() {
            return window.$store.state.manage.designer;
        },
    },
};
// 必填
var TableItemMustComp = {
    props: ['row', 'index'],
    data() {
        return {
            formItem: this.row,
        };
    },
    template: `<div v-if="!formItem.showTips" class="table-tab-icon form_edit_panel_table_icon" :class="classes" @click="handleClick">
				<i class="table_icon" :class="iconClass"></i>
			</div>
	        <tooltip-component v-else :close-delay="0" effect="light" :visible-arrow="true" placement="top">
				<div slot="content">当前表单项已用于支付金额计算，需为必填</div>
				<div class="table-tab-icon form_edit_panel_table_icon disabled" :class="classes">
					<i class="table_icon" :class="iconClass"></i>
				</div>
	 	    </tooltip-component>`,
    computed: {
        iconClass() {
            return this.formItem.must ? 'table_icon-on' : 'table_icon-off';
        },
        classes() {
            return 'is_type' + this.formItem.type;
        },
    },
    methods: {
        handleClick() {
            this.formItem.must = !this.formItem.must;
            vue_utils.dispatch(this, 'Table', 'on-item-must-change', this.formItem);

            if (this.formItem.must) {
                logDog(200914, 35);
            } else {
                logDog(200914, 36);
            }
        },
    },
};

// 操作
var TableItemOperateComp = {
    props: ['row', 'index'],
    data() {
        return {
            formItem: this.row,
        };
    },
    computed: {
        disableText() {
            let text = '当前表单项已用于支付金额计算，不支持隐藏';
            if (this.formItem.isLinkToMailNotify) {
                text = '当前表单项已用于邮箱通知，不支持隐藏';
            }
            return text;
        },
        showDisable() {
            return this.formItem.showTips || this.formItem.isLinkToMailNotify;
        },
    },
    template: `<div v-if="!showDisable" class="table-tab-icon form_edit_panel_table_delete" @click="handleClick">
				<i class="table_icon delete"></i>
			</div>
	        <tooltip-component v-else :close-delay="0" effect="light" :visible-arrow="true" placement="top">
				<div slot="content">{{disableText}}</div>
				<div class="table-tab-icon form_edit_panel_table_delete delete_disabled">
					<i class="table_icon delete"></i>
				</div>
	 	    </tooltip-component>`,
    methods: {
        handleClick() {
            //this.formInfo.contentList.splice(idx, 1);
            vue_utils.dispatch(this, 'Table', 'on-item-delete', this.formItem);
        },
    },
};

FormPanel.render = function (options = {}) {
    let { styleId = -1, moduleId = -1 } = options;
    const designer = window.$store.state.manage.designer;
    styleId = styleId == -1 ? designer.styleId : styleId;
    moduleId = moduleId == -1 ? designer.moduleId : moduleId;
    if (this.vm) {
        $('#jz_form_edit_panel').removeClass('shake');
        setTimeout(function () {
            $('#jz_form_edit_panel').addClass('shake');
        }, 200);
    }

    var formEmpty = false;
    if (styleId == 13 && window.$store.getters.currentModuleForId(moduleId).content.fi) {
        formEmpty = Object.keys(window.$store.getters.currentModuleForId(moduleId).content.fi).length == 0;
    }

    if ((styleId != 13 && styleId != 84) || this.vm || formEmpty) {
        return;
    }
    window.$store.commit('manage/updateDesignerStyleId', styleId);
    window.$store.commit('manage/updateDesignerModuleId', moduleId);
    $("<div id='jz_form_edit_panel_app'></div>").appendTo('body');
    this.vm = new Vue(PanelComponent);
};

FormPanel.destroy = function () {
    // 清理表单项正在编辑的状态
    jm('.item_editing').removeClass('item_editing');

    if (this.vm) {
        $(this.vm.$el).remove();
        this.vm && this.vm.$destroy();
        this.vm = null;
    }
};

FormPanel.destroyById = function (moduleId) {
    // 清理表单项正在编辑的状态
    jm('.item_editing').removeClass('item_editing');

    if (this.vm && this.vm.module.id === moduleId) {
        $(this.vm.$el).remove();
        this.vm && this.vm.$destroy();
        this.vm = null;
    }
};

function getListMaxId(list) {
    return list.reduce(function (maxId, item) {
        return maxId < item.id ? item.id : maxId;
    }, 0);
}

function getFormItemDefData(item, contentList, module) {
    var id = getListMaxId(contentList) + 1;
    var name = item.text;
    var type = item.type;

    //基本的默认数据(每一种类型都会有,跟随Site)
    var data = {
        id,
        name,
        hide: false,
        must: true,
        type,
        input: '',
        size: 1,
        isAllFile: true,
        dftl: '',
        cftl: '',
        placeholder: '',
        headline: '',
        openAreaCode: false,
        dateSetting: {},
        sys: false,
        openMunicipalCode: true,
        phoneNumberLength: '11',
        wordLimit: '{}',
        smsVerify: false, // 是否开启短信验证
        smsTemplateId: -1, // 短信模板 id
        smsImgVerify: true, // 是否开启图片验证码，默认为true
        unique: false,
        uniqueTips: '已存在该数据，请重新输入',
        nl: [],
        nd: false,
        ar: true,
        ol: false,
    };

    //需要添加描述文本的类型
    data.placeholder = {
        0: '请输入单行文本',
        1: '请输入多行文本',
        4: '请选择',
        12: '请选择',
        6: '请选择日期时间',
        7: '选择文件',
        8: '请输入手机号码',
        9: '请输入邮箱',
        10: '请输入身份证号码',
        11: '请选择',
    }[type];

    //每一种类型特别的默认值
    switch (type) {
        case 0:
            Object.assign(data, { wordLimit: '{"o": 0,"i": 1,"a": 100}' });
            break; //单行文本
        case 5:
            Object.assign(data, {
                headline: '文本说明',
                input: '描述内容',
                must: false,
            });
            break; //文本说明

        case 2: //单选按钮
        case 3: //多选按钮
            Object.assign(data, {
                must: false,
                input: '选项一\n选项二\n选项三',
            });
            break;
        case 4:
            Object.assign(data, { input: '选项一\n选项二\n选项三' });
            break; //下拉选项
        case 6: //日期选项
            Object.assign(data, {
                dateSetting: {
                    type: 0,
                    accuracy: 0,
                    ot: {
                        t: 0,
                        unSelectTime: [0, 1, 2, 3, 4, 5, 6, 22, 23],
                    },
                    od: {
                        t: 0,
                        unSelectDay: [],
                        openDay: [],
                    },
                    banAll: 0,
                    banHoliday: 0,
                    banPassDate: 0,
                    banSingleDateList: [],
                },
            });
            break;
        case 1: //多行文本
        case 7: //文件上传
        case 8: //手机号码
        case 9: //邮箱验证
        case 10: //身份验证
        case 11: //省市县区
            Object.assign(data, {});
            break;
        case 12: //多级下拉选项
            Object.assign(data, {
                input: '选项1-选项1.1\n选项1-选项1.2\n选项2-选项2.1\n选项2-选项2.2',
            });
            break;
        case 13:
            Object.assign(data, {
                numberSetting: {
                    type: 0,
                    min: 0,
                    max: 99999,
                    decimalCnt: 1,
                },
            });
            break;
        case 14:
            Object.assign(data, {
                radioPicSetting: deepExtendClone(module.extInfo.defaultContentItem.radioPicSetting),
            });
            break;
        case 15:
            Object.assign(data, {
                checkBoxPicSetting: deepExtendClone(module.extInfo.defaultContentItem.checkBoxPicSetting),
            });
            break;
        default:
            throw new Error('Unknown Type.');
    }

    return data;
}

export default FormPanel;
