import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import OnlineMapView from './module/onlineMap.vue';

class _OnlineMap extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.ONLINE_MAP, options);
    }

    render() {
        return OnlineMapView;
    }
}

export const OnlineMap = new _OnlineMap();
