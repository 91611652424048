<template>
    <div class="photoModule styleForm6">
        <div :id="'photoSwipe' + module.id" class="photoSwipe">
            <div class="photoSwipeBox">
                <div
                    v-for="(item, index) in cycleList"
                    :key="item.picId"
                    class="swipImgArea imageDiv"
                    :class="imageDivStyle"
                    :data-picId="item.picId || item.pic"
                >
                    <a
                        v-if="content.link === 0"
                        class="imgHref"
                        :href="item.hrefStr2"
                        style="cursor: default"
                        @click="photoSlidesshow(index, item)"
                    >
                        <img
                            :id="item.picId"
                            class="J_img_lazyload"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="item.name"
                            :photoIndex="index"
                        />
                    </a>
                    <a
                        v-else-if="content.link === 1"
                        class="imgHref"
                        hidefocus="true"
                        @click="photoSlidesshow(index, item)"
                    >
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner J_img_lazyload"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="item.name"
                            :photoIndex="index"
                        />
                    </a>
                    <a
                        v-else-if="
                            content.link === 2 && content.apw === 1 && item.linkType == 2 && item.jumpInfo.u != ''
                        "
                        class="imgHref"
                        hidefocus="true"
                        :href="item.jumpInfo.u"
                        @click="photoSlidesshow(index, item)"
                    >
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner J_img_lazyload"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="item.name"
                            :photoIndex="index"
                        />
                    </a>
                    <a
                        v-else-if="content.link === 2 && item.jumpInfo.u != ''"
                        class="imgHref"
                        hidefocus="true"
                        :href="item.jumpInfo.u"
                        @click="photoSlidesshow(index, item)"
                    >
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner J_img_lazyload"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="item.name"
                            :photoIndex="index"
                        />
                    </a>
                    <a v-else class="imgHref" hidefocus="true" @click="photoSlidesshow(index, item)">
                        <img
                            :id="item.picId"
                            class="J_noOpenDesigner J_img_lazyload"
                            :class="stretchImgClass"
                            :src-original="item.picPath"
                            :src="imgSrc(item)"
                            :vwidth="item.rect_640_width"
                            :vheight="item.rect_640_height"
                            :alt="item.name"
                            :photoIndex="index"
                        />
                    </a>
                </div>
            </div>
            <div :id="'photoBullet' + module.id" :class="showNameClass" class="photoBullet" :style="photoBulletStyle">
                <table class="tableFix" :class="tabaleBgColor">
                    <tbody>
                        <tr>
                            <td v-if="content.showName === true" :class="imgNameClass" style="display: block">
                                <div
                                    :id="'imgName' + module.id"
                                    class="imgName g_carouselPhoto_link"
                                    :style="link_style"
                                ></div>
                            </td>
                            <td v-else :class="imgNameClass">
                                <div
                                    :id="'imgName' + module.id"
                                    class="imgName g_carouselPhoto_link"
                                    :style="link_style"
                                ></div>
                            </td>

                            <td v-if="content.showName === true" class="tdUl" style="display: block">
                                <ul
                                    :id="'bullets' + module.id"
                                    class="bullets f-bullets J_noOpenDesigner"
                                    :class="bulletsHideClass"
                                >
                                    <li
                                        v-for="(item, index) in cycleList"
                                        :key="index"
                                        :class="onClass(index)"
                                        class="J_noOpenDesigner"
                                    ></li>
                                </ul>
                            </td>

                            <td v-else-if="content.showName === false" class="tdUl">
                                <ul
                                    :id="'bullets' + module.id"
                                    class="bullets f-bullets J_noOpenDesigner"
                                    :class="bulletsHideClass"
                                >
                                    <li
                                        v-for="(item, index) in cycleList"
                                        :key="index"
                                        :class="onClass(index)"
                                        class="J_noOpenDesigner"
                                    ></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import CarouselTypeMixins from './mixins';

export default {
    name: 'CarouselType6',
    mixins: [CarouselTypeMixins],
    inject: ['module', 'options'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['photoSwipeList', 'nameList', 'cycleList', 'noLazyLoad'],
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            photoList: [],
        };
    },
    computed: {
        content() {
            return this.module.content;
        },
        carouselType() {
            return this.module.content.type;
        },
        imageDivStyle() {
            var cls = {};

            if (this.index === 1) {
                cls['imageDivStyle'] = true;
            }

            return cls;
        },
        stretchImgClass() {
            var cls = {};

            if (this.module.content.picScale != 0) {
                cls['stretchImg'] = true;
            }

            return cls;
        },
        showNameClass() {
            var cls = {};

            if (this.module.content.showName === false) {
                cls['fk-photoBulletStyle'] = true;
            }

            return cls;
        },
        tabaleBgColor() {
            var cls = {};

            if (this.module.content.showName === true) {
                cls['tableFixColor'] = true;
            }

            return cls;
        },
        imgNameClass() {
            var cls = {};

            if (this.module.content.showName === false) {
                cls['f-imgName'] = true;
            }

            return cls;
        },
        bulletsHideClass() {
            var cls = {};
            if (this.cycleList.length === 1) {
                cls['bulletsHide'] = true;
            }
            return cls;
        },
        photoBulletStyle() {
            var style = {};
            style.display = 'block';
            style.height = 'auto !important';
            if (this.module.content.nameWrap) {
                style.wordWrap = 'break-word';
            } else {
                style.whiteSpace = 'nowrap';
            }
            return style;
        },
        link_style() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var imgDescFont = this.module.content.mhighs.idf;
            if (imgDescFont.type == 1) {
                if (imgDescFont.lfs) {
                    style += 'font-size:' + imgDescFont.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (imgDescFont.lfct == 1) {
                    style += 'color:' + imgDescFont.lfc + ';';
                }
                if (imgDescFont.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
    },
    methods: {
        onClass: function (index) {
            var cls = {};

            if (index == 0) {
                cls['on'] = true;
            }

            return cls;
        },
        imgSrc(item) {
            return this.noLazyLoad ? item.picPath : this.options.loadingPath;
        },
    },
};
</script>

<style lang="scss">
.jz_themeV3 .photoModule.styleForm6,
.jz_themeV3 .photoModule.styleForm7 {
    outline: #fff solid 0.2rem;
    margin: 0;
}
.carouselMultiPhoto .photoModule.styleForm6 {
    margin-top: 0;
    margin-bottom: 0;
}
.photoModule.styleForm6 .fk-photoBulletStyle .f-imgName {
    display: none;
}
.formStyle42.form .formMiddle {
    overflow: hidden;
    z-index: 99;
    padding-bottom: 0;
}
.photoModule.styleForm6 .photoSwipe {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    text-align: center;
    visibility: visible;
}
.carouselMultiPhoto .photoModule.styleForm6 .photoSwipeBox {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 0; /*处理两种情况：1.修复图片节点没有隐藏时候，初始化会看见图片垂直排列的过程 */
    /*2.图片比例调整和初始化不一样导致用户会看见图片由大变小或者由小变大的过程
                  /*解决办法：初始化让这个区域的宽度是0，用户看不见初始化的过程*/
}
.photoModule.styleForm6 .photoSwipeBox .swipImgArea {
    height: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.imageDiv img {
    vertical-align: middle;
}
.photoModule.styleForm6 .photoBullet {
    position: absolute;
    width: 100%;
    height: 1.2rem;
    top: auto;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);
    line-height: 1.2rem;
}

.photoModule.styleForm6 .fk-photoBulletStyle {
    background: none;
}

.photoModule.styleForm6 .noBG {
    background: none;
    text-align: center;
}
.photoModule.styleForm6 .photoBullet .bullets {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.photoModule.styleForm6 .noBG .bullets {
    text-align: center;
}

.photoModule.styleForm6 .fk-photoBulletStyle .f-bullets {
    text-align: center;
}

.photoModule.styleForm6 .photoBullet .bullets li {
    display: inline-block;
    width: 0.35rem;
    height: 0.35rem;
    border-radius: 100%;
    margin: 0 0.1rem;
    cursor: pointer;
    background: #adb1b1;
}
.photoModule.styleForm6 .noBG .bullets li {
    background: rgba(170, 170, 170, 0.5);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}
.photoModule.styleForm6 .photoBullet .bullets li.on {
    background: #fff;
}
.photoModule .photoBullet .imgName {
    width: 100%;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    line-height: 0.9rem;
    font-size: 0.6rem;
    text-align: center;
    margin-bottom: -0.2rem;
    padding-top: 0.2rem;
}
.tdUl,
.tdUl .bullets.f-bullets {
    line-height: 1rem;
    font-size: 0;
}
.tdUl {
    height: 1rem;
    padding: 0;
}
.photoModule.styleForm6 .photoBullet .bullets li {
    vertical-align: middle;
}
.tableFix td {
    padding: 0;
}
.photoModule .photoBullet .tableFix {
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
}

.photoModule .photoBullet .tableFix .tdUl {
    vertical-align: bottom;
}
.imageDivStyle {
    display: none;
}
.form .formMiddle .stretchImg {
    width: 100%;
    height: 100%;
}
.bulletsHide {
    display: none;
}
a.imgHref {
    height: 100%;
    display: flex;
    align-items: center;
}
</style>
