import { initWebPage } from '@/shared/misc/init-webpage';
export function productGuaranteeEventInit() {
    if (jm('.J_guarantee .guaranteeName').length == 0) {
        jm('.J_guarantee').hide();
    }

    initWebPage({
        triggerId: 'guaranteeContent',
        panelId: 'productGuaranteePanel',
        pageBg: true,
        returnSeltFun: true,
        direction: 'bottom',
        closeWebPage: '#guaranteeBtn,#closeProductGuarantee,#closePdGuaranteeIcon',
    });
}
