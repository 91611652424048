<template>
    <div :id="'groupList' + moduleId" :_prUrl="prUrl" :class="['first_panel', 'groupList' + moduleId]">
        <GroupBanner :nav-index="navIndex" :no-lazy-load="noLazyLoad"></GroupBanner>
        <div
            v-for="(item, index) in list"
            v-show="isSelect(item, catalogName)"
            :id="'sed_' + item.name"
            :key="index"
            :class="{ selected: isSelect(item, catalogName) }"
            :gcn="item.name"
            class="J_secondGroupPanel second_group_panel"
        >
            <div :class="['second_groupitem', 'second_groupitem_' + moduleStyle]">
                <div style="margin-top: 0" class="group_img_wrap clearFix">
                    <div
                        v-for="(childItem, childIndex) in lessThanMaxProductGroup(item.groupList)"
                        :key="childIndex"
                        class="J_groupItem f-groupImgContainer"
                        :class="secondGroupClass"
                        :data-id="childItem.id"
                    >
                        <a
                            v-if="moduleStyle == 5 || moduleStyle == 6"
                            :id="'imgContainer' + childItem.id"
                            :href="'pr.jsp?groupId=' + childItem.id"
                            target="_self"
                            class="f-imgContainer"
                            @mouseover="grouItemMouseOver($event, childItem)"
                            @mouseout="groupItemMouseOut($event)"
                        >
                            <span
                                v-if="childItem.mIcon != '' && isFontIconId(childItem.mIcon)"
                                :class="['J_itemImage', 'isFontIcon', 'f-itemImg', childItem.iconPath]"
                                :style="fontIconStyle"
                            ></span>
                            <div
                                v-if="
                                    childItem.mIcon != '' &&
                                    !isFontIconId(childItem.mIcon) &&
                                    childItem.fileInfo != null
                                "
                                class="J_itemImage f-itemImg J_img_lazyload"
                                :style="imgStyle(childItem)"
                                :src-original="childItem.iconPath"
                                :data-w="childItem.fileInfo.w"
                                :data-h="childItem.fileInfo.h"
                            ></div>
                            <span
                                v-if="
                                    childItem.mIcon != '' &&
                                    !isFontIconId(childItem.mIcon) &&
                                    childItem.fileInfo == null
                                "
                                class="J_itemImage notImage faisco-icons-pic"
                                :style="fontIconStyle"
                            ></span>
                            <div
                                v-if="childItem.mIcon == ''"
                                class="J_itemImage notImage J_img_lazyload"
                                :style="defaultImgStyle"
                                :src-original="defaultImg"
                            ></div>
                        </a>
                        <a :href="'pr.jsp?groupId=' + childItem.id" target="_self">
                            <p
                                class="f-itemImgName sed_groupname"
                                :style="sedGroupNameStyle"
                                v-text="childItem.name"
                            ></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GroupBanner from './group-banner.vue';
import { decodeHtml } from '@/shared/util';

import { productGroupBannerUpload, productGroupItemUpload } from '../product-group';
import { changeHref } from '@/features/router/universal';
import { setUrlArgToCookieAndJump } from '@/modules/comm';
export default {
    name: 'ProductPhotoGroupingPanel',
    components: {
        GroupBanner,
    },
    inject: ['module', 'options'],
    props: {
        moduleId: {
            type: [String, Number],
            require: true,
            default: '',
        },
        navIndex: {
            type: [Number],
            default: 0,
        },
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        catalogName: {
            type: String,
            default: '',
        },
        moduleStyle: {
            type: [String, Number],
            default: 5,
        },
        maxProductGroup: {
            type: Number,
            default: 20,
        },
        prUrl: {
            type: String,
            default: function () {
                return '';
            },
        },
        resRoot: {
            type: String,
            default: '',
        },
        pn: {
            type: String,
            default: '',
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        curGroupBanner: function () {
            return this.module.content.ap[this.navIndex] || {};
        },
        curIsDefaultBanner: function () {
            return !this.curGroupBanner || !this.curGroupBanner.pi;
        },
        groupBannerStyle: function () {
            var style = {},
                adPicInfo = this.curGroupBanner;
            // picPath = !!adPicInfo && !!adPicInfo.pi ? adPicInfo.p : ``;

            if (this.module.content.apt == 0 || !adPicInfo.s) {
                style.display = 'none';
            } else {
                if (!this.curIsDefaultBanner) {
                    style.backgroundImage = `url(${this.noLazyLoad ? adPicInfo.p : this.options.loadingPath})`;
                }
                switch (this.module.content.apst) {
                    case 1:
                        style.backgroundSize = 'contain';
                        break;
                    case 2:
                        style.backgroundSize = '100% 100%';
                        break;
                    case 3:
                        style.backgroundSize = 'auto';
                        break;
                }
            }
            return style;
        },
        defaultImg() {
            return this.resRoot + '/image/module/productGroup/defaultImg.jpg';
        },
        defaultImgStyle: function () {
            let style = {
                backgroundImage: 'url(' + (this.noLazyLoad ? this.defaultImg : this.options.loadingPath) + ')',
                paddingTop: '2.625rem',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
            };

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style.width = `${this.module.content.gps.w}rem`;
                    style.paddingTop = `${this.module.content.gps.h}rem`;
                }
            }

            return style;
        },
        sedGroupNameStyle() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            let style = {};
            if (this.module.content.s == 5 || this.module.content.s == 6 || this.module.content.s == 7) {
                const textSetting = this.module.content.mhighs.sgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
        thirdGroupNameStyle: function () {
            let style = {};
            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                // 自定义图片尺寸时，文字宽度固定
                if (this.module.content.gps.t == 1) {
                    style.width = `${this.module.content.gps.w}rem`;
                }
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }

            // 以下的访客态在 pageFunction.jsp.inc 输出
            if (this.module.content.s == 5 || this.module.content.s == 6) {
                const textSetting = this.module.content.mhighs.tgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
        secondGroupClass() {
            let classList = [];
            classList.push('f-groupImgContainer-' + this.moduleStyle);
            if (this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1 && this.module.content.gps.w > 6) {
                    // 超出宽度，减少margin-right过大导致换行
                    classList.push('f-less-margin-groupImgContainer');
                }
            }
            return classList;
        },
    },
    methods: {
        lessThanMaxProductGroup(list = []) {
            return list.filter((item, index) => index < this.maxProductGroup);
        },
        isSelect: function (item, catalogName) {
            return decodeHtml(item.name) == decodeHtml(catalogName);
        },
        isFontIconId(iconId) {
            if (!iconId || iconId.length == 0 || iconId.length < 'FontIcon_'.length) {
                return false;
            }

            return (
                iconId.substring(0, 'NewFontIcon_'.length) == 'NewFontIcon_' ||
                iconId.substring(0, 'FontIcon_'.length) == 'FontIcon_'
            );
        },
        imgStyle(childItem) {
            let style = '';
            style += `background-image:url(${this.noLazyLoad ? childItem.iconPath : this.options.loadingPath});`;

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style += `width:${this.module.content.gps.w}rem;padding-top:${this.module.content.gps.h}rem;`;
                    style += `font-size:${this.module.content.gps.w}rem;`;
                }

                let backgroundSize;
                switch (this.module.content.gps.s) {
                    case 1:
                        backgroundSize = 'contain';
                        break;
                    case 2:
                        backgroundSize = '100% 100%';
                        break;
                    case 3:
                        backgroundSize = 'auto';
                        break;
                }
                if (backgroundSize) {
                    style += `background-size:${backgroundSize};`;
                }
            }
            return style;
        },
        fontIconStyle() {
            let style = {};

            if (this.moduleStyle == 5 || this.moduleStyle == 6) {
                if (this.module.content.gps.t == 1) {
                    style.fontSize = `${Math.min(this.module.content.gps.w, this.module.content.gps.h)}rem`;
                    style.width = `${this.module.content.gps.w}rem`;
                    style.height = `${this.module.content.gps.h}rem`;
                }
            }
            return style;
        },
        groupBannerClick: function () {
            if (VITE_APP_MODE !== 'visitor' && Vue.prototype.$designer.checkSaveBar()) {
                return;
            } else if (this.curGroupBanner.i) {
                if (VITE_APP_MODE !== 'visitor' && !!this.curGroupBanner.ji && this.curGroupBanner.ji.t == 103) {
                    Fai.top.Fai.ing(`管理态不支持跳转外部链接，请通过“预览”进行访问`, true);
                } else {
                    if (this.curGroupBanner.ji && this.curGroupBanner.ji.reqArgs) {
                        setUrlArgToCookieAndJump(this.curGroupBanner.h, this.curGroupBanner.ji.reqArgs);
                    } else {
                        changeHref(this.curGroupBanner.h);
                    }
                }
            }
        },
        groupBannerMouseOver: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑产品',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupBannerUpload(this.module.id, this.navIndex);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };
            Vue.prototype.$layer.addEditLayer($(this.$refs.groupBanner), itemJson, 6, options);
        },
        groupBannerMouseOut: function () {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($(this.$refs.groupBanner));
        },
        grouItemMouseOver: function ($event, item) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑图片',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupItemUpload(this.module.id, item.id);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };
            Vue.prototype.$layer.addEditLayer($($event.currentTarget), itemJson, 6, options);
        },
        groupItemMouseOut: function ($event) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($($event.currentTarget));
        },
    },
};
</script>
