import { ing } from '@/shared/tips';
import { site_cityUtil } from '@/shared/city';
import { MobileSelectArea } from '@/shared/plugin';
import { getOneFormInfoApi } from '@/api/form';
import FormPopup from '@/system/formPopup/module/formPopup';
import { getLanCode } from '@/shared/fai';
import { JZUpload } from '@jz/biz-shared';
import { customPopup } from '@/shared/popup';
export const siteFormValidation = function (siteFormId, moduleId) {
    const lanCode = getLanCode();
    const lanCodePrefix = lanCode === '' ? '/' : `/${lanCode}/`;
    jm('#M' + moduleId + 'F' + siteFormId + 'validateCodeImg').attr(
        'src',
        lanCodePrefix + 'validateCode.jsp?' + parseInt(Math.random() * 1000) + '&vCodeId=' + moduleId + siteFormId
    );
};

export const siteFormFileUploadHtml = function (
    siteFormItemId,
    moduleId,
    fileTypeList,
    isOverLimit,
    updateUrlViewRes,
    mobiVer,
    file_size_limit
) {
    jm('#uploadButton' + moduleId + siteFormItemId)
        .find('.uploadify-button')
        .on('click', function () {
            jm('#uploadButton' + moduleId + siteFormItemId)
                .find('.selectbtn')[0]
                .click();
        });
    JZUpload.initUploadifyButton({
        dom: '#uploadButton' + moduleId + siteFormItemId,
        onChange: async (e) => {
            if (isOverLimit) {
                ing('已超过资源库容量限制，请升级网站版本。');
                return false;
            } else {
                let file = e.target.files[0];
                let jzUpload = new JZUpload({
                    getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey', // 请求库、请求路径、请求行为

                    onError(error) {
                        ing('文件:' + file.name + '，' + error.message);
                        jm('#uploadButton' + moduleId + siteFormItemId)
                            .find('.selectbtn')
                            .val('');
                    },
                    // 取决于后台
                    onComplete(data) {
                        onFileUploadEvent('upload', data, moduleId, siteFormItemId);
                        setTimeout(function () {
                            ing('文件上传成功', true);
                        }, 2500);
                        jm('#uploadButton' + moduleId + siteFormItemId)
                            .find('.selectbtn')
                            .val('');
                    },

                    onNext(info) {
                        const percent = Math.ceil(info.total.percent);
                        if (percent == 100) {
                            ing('正在上传' + percent + '%', true);
                        } else {
                            ing('正在上传' + percent + '%', false, true);
                        }
                    },
                    // faiupload的params
                    params: {
                        aid: Fai.top._aid,
                        folderId: Fai.top._siteId,
                        // 业务参数
                        bssInfo: {
                            siteId: Fai.top._siteId || 0,
                            fileSizeLimit: file_size_limit,
                            uploadType: 'siteFormFile',
                        },
                        accessKeyInfo: {
                            // 是否上传到临时目录 TODO
                            isTmp: true,
                        },
                    },
                    tipsFn(string) {
                        ing(string);
                    },
                    fileTypeExts: fileTypeList.join(''),
                });
                // 开始上传
                jzUpload.upload(file);
            }
        },
        fileTypeExts: fileTypeList.join(''),
    });
};

const onFileUploadEvent = function (evt, args, mId, sfItemId) {
    if (evt == 'upload') {
        var tmp_file_id = args.id; //返回临时文件ID
        var file_name = args.name; //返回文件名
        var file_type = args.type || 0; //返回文件类型
        var file_id = args.fileId; //返回文件ID
        var file_width = args.width || 0;
        var file_height = args.height || 0;
        jm('#module' + mId)
            .find('#siteForm' + mId + 'fileName' + sfItemId)
            .html(file_name);
        jm('#module' + mId)
            .find('#siteForm' + mId + 'fileName' + sfItemId)
            .attr('_tmpFileId', tmp_file_id)
            .attr('_tmpFileName', file_name)
            .attr('title', file_name)
            .attr('_fileId', file_id)
            .attr('_width', file_width)
            .attr('_height', file_height)
            .attr('_type', file_type);
    }
};

export const siteFormSelectArea = async function (
    siteFormItemId,
    siteFormId,
    moduleId,
    currentLcid,
    level,
    callback,
    options = {}
) {
    var provincelist;
    if (currentLcid == 2052 || currentLcid == 1028) {
        provincelist = await site_cityUtil.getProvince();
    } else {
        provincelist = await site_cityUtil.getProvinceEn();
    }
    var selectArea = new MobileSelectArea();
    selectArea.init({
        level,
        lcid: currentLcid,
        trigger: '#M' + moduleId + 'F' + siteFormId + 'siteFormAddressInput' + siteFormItemId + '',
        value: jm('#M' + moduleId + 'F' + siteFormId + 'siteFormAddressInput' + siteFormItemId + '').data('value'),
        data: provincelist,
        eventName: 'click',
        init: 0,
        callback,
        ...options,
    });
};

export const initmobiFormTimeBtn = function (options) {
    var webFooterBox = jm('#webFooterBox'),
        containerStartId = '#Mobi' + options.moduleId + 'Calendar' + options.siteFormItemId + 'Plugins',
        containerStart = jm(containerStartId),
        dateSetting = options.dateSetting,
        unSelectTimeClone = JSON.parse(JSON.stringify(dateSetting.od.unSelectDay)).sort(),
        showHour = dateSetting.type == 0 && dateSetting.accuracy == 1,
        dateRange = dateSetting.type == 1,
        now = new Date(),
        todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        containers = [{ elem: containerStart, isStart: true }],
        startInput,
        endInput,
        containerEndId,
        minHour;

    if (dateRange) {
        containerEndId = '#Mobi' + options.moduleId + 'Calendar' + options.siteFormItemId + 'Plugins_end';
        containers.push({ elem: jm(containerEndId), isStart: false });
    } else {
        // 如果在ban的列表没有找到，代表这是最小可以显示的时间
        for (var i = 0; i < 24; i++) {
            if (jm.inArray(i, dateSetting.ot.unSelectTime) == -1) {
                minHour = i;
                break;
            }
        }
    }

    for (i = 0; i < containers.length; i++) {
        (function (index) {
            var containerItem = containers[index],
                isStart = containerItem.isStart,
                container = containerItem.elem,
                inputExtClass = dateRange ? (isStart ? 'fk-datePickStart' : 'fk-datePickEnd') : '',
                nowDate = dateSetting.od.t == 1 && dateSetting.banPassDate == 1 ? todayDate : new Date(1900, 0, 1),
                drawNavHeaderHtml = '';

            if (dateRange) {
                drawNavHeaderHtml = isStart
                    ? `<div class='fk-calendarNav'>${LS.js_select_start_date}</div>`
                    : `<div class='fk-calendarNav'>${LS.siteFormPleaseSelect + LS.siteFormTimeEnd}</div>`;
            }

            // 写在前面 ：所有API传过来的date参数都是这样的格式，2017-08-29 要转换成 2017/08/29 ,因为IE678和手机浏览器不识别“-”的格式
            container.Calendar({
                inputExtClass,
                minDate: nowDate,
                yearChangeable: options.yearChangeable, // 能否切换年份
                monthChangeable: options.monthChangeable, // 能否切换月份
                slideAble: true, // 是否左右滑动切换,touchstart实现
                placeholder: options.placeholder,
                showHour, // 是否显示时间
                minHour: minHour || 0,
                drawNavHeaderHtml, // 头部导航（开始时间、结束时间）
                onBeforeInitCalendar(calendar) {
                    if (calendar._container.hasClass('fk-startDateInput')) {
                        calendar._option('minDate', nowDate);
                        calendar._option('maxDate', null);
                    }
                    calendar._input[0].blur();
                    webFooterBox.addClass('fk-datepickerContainer'); // 防止webFooterBox被隐藏了
                },
                onBeforeCancel(calendar) {
                    var targetOption;
                    if (dateRange && isStart) {
                        // 日期区间型 在开始日期点击取消时 要把结束日期面板也关掉
                        targetOption = calendar.getTargetOption(calendar._container[0].id + '_end');
                        targetOption._hideDate(targetOption._container, targetOption);
                    }
                },
                onBeforeSave(calendar) {
                    let { _options = {} } = calendar;
                    let { _selectDate } = _options;
                    if (_selectDate != null && _selectDate != '') {
                        let day = _selectDate.split(' ')[0];
                        let date = new Date(day);
                        if (!isOpenDay(date)) {
                            calendar._input.val('');
                        }
                    }
                },
                beforeShowTime(index) {
                    // 是否显示时间
                    // 哪些时间不可选
                    if (dateSetting.ot.unSelectTime.length > 0 && jm.inArray(index, dateSetting.ot.unSelectTime) > -1) {
                        return false;
                    }
                    return true;
                },
                beforeShowDay(date) {
                    return isOpenDay(date);
                },
                onAfterRefresh(calendar) {
                    if (dateRange) {
                        //之前选中的日期不要高亮了，而是点击了哪个日期，哪个日期就高亮
                        calendar._container.find('.ui-state-active').addClass('ui-state-active-hide');
                        if (!isStart) {
                            calendar._container
                                .find('td')
                                .not('.ui-calendar-unSelectable')
                                .eq(0)
                                .find('.ui-state-default')
                                .addClass('ui-calendar-startDate');
                        }
                    }
                },
                onSelectDate(dateStr, calendar) {
                    if (!dateRange) return;

                    var date = dateStr.replace(/-/g, '/'), // 由2017-08-29 转换成 2017/08/29 ,因为IE678和手机浏览器不识别“-”的格式
                        selDate = new Date(date),
                        dateTime = new Date(selDate.getFullYear(), selDate.getMonth(), selDate.getDate()).getTime(), // 当天00:00的时间
                        option = { minDate: nowDate, maxDate: null },
                        start = calendar._container.hasClass('fk-startDateInput'),
                        targetOption,
                        endDate,
                        endDateStr,
                        endDateVal,
                        nextDay;

                    if (start) {
                        // 结束日期最远能选择到(一年内)最近的一个不可选日期前一天
                        option.minDate = selDate;
                        if (dateSetting.banAll == 1 || dateSetting.banHoliday == 1 || unSelectTimeClone.length > 0) {
                            for (i = 1; i < 365; i++) {
                                nextDay = new Date(dateTime + i * 86400000);
                                if (!isOpenDay(nextDay)) {
                                    option.maxDate = nextDay;
                                    break;
                                }
                            }
                        }
                    }

                    // 限定日期范围
                    if (start) {
                        // 限定结束日期
                        targetOption = calendar.getTargetOption(calendar._container[0].id + '_end');
                        endDateStr = targetOption._input.val();
                        endDateVal = typeof endDateStr == 'string' ? endDateStr.replace(/-/g, '/') : '';
                        endDate = new Date(endDateVal).getTime();
                        if (
                            !!endDate &&
                            ((!!option.minDate && endDate < option.minDate.getTime()) ||
                                (!!option.maxDate && endDate > option.maxDate.getTime()))
                        ) {
                            targetOption._input.val('');
                        }
                    } else {
                        targetOption = calendar.getTargetOption(calendar._container[0].id.replace('_end', ''));
                    }

                    if (targetOption) {
                        targetOption._option('minDate', new Date(option.minDate));
                        targetOption._option('maxDate', option.maxDate != null ? new Date(option.maxDate) : null);
                        targetOption.switchMonthTo(calendar._options._selectedMonth, calendar._options._selectedYear); // 结束日期的选择也跳到开始日期的那个月中
                        targetOption.refresh();
                    }
                },
                onAfterSelectDate: options.onAfterSelectDate || function () {},
                onAfterHidePicker() {
                    if ((dateRange && !isStart) || !dateRange) {
                        webFooterBox.removeClass('fk-datepickerContainer');
                        if (options.onAfterHidePicker) {
                            options.onAfterHidePicker();
                        }
                    }
                },
            });
        })(i);
    }

    if (dateRange) {
        endInput = jm(containerEndId + 'input');
        startInput = jm(containerStartId + 'input');
        // 把“至”移到开始日期和结束日期中间
        jm('#Mobi' + options.moduleId + 'CalendarInput' + options.siteFormItemId).insertBefore(endInput);
        // 用遮罩层 遮住 开始日期与结束日期输入框，使点击日期输出框时先出现开始日期再出现结束日期
        createDateMask(startInput, containerEndId, containerStartId);
        createDateMask(endInput, containerEndId, containerStartId);
    }

    function createDateMask(dateInput, containerEndId, containerStartId) {
        var dateInputMask, dateInputMaskWrap;
        dateInputMaskWrap = jm("<div class='fk-dateInputMaskWrap'></div>");
        dateInputMask = jm("<div class='fk-dateInputMask'></div>");
        dateInputMaskWrap.insertAfter(dateInput);
        dateInputMaskWrap.append(dateInput);
        dateInputMaskWrap.append(dateInputMask);
        dateInputMask.on('click', function () {
            jm(containerEndId + 'input').trigger('click');
            jm(containerStartId + 'input').trigger('click');
        });
    }

    // 检查日期是否可选
    function isOpenDay(date) {
        var open = true,
            day = date.getDay(),
            time = date.getTime();

        if (dateSetting.od.t == 0) {
            return true;
        }

        if (dateSetting.banAll == 1) {
            open = false;
        }

        if (day == 0 || day == 6) {
            if (dateSetting.banHoliday == 1) open = false;
            else {
                open = true;
            }
        }

        // 支持单独屏蔽某天
        if (dateSetting.banSingleDateList && dateSetting.banSingleDateList.length) {
            if (dateSetting.od.t == 1 && jm.inArray(day, dateSetting.banSingleDateList) > -1) {
                open = false;
            }
        }

        if (dateSetting.od.t == 1 && jm.inArray(time, dateSetting.od.unSelectDay) > -1) {
            open = false;
        }

        if (dateSetting.od.t == 1 && jm.inArray(time, dateSetting.od.openDay) > -1) {
            open = true;
        }

        return open;
    }
};

const formMap = {};

function renderFormPopup(formId, moduleInfo, pid, fromBtn = false) {
    if (FormPopup.rending) {
        return;
    }

    if (window.$store.state.app.openMultiPdInquiry && fromBtn) {
        let inquiryProductList = JSON.parse(localStorage.getItem('inquiry_product_list') || '[]');
        if (Fai.top._manageMode) {
            inquiryProductList = [];
        }

        inquiryProductList.unshift(pid);

        inquiryProductList = [...new Set(inquiryProductList)];

        if (inquiryProductList.length <= 50) {
            localStorage.setItem('inquiry_product_list', JSON.stringify(inquiryProductList));
        }

        if (inquiryProductList.length === 50) {
            FormPopup.rending = true;
            FormPopup.render(formId, moduleInfo, moduleInfo.renderOptions, fromBtn);
            return;
        }

        var html = `
        <div style="width:11.95rem; height: 5.45rem;border-radius: 0.1rem;background: #fff;margin: auto;">
            <div style="font-size: 0.7rem;color: #333;border-bottom: 1px solid #e5e5e5;height: 2.925rem;display: flex;align-items: center;justify-content: center;">${
                LS.add_inquiry_list || '已添加进询盘列表'
            }</div>
            <div style="font-size: 0.6rem;display: flex;height: 2.5rem;">
                <div class="J-prompt-callback" style="color: #666;border-right: 1px solid #e5e5e5;flex-basis: 50%;display: flex;align-items: center;justify-content: center;cursor: pointer;">${
                    LS.continue_visit || '继续浏览'
                }</div>
                <div class="J-prompt-cancel" style="color: #1677FF;flex-basis: 50%;display: flex;align-items: center;justify-content: center;cursor: pointer;">${
                    LS.inquiry || '立即询盘'
                }</div>
            </div>
        </div>
    `;

        customPopup({
            content: html,
            insertSelector: '#g_body',
            callback: () => {},
            cancelCallback: () => {
                FormPopup.rending = true;
                FormPopup.render(formId, moduleInfo, moduleInfo.renderOptions, fromBtn);
            },
        });
    } else {
        FormPopup.rending = true;
        FormPopup.render(formId, moduleInfo, moduleInfo.renderOptions);
    }
}

// 点击打开表单弹层
export const linkToForm = async function (formId, pid, fromBtn = false) {
    if (formMap[formId] != null) {
        window.$store.commit('setPopupFormId', formId);
        renderFormPopup(formId, formMap[formId], pid, fromBtn);
        return;
    }
    let { success, data } = await getOneFormInfoApi({ formId });
    if (success) {
        let { module, renderOptions } = data;
        window.$store.commit('setPopupFormModuleInfo', {
            formId: formId,
            id: module.id,
            info: Object.assign({ renderOptions }, module),
        });
        window.$store.commit('setPopupFormId', formId);
        let moduleInfo = window.$store.state.popupFormModuleIdMap[formId];
        formMap[formId] = moduleInfo;
        renderFormPopup(formId, moduleInfo, pid, fromBtn);
    } else {
        ing('表单已被删除', true);
    }
};
