import NewsGroupPanel from './news-group-panel.vue';
let newsGroupPanelVm = null;
let vueData = {
    info: {},
};
export function installNewsGroupPanel(newInfo) {
    Object.assign(vueData.info, newInfo);
    // 路由切换后实例还在页面但是dom已经不在了
    if (newsGroupPanelVm && !jm('.p_newsgroup__wrap').length) {
        newsGroupPanelVm.$destroy();
        newsGroupPanelVm = null;
    }
    if (!newsGroupPanelVm) {
        //创建dom
        let dom = jm("<div id='newsgroup_teleport'></div>");
        dom.appendTo('#g_web');
        vueData.info.isShowPanel = false;
        newsGroupPanelVm = new Vue({
            el: `#newsgroup_teleport`,
            components: {
                NewsGroupPanel,
            },
            data: vueData,
            methods: {
                close() {
                    vueData.info.isShowPanel = false;
                },
            },
            template: `
                <NewsGroupPanel :info="info" @on-close="close"/>
            `,
        });
        setTimeout(() => {
            vueData.info.isShowPanel = newInfo.isShowPanel;
        });
    }
    return newsGroupPanelVm;
}
