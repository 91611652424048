<template>
    <div
        :id="_listId"
        class="mProductList styleForm8"
        :class="_listClass"
        :mType="module.content.t"
        :_productSelect="module.content.ma.mt == 0"
    >
        <template v-for="(info, index) in productList">
            <waterfall-item
                :key="'p_' + info.id"
                :info="info"
                :index="index"
                :len="productList.length"
                :no-lazy-load="noLazyLoad"
            />
        </template>
    </div>
</template>

<script>
import WaterfallItem from './waterfall-item.vue';
import productMixin from '../../mixins/productMixin';
export default {
    name: 'WaterfallList',
    components: { WaterfallItem },
    mixins: [productMixin],
    inject: ['module', 'options'],
    props: ['productList', 'noLazyLoad'],
};
</script>

<style></style>
