<template>
    <div class="m_mobi_form_textArea_wrap">
        <textarea
            class="m_mobi_form_textArea m_mobi_form_input_text"
            :value="currentValue"
            :maxlength="module.extInfo.inputMaxLength"
            :placeholder="formItem.placeholder"
            :style="_mixinsInputStyle"
            @input="handleInput"
        />
    </div>
</template>
<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormTextArea',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            inputMax: 1000,
        };
    },
    computed: {
        submitVal() {
            return this.currentValue;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.currentValue = value;
        },
        resetValue() {
            this.initDefaultValue();
        },
        validate() {
            const inputLength = this.currentValue.trim().length;
            if (inputLength > this.inputMax) {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputMaxLength, this.formItem.name, this.inputMax));
                return false;
            } else if (this.formItem.must && inputLength === 0) {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }
            return true;
        },
        initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtml;
            if (typeof initialValue === 'string') {
                this.currentValue = initialValue;
            }
        },
    },
};
</script>

<style>
.m_mobi_form_textArea_wrap .m_mobi_form_textArea {
    display: block;
    background-color: #fff;
    padding: 0.413rem 0.725rem;
    margin: 0;
    margin-top: 0.7rem;
    height: 3.25rem;
    outline: 0;
    resize: none;
}
</style>
