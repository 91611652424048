<template>
    <module-frame :module-id="moduleId">
        <div :class="wrapClasses">
            <template v-if="manageMode">
                <a
                    :id="wrapId"
                    ref="btnRef"
                    class="m_button_link J_floatBtn middle floatBtn"
                    :contenteditable="contenteditable"
                    :class="aClasses"
                    :style="aStyle"
                    :rel="rel"
                    :onclick="jumpOnclickStr"
                    :href="href"
                    @click="aClick"
                    @dblclick="aDblClick"
                    @blur="aBlur"
                >
                    {{ moduleContent.bs.v }}
                </a>
            </template>
            <template v-else>
                <a
                    :id="wrapId"
                    ref="btnRef"
                    :href="href"
                    class="m_button_link J_floatBtn middle floatBtn"
                    :contenteditable="contenteditable"
                    :class="aClasses"
                    :rel="rel"
                    :style="aStyle"
                    :onclick="jumpOnclickStr"
                    @click="aClick"
                    @dblclick="aDblClick"
                    @blur="aBlur"
                >
                    {{ moduleContent.bs.v }}
                </a>
            </template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';

import { createUrlArgsOnclickStr, initModuleTransformTrack } from '@/modules/comm';
import { changeHref } from '@/features/router/universal';
export default {
    name: 'FloatBtn',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            wrapId: `module${this.moduleId}FlBtn`,
            contenteditable: false,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapGetters('app', ['hashRemoved']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        jumpOnclickStr() {
            if (VITE_APP_MODE !== 'visitor') {
                return null;
            }
            return createUrlArgsOnclickStr(
                this.moduleExtInfo.reqArgs,
                this.module._openRemoveUrlArgs,
                this.hashRemoved
            );
        },
        href() {
            return VITE_APP_MODE !== 'visitor' ? 'javascript:;' : this.moduleExtInfo.href;
        },
        rel() {
            return this.moduleContent.bs.nf && this.moduleExtInfo.isUseNofollow ? 'nofollow' : '';
        },
        moduleExtInfo() {
            return this.module.extInfo || {};
        },
        moduleContent() {
            return this.module.content || {};
        },
        wrapClasses() {
            return `J_floatBtnBox_${this.moduleId} J_floatBtnBox floatBtnBox floatBtnStyle floatBtnStyle floatBtnStyle${this.moduleContent.fbs}`;
        },
        realBtnColor() {
            let color =
                this.moduleContent.color.cValue == '' ? this.options.themeColor : this.moduleContent.color.cValue;
            return color.trim();
        },
        aStyle() {
            const href = this.moduleExtInfo.href;
            const shadow = this.moduleContent.sh;
            const radius = this.moduleContent.r;
            const btnStyle = this.moduleContent.fbs;
            const size = this.moduleContent.s;

            let aStyle = {};
            aStyle.cursor = !href || this.moduleExtInfo.href == 'javascript:;' ? 'default' : 'pointer';

            if (btnStyle == 8 && shadow.t == 0) {
                // 样式八立体色,如果用户自定义了阴影，则用用户的阴影
                const shadowColor = this.burnColor(this.realBtnColor, 20);
                aStyle.boxShadow = `${shadowColor} 0 -0.13rem 0.09rem 0 inset, 0 0.13rem 0.17rem rgba(255,255,255,0.6) inset`;
            }

            if (radius.t == 0 && size.t == 1 && (btnStyle == 5 || btnStyle == 6)) {
                aStyle.borderRadius = `${size.h / 2}px`;
            }

            // 按钮高级样式，访客态在 pageFunction.jsp.inc 输出

            if (VITE_APP_MODE !== 'visitor') {
                const btnColor = this.moduleContent.color;
                let prefix = this.getPrefix();
                if (btnColor.cType != -1) {
                    const cValue = btnColor.cValue;
                    const gradientColorValue = btnColor.gcValue;
                    // 不跟随主题色
                    if (btnStyle == 0 || btnStyle == 1 || btnStyle == 6 || btnStyle == 8) {
                        aStyle.backgroundColor = cValue;
                    } else if (btnStyle == 2 || btnStyle == 5 || btnStyle == 7) {
                        aStyle.border = `1px solid ${cValue}`;
                        aStyle.color = cValue;
                    } else if (btnStyle == 3) {
                        aStyle.background = `${prefix}linear-gradient(bottom, ${cValue} 10%, ${gradientColorValue})`;
                    } else if (btnStyle == 4) {
                        aStyle.background = `${prefix}linear-gradient(bottom, ${cValue} 10%, ${gradientColorValue})`;
                        aStyle.border = `1px solid ${cValue}`;
                    }
                }

                const font = this.module.pattern.cl;

                const italic = this.moduleContent.fs.it;

                if (font.y == 1) {
                    if (font.ls) {
                        aStyle.fontSize = `${font.ls}px`;
                    }

                    if (font.f) {
                        aStyle.fontFamily = font.f;
                    }

                    if (!!font.fct && !!font.c) {
                        aStyle.color = font.c;
                    }

                    aStyle.fontWeight = font.b ? 'bold' : 'normal';
                    aStyle.textDecoration = font.d ? 'underline' : 'none';
                    aStyle.fontStyle = italic ? 'italic' : 'normal';
                }

                // 宽高
                if (size.t == 1) {
                    aStyle.width = `${size.w}px`;
                    aStyle.height = `${size.h}px`;
                    aStyle.lineHeight = `${size.h}px`;
                }

                // 圆角
                if (radius.t != 0) {
                    if (radius.t == 2) {
                        aStyle.borderRadius = `${radius.lt}px ${radius.rt}px ${radius.rb}px ${radius.lb}px`;
                    } else {
                        aStyle.borderRadius = '0';
                    }
                }

                // 边框
                const border = this.moduleContent.b;
                if (border.t != 0) {
                    if (border.t == 2) {
                        aStyle.border = `${border.w}px solid ${border.c}`;
                    } else if (border.t == 1) {
                        aStyle.border = 'none';
                    }
                }

                // 阴影
                if (shadow.t != 0) {
                    if (shadow.t == 2) {
                        aStyle.boxShadow = `${shadow.dx}px ${shadow.dy}px ${shadow.f}px ${shadow.c}`;
                    } else if (shadow.t == 1) {
                        aStyle.boxShadow = 'none';
                    }
                }

                // 背景
                const background = this.moduleContent.bg;
                if (background.t == 1) {
                    const imgPath = background.p.replace('.jpg', '!800x800.jpg');
                    let backgroundStr = this.getBackgroundStyle(background.r, background.c, imgPath);
                    aStyle.background = backgroundStr;
                    if (background.r == 4 || background.r == 5) {
                        aStyle.backgroundSize = '100%';
                        aStyle.backgroundPosition = 'top';
                    } else if (background.r == 23) {
                        // 填充
                        aStyle.background = `url(${imgPath}) no-repeat center /cover`;
                    } else if (background.r == 24) {
                        // 等比例
                        aStyle.background = `url(${imgPath}) no-repeat center /contain`;
                    }

                    if (this.colorIsLinearGradient(background.c) && !!imgPath) {
                        aStyle.background += `, ${background.c}`;
                    } else {
                        aStyle.background += ` ${background.c}`;
                    }
                }
            }
            return aStyle;
        },
        aClasses() {
            let classes = {};

            const btnStyle = this.moduleContent.fbs;
            if (btnStyle == 0 || btnStyle == 1 || btnStyle == 6 || btnStyle == 8) {
                classes['m_floatBtnStyle_color'] = true;
                classes['g_main_bgColor_v3'] = true;
                classes['g_oldThemeBgColor'] = true;
                classes['m_button_default_color'] = true;
            } else if (btnStyle == 2 || btnStyle == 5 || btnStyle == 7) {
                // classes['m_floatBtnStyle_color'] = true;
                classes['g_main_color_v3'] = true;
                classes['g_main_bdColor_v3'] = true;
                classes['g_oldThemeBdColor'] = true;
                classes['g_oldThemeColor'] = true;
            } else if (btnStyle == 3) {
                classes['m_floatBtnStyle_color'] = true;
                classes['g_main_bgGradientColor_v3'] = true;
                classes['m_button_default_color'] = true;
            } else if (btnStyle == 4) {
                classes['m_floatBtnStyle_color'] = true;
                classes['g_main_bgGradientColor_v3'] = true;
                classes['g_main_bdColor_v3'] = true;
                classes['g_oldThemeBdColor'] = true;
                classes['m_button_default_color'] = true;
            }

            if (this.module.pattern.cl.ftc) {
                classes['g_oldThemeColor'] = true;
                classes['g_main_color_v3'] = true;
            }

            return classes;
        },
    },
    watch: {
        contenteditable(flag) {
            // 监听是否编辑状态
            let $btn = $(`#module${this.module.id}`),
                isLockEdit = $btn.data('lockedit');

            if (isLockEdit) {
                // 锁定下，是ok的
                return;
            }
            flag ? $btn.addClass('formLockEdit') : $btn.removeClass('formLockEdit');
        },
    },
    created() {
        if (VITE_APP_MODE !== 'visitor') {
            this.module.pattern.cl = Object.assign(
                { b: 0, d: 0, f: '', fct: 1, c: '#fff', ls: 16 },
                this.module.pattern.cl
            );
            if (this.module.pattern.cl.y == 0) {
                // 更改字号默认值
                this.module.pattern.cl.ls = 16;
                let moduleAttr = this.$store.state.manage.moduleAttr[`module${this.module.id}`];
                moduleAttr.pattern.contentLink.ls = 16;
                this.$store.commit('manage/updateModuleAttr', {
                    id: this.module.id,
                    attr: moduleAttr,
                });
            }
        }
    },
    mounted() {
        this.initJumpWxApp();
        if (!this.manageMode) {
            initModuleTransformTrack(this.module.id, this.module.style, this.module.content.tts);
        }
    },
    methods: {
        initJumpWxApp() {
            if (this.moduleContent.jumpMode !== 1) {
                return;
            }
            Mobi.initJumpWxApp(this.moduleId, this.moduleContent.jumpWxAppData);
        },
        aClick() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            if (this.moduleContent.jumpMode === 1) {
                Fai.top.Fai.ing('请在微信端打开链接', true);
                return;
            }
            // 有链接
            if (this.moduleContent.bs.hi) {
                // 管理态先处理禁止外链跳转，再处理正常情况
                const { jumpInfo = {} } = this.moduleExtInfo;
                const { u: jumpUrl = '', t: type } = jumpInfo;
                if (jumpUrl.includes('http') || jumpUrl.includes('https') || type === 103) {
                    Fai.top.Fai.ing('管理态不支持跳转外部链接，请通过“预览”进行访问', true);
                    return;
                }

                if (this.$designer.checkSaveBar()) {
                    return;
                } else {
                    const isAction = jumpUrl.startsWith('javascript:');
                    if (isAction) {
                        eval(jumpUrl);
                    } else {
                        if (window.$store.state.editMode.isPopupZone) {
                            Fai.ing('弹窗编辑态不支持跳转链接');
                        } else {
                            changeHref(jumpUrl);
                        }
                    }
                }
            }
        },
        aDblClick(event) {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            if (!this.moduleContent.bs.hi) {
                // 无链接时可编辑文字
                this.contenteditable = true;

                this.$nextTick(() => {
                    let obj = this.$refs.btnRef;
                    $(event.target).focus();
                    // 光标移至最后
                    this.selectAll(obj);
                });

                this.$logDog(200310, 2);
            }
        },
        aBlur(e) {
            if (VITE_APP_MODE !== 'visitor') {
                if (this.contenteditable) {
                    const oldVal = this.moduleContent.bs.v;
                    this.moduleContent.bs.v = e.target.innerText;
                    this.$designer.onContentChange(this.module.id);
                    this.contenteditable = false;

                    this.$undo.undoRun(function () {}, {
                        type: 'modulePattern',
                        detail: 'floatBtn',
                        doArgs: [this.moduleContent.bs.v],
                        undoArgs: [oldVal],
                        undoSame: false,
                        redoSame: false,
                        undoFn: this.nameUndo,
                        redoFn: this.nameUndo,
                    });
                }
            }
        },
        burnColor(colorInput, level) {
            var inputRGB = this.hexToRGB(this.RgbtoHex(colorInput)); //优化点
            // 颜色格式校验
            if (!inputRGB || !level || isNaN(level)) {
                return '';
            }
            level = Math.max(level, 0);
            level = Math.min(level, 100);

            for (var i = 0; i < inputRGB.length; i++) {
                inputRGB[i] = Math.floor(inputRGB[i] - inputRGB[i] * (level / 100)).toString(16);

                if (inputRGB[i].length == 1) {
                    inputRGB[i] = '0' + inputRGB[i];
                }
            }
            var outputHex = '#' + inputRGB.join('');
            return outputHex;
        },
        nameUndo(val) {
            this.moduleContent.bs.v = val;
            this.$designer.onContentChange(this.module.id);
        },
        getPrefix() {
            var div = document.createElement('div');
            var cssText =
                '-webkit-transition:all .1s; -moz-transition:all .1s; -o-transition:all .1s; -ms-transition:all .1s; transition:all .1s;';
            div.style.cssText = cssText;
            var style = div.style;
            if (style.webkitTransition) {
                return '-webkit-';
            }
            if (style.MozTransition) {
                return '-moz-';
            }
            if (style.oTransition) {
                return '-o-';
            }
            if (style.msTransition) {
                return '-ms-';
            }
            return '';
        },
        getBackgroundStyle(repeat, color, path) {
            if (!path || repeat == -1) {
                return '';
            }

            return `url(${path}) ${this.getRepeatStyle(repeat)} ${this.getPositionStyle(repeat)}`;
        },
        getRepeatStyle(r) {
            let repeatStr = '';
            if (r == 0 || r == 6 || r == 7 || r == 8 || r == 9 || r == 13 || r == 14 || r == 15 || r == 16 || r == 17) {
                repeatStr = 'no-repeat';
            }
            if (r == 1 || r == 11 || r == 12) {
                repeatStr = 'repeat-x';
            }
            if (r == 2 || r == 21 || r == 22) {
                repeatStr = 'repeat-y';
            }
            if (r == 3 || r == 4 || r == 5 || r == 30) {
                repeatStr = 'repeat';
            }
            return repeatStr;
        },
        getPositionStyle(r) {
            let positionStr = 'center';
            if (r == 9 || r == 12) {
                positionStr = 'bottom';
            }
            if (r == 6 || r == 21) {
                positionStr = 'left';
            }
            if (r == 7 || r == 22) {
                positionStr = 'right';
            }
            if (r == 4 || r == 5 || r == 8 || r == 11 || r == 30) {
                positionStr = 'top';
            }
            if (r == 13) {
                positionStr = 'left top';
            }
            if (r == 14) {
                positionStr = 'right top';
            }
            if (r == 15) {
                positionStr = 'left bottom';
            }
            if (r == 16) {
                positionStr = 'right bottom';
            }
            return positionStr;
        },
        hexToRGB(hexValue) {
            hexValue = hexValue.replace('#', '').toLowerCase();
            if (hexValue.length == 3) {
                var hexValueArr = new Array();
                var i = 3;
                while (i--) {
                    hexValueArr[i] = hexValue.charAt(i) + '' + hexValue.charAt(i);
                }
                hexValue = hexValueArr.join('');
            }

            if (hexValue.length == 6) {
                var RGBValue = hexValue.match(/[0-9a-f]{2}/g);
                if (RGBValue && RGBValue.length == 3) {
                    let i = 3;
                    while (i--) {
                        RGBValue[i] = parseInt(RGBValue[i], 16);
                    }
                    return RGBValue;
                } else {
                    return false;
                }
            }
        },
        RgbtoHex(rgb) {
            if (/#/g.test(rgb)) return rgb;

            rgb = rgb.replace(/[rgba()]/g, '').split(','); //[r,g,b]

            var hex = ['#'];
            for (var i = 0, hexpart; i < 3; i++) {
                hexpart = parseInt(rgb[i]).toString(16);
                if (hexpart.length === 1) hexpart = '0' + hexpart;
                hex.push(hexpart);
            }
            return hex.join('');
        },
        colorIsLinearGradient(color = '') {
            return color.indexOf('linear-gradient') > -1;
        },
        selectAll(obj) {
            let range = window.getSelection(); //创建range
            if (obj && range.selectAllChildren && range.anchorNode) {
                range.selectAllChildren(obj); //range 选择obj下所有子内容
                range.collapseToEnd(); //光标移至最后
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.m_button_link {
    display: block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m_floatBtnStyle_color {
    color: #fff;
}
.m_button_default_radius {
    border-radius: 5px;
}
</style>

<style lang="scss">
/**************************** 按钮模块 start *****************************/
.formStyle54 .moduleContent {
    overflow: initial;
}
.floatBtnStyle5 .floatBtn,
.floatBtnStyle6 .floatBtn {
    border-radius: 1.951219512195122rem;
}
/*5种新按钮样式*/
.form.formStyle54 {
    padding: 0;
    background: none;
    overflow: visible;
    box-shadow: none;
} /*这里去除按钮的阴影*/
.form.formStyle54 .formMiddle {
    overflow: visible;
    background: none;
}
.form .floatBtnBox .middle {
    display: block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.form .floatBtnStyle .middle {
    /*以管理态20.5的_htmlFontSize为计算比例：120*40*/
    width: 5.853658536585366rem;
    height: 1.951219512195122rem;
    line-height: 2.048780487804878rem;
    display: block;
    outline: 0;
}
.form .floatBtnStyle0 .middle {
    border-radius: 5px;
}
.form .floatBtnStyle1 .middle {
    border-radius: 0px;
}
.form .floatBtnStyle2 .middle {
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
}
.form .floatBtnStyle3 .middle {
    border-radius: 3px;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
}
.form .floatBtnStyle4 .middle {
    border-radius: 3px;
    box-shadow: 0px 1.5px 5px -1px rgb(255, 255, 255) inset;
    border-width: 1px;
    border-style: solid;
}

.floatBtnStyle5 .floatBtn,
.floatBtnStyle6 .floatBtn {
    border-radius: 1.951219512195122rem;
}
.floatBtnStyle7 .floatBtn,
.floatBtnStyle8 .floatBtn {
    border-radius: 5px;
}
.floatBtnStyle5 .floatBtn,
.floatBtnStyle7 .floatBtn {
    border-width: 1px;
    border-style: solid;
}

/**************************** 按钮模块 end ****************************/
</style>
