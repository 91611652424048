<template>
    <div class="item_type_input">
        <input
            :maxlength="maxlength"
            :value="value"
            class="input_style u_custom_search_entry--input_line jz_focus"
            :placeholder="placeHolder"
            @input="inputItemText"
        />
    </div>
</template>

<style lang="scss" scope>
.u_custom_search_entry--input_line:focus {
    outline: none;
}
</style>

<script>
export default {
    name: 'CustomSearchInput',
    props: {
        item: Object,
        value: {
            type: String,
            default: '',
        },
        maxlength: Number,
        placeHolder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            inputText: '',
        };
    },
    methods: {
        inputItemText: function (e) {
            var value = e.target.value;
            this.inputText = value;
            this.emitInput();
        },
        emitInput: function () {
            this.$emit('input', this.inputText);
        },
    },
};
</script>
