<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="wrapClasses"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="[isNewAddModuleClass, shadowWrap, isOnlyTitle]">
            <a
                class="J_mixNewsStyleImgBox5_link f_mixNewsStyleImgBox5_link picRadiusClass"
                hidefocus="true"
                :href="_newsUrl"
                :target="_jumpTarget"
                :onclick="onclickStr"
            >
                <div class="mixNewsStyleImgBox5" :class="[picTextStyle, picRadiusBigClass]">
                    <div>
                        <div
                            :id="'newsTitlePic' + info.id"
                            v-drag-resize="_resizeOptions"
                            :alt="altName"
                            :class="[titlePicClass, picRadiusClass]"
                            :src-original="info.realPicId ? info.bigPicPath : ''"
                            :style="picStyle"
                            class="picScaleClass J_img_lazyload"
                        ></div>
                        <div class="J_mixNewsStyleTitleContainer5 mixNewsStyleTitleContainer5" :style="contentBg">
                            <div
                                :class="[titleClasses, titleLine]"
                                class="g_news_title news_title newTitle"
                                :style="news_title_style"
                            >
                                <div :class="_topFlagClass"></div>
                                <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component
                                >{{ info.title }}
                            </div>
                            <div v-if="!isNewAddModule" class="separatorLine"></div>
                            <div
                                v-if="!isNewAddModule"
                                class="mixNewsStyleSummary g_news_abstract"
                                :style="news_abstract_style"
                                :class="textAlignClasses"
                            >
                                {{ info.summary }}
                            </div>
                            <div
                                v-if="isNewAddModule && !module.content.des"
                                class="mixNewsStyleSummary g_news_abstract newDescriptionLine"
                                :style="news_abstract_style"
                                :class="[textAlignClasses, descriptionLine, isOnlyTitleAndDes]"
                            >
                                {{ info.summary }}
                            </div>
                            <div
                                v-if="showOtherInfo"
                                class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo"
                                :class="textAlignClasses"
                                @click="otherInfoClick"
                            >
                                <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                                    <span v-if="!isNewAddModule" class="newsCommentCount g_newsInfo">{{
                                        info.commentCount
                                    }}</span>
                                    <span v-else class="newsCommentCount g_newsInfo"
                                        >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
                                    >
                                </div>
                                <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                                    <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
                                </div>
                                <div
                                    v-if="(_showAuthor || _showComment) && (_showDate || _showSort)"
                                    class="ne_separatorLine g_ne_separatorLine"
                                ></div>
                                <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                                    {{ info.groupName }}
                                </div>
                                <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                                    {{ newsDate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'MaskItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        wrapClasses() {
            return `${this._lineClass} J_cardGraphicNewsStyle cardGraphicNewsStyle newsLine J_picNum`;
        },
        titleClasses() {
            return this.textAlignClasses + this.alignClasses;
        },
        alignClasses() {
            return this.module.content.sl ? ' mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        textAlignClasses() {
            return this.module.content.nta == 0 ? 'justifyTextAlign ' : 'centerTextAlign ';
        },
        titlePicClass() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        divSize() {
            let divWidth = 4.6;
            let divHeight = 5.75;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth / 20;
                divHeight = tSize.picHeight / 20;
            }
            return { divWidth, divHeight };
        },
        picStyle() {
            let picStyle = {};
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            if (this.isNewAddModule) {
                picStyle.height = `${this.divSize.divHeight}rem`;
            }
            return picStyle;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0.6rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        picTextStyle() {
            const picTextStyle = this.module.content.pts;
            var desClass = '';
            switch (picTextStyle) {
                case 0:
                    desClass = 'shadow';
                    break;
                case 1:
                    desClass = 'border';
                    break;
                case 2:
                    desClass = 'backgroundColor';
                    break;
                case 3:
                    desClass = 'transparent';
                    break;
            }
            return ` ${desClass}`;
        },
        shadowWrap() {
            return this.module.content.pts == 0 ? 'shadowWrap' : '';
        },
        contentBg() {
            let style = {};
            if (this.module.content.cbt === 1) {
                style.background = this.module.content.cb;
            }
            return style;
        },
        picRadiusBigClass() {
            return this.module.content.pts != 3 ? 'picRadiusClass' : '';
        },
        picRadiusClass() {
            return this.module.content.pts != 3 ? 'onlyPicRadiusClass' : 'picRadiusClass';
        },
    },
};
</script>

<style>
.news_list_wrap .newsList .cardGraphicNewsStyle .lineBody {
    padding-top: 0.74rem;
}
.news_list_wrap .newsList .cardGraphicNewsStyle .news_title {
    color: #333;
}
.news_list_wrap .newsList .cardGraphicNewsStyle .mixNewsStyleSummary {
    color: #999;
}
.news_list_wrap .newsList .cardGraphicNewsStyle .isNewAddModule .mixNewsStyleSummary {
    color: #848e98;
    font-size: 0.55rem;
}
.isNewAddModule .news_list_wrap .newsList .cardGraphicNewsStyle .isNewAddModule .mixNewsStyleSummary {
    color: #848e98;
    font-size: 0.6rem;
}
</style>
