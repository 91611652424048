<template>
    <div class="imgBulletGroup" :class="imgBulletGroupCls">
        <template v-if="bulletGroupStyle == 1">
            <template v-for="(item, index) in bulletList">
                <transition :key="index" name="bulletFade">
                    <span
                        v-if="imgBulletShow(index)"
                        class="imgBullet"
                        :class="offsetNum(index)"
                        @click="clickHandler(index)"
                    >
                        <span
                            class="imgBulletBall"
                            :class="index == currentIndex ? 'active g_oldThemeBgColor g_main_bgColor_v3' : ''"
                        ></span>
                    </span>
                </transition>
            </template>
        </template>
        <template v-if="bulletGroupStyle == 2">
            <template v-for="(item, index) in bulletList">
                <transition :key="index" name="bulletFade">
                    <span
                        v-if="imgBulletShow(index)"
                        class="imgBullet"
                        :class="offsetNum(index)"
                        @click="clickHandler(index)"
                    >
                        <span
                            class="imgBulletBall"
                            :class="index == currentIndex ? 'active g_oldThemeBgColor g_main_bgColor_v3' : ''"
                        ></span>
                    </span>
                </transition>
            </template>
        </template>
        <template v-if="bulletGroupStyle == 3">
            <template v-for="(item, index) in bulletList">
                <transition :key="index" name="bulletFade">
                    <span
                        v-if="imgBulletShow(index)"
                        class="imgBullet"
                        :class="offsetNum(index)"
                        @click="clickHandler(index)"
                    >
                        <span
                            class="imgBulletBall"
                            :class="index == currentIndex ? 'active g_oldThemeBgColor g_main_bgColor_v3' : ''"
                        ></span>
                    </span>
                </transition>
            </template>
        </template>
        <template v-if="bulletGroupStyle == 4">
            <template v-for="(item, index) in bulletList">
                <transition :key="index" name="bulletFade">
                    <span
                        v-if="imgBulletShow(index)"
                        class="imgBullet"
                        :class="offsetNum(index)"
                        @click="clickHandler(index)"
                    >
                        <span
                            class="imgBulletBall"
                            :class="index == currentIndex ? 'active g_oldThemeBgColor g_main_bgColor_v3' : ''"
                        ></span>
                    </span>
                </transition>
            </template>
        </template>
        <template v-if="bulletGroupStyle == 5">
            <div class="bulletBox">
                <div class="bulletText"
                    >{{ currentIndex + 1 }}<span class="bulletTextSep">/</span>{{ bulletLength }}</div
                >
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ImgBulletGroup',
    props: {
        currentIndex: { type: Number, default: 0 },
        bulletLength: { type: Number, default: 0 },
        maxBullect: {
            type: Number,
            default: 7,
        },
        bulletGroupStyle: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {};
    },
    computed: {
        imgBulletGroupCls() {
            return 'imgBulletGroup' + this.bulletGroupStyle;
        },
        bulletList() {
            var bulletList = [];
            for (var i = 0; i < this.bulletLength; i++) {
                bulletList.push(i);
            }
            return bulletList;
        },
    },
    methods: {
        imgBulletShow(index) {
            var maxBullect = this.maxBullect;
            var centerNum = Math.floor(maxBullect / 2);
            var offsetNum = centerNum + 1;
            var bulletLength = this.bulletLength;
            if (this.currentIndex > centerNum - 1) {
                if (this.currentIndex + offsetNum > bulletLength && index > bulletLength - maxBullect) {
                    return true;
                } else if (this.currentIndex - offsetNum < index && index < this.currentIndex + offsetNum) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (index < maxBullect - 1) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        offsetNum(index) {
            var maxBullect = this.maxBullect;
            var centerNum = Math.floor(maxBullect / 2);
            var bulletLength = this.bulletLength;
            var cls = '';
            if (index == this.currentIndex) {
                cls += 'active ';
            }
            if (this.currentIndex > centerNum - 1 && this.currentIndex < bulletLength - centerNum) {
                var extNumLeft = this.currentIndex + centerNum;
                var extNumRight = this.currentIndex - centerNum;
                if (index == extNumLeft || index == extNumRight) {
                    cls += 'offsetBullect';
                } else {
                    cls += '';
                }
            } else {
                cls += '';
            }
            return cls;
        },
        clickHandler: function (index) {
            this.$emit('bullet-click', index);
        },
    },
};
</script>
<style lang="scss">
.formStyle42 .imgBulletGroup {
    width: 100%;
    position: relative;
    bottom: 0;
    z-index: 8;
    margin: 0.75rem 0;
    text-align: center;
    font-size: 0;
    line-height: 0;
}

/*样式1*/
.formStyle42 .imgBulletGroup1 .imgBullet {
    width: 0.45rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.5 all;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .imgBulletGroup1 .imgBullet .imgBulletBall {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .imgBulletGroup1 .imgBullet .imgBulletBall:not(.active) {
    background: rgba(217, 217, 217, 1);
    transform: scale(0.75);
}
.formStyle42 .imgBulletGroup1 .imgBullet.offsetBullect .imgBulletBall {
    transform: scale(0.5);
}
.formStyle42 .imgBulletGroup1 .bulletFade-enter-active {
    animation: imgBulletFadeIn 0.5s;
}
.formStyle42 .imgBulletGroup1 .bulletFade-leave-active {
    animation: imgBulletFadeLeave 0.5s;
}
@keyframes imgBulletFadeIn {
    0% {
        opacity: 0.5;
        transform: scale(0);
        width: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes imgBulletFadeLeave {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0.5;
        transform: scale(0);
        width: 0;
    }
}

.formStyle42 .innerBox .imgBulletGroup1 .imgBullet {
    width: 0.4rem;
}
.formStyle42 .innerBox .imgBulletGroup1 .imgBullet .imgBulletBall {
    background-color: rgba(255, 255, 255, 0.6);
}
.formStyle42 .innerBox .imgBullet .imgBulletBall.active {
    background-color: #fff;
}

/*样式2*/
.formStyle42 .innerBox .imgBulletGroup2 .imgBullet {
    width: 0.3rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.5 all;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .innerBox .imgBulletGroup2 .imgBullet.active {
    width: 0.65rem;
}
.formStyle42 .imgBulletGroup2 .imgBullet .imgBulletBall {
    width: 0.15rem;
    height: 0.15rem;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .innerBox .imgBulletGroup2 .imgBullet .imgBulletBall.active {
    width: 0.5rem;
    height: 0.15rem;
    border-radius: 0.15rem;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .innerBox .imgBulletGroup2 .imgBullet .imgBulletBall:not(.active) {
    background: rgba(255, 255, 255, 0.6);
}
.formStyle42 .outerBox .imgBulletGroup2 .imgBullet {
    width: 0.4rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.5 all;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .outerBox .imgBulletGroup2 .imgBullet.active {
    width: 0.9rem;
}
.formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall.active {
    width: 0.7rem;
    height: 0.2rem;
    border-radius: 0.3rem;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall:not(.active) {
    background: #d9d9d9;
}
.formStyle42 .imgBulletGroup2 .bulletFade-enter-active {
    animation: imgBulletFadeIn 0.5s;
}
.formStyle42 .imgBulletGroup2 .bulletFade-leave-active {
    animation: imgBulletFadeLeave 0.5s;
}

/*样式3*/
.formStyle42 .innerBox .imgBulletGroup3 {
    display: flex;
    align-items: center;
}
.formStyle42 .innerBox .imgBulletGroup3 .imgBullet {
    width: 0.35rem;
    height: 0.35rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.5 all;
    transform-origin: 50% 50%;
    opacity: 1;
    position: relative;
}
.formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall {
    width: 0.15rem;
    height: 0.15rem;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall.active {
    width: 0.125rem;
    height: 0.25rem;
    border-radius: 0.075rem;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
}
.formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall:not(.active) {
    background: rgba(255, 255, 255, 0.6);
}
.formStyle42 .innerBox .imgBulletGroup3 .bulletFade-enter-active {
    animation: imgBulletFadeIn 0.5s;
}
.formStyle42 .innerBox .imgBulletGroup3 .bulletFade-leave-active {
    animation: imgBulletFadeLeave 0.5s;
}

/*样式4*/
.formStyle42 .innerBox .imgBulletGroup4 {
    display: flex;
    align-items: center;
}
.formStyle42 .innerBox .imgBulletGroup4 .imgBullet {
    width: 0.45rem;
    height: 0.45rem;
    display: inline-block;
    cursor: pointer;
    transition: 0.5 all;
    transform-origin: 50% 50%;
    opacity: 1;
    position: relative;
}
.formStyle42 .innerBox .imgBulletGroup4 .imgBullet .imgBulletBall {
    border: 1px solid #fff;
    box-sizing: border-box;
    width: 0.25rem;
    height: 0.25rem;
    display: inline-block;
    cursor: pointer;
    transform-origin: 50% 50%;
    opacity: 1;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.formStyle42 .innerBox .imgBulletGroup4 .imgBullet .imgBulletBall:not(.active) {
    background: transparent;
    opacity: 0.6;
}
.formStyle42 .innerBox .imgBulletGroup4 .imgBullet.offsetBullect .imgBulletBall {
    transform: scale(0.75);
}
.formStyle42 .innerBox .imgBulletGroup4 .bulletFade-enter-active {
    animation: imgBulletFadeIn 0.5s;
}
.formStyle42 .innerBox .imgBulletGroup4 .bulletFade-leave-active {
    animation: imgBulletFadeLeave 0.5s;
}

/*样式5*/
.formStyle42 .innerBox .imgBulletGroup5 .bulletBox {
    display: inline-block;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.7rem;
}
.formStyle42 .innerBox .imgBulletGroup5 .bulletText {
    font-size: 0.4rem;
    color: #fff;
    padding: 0.15rem 0.25rem;
    height: 0.4rem;
    display: inline-block;
    vertical-align: middle;
}
.formStyle42 .innerBox .imgBulletGroup5 .bulletText .bulletTextSep {
    margin: 0 0.05rem;
}
.formStyle42 .innerBox .imgBulletGroup5 .bulletText::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
}

@media only screen and (max-width: 640px) {
    .formStyle42 .innerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 6px;
        height: 6px;
    }
    .formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 8px;
        height: 8px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall {
        width: 6px;
        height: 6px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall.active {
        width: 5px;
        height: 10px;
    }
    .formStyle42 .innerBox .imgBulletGroup4 .imgBullet .imgBulletBall {
        width: 10px;
        height: 10px;
    }
}
@media only screen and (max-width: 528px) {
    .formStyle42 .innerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 5px;
        height: 5px;
    }
    .formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 6px;
        height: 6px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall {
        width: 5px;
        height: 5px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall.active {
        width: 4px;
        height: 8px;
    }
    .formStyle42 .innerBox .imgBulletGroup4 .imgBullet .imgBulletBall {
        width: 8px;
        height: 8px;
    }
}
@media only screen and (max-width: 414px) {
    .formStyle42 .innerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 4px;
        height: 4px;
    }
    .formStyle42 .outerBox .imgBulletGroup2 .imgBullet .imgBulletBall {
        width: 5px;
        height: 5px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall {
        width: 4px;
        height: 4px;
    }
    .formStyle42 .innerBox .imgBulletGroup3 .imgBullet .imgBulletBall.active {
        width: 3px;
        height: 6px;
    }
    .formStyle42 .innerBox .imgBulletGroup4 .imgBullet .imgBulletBall {
        width: 6px;
        height: 6px;
    }
}
</style>
