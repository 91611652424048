import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import ButtonView from './module/button.vue';

class _Button extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.FLOAT_BTN, options);
    }

    render() {
        return ButtonView;
    }
}

export const Button = new _Button();
