<template>
    <module-frame :module-id="moduleId">
        <div class="photoModule styleForm6 g_background" style="margin: 0">
            <div :id="photoSwipeId" class="photoSwipe">
                <div class="photoSwipeBox">
                    <div v-for="(photo, index) in photoList" :key="photo.id" class="swipImgArea imageDiv">
                        <a hidefocus="true">
                            <span class="imageMiddleSpan"></span>
                            <img
                                :src="photo.picThumbPath"
                                :vwidth="photo.rect640Width"
                                :vheight="photo.rect640Height"
                                :alt="photo.id"
                                :photoIndex="index"
                                :linkType="pLinkType"
                            />
                        </a>
                    </div>
                </div>
                <div :id="photoBulletId" class="photoBullet noBG">
                    <ul :id="'bullets' + moduleId" class="bullets">
                        <div :id="'imgName' + moduleId" class="imgName"></div>
                        <li
                            v-for="(photo, j) in photoList.slice(0, maxCount)"
                            :key="photo.id"
                            :class="{ on: j === 0 }"
                        ></li>
                    </ul>
                </div>
            </div>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';

import { PhotoCarouselDomService } from './dom-service';

export default {
    name: 'PhotoCarouselView',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        photoSwipeId() {
            return `photoSwipe${this.moduleId}`;
        },
        photoBulletId() {
            return `photoBullet${this.moduleId}`;
        },
        maxCount() {
            return this.module?.extInfo?.maxCount || 0;
        },
        photoList() {
            return this.module?.extInfo?.photoList || [];
        },
        pLinkType() {
            return this.module?.extInfo?.pLinkType || -1;
        },
    },
    mounted() {
        if (this.photoList.length > 1) {
            PhotoCarouselDomService.init(this.moduleId);
        }
    },
};
</script>

<style></style>
