import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import NewsGroupView from './module/news-group.vue';

class _NewsGroup extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.NEWS_GROUP, options);
    }

    render() {
        return NewsGroupView;
    }
}

export const NewsGroup = new _NewsGroup();
