<template>
    <div :id="'customSearchContainer' + module.id" class="m_custom_search_result J_customSearch">
        <template v-if="showModule">
            <template v-if="render">
                <template v-if="!hideEntry">
                    <div class="m_custom_search m_custom_search_entrance">
                        <div v-if="!hideTitle" class="m_custom_search_projectName">{{ project.name }}</div>
                        <div v-if="!hideTips" class="m_custom_search_projectTips">{{ project.tip }}</div>
                        <div class="m_custom_search_field">
                            <div v-for="item in itemListCondited" :key="item.id" class="m_custom_search_field_item">
                                <div class="m_custom_search_field_label"
                                    ><span v-if="item.isRequired" class="m_custom_search_star">*</span
                                    >{{ item.itemName.includes('：') ? item.itemName : item.itemName + ':' }}</div
                                >
                                <CustomSearchItem
                                    :item="item"
                                    :form-info="formInfo"
                                    :module="module"
                                    :func-type="funcType"
                                    :mobile-ct-list="mobileCtList"
                                    :select-place-holder="selectPlaceHolder('')"
                                    :input-place-holder="inputPlaceHolder('')"
                                    @on-input="onInput"
                                />
                            </div>
                        </div>
                        <div class="m_custom_search_submit_wrap">
                            <button
                                class="m_custom_search_submit g_main_bgColor_v3 g_oldThemeBgColor J_customSearchSubmit"
                                @click="onSubmit"
                                >{{ lanConf.customSearchText }}</button
                            >
                        </div>
                    </div>
                    <div class="m_custom_search_result_result_split" style="background-color: #f5f5f5; height: 0.8rem">
                    </div>
                </template>
                <div class="m_custom_search_result_result">
                    <div class="m_custom_search_result_result_title">{{ lanConf.customSearchResult }}</div>
                    <div v-if="!hideResultExplain && module.isNotAuth" class="m_custom_search_result_result_explain">{{
                        project.resultExplain
                    }}</div>
                    <div
                        v-if="totalSize > 0"
                        class="m_custom_search__result_result_projectDataTips"
                        v-html="totalSizeTips(totalSize)"
                    ></div>
                    <div class="m_custom_search_result_result_area">
                        <div v-if="resultInfo.length < 1" class="m_custom_search_result_empty">
                            <div class="m_custom_search_result_empty_icon"></div>
                            <div
                                v-if="resultTips"
                                class="m_custom_search_result_empty_tips"
                                @click="handleLogin"
                                v-html="resultTips"
                            ></div>
                        </div>
                        <div
                            v-for="itemData in resultInfo"
                            v-else
                            :key="itemData.id"
                            class="m_custom_search_result_result_data"
                        >
                            <div
                                v-for="(data, index) in filterPhotoItem(itemData)"
                                :key="index"
                                class="m_custom_search_result_result_item"
                            >
                                <div class="m_custom_search_result_result_label">{{
                                    data.type == 5 && funcType == 1 ? data.headline : data.itemName
                                }}</div>
                                <div
                                    v-if="data.type === 4 && funcType == 0"
                                    class="m_custom_search_result_result_value m_custom_search_result_result_photo_wrap"
                                >
                                    <div
                                        class="m_custom_search_result_result_photo"
                                        :class="photoScaleClass"
                                        :style="genPhotoStyle(data)"
                                        :data-picid="data.value"
                                        @click="openPictureSlide(itemData, data.id)"
                                    ></div>
                                </div>
                                <file-view
                                    v-else-if="data.type == 5 && funcType == 0"
                                    :img-class="photoScaleClass"
                                    :img-style="{
                                        'background-image': 'url(' + data.path + '})',
                                    }"
                                    :file-item="data"
                                    @img-click="openPictureSlide(itemData, data.id)"
                                ></file-view>
                                <template v-else-if="data.type == 7 && funcType == 1">
                                    <div
                                        class="m_custom_search_result_result_value m_custom_search_result_result_photo_wrap"
                                    >
                                        <template v-for="fileItem in data.fileList">
                                            <div
                                                v-if="fileItem.isImage"
                                                :key="fileItem.name"
                                                :class="photoScaleClass"
                                                :data-picid="fileItem.value"
                                                class="m_custom_search_result_result_ellipsis"
                                                @click="openPictureSlide(itemData, data.id)"
                                                >{{ fileItem.fileName }}</div
                                            >
                                            <a
                                                v-else
                                                :key="fileItem.name"
                                                hidefocus="true"
                                                rel="nofollow"
                                                target="_blank"
                                                class="m_custom_search_result_result_ellipsis"
                                                :href="fileItem.isPdf ? fileItem.path : fileItem.downloadPath"
                                            >
                                                {{ fileItem.fileName }}
                                            </a>
                                        </template>
                                    </div>
                                </template>
                                <div
                                    v-else-if="(data.type == 5 || data.type == 1) && funcType == 1"
                                    class="m_custom_search_result_result_value"
                                    v-html="encodeHtml(data.type == 5 && funcType == 1 ? data.input : data.value)"
                                ></div>
                                <div
                                    v-else-if="(data.type == 14 || data.type == 15) && funcType == 1"
                                    class="m_custom_search_result_result_value item_radio_pic_vertical"
                                >
                                    <div v-for="(pic, index) in getPicList(data.value)" class="radio_pic_item">
                                        <div
                                            class="radio_pic_item_pic"
                                            :style="'background-image:url(' + pic.src + ')'"
                                            @click.stop="previewPic(getPicList(data.value), pic, index)"
                                        ></div>
                                        <div class="radio_pic_item_name">{{ pic.name }}</div>
                                    </div>
                                </div>
                                <div v-else class="m_custom_search_result_result_value">{{ data.value }}</div>
                            </div>
                        </div>
                        <div class="m_custom_search_result_result_pageNation">
                            <span class="m_custom_search_result_result_prev"></span>
                            <span class="m_custom_search_result_result_next"></span>
                        </div>
                    </div>
                </div>
                <pagenation
                    v-if="totalSize > 5"
                    class="m_custom_search_result_result_pagenation"
                    :module-id="id"
                    :total-size="totalSize"
                    :page-size="5"
                    :pageno="pageno"
                    :open-theme-v3="openThemeV3"
                    :ajax-pagenation="true"
                    @on-change="changePage"
                ></pagenation>
            </template>
            <template v-else>
                <div class="m_custom_search_no_project_box">
                    <div class="m_custom_search_no_project_icon m_custom_search_no_project"></div>
                    <div class="m_custom_search_no_project_tip">{{ LS.mobiSearchResult }}</div>
                </div>
            </template>
        </template>
        <div v-else style="height: 180px">
            <loading />
        </div>
    </div>
</template>
<script>
import pagenation from '@/modules/customSearchResult/module/components/pagenation.vue';
import { encodeHtml } from '@/shared/util';
import { showTopManageFrame } from '@/shared/frame';
import { ing } from '@/shared/tips';
import { logDog, logJZFdpList } from '@/api/logDog';
import { mapState } from 'vuex';
import { changeHref } from '@/features/router/universal';
import { request } from '@/api/request';
import { mapActions } from 'vuex';
import { getRouterData } from '@/features/router/universal';
import { slideshowView } from '@/shared/photo/slides.js';
import { customSearchMixin } from '../../customSearchMixin.js';
import loading from '@/components/loading/loading.vue';
import { format } from '@/shared/fai';
import FileView from './components/fileView.vue';
/* eslint-disable no-duplicate-case */
export default {
    name: 'CustomSearchResultEntrance',
    components: {
        pagenation,
        loading,
        FileView,
    },
    mixins: [customSearchMixin],
    data() {
        return {
            loading: false,
            pageno: 1,
            timer: null,
            mId: 0,
            showModule: false,
        };
    },
    computed: {
        ...mapState('customSearchResult', ['moduleData']),
        ...mapState('app', ['LS']),
        options() {
            return {
                args: {
                    searchData: '',
                },
            };
        },
        args() {
            return this.moduleData?.args ?? {};
        },
        render() {
            return this.args?.searchData;
        },
        resultData() {
            return this.args?.resultData || [];
        },
        resultTips() {
            return this.args?.resultTips;
        },
        tmpOtions() {
            return this.render ? this.args?.searchData?.condition : {};
        },
        totalSize() {
            return this.args.totalSize;
        },
        projectID() {
            return this.project.id;
        },
        openSearch() {
            return this.checkBit(this.project.flag, 0x1);
        },
        resultInfo() {
            var resultInfo = [],
                _self = this;
            if (!Array.isArray(this.resultData)) {
                return resultInfo;
            }
            if (this.funcType) {
                return this.resultData.map((result) =>
                    this.formConditionItemList
                        .map(({ id, name, type, s }) => {
                            const submitData = result['submitList'].filter((value) => value.id === id)[0] || {};
                            const dateStr = ' 至 ';
                            const textDescription = this.formTextDescriptionItemList.filter(
                                (value) => value.id === id
                            )[0] || {
                                headline: '',
                                input: '',
                            };
                            let fileList = [];
                            if (type === 7) {
                                fileList = submitData?.['fileItemList'] || [];
                                fileList.forEach((file) => {
                                    file.isImage =
                                        /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/.test(
                                            file?.fileName
                                        );
                                    file.isPdf = /\.(pdf|PDF)$/.test(file?.fileName);
                                });
                            }
                            return type != 7
                                ? {
                                      id,
                                      itemName: name,
                                      type,
                                      value:
                                          dateStr.includes(submitData.val) && submitData.val.length <= dateStr.length
                                              ? submitData.val.replace(dateStr, '')
                                              : submitData.val,
                                      isDefault: !s,
                                      headline: textDescription.headline,
                                      input: textDescription.input,
                                  }
                                : {
                                      id,
                                      itemName: name,
                                      type,
                                      value: submitData.val,
                                      fileList,
                                      isDefault: !s, // true : 表示没找到图片
                                  };
                        })
                        .filter(({ isDefault }) => !isDefault)
                );
            } else {
                this.resultData.forEach(function (item) {
                    var valueList = [];
                    _self.itemList.forEach(function (childItem) {
                        if (childItem.isShow) {
                            if (childItem.type == 5) {
                                let { id, itemName, type } = childItem;
                                let fileItem = item[`fileItem${id}`];
                                let fileName = fileItem.fileName;
                                let value = item[`prop${id}`];
                                valueList.push({
                                    id: id,
                                    type: type,
                                    itemName: itemName,
                                    value: value,
                                    path: fileItem.path || ``,
                                    downloadPath: fileItem.downloadPath || ``,
                                    fileName: fileItem.fileName || ``,
                                    isDefault: fileItem.isDefault,
                                    isImage: /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/.test(
                                        fileName
                                    ),
                                    isPdf: /\.(pdf|PDF)$/.test(fileName),
                                });
                            } else {
                                valueList.push({
                                    id: childItem.id,
                                    type: childItem.type,
                                    itemName: childItem.itemName,
                                    value: item['prop' + childItem.id],
                                    fileItem: item[`fileItem${childItem.id}`],
                                    isImage: !!item[`fileItem${childItem.id}`],
                                    isPdf: false,
                                    path: item?.[`fileItem${childItem.id}`]?.path ?? '',
                                    fileName: item?.[`fileItem${childItem.id}`]?.fileName ?? '',
                                });
                            }
                        }
                    });
                    resultInfo.push(valueList);
                });
                return resultInfo;
            }
        },
        hideEntry() {
            return this.module.content.hentry;
        },
        hideTitle() {
            return this.module.content.htit;
        },
        hideTips() {
            return this.module.content.htips;
        },
        hideResultExplain() {
            return this.module.content.hre;
        },
        projectOpen() {
            return this.checkBit(this.project.flag, 0x1);
        },
        photoScaleClass() {
            const scalceMap = {
                0: 'm_custom_search_result_result_photo_default',
                1: 'm_custom_search_result_result_photo_equalRatioScale',
                2: 'm_custom_search_result_result_photo_stretchScale',
                3: 'm_custom_search_result_result_photo_tile',
            };
            return scalceMap[this.module.content.ist];
        },
    },
    watch: {},
    mounted() {
        let routerData = getRouterData();
        this.mId = routerData.mId || 0;
        this.callgetCustomSearchResultInfo().then(() => {
            this.showModule = true;
            if (!this.project?.itemList) return;
            let fdpList = this.createFdpList();
            logJZFdpList(fdpList);
        });
    },
    methods: {
        totalSizeTips(totalSize = 0) {
            return format(
                this?.LS?.customSearchQueryNumber ?? '',
                `<span class="g_main_color_v3"
                            >${totalSize}</span
                        >`
            );
        },
        createFdpList() {
            const { itemList, funcSetting } = this.project;
            const currentItemList = this.funcType ? funcSetting?.itemList : itemList;
            let list = [];
            if (currentItemList) {
                let typeList = new Set();
                currentItemList
                    .filter(({ isShow, s }) => s || isShow)
                    .forEach((item) => {
                        let typeName = this.getProjectType2Name(item.type);
                        typeList.add(typeName);
                    });

                list = Array.from(typeList).map((type) => {
                    return {
                        event: 'jz_query_result_view',
                        properties: {
                            jz_content_terminal: 'mobi',
                            jz_free_bool1: Boolean(this.resultData.length > 0),
                            jz_free_text1: this.funcType ? '表单查询' : '信息查询',
                            jz_free_text2: type,
                        },
                    };
                });
            }
            return list;
        },
        getProjectType2Name(t) {
            let text = '';
            if (this.funcType == 0) {
                switch (t) {
                    case 1:
                        text = '单选按钮';
                        break;
                    case 2:
                        text = '多选按钮';
                        break;
                    case 3:
                        text = '下拉选项';
                        break;
                    case 4:
                        text = '图片';
                        break;
                    case 5:
                        text = '文件';
                        break;
                    default:
                        text = '单行文本';
                }
                return text;
            }
            switch (t) {
                case 0:
                    text = '单行文本';
                    break;
                case 1:
                    text = '多行文本';
                    break;
                case 2:
                    text = '单选按钮';
                    break;
                case 3:
                    text = '多选按钮';
                    break;
                case 4:
                    text = '下拉列表';
                    break;
                case 6:
                    text = '日期选项';
                    break;
                case 8:
                    text = '手机认证';
                    break;
                case 9:
                    text = '邮箱认证';
                    break;
                case 10:
                    text = '身份认证';
                    break;
                case 11:
                    text = '省市县区';
                    break;
                case 12:
                    text = '多级下拉';
                    break;
                default:
                    text = '-';
                    break;
            }
            return text;
        },
        getItem(projectInfo) {
            let text = '';
            const { itemList, funcSetting } = projectInfo;
            const currentItemList = this.funcType ? funcSetting?.itemList : itemList;
            currentItemList
                ?.filter(({ isShow, s }) => s || isShow)
                ?.forEach(({ type }) => {
                    text += ' ' + this.getProjectType2Name(type);
                });
            return text;
        },
        ...mapActions('customSearchResult', ['loadCustomSearchResultInfo']),
        addSearch() {
            showTopManageFrame('manageFrameSearchProjectAdd', Fai.top.backUrl, Fai.top.lanCode);
        },
        inputPlaceHolder(name) {
            return this.lanConf.mobi_member_PleaseInput + name;
        },
        selectPlaceHolder(name) {
            return this.lanConf.siteFormPleaseSelect + name;
        },
        onSubmit() {
            if (!Fai.top._openMsgSearch) {
                //没有开通查询功能
                ing('功能不可用，如需继续使用请联系网站管理员');
                return;
            }
            if (this.useManageMode && this.$designer.checkSaveBar()) {
                return;
            }
            if (!this.projectOpen) {
                ing(LS.js_customSearchNoOpen);
                return;
            }
            var projectId = this.projectID;
            var param = {};
            var condition = [];
            var canCommit = true;
            param.searchId = projectId;
            this.itemListCondited.forEach((item) => {
                var type = item.type,
                    isRequired = item.isRequired,
                    propId = item.id,
                    value = item.value,
                    _value = '',
                    typeName = this.getSearchType(type);
                switch (typeName) {
                    case 'singleLine':
                    case 'phone':
                    case 'mail':
                    case 'identity':
                    case 'dropdown':
                    case 'radio':
                    case 'multiLine':
                    case 'address':
                    case 'date':
                    case 'phone':
                        _value = value;
                        if (value == '') {
                            if (isRequired) {
                                ing(LS.customSearchEmpty);
                                canCommit = false;
                            }
                        }
                        break;
                    case 'multipleChoice':
                        _value = value.join('\n');
                        if (value.length < 1) {
                            if (isRequired) {
                                ing(LS.customSearchEmpty);
                                canCommit = false;
                            }
                        }
                        break;
                    case 'multiDropdown': // 多级下拉
                        {
                            const {
                                firstLevel = {},
                                secondLevel = {},
                                thirdLevel = {},
                                fourthLevel = {},
                            } = value || {};
                            let result = '';
                            result = firstLevel.val || '';
                            if (result.length < 1) {
                                if (isRequired) {
                                    ing(LS.customSearchEmpty);
                                    canCommit = false;
                                }
                            }
                            !!secondLevel.val && (result += `-${secondLevel.val}`);
                            !!thirdLevel.val && (result += `-${thirdLevel.val}`);
                            !!fourthLevel.val && (result += `-${fourthLevel.val}`);
                            _value = result;
                        }
                        break;
                }

                if (_value && _value.length > 0) {
                    condition.push({
                        prop: 'prop' + propId,
                        type: type,
                        value: _value,
                    });
                }
            });
            if (this.funcType && !condition.length) {
                if (this.itemListCondited.length > 1) {
                    ing(LS.js_customSearch_condition, false);
                    canCommit = false;
                } else {
                    this.itemListCondited.length &&
                        ing(this.format(LS.siteFormSubmitInputIsEmpty, this.itemListCondited[0].name));
                    canCommit = false;
                }
            }
            if (!canCommit) {
                return;
            }
            logDog(201006, 3);
            param.condition = condition;

            changeHref(
                'csr.jsp?id=' +
                    projectId +
                    '&mId=' +
                    this.mId +
                    '&searchData=' +
                    encodeURIComponent(JSON.stringify(param))
            );
        },
        checkBit(flag, bitFlag) {
            var bit_31 = true;
            //31位皆置1为：2147483647
            if (flag > 2147483647 || flag < 0 || bitFlag > 2147483647 || bitFlag < 0) {
                bit_31 = false;
            }
            if (bit_31) {
                return (flag & bitFlag) == bitFlag;
            }
            var flagBinary = flag.toString(2);
            var bitFlagBinary = bitFlag.toString(2);
            if (flagBinary.length > 62 || bitFlagBinary.length > 62) {
                alert(
                    'Does not support more than 62 bit. flagBinary.length=' +
                        flagBinary.length +
                        ',bitFlagBinary.length' +
                        bitFlagBinary.length +
                        '.'
                );
                return false;
            }
            var flagLow;
            var bitFlagHight;
            var bitFlagLow;
            var flagHight;
            var lowStr;
            var hightStr;
            flagHight = flagLow = bitFlagHight = bitFlagLow = 0;
            //拆分高低位处理
            if (flagBinary.length > 31) {
                hightStr = flagBinary.slice(0, flagBinary.length - 31);
                lowStr = flagBinary.slice(flagBinary.length - 31);
                flagHight = parseInt(hightStr, '2');
                flagLow = parseInt(lowStr, '2');
            } else {
                flagLow = parseInt(flagBinary.slice(0, flagBinary.length), '2');
            }
            if (bitFlagBinary.length > 31) {
                hightStr = bitFlagBinary.slice(0, bitFlagBinary.length - 31);
                lowStr = bitFlagBinary.slice(bitFlagBinary.length - 31);
                bitFlagHight = parseInt(hightStr, '2');
                bitFlagLow = parseInt(lowStr, '2');
            } else {
                bitFlagLow = parseInt(bitFlagBinary.slice(0, bitFlagBinary.length), '2');
            }

            var result = (flagLow & bitFlagLow) == bitFlagLow;
            if (result) {
                result = (flagHight & bitFlagHight) == bitFlagHight;
            }
            return result;
        },
        changeValidator() {
            this.validatorImgSrc = `validateCode.jsp?random=${parseInt(Math.random() * 1000)}&vCodeId=${
                this.module.id
            }`;
        },
        changePage(pageno) {
            if (this.loading) {
                return;
            }
            var _self = this;
            this.loading = true;

            _self.timer = setTimeout(function () {
                _self.showPageCover();
            }, 2000);

            var params = [];
            params.push('&condition=' + encodeURIComponent(JSON.stringify(this.tmpOtions)));
            params.push('&pageNum=' + pageno);
            params.push('&searchId=' + this.projectID);

            request
                .post('/ajax/siteCustomSearch_h.jsp?cmd=getWafNotCk_searchData', {
                    data: params.join(''),
                })
                .catch(function () {
                    ing(LS.js_systemError);
                    _self.loading = false;
                    clearTimeout(_self.timer);
                    _self.removeCover();
                })
                .then(function (response) {
                    let { data } = response;
                    if (data.success) {
                        _self.args.resultData = data.resultData;
                        _self.loading = false;
                        clearTimeout(_self.timer);
                        _self.removeCover();
                        _self.pageno = pageno;
                    } else {
                        ing(LS.js_systemError, 2);
                        _self.loading = false;
                        clearTimeout(_self.timer);
                        _self.removeCover();
                    }
                });
        },
        showPageCover() {
            if (jm('#customSearchResultCover').length) {
                return;
            }
            var html = '';
            html += "<div id='customSearchResultCover' class='m_custom_search_result_result_pageCover'>";
            html += "<div class='loadingImg'></div>";
            html += '</div>';
            jm('#g_web').append(html);
        },
        removeCover() {
            if (jm('#customSearchResultCover').length) {
                jm('#customSearchResultCover').remove();
            }
        },
        handleLogin(event) {
            if (
                event &&
                event.target &&
                event.target.classList &&
                event.target.classList.contains('m_custom_search_result_result_login')
            ) {
                const href = event.target.getAttribute('href');
                if (this.useManageMode) {
                    changeHref(href);
                    return;
                }
                const backUrl = encodeURIComponent(window.location.href);
                changeHref(`${href}?returnUrl=${backUrl}`);
            }
        },
        genPhotoStyle(data) {
            return {
                'background-image': `url(${data.fileItem.path})`,
            };
        },
        flat(arr) {
            const result = [];
            const stack = [].concat(arr); // 将数组元素拷贝至栈，直接赋值会改变原数组
            //如果栈不为空，则循环遍历
            while (stack.length !== 0) {
                const val = stack.pop();
                if (Array.isArray(val)) {
                    stack.push(...val); //如果是数组再次入栈，并且展开了一层
                } else {
                    result.unshift(val); //如果不是数组就将其取出来放入结果数组中
                }
            }
            return result;
        },
        openPictureSlide(data, id) {
            const imageData = this.filterPhotoItem(data).filter((value) =>
                this.funcType == 1 ? value.fileList : value.isImage
            );
            let previewSrcList = imageData;
            let initialIndex = 0;
            if (this.funcType == 1) {
                previewSrcList = [];
                imageData.forEach((value) => {
                    value.src = value.path;
                    value.title = value.fileName;
                    if (value.fileList) {
                        value.fileList.forEach((file) => {
                            file.isImage && previewSrcList.push(file);
                        });
                    }
                });
                previewSrcList.forEach((value, index) => {
                    value.src = value.path;
                    value.title = value.fileName;
                    if (id == value.id) {
                        initialIndex = index;
                    }
                });
            } else {
                previewSrcList.forEach((value, index) => {
                    value.src = value.path;
                    value.title = value.fileName;
                    if (id == value.id) {
                        initialIndex = index;
                    }
                });
            }
            let options = {
                id: `${this.id}_${id}_${this.projectID}`,
                previewSrcList,
                zIndex: 9999,
                initialIndex,
                logEventConfig: {
                    event: 'jz_hdpzj',
                    properties: {
                        jz_content_terminal: 'mobi-在线查询结果',
                        jz_version: Fai.top.jzVersion,
                    },
                },
            };
            slideshowView(options);
        },
        filterPhotoItem(itemData) {
            // 图片项只在建站显示，而且图片项默认时，也不显示
            return itemData.filter((data) => {
                return (
                    ![4, 5].includes(data.type) ||
                    !!this.funcType ||
                    (data.type === 4 && data.fileItem && !data.fileItem.isDefault) ||
                    (data.type == 5 && data.fileName != '')
                );
            });
        },
        encodeHtml,
        callgetCustomSearchResultInfo() {
            let routerData = getRouterData();
            const { id, searchData } = routerData;
            if (searchData == null) {
                return Promise.resolve();
            } else {
                let searchDataJson = JSON.parse(searchData);
                return this.loadCustomSearchResultInfo({
                    projectId: id || searchDataJson.searchId,
                    searchData: JSON.parse(searchData),
                    moduleId: this.moduleId,
                    mId: this.mId,
                });
            }
        },
        getPicList(value) {
            let picList = [];
            try {
                picList = JSON.parse(value);
            } catch (e) {
                console.log(e);
            }
            return picList;
        },
        previewPic(list, pic, index) {
            const initialIndex = index;
            console.log(list, pic, index);
            slideshowView({
                id: pic.id,
                previewSrcList: list.map((item) => ({
                    src: item.src,
                    title: '',
                    description: '',
                })),
                initialIndex,
            });
        },
    },
};
</script>
<style lang="scss">
.m_custom_search_result {
    // background-color: #f5f5f5;
}

.m_custom_search_entrance,
.m_custom_search_result_result {
    background-color: transparent;
}

// .m_custom_search_entrance {
//     margin-bottom: 0.8rem;
// }
.m_custom_search_result_result {
    padding: 1.2rem 0.5rem 1.5rem 0.5rem;
}
.m_custom_search_result_result_title {
    font-size: 0.78rem;
    color: #333;
    text-align: center;
}
.m_custom_search_result_result_explain {
    font-size: 0.6rem;
    color: #999;
    margin-top: 0.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
}
.m_custom_search_result_result_area {
    margin-top: 0.8rem;
}
.m_custom_search_result_result_data {
    display: table;
    width: 100%;
    margin-top: 0.5rem;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.m_custom_search_result_result_item {
    display: table;
    width: 100%;
}

.m_custom_search_result_result_label,
.m_custom_search_result_result_value {
    display: table-cell;
    width: 50%;
    text-align: center;
    height: 1.25rem;
    vertical-align: middle;
    font-size: 0.55rem;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    word-break: break-word;
}
.m_custom_search_result_empty {
    text-align: center;
}
.m_custom_search_result_empty_icon {
    width: 12rem;
    height: 7.25rem;
    background-image: url(/image/v2/void_content.png?v=201904151129);
    margin: 1.5rem 0 1rem 0;
    display: inline-block;
    background-size: cover;
}

.m_custom_search_result_empty_tips {
    font-size: 0.7rem;
    text-align: center;
    color: #333333;
}
.m_custom_search_result .m_custom_search_result_result .m_custom_search_result_result_login {
    color: #5874d8;
    text-decoration: underline;
}
.m_custom_search_result_result_pagenation {
    padding: 0;
    margin-top: -1rem;
    background-color: #fff;
}
.m_custom_search_result_result_pageCover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 99;
}
.loadingImg {
    height: 100%;
    width: 100%;
    background: url(/image/loading/loading_2.gif?v=201610211723) no-repeat center;
}
.m_custom_search_no_project_box {
    background-color: #fff;
}
.m_custom_search_no_project_icon {
    background: url(/image/v2/defaultIcon10.png?v=201711250607) no-repeat center center;
    background-size: contain;
}

.m_custom_search_no_project {
    width: 5.5rem;
    height: 5rem;
    line-height: 6.4rem;
    margin: 2.9rem auto 0.65rem auto;
    text-align: center;
    background-color: #fff;
}

.m_custom_search_no_project_tip {
    margin-bottom: 2.5rem;
    font-size: 0.6rem;
    color: #666;
    text-align: center;
}
.m_custom_search_result_result_photo_wrap {
    height: auto;
}
.m_custom_search_result_result_photo {
    width: 6.25rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    margin: 0.25rem auto;
    cursor: zoom-in;
}
.m_custom_search_result_result_ellipsis {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding: 0 0.35rem;
    box-sizing: border-box;
}
.m_custom_search_result_result_photo_default {
    background-size: cover;
    background-position: 50% 50%;
}
.m_custom_search_result_result_photo_equalRatioScale {
    background-size: contain;
    background-position: 50% 50%;
}
.m_custom_search_result_result_photo_stretchScale {
    background-size: 100% 100%;
}
.m_custom_search_result_result_photo_tile {
    background-position: 50% 50%;
}
.m_custom_search__result_result_projectDataTips {
    color: #333;
    text-align: left;
    font-size: 0.6rem;
}
</style>
