import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import FloatImgView from './module/float-img.vue';

class _FloatImg extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.FLOAT_IMG, options);
    }

    render() {
        return FloatImgView;
    }
}

export const FloatImg = new _FloatImg();
