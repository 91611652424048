<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" class="fileDownloadPanel">
            <template v-if="!viewList.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板中添加文件</div>
                    <button-component active @click.stop="addFile">添加文件</button-component>
                </div>
            </template>
            <template v-else-if="!viewList.length && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加文件</div>
                </div>
            </template>
            <template v-else>
                <div class="fileDownloadPanel newFileDownloadPanel">
                    <div v-for="(item, index) in viewList" :key="index" class="mainBodyContainer tableBox">
                        <div class="fileImg tableCell">
                            <svg role="img" class="fileSvgClass" :class="svgItem(item).svgLink">
                                <use
                                    :style="svgItem(item).style"
                                    fill="#fff"
                                    :xlink:href="'#' + svgItem(item).svgLink"
                                ></use>
                            </svg>
                        </div>
                        <div v-if="nameWrap" class="fileName tableCell">
                            <div
                                class="fileNameText fileNameWrap g_file_name"
                                :title="item.name"
                                :style="file_name_style"
                                >{{ item.name }}</div
                            >
                            <div class="fileSize g_file_size" :style="file_size_style">{{
                                parseFileSize(item.size)
                            }}</div>
                        </div>
                        <div v-else class="fileName tableCell">
                            <div class="fileNameText g_file_name" :title="item.name" :style="file_name_style">{{
                                item.name
                            }}</div>
                            <div class="fileSize g_file_size" :style="file_size_style">{{
                                parseFileSize(item.size)
                            }}</div>
                        </div>
                        <div
                            v-if="/.pdf$/.test(item.name) && !module.content.isAndroid"
                            class="fileViewWrap"
                            :title="item.name"
                            @click="logViewFile"
                        >
                            <a
                                target="_blank"
                                class="fileViewIcon"
                                href="javascript:void(0);"
                                @click="checkPreviewPdf($event, item.sourcePath)"
                            >
                                <svg class="jzm-vicon_preview downloadSvg previewSvg" :style="downloadIconSize">
                                    <use xlink:href="#jzm-vicon_preview" :style="downloadPreviewColor" />
                                </svg>
                            </a>
                        </div>
                        <template v-if="allowedMemberDownload">
                            <template v-if="showMemberLevel == 1">
                                <template v-if="sessionMid <= 0">
                                    <div class="fileOperate tableCell">
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_self"
                                            @click="jumptoIndex(item)"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        v-if="
                                            (visitorMemberLevel < memberLevelLimit && !onlyLevel) ||
                                            (onlyLevel && visitorMemberLevel != memberLevelLimit)
                                        "
                                        class="fileOperate tableCell"
                                    >
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_self"
                                            @click="levelLimit"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                    <div v-else class="fileOperate tableCell">
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_blank"
                                            :href="item.downloadUrl"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                </template>
                            </template>
                            <template v-else-if="showMemberLevel == 2">
                                <template v-if="sessionMid <= 0">
                                    <div class="fileOperate tableCell">
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_self"
                                            @click="jumptoIndex(item)"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                </template>
                                <template v-else>
                                    <div v-if="!isFileMemberGroup" class="fileOperate tableCell">
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_self"
                                            @click="groupLimit"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                    <div v-else class="fileOperate tableCell">
                                        <a
                                            :id="pinModuleId(index)"
                                            :class="'fileDownload' + id"
                                            target="_blank"
                                            :href="item.downloadUrl"
                                            @click="handleDownload(item, $event)"
                                        >
                                            <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                                <use
                                                    xlink:href="#jzm-v16"
                                                    style="fill: #666666"
                                                    :style="downloadIconColor"
                                                ></use>
                                            </svg>
                                        </a>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="fileOperate tableCell">
                                    <a
                                        :id="pinModuleId(index)"
                                        :class="'fileDownload' + id"
                                        target="_blank"
                                        :href="item.downloadUrl"
                                        @click="handleDownload(item, $event)"
                                    >
                                        <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                            <use
                                                xlink:href="#jzm-v16"
                                                style="fill: #666666"
                                                :style="downloadIconColor"
                                            ></use>
                                        </svg>
                                    </a>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="fileOperate tableCell">
                                <a
                                    :id="pinModuleId(index)"
                                    :class="'fileDownload' + id"
                                    target="_blank"
                                    :href="item.downloadUrl"
                                    @click="handleDownload(item, $event)"
                                >
                                    <svg role="img" class="jzm-v16 downloadSvg" :style="downloadIconSize">
                                        <use
                                            xlink:href="#jzm-v16"
                                            style="fill: #666666"
                                            :style="downloadIconColor"
                                        ></use>
                                    </svg>
                                </a>
                            </div>
                        </template>
                    </div>
                    <pagenation
                        v-if="module.content.showPage"
                        :module-id="id"
                        :total-size="totalSize"
                        :page-size="pageSize"
                        :pageno="pageno"
                        :open-theme-v3="openThemeV3"
                        :ajax-pagenation="true"
                        @on-change="changePage"
                        @click.native.stop=""
                    >
                    </pagenation>
                </div>
            </template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import Pagenation from '@/components/pagenation.vue';
import { mapGetters, mapState } from 'vuex';
import { ing } from '@/shared/tips';
import { logDog } from '@/shared/log';
import { MODULE_STYLE } from '@/def/module';

import { changeHref } from '@/features/router/universal';
import { encodeHtml } from '@/shared/util';
import { preprocessUrl } from '@/shared/url';
import { parseFileSize, mobiIng } from '@/shared/fai';
import { copyDataToClipboard } from '@/shared/clipboard';
import { fileDownloadCdnLimitHandler } from '@/shared/fileDownload';
export default {
    name: 'FileDownload',
    components: {
        Pagenation,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let curPageno = 1;
        return {
            render: true,
            pageno: curPageno,
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['openThemeV3']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        id() {
            return this.moduleId;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        modulePattern() {
            return this.module.pattern;
        },
        styleId() {
            return this.module.style;
        },
        // viewList是真正渲染在页面上的list，根据分页每一页显示多少个来截取
        // allList是一共上传了多少个文件
        // fileList是所有文件的id数组
        viewList: function () {
            // 如果开启分页，真正显示在页面上的数据，是根据分页每一页显示多少个来决定的
            if (this.module.content.showPage) {
                const pageSize = this.module.content.count;
                const start = Math.max(pageSize * (this.pageno - 1), 0);
                const end = start + pageSize;
                return this.module.content.allList.slice(start, end);
            }
            // 如果没有开启分页，则显示全部数据
            else {
                return this.module.content.allList;
            }
        },
        allList: function () {
            return this.module.content.allList;
        },
        nameWrap: function () {
            return this.module.content.nameWrap;
        },
        allowedMemberDownload: function () {
            return this.module.content.allowedMemberDownload;
        },
        downloadUrl: function () {
            return this.module.content.downloadUrl;
        },
        showMemberLevel: function () {
            return this.module.content.showMemberLevel;
        },
        sessionMid: function () {
            return this.module.content.sessionMid;
        },
        visitorMemberLevel: function () {
            return this.module.content.visitorMemberLevel;
        },
        visitorMemberGroup: function () {
            return this.module.content.visitorMemberGroup || 0;
        },
        fileMemberGroup: function () {
            return this.module.content.authBuddyGroupIdList || [];
        },
        isFileMemberGroup: function () {
            let fileMemberGroupBit = 0;
            this.fileMemberGroup.forEach((item) => {
                fileMemberGroupBit = fileMemberGroupBit | (1 << (item - 1));
            });
            return (this.visitorMemberGroup & fileMemberGroupBit) != 0;
        },
        memberLevelLimit: function () {
            return this.module.content.memberLimit;
        },
        rootUrl: function () {
            return this.module.content.rootUrl;
        },
        visitorMemberAcct: function () {
            return this.module.content.visitorMemberAcct;
        },
        hrefStr: function () {
            return this.module.content.hrefStr;
        },
        downloadFid: function () {
            return this.module.content.downloadFid;
        },
        downloadMid: function () {
            return this.module.content.downloadMid;
        },
        levelLimitName: function () {
            return this.module.content.levelLimitName;
        },
        totalSize: function () {
            return this.module.content.totalSize;
        },
        pageSize: function () {
            return this.module.content.count;
        },
        file_name_style: function () {
            if (VITE_APP_MODE == 'visitor') {
                return '';
            }
            var fileName = this.module.content.mhighs.fns;

            var style = '';
            if (fileName.type == 1) {
                if (fileName.nfs) {
                    style += 'font-size:' + fileName.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (fileName.nfct == 1) {
                    style += 'color:' + fileName.nfc + ';';
                }
            }
            return style;
        },
        file_size_style: function () {
            if (VITE_APP_MODE == 'visitor') {
                return '';
            }
            var fileSize = this.module.content.mhighs.fss;
            var style = '';
            if (fileSize.type == 1) {
                if (fileSize.nfs) {
                    style += 'font-size:' + fileSize.nfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (fileSize.nfct == 1) {
                    style += 'color:' + fileSize.nfc + ';';
                }
            }
            return style;
        },
        downloadIconSize: function () {
            var style = {};
            if (VITE_APP_MODE !== 'visitor') {
                if (this.module.content.is === 1) {
                    style.width = this.module.content.isize + 'px';
                    style.height = this.module.content.isize + 'px';
                }
                return style;
            }
            return style;
        },
        downloadIconColor: function () {
            var style = {};
            if (this.module.content.is === 1 && this.module.content.ics === 1) {
                style.fill = this.module.content.ic;
            } else {
                style.fill = '#666666';
            }
            return style;
        },
        downloadPreviewColor() {
            var style = {};
            if (this.module.content.is === 1 && this.module.content.ics === 1) {
                style.stroke = this.module.content.ic;
            } else {
                style.stroke = '#666666';
            }
            return style;
        },
        onlyLevel: function () {
            return this.module.content.onlyLevel || 0;
        },
    },
    watch: {
        module: {
            handler: function () {
                var _this = this;
                if (!_this.viewList) return;
                // _this.render = false;
                // this.$nextTick(() => {
                // _this.render = true;
                this.$nextTick(() => {
                    _this.bindEvent();
                });
                // });
            },
            deep: true,
        },
    },
    mounted() {
        this.bindEvent();
    },
    methods: {
        checkPreviewPdf(event, pdfPath) {
            event.preventDefault();
            if (VITE_APP_MODE !== 'visitor' || !this.allowedMemberDownload) {
                return window.open(pdfPath);
            }
            if (this.module.content.viewType == 0) {
                return window.open(pdfPath);
            }
            // 会员已经登录
            if (this.sessionMid > 0) {
                if (this.module.content.canViewPdf) {
                    return window.open(pdfPath);
                }
                ing(this.module.content.cantViewTips);
            } else {
                changeHref(
                    preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + this.rootUrl + '&errno=11'
                );
            }
        },
        addFile(e) {
            e.stopPropagation();
            this.$designer.open({
                panelType: 'pattern file-download',
                styleId: MODULE_STYLE.FILE_DOWNLOAD,
                moduleId: this.moduleId,
            });
        },
        parseFileSize,
        pinModuleId: function (index) {
            return 'm' + this.id + 'i' + index;
        },
        groupLimit: function () {
            ing(jm.format(LS.site_file_down_group_tip, encodeHtml(this.visitorMemberAcct)));
        },
        levelLimit: function () {
            if (this.module.content.onlyLevel) {
                ing(jm.format(LS.site_file_down_group_tip, encodeHtml(this.visitorMemberAcct)));
            } else {
                ing(jm.format(LS.memberDownloadLevelLimit, encodeHtml(this.visitorMemberAcct), this.levelLimitName));
            }
        },
        svgItem: function (item) {
            var svgItem = {};
            var vName = item.name;
            var svgLink = '';
            var svgColor = '';
            var extName = vName.substring(vName.lastIndexOf('.') + 1);
            if (
                extName == 'avi' ||
                extName == 'mp4' ||
                extName == 'wmv' ||
                extName == '3gp' ||
                extName == 'mkv' ||
                extName == 'flv' ||
                extName == 'rmvb' ||
                extName == 'swf'
            ) {
                svgLink = 'jzm-v14';
                svgColor = '#707EE5';
            } else if (
                extName == 'mp3' ||
                extName == 'wav' ||
                extName == 'wma' ||
                extName == 'ogg' ||
                extName == 'ape' ||
                extName == 'acc'
            ) {
                svgLink = 'jzm-v7';
                svgColor = '#1CBE89';
            } else if (
                extName == 'gif' ||
                extName == 'jpg' ||
                extName == 'png' ||
                extName == 'bmp' ||
                extName == 'jpeg'
            ) {
                svgLink = 'jzm-v9';
                svgColor = '#23C786';
            } else if (extName == 'txt' || extName == 'epub') {
                svgLink = 'jzm-v13';
                svgColor = '#3686FD';
            } else if (extName == 'ppt' || extName == 'pptx') {
                svgLink = 'jzm-v12';
                svgColor = '#FF9743';
            } else if (extName == 'pdf') {
                svgLink = 'jzm-v11';
                svgColor = '#FF5A5A';
            } else if (extName == 'xls' || extName == 'xlsx') {
                svgLink = 'jzm-v6';
                svgColor = '#2C9660';
            } else if (extName == 'doc' || extName == 'docx' || extName == 'wps') {
                svgLink = 'jzm-v15';
                svgColor = '#4E99FF';
            } else if (extName == 'rar' || extName == 'zip') {
                svgLink = 'jzm-v8';
                svgColor = '#5095ED';
            } else if (extName == 'html' || extName == 'css') {
                svgLink = 'jzm-v22';
                svgColor = '#FF8976';
            } else if (extName == 'js') {
                svgLink = 'jzm-v23';
                svgColor = '#6AC543';
            } else if (extName == 'apk') {
                svgLink = 'jzm-v24';
                svgColor = '#F3B137';
            } else {
                svgLink = 'jzm-v17';
                svgColor = '#B9C9D6';
            }
            var style = {};
            style.color = svgColor;
            svgItem = {
                svgLink: svgLink,
                style: style,
            };
            return svgItem;
        },
        jumptoIndex: function (item) {
            changeHref(
                preprocessUrl({ path: '', oldPath: '' }) +
                    'login.jsp?returnUrl=' +
                    this.rootUrl +
                    '&errno=11&mid=' +
                    this.id +
                    '&fid=' +
                    item.id
            );
        },
        changePage: function (pageno) {
            var self = this;
            let param = [];
            param.push(`&pageno=${pageno}`);
            param.push(`&pageSize=${this.module.content.count}`);
            param.push(`&moduleId=${this.module.id}`);
            param.push(`&fileList=${JSON.stringify(this.module.content.fileList)}`);

            jm.ajax({
                type: 'post',
                url: '/ajax/module_h.jsp?cmd=getWafNotCk_getFileData',
                data: param.join(''),
                error: function () {
                    VITE_APP_MODE !== 'visitor'
                        ? Fai.top.Fai.ing('系统繁忙，请稍候重试', false)
                        : ing('系统繁忙，请稍候重试', false);
                },
                success: (result) => {
                    var data = jm.parseJSON(result);
                    if (data.success) {
                        // data.viewList.forEach((item) => {
                        //     self.module.extInfo.viewList.push(item);
                        // });
                        // self.render = false;
                        // this.$nextTick(() => {
                        // self.render = true;
                        this.$nextTick(() => {
                            self.pageno = parseInt(pageno);
                            self.bindEvent();
                        });
                        // })
                    }
                },
            });
        },
        bindEvent: function () {
            // var viewList = this.module.extInfo.viewList;
            let viewList = this.viewList;
            var viewListLength = viewList.length;
            for (var i = 0; i < viewListLength; i++) {
                if (this.downloadFid != '' && this.downloadMid != '' && this.sessionMid > 0) {
                    var downloanMID = parseInt(this.downloadMid, 0);
                    if (downloanMID == this.id && this.downloadFid == viewList[i].id) {
                        if (this.showMemberLevel == 1) {
                            if (this.module.content.onlyLevel) {
                                if (this.visitorMemberLevel == this.memberLevelLimit) {
                                    ing(
                                        jm.format(
                                            LS.memberDownloadLevelLimit,
                                            encodeHtml(this.visitorMemberAcct),
                                            this.levelLimitName
                                        )
                                    );
                                    jm.cookie('_moduleid', '', {
                                        expires: 0,
                                        path: '/',
                                    });
                                    jm.cookie('_fileid', '', {
                                        expires: 0,
                                        path: '/',
                                    });
                                    return;
                                }
                            } else {
                                if (this.visitorMemberLevel < this.memberLevelLimit) {
                                    ing(
                                        jm.format(
                                            LS.memberDownloadLevelLimit,
                                            encodeHtml(this.visitorMemberAcct),
                                            this.levelLimitName
                                        )
                                    );
                                    jm.cookie('_moduleid', '', {
                                        expires: 0,
                                        path: '/',
                                    });
                                    jm.cookie('_fileid', '', {
                                        expires: 0,
                                        path: '/',
                                    });
                                    return;
                                }
                            }
                        } else if (this.showMemberLevel == 2) {
                            if (!this.isFileMemberGroup) {
                                this.groupLimit();
                            }
                            return;
                        }
                        changeHref(jm('#m' + this.id + 'i' + i).attr('href'));
                        jm.cookie('_moduleid', '', { expires: 0, path: '/' });
                        jm.cookie('_fileid', '', { expires: 0, path: '/' });
                    }
                }
            }
        },
        encodeUrl: function (url) {
            return typeof url === 'undefined' ? '' : encodeURIComponent(url);
        },
        logViewFile() {
            !(VITE_APP_MODE !== 'visitor') && logDog(201610, 1);
        },
        handleDownload(item, event) {
            fileDownloadCdnLimitHandler();
            const isInWechatMP =
                (navigator.userAgent.match(/micromessenger/i) && navigator.userAgent.match(/miniprogram/i)) ||
                window.__wxjs_environment === 'miniprogram';
            if (isInWechatMP) {
                copyDataToClipboard({
                    data: item.downloadUrl,
                    sCb() {
                        mobiIng('链接已复制，请粘贴至浏览器打开下载', 1);
                    },
                    fCb() {
                        mobiIng('复制下载链接失败', 1);
                    },
                });
                event.preventDefault();
            }
        },
    },
};
</script>

<style lang="scss">
.empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/************* fileDownload start **************/
.newFileDownloadPanel {
    padding: 0 0.25rem;
}
.fileDownloadPanel .mainBodyContainer {
    margin: 0px;
    height: auto;
    border-bottom: 0.025rem solid #eeeeee;
    padding: 0.45rem 0.5rem;
    box-sizing: border-box;
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
    word-break: keep-all;
    overflow: hidden;
}
.fileDownloadPanel .mainBodyContainer:last-child {
    border: 0;
}
.newFileDownloadPanel .mainBodyContainer {
    display: flex;
    align-items: center;
    padding: 0.45rem 0.2rem 0.45rem 0.3rem;
}
.fileDownloadPanel .fileName {
    width: 100%;
    height: 1.15rem;
    padding-left: 1rem;
}
.fileDownloadPanel .fileImg {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0;
    padding: 0;
}
.fileDownloadPanel .fileName .fileNameText {
    width: 97%;
    /*height: 1.15rem;*/
    font-size: 0.7rem;
    line-height: 1.25rem;
    word-wrap: break-word;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newFileDownloadPanel .fileName .fileNameText {
    font-size: 0.6rem;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    width: 97%;
    /*height: 1.15rem;*/
    line-height: normal;
    word-wrap: break-word;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fileDownloadPanel .fileName .fileNameWrap {
    height: auto;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}
.fileDownloadPanel .fileSize {
    width: 9rem;
    height: auto;
    font-size: 0.5rem;
    font-weight: 500;
    color: rgba(179, 179, 179, 1);
    line-height: 1.05rem;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}
.newFileDownloadPanel .fileSize {
    height: auto;
    line-height: normal;
}
.newFileDownloadPanel .fileName {
    height: auto;
}
.fileDownloadPanel .fileOperate {
    width: 1.5rem;
    height: 100%;
    padding-left: 0px;
    padding-right: 0.25rem;
}
.newFileDownloadPanel .fileOperate {
    width: auto;
    padding-right: 0;
}
.fileDownloadPanel .iconAvi {
    background: url(/image/fileDownload/Format_icon_01.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconWmv {
    background: url(/image/fileDownload/Format_icon_02.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .icon3gp {
    background: url(/image/fileDownload/Format_icon_03.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconMkv {
    background: url(/image/fileDownload/Format_icon_04.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconFlv {
    background: url(/image/fileDownload/Format_icon_05.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconMp4 {
    background: url(/image/fileDownload/Format_icon_06.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconRmvb {
    background: url(/image/fileDownload/Format_icon_07.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconSwf {
    background: url(/image/fileDownload/Format_icon_08.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconMp3 {
    background: url(/image/fileDownload/Format_icon_09.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconWav {
    background: url(/image/fileDownload/Format_icon_10.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .downWma {
    background: url(/image/fileDownload/Format_icon_11.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconOgg {
    background: url(/image/fileDownload/Format_icon_12.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconApe {
    background: url(/image/fileDownload/Format_icon_13.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconAcc {
    background: url(/image/fileDownload/Format_icon_14.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconGif {
    background: url(/image/fileDownload/Format_icon_15.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconJpg {
    background: url(/image/fileDownload/Format_icon_16.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconPng {
    background: url(/image/fileDownload/Format_icon_17.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconBmp {
    background: url(/image/fileDownload/Format_icon_18.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconWps {
    background: url(/image/fileDownload/Format_icon_19.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconEpub {
    background: url(/image/fileDownload/Format_icon_20.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconTxt {
    background: url(/image/fileDownload/Format_icon_21.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconPpt {
    background: url(/image/fileDownload/Format_icon_22.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconPdf {
    background: url(/image/fileDownload/Format_icon_23.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconXls {
    background: url(/image/fileDownload/Format_icon_24.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconDoc {
    background: url(/image/fileDownload/Format_icon_25.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconHtml {
    background: url(/image/fileDownload/Format_icon_26.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconCss {
    background: url(/image/fileDownload/Format_icon_27.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconJs {
    background: url(/image/fileDownload/Format_icon_28.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconRar {
    background: url(/image/fileDownload/Format_icon_29.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconZip {
    background: url(/image/fileDownload/Format_icon_30.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconApk {
    background: url(/image/fileDownload/Format_icon_31.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 3px;
}
.fileDownloadPanel .iconDefault {
    background: url(/image/fileDownload/Format_icon_32.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0px;
    margin-right: 0.15rem;
}
.fileDownloadPanel .download {
    background: url(/image/fileDownload/download_icon2.png?v=201711250607) no-repeat;
    background-size: contain !important;
    width: 1.8rem;
    height: 1.8rem;
    margin-bottom: 0px;
    margin-right: 0.1rem;
}
#fileListDlButton:after {
    font-family: icomoon;
    content: '\eb1e';
    color: #666;
    position: absolute;
    top: 1em;
    left: 21.15em;
}
.fileDownloadPanel .fileSvgClass {
    width: 1.2rem;
    height: 1.2rem;
}
.fileDownloadPanel .downloadSvg {
    width: 1.3rem;
    height: 1.3rem;
}
.fileDownloadPanel .previewSvg {
    stroke: #666666;
}
/************ fileDownload end *****************/
</style>
