<template>
    <div :id="'J_first_group_' + moduleId" class="J_first_group first_group">
        <ul class="first_grouplist J_first_grouplist clearFix">
            <li
                v-for="(item, index) in groupList"
                :key="index"
                :class="{
                    selected: isSelect(item, index),
                    g_main_color_v3: isSelect(item, index),
                }"
                class="J_first_groupitem first_groupitem"
                :style="firstGroupStyle"
                @click.stop.prevent="handleHasProductClick(item, index)"
            >
                <p class="first_groupname" v-text="item.name"></p>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'ProductGroupNav',
    inject: ['module', 'options'],
    props: {
        moduleId: {
            type: [String, Number],
            require: true,
            default: '',
        },
        pType: {
            type: String, // id 为 printNav, name 为 printGroupingNav
            require: true,
            default: '',
        },
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        searchId: {
            type: Number,
            default: 0,
        },
        searchName: {
            type: String,
            default: '',
        },
    },
    computed: {
        groupList: function () {
            return this.list;
        },
        firstGroupStyle() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }
            let style = {};
            if (this.module.content.s == 5 || this.module.content.s == 6) {
                const textSetting = this.module.content.mhighs.fgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
    },
    methods: {
        isSelect: function (item, index) {
            if (this.pType == 'id') {
                return (this.searchId == 0 && index == 0) || (this.searchId != 0 && this.searchId == item.id);
            } else if (this.pType == 'name') {
                return (this.searchName == '' && index == 0) || (this.searchName != '' && this.searchName == item.name);
            }
        },

        handleHasProductClick: function (item, index) {
            //延迟执行节点的更新，避免click事件触发到别的节点上，导致跳转
            var _this = this;
            var timer = setTimeout(function () {
                _this.$emit('select', item, index);
                clearTimeout(timer);
                timer = null;
            }, 100);
        },
    },
};
</script>
