////  原路径：mobi_moduleProduct

import { copyData } from '@/shared/util';
import { getUrlParam } from '@/shared/url';
import { getAjaxPageModuleV2InfoApi } from '@/api/product';

export const getProductListPageData = async function (options) {
    var param = [];

    param = {
        _colId: Fai.top._pageId,
        _extId: Fai.top._extId,
        moduleId: options.moduleId,
        _lcid: Fai.top._lcid,
    };

    if (options.pid != null) {
        param.pid = options.pid;
    }

    var href = window.location.href;

    if (href.indexOf('_fromJzmHome') > 0) {
        var fromJzmHome = getUrlParam(href, '_fromJzmHome');
        if (fromJzmHome) {
            param._fromJzmHome = true;
        }
    }
    let _data = {};
    if (VITE_APP_MODE !== 'visitor') {
        var moduleCopy = copyData(options.module);
        moduleCopy.extInfo = {};
        _data.module = JSON.stringify(moduleCopy);
    }

    if (options.pageno) {
        param[`m${options.moduleId}pageno`] = options.pageno;
    }

    let result = await getAjaxPageModuleV2InfoApi(param, _data);
    var data = result.data;
    return data;
};

/*******判断产品展示是否有显示参数和名字，解决显示图片是下方有边距的问题*******/
export const hideParamPadding = function (moduleId) {
    var paramNum = jm('#' + moduleId + ' .paramCollection').children('p').length;
    if (paramNum === 0) {
        jm('#' + moduleId + ' .paramCollection').removeClass('paramPadding');
    }
};

/*********判断只有产品名称时去掉底部边距************/
export const removeMarginBottom = function (moduleId, styleType) {
    let productList;
    if (styleType == 'styleNine') {
        productList = jm('#' + moduleId + ' .productCrossedSlideSec');
    } else if (styleType == 'styleEight') {
        productList = jm('#' + moduleId + ' .productWaterFall');
    } else if (styleType == 'styleSeven') {
        productList = jm('#' + moduleId + ' .productCrossedSlide');
    }
    var pNum = productList.eq(0).find('.paramCollection').children('p').length;
    var paramName = productList.eq(0).find('.paramCollection').children('.paramName').length;
    if (pNum === 1 && paramName === 1) {
        jm('#' + moduleId + ' .paramCollection .paramName').css('marginBottom', 0);
    }
    var paramPrice = productList.eq(0).find('.paramCollection').children('.paramPrice').length;
    if (pNum === 2 && paramPrice === 1) {
        productList.find('.paramCollection').children('.paramLine').remove();
    }
};
