<template>
    <div class="m_mobi_form_phone" :style="_mixinsInputStyle1" :class="classes">
        <input
            type="number"
            class="m_mobi_form_input_text m_mobi_form_phone_input"
            :value="currentValue"
            :placeholder="formItem.placeholder"
            :style="_mixinsInputStyle2"
            @input="handleInput"
        />
        <div v-if="formItem.openAreaCode" class="form_phone_cn_warp">
            <i class="drop_down_icon faisco-icons-S000112"></i>
            <div class="cn_value">{{ currentCtCode }}</div>
            <select v-model="currentCtCode" class="cn_select">
                <option
                    v-for="(item, index) in mobileCtList"
                    :key="item.ctName"
                    :selected="index === 0"
                    :value="item.ctCode"
                    >{{ item.ctShowName + item.ctCode }}</option
                >
            </select>
        </div>
    </div>
</template>
<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

const debounce = function (fn, delay) {
    let timer = null;
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
};
export default {
    name: 'FormPhone',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            mobileCtList: this.module.extInfo.mobileCtNameList || [],
            currentCtCode: '+86',
        };
    },
    computed: {
        submitVal() {
            const val = this.getCurCn();
            const areaCode = val.ctName || '';
            if (this.formItem.openAreaCode && this.currentValue !== '') return areaCode + this.currentValue;
            return this.currentValue;
        },
        classes() {
            return this.module.content.ms === 0 ? '' : 'm_mobi_form_input_text';
        },
        _mixinsInputStyle2() {
            return this.module.content.ms === 0 ? this._mixinsInputStyle : '';
        },
        _mixinsInputStyle1() {
            return this.module.content.ms === 0 ? '' : this._mixinsInputStyle;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        resetValue() {
            this.currentValue = '';
        },

        handleInput: debounce(function (event) {
            let value = event.target.value,
                that = this;

            if (value.length > 11) {
                value = value.slice(0, 11);
                event.target.value = value;
            }
            this.currentValue = value;
            that.$emit('chanage', this.currentValue);
        }, 200),
        getCurCn() {
            return this.mobileCtList.filter((item) => item.ctCode === this.currentCtCode)[0] || {};
        },
        validate() {
            const phoneNumber = this.currentValue || '';
            const phoneNumberLength = (this.formItem.phoneNumberLength || '7-11').split('-');
            const minLength = phoneNumberLength[0];
            const maxLength = phoneNumberLength[1] || minLength;

            if (this.formItem.must && phoneNumber == '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            if (phoneNumber) {
                if (!jm.isLimitedRangeNationMobile(phoneNumber, minLength, maxLength)) {
                    this.$mobiIng(jm.format(LS.newMobileNumRegular, phoneNumberLength));
                    return false;
                } else {
                    if (
                        !(
                            jm.isMobile(phoneNumber) ||
                            (this.formItem.phoneNumberLength == '7-11' && jm.isNationMobile(phoneNumber))
                        )
                    ) {
                        this.$mobiIng('手机号码格式有误');
                        return false;
                    }
                }
            }

            return true;
        },
        initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : '';
            const [, codeName, phone] = initialValue.match(/([a-zA-Z]+)?(\d+)?/);
            if (codeName) {
                const codeInfo = this.mobileCtList.find((info) => info.ctName === codeName);
                if (codeInfo) {
                    this.currentCtCode = codeInfo.ctCode;
                }
            }
            if (phone) {
                this.currentValue = phone;
            }
        },
    },
};
</script>

<style>
.m_mobi_form_phone {
    display: flex;
    justify-content: space-around;
}

.m_mobi_form_phone.m_mobi_form_input_text {
    padding: 0;
}
.m_mobi_form_phone .m_mobi_form_phone_input {
    border: 0;
    padding-left: 0.2rem;
}
.m_mobi_form_phone .m_mobi_form_phone_input.m_mobi_form_input_text {
    background-color: transparent;
    order: 1;
}

.m_mobi_form_phone_input::-webkit-inner-spin-button,
.m_mobi_form_phone_input::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

.mobi_form_style_2 .m_mobi_form_phone .m_mobi_form_input_text:focus {
    border: 0px;
}

.m_mobi_form_phone .form_phone_cn_warp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    padding-left: 0.2rem;
}

.m_mobi_form_phone .cn_value {
    top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.m_mobi_form_phone .cn_select {
    opacity: 0;
    max-width: 60px;
    padding-right: 0;
    position: absolute;
}

.m_mobi_form_phone .drop_down_icon {
    color: rgba(183, 183, 183, 1);
    font-size: 0.45rem;
    margin-top: 0.2rem;
    order: 1;
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}
</style>
