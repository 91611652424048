<template>
    <div class="up_down_pic_text" :class="column2">
        <template v-for="(info, index) in newsList">
            <up-down-pic-text-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            <div v-if="!isColumn2" :key="'n_col_' + info.id" class="g_separator separatorLine"></div>
        </template>
        <div v-if="needBlankDiv" class="blankDiv newsLine">
            <up-down-pic-text-item :no-lazy-load="noLazyLoad" />
        </div>
    </div>
</template>

<script>
import upDownPicTextItem from './up-down-pic-text-item.vue';
export default {
    name: 'UpAndDownPicText',
    components: { upDownPicTextItem },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        isColumn2() {
            return this.module.content.naw == 2;
        },
        column2() {
            return this.module.content.naw == 2 ? 'isColumn2' : '';
        },
        needBlankDiv() {
            return this.newsList.length % 2 != 0 && this.isColumn2 ? true : false;
        },
    },
};
</script>

<style></style>
