<template>
    <div class="m_mobi_form_select_wrap">
        <i class="drop_down_icon faisco-icons-S000112"></i>
        <select
            class="m_mobi_form_select m_mobi_form_input_text J_mobi_form_select"
            :style="_mixinsInputStyle"
            @change="change"
        >
            <option :selected="currentValue === ''" value="noneValue" style="display: none" disabled>{{
                formItem.placeholder || pleaseSelectTips
            }}</option>
            <option
                v-for="name in selectGroup"
                :key="name"
                :selected="currentValue === name"
                :value="name"
                :disabled="isZeroResidue(name)"
            >
                {{ name + getResidueText(name) }}</option
            >
        </select>
    </div>
</template>

<script>
import Mixins from '../mixins';
import { encodeHtml } from '@/shared/util';
import { residueMixins } from '../mixins/residue.js';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormSelect',
    mixins: [Mixins, residueMixins(), formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            noneValue: 'none',
        };
    },
    computed: {
        selectGroup() {
            return (this.formItem.input || '').split('\n').filter((formItem) => formItem);
        },
        submitVal() {
            return this.currentValue;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        change(event) {
            this.currentValue = event.target.value;
            this.$emit('selectChange', {
                value: this.currentValue,
                formItem: this.formItem,
            });
        },
        resetValue() {
            this.initDefaultValue();
        },
        validate(needValidate) {
            let must = needValidate || this.formItem.must;
            if (must && this.currentValue === '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }
            return true;
        },
        async initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtds;
            if (typeof initialValue === 'string' && initialValue !== '' && !this.isZeroResidue(initialValue)) {
                this.currentValue = initialValue;
                await this.$nextTick(); // 保证前面的修改完成
                this.$emit('selectChange', {
                    value: this.currentValue,
                    formItem: this.formItem,
                });
            } else {
                this.currentValue = '';
            }
        },
    },
};
</script>

<style>
.m_mobi_form_select_wrap {
    position: relative;
}
.m_mobi_form_select_wrap .drop_down_icon {
    position: absolute;
    right: 0.85rem;
    color: #b7b7b7;
    font-size: 0.45rem;
    top: 50%;
    transform: translateY(-50%);
}
</style>
