<template>
    <div class="three_pic_mix_pic_text_list" :class="isOnlyTitle">
        <three-pic-text-item v-if="!!headPicItemInfo" :info="headPicItemInfo" :no-lazy-load="noLazyLoad" />
        <div class="g_separator separatorLine"></div>
        <div class="news_list-pic-text">
            <template v-if="isNewModule">
                <template v-for="(info, index) in newsList2">
                    <pic-text-item-v2 :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
                    <div :key="'s_' + info.id" class="g_separator separatorLine"></div>
                </template>
            </template>
            <template v-else>
                <template v-for="(info, index) in newsList2">
                    <pic-text-item-v1 :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import PicTextItemV2 from '../picTextList/pic-text-item-v2.vue';
import PicTextItemV1 from '../picTextList/pic-text-item-v1.vue';
import threePicTextItem from '../threePicList/three-pic-text-item.vue';
import { mapFlag } from '@/shared/flag';
import ListItem from '../../mixins/ListItem';

export default {
    name: 'ThreePicMixTextList',
    components: { threePicTextItem, PicTextItemV2, PicTextItemV1 },
    mixins: [ListItem],
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        ...mapFlag(
            {
                isNewModule: 0x2,
            },
            'module.flag'
        ),
        headPicItemInfo() {
            return !!this.newsList && this.newsList[0];
        },
        newsList2() {
            return this.newsList ? this.newsList.slice(1, this.newsList.length) : [];
        },
    },
};
</script>

<style></style>
