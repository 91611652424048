<template>
    <div ref="dateContainer" class="m_mobi_form_date" :class="classes" @click.stop="handlerClick"></div>
</template>
<script>
import Mixins from '../mixins';
import { initmobiFormTimeBtn } from '../../util';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

import { ing } from '@/shared/tips';
import { encodeHtml } from '@/shared/util';
export default {
    name: 'FormDate',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentValue: '',
            formInfo: this.module.content.fi,
        };
    },
    computed: {
        submitVal() {
            return this.currentValue;
        },
        classes() {
            return {
                is_dateRange: this.formItem.dateSetting.type == 1,
            };
        },
    },
    watch: {
        formItem: {
            deep: true,
            handler() {
                this.bindEvent();
            },
        },
        module: {
            deep: true,
            handler() {
                if (!(VITE_APP_MODE !== 'visitor')) {
                    return;
                }
                this.bindStyle();
            },
        },
        'module.content.ms'() {
            this.bindEvent();
        },
    },
    mounted() {
        this.bindEvent();
        this.initDefaultValue();
    },
    methods: {
        handleInput(event) {
            const value = event.target.value;
            this.currentValue = value;
        },
        validate() {
            var moduleId = this.module.id,
                formItemId = this.formItem.id,
                tmpInput = jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Pluginsinput').val(),
                dateType = this.formItem.dateSetting ? this.formItem.dateSetting.type : 0,
                temInputEnd =
                    dateType == 1 ? jm('#Mobi' + moduleId + 'Calendar' + formItemId + 'Plugins_endinput').val() : '';

            if (this.formItem.must && (tmpInput == '' || (dateType == 1 && temInputEnd == ''))) {
                ing(jm.format(LS.siteFormSubmitCheckIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }

            tmpInput = dateType == 1 ? tmpInput + ' 至 ' + temInputEnd : tmpInput;

            // 验证时才赋值
            this.currentValue = tmpInput;

            return true;
        },
        bindEvent() {
            const dateRange = this.formItem.dateSetting.type == 1;
            const classes = dateRange ? 'fk-startDateInput' : '';
            const moduleId = this.module.id;
            const formItemId = this.formItem.id;

            jm(this.$el).find('.fk-dateInputMaskWrap').remove();
            jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).remove();
            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).remove();
            jm(`#Mobi${moduleId}CalendarInput${formItemId}`).remove();

            if (
                jm.inArray(7, this.formItem.dateSetting.banSingleDateList) > -1 &&
                jm.inArray(0, this.formItem.dateSetting.banSingleDateList) === -1
            ) {
                // eslint-disable-next-line vue/no-mutating-props
                this.formItem.dateSetting.banSingleDateList.push(0);
            }

            let template = `<div class="mobiCalendarPlugins ${classes}" id="Mobi${moduleId}Calendar${formItemId}Plugins"></div>`;

            // 结束日期输入框
            if (dateRange) {
                if (this.module.content.ms != 3) {
                    template += `<span class='fk-dateRangeUtil' id="Mobi${moduleId}CalendarInput${formItemId}">-</span>`;
                }

                template += `<div class="fk-endDateInput mobiCalendarPlugins" id="Mobi${moduleId}Calendar${formItemId}Plugins_end"></div>`;
            }

            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins`).remove();

            jm(template).appendTo(this.$refs.dateContainer);

            initmobiFormTimeBtn({
                moduleId: moduleId,
                siteFormItemId: formItemId,
                minDate: new Date(),
                yearChangeable: true,
                monthChangeable: true,
                swipeable: true,
                placeholder: this.formItem.placeholder || this.pleaseSelectTips,
                dateSetting: this.formItem.dateSetting,
            });

            jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).addClass('m_mobi_form_input_text');

            if (dateRange) {
                jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).addClass('m_mobi_form_input_text');
            }

            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins`).remove();
            jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_end`).remove();

            this.bindStyle();
        },
        bindStyle() {
            const This = this;
            jm(this.$el)
                .find('.m_mobi_form_input_text')
                .forEach((el) => {
                    jm(el).attr('style', This._mixinsInputStyle);
                });
        },
        handlerClick(e) {
            e.stopPropagation();
        },
        resetValue() {
            this.initDefaultValue();
        },
        initDefaultValue() {
            const moduleId = this.module.id,
                formItemId = this.formItem.id;

            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : this.formItem.dtd;

            if (typeof initialValue === 'string' && initialValue !== '') {
                this.currentValue = initialValue;

                const [start, end] = initialValue.split('至');

                // 从 initmobiFormTimeBtn 方法中抽出的逻辑
                const now = new Date();
                const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                const dateSetting = this.formItem.dateSetting;
                const nowDate =
                    dateSetting.od.t == 1 && dateSetting.banPassDate == 1 ? todayDate : new Date(1900, 0, 1);

                if (typeof start === 'string' && start !== '' && new Date(start.trim()) >= nowDate) {
                    this.$nextTick(() => {
                        jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).val(start.trim());
                    });
                }
                if (typeof end === 'string' && end !== '' && new Date(end.trim()) >= nowDate) {
                    this.$nextTick(() => {
                        jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).val(end.trim());
                    });
                }
            } else {
                jm(`#Mobi${moduleId}Calendar${formItemId}Pluginsinput`).val(''),
                    jm(`#Mobi${moduleId}Calendar${formItemId}Plugins_endinput`).val('');
                this.currentValue = '';
            }
        },
    },
};
</script>

<style>
.m_mobi_form_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .m_mobi_form_date .fk-dateInputMaskWrap,.m_mobi_form_date .fk-dateInputMaskWrap {width: 6.75rem;} */

.m_mobi_form_date .fk-dateRangeUtil {
    text-align: center;
    width: 1.25rem;
}
</style>
