<template>
    <div>
        <div v-if="manageMode" class="empty_tips_panel">
            <div class="text">{{ text }}</div>
            <button-component active @click.stop="addType">{{ buttonText }}</button-component>
        </div>
        <div v-if="!manageMode" class="cus_Empty_tips_panel">
            <div class="text2">没有添加分类</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EmptyText',
    props: {
        text: {
            type: String,
            default: '请在右侧面板添加分类',
        },
        buttonText: {
            type: String,
            default: '添加分类',
        },
        manageMode: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        addType: function (e) {
            e.stopPropagation();
            this.$emit('add-click');
        },
    },
};
</script>
