import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import FormView from './module/mobiForm.vue';

class _Form extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.MOBI_FORM, options);
    }

    render() {
        return FormView;
    }
}

export const Form = new _Form();
