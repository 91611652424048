import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import TotalSearchView from './module/Entrance.vue';

class _TotalSearch extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.TOTAL_SEAECH, options);
    }

    render() {
        return TotalSearchView;
    }
}

export const TotalSearch = new _TotalSearch();
