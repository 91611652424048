<template>
    <transition :css="false" @enter="animEnter" @leave="animLeave">
        <div v-if="value" v-dom-portal="'#g_web'" class="p_popup_layer" ontouchmove="return false">
            <div ref="mask" class="popup_mask" @click="onClose"></div>
            <div ref="content" class="popup_content">
                <slot />
            </div>
        </div>
    </transition>
</template>

<style lang="scss">
.p_popup_layer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
}
.p_popup_layer .popup_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
}
.p_popup_layer .popup_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s;
    transform: translate(0, 100%);
}
.p_popup_layer .popup_content.active {
    transform: none;
}
</style>

<script>
export default {
    name: 'PopupLayerComponent',
    props: ['value'],
    beforeDestroy() {
        //v-dom-portal会导致组件销毁的时候节点不销毁
        //可是在指令的unbind内修改又会导致value等于false的时候马上把节点移除掉导致结束动画不播放
        jm(this.$el).remove();
    },
    methods: {
        onClose() {
            this.$emit('input', false);
        },
        animEnter(el, done) {
            jm(this.$refs.mask).show();
            setTimeout(() => {
                jm(this.$refs.content).addClass('active');
                done();
            }, 20);
        },
        animLeave(el, done) {
            jm(this.$refs.mask).hide();
            jm(this.$refs.content).removeClass('active');
            done();
        },
    },
};
</script>
