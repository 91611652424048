import { ing } from '@/shared/tips';
import { initWebPage } from '@/shared/misc/init-webpage';
import { addRequestPrefix } from '@/shared/util';
import { initmobiFormTimeBtn, siteFormFileUploadHtml, siteFormValidation } from '@/modules/mobiForm/util';
import { site_cityUtil } from '@/shared/city';
import { MobileSelectArea } from '@/shared/plugin';
import popupWindow from '@/shared/popupWindow';
import { getSiteMultiLanguageUrl, getMobiMultiLanguageUrl } from '@/shared/url';
import { showTopManageFrame } from '@/shared/frame';
import { encodeHtml } from '@/shared/util';

export const bookingBoxBind = function (overLimit) {
    let triggerId = 'bookingPanelTriggle';
    jm('#' + triggerId)
        .off('click.bookingBoxBind')
        .on('click.bookingBoxBind', function () {
            if (overLimit) {
                ing(LS.bookingSubmitFullTips);
            }
        });

    if (overLimit) return;

    if (jm('#productBookingPanel').length > 0) {
        return;
    }

    var content = [];
    content.push(
        "<div class='productBookingPanel' id='productBookingPanel' style='display:none; background-color:white;'>"
    );
    content.push("<div class='ShipDetailsHeader webHeaderBg g_main_bgColor_v3'>");
    content.push(
        "<span class='g_close icon-gClose'></span><span class='title pageTitle'>" +
            Fai.top._bookingModuleTitle +
            '</span>'
    );
    content.push('</div>');
    content.push('</div>');

    jm(content.join('')).insertAfter('#' + triggerId);

    var commentOption = {
        triggerId,
        panelId: 'productBookingPanel',
        callback() {
            showBookingBox();
        },
        closeWebPage: '#productBookingPanel .g_close',
    };

    initWebPage(commentOption);
};

const showBookingBox = function () {
    var param = [],
        _pid = 0,
        _moduleInfo = {},
        pageData = {}; //后台返回的必要数据
    param.push('pid=' + _pid);
    param.push('&cmd=getWafNotCk_getPanelData');
    param.push('&_colId=' + Fai.top._colId);
    param.push('&_extId=' + Fai.top._extId);
    jm.ajax({
        type: 'post', //这里必须用get方法，方便后台取参数数据
        cache: false, //必须清缓存，否则IE浏览器会一直使用存储的旧数据，不会发起新的请求
        url: addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/booking_h.jsp?' + param.join(''),
        error() {
            ing(LS.js_systemError);
        },
        success(returns) {
            var res = jm.parseJSON(returns);
            if (res.success) {
                pageData = res.pageData;
                _moduleInfo = pageData.moduleInfo;
                bookingBoxInit(pageData, _moduleInfo);
            } else {
                ing('获取数据失败', false);
            }
        },
    });
};

const mobiBookingSubmitAdd = function (pageData) {
    if (VITE_APP_MODE !== 'visitor') {
        alert('当前为管理状态，提交无效。');
        return;
    }

    var moduleId = pageData.moduleId,
        moduleInfo = pageData.moduleInfo,
        siteFormItemList = pageData.contentList,
        tmpFormSubmitContentList = [],
        tmpFormContentList = siteFormItemList,
        checkOk = true,
        typeTextAreaMaxLen = pageData.Def_LIMIT_TEXTAREA_MAXLEN,
        tmpValidation = pageData.validation,
        tmpFormValidation = jm('#M' + moduleId + 'siteFormValidateCode'),
        tmpFormValidationVal = tmpFormValidation.val(),
        tmpFileList = [];

    if (tmpFormContentList.length < 1) {
        return false;
    }
    jm.each(tmpFormContentList, function (i, n) {
        var id = n.id,
            name = n.name,
            must = n.must,
            type = n.type,
            tmpContentList = {};

        (tmpContentList.id = id), (tmpContentList.type = type);
        tmpContentList.must = must;
        var wordLimit = { o: 0, i: 0, a: 100 };
        if (n.wordLimit != undefined) {
            wordLimit = JSON.parse(n.wordLimit);
        }
        // check
        if (type == 0) {
            var tmpInput = jm.trim(jm('#M' + moduleId + 'siteFormInput' + id).val());
            if (must && tmpInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (tmpInput.length > wordLimit.a && must) {
                ing(jm.format(LS.siteFormSubmitInputMaxLength, encodeHtml(name), wordLimit.a));
                checkOk = false;
                return false;
            }
            if (tmpInput.length < wordLimit.i && must) {
                ing(jm.format(LS.siteFormSubmitInputMinLength, encodeHtml(name), wordLimit.i));
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpInput;
        } else if (type == 1) {
            var tmpArea = jm.trim(jm('#M' + moduleId + 'siteFormTextArea' + id).val());
            if (must && tmpArea == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (tmpArea.length > typeTextAreaMaxLen) {
                ing(jm.format(LS.siteFormSubmitInputMaxLength, encodeHtml(name), typeTextAreaMaxLen));
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpArea;
        } else if (type == 2) {
            var radioRs = document.querySelectorAll("input[name='M" + moduleId + 'siteFormRadioR' + id + "']:checked");
            if (must && radioRs.length == 0) {
                ing(jm.format(LS.siteFormSubmitCheckIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (radioRs.length > 0) {
                tmpContentList.val = radioRs[0].value;
            } else {
                tmpContentList.val = '';
            }
        } else if (type == 3) {
            var checkBoxRs = document.querySelectorAll(
                "input[name='M" + moduleId + 'siteFormCheckboxR' + id + "']:checked"
            );
            if (must && checkBoxRs.length == 0) {
                ing(jm.format(LS.siteFormSubmitCheckIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            var tmpcheckBoxRVal = [];
            for (let i = 0; i < checkBoxRs.length; i++) {
                tmpcheckBoxRVal.push(checkBoxRs[i].value);
            }

            tmpContentList.val = tmpcheckBoxRVal.join('\n');
        } else if (type == 4) {
            var selected = jm('#M' + moduleId + 'siteFormSelect' + id).val();

            if (must && (selected.length == 0 || selected == 'none')) {
                ing(jm.format(LS.siteFormSubmitCheckIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            tmpContentList.val = selected;
        } else if (type == 5) {
            return true;
        } else if (type == 6) {
            // eslint-disable-next-line no-redeclare
            var tmpInput = jm('#Mobi' + moduleId + 'Calendar' + id + 'Pluginsinput').val(),
                dateType = n.dateSetting ? n.dateSetting.type : 0,
                temInputEnd = dateType == 1 ? jm('#Mobi' + moduleId + 'Calendar' + id + 'Plugins_endinput').val() : '';

            if (must && (tmpInput == '' || (dateType == 1 && temInputEnd == ''))) {
                ing(jm.format(LS.siteFormSubmitCheckIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (dateType == 1) {
                if (jm.trim(tmpInput) == '' && jm.trim(temInputEnd) == '') {
                    tmpInput = '';
                } else {
                    tmpInput = tmpInput + ' 至 ' + temInputEnd;
                }
            }

            tmpContentList.val = tmpInput;
        } else if (type == 7) {
            var $file = jm('#module' + moduleId).find('#siteForm' + moduleId + 'fileName' + id);
            var file = {};
            if (must && $file.attr('_tmpFileId') == '') {
                ing('请选择文件上传');

                checkOk = false;
                return false;
            }

            if ($file.attr('_tmpFileId') == '') {
                tmpContentList.val = '';
            } else {
                tmpContentList.val = $file.attr('_fileId');
                file.tmpFileName = $file.attr('_tmpFileName');
                file.fileId = tmpContentList.val;
                file.tmpFileId = $file.attr('_tmpFileId');
                tmpFileList.push(file);
            }
        } else if (type == 8) {
            var tmpPhoneInput = jm.trim(jm('#M' + moduleId + 'siteFormPhoneInput' + id).val());
            if (must && tmpPhoneInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (!jm.isNationMobile(tmpPhoneInput) && tmpPhoneInput != '') {
                ing(LS.mobileNumRegular);
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpPhoneInput;
        } else if (type == 9) {
            var tmpMailInput = jm.trim(jm('#M' + moduleId + 'siteFormMailInput' + id).val());
            if (must && tmpMailInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }
            if (!jm.isEmail(tmpMailInput) && tmpMailInput != '') {
                ing(jm.format(LS.memberSignupUserAddItemCorrect, encodeHtml(name)));
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpMailInput;
        } else if (type == 10) {
            var tmpIndentityInput = jm.trim(jm('#M' + moduleId + 'siteFormIndentityInput' + id).val());
            if (must && tmpIndentityInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }

            if (!jm.isCardNo(tmpIndentityInput) && tmpIndentityInput != '') {
                ing(jm.format(LS.memberSignupUserAddItemCorrect, encodeHtml(name)));
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpIndentityInput;
        } else if (type == 11) {
            var tmpAddressInput = jm('#M' + moduleId + 'siteFormAddressInput' + id).val();
            if (must && tmpAddressInput == '') {
                ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(name)));
                checkOk = false;
                return false;
            }
            tmpContentList.val = tmpAddressInput;
        }
        tmpFormSubmitContentList.push(tmpContentList);
    });

    if (!checkOk) {
        return false;
    }

    // 开启校验验证码的
    if (tmpValidation && tmpFormValidationVal == '') {
        ing(jm.format(tmpFormValidation.attr('msg')));
        checkOk = false;
        return false;
    }

    var paramStr =
        'pid=' +
        jm('#bookingPanelTriggle').data('pid') +
        '&submitContentList=' +
        jm.encodeUrl(jm.toJSON(tmpFormSubmitContentList)) +
        '&vCodeId=' +
        moduleId +
        '&validateCode=' +
        tmpFormValidationVal +
        '&tmpFileList=' +
        jm.encodeUrl(jm.toJSON(tmpFileList));
    ing(LS.siteFormSubmitIng, -1);

    jm.ajax({
        type: 'post',
        url: addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/booking_h.jsp?cmd=addWafCk_add',
        data: paramStr,
        error() {
            ing(LS.js_systemError);
        },
        success(result) {
            result = jm.parseJSON(result);
            if (result.success) {
                ing(LS.siteFormSubmitOk, 1);
                jm('#M' + moduleId + 'siteFormAddButton .sendIcon').attr('disabled', true);
                var jumpCtrl = moduleInfo[pageData.Def_BookingSubmit_JUMP_URL];
                setTimeout(function () {
                    jumpCtrl.lt == 1
                        ? (function () {
                              Fai.top.location.href = moduleInfo.jumpToUrl;
                          })()
                        : Fai.top.location.reload();
                }, 2500);
            } else {
                if (result.hasFW) {
                    ing(result.msg);
                    return;
                }

                if (result.rt == -4) {
                    ing(LS.siteFormSubmitCountLimit);
                } else if (result.rt == -20) {
                    ing(result.msg);
                } else if (result.rt == -401) {
                    ing(LS.siteFormSubmitValidateCodeErr);
                    jm('#M' + moduleId + 'siteFormValidateCode').val('');
                    siteFormValidation('', moduleId);
                } else if (result.rt == -7) {
                    ing(LS.siteImgFull);
                } else if (result.rt == -2) {
                    ing(LS.js_argsError);
                } else {
                    ing(LS.js_systemError);
                }
            }
        },
    });
};

const bookingSubmitPanleEventInit = function (pageData) {
    var moduleId = pageData.moduleId;

    jm.each(pageData.contentList, function (i, data) {
        if (data.hide) return true;

        var siteFormItemId = data.id,
            type = data.type;
        if (type == pageData.Def_ContentList_Type_DATE) {
            var dateSetting = data.dateSetting;
            initmobiFormTimeBtn({
                moduleId,
                siteFormItemId,
                minDate: new Date(),
                yearChangeable: true,
                monthChangeable: true,
                swipeable: true,
                placeholder: LS.mallPleaseChoose,
                dateSetting,
            });

            jm(
                "<span class='fk-dateRangeUtil' id='Mobi" +
                    moduleId +
                    'CalendarInput' +
                    siteFormItemId +
                    "'>" +
                    LS.to +
                    '</span>\n'
            ).insertAfter('#module' + moduleId + ' #siteFormItem' + siteFormItemId + ' .fk-dateInputMaskWrap');
        } else if (type == pageData.Def_ContentList_Type_FILE_UPLOAD) {
            if (VITE_APP_MODE !== 'visitor') {
                return true;
            }
            var fileSize = data.size > pageData.fileSizeLimit ? pageData.fileSizeLimit : data.size,
                fileTypeList = [];

            if (!data.isAllFile) {
                var tmpFileTypeStr = (data.dftl || '') + (data.cftl || '');
                var tmpFileTypeList = tmpFileTypeStr.split(' ');
                jm.each(tmpFileTypeList, function (i, tmpFileTypeItem) {
                    if (tmpFileTypeItem.length > 0) {
                        var fileTypeItem = '*.' + tmpFileTypeItem + ';';
                        fileTypeList.push(fileTypeItem);
                    }
                });
            }

            if (Fai.top._cid == 11496535) {
                siteFormFileUploadHtml(
                    siteFormItemId,
                    moduleId,
                    fileTypeList,
                    pageData.isOverLimit,
                    pageData.updateUrlViewRes,
                    pageData.mobiVer,
                    fileSize,
                    pageData.srcjQuery,
                    pageData.srcUploadjQuery,
                    'www.jihosen.icoc.me',
                    LS.mobiFormSubmitFileUploadReSelect
                );
            } else {
                siteFormFileUploadHtml(
                    siteFormItemId,
                    moduleId,
                    fileTypeList,
                    pageData.isOverLimit,
                    pageData.updateUrlViewRes,
                    pageData.mobiVer,
                    fileSize,
                    pageData.srcjQuery,
                    pageData.srcUploadjQuery,
                    pageData.siteMainDomain,
                    LS.mobiFormSubmitFileUploadReSelect
                );
            }
        } else if (type == pageData.Def_ContentList_Type_ADDRESS) {
            var provincelist,
                currentLcid = pageData.currentLcid;

            if (currentLcid == 2052 || currentLcid == 1028) {
                provincelist = site_cityUtil.getProvince();
            } else {
                provincelist = site_cityUtil.getProvinceEn();
            }
            var selectArea = new MobileSelectArea();
            selectArea.init({
                level: data.openMunicipalCode ? 3 : 2,
                lcid: currentLcid,
                trigger: '#M' + pageData.moduleId + 'siteFormAddressInput' + siteFormItemId + '',
                value: jm('#M' + pageData.moduleId + 'siteFormAddressInput' + siteFormItemId + '').data('value'),
                data: provincelist,
                eventName: 'click',
                init: 0,
            });
        }
    });

    jm('#module' + moduleId + ' .siteFormSend').bind('click', function () {
        mobiBookingSubmitAdd(pageData);
    });
};

const bookingBoxInit = function (pageData, moduleInfo) {
    var bookingOpen = pageData.bookingOpen,
        moduleId = pageData.moduleId;
    jm('#module' + moduleId).remove();

    var content = [];
    content.push("<div id='module" + pageData.moduleId + "'>");
    if (bookingOpen) {
        var showType = moduleInfo.content.st;

        content.push("<div class='siteForm fk-siteForm-v " + (showType == 1 ? 'verticalShowTye' : '') + "'>");
        content.push("<div id='siteFormItems' class='siteFormItemTable'>\n");
        jm.each(pageData.contentList, function (i, o) {
            if (o.hide) return true;
            content.push(genBookingInputStr(o, pageData, moduleInfo));
        });

        // 验证码
        if (pageData.validation) {
            if (showType == 1) {
                content.push("<div class='siteFormItem siteFormItemTr f-siteFormItemTr'>\n");
                content.push("<div class='siteFormItemShowName' width='100%'>" + LS.bookingCode + '</div>\n');
                content.push('</div>\n');
                content.push("<div class='siteFormItem siteFormValidate siteFormItemTr'>");
                content.push("<div class='siteFormItemTd'width='100%' >");
                content.push(
                    "<input class='g_itext' type='text' placeholder='" +
                        LS.EnterVerificationCode +
                        "' id='M" +
                        moduleId +
                        "siteFormValidateCode' maxlength='4' msg='" +
                        LS.EnterVerificationCode +
                        "' />"
                );
                content.push(
                    "<img id='M" +
                        moduleId +
                        "FvalidateCodeImg' class='validateCodeImg' onclick=\"Mobi.siteFormValidation('', " +
                        moduleId +
                        ')" src=\'/validateCode.jsp?vCodeId=' +
                        moduleId +
                        '&' +
                        Math.random() * 1000 +
                        "'/>"
                );
                content.push(
                    "<div onclick=\"Mobi.siteFormValidation('', " +
                        moduleId +
                        ")\" class='left siteFormValidateUp changeSiteFormValidate'></div>"
                );
                content.push("<font class='bookingSubmitItemMust'>*</font>");
                content.push('</div>');
                content.push('</div>');
            } else {
                content.push("<div class='siteFormItem siteFormValidate'>");
                content.push(
                    "<div style='width: 23%;float: left; height:100%;'><div class='siteFormItemShowName'><table style='table-layout:fixed;text-align:right; height:100%;' width='100%' cellpadding='0' cellspacing='0'><tr><td  width='100%'>" +
                        LS.bookingCode +
                        '</td></tr></table></div></div>\n'
                );
                content.push("<div style='width: 77%;float: left;'><div class='siteFormItemShowVal'>");
                content.push(
                    "<input class='g_itext validateInput' type='text' placeholder='" +
                        LS.EnterVerificationCode +
                        "' id='M" +
                        moduleId +
                        "siteFormValidateCode' maxlength='4' msg='" +
                        LS.EnterVerificationCode +
                        "' />"
                );
                content.push(
                    "<img class='validateCodeImg' id='M" +
                        moduleId +
                        "FvalidateCodeImg' onclick=\"Mobi.siteFormValidation('', " +
                        moduleId +
                        ")\" title='换一换' src='/validateCode.jsp?vCodeId=" +
                        moduleId +
                        '&' +
                        Math.random() * 1000 +
                        "'/>"
                );
                content.push(
                    "<div onclick=\"Mobi.siteFormValidation('', " +
                        moduleId +
                        ")\" class='left changeSiteFormValidate'></div>"
                );
                content.push("<font class='bookingSubmitItemMust'>*</font>");
                content.push('</div></div>');
                content.push('</div>');
            }
        }

        content.push('</div>');
        content.push('</div>');

        // 提交表单出错时提示
        if (!(VITE_APP_MODE !== 'visitor')) {
            content.push("<div id='siteFormMsgM" + pageData.moduleId + "F-1' class='siteFormMsg g_warning'></div>");
        }

        content.push("<div class='siteFormAddButton " + (showType == 1 ? 'verticalShowTye' : '') + "'>\n");
        content.push(
            "<div id='M" +
                pageData.moduleId +
                "siteFormAddButton' class='siteFormSend'><input type='button' class='g_button sendIcon g_main_bgColor_v3' value='" +
                pageData.buttonName +
                "' /></div>\n"
        );
        content.push('</div>\n');
    } else {
        var bookingCloseTip = pageData.bookingClosedTip;
        if (VITE_APP_MODE !== 'visitor') {
            bookingCloseTip +=
                '，' +
                pageData.pleaseClick +
                "<a style='color:#333;' href='javascript:window.open(\"" +
                pageData.bookingEditUrl +
                '");\'>' +
                pageData.setUp +
                '</a>';
        }

        content.push(
            "<div style='color:#333; font-size:0.6rem; text-align:center; padding-top:45%; padding-bottom:45%;'>" +
                bookingCloseTip +
                '</div>'
        );
    }
    content.push('</div>');
    var contentStr = content.join('');
    jm('#productBookingPanel').append(contentStr);

    Fai.top._bookingModuleTitle = moduleInfo.content.mt;
    jm('#productBookingPanel .pageTitle').text(Fai.top._bookingModuleTitle);

    if (bookingOpen) {
        bookingSubmitPanleEventInit(pageData);
    }
    const itemList = [
        {
            text: '编辑模块',
            title: '编辑模块',
            display: 'text',
            onClick: () => {
                Mobi.logDog(200332, 18);
                popupWindow({
                    title: '编辑在线预约模块',
                    frameSrcUrl: `${getMobiMultiLanguageUrl()}/manage/mobiBookingSubmitModuleEdit.jsp?extId=${
                        Fai.top._extId
                    }&colId=${Fai.top._colId}&ram=${Math.random()}`,
                    width: 564,
                    height: 460,
                    popUpWinZIndex: 9032,
                    version: 2,
                    closeFunc: showBookingBox,
                });
            },
        },
        {
            text: '编辑预约',
            title: '编辑预约',
            display: 'icon',
            iconClass: 'manageGroup',
            onClick: () => {
                Mobi.logDog(200332, 19);
                popupWindow({
                    title: '编辑在线预约',
                    frameSrcUrl:
                        getSiteMultiLanguageUrl() + '/manage_v2/bookingEdit.jsp?_isMobi=true&ram=' + Math.random(),
                    width: 830,
                    height: 710,
                    popUpWinZIndex: 9032,
                    version: 2,
                    closeFunc: showBookingBox,
                });
            },
        },
        {
            text: '管理预约',
            title: '管理预约',
            display: 'icon',
            iconClass: 'editBook',
            onClick: () => {
                Mobi.logDog(200332, 20);
                showTopManageFrame('manageFrameBookingManage', Fai.top.backUrl, Fai.top.lanCode, false, false, true);
            },
        },
    ];
    if (VITE_APP_MODE !== 'visitor') {
        const $module = $(`#module${moduleId}`);
        $module.off('mousemove.toolbar').on('mousemove.toolbar', function () {
            Vue.prototype.$layer.addModuleLayer(`module${moduleId}`, itemList);
        });

        $module.off('mouseleave.toolbar').on('mouseleave.toolbar', function () {
            setTimeout(() => {
                Vue.prototype.$layer.removeModuleLayer(jm(this).attr('id'));
            }, 500);
        });
    }
};

const genBookingInputStr = function (data, pageData, moduleInfo) {
    var moduleId = pageData.moduleId,
        id = data.id,
        type = data.type,
        name = data.name,
        must = data.must,
        headline = data.headline,
        input = data.input,
        showType = moduleInfo.content.st,
        isIpad = pageData.isIpad;

    var tmpArr2 = input.split('\n'); //把“;”分出新数组
    var tmpLine = '';
    var tmpInput = '';
    var placeholder = data.placeholder == undefined ? '' : data.placeholder;
    var wordLimit = { o: 0, i: 0, a: 100 };
    if (data.wordLimit != undefined) {
        wordLimit = JSON.parse(data.wordLimit);
    }

    if (type == pageData.Def_ContentList_Type_SINGLE_LINE) {
        tmpInput =
            "<input type='text' class='g_itext' placeholder='" +
            encodeHtml(placeholder) +
            "' maxlength='" +
            wordLimit.a +
            "' name='M" +
            moduleId +
            'siteFormInput' +
            id +
            "' value='" +
            encodeHtml(typeof pageData.memberName == 'undefined' || !data.sys ? '' : pageData.memberName) +
            "' id='M" +
            moduleId +
            'siteFormInput' +
            id +
            "' />\n";
    } else if (type == pageData.Def_ContentList_Type_MULTI_LINE) {
        tmpInput =
            "<textArea class='g_textarea fk_formTextareaStyle' placeholder='" +
            encodeHtml(placeholder) +
            "' maxlength='" +
            pageData.Def_LIMIT_TEXTAREA_MAXLEN +
            "' id='M" +
            moduleId +
            'siteFormTextArea' +
            id +
            "' cols='50' rows='3' /></textArea>\n";
    } else if (type == pageData.Def_ContentList_Type_RADIO) {
        tmpInput += "<div class='fk-siteFormItemCheckWrap'>";
        tmpInput += "<div class='fk-siteFormItemCheckList'>";
        for (var i = 0; i < tmpArr2.length; i++) {
            tmpInput +=
                "<div class='siteFormItemCheckItem " +
                (isIpad ? 'fk-siteFormItemCheckItem-isIpad' : '') +
                "'><input type='radio' id='M" +
                moduleId +
                'siteFormRadio' +
                id +
                'I' +
                i +
                "' name='M" +
                moduleId +
                'siteFormRadioR' +
                id +
                "' value='" +
                encodeHtml(tmpArr2[i]) +
                "'/><label for='M" +
                moduleId +
                'siteFormRadio' +
                id +
                'I' +
                i +
                "'>" +
                encodeHtml(tmpArr2[i]) +
                '</label></div>\n';
        }
        tmpInput += '</div>';
        tmpInput += '</div>';
    } else if (type == pageData.Def_ContentList_Type_MULTIPLECHOICE) {
        tmpInput += "<div class='fk-siteFormItemCheckWrap'>";
        tmpInput += "<div class='fk-siteFormItemCheckList'>";
        for (let i = 0; i < tmpArr2.length; i++) {
            tmpInput +=
                "<div class='siteFormItemCheckItem " +
                (isIpad ? 'fk-siteFormItemCheckItem-isIpad' : '') +
                "'><input type='checkbox' id='M" +
                moduleId +
                'siteFormCheckbox' +
                id +
                'I' +
                i +
                "' name='M" +
                moduleId +
                'siteFormCheckboxR' +
                id +
                "' value='" +
                encodeHtml(tmpArr2[i]) +
                "'/><label for='M" +
                moduleId +
                'siteFormCheckbox' +
                id +
                'I' +
                i +
                "'>" +
                encodeHtml(tmpArr2[i]) +
                '</label></div>\n';
        }
        tmpInput += '</div>';
        tmpInput += '</div>';
    } else if (type == pageData.Def_ContentList_Type_DROPDOWN) {
        tmpInput +=
            "<select class='g_selectTag fk-selectStyle' id='M" +
            pageData.moduleId +
            'siteFormSelect' +
            id +
            "' name='M" +
            moduleId +
            'siteFormSelect' +
            id +
            "'>\n";
        tmpInput += "<option value='none'>" + LS.mallPleaseChoose + '</option>';
        for (let i = 0; i < tmpArr2.length; i++) {
            tmpInput += "<option value='" + encodeHtml(tmpArr2[i]) + "'>" + encodeHtml(tmpArr2[i]) + '</option>\n';
        }
        tmpInput += '</select>';
    } else if (type == pageData.Def_ContentList_Type_DESC) {
        tmpInput += "<div class='fk-siteFormTextTip fk-siteFormTextTipMain'>" + encodeHtml(headline) + '</div>\n'; //headline
        tmpInput += "<div class='fk-siteFormTextTip fk-siteFormTextTipSecond'>" + encodeHtml(input) + '</div>\n'; //siteFormItemInput
    } else if (type == pageData.Def_ContentList_Type_DATE) {
        var dateSetting = data.dateSetting;
        var dateRange = (dateSetting.type == undefined ? 0 : dateSetting.type) == 1;
        tmpInput +=
            "<div class='" +
            (dateRange ? 'fk-startDateInput' : '') +
            " mobiCalendarPlugins' id='Mobi" +
            moduleId +
            'Calendar' +
            id +
            "Plugins' val=''></div>\n";
        // 结束日期输入框
        if (dateRange) {
            tmpInput +=
                "<div class='fk-endDateInput mobiCalendarPlugins' id='Mobi" +
                moduleId +
                'Calendar' +
                id +
                "Plugins_end' val=''></div>\n";
        }
    } else if (type == pageData.Def_ContentList_Type_FILE_UPLOAD) {
        tmpInput += "<div class='fk-uploadComponent'>\n";
        tmpInput += "<span id='uploadButton" + pageData.moduleId + id + "'>\n";

        tmpInput +=
            "<input readonly='readonly' type='button' class='uploadify-button' onclick='" +
            (VITE_APP_MODE !== 'visitor' ? 'javascript:alert("当前为管理状态，提交无效。");' : '') +
            "' value='" +
            '选择文件' +
            "'/>\n";

        tmpInput +=
            "<div class='uploadify-text' id='siteForm" +
            moduleId +
            'fileName' +
            id +
            "' _tmpFileId='' >" +
            '未选择文件' +
            '</div>\n'; //加一个多语言参数
        tmpInput += '</span>\n';

        tmpInput += '</div>\n';
    } else if (type == pageData.Def_ContentList_Type_PHONE) {
        tmpInput =
            "<input onkeyup='(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,\"\");}).call(this)' oninput='if(value.length>11)value=value.slice(0,11);' type='number' class='g_itext' maxlength='11' placeholder='" +
            encodeHtml(placeholder) +
            "' id='M" +
            moduleId +
            'siteFormPhoneInput' +
            id +
            "' value='" +
            encodeHtml(
                typeof pageData.memberMobile == 'undefined' || !/^[0-9]*$/.test(pageData.memberMobile) || !data.sys
                    ? ''
                    : pageData.memberMobile
            ) +
            "' />";
    } else if (type == pageData.Def_ContentList_Type_MAIL) {
        tmpInput =
            "<input type='text' class='g_itext' maxlength='20' name='M" +
            moduleId +
            'siteFormMailInput' +
            id +
            "' id='M" +
            moduleId +
            'siteFormMailInput' +
            id +
            "' placeholder='" +
            encodeHtml(placeholder) +
            "'/>\n";
    } else if (type == pageData.Def_ContentList_Type_IDENTITY) {
        tmpInput =
            "<input type='text' class='g_itext' maxlength='18' name='M" +
            moduleId +
            'siteFormIndentityInput' +
            id +
            "' id='M" +
            moduleId +
            'siteFormIndentityInput' +
            id +
            "' placeholder='" +
            encodeHtml(placeholder) +
            "'/>\n";
    } else if (type == pageData.Def_ContentList_Type_ADDRESS) {
        tmpInput =
            "<input type='text' class='g_itext' placeholder='" +
            LS.mallPleaseChoose +
            "' maxlength='20' name='M" +
            moduleId +
            'siteFormAddressInput' +
            id +
            "' id='M" +
            moduleId +
            'siteFormAddressInput' +
            id +
            "' />\n";
    }

    if (showType == 1) {
        //上下展示
        if (type != 5) {
            tmpLine += "<div class='siteFormItem siteFormItemTr f-siteFormItemTr' >\n";
            tmpLine += "<div class='siteFormItemShowName' width='100%'>" + encodeHtml(name) + '</div>\n';
            tmpLine += '</div>\n';
            tmpLine += "<div class='siteFormItem siteFormItemTr' id='siteFormItem" + id + "'>\n";
            tmpLine +=
                "<div class='siteFormItemTd' width='100%'>" +
                tmpInput +
                (must && type != 7 ? "<font class='bookingSubmitItemMust'>*</font>" : '') +
                '</div>\n';
            tmpLine += '</div>\n';
        } else {
            tmpLine += "<div class='siteFormItem siteFormItemTr'>\n";
            tmpLine += "<div class='siteFormItemTd'>" + tmpInput + '</div>\n';
            tmpLine += '</div>\n';
        }
    } else {
        //左右展示
        tmpLine += "<div class='siteFormItem' id='siteFormItem" + id + "'>\n";
        if (type != 5) {
            tmpLine +=
                "<div style='width: 23%;float: left; height:100%;'><div class='siteFormItemShowName'><table style='table-layout:fixed;text-align:right; height:100%;' width='100%' cellpadding='0' cellspacing='0'><tr><td style='overflow:hidden; text-overflow:ellipsis; white-space:nowrap; padding-left:0.5rem;' width='100%'>" +
                encodeHtml(name) +
                '</td></tr></table></div></div>\n';
            tmpLine +=
                "<div style='width: 77%;float: left;'><div class='siteFormItemShowVal'>" +
                tmpInput +
                (must ? "<font class='bookingSubmitItemMust'>*</font>" : '') +
                '</div></div>\n';
        } else {
            tmpLine +=
                "<div style='width: 23%;float: left; height:100%;'><div class='siteFormItemShowName'><table style='table-layout:fixed;text-align:right; height:100%;' width='100%' cellpadding='0' cellspacing='0'><tr><td style='overflow:hidden; text-overflow:ellipsis; white-space:nowrap; padding-left:0.5rem;' width='100%'>" +
                encodeHtml(headline) +
                '</td></tr></table></div></div>\n';
            tmpLine +=
                "<div style='width: 77%;float: left; height:100%; font-size:0.6rem;'><div class='siteFormItemShowVal' style='height:100%;'><table style='height:100%;'><tr><td>" +
                encodeHtml(input) +
                '</td></tr></table></div></div>\n';
        }
        tmpLine += '</div>\n';
        tmpLine += "<div class='siteFormItemLink'></div>\n";
    }

    return tmpLine;
};
