<template>
    <span class="quality_content_label">{{ text }}</span>
</template>

<script>
export default {
    name: 'QualityContentLabel',
    props: {
        text: {
            type: String,
            require: true,
            default: '图文',
        },
    },
};
</script>

<style></style>
