import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import VoteView from './module/vote.vue';

class _Vote extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.VOTE, options);
    }

    render() {
        return VoteView;
    }
}

export const Vote = new _Vote();
