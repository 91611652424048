<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" :id="'carouselMultiPhoto' + module.id" class="carouselMultiPhoto">
            <template v-if="!cycleList.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板添加图片</div>
                    <button-component active class="addPhoto" @click.stop="addPhoto">添加图片</button-component>
                </div>
            </template>
            <template v-if="!cycleList.length && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加图片</div>
                </div>
            </template>
            <template v-if="cycleList.length">
                <div class="content_wrap">
                    <component
                        :is="getCarouselItem(carouselType)"
                        :photo-swipe-list="photoSwipeList"
                        :name-list="nameList"
                        :cycle-list="cycleList"
                        :no-lazy-load="noLazyLoad"
                    />
                </div>
                <image-viewer
                    v-if="showMobiViewer"
                    :z-index="zIndex"
                    :initial-index="initialIndex"
                    :on-close="onClose"
                    :url-list="photoSwipeList"
                    :is-mobi="true"
                    :content-terminal="'mobi'"
                    :manage-mode="manageMode"
                    :log-event-config="logEventConfig"
                    :document-event-el="documentEventEl"
                    append-to-container-id="g_body"
                    :append-to-body="false"
                    :disable-long-press="banCopyTextImg"
                />
            </template>
        </div>
    </module-frame>
</template>

<script>
import * as CarouselTypes from './CarouselTypes';
import { slidesshowMixins } from '@/modules/moduleMixins';
import { isBrowserEnv } from '@jz/utils';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { initPhotoSwipe, setPrImageMaxHeight, initPhotoImageSwipe } from '@/modules/shared/photo';
import { encodeHtml } from '@/shared/util';

import { mapGetters } from 'vuex';
import ModuleFrame from '@/modules/frame/index.vue';

export default {
    name: 'CarouselMultiPhoto',
    components: {
        ModuleFrame,
    },
    mixins: [slidesshowMixins],
    provide() {
        return {
            module: this.module,
            options: this.options,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        let module = this.$store.state.currentPageModuleIdMap[this.moduleId];
        let options = module.renderOptions || {};
        let args = options.args || {};
        let cycleList = args.cycleList || [];
        return {
            render: true,
            cycleList,
            noLazyLoad: options.isFirstScreen || false,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-轮播多图',
                    jz_version: isBrowserEnv() ? Fai.top.jzVersion : 0,
                },
            },
            manageMode: VITE_APP_MODE !== 'visitor',
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        id() {
            return this.module.id;
        },
        styleId() {
            return this.module.style;
        },
        options() {
            return this.module.renderOptions;
        },
        carouselType() {
            return this.module.content.type;
        },
        moduleType() {
            return this.module.content.type;
        },
        nameList() {
            var nameList = [];
            if (this.moduleType == 6) {
                for (var i = 0; i < this.cycleList.length; i++) {
                    if (this.module.content.apw === 0) {
                        if (this.cycleList[i].desc != undefined) {
                            nameList.push(encodeHtml(this.cycleList[i].desc));
                        }
                    } else if (this.module.content.apw === 1) {
                        if (this.cycleList[i].basic != undefined) {
                            nameList.push(this.cycleList[i].basic);
                        }
                    }
                }
            }
            return nameList;
        },
        photoSwipeList() {
            var photoSwiperList = [];
            if (
                this.moduleType === 6 ||
                this.moduleType === 7 ||
                this.moduleType === 8 ||
                this.moduleType === 9 ||
                this.moduleType === 10 ||
                this.moduleType === 11 ||
                this.moduleType === 12 ||
                this.moduleType === 13
            ) {
                if (this.module.content.apw == 1) {
                    for (let i = 0; i < this.cycleList.length; i++) {
                        let pitem = {};
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.cycleList[i].index = i;
                        pitem.src = this.cycleList[i].picPath;
                        pitem.id = this.cycleList[i].pic;
                        pitem.title = typeof this.cycleList[i].name == 'undefined' ? '' : this.cycleList[i].name;
                        pitem.desc = this.cycleList[i].basic;
                        pitem.description = this.cycleList[i].basic;
                        pitem.openLink =
                            this.cycleList[i].linkType == 2 && this.module.content.link != 1 ? true : false;
                        pitem.width = this.cycleList[i].rect_640_width;
                        pitem.height = this.cycleList[i].rect_640_height;
                        pitem.pid = this.cycleList[i].id;
                        pitem.groupId = this.cycleList[i].groupId;
                        pitem.nf = this.cycleList[i].nf;
                        pitem.detail = this.cycleList[i].linkType == 1;
                        pitem.detailContent = this.cycleList[i].detailContent || '';

                        photoSwiperList.push(pitem);
                    }
                } else if (this.module.content.apw == 0) {
                    for (let i = 0; i < this.cycleList.length; i++) {
                        let pitem = {};
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.cycleList[i].index = i;
                        pitem.src = this.cycleList[i].picPath;
                        pitem.id = this.cycleList[i].picId;
                        pitem.title = encodeHtml(this.cycleList[i].name) || '';
                        pitem.desc = this.cycleList[i].desc;
                        pitem.description = this.cycleList[i].desc;
                        pitem.openLink = this.cycleList[i].openLink;
                        pitem.width = this.cycleList[i].rect_640_width;
                        pitem.height = this.cycleList[i].rect_640_height;
                        pitem.pid = 0;
                        pitem.groupId = this.cycleList[i].groupId;
                        pitem.detail = this.cycleList[i].linkType == 1;
                        pitem.nf = this.cycleList[i].nf;
                        pitem.detailContent = this.cycleList[i].detailContent || '';
                        photoSwiperList.push(pitem);
                    }
                }
            }
            return photoSwiperList;
        },
    },
    watch: {
        module: {
            handler: function () {
                this.noLazyLoad = true;
                if (VITE_APP_MODE !== 'visitor') {
                    this.refreshModuleData();
                }
            },
            deep: true,
        },
    },
    mounted() {
        bindEvent(this);
    },
    methods: {
        getCarouselItem(carouselType) {
            return CarouselTypes[`CarouselType_${carouselType}`];
        },
        addPhoto(e) {
            e.stopPropagation();
            this.$designer.open({
                panelType: 'pattern carousel-multi-photo basis',
                moduleId: this.id,
                styleId: this.styleId,
            });
        },
        refreshModuleData() {
            if (this.module.content.showMore) {
                if (this.module.content.jumpInfo.t == 103 && this.module.content.jumpInfo.u != '') {
                    this.options.args.bannerMore =
                        'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                } else {
                    this.options.args.bannerMore = this.module.content.jumpInfo.u;
                }
                if (this.module.content.jumpInfo.reqArgs) {
                    this.options.args.reqArgs = this.module.content.jumpInfo.reqArgs;
                } else {
                    this.options.args.reqArgs = '';
                }
            } else {
                this.options.args.bannerMore = '';
            }

            if (this.module.content.apw === 1) {
                this.cycleList = this.module.content.photoGroupImgList;
            } else {
                this.cycleList = this.module.content.picList;
            }
            this.render = false;
            this.$nextTick(() => {
                this.render = true;
                this.$nextTick(() => {
                    bindEvent(this);
                });
            });
        },
    },
};

function bindEvent(context) {
    var moduleType = context.module.content.type;
    if (moduleType == 7) {
        if (context.module.content.picScale == 1) {
            setPrImageMaxHeight(context.id, '.photoImageItem', false);
        }
        initPhotoImageSwipe('photoModuleImageSwipe' + context.id, context.module.content.picScale);
    } else if (moduleType == 6) {
        if (context.module.content.picScale == 1) {
            setPrImageMaxHeight(context.id, '.imageDiv', true);
        }
        if (context.cycleList != null) {
            initPhotoSwipe(
                context.id,
                context.nameList,
                context.module.content.interval,
                context.module.content.animate,
                context.module.content.picScale
            );
        }
    }
    if (!context.noLazyLoad || !context.manageMode) {
        if (!context.options.isFirstScreen) {
            bindImgLazyLoad(`module${context.module.id}`);
        }
    }
}
</script>
