<template>
    <module-frame :module-id="moduleId">
        <div class="wx_addFans">
            <svg v-if="!isCustomizedIMG" class="default_icon icon" :style="imgObj">
                <use xlink:href="#jzm-v42"></use>
            </svg>
            <div v-else :style="customImgObj" class="default_img icon J_img_lazyload" :src-original="imgSrc"> </div>
            <div class="account_information">
                <span v-if="isShowName" class="account_information_name" :style="nameStyle" v-html="name"></span>
                <span
                    v-if="isShowAccount"
                    class="account_information_account"
                    :style="accountStyle"
                    v-html="account"
                ></span>
            </div>
            <div
                :id="'wx_addFans' + module.id"
                ref="btn"
                class="add_button g_main_bgColor_v3 g_oldThemeBgColor"
                @click="jump"
                v-html="btnText"
            ></div>
        </div>
    </module-frame>
</template>

<script>
import { encodeHtml } from '@/shared/util';
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters } from 'vuex';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { logDog } from '@/api/logDog';
import { copyAccountAndOpenWX } from './service';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';
export default {
    name: 'WXAddFans',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(['getModuleById']),
        options() {
            return this.module.renderOptions;
        },
        noLazyLoad() {
            return this.options.isFirstScreen;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        content() {
            return this.module.content;
        },
        name() {
            return encodeHtml(this.content.name);
        },
        account() {
            return encodeHtml(this.content.account);
        },
        btnTextType() {
            return this.content.btt;
        },
        isCustomizedText() {
            return this.content.btt === 1;
        },
        isCustomizedIMG() {
            return this.content.i.t === 1;
        },
        isCustomizedIMGSize() {
            return this.content.is.t === 1;
        },
        btnText() {
            return this.isCustomizedText ? encodeHtml(this.content.bt) : '添加微信';
        },
        isShowName() {
            return this.content.isn;
        },
        isShowAccount() {
            return this.content.isa;
        },
        nameStyle() {
            return this.transformDataToStyleObj(this.content.ns);
        },
        accountStyle() {
            return this.transformDataToStyleObj(this.content.as);
        },
        defaultPhotoPath() {
            return this.module.extInfo.defaultPhotoPath;
        },
        imgSrc() {
            return this.module.extInfo.sp || this.defaultPhotoPath;
        },
        imgObj() {
            const IS_DEFAULT = this.content.is.t === 0;

            let imgSize = this.content.is.s;

            return IS_DEFAULT
                ? {}
                : {
                      width: `${imgSize}rem`,
                      height: `${imgSize}rem`,
                  };
        },
        customImgObj() {
            const IS_DEFAULT_SIZE = this.content.is.t === 0;

            let imgSize = this.content.is.s;

            let sizeObj = IS_DEFAULT_SIZE
                ? {}
                : {
                      width: `${imgSize}rem`,
                      height: `${imgSize}rem`,
                  };

            let imgSrc = this.noLazyLoad ? this.imgSrc : this.options.loadingPath;

            return Object.assign(sizeObj, {
                'background-image': `url(${imgSrc})`,
                'background-size': 'cover',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
            });
        },
    },
    watch: {
        imgSrc: {
            handler: function () {
                this.noLazyLoad = true;
            },
        },
        'content.is': {
            handler: function () {
                this.noLazyLoad = true;
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.options.isFirstScreen) {
            bindImgLazyLoad(`module${this.module.id}`);
        }
    },
    methods: {
        transformDataToStyleObj(data) {
            const { t: type } = data;
            const HTML_FONT_SIZE = NEW_MANAGE_HTML_FONTSIZE;
            const DEFAULT_FONT_SIZE = 12;

            const FONT_SIZE = data.fs ? data.fs : DEFAULT_FONT_SIZE / HTML_FONT_SIZE;

            let configStyle = {
                'font-size': `${FONT_SIZE}rem`,
                color: data.c,
                'font-style': data.it ? 'italic' : 'normal',
                'font-weight': data.ib ? 'bold' : 'normal',
                'text-decoration': data.iu ? 'underline' : 'none',
            };

            const IS_DEFAULT_STYLE = type === 0;

            return IS_DEFAULT_STYLE ? {} : configStyle;
        },
        jump() {
            logDog(201208, 13);
            copyAccountAndOpenWX(this.account);
        },
    },
};
</script>

<style scoped>
.wx_addFans {
    display: flex;
    align-items: center;
    padding: 0.6rem 1.12rem 0.6rem 0.86rem;
}
.wx_addFans .icon {
    margin-right: 0.6rem;
    flex-shrink: 0;
}
.wx_addFans .default_icon {
    width: 1.7rem;
    height: 1.7rem;
    fill: #30cf30;
}

.wx_addFans .default_img {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
}
.wx_addFans .account_information {
    display: flex;
    flex-direction: column;
    margin-right: 0.6rem;
}

.wx_addFans .account_information_name {
    font-size: 0.6rem;
    color: #333;
    word-break: break-all;
    font-weight: bold;
}

.wx_addFans .account_information_account {
    font-size: 0.51rem;
    color: #999;
    word-break: break-all;
}

.wx_addFans .add_button {
    padding: 0 0.6rem;
    flex-shrink: 0;
    white-space: nowrap;
    word-break: keep-all;
    border-radius: 0.7rem;
    color: #fff;
    font-size: 0.5rem;
    margin-left: auto;
    line-height: 1rem;
    height: 1rem;
}
</style>
