import { logDog, logJZFdp } from '@/api/logDog';
import { ing } from '@/shared/tips';
import { storageReturnUrl } from '@/shared/url';
import { preprocessUrl } from '@/shared/url';
import { addRequestPrefix } from '@/shared/util';
import { jzPayUtils, BussinessType } from '@/shared/jzPayUtils';
import { changeHref } from '@/features/router/universal';

function logKnowlegdePayMentByJz(state) {
    if ((VITE_APP_MODE !== 'visitor') === false) {
        const type = Number(jm('.J_pd_detail').data('content-type'));
        const typeMap = ['视频', '图文', '专栏', '音频'];
        const legalType = typeof type === 'number' && type >= 0 && type < typeMap.length;

        logJZFdp('jz_c_knowlegde_payment', {
            jz_free_text1: state,
            jz_content_terminal: 'mobi',
            jz_free_text2: legalType ? typeMap[type] : '',
        });
    }
}
export function productKnowPayBtnClick(productId) {
    if (VITE_APP_MODE !== 'visitor') {
        ing('当前为管理态，不支持购买');
    } else if (Fai.top.sessionMemberId == 0) {
        // 跳转登录
        logDog(201356, 3);
        logKnowlegdePayMentByJz('登录状态');
        storageReturnUrl(Fai.top.location.href);
        changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp');
        return;
    } else {
        // 对接购买接口
        // let mock = location.search.includes("mock")
        logDog(201356, 3);
        jm.ajax({
            type: 'post',
            url:
                addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) +
                '/product_h.jsp?cmd=setWafCk_genKnowProductOrder',
            data: 'productId=' + productId,
            success(result) {
                if (result != null) {
                    let resultJson = jm.parseJSON(result);
                    if (resultJson.success) {
                        logDog(201356, 4);
                        if (resultJson.isZero) {
                            ing('购买成功');
                            logKnowlegdePayMentByJz('购买成功');
                            let jumpUrl = '';
                            let { origin, pathname, search, hash } = window.location;
                            try {
                                let urlSearchParam = new URLSearchParams(search);
                                // 【用户域名购买知识付费产品后页面未自动更新购买状态】https://www.tapd.cn/42954297/bugtrace/bugs/view?bug_id=1142954297001135971
                                urlSearchParam.append('random', jzUtils.createRandomId(4));
                                jumpUrl = `${origin}${pathname}?${urlSearchParam}${hash}`;
                            } catch (err) {
                                jumpUrl = window.location.href;
                            }
                            window.location.href = jumpUrl;
                        } else {
                            logKnowlegdePayMentByJz('支付状态');
                            let jumpUrl = '';
                            let { origin, pathname, search, hash } = window.location;
                            try {
                                let urlSearchParam = new URLSearchParams(search);
                                urlSearchParam.delete('openId'); // 移除openId, 避免影响支付。openId里面会有空格，导致支付异常
                                jumpUrl = `${origin}${pathname}?${urlSearchParam}${hash}`;
                            } catch (err) {
                                jumpUrl = window.location.href;
                            }

                            jzPayUtils.normalOpenPay({
                                id: productId,
                                bssId: resultJson.knowProductOrderId,
                                bssType: BussinessType.BSSTYPE_KNOWPRODUCT,
                                sucUrl: encodeURIComponent(jumpUrl),
                                failUrl: encodeURIComponent(jumpUrl),
                            });
                        }
                    } else {
                        const { memberNotExit, rt } = resultJson;
                        if (memberNotExit) {
                            storageReturnUrl(Fai.top.location.href);
                            changeHref(preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp');
                        } else if (rt == -397) {
                            ing(LS.paymentAmountOver);
                        } else {
                            ing(resultJson.msg);
                        }
                    }
                }
            },
            error() {
                ing('系统错误');
            },
        });
    }
}
