import { initWebPage } from '@/shared/misc/init-webpage';
import { addRequestPrefix } from '@/shared/util';
export function initCourseCatalog(belongToProductId, productId) {
    let ajax_data = `columnProductId=${belongToProductId}&pid=${productId}`;
    jm.ajax({
        type: 'post',
        url:
            addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/product_h.jsp?cmd=getWafNotCk_getCourseCatalog',
        data: ajax_data,
        success(res) {
            let { html: body = '', productUrl: columnUrl, isFinPay } = JSON.parse(res);
            let footer = '';
            if (!isFinPay) {
                footer = `
                <a class="course_catalog_jump_column_btn g_oldThemeBgColor g_main_bgColor_v3" href="${columnUrl}">前往购买</a>
                `;
            }
            let html = `
                <div class="course_catalog_container">
                    <div class="course_catalog_container_header">
                        <a class="course_catalog_jump" href="${columnUrl}">
                            <svg class="course_catalog_jump_icon">
                                <use xlink:href="#jzm-vicon_arrow_1"></use>
                            </svg>
                            <span class="course_catalog_jump_text">返回专栏</span>
                        </a>
                        <span class="course_catalog_title">课程目录</span>
                        <svg class="course_catalog_close_iocn">
                            <use xlink:href="#jzm-vicon_close"></use>
                        </svg>
                    </div>
                    ${body}
                    ${footer}
                </div>
            `;
            initWebPage({
                triggerId: 'J_courseCatalogBtn', // 触发id
                pageBg: true,
                direction: 'bottom',
                closeWebPage: '.course_catalog_close_iocn', // 关闭按钮
                pageHtml: `${html}`, // 节点
                returnSeltFun: true,
            });
        },
    });
}
