import { isLabelModule } from '../type-mapping';
import { MODULE_STYLE } from '@/def/module';
import { resetModuleAddState } from '@/modules/comm';

/*
 *添加模块面板不可编辑区域（如自由容器添加模块时，排版类模块等区域被禁止）
 *by json
 */
export let panelDisableArea = {};
panelDisableArea.Data = {
    disableArea: [],
    layoutModuleContent: [],
    disableSeveralModule: [],
    showModuleCash: [], // 缓存module的显示状态
};
panelDisableArea.disableArea = [];
panelDisableArea.hasDisableAddModuleArea = false; // 标志此时的状态，是否是禁用状态
panelDisableArea.disableAddModuleArea = ''; // 记录模块名称
panelDisableArea.disableEditBtnId; // 上一次添加模块记录的模块id
panelDisableArea.hasDisableSeveralModule = false; // 判断是否需要单独禁用某些模块
panelDisableArea.baseData = {
    btnClass: 'addBtn_Icon',
    disableSeveralModuleList: [],
};

//初始化相关容器禁用编辑区域的数据
panelDisableArea.initDisableModuleAreaData = function (id) {
    var disableArea = [];

    if (!id) {
        return;
    }
    panelDisableArea.disableAddModuleArea = id;
    panelDisableArea.disableArea = disableArea;
};

//创建排版类禁用层
panelDisableArea.createDisableMask = function () {
    panelDisableArea.removeDisableMask();
    panelDisableArea.Data.disableArea = Fai.top.v_colModuleManagePanel.commData.addModuleList || [];
    panelDisableArea.Data.showModuleCash = JSON.parse(JSON.stringify(panelDisableArea.Data.disableArea)) || []; // 用于remove时恢复到原来的状态
    panelDisableArea.hasDisableAddModuleArea = true;

    $.each(panelDisableArea.Data.disableArea, function (index, val) {
        if (panelDisableArea.disableArea.indexOf(val.groupName) > -1) {
            val.noModuleTips = '该容器模块内无法添加' + val.groupName + '模块';
            for (var j = 0; j < val.list.length; j++) {
                val.list[j].show = false;
            }
        }
    });

    // 针对某些模块的
    if (panelDisableArea.hasDisableSeveralModule) {
        panelDisableArea.showOrHideDisableModule(true);
    }
};

//删除排版类禁用层
panelDisableArea.removeDisableMask = function (flag, e) {
    // flag --> false时移除禁用层与清除按钮状态

    var $editBtn = $('.' + panelDisableArea.baseData.btnClass),
        disableArea = panelDisableArea.Data.disableArea,
        showModuleCash = panelDisableArea.Data.showModuleCash,
        modules,
        cashModules;

    if (!flag) {
        for (let i = 0; i < disableArea.length; i++) {
            modules = disableArea[i];
            cashModules = showModuleCash[i];

            for (var j = 0; j < modules.list.length; j++) {
                modules.list[j].show = cashModules.list[j].show;
                modules.noModuleTips = '';
            }
        }

        panelDisableArea.hasDisableAddModuleArea = false;
        panelDisableArea.Data.disableArea = [];
        panelDisableArea.Data.showModuleCash = [];
        panelDisableArea.disableAddModuleArea = '';

        // 针对某些模块的
        if (panelDisableArea.hasDisableSeveralModule) {
            panelDisableArea.showOrHideDisableModule(false);
        }
    }

    $.each($editBtn, function (index, val) {
        var $btn = $(val);
        if ($btn.parents('.empty_tips_panel').length) {
            if ($btn.hasClass('f-disableEdit')) {
                $btn.removeClass('f-disableEdit');
                $btn.attr('_status', '');
                $btn.text('添加模块');
            }
        } else {
            $btn.hasClass('f-disableEdit') && $btn.removeClass('f-disableEdit') && $btn.attr('_status', '');
        }
    });

    // 自由容器不是走原逻辑，不适用一下清除状态的逻辑
    let { moduleAdd } = window.$store.state.manage;
    let { containerStyle } = moduleAdd;
    const isClickPack = containerStyle == MODULE_STYLE.PACK;
    if (isClickPack) {
        return;
    }
    // 如果点击了增加模块后 点击了其他地方 则清除位置信息。如果是点击了添加模块面板内就不清除
    if (!e || (!!e && $(e.target).closest('.formDialog').length < 1)) {
        resetModuleAddState();
    }

    Fai.top.addToPackId && delete Fai.top.addToPackId;
};

// 点击不相干区域，撤销禁用层
panelDisableArea.bindRemoveMaskEvent = function () {
    Fai.top
        .$(document)
        .unbind('click.doc')
        .bind('click.doc', function (e) {
            if (
                $(e.target).parents('.jz_modal').length ||
                $(e.target).parents('#module' + panelDisableArea.disableEditBtnId).length
            ) {
                return;
            }
            panelDisableArea.removeDisableMask(false, e);
            Fai.top.$(document).unbind('click.doc');
        });
};

// 更改按钮状态
panelDisableArea.changeAddBtn = function ($btn, moduleID, id) {
    var addStatus = !!$btn.attr('_status'),
        changeStatus = '',
        isPack = id == 'pack',
        isLabel = id == 'fold' || id == 'horizonTab';

    // 判断是否点击不同的添加模块的按钮，并且是要模块添加的状态
    // 是的话，就执行下面语句，只清除按钮状态
    if (panelDisableArea.disableEditBtnId != moduleID && !addStatus) {
        panelDisableArea.removeDisableMask(true);
    }

    if (!addStatus) {
        // 此时的状态是添加状态，需要切换成取消添加状态
        changeStatus = 'edit';
        $btn.addClass('f-disableEdit');
        isPack && (Fai.top.addToPackId = moduleID);
        if (isLabel) {
            $btn.text('取消添加模块');
        }
    } else {
        panelDisableArea.removeDisableMask();
        $btn.removeClass('f-disableEdit');
        isPack && delete Fai.top.addToPackId;
    }

    $btn.attr('_status', changeStatus);
};

// 初始化个别不可拖进的模块
panelDisableArea.initDisableSeveralModule = function () {
    var disableModuleList = panelDisableArea.baseData.disableSeveralModuleList,
        allModuleArea = Fai.top.v_colModuleManagePanel.commData.addModuleList || [],
        length = disableModuleList.length,
        curLength = 0,
        areaModules;

    if (disableModuleList.length > 0) {
        panelDisableArea.hasDisableSeveralModule = true;
        for (var i = 0; i < allModuleArea.length; i++) {
            // 如果已经找到全部模块，则跳出循环
            if (curLength == length) {
                break;
            }

            areaModules = allModuleArea[i].list;
            for (var j = 0; j < areaModules.length; j++) {
                if (disableModuleList.indexOf(areaModules[j].keyword) > -1) {
                    //拼接禁用的单个模块
                    panelDisableArea.Data.disableSeveralModule.push(areaModules[j]);
                    curLength++;
                }
            }
        }
    } else {
        return;
    }
};

// 展示或者隐藏个别不可拖进模块
panelDisableArea.showOrHideDisableModule = function (hide) {
    var showModuleCash = panelDisableArea.Data.showModuleCash,
        cashModules,
        breakFlag;

    if (!panelDisableArea.hasDisableSeveralModule) {
        return;
    } else {
        $.each(panelDisableArea.Data.disableSeveralModule, function (index, item) {
            breakFlag = false;
            if (hide) {
                item.show = false;
            } else {
                for (let i = 0; i < showModuleCash.length; i++) {
                    if (breakFlag) {
                        break;
                    }

                    cashModules = showModuleCash[i];
                    for (var j = 0; j < cashModules.list.length; j++) {
                        if (cashModules.list[j].keyword == item.keyword) {
                            item.show = cashModules.list[j].show;
                            breakFlag = true;
                            break;
                        }
                    }
                }
            }
        });
    }
};

panelDisableArea.disableFunc = function (id, event, moduleID, options, labelId) {
    var $editBtn = $(event.target);

    // 当前正在添加，再次点击就remove掉所有。
    if ($editBtn.attr('_status')) {
        panelDisableArea.removeDisableMask();
        return;
    }

    $.extend(panelDisableArea.baseData, options);
    // 判断是否初始化某些禁用的模块
    if (panelDisableArea.baseData.disableSeveralModuleList.length) {
        panelDisableArea.initDisableSeveralModule();
    }
    panelDisableArea.initDisableModuleAreaData(id, moduleID);
    panelDisableArea.createDisableMask();
    panelDisableArea.bindRemoveMaskEvent();
    panelDisableArea.changeAddBtn($editBtn, moduleID, id);
    if (id == 'fold' || id == 'horizonTab') {
        panelDisableArea.disableEditBtnId = labelId;
        // 联动面板，只展示可添加的子模块图标。
        const childFilter = (obj) => {
            return !isLabelModule(obj.style);
        };
        window.$store.dispatch('manage/moduleAdd/filterChildren', childFilter);
    } else {
        panelDisableArea.disableEditBtnId = moduleID;
    }
};
