import { parseInputBdCss, parseInputBgCss } from './utils';
import { encodeHtml } from '@/shared/util';
export default {
    computed: {
        _mixinsInputStyle() {
            const inputBgCss = parseInputBgCss(this.module.content.mhighs.ibg);
            const inputBdCss = parseInputBdCss(this.module.content.mhighs.ibd);
            return inputBgCss + inputBdCss;
        },
        pleaseSelectTips() {
            return this.module.extInfo.siteFormPleaseSelect;
        },
        picSize() {
            return this.module.content.picSize || {};
        },
        picSizeStyle() {
            if (this.picSize.t === 1) {
                return {
                    width:
                        this.module.content.od === 0
                            ? null
                            : VITE_APP_MODE !== 'visitor'
                            ? `${this.picSize.w}px`
                            : `${this.picSize.w / 23.4375}rem`,
                    height: VITE_APP_MODE !== 'visitor' ? `${this.picSize.h}px` : `${this.picSize.h / 23.4375}rem`,
                };
            } else {
                return;
            }
        },
        picNameStyle() {
            if (this.module.content.od) {
                return {};
            } else {
                const picSizeWidth =
                    this.module.content.od === 0
                        ? '100%'
                        : this.picSize.t === 1
                        ? VITE_APP_MODE !== 'visitor'
                            ? `${this.picSize.w}px`
                            : `${this.picSize.w / 23.4375}rem`
                        : '4rem';
                return {
                    'max-width': picSizeWidth,
                };
            }
        },
        picScaleStyle() {
            if (this.picSize.s === 0) {
                return {
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                };
            } else if (this.picSize.s === 1) {
                return {
                    backgroundPosition: 'center',
                    backgroundSize: '100% 100%',
                };
            }
        },
    },
    methods: {
        encodeHtml,
    },
};
