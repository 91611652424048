<template>
    <div class="m_mobi_form_multi_dropdown_wrap">
        <div class="select_wrap">
            <select-component
                :list="firstLevelList"
                :value="currentVal.firstLevel.val"
                :placeholder="formItem.placeholder || pleaseSelectTips"
                content-key="value"
                :item-input-style="_mixinsInputStyle"
                :disabled="currentVal.firstLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.firstLevel.disabled,
                }"
                :residue-map="getResidueMap(1)"
                @input="selectFirstLevel($event)"
            />
        </div>
        <div v-show="(level >= 2 && secondLevelList.length) || shortLevel >= 2" class="select_wrap">
            <select-component
                :list="secondLevelList"
                :value="currentVal.secondLevel.val"
                :placeholder="formItem.placeholder || pleaseSelectTips"
                content-key="value"
                :item-input-style="_mixinsInputStyle"
                :disabled="currentVal.secondLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.secondLevel.disabled,
                }"
                :residue-map="getResidueMap(2)"
                @input="selectSecondLevel($event)"
            />
        </div>
        <div v-show="(level >= 3 && thirdLevelList.length) || shortLevel >= 3" class="select_wrap">
            <select-component
                :list="thirdLevelList"
                :value="currentVal.thirdLevel.val"
                :placeholder="formItem.placeholder || pleaseSelectTips"
                content-key="value"
                :item-input-style="_mixinsInputStyle"
                :disabled="currentVal.thirdLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.thirdLevel.disabled,
                }"
                :residue-map="getResidueMap(3)"
                @input="selectThirdLevel($event)"
            />
        </div>
        <div v-show="(level >= 4 && fourthLevelList.length) || shortLevel >= 4" class="select_wrap">
            <select-component
                :list="fourthLevelList"
                :value="currentVal.fourthLevel.val"
                :placeholder="formItem.placeholder || pleaseSelectTips"
                content-key="value"
                :item-input-style="_mixinsInputStyle"
                :disabled="currentVal.fourthLevel.disabled"
                :class="{
                    'select_input--disabled': currentVal.fourthLevel.disabled,
                }"
                :residue-map="getResidueMap(4)"
                @input="selectFourthLevel($event)"
            />
        </div>
    </div>
</template>

<script>
import Mixins from '../mixins.js';
import SelectComponent from '@/modules/commComponent/SelectComponent.vue';
import { ing } from '@/shared/tips';
import { getMultiDropDownInfo, getMultiDropDownLevel, getMultiDropDownLevelData } from '@jz/biz-shared';
import { encodeHtml } from '@/shared/util';
import { residueMixins } from '../mixins/residue.js';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormMultiDropdown',
    components: {
        SelectComponent,
    },
    mixins: [Mixins, residueMixins(), formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            noneValue: 'none',
            manageVal: {
                firstLevel: {
                    val: '',
                    disabled: false,
                },
                secondLevel: {
                    val: '',
                    disabled: true,
                },
                thirdLevel: {
                    val: '',
                    disabled: true,
                },
                fourthLevel: {
                    val: '',
                    disabled: true,
                },
            },
            submitVal: '',
        };
    },
    computed: {
        shortLevel() {
            return getMultiDropDownLevel(this.formItem.input, true);
        },
        level() {
            return getMultiDropDownLevel(this.formItem.input);
        },
        currentVal() {
            return this.manageVal;
        },
        dropDownInfo() {
            const input = this.formItem.input || '';
            return getMultiDropDownInfo(input);
        },
        firstLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(this.dropDownInfo, 0);
            } catch (e) {
                return [];
            }
        },
        secondLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(this.dropDownInfo, 1, this.currentVal.firstLevel.val);
            } catch (e) {
                return [];
            }
        },
        thirdLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(
                    this.dropDownInfo,
                    2,
                    this.currentVal.firstLevel.val,
                    this.currentVal.secondLevel.val
                );
            } catch (e) {
                return [];
            }
        },
        fourthLevelList() {
            try {
                /* eslint-disable-next-line no-undef */
                return getMultiDropDownLevelData(
                    this.dropDownInfo,
                    3,
                    this.currentVal.firstLevel.val,
                    this.currentVal.secondLevel.val,
                    this.currentVal.thirdLevel.val
                );
            } catch (e) {
                return [];
            }
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        changeOtherLevel(key, disabled = false) {
            this.currentVal[key].val = '';
            this.currentVal[key].disabled = disabled;
        },
        selectItem(item, level = '') {
            if (item instanceof Object) {
                this.currentVal[level].val = item.value;
            } else {
                this.currentVal[level].val = item;
            }
            const { firstLevel = {}, secondLevel = {}, thirdLevel = {}, fourthLevel = {} } = this.currentVal || {};
            let result = '';
            result = firstLevel.val || '';
            !!secondLevel.val && (result += `-${secondLevel.val}`);
            !!thirdLevel.val && (result += `-${thirdLevel.val}`);
            !!fourthLevel.val && (result += `-${fourthLevel.val}`);
            this.submitVal = result;
            this.$emit('selectChange', {
                value: this.currentVal,
                formItem: this.formItem,
            });
        },
        selectFirstLevel(item) {
            this.selectItem(item, 'firstLevel');
            this.changeOtherLevel('secondLevel');
            this.changeOtherLevel('thirdLevel', true);
            this.changeOtherLevel('fourthLevel', true);
        },
        selectSecondLevel(item) {
            this.selectItem(item, 'secondLevel');
            this.changeOtherLevel('thirdLevel');
            this.changeOtherLevel('fourthLevel', true);
        },
        selectThirdLevel(item) {
            this.selectItem(item, 'thirdLevel');
            this.changeOtherLevel('fourthLevel');
        },
        selectFourthLevel(item) {
            this.selectItem(item, 'fourthLevel');
        },
        validate() {
            if (this.formItem.must) {
                if (!this.currentVal.firstLevel.val) {
                    ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                    return false;
                }
                if (this.level > 1 && !this.currentVal.secondLevel.val) {
                    if (this.secondLevelList.length) {
                        ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                        return false;
                    }
                }
                if (this.level > 2 && !this.currentVal.thirdLevel.val) {
                    if (this.thirdLevelList.length) {
                        ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                        return false;
                    }
                }
                if (this.level > 3 && !this.currentVal.fourthLevel.val) {
                    if (this.fourthLevelList.length) {
                        ing(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                        return false;
                    }
                }
            }

            return true;
        },
        resetValue() {
            this.changeOtherLevel('firstLevel');
            this.changeOtherLevel('secondLevel', true);
            this.changeOtherLevel('thirdLevel', true);
            this.changeOtherLevel('fourthLevel', true);
        },
        getResidueMap(level) {
            const { ol } = this.formItem;
            let firstLevelVal = this?.currentVal?.firstLevel?.val;
            let secondLevelVal = this?.currentVal?.secondLevel?.val;
            let thirdLevelVal = this?.currentVal?.thirdLevel?.val;

            return ol
                ? this.getMutilDropDownResidueData({
                      level,
                      firstLevelVal,
                      secondLevelVal,
                      thirdLevelVal,
                      firstLevelList: this.firstLevelList,
                      secondLevelList: this.secondLevelList,
                      thirdLevelList: this.thirdLevelList,
                      fourthLevelList: this.fourthLevelList,
                  })
                : null;
        },
        initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : '';
            if (initialValue !== undefined && initialValue !== '') {
                const values = initialValue.split('-');
                const selectActions = [
                    this.selectFirstLevel,
                    this.selectSecondLevel,
                    this.selectThirdLevel,
                    this.selectFourthLevel,
                ];
                selectActions.forEach((selectAction, index) => {
                    if (values[index]) {
                        selectAction(values[index]);
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss">
.m_mobi_form_multi_dropdown_wrap {
    .select_wrap:not(:last-of-type) {
        margin-bottom: 0.68rem;
    }
}
</style>
