<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="getItemClasses"
        :style="getColumnStyle"
        class="lineHeightStyle"
    >
        <div
            :id="'lineBody' + info.id"
            class="lineBody lineBodyTable"
            :class="[isNewAddModuleClass, isOnlyTitle, lineHeightStyle]"
        >
            <div v-if="_topFlagClass != '' && !isNewAddModule" class="wholeLineTFTableCell">
                <div :class="_topFlagClass"></div>
            </div>
            <div class="linkTableCell">
                <div class="linkTable">
                    <div v-if="isNewAddModule">
                        <div class="tableTitle">
                            <a
                                :href="_newsUrl"
                                class="J_linkH g_link g_news_title news_title newTitle"
                                :style="news_title_style"
                                hidefocus="true"
                                :target="_jumpTarget"
                                :class="[titleLine, noDes]"
                                :onclick="onclickStr"
                                ><top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component
                                >{{ info.title }}</a
                            >
                        </div>
                    </div>
                    <div v-if="!isNewAddModule" class="tableTitle">
                        <a
                            :href="_newsUrl"
                            class="J_linkH g_link g_news_title news_title newTitle"
                            :style="news_title_style"
                            hidefocus="true"
                            :target="_jumpTarget"
                            :class="[titleLine, noDes]"
                            :onclick="onclickStr"
                            ><top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component
                            >{{ info.title }}</a
                        >
                    </div>
                    <div v-if="isNewAddModule && showDes">
                        <div
                            class="g_news_abstract g_text newDescriptionLine"
                            :class="[descriptionLine, isOnlyTitleAndDes]"
                            :style="news_abstract_style"
                        >
                            {{ info.summary }}
                        </div>
                    </div>
                    <a
                        v-if="isNewAddModule && showOtherInfo"
                        :href="_newsUrl"
                        class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel newTextInfo"
                        :class="hasDes"
                        :onclick="onclickStr"
                    >
                        <div v-if="_showComment" class="ne_newsComment g_newsInfo">
                            {{ info.commentCount }}{{ module.extInfo.commentText }}
                        </div>
                        <div v-if="_showAuthor && info.author != ''" class="ne_newsAuthor g_newsInfo">
                            {{ info.author }}
                        </div>
                        <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                            {{ info.groupName }}
                        </div>
                        <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                            {{ newsDate }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <a
            v-if="!isNewAddModule && showOtherInfo"
            style="display: block"
            :href="_newsUrl"
            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel"
            :onclick="onclickStr"
        >
            <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount || 0 }}</span>
                <span v-else class="newsCommentCount g_newsInfo"
                    >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
                >
            </div>
            <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
            </div>
            <div
                v-if="(_showAuthor || _showComment) && (_showSort || _showDate)"
                class="ne_separatorLine g_ne_separatorLine"
            ></div>
            <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                {{ info.groupName }}
            </div>
            <div v-if="_showDate" class="ne_newsTime g_newsInfo">
                {{ newsDate }}
            </div>
        </a>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import TopComponent from '../topComponent/top-component.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'TextListItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
        listLength: { type: Number, default: 0 },
        isColumn2: { type: Boolean, default: false },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        jumpInSelf() {
            return this.module.content.dts == 1;
        },
        getColumnStyle() {
            const i = this.index;
            const isNeedTwoColumn = i % 2 == 1;
            return this.isColumn2 && isNeedTwoColumn ? 'margin-left:5%;' : '';
        },
        getItemClasses() {
            const i = this.index;
            const picStyle = this.module.content.ns;
            const firstClass = i == 0 || (picStyle && i == 1) ? 'firstClass' : '';
            const lastClass = i == this.listLength - 1 ? 'lastClass' : '';
            const isNewAddModuleClass = this.isNewAddModuleClass ? 'isNewAddModule' : '';

            if (this.isColumn2) {
                return `newsLine J_lineHeigh wholeLine J_lineH ${this._lineHeightClass} ${this._lineClass} ${this._topFlagLineClass} ${isNewAddModuleClass}`;
            } else {
                return `newsLine J_lineHeigh line icon-gline ${this._lineClass} ${this._topFlagLineClass} ${lastClass} ${firstClass} wholeLine J_lineH ${this._lineHeightClass} ${isNewAddModuleClass}`;
            }
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        noDes() {
            return this.showDes ? '' : 'noDes';
        },
        hasDes() {
            return this.showDes ? 'hasDes' : '';
        },
        lineHeightStyle() {
            return this.isNewAddModule ? 'lineHeightStyle' : '';
            // return ''
        },
    },
};
</script>
<style>
.news_list_wrap .text_list .linkTable .news_title {
    color: #333;
}
.news_list_wrap .text_list .isNewAddModule .linkTable .news_title {
    color: #222;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
}
</style>
