<template>
    <div :id="photoItem.picId" class="horizonContainer" :data-picid="photoItem.picId">
        <div class="horizonContent" :style="horizonContent">
            <a
                :style="picLinkStyle"
                class="J_img_warp"
                :rel="photoItem.nf ? 'nofollow' : ''"
                :href="imgHref"
                :onclick="jumpOnclickStr"
                @click="photoSlidesshow"
            >
                <div
                    v-drag-resize="_resizeOptions"
                    class="horizon_img J_img_lazyload"
                    :src-original="photoItem.picThumbPath"
                    :style="photoItemImgStyle"
                ></div>
                <div class="horizion_text" :style="picTextStyle">
                    <div v-show="showTitle" :class="titleClass" :style="titleWrapStyle" v-html="titleData"></div>
                    <div v-show="showDesc" :class="descClass" :style="descWrapStyle" v-html="descData"></div>
                </div>
            </a>
        </div>
        <div v-if="showDivider" :style="dividerStyle"></div>
    </div>
</template>
<script>
import { photoMixins } from '../../mixins';
import { photoImgScaleStyleMap } from '../../utils';
export default {
    name: 'PhotoTextHorizontal',
    mixins: [photoMixins],
    props: {
        listLength: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        leftRightPicSize() {
            return this.moduleContent.mhighs.lrss;
        },
        showDivider() {
            return this.imgIndex < this.listLength;
        },

        picPosition() {
            return this.moduleContent.iposition;
        },

        picLinkStyle() {
            let style = {};
            if (this.leftRightPicSize.type == 1) {
                style['min-height'] = `${this.leftRightPicSize.height}px`;
            } else {
                style['min-height'] = '4.35rem';
            }
            return style;
        },

        picTextStyle() {
            let style = {};
            let width = '';
            if (this.leftRightPicSize.type == 1) {
                style['min-height'] = `${this.leftRightPicSize.height}px`;
            } else {
                style['min-height'] = '4.35rem';
            }

            width = this.leftRightPicSize.type == 1 ? this.leftRightPicSize.width + 'px' : '5.75rem';

            if (this.picPosition == 1) {
                style['padding-right'] = width;
            } else {
                style['padding-left'] = width;
            }
            return style;
        },

        photoItemImgStyle() {
            let style = {};
            style['background-image'] = `url(${this.noLazyLoad ? this.photoItem.picThumbPath : this.loadingPath})`;
            // Object.assign(style, photoImgScaleStyleMap[this.picScaleType]);
            style = { ...style, ...photoImgScaleStyleMap[this.picScaleType] };
            style['width'] = this.leftRightPicSize.type == 1 ? `${this.leftRightPicSize.width}px` : '5.75rem';
            style['height'] = this.leftRightPicSize.type == 1 ? `${this.leftRightPicSize.height}px` : '4.35rem';
            style['border-radius'] =
                this.photoRadius.type == 1 ? `${this.photoRadius.size / this.htmlFontSize}rem` : '0.2rem';

            if (this.picPosition == 1) {
                style['right'] = 0;
            }

            return style;
        },
        titleWrapStyle() {
            let style = {};
            // Object.assign(style, this.wrapStyle(this.titleIsWrap, this.moduleStyle));
            // Object.assign(style, this.textLinkStyle(this.titleLink));
            // Object.assign(style, this.textPaddingStyle);

            let wrapStyle = this.wrapStyle(this.titleIsWrap, this.moduleStyle);
            let linkStyle = this.textLinkStyle(this.titleLink);
            style = { ...wrapStyle, ...linkStyle, ...this.textPaddingStyle };

            style['text-align'] = this.textAlignStyle;
            style['line-height'] = 'inherit';
            return style;
        },
        descWrapStyle() {
            let style = {};
            // Object.assign(style, this.wrapStyle(this.descIsWrap, this.moduleStyle));
            let wrapStyle = this.wrapStyle(this.descIsWrap, this.moduleStyle);
            style = { ...wrapStyle };
            if (this.nameLink.type == 0) {
                style['font-size'] = '0.55rem';
                style['line-height'] = '0.75rem';
                style['color'] = '#666';
            }
            if (!this.showTitle) {
                style['margin-top'] = '0';
            }

            style['text-align'] = this.textAlignStyle;
            // Object.assign(style, this.textLinkStyle(this.nameLink));
            // Object.assign(style, this.textPaddingStyle);
            let linkStyle = this.textLinkStyle(this.nameLink);
            let textPaddingStyle = this.textPaddingStyle;
            style = { ...style, ...linkStyle, ...textPaddingStyle };

            style['line-height'] = 'inherit';
            return style;
        },
        titleClass() {
            return ['horizon_title'];
        },
        descClass() {
            return ['horizon_desc'];
        },
        horizonContent() {
            let style = {};
            if (this.picSpace.type == 1) {
                if (this.imgIndex == 1) {
                    style['paddingBottom'] = `${this.picSpace.vertical / this.htmlFontSize}rem`;
                } else if (this.imgIndex == this.listLength) {
                    style['paddingTop'] = `${this.picSpace.vertical / this.htmlFontSize}rem`;
                } else {
                    style['padding'] = `${this.picSpace.vertical / this.htmlFontSize}rem 0`;
                }
            }
            return style;
        },

        textPaddingStyle() {
            let style = {};
            if (this.picPosition == 1) {
                style['padding-right'] = 0.5 + 'rem';
            } else {
                style['padding-left'] = 0.5 + 'rem';
            }
            return style;
        },
        dividerContent() {
            return this.moduleContent.mhighs.divider;
        },
        dividerStyle() {
            let style = {};
            if (this.dividerContent.type == 1) {
                style['border-top-width'] = this.dividerContent.size + 'px';
                style['border-top-color'] = this.dividerContent.color;
                switch (this.dividerContent.style) {
                    case 0:
                        style['border-top-style'] = 'solid';
                        break;
                    case 1:
                        style['border-top-style'] = 'dotted';
                        break;
                    case 2:
                        style['border-top-style'] = 'dashed';
                        break;
                }
            } else {
                style['border-top-width'] = '1px';
                style['border-top-color'] = '#EEEEEE';
                style['border-top-style'] = 'solid';
            }
            return style;
        },
    },
};
</script>
<style>
.horizonContainer {
    padding: 0 0.675rem 0 0.75rem;
}

.horizonContainer .horizonContent {
    padding: 0.75rem 0;
}

.horizonContainer a {
    position: relative;
    display: block;
}

.horizonContainer .horizon_img {
    position: absolute;
}

.horizonContainer .horizion_text {
    padding-top: 0.125rem;
    box-sizing: border-box;
}

.horizion_text .horizon_title {
    color: #222222;
    font-size: 0.65rem;
    font-weight: bold;
}

.horizion_text .horizon_desc {
    margin-top: 0.25rem;
    color: #666666;
    font-size: 0.55rem;
    font-weight: 500;
    line-height: 0.85rem;
}
</style>
