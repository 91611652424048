import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import MultiPhotoListView from './module/multi-photo-list.vue';

class _MultiPhotoList extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.MULTI_PHOTO_LIST, options);
    }

    render() {
        return MultiPhotoListView;
    }
}

export const MultiPhotoList = new _MultiPhotoList();
