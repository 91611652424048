import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import PopupZoneView from './module/visitor.vue';

class _PopupZone extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.FOLD, options);
    }

    render() {
        return PopupZoneView;
    }
}

export const PopupZone = new _PopupZone();
