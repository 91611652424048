<template>
    <module-frame :module-id="moduleId">
        <div v-if="!module.content.vs.nt && module.content.t === 0 && module.content.iu != ''" :id="'video' + id">
            <video
                :id="videoTagId"
                style="object-fit: scale-down"
                class="mobiVideo"
                :loop="loop"
                :poster="videoFirstImgUrl"
                controls
                webkit-playsinlin
                playsinline
                :data-original="module.content.ip"
                :src="isJzCdnFlowLimit ? '' : module.content.ip"
            >
                抱歉，您的浏览器不能查看该视频。
            </video>
        </div>
        <div v-else-if="!module.content.vs.nt && module.content.t != 0 && module.content.iu != ''" :id="'video' + id">
            <div :id="'video' + id" class="mobiVideoOnline">
                <div v-if="illnormalHttp && !module.content.urlIdErr" class="moduleHttpTip">
                    在线视频存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问
                </div>
                <iframe
                    v-if="urlDomainYouku && !module.content.urlIdErr"
                    ref="youku"
                    class="mobiVideoOnlineIframe"
                    frameborder="0"
                    :src="'//player.youku.com/embed/' + urlId + '?'"
                    allowfullscreen
                ></iframe>
                <iframe
                    v-else-if="urlDomainYoukuPlayer && !module.content.urlIdErr"
                    ref="domainYouku"
                    class="mobiVideoOnlineIframe"
                    frameborder="0"
                    :src="'//player.youku.com/embed/' + urlId"
                    allowfullscreen
                ></iframe>
                <iframe
                    v-else-if="urlDomainQQ && !module.content.urlIdErr"
                    ref="domainQQ"
                    class="mobiVideoOnlineIframe"
                    frameborder="0"
                    :src="'//v.qq.com/iframe/player.html?vid=' + urlId + '&tiny=0&auto=0'"
                    allowfullscreen
                ></iframe>

                <template v-else-if="canOtherSiteVideo && module.content.t == 2">
                    <iframe
                        v-show="isiFrameAddress"
                        ref="other"
                        class="mobiVideoOnlineIframe"
                        frameborder="0"
                        :src="otherSiteVideo"
                        allowfullscreen
                    ></iframe>
                    <video
                        v-show="!isiFrameAddress"
                        style="object-fit: scale-down"
                        :data-original="module.content.iu"
                        :src="isJzCdnFlowLimit ? '' : module.content.iu"
                        class="mobiVideo"
                        controls
                        webkit-playsinlin
                        playsinline
                    ></video>
                </template>

                <video
                    v-else-if="canOtherSiteVideo && module.content.t == 1"
                    style="object-fit: scale-down"
                    :data-original="module.content.iu"
                    :src="isJzCdnFlowLimit ? '' : module.content.iu"
                    class="mobiVideo"
                    controls
                ></video>
                <video
                    v-else
                    :id="videoTagId"
                    style="object-fit: scale-down"
                    class="mobiVideo"
                    controls
                    :data-original="module.content.iu"
                    :src="isJzCdnFlowLimit ? '' : module.content.iu"
                >
                    抱歉，您的浏览器不能查看该视频。
                </video>
            </div>
        </div>
        <div v-else-if="module.content.vs.nt && module.content.iu != ''" class="videoDiv" @click="videoDialog">
            <div class="videoPlay faisco-icons-"></div>
            <div class="videoPoster J_img_lazyload" :style="videoPosterStyle" :src-original="videoFirstImgUrl"></div>
        </div>
        <div v-else-if="module.content.iu == '' && manageMode" class="empty_tips_panel">
            <div class="text">请在右侧面板添加视频</div>
            <button-component active @click.stop="addVideo">添加视频</button-component>
        </div>
        <div v-else-if="module.content.iu == '' && !manageMode" class="cus_Empty_tips_panel">
            <div class="text">没有添加视频</div>
        </div>
    </module-frame>
</template>
<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';
import { bindEvent, videoDialog } from '../util';

import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { getVideoResourceHandler } from '@/shared';
export default {
    name: 'OnlineVideo',
    components: {
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            nolazyLoad: false,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('app', ['isJzCdnFlowLimit']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        id() {
            return this.moduleId;
        },
        styleId() {
            return this.module.style;
        },
        modulePattern() {
            return this.module.pattern;
        },
        modulePatternV3() {
            return this.module.patternV3;
        },
        options() {
            return this.module.renderOptions;
        },
        loop: function () {
            return this.module.content.vs.ls ? true : false;
        },
        videoTagId: function () {
            return 'video' + this.id + 'Tag';
        },
        illnormalHttp: function () {
            return (
                (this.module.content.iu && this.module.content.iu.trim().startsWith('//')) ||
                (this.module.content.urlValidatedFlag && this.module.content.iu.trim().indexOf('https') == -1)
            );
        },
        urlId: function () {
            return this.module.content.urlId;
        },
        urlDomainYouku: function () {
            return this.module.content.urlDomain == 'v.youku.com';
        },
        urlDomainYoukuPlayer: function () {
            return this.module.content.urlDomain == 'player.youku.com';
        },
        urlDomainQQ: function () {
            return (
                this.module.content.urlDomain == 'v.qq.com' ||
                this.module.content.urlDomain == 'static.video.qq.com' ||
                this.module.content.urlDomain == 'imgcache.qq.com'
            );
        },
        checkVer: function () {
            return this.module.content.checkVer;
        },
        loadingPath() {
            return this.options.loadingPath;
        },
        videoPosterStyle() {
            let style = {};
            style['background-image'] = this.nolazyLoad ? `url(${this.videoFirstImgUrl})` : `url(${this.loadingPath})`;
            return style;
        },
        videoFirstImgUrl: function () {
            if (this.module.content.vs.ps && this.module.content.vs.pi != '') {
                return this.module.content.vs.pp;
            } else {
                return this.module.content.vs.vp;
            }
        },
        otherSiteVideo: function () {
            if (this.module.content.iu != null && this.module.content.iu != '') {
                // 通用地址
                let arr = this.module.content.iu.split(' ');
                let src = arr.filter((item) => {
                    if (item.indexOf('src') != -1) {
                        return item;
                    }
                });
                try {
                    if (src != null && src != '') {
                        let srcArr = src[0].split('src="');
                        return srcArr[1].split('"')[0];
                    } else {
                        return '';
                    }
                } catch (e) {
                    return '';
                }
            } else {
                return '';
            }
        },

        canOtherSiteVideo: function () {
            return (
                !this.urlDomainQQ && !this.module.content.urlIdErr && !this.urlDomainYoukuPlayer && !this.urlDomainYouku
            );
        },

        isiFrameAddress: function () {
            return this.module.content.iu.indexOf('iframe') > -1 || this.module.content.iu.indexOf('embed') > -1;
        },
        manageMode() {
            return VITE_APP_MODE !== 'visitor';
        },
    },
    watch: {
        module: {
            handler: function () {
                var _this = this;
                this.nolazyLoad = true;
                Vue.nextTick(() => {
                    bindEvent(_this);
                    !!$(_this.videoTagId).length && $(_this.videoTagId).get(0).load();
                });
            },
            deep: true,
        },
    },
    mounted() {
        bindEvent(this);
        bindImgLazyLoad(`module${this.module.id}`);

        if (this.isJzCdnFlowLimit) {
            const VideoResourceHandler = getVideoResourceHandler();
            const container = document.getElementById(`video${this.id}`);
            if (container && container.querySelectorAll) {
                const videos = container.querySelectorAll('video');
                for (let videoEl of videos) {
                    VideoResourceHandler.add(videoEl);
                }
            }
        }
    },
    methods: {
        videoDialog: function () {
            videoDialog(this.id, this.module.content, true);
        },
        addVideo(e) {
            e.stopPropagation();
            this.$designer.open('pattern online-video', this.id, this.styleId, 0);
        },
    },
};
</script>

<style lang="scss">
.mobiVideoOnline {
    z-index: 0;
    height: auto;
}
.moduleHttpTip {
    padding: 10px 0;
    font-size: 13px;
    color: red;
}
.mobiVideo {
    width: 100%;
    display: block;
}

/*视频弹窗*/
.videoDialog {
    background-color: black;
    height: 100%;
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    z-index: 99999;
    top: 0px;
}
.mobiVideo {
    width: 100%;
}
.cancelVideo {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 15px;
    color: white;
    z-index: 1;
    cursor: pointer;
}
.cancelVideo:before {
    content: '\b0118';
}
.videoPlay {
    width: 2.2rem;
    height: 2.2rem;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 1.1rem;
    color: white;
    text-align: center;
}
.videoPlay:before {
    content: '\b0284';
    font-size: 0.9rem;
    line-height: 2.2rem;
    margin-left: 0.2rem;
}
.videoDiv {
    cursor: pointer;
    height: 8.1rem;
    width: 100%;
    position: relative;
}
.videoDiv .videoPoster {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mobiVideoDialog {
    position: absolute;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 100%;
}

.mobiVideoDialog .mobiVideo {
    height: auto !important;
}

.mobiVideoDialogOl {
    position: absolute;
    top: 50%;
    min-height: 9rem;
    width: 100%;
    transform: translateY(-50%);
}
.mobiVideoOnlineIframe {
    width: 100%;
    height: 100%;
    min-height: 9rem;
}
.mobiVideoOnline .video-js {
    width: 100%;
    height: initial;
    min-height: 9rem;
    object-fit: cover;
}
</style>
