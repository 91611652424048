<template>
    <div v-if="render" :id="cube_link_id" class="cubeLink" :class="cubeLinkCls" :linkid="id" :style="cubeLinkWrapStyle">
        <div
            :id="cubeLink_wrap_a_id"
            class="cubeLink_a_wrap"
            @mouseover="mouseOverHandle($event)"
            @mouseleave="mouseLeaveHandle($event)"
        >
            <a
                :id="cubeLink_a_id"
                class="cubeLink_a"
                :class="cubeLinkAClass"
                :href="href"
                :rel="rel"
                :style="tmpHrefStyle"
                :onclick="jumpOnclickStr"
                @click="linkItemClick"
            >
                <template v-if="link.coverType == 2 && bgUrl != ''">
                    <div
                        class="cubeLink_bg J_cubeLink_bg J_img_lazyload"
                        :style="cubeLink_bg_style"
                        :src-original="srcOriginal"
                    >
                        <div
                            v-if="cubeLayoutId == 7 || cubeLayoutId == 17"
                            class="cubeLink_ico icon-cube J_img_lazyload"
                            :src-original="incoUrl"
                            :style="iconStyle"
                        ></div>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-if="cubeLayoutId == 15"
                        class="cubeLink_bg_style J_img_lazyload"
                        :src-original="srcOriginal"
                        :style="cubeLink_bg_style"
                    ></div>
                    <div
                        v-if="cubeLayoutId == 7 || cubeLayoutId == 17"
                        class="cubeLink_bg J_img_lazyload"
                        :src-original="srcOriginal"
                        :style="cubeLink_bg_style"
                        ><div
                            class="cubeLink_ico icon-cube J_img_lazyload"
                            :src-original="incoUrl"
                            :style="iconStyle"
                        ></div
                    ></div>
                    <div
                        v-else
                        class="cubeLink_bg J_img_lazyload"
                        :src-original="srcOriginal"
                        :style="cubeLink_bg_style"
                    ></div>
                </template>

                <div
                    v-if="cubeLayoutId != 7 && cubeLayoutId != 17"
                    class="cubeLink_ico icon-cube J_img_lazyload"
                    :src-original="incoUrl"
                    :style="iconStyle"
                ></div>
                <div class="cubeLink_curtain"></div>
                <div class="cubeLink_text g_link" :style="cubeLink_text_wrap_style">
                    <div class="cubeLink_text_p">
                        <span class="cube_text g_cube_link" :style="cubeLink_text_style">{{ linkText }}</span>
                        <p class="cubeLink_subText_p cube_subtext g_cube_link" :style="cubeLink_subText_style">{{
                            linkSubText
                        }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { createUrlArgsOnclickStr } from '../../../comm';

import { ing } from '@/shared/tips';
import { CubeNavOpenItemManage } from '@/modules/cubeNavigation/module/service';
import { mapGetters } from 'vuex';

export default {
    name: 'Cubelink',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        link: Object,
        // eslint-disable-next-line vue/require-default-prop
        index: Number,
        // eslint-disable-next-line vue/require-default-prop
        pageIndex: Number,
        // eslint-disable-next-line vue/require-default-prop
        id: Number,
        // eslint-disable-next-line vue/require-default-prop
        hrefStr: String,
        moduleManage: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        newAdd: Number,
        // eslint-disable-next-line vue/require-default-prop
        dataLength: Number,
        // eslint-disable-next-line vue/require-default-prop
        moduleId: Number,
        // eslint-disable-next-line vue/require-default-prop
        cubeLayoutId: Number,
        // eslint-disable-next-line vue/require-default-prop
        hasSubTitle: Number,
        // eslint-disable-next-line vue/require-default-prop
        cubeType: Number,
        // eslint-disable-next-line vue/require-default-prop
        titleStyle: Object,
        // eslint-disable-next-line vue/require-default-prop
        canSet: Object,
        // eslint-disable-next-line vue/require-default-prop
        module: Object,
        // eslint-disable-next-line vue/require-default-prop
        options: Object,
    },
    data() {
        var render = !!this.link;
        return {
            render: render,
        };
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        jumpOnclickStr() {
            return createUrlArgsOnclickStr(
                this.link.jumpInfo.reqArgs,
                this.module.content._openRemoveUrlArgs,
                this.hashRemoved
            );
        },
        incoUrl() {
            var icoType = this.link.icoType;
            var icoCusType = this.link.icoCusType;
            var icoSysType = this.link.icoSysType;
            var icoSysId = this.link.icoSysId;
            if (icoType == 2) {
                if (icoCusType == 0 && icoSysType == 1 && icoSysId != 0) {
                    return this.link.linkIcoPreViewPath;
                } else if (this.link.linkIcoPreViewPath) {
                    return this.link.linkIcoPreViewPath;
                }
            }

            return null;
        },
        iconStyle() {
            let style = {};
            if (this.module.content.fi.t == 1) {
                style = {
                    width: `${this.module.content.fi.v}rem`,
                    height: `${this.module.content.fi.v}rem`,
                    fontSize: `${this.module.content.fi.v}rem`,
                };
            }

            const subTextHide = this.link.subTextHide || this.module.content.hasSubTitle == 0;
            const textHide = this.link.textHide;
            var icoType = this.link.icoType;
            var icoCusType = this.link.icoCusType;
            var icoSysType = this.link.icoSysType;
            var icoSysId = this.link.icoSysId;
            if (this.canSet.pic && this.module.content.ips.t == 1 && !(subTextHide && textHide)) {
                style.width = `${this.module.content.ips.w + 0.1}%`; /* 0.1 是为了遮挡实时改变宽度时的空白缝隙*/
            }

            if (icoType == 2) {
                if (icoCusType == 0 && icoSysType == 1 && icoSysId != 0) {
                    style.backgroundImage =
                        'url(' + (this.noLazyLoad ? this.link.linkIcoPreViewPath : this.options.loadingPath) + ')';
                } else if (this.link.linkIcoPreViewPath) {
                    style.backgroundImage =
                        'url(' + (this.noLazyLoad ? this.link.linkIcoPreViewPath : this.options.loadingPath) + ')';
                }
            }

            if (this.cubeLayoutId == 18 && icoType == 2 && this.link.icoCusType == 0 && this.link.icoSysType == 0) {
                // 选择系统字体图标
                style.backgroundImage = 'none';
            }
            return style;
        },
        cubeLinkWrapStyle() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }

            let style = {};
            const canSetHeight = this.canSet.itemHeight;
            const canSetItemSpace = this.canSet.itemSpace;

            if (this.module.content.ih.t == 1 && canSetHeight) {
                style.height = `${this.module.content.ih.v}rem`;
            }

            if (this.module.content.is.t == 1 && canSetItemSpace) {
                style.paddingLeft = `${this.module.content.is.h}rem`;
                style.paddingBottom = `${this.module.content.is.v}rem`;
            }

            return style;
        },
        bgUrl() {
            return this.link.bgUrl;
        },
        srcOriginal() {
            if (this.link.coverType == 2) {
                return this.link.bgUrl;
            } else {
                return '';
            }
        },
        dataOriginal() {
            return !(VITE_APP_MODE !== 'visitor') ? this.bgUrl : null;
        },
        cubeLinkBgSrc() {
            return VITE_APP_MODE !== 'visitor' ? this.bgUrl : null;
        },
        href() {
            if (this.link.linkType != 2) {
                return this.link.linkHref;
            } else {
                return this.hrefStr;
            }
        },
        rel() {
            if (this.link.nf) {
                return 'nofollow';
            }
            return '';
        },
        cubeLink_text_wrap_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return;
            }

            let style = '';
            if (this.canSet.pic && this.module.content.ips.t == 1 && this.link.icoType != 1) {
                style += `width:${100 - this.module.content.ips.w}%;`;
            }
            return style;
        },
        cubeLink_bg_style() {
            var style = '';
            var link = this.link;
            var linkCoverBgImg = '';
            if (this.bgUrl) {
                linkCoverBgImg = 'url(' + (this.noLazyLoad ? this.bgUrl : this.options.loadingPath) + ')';
            }
            var linkCoverBgSize = '';
            var linkCoverOpacity = '';

            if (link.coverType == 2) {
                var cover = link.cover;
                var linkCover_color = cover.color;
                var linkCover_tile = cover.tile;
                var linkCover_opacity = cover.opacity;
                var coverId = cover.fileId;
                if (linkCover_tile != -1) {
                    if (this.bgUrl != '') {
                        if (linkCover_tile != 101 && linkCover_tile != 102 && linkCover_tile != 103) {
                            //兼容旧数据
                            linkCover_tile = 101;
                        }
                        if (linkCover_tile == 101) {
                            //自适应
                            linkCoverBgSize =
                                'background-size:cover;background-position:center;background-repeat:no-repeat;';
                        } else if (linkCover_tile == 102) {
                            //拉伸
                            linkCoverBgSize = 'background-size:100% 100%;background-repeat:no-repeat;';
                        } else if (linkCover_tile == 103) {
                            //平铺
                            linkCoverBgSize = 'background-size: inherit;background-repeat:repeat;';
                        }
                    }
                }
                linkCoverOpacity =
                    "filter:(opacity='" + linkCover_opacity + "');opacity:" + linkCover_opacity / 100.0 + ';';
                if (coverId == '' && linkCover_color.indexOf('linear-gradient') >= 0) {
                    linkCoverBgImg = '';
                }

                let isLinearColor = linkCover_color.indexOf('linear-gradient') > -1;

                if (linkCoverBgImg == '') {
                    style =
                        'background:' +
                        linkCover_color +
                        ';background-position: initial;' +
                        linkCoverBgSize +
                        linkCoverOpacity;
                } else if (this.cubeLayoutId == 15) {
                    if (isLinearColor) {
                        style = `background-position: initial;animation:none;background-image:${linkCoverBgImg}, ${linkCover_color}; ${
                            linkCoverBgSize + linkCoverOpacity
                        }`;
                    } else {
                        style = `background: ${linkCover_color}; background-position: initial;animation:none;background-image:${linkCoverBgImg}; ${
                            linkCoverBgSize + linkCoverOpacity
                        }`;
                    }
                } else {
                    if (isLinearColor) {
                        style = `background-position: initial;background-image:${linkCoverBgImg}, ${linkCover_color}; ${
                            linkCoverBgSize + linkCoverOpacity
                        }`;
                    } else {
                        style = `background: ${linkCover_color}; background-position: initial;background-image:${linkCoverBgImg}; ${
                            linkCoverBgSize + linkCoverOpacity
                        }`;
                    }
                }
            } else if (link.coverType == 1) {
                style = 'background:transparent;';
            }

            if (this.canSet.itemRadius && this.module.content.ir.t == 1) {
                style += `border-radius:${this.module.content.ir.v}rem;`;
            }

            if (this.canSet.pic && this.module.content.ips.t == 1 && this.link.icoType != 1) {
                style += `width:${100 - this.module.content.ips.w}%;`;
            }
            return style;
        },
        cubeLink_text_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var pStr = '';
            var link = this.link;
            var linkTextData = link.textData;
            var linkTextData_type = linkTextData.textType;
            var titleStyle = this.titleStyle;
            var linkTextData_size = linkTextData.size || 12;
            linkTextData_size = parseInt(linkTextData.size);
            var linkTextData_bold = linkTextData.bold;
            var linkTextData_color = linkTextData.color;
            var linkTextData_decoration = linkTextData.fontDecoration;

            if (linkTextData_type == 1) {
                pStr =
                    'color:' +
                    linkTextData_color +
                    ';font-weight:' +
                    (linkTextData_bold ? 'bold' : 'normal') +
                    ';font-size:' +
                    linkTextData_size / 23.1875 +
                    'rem;';
                if (linkTextData_decoration) {
                    pStr += 'text-decoration:underline;';
                } else {
                    pStr += 'text-decoration:none;';
                }
            } else if (titleStyle.type == 1) {
                if (titleStyle.lfs) {
                    pStr += 'font-size:' + titleStyle.lfs + 'rem;';
                } else {
                    pStr += 'font-size:12px;';
                }
                if (titleStyle.lfct == 1) {
                    pStr += 'color:' + titleStyle.lfc + ';';
                }

                if (titleStyle.lfd == 1) {
                    pStr += 'text-decoration:underline;';
                } else {
                    pStr += 'text-decoration:none;';
                }
            }
            return pStr;
        },
        cubeLink_subText_style() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            var subPStr = '';
            var link = this.link;
            var linkSubTextData = link.subTextData;
            // eslint-disable-next-line no-unused-vars
            var titleStyle = this.titleStyle;
            var linkSubTextData_decoration = false;
            var moduleSubTitleStyle = this.module.content.mhighs.sts;

            //兼容旧数据
            if (linkSubTextData && linkSubTextData.textType == 1) {
                var linkSubTextData_size = linkSubTextData.size || 12;
                linkSubTextData_size = parseInt(linkSubTextData_size);
                var linkSubTextData_bold = linkSubTextData.bold;
                var linkSubTextData_color = linkSubTextData.color;
                linkSubTextData_decoration = linkSubTextData.fontDecoration;
                subPStr =
                    'color:' +
                    linkSubTextData_color +
                    ';font-weight:' +
                    (linkSubTextData_bold ? 'bold' : 'normal') +
                    ';font-size:' +
                    linkSubTextData_size / 23.1875 +
                    'rem;';
                if (linkSubTextData_decoration) {
                    subPStr += 'text-decoration:underline;';
                } else {
                    subPStr += 'text-decoration:none;';
                }
            } else if (moduleSubTitleStyle.type == 1) {
                if (moduleSubTitleStyle.lfs) {
                    subPStr += 'font-size:' + moduleSubTitleStyle.lfs + 'rem;';
                } else {
                    subPStr += 'font-size:12px;';
                }
                if (moduleSubTitleStyle.lfct == 1) {
                    subPStr += 'color:' + moduleSubTitleStyle.lfc + ';';
                }
                if (moduleSubTitleStyle.lfd == 1) {
                    subPStr += 'text-decoration:underline;';
                } else {
                    subPStr += 'text-decoration:none;';
                }
            }
            return subPStr;
        },
        tmpHrefStyle() {
            if (this.href == 'javascript:;' && !(VITE_APP_MODE !== 'visitor')) {
                return 'cursor:default';
            } else {
                return null;
            }
        },
        cube_link_id() {
            return 'cubeNavigation' + this.moduleId + '_cubeLink' + this.id;
        },
        cubeLink_a_id() {
            return 'cubeLink_a' + this.id + '_cubeNav' + this.moduleId;
        },
        cubeLink_wrap_a_id() {
            return 'cubeLink_a' + this.id + '_cubeNav' + this.moduleId + '_' + this.pageIndex;
        },
        flagToFix() {
            if (this.cubeType != 1) {
                return this.dataLength % this.cubeType;
            } else {
                return 0;
            }
        },
        cubeLinkFixWidth() {
            var cubeLinkFixWidth = '';
            var flagToFix = this.flagToFix;
            if (this.index + flagToFix >= this.dataLength) {
                //当个数不够填满样式的位置时进行补宽，如默认样式是3*3的位置，现有八个，要将最后一个进行补宽。
                if (flagToFix == 1) {
                    cubeLinkFixWidth = 'cubeLink_fixWidth_1';
                } else if (flagToFix == 2) {
                    cubeLinkFixWidth = 'cubeLink_fixWidth_2';
                }
            }
            return cubeLinkFixWidth;
        },
        cubeLinkCls() {
            const subTextHide = this.link.subTextHide || this.module.content.hasSubTitle == 0;
            const textHide = this.link.textHide;
            const icoType = this.link.icoType;
            const cubeLayoutId = this.cubeLayoutId;

            let classes = 'cubeLink' + (this.index + 1) + ' ' + this.cubeLinkFixWidth;
            classes += this.index % 2 == 0 ? ' odd' : ' even';

            if (this.module.content.newAdd2 && [10, 4, 3, 16, 9, 1, 2, 5, 6, 11, 14, 8, 18].includes(cubeLayoutId)) {
                if (subTextHide && textHide) {
                    // 隐藏标题，图标居中
                    classes += ' title_hide';
                }

                if (icoType == 1) {
                    classes += ' icon_hide';
                }
            }

            return classes;
        },
        cubeLinkAClass() {
            var link = this.link;
            var index = this.index;
            var textLayout = 'textLayout0';
            var linkTextLayout = link.textLayout;
            var linkTextLayout_type = linkTextLayout.layoutType;
            var cubeLayoutId = this.cubeLayoutId;
            if (
                cubeLayoutId == 13 ||
                cubeLayoutId == 14 ||
                cubeLayoutId == 15 ||
                cubeLayoutId == 7 ||
                cubeLayoutId == 12 ||
                cubeLayoutId == 17
            ) {
                linkTextLayout_type = 0;
            }
            if (linkTextLayout_type == 1) {
                var linkTextLayout_id = linkTextLayout.layoutId;
                textLayout = 'textLayout' + linkTextLayout_id;
            }

            var noIcon = '';
            if (this.cubeLayoutId == 11 && index == 0) {
                if (link.icoType == 0 || link.icoType == 1) {
                    noIcon = ' noIcon';
                }
            }

            if (cubeLayoutId == 18) {
                textLayout = '';
            }

            return textLayout + noIcon;
        },
        linkText() {
            var link = this.link;
            var cubeLayoutId = this.cubeLayoutId;
            var linkText = link.text;
            var linkTextHide = link.textHide || false;
            if (linkTextHide) {
                return '';
            }
            //第3、4、5、6、8、11、15、16种样式文字不能超过10个
            if (
                !this.module.content.mw &&
                (cubeLayoutId == 3 ||
                    cubeLayoutId == 4 ||
                    cubeLayoutId == 5 ||
                    cubeLayoutId == 6 ||
                    cubeLayoutId == 8 ||
                    cubeLayoutId == 11 ||
                    cubeLayoutId == 15 ||
                    cubeLayoutId == 16)
            ) {
                if (linkText.length > 10) {
                    linkText = linkText.substring(0, 10) + '...';
                }
            }

            return linkText; //= Encoder.encodeHtml(linkText);
        },
        linkSubText() {
            var link = this.link;
            var linkSubText = link.subText;
            var linkSubTextHide = link.subTextHide;
            var hasSubTitle = this.hasSubTitle;
            if (hasSubTitle == 0) {
                //不存在副标题
                linkSubTextHide = true;
            }
            if (linkSubText == null || linkSubTextHide) {
                linkSubText = '';
            }

            return linkSubText;
        },
    },
    beforeDestroy() {
        //手动加上去的，要确保销毁的时候也去除掉。
        this.mouseLeaveHandle();
    },
    methods: {
        linkItemClick() {
            if (this.href == 'javascript:;' && VITE_APP_MODE !== 'visitor' && this.moduleManage) {
                window.$store.commit('manage/cubeNavigation/updateCurCubeNavigationItemEdit', {
                    moduleId: this.moduleId,
                    itemId: this.id,
                });

                this.$designer.open({
                    moduleId: this.moduleId,
                    panelType: 'JzCubeNavigationEditItem',
                });
            }
            let { protocol, search } = location;

            // 管理态下预览态https里的http链接会出现混合内容出错，给提示
            if (protocol === 'https:' && search.includes('_preview=true') && this.href.includes('http:')) {
                ing(LS.mobi_preview_https_mixin_error);
            }
        },
        mouseOverHandle() {
            if (VITE_APP_MODE !== 'visitor' && this.moduleManage) {
                var linkJson = [
                    {
                        operationText: '编辑魔方导航单个链接',
                        className: 'edit',

                        onClick: () => {
                            CubeNavOpenItemManage(this.moduleId, this.id);
                        },
                    },
                ];
                this.$layer.addEditLayer(this.cubeLink_wrap_a_id, linkJson, 106, {
                    moduleId: this.moduleId,
                });
            }
        },
        mouseLeaveHandle() {
            if (VITE_APP_MODE !== 'visitor' && this.moduleManage) {
                this.$layer.removeEditLayer(this.cubeLink_wrap_a_id, null, 106);
            }
        },
    },
};
</script>
