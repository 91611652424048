import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import AdPictureView from './module/index.vue';

class _AdPicture extends MobiModule {
    constructor() {
        super(MODULE_STYLE.AD_PICTURE);
    }

    render() {
        return AdPictureView;
    }
}

export const AdPicture = new _AdPicture();
