<template>
    <div :id="'pagenation' + moduleId" :class="pagenationClass">
        <div :class="pagePrevClass" @click="changeSelected(1)"
            ><a v-if="preEnable && !ajaxPagenation" :href="pageData.preUrl"></a
        ></div>
        <div :id="'pagenationSelectDiv' + moduleId" class="pagenationSelectDiv">
            <select v-if="ajaxPagenation" ref="select" class="pageSelect J_noOpenDesigner" @change="changeSelected(3)">
                <option v-for="item in totalP" :key="item.id" :class="optionClass(item)" :value="item"
                    >{{ item }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ totalPage }}</option
                >
            </select>
            <select
                v-if="pageData.pageList.length && !ajaxPagenation"
                ref="select"
                class="pageSelect J_noOpenDesigner"
                onchange="location = this.options[this.selectedIndex].value;"
            >
                <option
                    v-for="(item, index) in pageData.pageList"
                    :key="item.id"
                    :class="optionClass(item)"
                    :value="item"
                    >{{ index }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ totalPage }}</option
                >
            </select>
            <span v-if="openThemeV3" class="currentPageV3Cover g_main_color_v3"
                >{{ pageno }}&nbsp;&nbsp;<span class="totalPageV3">/&nbsp;&nbsp;{{ totalPage }}</span></span
            >
        </div>
        <div :class="pageNextClass" @click="changeSelected(2)"
            ><a v-if="nextEnable && !ajaxPagenation" :href="pageData.nextUrl"></a
        ></div>
    </div>
</template>

<script>
import { AnimationApis } from '@jz/biz-shared';

export default {
    name: 'Pagenation',
    props: {
        moduleId: {
            type: Number,
            required: true,
        },
        totalSize: {
            type: Number,
            required: true,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        pageno: {
            type: Number,
            default: 1,
        },
        ajaxPagenation: {
            type: Boolean,
            default: false,
        },
        openThemeV3: {
            type: Boolean,
            default: false,
        },
        pageData: {
            type: Object,
            default: () => {
                return {
                    pageList: [],
                    preUrl: '',
                    nextUrl: '',
                };
            },
        },
    },
    data() {
        var totalSize = this.totalSize;
        var pageSize = this.pageSize;
        var pageno = this.pageno <= 0 ? 0 : this.pageno;
        var totalPage = Math.ceil(
            totalSize - (totalSize / pageSize) * pageSize > 0 ? totalSize / pageSize + 1 : totalSize / pageSize
        );
        var prePage = pageno - 1 <= 0 ? 1 : pageno - 1; //上一页的页码
        var nextPage = pageno + 1 >= totalPage ? totalPage : pageno + 1; //下一页的页码
        var totalP = [];
        for (var j = 1; j < totalPage + 1; j++) {
            totalP.push(j);
        }
        return {
            showHowManyPage: 2, //页面展示的翻页数字的个数
            totalPage: totalPage,
            prePage: prePage,
            nextPage: nextPage,
            totalP: totalP,
            pagenationClass: this.openThemeV3 ? 'pagenation theme_v3_pagenation' : 'pagenation',
            pagenStyle: 'icon-cnListP',
            pagenStyle2: 'icon-cnListN',
        };
    },
    computed: {
        nextEnable() {
            return !(this.pageno >= this.totalPage);
        },
        preEnable() {
            return this.pageno > 1;
        },
        pagePrevClass() {
            var tmpClass = 'pagePrev icon-pagePrev J_noOpenDesigner';
            if (this.pagenStyle != '') {
                tmpClass += ' ' + this.pagenStyle;
            }
            if (!this.preEnable) {
                tmpClass += ' pageDisable';
            }
            return tmpClass;
        },
        pageNextClass() {
            var tmpClass = 'pageNext icon-pageNext J_noOpenDesigner';
            if (this.pagenStyle2 != '') {
                tmpClass += ' ' + this.pagenStyle2;
            }
            if (!this.nextEnable) {
                tmpClass += ' pageDisable';
            }
            return tmpClass;
        },
    },
    watch: {
        pageno(newVal) {
            this.$refs.select.value = newVal;
        },
    },
    methods: {
        optionClass(item) {
            return item == 1 ? 'pageOptions currentPageV3 J_noOpenDesigner' : 'pageOptions J_noOpenDesigner';
        },
        optionSelect(item) {
            return item == 1 ? 'selected' : '';
        },
        changeSelected(type) {
            if (this.ajaxPagenation) {
                var pageno = 0;
                if (type == 1 && this.preEnable) {
                    pageno = this.pageno - 1;
                    this.$emit('on-change', pageno);
                } else if (type == 2 && this.nextEnable) {
                    pageno = this.pageno + 1;
                    this.$emit('on-change', pageno);
                } else if (type == 3) {
                    pageno = parseInt(this.$refs.select.value);
                    this.$emit('on-change', pageno);
                }
                AnimationApis.getAllAnimationManageMap().delete(this.moduleId);
            }
        },
    },
};
</script>

<style lang="scss">
.pagenation_btn {
    width: 100%;
    display: inline-block;
    height: 100%;
}
</style>
