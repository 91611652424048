/*
 *  渲染产品结构
 */

import { getBrowserType, BrowserType } from '@/shared/browser';
import { isModuleV2 } from '@/manage/module-edit-panel/utils/module';
import { browser } from '@jz/utils';

/****************横向滑动切换图片(自定义宽高等)*******************/
export const productCrossedSlide = function (moduleId, proScaleWidth, proScaleHeight, wrap, isNewAddModule) {
    var divWidth = jm('#' + moduleId + ' .productCrossedSlide .style7Img').width();
    var htmlFontSize = Fai.top._htmlFontSize;
    //获取普通产品样式的高度
    var divProHeight = (divWidth * proScaleHeight) / (proScaleWidth * htmlFontSize);
    divProHeight = parseFloat(divProHeight.toFixed(4));

    //获取价格名称字体大小
    var priceFontSize = jm('#' + moduleId + ' .paramPadding .paramName').css('font-size');
    //jq获取的font-size是带单位的，所以要截取。单位转换
    if (typeof priceFontSize != 'object') {
        var pFontSize = priceFontSize.substring(0, priceFontSize.indexOf('p')) / htmlFontSize + 0.2; //+ 0.2是为了解决部分浏览器文字显示不全问题
        if (!isNewAddModule) {
            jm('#' + moduleId + ' .productCrossedSlide .paramPadding .paramName').css('line-height', pFontSize + 'rem');
        }
        pFontSize = parseFloat(pFontSize.toFixed(4)) * 2;
    } else {
        pFontSize = 0;
    }
    var paramWrap = 'paramWrap';
    if (wrap == paramWrap) {
        if (getBrowserType() == BrowserType.FIREFOX) {
            jm('#' + moduleId + ' .productCrossedSlide .paramPadding .paramName').height(pFontSize + 'rem');
        } else {
            jm('#' + moduleId + ' .productCrossedSlide .paramPadding .paramName').height(pFontSize - 0.08 + 'rem');
        }
    } else {
        jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').height(pFontSize / 2.0 - 0.1 + 'rem');
    }
    //给样式高度赋值
    jm('#' + moduleId + ' .productCrossedSlide .style7Img').height(divProHeight + 'rem');
};

/*************product swipe 横向滑动切换 *****************/
export const initProductSwipe1 = function (dId, isNewAddModule) {
    //if(Fai.top._bannerData.h) return;
    var swipe = $('#' + dId)[0];
    var $productSwipeItem = jm('#' + dId).find('.productSwipeItem');
    if (!swipe) return;

    jm('#' + dId).swipehandle(swipe, {
        auto: false,
        continuous: false,
        bulletsClick: true,
        callback(pos) {
            var liLen = bullets.length;
            while (liLen--) {
                bullets[liLen].className = ' ';
            }
            if (!bullets[pos]) {
                bullets[pos - bullets.length].className = 'on';
                // 新加的模块当前bullet要跟主题色
                if (isNewAddModule) {
                    for (let i = 0; i < bullets.length; i++) {
                        bullets[i].style.backgroundColor = '#d9d9d9';
                    }
                    bullets[pos - bullets.length].style.backgroundColor = Fai.top._themeColor;
                }
            } else {
                bullets[pos].className = 'on';
                if (isNewAddModule) {
                    for (let i = 0; i < bullets.length; i++) {
                        bullets[i].style.backgroundColor = '#d9d9d9';
                    }
                    bullets[pos].style.backgroundColor = Fai.top._themeColor;
                }
            }
            setTimeout(function () {
                $productSwipeItem.eq(pos).find('div.style7Img').trigger('slidePic'); //产品展示样式三触发懒加载
            }, 300); //延迟300ms运行时因为，transition持续 300ms
        },
    });
    if (!swipe.querySelector('.bullets')) return;
    var bullets = swipe.querySelector('.bullets').getElementsByTagName('li');
};

/*************瀑布流布局js***************/
export const waterFall = function (
    list,
    moduleId,
    comScaleWidth,
    comScaleHeight,
    endsScaleWidth,
    endsScaleHeight,
    wrap,
    productSalesPromotionInfo,
    isNewAddModule
) {
    var $module = jm('#' + moduleId);
    $module.css('height', 'unset');
    var _isModuleV2 = isModuleV2(3);
    var isInTab = $module.parents('.J_formTabContent').length > 0;
    //修复访客态异步加载script导致的模块没有出现问题，属性计算错误的问题。
    if (isInTab) {
        $module.parents('.J_formTabContent').addClass('showTabContent');
    }

    jm('#' + moduleId + ' .productWaterFall').css('display', 'block');
    var divWidth = parseInt(jm('#' + moduleId + ' .productWaterFall .style8Img').width());
    //HTML字体大小，px转化为rem时要用
    //var htmlFontSize = parseInt(document.getElementsByTagName("html")[0].style.fontSize);
    // var htmlFontSize = parseFloat(document.documentElement.style.fontSize.replace("px",""));
    var htmlFontSize = Fai.top._htmlFontSize;
    var divEndsHeight = (divWidth * endsScaleHeight) / (endsScaleWidth * htmlFontSize);
    divEndsHeight = parseFloat(divEndsHeight.toFixed(4));
    var divComHeight = (divWidth * comScaleHeight) / (comScaleWidth * htmlFontSize);
    divComHeight = parseFloat(divComHeight.toFixed(4));
    //获取价格名称字体大小
    var priceFontSize = jm('#' + moduleId + ' .paramPadding .paramName').css('font-size');
    //jq获取的font-size是带单位的，所以要截取。单位转换
    if (typeof priceFontSize != 'object') {
        var pFontSize = priceFontSize.substring(0, priceFontSize.indexOf('p')) / htmlFontSize;
        pFontSize = parseFloat(pFontSize) + 0.2;
        if (!isNewAddModule) {
            jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').css('line-height', pFontSize + 'rem');
        }
        pFontSize = parseFloat(pFontSize.toFixed(4)) * 2;
    } else {
        pFontSize = 0;
    }
    var paramWrap = 'paramWrap';
    if (wrap == paramWrap) {
        if (getBrowserType() == BrowserType.FIREFOX) {
            jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').height(pFontSize + 'rem');
        } else {
            jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').height(pFontSize - 0.08 + 'rem'); //-0.08是为了兼容苹果手机的样式
        }
    } else {
        jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').height(pFontSize / 2.0 + 'rem');
    }

    if (list.length == 1) {
        jm('#' + moduleId + ' .productWaterFall .style8Img').height(divEndsHeight + 'rem');
        jm('#' + moduleId + ' .productWaterFall')
            .eq(i)
            .css({ 'vertical-align': 'top' });
        jm('#' + moduleId + ' .productWaterFall')
            .eq(i)
            .css({ top: 'unset' });
        jm('#' + moduleId + ' .productWaterFall')
            .eq(i)
            .css({ position: 'static' });
    } else if (list.length == 2) {
        for (var i = 0; i < list.length; i++) {
            if (i % 2 == 0) {
                jm('#' + moduleId + ' .productWaterFall .style8Img')
                    .eq(i)
                    .height(divEndsHeight + 'rem');
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ 'vertical-align': 'top' });
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ top: 'unset' });
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ position: 'static' });
            } else if (i % 2 == 1) {
                var topPosition1 = 0;
                jm('#' + moduleId + ' .productWaterFall .style8Img')
                    .eq(i)
                    .height(divComHeight + 'rem');
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ position: 'absolute', top: topPosition1 + 'rem' });
                // 获取居右的模块高度
                var rightDivDom, leftDivDom;
                if (_isModuleV2) {
                    rightDivDom = jm('#' + moduleId + ' .productWaterFall .commonProRight').closest(
                        '.productWaterFall'
                    );
                    leftDivDom = jm('#' + moduleId + ' .productWaterFall .firstLastPro').closest('.productWaterFall');
                } else {
                    rightDivDom = jm('#' + moduleId + ' .productWaterFall .commonProRight')
                        .parent()
                        .parent()
                        .parent();
                    leftDivDom = jm('#' + moduleId + ' .productWaterFall .firstLastPro')
                        .parent()
                        .parent()
                        .parent();
                }
                var rightDivHeight = parseFloat(rightDivDom.height());
                //获取左边的模块高度
                var leftDivHeight = parseFloat(leftDivDom.height());
                //px转化为rem
                rightDivHeight = rightDivHeight / htmlFontSize;
                leftDivHeight = leftDivHeight / htmlFontSize;
                if (leftDivHeight < rightDivHeight) {
                    //10为底部边距
                    rightDivHeight = rightDivHeight + 10 / htmlFontSize;
                    var moduleDiv;
                    if (_isModuleV2) {
                        moduleDiv = jm('#' + moduleId + ' .productWaterFall .firstLastPro').closest('.mProductList');
                    } else {
                        moduleDiv = jm('#' + moduleId + ' .productWaterFall .firstLastPro')
                            .parent()
                            .parent()
                            .parent()
                            .parent();
                    }
                    moduleDiv.height(rightDivHeight + 'rem');
                }
            }
        }
    } else {
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < list.length; i++) {
            var k = i;
            // eslint-disable-next-line no-redeclare
            var rightDivDom, leftDivDom;
            if (_isModuleV2) {
                rightDivDom = jm('#' + moduleId + ' .productWaterFall .commonProRight').closest('.productWaterFall');
            } else {
                rightDivDom = jm('#' + moduleId + ' .productWaterFall .commonProRight')
                    .parent()
                    .parent()
                    .parent();
            }
            if (i == 0) {
                jm('#' + moduleId + ' .productWaterFall .style8Img')
                    .eq(i)
                    .height(divEndsHeight + 'rem');
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ 'vertical-align': 'top' });
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ top: 'unset' });
                jm('#' + moduleId + ' .productWaterFall')
                    .eq(i)
                    .css({ position: 'static' });
            } else if (k + 1 == list.length) {
                jm('#' + moduleId + ' .productWaterFall .style8Img')
                    .eq(i)
                    .height(divEndsHeight + 'rem');
                if (list.length % 2 == 1) {
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ 'vertical-align': 'top' });
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ top: 'unset' });
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ position: 'static' });
                } else if (list.length % 2 == 0) {
                    // 获取居右的模块高度
                    var rightDiv = parseFloat(rightDivDom.height());
                    //px转化为rem
                    rightDiv = rightDiv / htmlFontSize;
                    var topPosition2 = (rightDiv + 0.5) * ((i + 1) / 2 - 1);
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({
                            position: 'absolute',
                            top: topPosition2 + 'rem',
                        });
                }
            } else {
                jm('#' + moduleId + ' .productWaterFall .style8Img')
                    .eq(i)
                    .height(divComHeight + 'rem');
                //获取居右的模块高度
                // eslint-disable-next-line no-redeclare
                var rightDiv = parseFloat(rightDivDom.height());
                //px转化rem
                rightDiv = rightDiv / htmlFontSize;
                if (i % 2 == 0) {
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ 'vertical-align': 'top' });
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ top: 'unset' });
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({ position: 'static' });
                } else if (i % 2 == 1) {
                    var topPosition3 = (rightDiv + 0.5) * ((i + 1) / 2 - 1);
                    jm('#' + moduleId + ' .productWaterFall')
                        .eq(i)
                        .css({
                            position: 'absolute',
                            top: topPosition3 + 'rem',
                        });
                }
            }
        }
    }

    //管理态添加置顶提醒由于是在管理态下，用jq直接使用$
    if (VITE_APP_MODE !== 'visitor') {
        var module = $('#' + moduleId),
            productWaterFall = module.find('.productWaterFall'),
            productselect = module.attr('_productselect') == 'true' ? true : false,
            elem,
            topFlag;
        productWaterFall.each(function (index, el) {
            elem = $(el);
            topFlag = elem.attr('topswitch') == 'off';
            let waterFallImg = elem.find('.waterFallImg');
            waterFallImg.wrap("<div class='J_productListImgWrap fk-productListImgWrap'></div>");
            if (topFlag && !productselect) {
                elem.find('.J_productListImgWrap').append("<div class='f-productListTopFlag' >置顶</div>");
            }
        });
    }
    var tempProductWaterFall = jm('#' + moduleId + ' .productWaterFall');
    tempProductWaterFall.each(function (index, el) {
        var elem = jm(el);
        var productId = elem.attr('productId');
        if (productSalesPromotionInfo[productId]) {
            var salesPromotionInfo = productSalesPromotionInfo[productId];
            if (salesPromotionInfo.salePromotionOpen) {
                elem.find('.waterFallImg').append(
                    "<div class='g_bgColor g_main_bgColor_v3 f-productListTopPromotion'>" +
                        salesPromotionInfo.saleFlagName +
                        '</div>\n'
                );
            }
        }
    });

    let waterfallList = jm(`#${moduleId} .productWaterFall`);
    if (waterfallList.length % 2 == 0 && waterfallList.length > 2) {
        // 取最后一个，获取其高度 + top，跟 mProductList 的高度做对比，若前者大于后者，说明溢出，需要修正高度
        let lastFallItem = waterfallList[waterfallList.length - 1];
        let $el = jm(lastFallItem);
        let top = lastFallItem.offsetTop;
        let height = parseFloat($el.height());
        let $productList = jm(`#${moduleId}`);
        let productListElHeight = document.getElementById(moduleId).clientHeight;
        if (top + height > productListElHeight) {
            $productList.css('height', top + height + 'px');
        }
    }

    if (isInTab) {
        $module.parents('.J_formTabContent').removeClass('showTabContent');
    }
};

/********** 产品展示样式9，左右小滑动 *****************/
export const productCrossedSlideSecSwipe = function (productSlideList, productSlide, entry, isNewAddModule) {
    // Mobi.hideProductItemContainer(productSlideList);
    var itemContainerDom = jm('#' + productSlideList)[0],
        productDom = jm('#' + productSlide)[0];

    if (!productDom) {
        return;
    }
    var itemContainer = itemContainerDom,
        centerWidth = parseInt(jm(productDom).width()),
        proItemsWidth = 0,
        proItemArray = productDom.querySelectorAll('.productCrossedSlideSec'),
        // var proLineArray = productDom.querySelectorAll(".itemSep1");
        htmlFontSize = Fai.top._htmlFontSize,
        productMargins = isNewAddModule
            ? proItemArray.length * parseInt(jm(proItemArray[0]).css('margin-left'))
            : proItemArray.length * 0.5 * htmlFontSize,
        /* jser 添加mobi管理态的拖拽功能,fix 垂直滑动时iscroll横向滑动的bug 2017-2-9*/
        //mobi管理态下要用到的变量
        $prodSlide = jm(productDom), //滑动的容器
        $prodSlideList = jm('#' + productSlideList), //滑动的列表容器
        isMove = false,
        canClickA = true,
        //mobi逻辑要用到的变量
        isVer = undefined, //代表滑动的方向
        $editLayer = null, //提示工具
        listStartLeft,
        listEndLeft = 0,
        $iScrollMask = jm('<div></div>'), //用于阻止iscroll滑动的遮罩层
        oldX,
        oldY,
        currX,
        currY; //新老坐标

    if (entry !== 'productList') {
        $prodSlide.css('margin-top', '30px'); //增加产品离顶部的距离
    }

    /*pc 函数*/
    //鼠标移出
    function prodMouseOut() {
        //事件解绑
        $prodSlide.off('mouseup.prod');
        $prodSlide.off('mousemove.prod');
        $prodSlide.off('mouseout.prod');
    }
    //鼠标按下
    function prodMouseDown(event) {
        //触发懒加载
        $prodSlideList.find('div.style9Img').trigger('horizonScroll');
        //插入节点
        $prodSlideList.append($iScrollMask);
        //获取初始化坐标
        oldX = currX = event.pageX;
        oldY = currY = event.pageY;
        //事件绑定
        $prodSlide.off('mousemove.prod').on('mousemove.prod', prodMouseMove);
        $prodSlide.off('mouseup.prod').on('mouseup.prod', prodMouseUp);
        $prodSlide.off('mouseout.prod').on('mouseout.prod', prodMouseOut); //鼠标移除的时候去解除事件绑定

        event.preventDefault(); //阻止默认的拉选事件，避免dom的滑动被影响
        event.stopPropagation(); //阻止冒泡，防止商品模块被整体拖动
    }
    //鼠标移动
    function prodMouseMove(event) {
        var leftMax = 0,
            leftMin = $prodSlide.width() - $prodSlideList.width(),
            left = parseInt($prodSlideList.css('left'));

        isMove = true;

        currX = event.pageX;
        currY = event.pageY;

        left += currX - oldX; //计算每次move后的left值
        //边界检测，保证不出界
        if (left > leftMax) {
            left = leftMax;
        }

        if (left < leftMin) {
            left = leftMin;
        }

        $prodSlideList.css('left', left + 'px');

        //记录此次的坐标
        oldX = currX;
        oldY = currY;

        //工具消失
        if ($editLayer && $editLayer.length) {
            $editLayer.hide();
        }
        event.stopPropagation();
    }
    //鼠标松开
    function prodMouseUp() {
        var editDisX = 0,
            editLeft;

        //事件解绑
        $prodSlide.off('mouseup.prod');
        $prodSlide.off('mousemove.prod');
        $prodSlide.off('mouseout.prod');

        listEndLeft = $prodSlideList.offset().left; //最终的left

        //工具出现(计算出现前后位置的偏移量,依据商品列表被移动的距离)
        if ($editLayer && $editLayer.length) {
            editDisX = listEndLeft - listStartLeft; //商品列表初始left - 商品列表最终left
            editLeft = parseFloat($editLayer.css('left')); //

            $editLayer.css('left', editLeft + editDisX + 'px'); //初始left + 偏移量 = 最终位置
            $editLayer.show();
        }
        //清空工具
        $editLayer = null;
    }

    /*mobi 函数*/
    /* 判断滑动方向的状态
     * @return 	true 垂直滑动
     * 			false 水平滑动
     */
    function isVertical(x1, y1, x2, y2) {
        var dx = Math.abs(x1 - x2),
            dy = Math.abs(y1 - y2);

        //避免分母为0
        if (dx === 0 && dy === 0) {
            return;
        }
        if (dx === 0 && dy > 0) {
            return true;
        }
        if (dy === 0 && dx > 0) {
            return false;
        }
        return dy / dx > 1 ? true : false;
    }
    //获取点击处的坐标信息
    function getTouchPos(event) {
        var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
        return {
            pageX: touch.pageX,
            pageY: touch.pageY,
        };
    }
    //touchstart事件的回调函数
    function prodTouchStart(event) {
        //触发懒加载
        $prodSlideList.find('div.style9Img').trigger('horizonScroll');

        //获取坐标
        var pos = getTouchPos(event);

        oldX = currX = pos.pageX;
        oldY = currY = pos.pageY;

        //绑定事件
        $prodSlide.off('touchmove.prod').on('touchmove.prod', prodTouchMove);
        $prodSlide.off('touchend.prod').on('touchend.prod', prodTouchEnd);

        //添加iscroll遮罩层
        $prodSlideList.append($iScrollMask);
    }
    //touchmove事件的回调函数
    function prodTouchMove(event) {
        //获取坐标
        var pos = getTouchPos(event);
        currX = pos.pageX;
        currY = pos.pageY;

        if (isVer === undefined) {
            if (oldX !== undefined && oldY !== undefined) {
                isVer = isVertical(oldX, oldY, currX, currY); //获取当前滑动状态
                $prodSlideList.off('touchmove.prod');
            }
        }
        oldX = currX;
        oldY = currY;
    }
    //touchend事件的回调函数
    function prodTouchEnd() {
        //删除iscroll遮罩层
        $iScrollMask.remove();

        //恢复标志位
        isVer = oldX = oldY = currX = currY = undefined;
        //事件解绑
        $prodSlide.off('touchmove.prod', prodTouchMove);
        $prodSlide.off('touchend.prod', prodTouchEnd);
    }

    //由于这里每个块都是等宽的，不依赖于每一个的宽，只取第一个即可
    if (proItemArray.length > 0) {
        proItemsWidth = proItemArray[0].offsetWidth * proItemArray.length;
    }

    proItemsWidth += productMargins;

    if (proItemsWidth > centerWidth) {
        if (navigator.appVersion.match(/MSIE [\d.]+/)) {
            if (parseInt(navigator.appVersion.match(/MSIE ([\d.]+)/)[1]) === 10) {
                proItemsWidth = proItemsWidth + proItemArray.length * 2;
            }
        }

        proItemsWidth = proItemsWidth + 10;
        jm(itemContainer).css('width', proItemsWidth + 'px');
        var startX = jm.getCookie('startX') ? parseInt(jm.getCookie('startX')) : 0; //获取上一次移动到的位置,优化用户体验

        /* jser 添加PC端、移动端的拖拽功能,fix 垂直滑动时iscroll横向滑动的bug 2017-2-9*/
        if (browser.isPc()) {
            // 是PC端的话
            //初始化样式，建议类名作为解决方案
            $prodSlide.css({
                position: 'relative',
                height: parseInt($prodSlideList.height()) + 'px',
            });
            $prodSlideList.css({
                position: 'absolute',
                left: 0,
                top: 0,
            });

            //绑定商品整体鼠标按下事件
            $prodSlide.off('mousedown.prod').on('mousedown.prod', prodMouseDown);
            $prodSlide.off('mousedown.prodItem').on('mousedown.prodItem', '.J_prodItem', function () {
                $editLayer = jm('.editLayer'); //获取工具
                listStartLeft = $prodSlideList.offset().left; //获取商品列表初始left
            });
            //fix a链接跳转和手势滑动的冲突
            $prodSlide.off('click.checkMove').on('click.checkMove', function () {
                if (isMove) {
                    //如果手指曾经移动，那么就不是点击链接的行为了
                    canClickA = false;
                } else {
                    canClickA = true;
                }
                isMove = false;
            });
            //阻止误操作导致的页面跳转
            $prodSlide.off('click.prodLink').on('click.prodLink', '.J_prodItem-a', function () {
                return canClickA;
            });
        } else {
            let curIsver;
            let productListTouchMove = function (event) {
                let touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
                currX = touch.pageX;
                currY = touch.pageY;

                if (curIsver === undefined) {
                    if (oldX !== undefined && oldY !== undefined) {
                        curIsver = isVertical(oldX, oldY, currX, currY); //获取当前滑动状态
                        if (curIsver === false) {
                            event.preventDefault();
                        }
                        $prodSlide.off('touchmove.prod');
                    }
                }

                oldX = currX;
                oldY = currY;
            };

            let productListTouchEnd = function () {
                curIsver = oldX = oldY = currX = currY = undefined;
                //事件解绑
                $prodSlide.off('touchmove.proCross', productListTouchMove);
                $prodSlide.off('touchend.proCross', productListTouchEnd);
            };
            //添加插件
            new iScroll(productDom, {
                x: startX,
                scrollX: true,
                scrollY: false,
                mouseWheel: true,
                remarkXCoordinate: true,
                onBeforeScrollStart: null,
                onScrollMove: null,
                onBeforeScrollEnd: null,
                onScrollEnd() {
                    //触发懒加载
                    $prodSlideList.find('div.style9Img').trigger('horizonScroll');
                },
            });

            $prodSlide.off('touchstart.prod').on('touchstart.prod', prodTouchStart);

            //水平滑动，禁止文档移动
            $prodSlide.off('touchstart.proCross').on('touchstart.proCross', function (event) {
                var touch = event.touches.length > 0 ? event.touches[0] : event.chengedTouches[0];
                oldX = touch.pageX;
                oldY = touch.pageY;

                $prodSlide.off('touchmove.proCross').on('touchmove.proCross', productListTouchMove);
                $prodSlide.off('touchend.proCross').on('touchend.proCross', productListTouchEnd);
            });

            //垂直滑动，禁止iscroll滑动
            //添加iscroll遮罩层的样式，用于利用事件冒泡阻止滑动(这个模块的样式是在base.src.css中，不好加)
            $iScrollMask.css({
                width: '100%',
                height: '100%',
                position: 'absolute',
                opacity: 0,
                left: 0,
                top: 0,
                zIndex: -1,
            });
            //在子节点上添加事件，利用事件冒泡在垂直滑动的时候，阻止iscroll滑动
            $prodSlideList
                .children()
                .off('touchmove.banIScroll')
                .on('touchmove.banIScroll', function (event) {
                    if (isVer === true) {
                        event.stopPropagation();
                    }
                });
        }
    }
    $prodSlideList.find('.productCrossedSlideSec').show(); //宽度未初始化完，隐藏掉后面dom节点。初始化最新宽度之后，要全部显示。（防止页面跳动）
    //触发懒加载
    //$prodSlideList.find('div.style9Img').trigger('horizonScroll');
};

/****************左右小滑动切换图片（自定义宽高）*******************/
export const productCrossedSlideSec = function (moduleId, proScaleWidth, proScaleHeight, wrap, isNewAddModule) {
    var divWidth = jm('#' + moduleId + ' .productCrossedSlideSec .style9Img').width();
    var htmlFontSize = Fai.top._htmlFontSize;
    var themev3 = Fai.top._openThemeV3;
    var currentHeight,
        hasDiifHeight = false;
    //获取普通产品样式的高度
    var divProHeight = (divWidth * proScaleHeight) / (proScaleWidth * htmlFontSize);
    divProHeight = parseFloat(divProHeight.toFixed(4));

    //获取价格名称字体大小
    var priceFontSize = jm('#' + moduleId + ' .paramPadding .paramName').css('font-size');
    //主题3.0判断推荐产品文章是否是多行
    if (themev3) {
        jm('#' + moduleId + ' .paramPadding .paramName').each(function () {
            var thisHeight = parseInt(jm(this).height());
            if (currentHeight == undefined) {
                currentHeight = thisHeight;
            } else if (currentHeight != thisHeight) {
                hasDiifHeight = true;
            }
        });
    }
    //jq获取的font-size是带单位的，所以要截取。单位转换
    if (typeof priceFontSize != 'object') {
        var pFontSize = priceFontSize.substring(0, priceFontSize.indexOf('p')) / htmlFontSize + 0.2;
        if (!isNewAddModule) {
            jm('#' + moduleId + ' .productCrossedSlideSec .paramPadding .paramName').css(
                'line-height',
                pFontSize + 'rem'
            );
        }
        if (!hasDiifHeight && themev3) {
            pFontSize = parseFloat(pFontSize.toFixed(4)) * 1;
        } else {
            pFontSize = parseFloat(pFontSize.toFixed(4)) * 2;
        }
    } else {
        pFontSize = 0;
    }
    var paramWrap = 'paramWrap';
    if (!isNewAddModule) {
        if (wrap == paramWrap) {
            if (getBrowserType() == BrowserType.FIREFOX) {
                jm('#' + moduleId + ' .productCrossedSlideSec .paramPadding .paramName').height(pFontSize + 'rem');
            } else {
                jm('#' + moduleId + ' .productCrossedSlideSec .paramPadding .paramName').height(
                    pFontSize - 0.08 + 'rem'
                );
            }
            //jm("#"+moduleId+" .productCrossedSlideSec .paramPadding .paramName").height(pFontSize-0.1+"rem");
        } else {
            jm('#' + moduleId + ' .productWaterFall .paramPadding .paramName').height(pFontSize / 2.0 - 0.1 + 'rem');
        }
    }
    //给样式高度赋值
    jm('#' + moduleId + ' .productCrossedSlideSec .style9Img').height(divProHeight + 'rem');
};
