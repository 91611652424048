<template>
    <div class="variable_select">
        <template v-if="isPc()">
            <div
                v-outsideClick="handleClose"
                class="variable_input variable_select_input"
                :style="inputBoxStyle"
                :class="focusCls"
            >
                <input
                    ref="selectInput"
                    type="text"
                    readonly
                    unselectable="on"
                    :placeholder="placeHolder"
                    :value="currentVal.key"
                    class="select_input input_style"
                    :style="inputStyle"
                    @click.stop="toggleList"
                />
                <span class="arrow faisco-icons-S000112" @click="toggleList" />
            </div>
            <div v-show="list.length && showList" ref="selectList" v-dom-portal="target" class="select_list_wrap">
                <ul class="select_list">
                    <li
                        v-for="item in list"
                        class="select_item J_noOpenDesigner g_oldThemeHoverBgColor g_main_hoverbgColor_v3"
                        :class="selectActive(item)"
                        :style="optionFontStyle"
                        @click="selectItem(item)"
                        >{{ item.key }}</li
                    >
                </ul>
            </div>
        </template>
        <template v-else>
            <div
                v-outsideClick="handleClose"
                class="variable_input variable_select_input"
                :style="inputBoxStyle"
                :class="focusCls"
            >
                <span class="arrow faisco-icons-S000112" />
                <select
                    class="mobileSelect m_mobi_form_input_text J_mobi_form_select"
                    :style="inputStyle"
                    @change="change"
                    @blur="selectBlurHandle"
                    @click="selectClickHandle"
                >
                    <option :selected="currentVal === ''" value="noneValue" style="display: none">{{
                        placeHolder
                    }}</option>
                    <option v-for="item in list" :selected="currentVal.key === item.key" :value="item.key">
                        {{ item.key }}</option
                    >
                </select>
            </div>
        </template>
    </div>
</template>

<script>
import { browser } from '@jz/utils';
export default {
    name: 'VariableSelect',
    directives: {
        outsideClick: {
            bind: function (el, binding) {
                function documentHandler(e) {
                    if (el.contains(e.target)) {
                        return false;
                    }
                    if (binding.expression) {
                        binding.value(e.target);
                    }
                }
                var isBubble = binding.modifiers.bubble;
                var useCapture = !isBubble;
                el.__vueClickOutside__ = documentHandler;
                document.addEventListener('click', documentHandler, useCapture);
            },
        },
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: Object,
        list: {
            type: Array,
            default: () => [],
        },
        placeHolder: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/require-valid-default-prop
        optionFontStyle: {
            type: Object,
            default: () => ({}),
        },
        // eslint-disable-next-line vue/require-valid-default-prop
        inputStyle: {
            type: Object,
            default: () => ({}),
        },
        // eslint-disable-next-line vue/require-valid-default-prop
        inputBoxStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentVal: this.value ? this.value : { key: '', value: '' },
            showList: false,
            target: 'body',
            noneValue: 'noneValue',
            currentValue: '',
        };
    },
    computed: {
        focusCls() {
            var cls = {};
            cls['focuAcitive'] = this.showList;
            return cls;
        },
    },
    methods: {
        selectClickHandle() {
            this.showList = !this.showList;
        },
        selectBlurHandle() {
            this.showList = false;
        },
        isPc() {
            var isPc = false;
            if (browser.isPc()) {
                isPc = true;
            }
            return isPc;
        },
        selectActive(item) {
            var cls = {};
            if (item.key == this.currentVal.key) {
                cls['g_main_bgColor_v3'] = true;
                cls['g_oldThemeBgColor'] = true;
                cls['selectActive'] = true;
            }
            return cls;
        },
        change(event) {
            this.list.forEach((val) => {
                if (val.key === event.target.value) {
                    this.$emit('input', val);
                    return;
                }
            });
        },
        handleClose() {
            this.showList = false;
            this.$refs.selectInput && this.$refs.selectInput.blur();
        },
        selectItem(item) {
            this.$emit('input', item);
            this.currentVal = item;
            // eslint-disable-next-line vue/no-mutating-props
            this.value = item;
            this.showList = false;
        },
        toggleList() {
            this.showList = !this.showList;
            if (this.showList) {
                //定位置
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                let selectElm = this.$el;
                let selectClientRect = selectElm.getBoundingClientRect();
                let selctElmWidth = selectClientRect.width;
                let listTop = selectClientRect.top + selectClientRect.height + scrollTop;
                let listLeft = selectClientRect.left;
                this.$refs.selectList.style.width = selctElmWidth + 'px';
                this.$refs.selectList.style.top = listTop + 'px';
                this.$refs.selectList.style.left = listLeft + 'px';
                Vue.nextTick(() => {
                    jm(document).one('click', () => {
                        this.showList = false;
                    });
                });
            }
        },
    },
};
</script>

<style lang="scss">
.select_list .selectActive,
.select_list .select_item:hover {
    color: #fff !important;
}
.variable_select .arrow {
    position: absolute;
    right: 0.65rem;
    font-size: 0.55rem;
    color: #333;
    cursor: pointer;
    top: 0.65rem;
    transition: transform 0.1s;
    transform-origin: center;
}
.variable_select .focuAcitive .arrow {
    transform: rotate(-180deg);
}
</style>
