<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="bigItemClasses"
        :picnum="4"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="isOnlyTitle">
            <div class="mixNewsStyleImgBox3">
                <a
                    v-drag-resize="_resizeOptions"
                    class="mixNewsStyleImgBoxWrap"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div class="headPicTopWrap">
                        <div
                            id="newsTitlePic"
                            :alt="altName"
                            :class="bigPicClasses"
                            :style="bigPicStyle"
                            :src-original="info.realPicId ? info.bigPicPath : ''"
                            class="picScaleClass J_img_lazyload"
                        ></div>
                        <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>
                    </div>
                    <div class="mixNewsStyleTitleContainer3" :class="isTextInner">
                        <div v-if="!isNewAddModule" :class="_topFlagClass"></div>
                        <div
                            v-if="!isNewAddModule"
                            :class="[titleClasses, titleLine]"
                            class="news_title"
                            :style="news_title_style"
                        >
                            {{ info.title }}
                        </div>
                        <div
                            v-if="isNewAddModule && module.content.ts === 0"
                            :class="[titleClasses, titleLine]"
                            class="news_title"
                        >
                            {{ info.title }}
                        </div>
                    </div>
                    <div
                        v-if="isNewAddModule && module.content.ts === 1"
                        :class="[titleClasses, titleLine]"
                        class="news_title textOuter textBottomOuter"
                    >
                        {{ info.title }}
                    </div>
                </a>
            </div>
        </div>
        <div v-if="module.content.ts == 1" class="g_separator separatorLine"></div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'HeadPicItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        bigItemClasses() {
            return `${this._lineClass} mixNewsStyleLine newsLine J_picNum`;
        },
        indexPicHeight() {
            // 首图高度
            const picHeightSet = this.module.content.indexPicHeightParam || {};
            const indexPicHeightType = picHeightSet.indexPicHeightType;
            return indexPicHeightType == 2 ? picHeightSet.indexPicHeight / 20 : 160 / 20;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        bigPicClasses() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        bigPicStyle() {
            let picStyle = {};
            picStyle.height = `${this.indexPicHeight}rem`;
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            return picStyle;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        titleTop() {
            if (this.isNewAddModule && this.module.content.ts === 1 && this.module.content.ptp === 1) {
                return true;
            } else {
                return false;
            }
        },
        isTextInner() {
            return this.isNewAddModule && this.module.content.ts === 0 ? 'textInner' : '';
        },
    },
};
</script>

<style>
.news_list_wrap .head_pic_text_list .mixNewsStyleImgBox3 {
    padding-top: 0.74rem;
}
.news_list_wrap .head_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox3 .news_title {
    font-size: 0.7rem;
}
.news_list_wrap .head_pic_text_list .mixNewsStyleLine .mixNewsStyleImgBox3 .mixNewsStyleTitleContainer3 {
    background: rgba(0, 0, 0, 0.5);
}
</style>
