<template>
    <div class="group-list">
        <div v-for="item in showList" :key="item.id" class="group-item">
            <div
                class="line j-lineH J_lineH icon-gline"
                :class="openIconClass(item)"
                @click="toggleGroup(item, $event)"
            >
                <a
                    class="first-group-name first_groupname g_productSort_name"
                    :href="createPgrUrl(item)"
                    target="_self"
                    :style="firstGroupNameStyle"
                    v-text="item.name"
                >
                </a>
            </div>
            <div v-if="openGroup" class="g_separator separatorLine"></div>
            <div class="child-group-container">
                <div
                    v-for="(secondItem, secondIndex) in item.childrens"
                    :key="secondItem.id"
                    class="second-group-container"
                >
                    <div
                        class="second-line icon-gline"
                        :class="openIconClass(secondItem)"
                        @click="toggleGroup(secondItem, $event)"
                    >
                        <a
                            class="second-group-name sed_groupname"
                            :style="sedGroupNameStyle"
                            target="_self"
                            :href="createPgrUrl(secondItem)"
                            v-text="secondItem.name"
                        ></a>
                    </div>
                    <template v-if="secondItem.childrens">
                        <div
                            v-if="
                                secondItem.childrens.length == 0 &&
                                secondIndex != item.childrens.length - 1 &&
                                openGroup
                            "
                            class="g_separator separatorLine"
                        ></div>
                        <div class="third-list">
                            <a
                                v-for="thirdItem in secondItem.childrens"
                                :key="thirdItem.id"
                                :style="thirdGroupNameStyle"
                                :href="createPgrUrl(thirdItem)"
                                class="third-item f-thirdGroupName"
                                target="_self"
                                v-text="thirdItem.name"
                            ></a>
                        </div>
                        <div
                            v-if="
                                secondItem.childrens.length > 0 && secondIndex != item.childrens.length - 1 && openGroup
                            "
                            class="g_separator separatorLine"
                        ></div>
                    </template>
                </div>
            </div>
            <div v-if="item.childrens.length > 0 && openGroup" class="g_separator separatorLine last_separator"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProductGroupTextStyle',
    props: {
        dataMap: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        moduleId() {
            return this.dataMap.moduleId;
        },
        moduleStyle() {
            return this.dataMap.moduleStyle;
        },
        idList() {
            return this.dataMap.idList || [];
        },
        catalogList() {
            return this.dataMap.catalogList || [];
        },
        showLevel() {
            return this.dataMap.showLev;
        },
        isAllGroup() {
            return this.showLevel == 0;
        },
        productGroupList() {
            return this.dataMap.productGroupList || [];
        },
        groupType() {
            // 0：指定分类（1、2、3分类才有）， 1：指定分组（2、3级分类才有）
            return this.dataMap.groupType;
        },
        mheightSetting() {
            return this.dataMap.mheightSetting || {};
        },
        firstGroupNameStyle() {
            return this.dataMap.firstGroupNameStyle || {};
        },
        showList() {
            let list = [];
            if (this.groupType == 0) {
                list = this.getListByLevel(this.showLevel);
            } else if (this.groupType == 1) {
                list = this.getListByGroup(this.showLevel);
            }
            return list;
        },
        openGroup() {
            return this.dataMap.gdo == 0;
        },
        prUrl() {
            return this.dataMap.prUrl || '';
        },
        newNpgcUrl() {
            return this.dataMap.newNpgcUrl || '';
        },
        sedGroupNameStyle() {
            let style = {};
            if (VITE_APP_MODE !== 'visitor') {
                const textSetting = this.mheightSetting.sgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
        thirdGroupNameStyle() {
            let style = {};
            if (VITE_APP_MODE !== 'visitor') {
                const textSetting = this.mheightSetting.tgn;
                if (textSetting.type == 1) {
                    style.fontSize = textSetting.nlfs + 'rem';
                    style.color = textSetting.nlfc;

                    if (textSetting.nlfi == 1) {
                        style.fontStyle = 'italic';
                    }

                    if (textSetting.nlfb == 1) {
                        style.fontWeight = 'bold';
                    }

                    if (textSetting.nlfd == 1) {
                        style.textDecoration = 'underline';
                    }
                }
            }
            return style;
        },
    },
    methods: {
        openIconClass(item) {
            let classlist = [];
            if (item.childrens && item.childrens.length == 0) {
                classlist.push('hide-icon');
            } else if (this.openGroup) {
                classlist.push('rotate-icon');
            }
            return classlist;
        },
        openContentClass(item, prefix) {
            let classList = [];
            classList.push(`${prefix}-${item.id}`);
            return classList;
        },
        toggleGroup(item, event) {
            if ([0, 2, 3].includes(this.showLevel)) {
                if (!this.openGroup) {
                    let itemData = { ...item };
                    if (this.groupType == 1) {
                        // 分组下，分组的id为数值就好
                        itemData.id = 0;
                    } else {
                        itemData.groupList = item.childrens.map((item) => {
                            return item.id;
                        });
                    }

                    this.$emit('itemClick', itemData);
                } else {
                    const { target } = event;
                    if (target) {
                        let targetEl = jm(target);
                        if (targetEl.hasClass('icon-gline')) {
                            targetEl.hasClass('rotate-icon')
                                ? targetEl.removeClass('rotate-icon')
                                : targetEl.addClass('rotate-icon');
                        } else {
                            // 查找父元素
                            let parentEl = jm(target).parents('.icon-gline');
                            parentEl.hasClass('rotate-icon')
                                ? parentEl.removeClass('rotate-icon')
                                : parentEl.addClass('rotate-icon');
                        }
                    }
                }
            }
        },
        createPgrUrl(item) {
            const idIsStr = typeof item.id == 'string';
            if (this.showLevel == 1 || (item.childrens && item.childrens.length == 0 && !idIsStr)) {
                return `${this.prUrl}?mid=${this.moduleId}&groupId=${item.id}`;
            } else {
                return !this.openGroup || idIsStr ? null : `${this.prUrl}?mid=${this.moduleId}&groupId=${item.id}`;
            }
        },
        getListByLevel(level) {
            let list = [];

            let firstGroupList = this.productGroupList
                .filter((item) => {
                    return item.id != 0 && item.parentId == 0;
                })
                .map((item) => {
                    return { ...item, childrens: [] };
                });
            let secondGroupList = this.productGroupList
                .filter((item) => {
                    return item.id != 0 && item.parentId != 0;
                })
                .map((item) => {
                    return { ...item, childrens: [] };
                });

            if (level == 0 || level == 3) {
                // 全部分类、三级分类
                secondGroupList.forEach((secondGroup) => {
                    secondGroup.childrens = secondGroupList.filter((item) => {
                        return item.parentId == secondGroup.id;
                    });
                });
                list = firstGroupList.map((firstItem) => {
                    // 查找二层节点
                    firstItem.childrens = secondGroupList.filter((secondItem) => {
                        return secondItem.parentId == firstItem.id;
                    });
                    return firstItem;
                });
            } else if (level == 1) {
                list = this.productGroupList
                    .filter((item) => {
                        return this.idList.includes(item.id);
                    })
                    .map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            childrens: [],
                        };
                    });
            } else if (level == 2) {
                list = firstGroupList.map((item) => {
                    let data = { ...item, childrens: [] };
                    data.childrens = secondGroupList.filter((secondItem) => {
                        return secondItem.parentId == data.id;
                    });
                    return data;
                });
            }

            if ([1, 2, 3].includes(level)) {
                list = list.filter((item) => {
                    return this.idList.includes(item.id);
                });
            }

            return list;
        },
        getListByGroup(level) {
            // 分组名作为第一分类
            return this.catalogList.map((item, index) => {
                let data = {
                    childrens: [],
                    id: `module${this.moduleId}_group_${index + 1}`,
                };
                const { name, groupList = [] } = item;
                // 分组里的groupList 作为第二分类
                groupList.forEach((groupId) => {
                    let groupItem = this.productGroupList.find((group) => {
                        return group.id == groupId;
                    });
                    if (groupItem) {
                        let groupItemData = {
                            id: groupItem.id,
                            name: groupItem.name,
                            childrens: [],
                        };

                        if (level === 3) {
                            // groupList中的孩子分类 作为第三分类
                            groupItemData.childrens = this.productGroupList
                                .filter((productGroup) => {
                                    return productGroup.parentId == groupId;
                                })
                                .map((item) => {
                                    return {
                                        id: item.id,
                                        name: item.name,
                                        childrens: [],
                                    };
                                });
                        }
                        data.childrens.push(groupItemData);
                    }
                });

                data.name = name;
                data.groupList = groupList;
                return data;
            });
        },
    },
};
</script>
<style lang="scss">
.group-list {
    .group-item {
        background: #fff;
        .j-lineH {
            display: flex;
            align-items: center;
            min-height: 2rem;
        }
        .hide-icon:after {
            display: none;
        }
        .icon-gline:after {
            transition: all 0.3s;
            top: unset;
        }
        .rotate-icon:after {
            transform: rotate(90deg);
        }
        .last_separator {
            display: none;
        }
        .rotate-icon ~ .child-group-container {
            display: block;
        }
        .rotate-icon ~ .last_separator {
            display: block;
        }

        .child-group-container {
            transition: all 0.3s;
            display: none;
            padding: 0 0.64rem;
        }
        .g_productSort_name {
            width: 100%;
        }
        .second-line {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding: 0.64rem 0.6rem 0.63rem 0.2133rem;
        }
        .second-line.icon-gline:after {
            right: -0.1rem;
        }
        .first-group-name {
            font-size: 0.7rem;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1rem;
        }
        .second-group-name {
            display: block;
            font-size: 0.6rem;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 0.9rem;
        }
        .rotate-icon ~ .third-list {
            display: flex;
        }
        .third-list {
            .third-item {
                display: inline-block;
                font-size: 0.512rem;
                color: #666666;
                margin: 0 0.2rem 0.8rem;
                width: 4.4rem;
            }
        }
    }
}
.third-list {
    display: none;
    flex-wrap: wrap;
    transition: all 0.3s;

    .third-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.third_group_name_wrap .group-list {
    .third-list {
        .f-thirdGroupName {
            white-space: normal;
            text-overflow: initial;
            word-break: break-all;
        }
    }
}
.fk-productGroup.sec_group_name_wrap .second-line .second-group-name {
    width: 100%;
}
</style>
