<template>
    <div>
        <template v-for="(info, index) in newsList">
            <mask-item :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
        </template>
    </div>
</template>

<script>
import MaskItem from './mask-item.vue';

export default {
    name: 'MaskList',
    components: { MaskItem },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
};
</script>

<style></style>
