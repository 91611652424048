<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" :id="moduleIdAttr" :class="moduleCls" class="multi_photo_list">
            <template v-if="!picDataList.length && manageMode">
                <div class="empty_tips_panel">
                    <div class="text">请在右侧面板中编辑图片</div>
                    <button-component class="addPhoto" active @click.stop="addMultiPhotoList"
                        >添加图片</button-component
                    >
                </div>
            </template>
            <template v-if="!picDataList.length && !manageMode">
                <div class="cus_Empty_tips_panel">
                    <div class="text">没有添加图片</div>
                </div>
            </template>
            <template v-if="picDataList.length">
                <component
                    :is="componentMap[type]"
                    :content-list="picDataList"
                    :photo-swipe-list="photoSwipeList"
                    :no-lazy-load="noLazyLoad"
                />
                <image-viewer
                    v-if="showMobiViewer"
                    :z-index="zIndex"
                    :initial-index="initialIndex"
                    :on-close="onClose"
                    :url-list="imageViewerList"
                    :is-mobi="true"
                    :content-terminal="'mobi'"
                    :manage-mode="manageMode"
                    :log-event-config="logEventConfig"
                    :document-event-el="documentEventEl"
                    append-to-container-id="g_body"
                    :append-to-body="false"
                    :disable-long-press="banCopyTextImg"
                />

                <pagenation
                    v-if="showPageComponent"
                    :module-id="moduleId"
                    :total-size="totalSize"
                    :page-size="module.content.count"
                    :pageno="pageno"
                    :open-theme-v3="openThemeV3"
                    :ajax-pagenation="true"
                    @on-change="changePage"
                >
                </pagenation>
            </template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import { mapGetters, mapState } from 'vuex';
import pagenation from './components/pagenation.vue';
import WaterFallList from './components/waterfallList/waterfall-list.vue';
import VerticalList from './components/verticalList/vertical-list.vue';
import SlideList from './components/slideList/slide-list.vue';
import CartList from './components/cartList/cart-list.vue';
import HoverList from './components/hoverList/hover-list.vue';
import LeftRightList from './components/leftRightList/left-right-list.vue';
import { slidesshowMixins } from '@/modules/moduleMixins';
import { isBrowserEnv } from '@jz/utils';
import { bindEvent, bindLazyLoad } from './utils';
import { getPhotoGroupData, getMultiPhotoListData } from '@/api/photo';
import { deepClone, encodeHtml } from '@/shared/util';

const manageMode = VITE_APP_MODE !== 'visitor';

export default {
    name: 'MultiPhotoList',
    components: {
        ModuleFrame,
        pagenation,
        WaterFallList,
        VerticalList,
        SlideList,
        CartList,
        HoverList,
        LeftRightList,
    },
    mixins: [slidesshowMixins],
    provide() {
        return {
            module: this.module,
            options: this.options,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            render: true,
            hrefStr2: '',
            pageno: 0,
            inTab: 0,
            picDataList: [],
            photoSwipeList: [],
            totalSize: 0,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: 'mobi-列表多图',
                    jz_version: isBrowserEnv() ? window.jzVersion : 0,
                },
            },
            noLazyLoad: false,
            manageMode,
            componentMap: {
                1: VerticalList, // 垂直列表
                2: SlideList, // 横向滑动
                3: CartList, // 卡片层叠
                4: WaterFallList, // 瀑布流
                5: LeftRightList, // 左右图文
                6: HoverList, // 图片遮罩
            },
        };
    },
    computed: {
        ...mapState(['htmlFontSize']),
        ...mapState('app', ['openThemeV3']),
        ...mapGetters(['getModuleById']),
        id() {
            return this.moduleId;
        },
        showPageComponent() {
            let isShowPage = false;
            if (this.type == 1 || this.type == 4 || this.type == 5 || this.type == 6) {
                isShowPage = this.module.content.showPage;
            }
            return isShowPage;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        styleId() {
            return this.module.style;
        },
        options() {
            return this.module.renderOptions;
        },
        type() {
            return this.module.content.type;
        },
        moduleCls() {
            let moduleCls = '';
            if (this.type == 1) {
                moduleCls = 'photoModule styleForm1 verticalList';
            } else if (this.type == 2) {
                moduleCls = 'mPhotoList';
                if (this.module.content?.m?.s) {
                    moduleCls += ' multi_photo_richMarquee';
                }
            } else if (this.type == 3) {
                moduleCls = 'J_photoListCard fk-photoListCard';
            }
            if (!this.picDataList.length) {
                return moduleCls + ' noPhotoContainer';
            }
            return moduleCls;
        },
        moduleIdAttr() {
            if (this.type == 2) {
                return 'mMultiPhotoList' + this.id;
            }
            return '';
        },
        linkType() {
            return this.module.content.link;
        },
        imageViewerList() {
            const { showName, showTitle } = this.module.content;
            let photoSwipeList = this.photoSwipeList;
            if (!showName || !showTitle) {
                photoSwipeList = this.photoSwipeList.map((item) => {
                    const cloneItem = deepClone(item);
                    //隐藏图片名称
                    if (!showTitle) {
                        cloneItem.title = '';
                    }
                    //隐藏图片描述
                    if (!showName) {
                        cloneItem.description = '';
                    }
                    return cloneItem;
                });
            }
            return photoSwipeList;
        },
    },
    watch: {
        'module.content': {
            handler() {
                this.refreshModuleData();
            },
            deep: true,
        },
        'module.photoGroupChange': {
            handler() {
                this.refreshModuleData();
            },
            deep: true,
        },
    },
    updated() {
        this.$nextTick(() => {
            bindLazyLoad(this);
        });
    },
    created() {
        this.initData();
        this.photoSwipeList = this.getPhotoListByType(this.getPhotoListOption(this.photoSwipeList, true));
        this.picDataList = this.getPhotoListByType(this.getPhotoListOption(this.picDataList));
    },
    mounted() {
        this.refreshModuleData(true);
        setTimeout(() => {
            bindEvent(this);
        }, 50);
    },
    methods: {
        changePage(pageno) {
            var self = this;
            var postData = {};
            var type = this.module.content.type;
            postData.apw = this.module.content.apw;
            postData.type = this.module.content.type;
            postData.scale = this.module.content.picScale;
            postData.linkType = this.module.content.link;
            if (this.module.content.apw == 1) {
                postData.groupId = this.module.content.gi;
            } else {
                postData.picList = JSON.stringify(this.module.content.picList);
            }
            if (type == 1 || type == 4 || type == 5 || type == 6) {
                postData.pageSize = this.module.content.count;
                postData.pageno = pageno || this.pageno;
                postData.showPage = this.module.content.showPage;
            }

            getMultiPhotoListData(postData)
                .catch(function () {
                    if (manageMode) {
                        Fai.top.Fai.ing('系统繁忙，请稍候重试', false);
                    } else {
                        Mobi.ing('系统繁忙，请稍候重试', false);
                    }
                })
                .then(function (response) {
                    const { data } = response;

                    if (data.success) {
                        self.photoSwipeList = self.getPhotoListByType(
                            self.getPhotoListOption(data.photoSwipeList, true)
                        );
                        self.$nextTick(() => {
                            self.picDataList = self.getPhotoListByType(self.getPhotoListOption(data.picDataList));
                        });
                        self.render = false;
                        self.$nextTick(() => {
                            self.render = true;
                            self.$nextTick(() => {
                                self.pageno = parseInt(pageno);
                                bindEvent(self);
                            });
                        });
                    } else {
                        if (manageMode) {
                            Fai.top.Fai.ing('系统繁忙，请稍候重试', false);
                        } else {
                            Mobi.ing('系统繁忙，请稍候重试', false);
                        }
                    }
                });
        },
        initData() {
            this.hrefStr2 = this.options.args.hrefStr2;
            this.pageno = this.options.args.pageno;
            this.inTab = this.options.args.inTab;
            this.picDataList = this.options.args.picDataList;
            this.photoSwipeList = this.options.args.photoSwipeList;
            this.totalSize = this.options.args.totalSize;
            this.noLazyLoad = this.options.isFirstScreen;
        },
        refreshModuleData(init) {
            var self = this;
            var addPhotoWay = this.module.content.apw;
            var groupId = this.module.content.gi;
            var type = this.module.content.type;
            var linkType = this.module.content.link;
            var showPage = this.module.content.showPage;
            var showMore = this.module.content.showMore;
            //图片信息
            var picList = this.module.content.picList;
            var showPicList = [];
            var cycleList = [];

            if (addPhotoWay == 0) {
                cycleList = picList;
                change();
            } else if (addPhotoWay == 1) {
                var postData = {
                    groupId,
                };

                getPhotoGroupData(postData)
                    .catch(function () {
                        if (manageMode) {
                            Fai.top.Fai.ing('系统繁忙，请稍候重试', false);
                        }
                    })
                    .then(function (response) {
                        let { data } = response;
                        if (data.success) {
                            cycleList = data.photoList;
                            change();
                        } else {
                            if (manageMode) {
                                Fai.top.Fai.ing('系统繁忙，请稍候重试', false);
                            }
                        }
                    });
            }

            function change() {
                var isShowPageList = false;
                if (type == 1 || type == 4 || type == 5 || type == 6) isShowPageList = true;
                if (type == 1 || type == 4 || type == 5 || type == 6) {
                    // 翻页相关
                    var pageSize = self.module.content.count;
                    var pageno = self.pageno;
                    var start = pageSize * (pageno - 1);
                    var totalSize = cycleList.length;
                    if (start >= totalSize) {
                        self.pageno = 1;
                        self.totalSize = totalSize;
                        self.changePage(self.pageno);
                        return;
                    }
                    self.totalSize = totalSize;
                    if (showPage) {
                        for (var i = start; i < start + pageSize; i++) {
                            if (totalSize > i && cycleList[i]) {
                                showPicList.push(cycleList[i]);
                            }
                        }
                    } else {
                        showPicList = cycleList;
                    }
                }
                var picDataList = [];
                var photoSwipeList = [];
                var dataList = isShowPageList ? showPicList : cycleList;
                photoSwipeList = self.getPhotoListByType({
                    addPhotoWay,
                    photoList: cycleList,
                    groupId,
                    isSwipe: true,
                    linkType,
                });
                if (showMore) {
                    if (self.module.content.moreJumpInfo.t == 103) {
                        if (self.module.content.moreJumpInfo.u) {
                            if (manageMode) {
                                self.options.args.bannerMore =
                                    'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                            }
                        } else {
                            self.options.args.bannerMore = self.hrefStr2;
                        }
                    } else {
                        self.options.args.bannerMore = self.module.content.moreJumpInfo.u;
                    }
                    if ((self.module.content.moreJumpInfo || {}).reqArgs) {
                        self.options.args.reqArgs = self.module.content.moreJumpInfo.reqArgs;
                    } else {
                        self.options.args.reqArgs = '';
                    }
                } else {
                    self.options.args.bannerMore = '';
                }
                self.photoSwipeList = photoSwipeList;
                self.$nextTick(() => {
                    picDataList = self.getPhotoListByType({
                        addPhotoWay,
                        photoList: dataList,
                        linkType,
                    });
                    self.picDataList = picDataList;
                });
                if (init) {
                    return;
                }
                if (manageMode) {
                    self.noLazyLoad = true;
                }
                self.render = false;
                self.$nextTick(() => {
                    self.render = true;
                    self.$nextTick(() => {
                        bindEvent(self);
                    });
                });
            }
        },
        addMultiPhotoList() {
            this.$designer.open({
                panelType: 'pattern multi-photo-list basis',
                moduleId: this.moduleId,
                styleId: this.styleId,
            });
        },
        getPhotoListByType(options) {
            const { addPhotoWay = 0, photoList = [], isSwipe = false, groupId = 0, linkType } = options;
            let list = [];
            let self = this;
            photoList.forEach((pinfo, index) => {
                let picId = '';
                let imgName = '';
                let jumpUrl = '';
                let description = '';
                let openLink = false;
                let jumpInfo = {};
                let skipMobiID = 0;
                let picLinkType = 0;
                let title = ''; // 新增图片标题

                if (addPhotoWay == 0) {
                    picId = pinfo.id;
                    imgName = pinfo.desc;
                    description = pinfo.desc;
                    title = encodeHtml(pinfo.title || ''); // 直接添加的图片名称
                    openLink = pinfo.openLink;
                    picLinkType = pinfo.linkType || 0;
                    jumpInfo = pinfo.jumpInfo;
                    pinfo.name = imgName;
                    pinfo.mobiDetail = 0;
                    pinfo.detail = pinfo.linkType == 1;
                } else if (addPhotoWay == 1) {
                    jumpInfo = pinfo.jumpInfo;
                    openLink = pinfo.linkType == 2 && linkType != 1 ? true : false;
                    picLinkType = pinfo.linkType || 0;
                    picId = pinfo.pic;
                    imgName = pinfo.basic;
                    description = pinfo.basic;
                    skipMobiID = pinfo.skipMobiColId || 0;
                    pinfo.detail = pinfo.linkType == 1;
                    title = encodeHtml(pinfo.name || ''); // 直接绑定图册的名称
                }
                if (picLinkType == 1 && isSwipe) {
                    pinfo.mobiDetail = 1;
                }

                let picThumbPath = pinfo.picThumbPath;
                if (jumpInfo && self.linkType == 2 && (picLinkType == 2 || picLinkType == 0)) {
                    jumpUrl = jumpInfo.u;
                    let jumpType = jumpInfo.t;
                    //mobi-將siteCol的id替換成mobiCol的id
                    if (skipMobiID != 0 && jumpType == 100) {
                        let jumpInfoColId = jumpInfo.i || 0;
                        jumpUrl = jumpUrl.replace(jumpInfoColId, skipMobiID);
                    }
                    if (jumpType == 103 && jumpUrl && manageMode) {
                        jumpUrl = 'javascript:top.Fai.ing("管理态不支持跳转外部链接，请通过“预览”进行访问", true);';
                    }
                }

                if (isSwipe) {
                    // 拼装幻灯片图片参数
                    list.push({
                        width: pinfo.width || 0,
                        height: pinfo.height || 0,
                        id: picId,
                        src: picThumbPath,
                        title,
                        index,
                        desc: imgName,
                        description,
                        detail: pinfo.detail,
                        pid: pinfo.id || 0,
                        groupId,
                        openLink,
                        detailContent: pinfo.detailContent || '',
                    });
                } else {
                    const currentPicList = self.photoSwipeList.filter(
                            (value) => value.id === picId && value.src === picThumbPath
                        ),
                        _picIndex = currentPicList.length && currentPicList[0].index;
                    list.push({
                        picId,
                        index: _picIndex,
                        jumpUrl,
                        picThumbPath,
                        imgName,
                        faiWidth: pinfo.width || 640,
                        faiHeight: pinfo.height || 640,
                        imgTitle: title,
                        nf: pinfo.nf,
                        reqArgs: (pinfo.jumpInfo || {}).reqArgs || '',
                        detailContent: pinfo.detailContent || '',
                    });
                }
            });

            return list;
        },
        getPhotoListOption(photoList = [], isSwipe = false) {
            return {
                addPhotoWay: this.module.content.apw,
                photoList,
                groupId: this.module.content.gi,
                linkType: this.module.content.link,
                isSwipe,
                isManageMode: VITE_APP_MODE !== 'visitor',
            };
        },
    },
};
</script>

<style lang="scss">
.noPhotoContainer .empty_tips_panel {
    text-align: center;
    padding-top: 1.7rem;
    padding-bottom: 2.4rem;
}

.noPhotoContainer .empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}
.noPhotoContainer {
    margin: 0 !important;
    padding: 0 !important;
}
.noPhotoContainer .cus_Empty_tips_panel {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noPhotoContainer .cus_Empty_tips_panel .text {
    color: #666666;
    font-size: 14px;
}

// 样式1
.multi_photo_list.photoModule {
    margin-top: 0.74rem;
}

.multi_photo_list.photoModule .photoLDiv {
    margin-left: 3.25%;
    margin-right: 1.75%;
}

.multi_photo_list.photoModule .photoRDiv {
    margin-left: 1.75%;
    margin-right: 3.25%;
}

.multi_photo_list.photoModule.verticalList .photoDiv .imgName {
    font-size: 0.6rem;
    line-height: 0.9rem;
    margin-top: 0.39rem;
}

.multi_photo_list.photoModule .photoDiv {
    margin-bottom: 0.61rem;
}

// 样式2
.multi_photo_list.mPhotoList {
    margin-top: 0.74rem;
}

.multi_photo_list.mPhotoList .photoSlideList .photoCrossedSlideSec .imgName {
    padding: 0.52rem 0.25rem;
    color: #333;
}

.multi_photo_list.mPhotoList .photoCrossedSlideSec {
    border-color: #eee;
}
.multi_photo_list.mPhotoList:not(.multi_photo_richMarquee) .newPhotoCrossedSlideSec:last-child {
    margin-right: 0.5rem;
}

// 样式3
.multi_photo_list .fk-photoListCard .f-photoDesc {
    color: #333;
}

.multi_photo_list .title {
    margin-top: 0.55rem;
    color: #222222;
    font-weight: bold;
    padding-left: 0.4rem;
    padding-right: 0.65rem;
}

.multi_photo_list .desc {
    margin-top: 0.55rem;
    padding-left: 0.4rem;
    padding-right: 0.65rem;
    color: #222222;
}

.multi_photo_list .wrapText {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

/*********************  列表多图卡片样式 ****************************/
.fk-photoListCard {
    font-size: 0.65rem;
    color: #666;
}
.fk-photoListCard .f-cardContainer {
    width: 13.55rem;
    height: 15.25rem;
    padding: 0;
    margin: 1.25rem;
    position: relative;
    list-style: none;
    text-align: center;
    perspective: 1000px;
    perspective-origin: 50% -50%;
}
.fk-photoListCard .f-cardContainer > li {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;
    overflow: hidden;
    transition: transform 0.25s;
}
.fk-photoListCard .f-cardContainer img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    top: 50%;
    left: 50%;
    vertical-align: middle;
    pointer-events: none;
} /*使点击事件冒泡到父元素里处理，解决滑动时触发点击事件bug*/
.fk-photoListCard .f-imgContainer {
    width: 13.5rem;
    height: 11.25rem;
    position: relative;
    overflow: hidden;
}
.fk-photoListCard .f-imgContainer span.f-imageMiddle {
    display: none;
}
.fk-photoListCard ul.f-cardContainer a {
    color: #666;
}
/*max-height = 1.3 * fontSize * rows 两行的高度。*/
.fk-photoListCard .f-photoDesc {
    max-width: 12rem;
    max-height: 1.7rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin: 0.75rem;
    text-align: center;
}
.fk-photoListCard div.f-photoDescNoWrap {
    white-space: nowrap;
    word-break: break-all;
}
.fk-photoListCard .f-showPic_0 {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}
.fk-photoListCard .f-showPic_0 .f-imgContainer {
    display: none;
}
.fk-photoListCard .f-showPic_1 .f-imgContainer,
.fk-photoListCard .f-showPic_2 .f-imgContainer,
.fk-photoListCard .f-showPic_3 .f-imgContainer {
    display: block;
}
.fk-photoListCard .f-showPic_1 {
    z-index: 3;
    opacity: 1;
    // transform: translate3d(0, 0, 0);
}
.fk-photoListCard .f-showPic_2 {
    z-index: 2;
    opacity: 1;
    pointer-events: none;
    transform: translate3d(0, 1.25rem, -40px);
}
.fk-photoListCard .f-showPic_3 {
    z-index: 1;
    opacity: 1;
    pointer-events: none;
    transform: translate3d(0, 2.5rem, -80px);
}

.fk-photoListCard .f-cardAnimation_right {
    z-index: 4;
    animation: krisnaAccept 0.5s forwards;
}

@keyframes krisnaAccept {
    to {
        transform: translate3d(100vw, 0, 0) rotate3d(0, 0, 1, 5deg);
    }
}

.fk-photoListCard .f-cardAnimation_left {
    z-index: 4;
    animation: krisnaAccept_left 0.5s forwards;
}

@keyframes krisnaAccept_left {
    to {
        transform: translate3d(-100vw, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
}

/*********************  列表多图卡片样式 end ****************************/
</style>
