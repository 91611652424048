import { mapFlag } from '@/shared/flag';
export default {
    computed: {
        ...mapFlag({
            isNewAddModule: 0x4,
        }),
        type: function () {
            return this.module.content.t;
        },
        colmumType: function () {
            return this.module.content.ct;
        },
        _isBigCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 1;
            } else {
                return this.module.content.t == '33';
            }
        },
        _isTwoCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 2;
            } else {
                return this.module.content.t == '1';
            }
        },
        _isThreeCol: function () {
            if (this.isNewAddModule) {
                return this.type == '1' && this.colmumType == 3;
            } else {
                return this.module.content.t == '31';
            }
        },
        _listClass() {
            var tmpType = this.module.content.t;
            if (this.isNewAddModule) {
                if (this._isBigCol) {
                    tmpType = '33';
                } else if (this._isThreeCol) {
                    tmpType = '31';
                }
            }
            return `fk-mProductList${tmpType}`;
        },
        _listId() {
            return `mProductList${this.module.id}`;
        },
    },
};
