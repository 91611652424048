<template>
    <div class="photoImageItem photoSwipeItem J_also_resize" :data-picId="item.picId || item.pic">
        <a
            v-if="outerBox"
            :href="imgHref"
            :rel="imgNofollow"
            class="photoImageItemContent"
            :style="cardItemContentStyle"
            :class="cardItemCls"
            hidefocus="true"
            :onclick="jumpOnclickStr"
            @click="photoSlidesshow"
        >
            <div v-if="textPos == 1 && !hideDescBox" class="imgDescBox" :class="imgDescBoxCls">
                <div
                    v-if="itemNameShow"
                    class="imgName g_carouselPhoto_nameLink"
                    :class="imgNameCls"
                    :style="imgNameStyle"
                    v-html="itemName"
                ></div>
                <div
                    v-if="itemDescShow"
                    class="imgDesc g_carouselPhoto_link"
                    :class="imgDescCls"
                    :style="imgDescStyle"
                    v-html="itemDesc"
                ></div>
            </div>
            <div
                v-drag-resize="resizeOptions"
                class="imgLink J_img_lazyload"
                :src-original="item.picPath"
                :style="imgCardStyle"
                :class="imgCls"
            ></div>
            <div v-if="textPos == 0 && !hideDescBox" class="imgDescBox" :class="imgDescBoxCls">
                <div
                    v-if="itemNameShow"
                    class="imgName g_carouselPhoto_nameLink"
                    :class="imgNameCls"
                    :style="imgNameStyle"
                    v-html="itemName"
                ></div>
                <div
                    v-if="itemDescShow"
                    class="imgDesc g_carouselPhoto_link"
                    :class="imgDescCls"
                    :style="imgDescStyle"
                    v-html="itemDesc"
                ></div>
            </div>
        </a>
        <div v-else class="photoImageItemContent" :style="itemContentStyle" :class="itemCls">
            <a
                v-drag-resize="resizeOptions"
                :href="imgHref"
                :rel="imgNofollow"
                class="imgLink J_img_lazyload"
                :src-original="item.picPath"
                :style="imgStyle"
                hidefocus="true"
                :class="imgCls"
                :onclick="jumpOnclickStr"
                @click="photoSlidesshow"
            >
                <div class="imgCover" :style="imgCover" :class="imgCoverCls">
                    <div v-if="itemNameShow || itemDescShow" class="imgDescBox" :class="imgDescBoxCls">
                        <div
                            v-if="itemNameShow"
                            class="imgName g_carouselPhoto_nameLink"
                            :style="imgNameStyle"
                            v-html="itemName"
                        ></div>
                        <div
                            v-if="itemDescShow"
                            class="imgDesc g_carouselPhoto_link"
                            :style="imgDescStyle"
                            v-html="itemDesc"
                        ></div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { createUrlArgsOnclickStr } from '../../../comm';

import { encodeHtml } from '@/shared/util';
import { mapGetters } from 'vuex';
export default {
    name: 'NewCarouselImageItem',
    inject: ['module', 'options'],
    props: {
        item: {
            type: Object,
            default: () => {
                return {};
            },
        },
        itemIndex: { type: Number, default: 0 },
        outerBox: {
            type: Boolean,
            default: false,
        },
        textPos: {
            type: Number,
            default: 0,
        },
        autoHideText: {
            type: Boolean,
            default: false,
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: this.module.id,
            styleId: this.module.style,
            oldIndex: 0,
            moduleWidth: 0,
        };
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        content() {
            return this.module.content;
        },
        mhighs() {
            return this.content.mhighs;
        },
        picScale() {
            return this.content.picScale;
        },
        imgHref() {
            if (this.content.link == 0) {
                return this.item.hrefStr2;
            } else if (
                this.content.link == 2 &&
                this.content.apw == 1 &&
                this.item.linkType == 2 &&
                this.item.jumpInfo.u != ''
            ) {
                return this.item.jumpInfo.u;
            } else if (this.content.link === 2 && this.item.jumpInfo.u != '') {
                return this.item.jumpInfo.u;
            } else {
                return undefined;
            }
        },
        jumpOnclickStr() {
            return createUrlArgsOnclickStr(
                this.item.jumpInfo.reqArgs,
                this.module._openRemoveUrlArgs,
                this.hashRemoved
            );
        },
        imgNofollow() {
            if (this.item.nf) {
                return 'nofollow';
            }
            return '';
        },
        imgNameStyle() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var imgNameFont = this.module.content.mhighs.inf;
            if (imgNameFont.type == 1) {
                if (imgNameFont.lfs) {
                    style += 'font-size:' + imgNameFont.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (imgNameFont.lfct == 1) {
                    style += 'color:' + imgNameFont.lfc + ';';
                }
                if (imgNameFont.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        imgDescStyle() {
            var style = '';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            var imgDescFont = this.module.content.mhighs.idf;
            if (imgDescFont.type == 1) {
                if (imgDescFont.lfs) {
                    style += 'font-size:' + imgDescFont.lfs + 'rem;';
                } else {
                    style += 'font-size:12px;';
                }
                if (imgDescFont.lfct == 1) {
                    style += 'color:' + imgDescFont.lfc + ';';
                }
                if (imgDescFont.lfd == 1) {
                    style += 'text-decoration:underline;';
                } else {
                    style += 'text-decoration:none;';
                }
            }
            return style;
        },
        imgCoverCls() {
            if (this.content.mhighs.imgCoverSet.t == 2) {
                return '';
            } else if (this.content.mhighs.imgCoverSet.t == 1) {
                return 'imgCoverHide';
            } else {
                return 'imgCoverAuto';
            }
        },
        imgCover() {
            if (!(VITE_APP_MODE !== 'visitor')) {
                return '';
            }
            if (this.content.mhighs.imgCoverSet.t == 2) {
                return {
                    'background-color': this.content.mhighs.imgCoverSet.c,
                    height: this.content.mhighs.imgCoverSet.h != 0 ? this.content.mhighs.imgCoverSet.h + 'rem' : '40px',
                };
            } else {
                return '';
            }
        },
        imgStyle() {
            var style = {},
                item = this.item;
            style['background-image'] = 'url(' + (this.noLazyLoad ? item.picPath : this.options.loadingPath) + ')';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }

            if (this.mhighs.imgHeightSet.t == 1) {
                style['height'] = this.mhighs.imgHeightSet.h > 0 ? this.mhighs.imgHeightSet.h + 'rem' : '200px';
            }

            return style;
        },
        imgCardStyle() {
            var style = {},
                item = this.item;
            style['background-image'] = 'url(' + (this.noLazyLoad ? item.picPath : this.options.loadingPath) + ')';
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            if (this.mhighs.imgHeightSet.t == 1) {
                style['height'] = this.mhighs.imgHeightSet.h > 0 ? this.mhighs.imgHeightSet.h + 'rem' : '200px';
            }

            if (this.mhighs.imgRaidusSet.t == 1 && this.module.content.its == 3) {
                style['border-radius'] = this.mhighs.imgRaidusSet.r + 'rem';
            }

            return style;
        },
        imgCls() {
            return 'picScale' + (this.picScale + 1);
        },
        itemContentStyle() {
            var style = {};
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            if (this.mhighs.imgRaidusSet.t == 1) {
                style['border-radius'] = this.mhighs.imgRaidusSet.r + 'rem';
            }
            return style;
        },
        itemCls() {
            var cls = '';
            if (this.content.type != 8) {
                switch (this.mhighs.fontAlign) {
                    case 1:
                        cls = 'textCenter';
                        break;
                    case 2:
                        cls = 'textRight';
                        break;
                    default:
                        cls = 'textLeft';
                }
            }
            return cls;
        },
        cardItemContentStyle() {
            var style = {};
            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }
            if (this.mhighs.imgRaidusSet.t == 1 && this.module.content.its != 3) {
                style['border-radius'] = this.mhighs.imgRaidusSet.r + 'rem';
            }
            //区域背景
            if (this.content.its != 3) {
                if (this.mhighs.areaBg.t) {
                    style['background-color'] = this.mhighs.areaBg.c;
                }
            }
            return style;
        },
        cardItemCls() {
            var cls = '';
            switch (this.mhighs.fontAlign) {
                case 1:
                    cls = 'textCenter';
                    break;
                case 2:
                    cls = 'textRight';
                    break;
                default:
                    cls = 'textLeft';
            }
            return cls;
        },
        itemName() {
            return this.encodeHtml(this.item.name);
        },
        itemDesc() {
            return this.encodeHtml(this.item.desc);
        },
        itemNameShow() {
            return this.content.sin && this.itemName != '';
        },
        itemDescShow() {
            return this.content.showName && this.itemDesc != '';
        },
        hideDescBox() {
            //是否自动隐藏盒子，没有描述和名称的情况
            if (this.autoHideText && !this.itemNameShow && !this.itemDescShow) {
                return true;
            }
            //设置了都设置了隐藏，需要隐藏盒子
            else if (!this.content.sin && !this.content.showName) {
                return true;
            } else {
                return false;
            }
        },
        imgNameCls() {
            if (this.mhighs.sinw == 1) {
                return 'nowrap';
            } else if (this.mhighs.sinw == 2) {
                return 'wrap';
            } else {
                return 'default';
            }
        },
        imgDescCls() {
            if (this.mhighs.sidw == 1) {
                return 'nowrap';
            } else if (this.mhighs.sidw == 2) {
                return 'wrap';
            } else {
                return 'default';
            }
        },
        imgDescBoxCls() {
            var cls = '';
            if (!this.content.sin) {
                cls += ' noName';
            }

            if (!this.content.showName) {
                cls += ' noDesc';
            }
            return cls;
        },
        resizeOptions() {
            var handles = 's';
            var maxWidth = 323; // 323 中间手机宽度
            var minWidth = null;
            var notAllow = false;
            var alsoResizeParentSelector = null;
            var alsoResizeChildSelector = null;
            var moduleStyle = this.module.content.type;

            if (this.module.content.type == 8) {
                alsoResizeParentSelector = !this.outerBox ? '.J_photoSwipe' : '.J_also_resize';
            } else if ([9, 10, 11].includes(this.module.content.type)) {
                alsoResizeParentSelector = '.photoSwipe';
            }

            if ([9, 10, 11].includes(moduleStyle)) {
                handles = 's,e';
            }

            if (moduleStyle == 9) {
                minWidth = 160;
            } else if (moduleStyle == 10 || moduleStyle == 11) {
                minWidth = 173;
            }

            return {
                notAllow: notAllow,
                handles: handles,
                alsoResizeParentSelector: alsoResizeParentSelector,
                alsoResizeChildSelector: alsoResizeChildSelector,
                fixPosition: true,
                outlineInBody: true,
                maxWidth: maxWidth,
                minWidth: minWidth,
                beforeStart: this._resizeBeforeStart,
                stop: this._resizeStop,
                resize: this._resizeing,
            };
        },
    },
    mounted: function () {
        if (VITE_APP_MODE !== 'visitor') {
            let moduleContent = $(`#module${this.module.id} .carouselMultiPhoto`)[0];
            if (moduleContent) {
                this.moduleWidth = moduleContent.offsetWidth;
            }
        }
    },
    methods: {
        photoSlidesshow() {
            if (this.itemIndex == -1) return;
            this.$modulesEventBus.$emit(`photo-slidesshow${this.id}`, this.itemIndex, this.item);
        },
        encodeHtml(html) {
            return encodeHtml(html);
        },
        _resizeBeforeStart() {
            const carouselType = this.module.content.mhighs.carouselSet.carouselType;
            if (carouselType == 1 || carouselType == 0) {
                const styleCom = vue_utils.findComponentsUpward(this, `CarouselType${this.module.content.type}`)[0];
                !!styleCom && styleCom.cleanAutoPlay();
            }
        },
        _resizeStop(event, ui) {
            const { width, height } = { ...ui.size };
            const moduleStyle = this.module.content.type;
            if (ui.originalSize.width != width) {
                this.mhighs.imgWidthSet.t = 1;
                if ([9, 10, 11].includes(moduleStyle)) {
                    const wrapSelect = moduleStyle == 9 ? '.J_also_resize' : '.drag_also_resize_wrap';
                    const wrap = ui.originalElement.closest(wrapSelect)[0];
                    if (wrap) {
                        // 相对百分比
                        this.mhighs.imgWidthSet.wp = Math.round((wrap.offsetWidth / this.moduleWidth) * 100);
                    }
                } else {
                    this.mhighs.imgWidthSet.wp = Math.round((width / this.moduleWidth) * 100);
                }
            } else {
                this.mhighs.imgHeightSet.h = height / window._htmlFontSize;
                this.mhighs.imgHeightSet.t = 1;
            }
            this.$designer.onContentChange(this.module.id);

            const carouselType = this.module.content.mhighs.carouselSet.carouselType;
            if (carouselType == 1 || carouselType == 0) {
                const styleCom = vue_utils.findComponentsUpward(this, `CarouselType${this.module.content.type}`)[0];
                !!styleCom && styleCom.setAutoplay();
            }
            Fai.top.Mobi.logDog(201218, 4);
        },
        _resizeing(event, ui) {
            if (this.module.content.type == 8 && this.outerBox) {
                ui.originalElement
                    .closest('.J_photoSwipe')
                    .css('height', ui.originalElement.closest('.J_also_resize').css('height'));
            }
        },
    },
};
</script>
<style lang="scss">
.formStyle42 .newCarouselMultiPhoto .photoImageItem {
    display: inline-block;
    border-radius: 0.2rem;
    position: relative;
    width: 100%;
    vertical-align: top;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .photoImageItemContent {
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgLink {
    height: 7.75rem;
    width: 100%;
    display: block;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgLink.picScale1 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgLink.picScale2 {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgLink.picScale3 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgLink.picScale4 {
    background-size: unset;
    background-position: center;
    background-repeat: no-repeat;
}

.formStyle42 .newCarouselMultiPhoto.outerBox2 .photoImageItem .imgLink {
    width: auto;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem img {
    vertical-align: middle;
    width: auto;
    height: auto;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgCover {
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgCover.imgCoverHide {
    background-color: unset !important;
}
.formStyle42 .newCarouselMultiPhoto.innerBox .photoImageItem .imgDescBox {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0.5rem 0.5rem 0.4rem 0.6rem;
    box-sizing: border-box;
    bottom: 0;
}
.formStyle42 .newCarouselMultiPhoto.innerBox .photoImageItem .imgCoverAuto .imgDescBox {
    position: relative;
    height: auto;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgName {
    font-size: 0.65rem;
    color: #fff;
    text-align: inherit;
    font-weight: bold;
}
.formStyle42 .newCarouselMultiPhoto .photoImageItem .imgDesc {
    font-size: 0.5rem;
    margin: 0;
    color: #fff;
    margin-top: 0.1rem;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}
.formStyle42 .newCarouselMultiPhoto.innerBox .photoImageItem .imgDesc,
.formStyle42 .newCarouselMultiPhoto.innerBox .photoImageItem .imgName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.formStyle42 .newCarouselMultiPhoto.outerBox1 .photoImageItem .imgDescBox {
    padding: 0.4rem 0.5rem 0.65rem 0.5rem;
    width: 100%;
    min-height: 3.4rem;
    box-sizing: border-box;
    flex: 1;
}
.formStyle42 .newCarouselMultiPhoto.outerBox2 .photoImageItem .imgDescBox {
    padding: 0.65rem 0.5rem 0.4rem 0.5rem;
}
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgDescBox.noName,
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgDescBox.noDesc {
    min-height: unset;
}
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgName.default {
    color: #222222;
    white-space: unset;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
}
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgDesc.default {
    color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-break: break-all;
}
.formStyle42 .outerBox .photoImageItem .photoImageItemContent {
    background-color: #fff;
    text-align: left;
    width: auto;
    display: flex;
    flex-direction: column;
    vertical-align: top;
    border-radius: 0.2rem;
    height: 100%;
}
.formStyle42 .outerBox2 .photoImageItem .photoImageItemContent {
    justify-content: space-between;
}
.formStyle42 .photoImageItem .photoImageItemContent.textCenter {
    text-align: center;
}
.formStyle42 .photoImageItem .photoImageItemContent.textLeft {
    text-align: left;
}
.formStyle42 .photoImageItem .photoImageItemContent.textRight {
    text-align: right;
}
.formStyle42 .outerBox.imgTextType1 .photoImageItem .photoImageItemContent {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 12px 0px;
}
.formStyle42 .outerBox.imgTextType2 .photoImageItem .photoImageItemContent {
    box-sizing: border-box;
    border: 1px solid rgba(238, 238, 238, 1);
}
.formStyle42 .outerBox.imgTextType3 .photoImageItem .photoImageItemContent {
    background-color: rgba(246, 246, 246, 1);
}
.formStyle42 .outerBox.imgTextType4 .photoImageItem .photoImageItemContent .imgLink {
    border-radius: 0.2rem;
}
.formStyle42 .outerBox.imgTextType4 .photoImageItem .photoImageItemContent {
    background-color: unset !important;
    border-radius: 0;
}
.formStyle42 .newCarouselMultiPhoto.outerBox1.imgTextType4 .photoImageItem .imgDescBox {
    padding: 0.4rem 0 0.25rem 0;
    min-height: 3rem;
}
.formStyle42 .newCarouselMultiPhoto.outerBox2.imgTextType4 .photoImageItem .imgDescBox {
    padding: 0.65rem 0 0.4rem 0;
}

.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgName.nowrap {
    color: #222222;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
}
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgDesc.wrap {
    color: #666666;
    overflow: hidden;
    word-break: break-all;
    white-space: normal;
}

.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgName.wrap {
    color: #222222;
    word-break: break-all;
    overflow: hidden;
    white-space: normal;
}
.formStyle42 .newCarouselMultiPhoto.outerBox .photoImageItem .imgDesc.nowrap {
    color: #666666;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
}
</style>
