<template>
    <module-frame :module-id="moduleId">
        <div class="J_mobi_vote mobi_vote" :class="moduleWrapClass">
            <template v-if="module.extInfo.notAllowPrint">{{ module.extInfo.noticeWord }}</template>
            <template v-else-if="!voteNotFound">
                <div class="vote_items_wrap">
                    <div
                        v-for="item in voteInfo.itemList"
                        :key="'vote_' + item.id"
                        class="J_item_wrap item_wrap"
                        @click.stop="editItem($event, item)"
                    >
                        <vote-item :vote-item="item">
                            <component
                                :is="componentMap[item.i_type]"
                                :vote-item="item"
                                :vote-info="voteInfo"
                            ></component>
                        </vote-item>
                    </div>
                </div>
                <div class="vote_code_panel">
                    <template v-if="openValidateCode">
                        <div class="code_title">{{ module.extInfo.verificationCodeStr }}</div>
                        <div class="code_content">
                            <input
                                v-model="voteCode"
                                class="g_itext code_input"
                                type="text"
                                :placeholder="module.extInfo.enterVerificationCode"
                            />
                            <img
                                id="memberCaptchaImg"
                                class="code_img"
                                :src="codeSrc"
                                title="看不清，换一张"
                                @click="refreshCode"
                            />
                            <span class="code_refresh_btn" @click="refreshCode"></span>
                            <span class="star">*</span>
                        </div>
                    </template>
                    <template v-else-if="showCodeContent2">
                        <input
                            v-model="voteCode2"
                            class="g_itext code_input"
                            type="text"
                            :style="voteCode2Style"
                            :placeholder="module.extInfo.enterVerificationCode"
                        />
                        <img
                            id="memberCaptchaImg"
                            class="code_img"
                            :src="codeSrc2"
                            title="看不清，换一张"
                            @click="refreshCode2"
                        />
                    </template>
                </div>
                <div class="voteOperate">
                    <div class="g_main_bgColor_v3 g_button btn" @click.stop="submit">{{ module.extInfo.voteStr }}</div>
                    <div v-if="resultPublic" class="g_main_bgColor_v3 g_button btn viewBtn" @click.stop="viewResult">{{
                        module.extInfo.viewResultStr
                    }}</div>
                </div>
            </template>
            <template v-else-if="voteNotFound">
                <div class="noVote">
                    {{ module.extInfo.voteNotFound }}
                </div>
            </template>
        </div>
    </module-frame>
</template>

<script>
import ModuleFrame from '@/modules/frame/index.vue';
import VoteItem from './components/vote-item.vue';
import VoteRadio from './components/vote-radio.vue';
import VoteCheckbox from './components/vote-checkbox.vue';
import { mapFlag } from '@/shared/flag';

import { mapActions, mapGetters, mapState } from 'vuex';
import { addVoteItem, getVoteResult } from '@/api/vote';

export default {
    name: 'Vote',
    components: {
        ModuleFrame,
        VoteItem,
        VoteRadio,
        VoteCheckbox,
    },
    provide() {
        return {
            module: this.module,
            options: this.options,
        };
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            componentMap: {
                radio: VoteRadio, // 单选按钮
                checkbox: VoteCheckbox, // 多选按钮
            },
            voteItems: [],
            voteCode: '',
            submitting: false,
            showCodeContent2: false, // 防刷票的验证码
            voteCode2: '',
            codeSrc: '',
            codeSrc2: `/validateCode.jsp?${Math.random() * 1000}&validateCodeRegType=3`,
            showCode2Style: false,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        ...mapState('manage/vote', ['voteList', 'curVoteItemEdit']),
        id() {
            return this.moduleId;
        },
        styleId() {
            return this.module.style;
        },
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        ...mapFlag(
            {
                resultPublic: 0x2,
                noRepeatVote: 0x4,
                openValidateCode: 0x20, // 开启验证码
            },
            'voteInfo.flag'
        ),
        voteInfo() {
            let voteId = this.module.content.voteId;
            if (VITE_APP_MODE !== 'visitor') {
                const voteList = this.voteList;
                const vote = voteList.find((vote) => vote.id === voteId);
                if (vote) {
                    return JSON.parse(JSON.stringify(vote));
                }
            }
            return this.module.extInfo.voteInfo;
        },
        voteCode2Style() {
            return this.showCode2Style ? { borderColor: 'ff0000' } : { borderColor: 'ff0000' };
        },
        moduleWrapClass() {
            return VITE_APP_MODE !== 'visitor' ? 'manageMode' : '';
        },
        voteItemEmpty() {
            return this.voteInfo.itemList.length < 1;
        },
        voteNotFound() {
            return this.isEmptyObject(this.voteInfo);
        },
    },
    watch: {
        'module.content.voteId': {
            handler() {
                this.codeSrc = `/validateCode.jsp?${Math.random() * 1000}&vCodeId=${this.moduleId}${
                    this.module.content.voteId
                }`;
            },
            immediate: true,
        },
    },
    created() {
        this.$on('on-vote-item-add', (item) => {
            if (item) this.voteItems.push(item);
        });

        if (VITE_APP_MODE !== 'visitor' && !this.isEmptyObject(this.module.extInfo.voteInfo)) {
            this.addVote(this.module.extInfo.voteInfo);
        }
    },
    mounted() {
        if (!(VITE_APP_MODE !== 'visitor')) {
            const delay = this.voteInfo.delayTime;
            const voteId = this.module.content.voteId;
            const voteCookieId = `vote${voteId}`;
            const voteCookieValue = jm.getCookie(voteCookieId);
            if (delay == 0) {
                // 允许重复投
                jm.cookie(voteCookieId, null, { path: '/' });
            } else if (delay == -1) {
                // 不允许重复投
                if (voteCookieValue === 'null') {
                    jm.cookie(voteCookieId, -1, { expires: 30, path: '/' });
                }
            } else {
                // 允许重复投, 限制时间
                if (voteCookieValue === '-1') {
                    jm.cookie(voteCookieId, null, { path: '/' });
                }

                if (voteCookieValue !== '' && parseInt(voteCookieValue) != delay) {
                    var d = new Date();
                    d.setHours(d.getHours() + delay);
                    jm.cookie(voteCookieValue, delay, {
                        expires: d,
                        path: '/',
                    });
                }
            }
        }
    },
    methods: {
        ...mapActions('manage/vote', ['addVote']),
        editItem(evt, item) {
            if (Fai.top._onlyHasBannerEditAuth) {
                return;
            }
            if (VITE_APP_MODE !== 'visitor') {
                Object.assign(this.curVoteItemEdit, {
                    voteId: this.voteInfo.id,
                    itemId: item.id,
                    moduleId: this.id,
                });

                this.$designer.open({
                    panelType: 'JzVoteEditItem',
                });
                jm('.J_mobi_vote .item_editing').removeClass('item_editing');
                jm(evt.currentTarget).addClass('item_editing');
            }
        },
        async submit() {
            if (VITE_APP_MODE !== 'visitor') {
                this.$mobiIng('管理态不支持提交投票。');
                return;
            }

            if (this.module.extInfo.isJuly3FreeUser) {
                this.$logDog(200964, 7);
                this.$mobiIng('免费版暂不支持此功能');
                return;
            }

            if (this.submitting) {
                // 正在提交
                return;
            }

            if (!this.validateSuccess()) {
                // 验证失败
                return;
            }

            if (this.openValidateCode) {
                // 没输入验证码
                if (this.voteCode === '') {
                    this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, this.module.extInfo.enterVerificationCode));
                    return;
                }
            }

            let submitInfoList = [];
            this.voteItems.forEach((content) => {
                submitInfoList.push({
                    itemList: content.submitVal,
                });
            });

            this.$mobiIng(LS.voteBeing, -1);
            this.submitting = true;
            this.refreshCode();

            let This = this;
            const delay = this.voteInfo.delayTime;
            const voteId = this.module.content.voteId;
            const voteCookieId = `vote${voteId}`;
            const voteCookieValue = jm.getCookie(voteCookieId);

            let param = [];
            param.push('cmd=addWafCk_voteItem');
            param.push(`&vid=${this.module.content.voteId}`);
            param.push(`&openValidate=${this.openValidateCode}`);
            param.push(`&validateCode=${this.voteCode}`);
            param.push(`&verificationCode=${this.voteCode2}`);
            param.push(`&vCodeId=${this.module.id}${this.module.content.voteId}`);
            param.push(`&list=${jm.encodeUrl(jm.toJSON(submitInfoList))}`);

            try {
                const result = await addVoteItem(param.join(''));
                if (result.success) {
                    this.$mobiIng(LS.voteSuccess, 1);
                    This.voteItems.forEach((item) => item.resetValue && item.resetValue());
                    This.voteCode = '';
                    if (delay > 0) {
                        if (voteCookieValue === '' || voteCookieValue === 'null') {
                            var d = new Date();
                            d.setHours(d.getHours() + delay);
                            jm.cookie(voteCookieId, delay, {
                                expires: d,
                                path: '/',
                            });
                        }
                    } else if (delay == 0) {
                        jm.cookie(voteCookieId, null, { path: '/' });
                    } else {
                        jm.cookie(voteCookieId, -1, { expires: 30, path: '/' });
                    }
                } else {
                    if (result.login) {
                        this.$mobiIng(LS.voteErrorByNotLogin);
                    } else if (result.validateCodeErr) {
                        this.$mobiIng(result.msg);
                        This.voteCode = '';
                        This.refreshCode();
                    } else {
                        this.$mobiIng(result.msg);
                        if (result.needCode) {
                            This.showCodeContent2 = true;
                        } else if (result.codeErr) {
                            This.showCode2Style = true;
                        }
                    }
                }
                setTimeout(() => {
                    This.submitting = false;
                }, 3000);
            } catch (err) {
                this.$mobiIng(LS.voteError);
                setTimeout(() => {
                    this.submitting = false;
                }, 3000);
            }
        },
        validateSuccess() {
            return !this.voteItems.find((item) => item.validate && !item.validate());
        },
        refreshCode() {
            this.codeSrc = `/validateCode.jsp?${Math.random() * 1000}&vCodeId=${this.module.id}${
                this.module.content.voteId
            }`;
        },
        refreshCode2() {
            this.codeSrc2 = `/validateCode.jsp?${Math.random() * 1000}&validateCodeRegType=3`;
        },
        viewResult() {
            if (VITE_APP_MODE !== 'visitor' && this.$designer.checkSaveBar()) {
                return;
            }

            if (this.module.extInfo.isJuly3FreeUser) {
                this.$mobiIng('免费版暂不支持此功能');
                return;
            }

            if (this.loading) {
                return;
            } else {
                jm('#voteResult').addClass('showFullScreen');
                if (jm('#voteResult').length > 0) {
                    jm('#voteResult').remove();
                }
                this.loading = true;
                jm('#fullScreenDivCotainer').append(
                    "<div id='voteResult'  class=' webBackground webContainerBox voteResultDiv moduleContent fk-body-bg' style='overflow-y:auto; position: fixed !important;'></div>"
                );

                getVoteResult(this.module.content.voteId)
                    .then(({ data }) => {
                        import('./components/vote-result.vue').then(({ default: VoteResultComponent }) => {
                            const voteResultComponent = new Vue(VoteResultComponent);
                            voteResultComponent.$props.questions = data.items;
                            jm('#voteResult').append(voteResultComponent.$mount().$el);
                            jm('#voteResult').addClass('showVoteResult');
                        });
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err);
                        this.loading = false;
                    });
            }
        },
        isEmptyObject(obj) {
            var name;
            for (name in obj) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style>
.mobi_vote .vote_item_name::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url(/image/mobi/toupiaoicon.png?v=201711250607) no-repeat;
    background-size: contain;
    vertical-align: middle;
}
.mobi_vote .vote_item_name {
    text-align: center;
    line-height: 1.05rem;
    margin: 0.64rem auto;
}
.mobi_vote .vote_item_name_text {
    vertical-align: middle;
}
.mobi_vote {
    padding: 0 1.29rem 0.7rem 1.29rem;
}
.mobi_vote .text_box .choice_box_wrap {
    margin-top: 0.86rem;
}
.mobi_vote .vote_item_content_img {
    height: 8.5rem;
    text-align: center;
    cursor: pointer;
}
.mobi_vote .vote_item_content_img .vote_item_img {
    max-height: 100%;
    max-width: 100%;
}
.mobi_vote .choice_box_wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.mobi_vote .img_box .choice_box_wrap {
    width: 100%;
    height: 11rem;
    border: 1px solid #eeeeee;
    padding: 0.5rem;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    margin-top: 0.86rem;
}
.mobi_vote .img_box .choice_box_wrap {
    line-height: 2rem;
}
.mobi_vote .btn {
    margin: 0;
    font-size: 0.7rem;
    white-space: nowrap;
}
.mobi_vote .voteOperate {
    display: flex;
    justify-content: space-between;
    margin-top: 0.86rem;
}
.mobi_vote .viewBtn {
    margin-left: 0.9rem;
}
.mobi_vote .vote_code_panel {
    margin-top: 10px;
    padding-top: 0.45rem;
}
.mobi_vote .code_title {
    height: 1.45rem;
}
.mobi_vote .code_content {
    display: flex;
    position: relative;
}
.mobi_vote .code_input {
    width: 50.6%;
    box-sizing: border-box;
    height: 1.6rem;
    line-height: 1rem;
    font-size: 0.6rem;
    font-family: '微软雅黑';
    text-indent: 0.25rem;
    background-color: #f7f7f7;
    border: 1px solid #dadada;
    border-radius: 4px;
}
.mobi_vote .code_img {
    cursor: pointer;
    height: 1.6rem;
    width: auto;
    visibility: visible;
    padding-left: 0.5rem;
    vertical-align: middle;
}
.mobi_vote .code_refresh_btn {
    line-height: 1.75rem;
    background-image: url(/image/refurbish.png?v=201711250607);
    background-repeat: no-repeat;
    width: 10%;
    height: 1.75rem;
    background-size: 18px auto;
    cursor: pointer;
    background-position: 80% 50%;
    vertical-align: middle;
}
.mobi_vote .star {
    position: absolute;
    right: -0.5rem;
    line-height: 1.8rem;
    color: red;
    font-size: 0.7rem;
}

.mobi_vote.manageMode .item_wrap:hover {
    outline: 1px dashed rgb(153, 153, 153);
}
.mobi_vote.manageMode .item_editing {
    outline: 1px dashed rgba(89, 116, 213, 1);
}
.mobi_vote .empty_tips_panel {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 55px;
}
.mobi_vote .empty_tips_panel .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.mobi_vote .voteItemWrap {
    white-space: normal;
    word-break: break-word;
}
.mobi_vote .voteQuestionNowrap {
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
}
.mobi_vote .choice_box_wrap.voteItemWrap {
    height: auto;
    line-height: normal;
}
.mobi_vote .choice_box_wrap.voteItemWrap .vote_item_content_img {
    margin-bottom: 0.5rem;
}

.vote {
    padding: 0 1.29rem 0.7rem 1.29rem;
}

.vote .voteSubject {
    text-align: center;
    line-height: 1.05rem;
    margin: 0.64rem auto;
}
.vote .voteSubject .voteSubjectText {
    display: inline-block;
    padding-left: 1rem;
    background: url(/image/mobi/toupiaoicon.png?v=201711250607) no-repeat;
    background-size: 1rem;
    background-position: 0 50%;
}
.voteBtn {
    width: 100%;
    margin: 0;
    font-size: 0.7rem;
}
.vote .voteItems {
    align-items: center;
    margin-top: 0.86rem;
}
.vote .voteItemImgPanel {
    width: 100%;
    height: 11rem;
    border: 1px solid #eeeeee;
    padding: 0.5rem;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
}
.vote .voteItemNamePanel {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2rem;
}
.vote .voteItems .voteLab {
    margin-left: 0.25rem;
}
.vote .voteItems .voteItemName {
    display: inline-block;
    margin-left: 0.25rem;
}
.vote .voteOperate {
    text-align: center;
    margin-top: 0.86rem;
}
.vote .voteItemCount {
    width: 4rem;
    display: inline-block;
    text-align: center;
    font-size: 0.4rem;
}
.vote .voteItemShow {
    align-items: center;
}

.vote .voteValidateCode {
    margin-top: 10px;
    padding-top: 0.45rem;
}

.vote .voteValidateCode .voteValidateCodeTitle {
    height: 1.45rem;
    display: table-row;
    text-align: left;
    word-break: break-all;
}

.vote .voteValidateCode .voteValidateCodeMain {
    position: relative;
}

.vote .voteValidateCode .g_itext {
    width: 7rem;
    box-sizing: border-box;
    height: 1.6rem;
    line-height: 1rem;
    font-size: 0.6rem;
    font-family: '微软雅黑';
    text-indent: 0.25rem;
    background-color: #f7f7f7;
    border: 1px solid #dadada;
    border-radius: 4px;
}

.vote .voteValidateCode .voteValidateCodeImg {
    cursor: pointer;
    height: 1.6rem;
    width: auto;
    visibility: visible;
    padding-left: 0.5rem;
    vertical-align: middle;
}

.vote .voteValidateCode .validateCodeTip {
    display: inline-block;
    line-height: 1.75rem;
    background-image: url(/image/refurbish.png?v=201711250607);
    background-repeat: no-repeat;
    width: 10%;
    height: 1.75rem;
    background-size: 18px auto;
    cursor: pointer;
    background-position: 80% 50%;
    vertical-align: middle;
}

.vote .voteValidateCode .voteMustStar {
    position: absolute;
    right: -0.5rem;
    line-height: 1.8rem;
    color: red;
    font-size: 0.7rem;
}

.vote .voteCodePanel {
    margin-top: 0.5rem;
}
.vote .captchaText {
    width: 9.3rem;
    height: 1.8rem;
    font-size: 0.6rem;
    color: #8c8c8c;
}
.vote .voteCodePic {
    width: 3rem;
    vertical-align: middle;
    margin-left: 0.7rem;
}

.fullScreenDivCotainer {
    position: fixed;
    z-index: 99;
    padding: 0 !important;
    overflow: visible; /*产品详情详细说明弹出层*/
}

/*修复电商主题下分类跳不过去的问题*/
#webFooterBox .fullScreenDivCotainer .refreshBase {
    position: fixed !important;
    top: 0 !important;
}

.fullScreenDiv {
    visibility: hidden;
    left: 150%;
    overflow-y: auto !important;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 768px;
    z-index: 999 !important;
    transition: all 500ms;
}
.showFullScreen {
    visibility: visible;
    left: 0px;
    visibility: visible;
    transition: all 500ms;
}

.progressBarBg {
    width: 100%;
    height: 0.5rem;
    /*border: 1px solid #d6d6d6;*/
    overflow: hidden;
    /*  background: transparent url(/image/mobi/progressBarbg.jpg?v=201711250607) 0 0 repeat;*/
    background: #dbdbdb;
}

.progressBar {
    width: 0;
    height: 100%;
    background: transparent url(/image/mobi/pre.png?v=201711250607) 0 0 repeat;
}
.progressBar-box {
    padding: 0.25rem 0px;
    margin-top: 0.25rem;
}

.progressBar-box-img {
    height: 2.5rem;
}
.vote .selectedItem {
    background: #f0f0f0;
}

.progressBar-box .voteItemImg {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.3rem;
}

.progressBar-box .voteItemCentent {
    width: 82%;
    display: inline-block;
}
.vote .progressBar-box .voteItemName.selected {
    background: url(/image/mobi/toupiaoselect.png?v=201711250607) no-repeat;
    padding-left: 1.25rem;
    background-size: 10.3rem;
    background-position: 0.25rem;
}

.voteResultDiv {
    visibility: hidden;
    position: fixed;
    top: 150%;
    height: 100%;
    width: 100%;
    max-width: 768px;
    z-index: 99;
    transition: all 500ms;
    overflow-y: auto;
}
.showVoteResult {
    top: 0px !important;
    visibility: visible;
    transition: all 500ms;
}
.voteResultHeader {
    height: 2.3rem;
    text-align: center;
}

.voteResultHeader .title {
    font-size: 1rem;
    line-height: 2.3rem;
}
.voteResultDiv .vote {
    margin: 0px;
}
</style>
