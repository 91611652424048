const STYLE = Symbol();

export class MobiModule {
    /**
     * 访客态模块配置
     * @typedef Options
     * @type {object}
     * @property {boolean} system 是否系统模块
     */
    /**
     *
     * @param {number} style in @/modules/type-mapping.js 模块类型
     * @param {Options} options
     */
    constructor(style, options = {}) {
        this[STYLE] = style;
        this._options = options;
    }

    get style() {
        return this[STYLE];
    }

    render() {
        /** @abstract */
        throw new Error('render function must be implement! error module style is ' + this[STYLE]);
    }
}
