<template>
    <div class="grid_photo_panel">
        <a v-for="item in list" :key="item.id" :href="prUrl + '?groupId=' + item.id" class="img_panel_wrap">
            <div class="img_panel J_img_panel">
                <div
                    :id="'pg_img_item' + item.id"
                    class="img J_img_lazyload"
                    :style="imgStyle(item)"
                    :src-original="item.iconPath"
                    @mouseover="grouItemMouseOver($event, item)"
                    @mouseout="groupItemMouseOut($event)"
                >
                </div>
                <div class="text first_groupname g_productSort_name" :style="textStyle(item)">{{ item.name }}</div>
            </div>
        </a>
    </div>
</template>

<script>
import { productGroupItemUpload } from '../product-group';
export default {
    name: 'GridPhoto',
    inject: ['module', 'options'],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
        prUrl: {
            type: String,
            default: '',
        },
        resRoot: {
            type: String,
            default: '',
        },
    },
    mounted() {},
    methods: {
        imgStyle(item) {
            let style = '';
            let bgImgUrl = this.noLazyLoad ? item.iconPath : this.options.loadingPath;

            if (!bgImgUrl) {
                bgImgUrl = this.resRoot + '/image/no-pic.jpg';
            }

            style += `background-image:url(${bgImgUrl});`;

            if (this.module.content.gps.t == 1) {
                style += `width:${this.module.content.gps.w}rem;height:${this.module.content.gps.h}rem;`;
                style += `font-size:${this.module.content.gps.w}rem;`;
            }

            let backgroundSize;
            switch (this.module.content.gps.s) {
                case 1:
                    backgroundSize = 'contain';
                    break;
                case 2:
                    backgroundSize = '100% 100%';
                    break;
                case 3:
                    backgroundSize = 'auto';
                    break;
            }
            if (backgroundSize) {
                style += `background-size:${backgroundSize};`;
            }

            return style;
        },
        textStyle() {
            let style = {};
            // 自定义图片尺寸时，文字宽度固定
            if (this.module.content.gps.t == 1) {
                style.width = `${this.module.content.gps.w}rem`;
            }

            if (!(VITE_APP_MODE !== 'visitor')) {
                return style;
            }

            var productSortName = this.module.content.mhighs.psn;
            if (productSortName.type == 1) {
                if (productSortName.nfs) {
                    style.fontSize = productSortName.nfs + 'rem';
                }

                if (productSortName.nfct == 1) {
                    style.color = productSortName.nfc;
                }
            }

            return style;
        },
        grouItemMouseOver: function ($event, item) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            const itemJson = [
                {
                    operationText: '编辑图片',
                    className: 'productListEdit',
                    onClick: () => {
                        productGroupItemUpload(this.module.id, item.id);
                    },
                },
            ];
            const options = { extCls: 'productListEditLayer' };

            Vue.prototype.$layer.addEditLayer($($event.currentTarget), itemJson, 6, options);
        },
        groupItemMouseOut: function ($event) {
            if (!(VITE_APP_MODE !== 'visitor')) return;
            Vue.prototype.$layer.removeEditLayer($($event.currentTarget));
        },
    },
};
</script>

<style>
.fk-productGroup .grid_photo_panel {
    overflow: hidden;
    padding-top: 1rem;
    width: 13.5rem;
    margin: auto;
}
.fk-productGroup .img_panel_wrap {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.fk-productGroup .img_panel_wrap:nth-child(4n) {
    margin-right: 0;
}
.fk-productGroup .grid_photo_panel .img_panel {
    width: 2.625rem;
}
.fk-productGroup .grid_photo_panel .img {
    width: 2.625rem;
    height: 2.625rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.fk-productGroup .grid_photo_panel .text {
    width: 2.625rem;
    margin-top: 0.5rem;
    font-size: 0.5rem;
    color: rgba(102, 102, 102, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
</style>
