<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="wrapClasses"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="[isNewAddModuleClass, isOnlyTitle]">
            <div class="mixNewsStyleImgBox6">
                <a
                    v-drag-resize="_resizeOptions"
                    class="f_mixNewsStyleImgBox6_link picRadiusClass"
                    hidefocus="true"
                    :href="_newsUrl"
                    :target="_jumpTarget"
                    :onclick="onclickStr"
                >
                    <div class="headPicTopWrap">
                        <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>
                        <div
                            id="newsTitlePic"
                            :alt="altName"
                            :class="titlePicClass"
                            :src-original="info.realPicId ? info.bigPicPath : ''"
                            :style="picStyle"
                            class="picRadiusClass picScaleClass J_img_lazyload"
                        ></div>
                    </div>
                    <div class="mixNewsStyleTitleContainer6 picRadiusClass" :style="maskBg">
                        <div class="mixNewsStyleTitlePanel">
                            <div v-if="!isNewAddModule && _showSort" class="newsSortPanel">
                                <div class="newsSort">{{ info.groupName }}</div>
                            </div>
                            <div :class="[titleClasses, titleLine]" class="g_news_title" :style="news_title_style">
                                <div :class="_topFlagClass"></div>
                                {{ info.title }}
                            </div>
                            <div
                                v-if="(isNewAddModule && !module.content.des) || !isNewAddModule"
                                class="mixNewsStyleSummary g_news_abstract"
                                :style="news_abstract_style"
                                :class="[isNewAddModule && descriptionLine, isOnlyTitleAndDes]"
                            >
                                {{ info.summary }}
                            </div>
                        </div>
                        <div
                            v-if="!isNewAddModule && showOtherInfo"
                            class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo"
                            @click="otherInfoClick"
                        >
                            <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                                <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                                <span v-else class="newsCommentCount g_newsInfo"
                                    >{{ info.commentCount }} {{ module.extInfo.commentText }}</span
                                >
                            </div>
                            <div v-if="_showAuthor && _showComment" class="ne_separatorLine g_ne_separatorLine"></div>
                            <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                                <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
                            </div>
                            <div v-if="_showDate" class="ne_newsTime g_newsInfo" :class="fixClass">
                                {{ newsDate }}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div
                v-if="isNewAddModule && showOtherInfo"
                class="newsElementsPanel g_newsElementsPanel J_newsElementsPanel g_newsInfo"
                @click="otherInfoClick"
            >
                <div v-if="_showComment" class="ne_newsComment faisco-icons-comment">
                    <span v-if="!isNewAddModule" class="newsCommentCount">{{ info.commentCount }}</span>
                    <span v-else class="newsCommentCount g_newsInfo"
                        >{{ info.commentCount }}{{ module.extInfo.commentText }}</span
                    >
                </div>
                <div v-if="_showSort" class="ne_newsSort g_newsInfo">
                    {{ info.groupName }}
                </div>
                <div v-if="_showAuthor" class="ne_newsAuthor" :style="authorStyle">
                    <span class="newsAuthorName g_newsInfo">{{ info.author }}</span>
                </div>
                <div v-if="_showDate" class="ne_newsTime g_newsInfo" :class="fixClass">
                    {{ newsDate }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'MaskItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        isNewAddModuleClass() {
            return this.isNewAddModule ? ' isNewAddModule' : '';
        },
        wrapClasses() {
            return `${this._lineClass} largeImageNewsStyle newsLine J_picNum`;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        titlePicClass() {
            return this.info.realPicId === '' ? 'newsTitlePic icon-noFigureB' : 'newsTitlePic';
        },
        divSize() {
            let divWidth = 4.6;
            let divHeight = 9;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth / 20;
                divHeight = tSize.picHeight / 20;
            }
            return { divWidth, divHeight };
        },
        picStyle() {
            let picStyle = {};
            if (this.info.realPicId !== '') {
                picStyle.background = `url(${
                    this.noLazyLoad ? this.info.bigPicPath : this.options.loadingPath
                }) no-repeat center /cover`;
            }
            if (this.isNewAddModule) {
                picStyle.height = `${this.divSize.divHeight}rem`;
            }
            return picStyle;
        },
        authorStyle() {
            return !this._showComment ? 'margin-left:0.6rem;' : '';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        showOtherInfo() {
            return this._showDate || this._showSort || this._showAuthor || this._showComment;
        },
        fixClass() {
            if (!(VITE_APP_MODE !== 'visitor')) return '';
            if (this._showComment && !this._showAuthor) return 'fix1';
            if (!this._showComment && !this._showAuthor) return 'fix2';
            return '';
        },
        descriptionLine() {
            const descriptionLine = this.module.content.dsl;
            const textPosClass = this.module.content.tp == 2 ? 'newsTextPosition2' : '';
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return `${textPosClass} ${desClass}`;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        maskBg() {
            let style = {};
            if (this.module.content.mbt === 1) {
                style.background = 'none';
            } else if (this.module.content.mbt === 2) {
                style.background = this.module.content.mbc;
            }
            return style;
        },
    },
};
</script>

<style></style>
