import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import SimpleTextView from './module/simple-text.vue';

class _SimpleText extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.SIMPLE_TEXT, options);
    }

    render() {
        return SimpleTextView;
    }
}

export const SimpleText = new _SimpleText();
