import { MobiModule } from '../Module';
import { MODULE_STYLE } from '@/def/module';
import CubeNavigationView from './module/cubeNavigation.vue';

class _CubeNavigation extends MobiModule {
    constructor(options = {}) {
        super(MODULE_STYLE.CUBE_NAVIGATION, options);
    }

    render() {
        return CubeNavigationView;
    }
}

export const CubeNavigation = new _CubeNavigation();
