import { createElementByHtml } from '@/shared/util';
export function noticeLeftRightMarquee(options) {
    var defaultOptions = {
        direction: 'up', //方向
        speed: 'normal', //速度
        loop: 'infinite',
        moveout: false, //移入移出
        isScrolling: false, //是否连续滚动
    };
    jm.extend(defaultOptions, options);
    if (defaultOptions.speed == 'normal') {
        jm.extend(defaultOptions, { speed: 70 });
    } else if (defaultOptions.speed == 'slow') {
        jm.extend(defaultOptions, { speed: 40 });
    } else {
        jm.extend(defaultOptions, { speed: 100 });
    }

    jm('#noticeMarquee' + defaultOptions.id).data('options', options);

    //声明变量
    var id = defaultOptions.id,
        marqueeModule = $('#noticeMarquee' + id)[0],
        //文本内容的高度
        marqueeHeight = marqueeModule.offsetHeight, // 上下移动使用
        marqueeModuleOriginalWidth = marqueeModule.offsetWidth,
        marqueeContentWidth = 0,
        noticeMarqueeRowSpan = marqueeModule.querySelectorAll('.noticeMarqueeRow>span');
    for (var span = 0; span < noticeMarqueeRowSpan.length; span++) {
        marqueeContentWidth += noticeMarqueeRowSpan[span].offsetWidth;
    }

    var marqueeModuleParent = $('#noticeContainer' + id)[0],
        marqueeModuleParentWidth = marqueeModuleParent.offsetWidth,
        marqueeWidth = marqueeContentWidth > marqueeModuleParentWidth ? marqueeContentWidth : marqueeModuleParentWidth;
    var direction = defaultOptions.direction,
        initPosition = 0,
        speed = defaultOptions.speed,
        moveout = false, //defaultOptions.moveout ,
        isScrolling = defaultOptions.isScrolling,
        noticeType = defaultOptions.noticeType,
        //无缝滚动添加的DIV
        noticeMarqueeCopy = createElementByHtml(
            "<div id='noticeMarqueeCopy" +
                id +
                "' class='noticeMarquee' style='overflow:hidden;width:" +
                marqueeWidth +
                "px;' ></div>"
        );
    var marqueeNewParentTmp =
        "<div id='noticeMarqueeNewParent" +
        id +
        "' style='position:relative;overflow:hidden;width:" +
        (marqueeWidth + marqueeModuleParentWidth) +
        "px;'></div>";
    //iphone 动画前文字会进行调整 造成marqueeWidth 不够 所以重叠问题  解决：-webkit-text-size-adjust:none;

    // moduleStyle == 2
    if (noticeType <= 5 && noticeType >= 1) {
        var marqueeModuleView = createElementByHtml(
            "<div style='-webkit-text-size-adjust:none;position: relative;width: 100%;height:1.5rem;line-height:1.5rem;overflow: hidden;" +
                (noticeType == 4 ? '' : 'left: 23px;') +
                "'></div>"
        );
    }
    // moduleStyle ==1
    else {
        marqueeModuleView = createElementByHtml(
            "<div style='-webkit-text-size-adjust:none; position: relative; width: auto;height:70px; line-height:70px; margin-left:70px; overflow: hidden;" +
                (noticeType == 4 ? '' : 'left: 20px;') +
                "'></div>"
        );
    }
    var marqueeModuleNewParent = createElementByHtml(marqueeNewParentTmp);

    marqueeModule.style.width = marqueeWidth + 'px';
    //初始化
    function init() {
        //连续滚动新增一个父类DIV和克隆一个同级DIV
        marqueeModuleParent.appendChild(marqueeModuleView);
        marqueeModuleView.appendChild(marqueeModuleNewParent);
        marqueeModuleNewParent.appendChild(marqueeModule);
        marqueeModuleNewParent.appendChild(noticeMarqueeCopy);

        //连续滚动
        if (!isScrolling) {
            //非连续滚动  移入  noticeMarqueeCopy里面不需要内容
            noticeMarqueeCopy.style.height = 20 + 'px';
            noticeMarqueeCopy.style.width = marqueeModuleParentWidth + 'px';
        } else {
            //jm(marqueeModule.html()).appendTo(noticeMarqueeCopy);
            noticeMarqueeCopy.appendChild(marqueeModule.querySelector('.noticeMarqueeDiv').cloneNode(true));
        }

        if (direction == 'left') {
            initPosition = marqueeHeight;
        } else {
            //不连续滚动设置的初始值
            initPosition = 0 - marqueeWidth;
            //连续滚动，重新设置初始值
            if ((isScrolling && !moveout) || (!isScrolling && !moveout)) {
                initPosition = 0;
            }
            if (isScrolling && moveout) {
                initPosition = -marqueeWidth;
            }
        }
        //移入移出
        if (moveout) {
            // init
            marqueeModule.style.left = 0 + 'px';
        } else {
            marqueeModule.style.left = initPosition + 'px';
        }

        if (direction == 'left' || direction == 'right') {
            marqueeModule.style.top = 0 + 'px';
        }

        noticeMarqueeDirection(direction);
    }

    //连续滚动与非连续滚动
    function noticeMarqueeDirection(direction) {
        if (direction == 'left' || direction == 'right') {
            marqueeModuleNewParent.style.position = 'relative';
            noticeMarqueeCopy.style.top = 0 + 'px';

            if (direction == 'left') {
                //连续滚动  移入
                if (isScrolling && !moveout) {
                    marqueeModuleNewParent.style.width = marqueeWidth * 2 + 'px';
                    marqueeModule.style.left = 0 + 'px';
                    noticeMarqueeCopy.style.left = marqueeWidth + 'px';
                }
                //连续滚动  移出
                if (isScrolling && moveout) {
                    //noticeMarqueeCopy.style.left = marqueeWidth + "px";
                }
                //非连续滚动 移入
                if (!isScrolling && !moveout) {
                    marqueeModule.style.left = marqueeModuleOriginalWidth + 'px';
                    noticeMarqueeCopy.style.left = marqueeWidth + marqueeModuleParentWidth + 'px';
                }
                //非连续滚动 移出
                if (!isScrolling && moveout) {
                    //marqueeModuleNewParent.style.left = marqueeModuleParentWidth + "px";
                }
            } else {
                // right

                //两个子DIV的间隙
                //marqueeModule.style.marginLeft = paramMargin + "px";
                marqueeModule.style.width = marqueeWidth + 'px';
                //连续滚动  移入
                if (isScrolling && !moveout) {
                    marqueeModuleNewParent.style.width = marqueeWidth * 2 + 'px';
                    marqueeModule.style.paddingLeft = marqueeWidth + 'px';
                    marqueeModuleNewParent.style.left = -(marqueeWidth * 2 - marqueeModuleParentWidth + 23) + 'px';
                }
                //连续滚动  移出
                if (isScrolling && moveout) {
                    marqueeModuleNewParent.style.width = marqueeWidth * 2 + 'px';
                    marqueeModule.style.paddingLeft = marqueeWidth + 'px';
                    marqueeModuleNewParent.style.left = -(marqueeWidth * 2 - marqueeModuleParentWidth + 23) + 'px';
                }
                //非连续滚动 移入
                if (!isScrolling && !moveout) {
                    noticeMarqueeCopy.style.left = marqueeWidth + 'px';
                    marqueeModuleNewParent.style.left = -marqueeWidth + 'px';
                }
                //非连续滚动 移出
                if (!isScrolling && moveout) {
                    marqueeModuleNewParent.style.left = -(marqueeWidth + 23) + 'px';
                    marqueeModule.style.left = marqueeModuleParentWidth + 'px';
                    noticeMarqueeCopy.style.left = 0 + 'px';
                }
            }
        }
    }

    function animation50(width, direction, isScrolling, speed) {
        var animationName;
        if (direction == 'left') {
            animationName = isScrolling ? 'mobi_notice_right_to_left_50' : 'mobi_notice_right_to_left';
        } else if (direction == 'right') {
            animationName = isScrolling ? 'mobi_notice_left_to_right_50' : 'mobi_notice_left_to_right';
        }

        return (
            '-webkit-animation: ' +
            animationName +
            ' ' +
            width / speed +
            's linear 1s infinite;' + // Safari 4+
            '-moz-animation:    ' +
            animationName +
            ' ' +
            width / speed +
            's linear 1s infinite;' + // Fx 5+
            '-o-animation:      ' +
            animationName +
            ' ' +
            width / speed +
            's linear 1s infinite;' + // Opera 12+
            'animation:         ' +
            animationName +
            ' ' +
            width / speed +
            's linear 1s infinite;'
        ); // IE 10+
    }

    //设置连续滚动
    function noticeMarquee() {
        if (direction == 'left') {
            //连续滚动  移入
            if (isScrolling && !moveout) {
                //marqueeModuleNewParent.className += " mobi_notice_right_to_left_50_" + speed;
                marqueeModuleNewParent.setAttribute(
                    'style',
                    marqueeModuleNewParent.getAttribute('style') +
                        animation50(marqueeWidth + marqueeModuleParentWidth, direction, isScrolling, speed)
                );
            }
            //连续滚动  移出
            if (isScrolling && moveout) {
                //marqueeModuleNewParent.className += " mobi_notice_right_to_left_50_" + speed;
            }
            //非连续滚动 移入
            if (!isScrolling && !moveout) {
                //marqueeModuleNewParent.className += " mobi_notice_right_to_left_" + speed;
                marqueeModuleNewParent.setAttribute(
                    'style',
                    marqueeModuleNewParent.getAttribute('style') +
                        animation50(marqueeWidth + marqueeModuleParentWidth, direction, isScrolling, speed)
                );
            }
            //非连续滚动 移出
            if (!isScrolling && moveout) {
                marqueeModuleNewParent.className += ' mobi_notice_right_to_left';
                setTimeout(function () {
                    marqueeModuleNewParent.style.left = marqueeModuleParentWidth + 'px';
                }, 15000);
            }
        } else if (direction == 'right') {
            //连续滚动  移入
            if (isScrolling && !moveout) {
                marqueeModuleNewParent.setAttribute(
                    'style',
                    marqueeModuleNewParent.getAttribute('style') +
                        animation50(marqueeWidth + marqueeModuleParentWidth, direction, isScrolling, speed)
                );
            }
            //连续滚动  移出
            if (isScrolling && moveout) {
                marqueeModuleNewParent.className += ' mobi_notice_left_to_right_50_' + speed;
            }
            //非连续滚动  移入
            if (!isScrolling && !moveout) {
                marqueeModuleNewParent.setAttribute(
                    'style',
                    marqueeModuleNewParent.getAttribute('style') +
                        animation50(marqueeWidth + marqueeModuleParentWidth, direction, isScrolling, speed)
                );
            }
            //非连续滚动  移出
            if (!isScrolling && moveout) {
                marqueeModuleNewParent.className += ' mobi_notice_left_to_right_' + speed;
                setTimeout(function () {
                    marqueeModuleNewParent.style.left = -(marqueeModuleParentWidth + marqueeWidth + 23) + 'px';
                }, 15000);
            }
        }
    }

    if (options != null) {
        init();
        noticeMarquee();
    }
}
export function noticeMarqueeUpDown(options) {
    var defaultOptions = {
        direction: 'top', //方向
        speed: 'normal', //速度
        stopTime: 1000, //停留时间
    };
    jm.extend(defaultOptions, options);
    if (defaultOptions.speed === 'fast') {
        jm.extend(defaultOptions, { speed: 500 });
    } else if (defaultOptions.speed === 'slow') {
        jm.extend(defaultOptions, { speed: 1100 });
    } else {
        jm.extend(defaultOptions, { speed: 850 });
    }

    var noticeScrollbars = $('#noticeScrollbar' + defaultOptions.id)[0];

    if (noticeScrollbars.length < 1) {
        return;
    }

    var noticeScrollbarStyle = noticeScrollbars.style,
        $noticeScrollbars = jm(noticeScrollbars),
        noticeScrollbarsLis = noticeScrollbars.querySelectorAll('li'),
        lisLength = noticeScrollbarsLis.length,
        copyLi = '';
    var noticeScrollbarClassname = noticeScrollbarsLis[0].getAttribute('class');
    var copyNoticeScrollbarStyle = noticeScrollbarsLis[0].getAttribute('style') || '';
    if (lisLength == 1) {
        var li = '';
        if (lisLength == 1) {
            li =
                "<li class='" +
                noticeScrollbarClassname +
                "' style='" +
                copyNoticeScrollbarStyle +
                "'>" +
                noticeScrollbarsLis[0].innerHTML +
                "<li class='" +
                noticeScrollbarClassname +
                "' style='" +
                copyNoticeScrollbarStyle +
                "'>" +
                noticeScrollbarsLis[0].innerHTML +
                '</li>';
        } else {
            for (var k in noticeScrollbarsLis) {
                li +=
                    "<li class='" +
                    noticeScrollbarClassname +
                    "' style='" +
                    copyNoticeScrollbarStyle +
                    "'>" +
                    noticeScrollbarsLis[k].innerHTML +
                    '</li>';
            }
        }
        $noticeScrollbars.append(jm(li));
    } else {
        // 实现上下无缝轮播
        //向下滚动就要复制最后一个节点插入到第一个节点中
        if (defaultOptions.direction == 'top') {
            copyLi =
                "<li class='" +
                noticeScrollbarClassname +
                "' style='" +
                copyNoticeScrollbarStyle +
                "'>" +
                noticeScrollbarsLis[lisLength - 1].innerHTML +
                '</li>';
            noticeScrollbars.insertBefore(jm(copyLi)[0], noticeScrollbars.getElementsByClassName('scrollbarLi')[0]);
        } else {
            copyLi =
                "<li class='" +
                noticeScrollbarClassname +
                "' style='" +
                copyNoticeScrollbarStyle +
                "'>" +
                noticeScrollbarsLis[0].innerHTML +
                '</li>';
            $noticeScrollbars.append(copyLi);
        }
    }

    //由于上面插入了新的li，这里要重新再获取一下li的数量
    noticeScrollbarsLis = noticeScrollbars.querySelectorAll('li');

    noticeScrollbarStyle[jm.feat.cssPrefix + 'TransitionProperty'] = 'top';
    noticeScrollbarStyle[jm.feat.cssPrefix + 'TransitionTimingFunction'] = 'ease';
    noticeScrollbars.parentNode.style.height = noticeScrollbarsLis[0].offsetHeight + 'px';
    var top = [];

    for (var i = 0; i < noticeScrollbarsLis.length; i++) {
        top[i] = noticeScrollbarsLis[i].offsetTop;
    }

    if (defaultOptions.direction == 'top') {
        top.reverse();
    }
    noticeScrollbars.style.top = -top[0] + 'px';

    Mobi.noticeMarqueetimeIds = Mobi.noticeMarqueetimeIds || {}; //保存公告动画setTimeout的id
    // 清除老setTimeout
    var noticeMarqueetimeId = Mobi.noticeMarqueetimeIds['scroll' + defaultOptions.id];
    if (noticeMarqueetimeId >= 0) {
        clearTimeout(noticeMarqueetimeId);
    }

    var j = top.length - 1, // 用最后一张图模拟第一张图的效果，这样不会忽略两张图间的等待时间。如有疑问可以设j=0看效果，第一次运行时第一张图与第二张图间隔时间很快
        length = top.length,
        defaultOptionsSpeed = defaultOptions.speed + 'ms',
        timeoutTime = 0, // 下一次滚动的等待时间
        transitionDuration = jm.feat.cssPrefix + 'TransitionDuration'; // jm.feat.cssPrefix 根据当前浏览器取前缀如-webkit-、-o-
    if (noticeScrollbarsLis.length > 1) {
        noticUpDownScroll();
    }

    function noticUpDownScroll() {
        // 上下无缝滚动
        if (j < length) {
            if (j) {
                noticeScrollbarStyle[transitionDuration] = defaultOptionsSpeed;
                timeoutTime = defaultOptions.stopTime + defaultOptions.speed;
            } else {
                // 实现无缝连接的关键，由于最后一样图把第一张图的效果模拟出来，代码真正执行到第一张图的时候，第一幅图要瞬间转到相应位置，且让其尽快再次执行滚动函数
                noticeScrollbarStyle[transitionDuration] = '0ms';
                timeoutTime = 25; //set成25是比较主观的数值，本来是set成0，让其尽快执行的，但是这样跟下面的else里面会有顺序上的错误，两个set(noticUpDownScroll,0)的先后顺序是随机的，根据浏览器默认值所有这里set 25
            }
            noticeScrollbarStyle.top = -top[j] + 'px';
            j++;
        } else {
            j = 0;
            timeoutTime = 0;
        }

        Mobi.noticeMarqueetimeIds['scroll' + defaultOptions.id] = setTimeout(noticUpDownScroll, timeoutTime);
    }
}

export function revertIcon(moduleId) {
    var module = jm('#module' + moduleId);
    var isFontIcon = false;
    var noticeWH = module.find('.noticeContainer').height();
    if (module.find('.noticeFontIcon').length != 0) {
        isFontIcon = true;
    }
    if (isFontIcon) {
        module
            .find('.noticeFontIcon')
            .css('font-size', 21 + 'px')
            .css('line-height', noticeWH + 'px');
    } else {
        module
            .find('.noticeImg')
            .css('width', 21 + 'px')
            .css('height', 21 + 'px');
    }
}
export function noticeUpDownSizeChange(moduleId) {
    var noticeMarquee = jm('#noticeMarquee' + moduleId),
        noticeMarqueeHeight = noticeMarquee.height(),
        scrollBarChs = noticeMarquee.children().children();
    jm.each(scrollBarChs, function (i, obj) {
        jm(obj).css('top', -(noticeMarqueeHeight + 2) + 'px');
    });
    jm('#noticeScrollbar' + moduleId).removeData('_innerHeight');
}
