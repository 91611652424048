<template>
    <a :href="product.htmlUrl.endsWith('pd.jsp') ? 'javascript:void(0);' : product.htmlUrl" :onclick="onclickStr">
        <div
            class="product_item J_product_item"
            :productId="product.id"
            :productName="product.name"
            :class="productItemClass"
        >
            <div :id="'product' + product.id + 'module' + moduleId" class="img_panel">
                <div
                    class="photoListImg J_img_lazyload"
                    :src-original="product.newPath"
                    :style="imgStyle(product)"
                ></div>
                <quality-content-label
                    v-if="isShowQualityContentLabel"
                    :text="qualityContentLabelText"
                ></quality-content-label>
            </div>
            <div class="right_content">
                <div class="title g_product_name" :style="pn">
                    <span v-if="product.isTimedAdded" class="g_oldThemeBgColor g_main_bgColor_v3 m_timedAddTag"
                        >即将开售</span
                    >{{ product.name }}
                </div>
                <div
                    v-if="product.isShowMallPrice && !product.vipName && !isLinkProduct"
                    class="g_main_color_v3 price_panel mallPrice"
                >
                    <span v-if="!siteCurrencyRight" class="unit" v-text="product.unit"></span
                    ><span class="num1" v-text="product.num1 + '.'"></span
                    ><span class="num2" v-text="product.num2"></span
                    ><span v-if="siteCurrencyRight" class="unit" v-text="product.unit"></span>
                    <div
                        v-if="product.enablePromotion && product.isSaleTypeDis"
                        class="linePrice"
                        v-text="
                            siteCurrencyRight
                                ? formatPrice(product.mallPrice) + product.unit
                                : product.unit + formatPrice(product.mallPrice)
                        "
                    ></div>
                    <div
                        v-else-if="product.isShowMarketPrice"
                        class="linePrice"
                        v-text="
                            siteCurrencyRight
                                ? formatPrice(product.mallMarketPrice) + product.unit
                                : product.unit + formatPrice(product.mallMarketPrice)
                        "
                    ></div>
                </div>
                <div
                    v-if="product.isShowMallPrice && product.vipName"
                    class="g_main_color_v3 price_panel2 mallPrice"
                    :class="productItemPriceClass"
                >
                    <div class="price_wrap">
                        <span class="unit" v-text="product.unit"></span>
                        <span class="num1" v-text="product.num1 + '.'"></span>
                        <span class="num2" v-text="product.num2"></span>
                    </div>
                    <span v-if="product.vipName" class="vipName g_bgColor g_main_bgColor_v3">{{
                        product.vipName
                    }}</span>
                    <div
                        v-if="product.isShowMarketPrice"
                        class="linePrice"
                        v-text="product.unit + formatPrice(product.mallMarketPrice)"
                    ></div>
                </div>
                <div
                    v-if="mallOpen && product.isShowMallPrice && !isOpenKnowPayProduct"
                    :id="product.id"
                    class="g_main_bgColor_v3 mall_cart J_fk-mallCart f-mallCart icon-gwc"
                    @click.prevent.stop="handleCart(product)"
                >
                    <span class="faisco-icons-S000279"></span>
                </div>
                <span
                    v-if="showBookingBtn"
                    :id="'proListBookingBtn' + product.id"
                    class="g_main_bgColor_v3 g_button booking_btn"
                    :_pid="product.id"
                    :class="btnClass"
                    @click.stop.prevent="handleBookClick"
                >
                    {{ bookingBtnText }}
                </span>
                <span
                    v-if="showProductForm"
                    class="g_main_bgColor_v3 g_button booking_btn"
                    :class="btnClass"
                    @click.stop.prevent="handleFormClick"
                >
                    {{ productFormText }}
                </span>
            </div>
        </div>
    </a>
</template>
<script>
import qualityContentLabel from './quality-content-label.vue';
import { createUrlArgsOnclickStr } from '../../../comm';
import { productFormBtnClick } from '@/modules/shared/product/btn';
import { mapGetters } from 'vuex';
export default {
    name: 'ProductItem',
    components: {
        qualityContentLabel,
    },
    inject: ['module', 'options'],
    props: {
        moduleId: {
            type: [String, Number],
            require: true,
            default: '',
        },
        mallOpen: {
            type: Boolean,
            default: false,
        },
        bookingOpen: {
            type: Boolean,
            deafult: false,
        },
        productFormOpen: {
            type: Boolean,
            deafult: false,
        },
        bookingBtnText: {
            type: String,
            default: '',
        },
        product: {
            type: Object,
            default: function () {
                return {};
            },
        },
        pn: {
            type: String,
            default: '',
        },
        noLazyLoad: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('app', ['hashRemoved']),
        //已抢光标志图片
        soldOutImg() {
            // return this.module.cataLogData.isCN
            //     ? 'http://ma.faisys.com/image/soldOut.png'
            //     : this.options.resRoot + '/image/soldOut_en.png';
            return `${this.options.resRoot}/image/soldOut${this.module.cataLogData.isCN ? '' : '_en'}.png`;
        },
        color: function () {
            let type = Fai.top._other.templateInfo.templateDesignType;
            switch (type) {
                case 1:
                    return Fai.top._colorFullThemeColor;
                case 2:
                    return Fai.top._mallThemeColor;
            }
            return '';
        },
        style: function () {
            if (this.color != null) {
                return {
                    color: this.color,
                    backgroundColor: this.color,
                };
            } else {
                return {};
            }
        },
        productFormText() {
            return this.module.content.pf.cft == 1
                ? this.module.content.pf.pft
                : this.module.cataLogData.productFormText;
        },
        isKnowPayProduct() {
            return this.product.productType == 2;
        },
        isLinkProduct() {
            return this.product.productType == 4;
        },
        isOpenKnowPayProduct() {
            return this.module.cataLogData.openKnowPayment || false;
        },
        showProductForm() {
            return (
                this.productFormOpen &&
                this.module.content.pf.s &&
                !(this.isOpenKnowPayProduct && this.isKnowPayProduct) &&
                !this.isLinkProduct
            );
        },
        showBookingBtn() {
            return this.bookingOpen && !(this.isOpenKnowPayProduct && this.isKnowPayProduct) && !this.isLinkProduct;
        },
        isShowQualityContentLabel() {
            return this.isOpenKnowPayProduct && this.isKnowPayProduct;
        },
        qualityContentLabelText() {
            const payContentType =
                this.product.other && this.product.other.pkp && this.product.other.pkp.payContentType;
            if (payContentType === 0) {
                return '视频';
            } else if (payContentType === 1) {
                return '图文';
            } else if (payContentType === 2) {
                return '专栏';
            } else if (payContentType === 3) {
                return '音频';
            }
            return '';
        },
        productItemClass() {
            let className = [];
            if (this.product.isShowMallPrice && this.product.vipName && (this.showBookingBtn || this.showProductForm)) {
                className = ['product_market'];
            }
            return className;
        },

        productItemPriceClass() {
            let className = [];
            if (this.showBookingBtn || this.showProductForm) {
                className = ['product_market_price'];
            }
            return className;
        },
        btnClass() {
            let className = [];
            if (this.product.isShowMallPrice && this.product.vipName) {
                className = ['product_market_btn'];
            }
            return className;
        },
        onclickStr() {
            const { pdReqArgs } = this.product;
            return (
                "sessionStorage.setItem('clickPdFrom', '产品分类模块');" +
                createUrlArgsOnclickStr(pdReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved)
            );
        },
        siteCurrencyRight() {
            let { siteCurrencyRight, unit } = this.product;
            return siteCurrencyRight && unit != '￥';
        },
    },
    methods: {
        handleCart(item) {
            this.$emit('clickCart', item);
        },
        handleBookClick() {
            this.$logDog(200332, 16);
            jm('#bookingPanelTriggle').data('pid', this.product.id).trigger('click');
        },
        imgStyle(item) {
            let style = '';
            style += `background-image:url(${this.noLazyLoad ? item.newPath : this.options.loadingPath});`;

            if (this.module.content.s == 3 || this.module.content.s == 4) {
                if (this.module.content.pps.t == 1) {
                    style += `width:${this.module.content.pps.w}rem;padding-top:${this.module.content.pps.h}rem;`;
                    style += `font-size:${this.module.content.pps.w}rem;`;
                }

                let backgroundSize;
                switch (this.module.content.pps.s) {
                    case 1:
                        backgroundSize = 'contain';
                        break;
                    case 2:
                        backgroundSize = '100% 100%';
                        break;
                    case 3:
                        backgroundSize = 'auto';
                        break;
                }
                if (backgroundSize) {
                    style += `background-size:${backgroundSize};`;
                }
            }
            return style;
        },
        handleFormClick() {
            let productFormId = this.module.cataLogData.productFormId;
            if (
                this.product.other &&
                this.product.other.productForm &&
                this.product.other.productForm.productFormWay == 1
            ) {
                productFormId = this.product.other.productForm.productFormId;
            }
            productFormBtnClick(productFormId, this.product.id);
        },
        formatPrice(num) {
            //输入int类型数字后自动在后面加.00，输入double型保留小数点后两位
            num += '';
            num = num.replace(/[^0-9|.]/g, '');

            if (/^0+/.test(num)) num = num.replace(/^0+/, '');
            if (!/\./.test(num)) num += '.00';
            if (/^\./.test(num)) num = '0' + num;
            num += '00';
            num = num.match(/\d+\.\d{2}/)[0];
            return num;
        },
    },
};
</script>
<style>
.moduleContent .fk-productGroup .first_panel .product_market {
    height: 5rem;
}
.moduleContent .fk-productGroup .first_panel .product_market_price {
    bottom: 1.6rem;
}
.moduleContent .fk-productGroup .first_panel .product_market_btn {
    right: initial;
    bottom: 0.3rem;
}
</style>
