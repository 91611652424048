import { browser } from '@jz/utils';
import { encodeHtmlJs } from '@/shared/util';
import { popupWindow } from '@/shared/mobile';
import { successHandle } from '@/shared/fai';
import { genAjaxUrl, getSiteMultiLanguageUrl } from '@/shared/url';
import { reloadPage } from '@/features/router/universal';
import { encodeHtml } from '@/shared/util';

//处理图册模块样式交互 by jser 2017-6-6
//对外添加两个工具接口，用于绑定交互，解绑交互，暴露共享API
let photoGroup = {
    init,
    bindInteractive,
    unBindInteractive,
    bindWithEditLayer,
    utils: {
        getImgByCard,
        getSliderDir,
        getPosByEvent,
        getStyleType,
        isInDom,
    },
};

function init() {
    registerZoomSlider(photoGroup);
    registerPokerSlider(photoGroup);

    //初始化整个页面的交互(日后需要，可以移动到其他地方)
    jm(initPageInteractive);
    jm(window).load(initEditLayer);
    //图册筛选结果editLayer by jser 2017-7-17
    //时机太早，拿不到Fai对象
    jm(initPhotoGroupResult);

    function initPhotoGroupResult() {
        if (!(VITE_APP_MODE !== 'visitor')) {
            return;
        }

        jm('.formStyle48').each(function (index, module) {
            jm(module)
                .on('mouseenter.bindEditLayer', function () {
                    bindEditLayer(jm(this));
                })
                .on('mouseleave.bindEditLayer', function () {
                    bindEditLayer(jm(this), false);
                });
        });
    }

    function bindEditLayer($module, flag) {
        var $items = $module.find('.f-photoGroupItem');

        $items.off('mouseenter.editLayer mouseleave.editLayer');

        if (flag === false) {
            return;
        }

        $items
            .on('mouseenter.editLayer', function () {
                var $item = jm(this),
                    groupName = $item.find('.f-photoGroupR-title').text().trim(),
                    id = $item.attr('id'),
                    groupId = id.replace('photoGroupR', ''), //数值部分就是groupid
                    editJson = {
                        operationText: '编辑图册',
                        className: 'edit_2',
                        onClick: () => {
                            popupWindow({
                                title: '编辑图册',
                                frameSrcUrl: `${getEditUrl(groupId)}`,
                                width: 860,
                                height: 682,
                                version: 2,
                            });
                        },
                    },
                    delJson = {
                        operationText: '删除图册',
                        className: 'close_2',
                        onClick: () => {
                            deletePhotoGroupManage(`${groupId}`, `${encodeHtmlJs(groupName)}`, '', 0);
                        },
                    },
                    json = [editJson, delJson],
                    $editLayer;
                if (Vue.prototype.$layer && typeof Vue.prototype.$layer === 'function') {
                    $editLayer = Vue.prototype.$layer($item, json, 6);
                    //调整出现的位置，右下角
                    $editLayer.css({
                        left: $item.offset().right - $editLayer.width() - 2,
                        top: $item.offset().bottom - $editLayer.height() - 2,
                    });
                }
            })
            .on('mouseleave.editLayer', function () {
                var $item = jm(this);
                Vue.prototype.$layer.removeEditLayer($item);
            });
    }

    //获取编辑入口的url
    function getEditUrl(groupId) {
        var topLoc = Fai.top.location,
            pathname = topLoc.pathname,
            pathArr = pathname.split('/'),
            editUrl;

        pathArr.pop(); //替换页面
        pathArr.pop();
        pathArr.push('manage_v2');
        pathArr.push('photoGroupEdit.jsp');
        editUrl = Fai.top._siteDomain + pathArr.join('/') + '?fromManage=true&mobiSettingState=3&id=' + groupId;

        return editUrl;
    }
}

//初始化整个页面所有图册目录的交互
function initPageInteractive() {
    //为所有模块绑定交互功能
    jm('.formStyle34').each(function (index, module) {
        var $module = jm(module);
        //初始化交互
        bindInteractive($module);
    });
}
//解除某个模块的交互
function unBindInteractive($module) {
    var styleId, $photoAll;

    if ($module.length === 0) {
        return;
    }

    $photoAll = $module.find('.J_photoGroupAll');
    //样式4
    if ($photoAll.hasClass('J_photoGroupAll4')) {
        styleId = 4;
    }
    //样式5
    if ($photoAll.hasClass('J_photoGroupAll5')) {
        styleId = 5;
    }

    getSliderByStyleId(styleId).destory($module);
    recoverDefaultInteractive($module);
}
//绑定交互
function bindInteractive($module) {
    var styleId, $photoAll;

    if ($module.length === 0) {
        return;
    }

    $photoAll = $module.find('.J_photoGroupAll');
    //样式4模块
    if ($photoAll.hasClass('J_photoGroupAll4')) {
        styleId = 4;
    }
    //样式5模块
    if ($photoAll.hasClass('J_photoGroupAll5')) {
        styleId = 5;
    }

    //绑定交互(依据模块样式ID的不同，选择不同的交互方案，如果没有交互方案，那么create和destory都会是一个空函数)
    getSliderByStyleId(styleId).create($module);

    //禁止默认的交互行为（比如，拖拽排序）
    banDefaultInteractive($module, styleId);
}
//禁止默认的交互(如模块的拖拽排序等，规避其对【样式4交互】的影响)
function banDefaultInteractive($module) {
    crtlDefaultInteractive($module);
}
//恢复默认交互
function recoverDefaultInteractive($module) {
    crtlDefaultInteractive($module, false);
}
//控制默认交互
function crtlDefaultInteractive($module, flag) {
    //控制模块排序
    ctrlModuleSort($module, flag);
    //控制图片浏览
    ctrlPhotoScan($module, flag);
    //控制链接跳转
    ctrlLinkJump($module, flag);
    //控制编辑入口
    ctrlEditLayer($module, flag);
}
//控制模块排序(处理管理态模块排序对交互造成的影响)  flag:false用于标志是一个解除控制的操作
function ctrlModuleSort($module, flag) {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }

    var $photoGroup = $module.find('.J_photoGroupAll');

    //解除控制
    $photoGroup.off('mousedown.photoGroup').off('mouseup.photoGroup');

    //是否是解除控制的操作
    if (flag === false) {
        return;
    }

    /*绑定控制*/
    //交互期间不触发模块排序功能
    $photoGroup
        .on('mousedown.photoGroup', function () {
            $('#webModuleContainer').sortable('disable');
        })
        .on('mouseup.photoGroup', function () {
            $('#webModuleContainer').sortable('enable');
        });
}
//控制图片浏览 (主要处理样式4的问题)  flag:false用于标志是一个解除控制的操作
function ctrlPhotoScan($module, flag) {
    var $photoGroup = $module.find('.J_photoGroupAll'),
        $imgMask,
        info;

    if (!$photoGroup.hasClass('J_photoGroupAll4') && !$photoGroup.hasClass('J_photoGroupAll5')) {
        //处理样式4和样式5
        return;
    }

    //解除控制
    $imgMask = $photoGroup.find('.J_photoGroup-img-mask');
    $imgMask.off('mousedown.photoGroupScan mouseup.photoGroupScan'); //解除遮罩层的事件
    $imgMask.remove(); //删除遮罩层

    //是否是解除控制的操作
    if (flag === false) {
        return;
    }

    if (!browser.isPc()) {
        return;
    } //非PC访问，不做处理

    //绑定控制
    info = {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        time: 0,
    };
    //为里面的图片添加遮罩层
    $photoGroup.find('.f-photoGroup-a').each(function (index, a) {
        var $a = jm(a),
            $img = $a.find('.f-photoGroup-img'),
            height = $img.height() / window._htmlFontSize + 'rem', //jm 要添加单位 offset.height会被缩放给影响了, 而height则不会
            $mask;
        //为每张图片添加一个遮罩层
        if ($a.find('.J_photoGroup-img-mask').length === 0) {
            $mask = jm("<div class='f-photoGroup-img-mask J_photoGroup-img-mask'></div>");
            $mask.height(height); //要使用rem单位，避免html的font-size变化，引起尺寸的不同
            $a.append($mask);
        }
    });

    //实现点击遮罩层，触发图片的浏览功能
    $photoGroup
        .find('.J_photoGroup-img-mask')
        .off('mousedown.photoGroupScan mouseup.photoGroupScan')
        .on('mousedown.photoGroupScan', downMask)
        .on('mouseup.photoGroupScan', upMask);

    //按下遮罩层
    function downMask(event) {
        info.time = new Date();
        info.startX = event.pageX;
        info.startY = event.pageY;
    }
    //松开遮罩层
    function upMask(event) {
        var $mask = jm(event.target),
            time = new Date() - info.time;

        info.endX = event.pageX;
        info.endY = event.pageY;

        //前后时间间隔长
        if (time > 200) {
            return;
        }
        //前后坐标不一样，视为非click行为
        if (info.startX !== info.endX || info.startY !== info.endY) {
            return;
        }

        $mask.parent().find('.f-photoGroup-img').click();
    }
}

//处理图册模块	【点击图弹出】为图册详情  flag:false用于标志是一个解除控制的操作 by jser 2017-6-14;
function ctrlLinkJump($module, flag) {
    var eventNameMap = getEventNameMapForCtrlLink(),
        info;

    //解除控制
    $module.off(eventNameMap.downLink).off(eventNameMap.clickLink);

    //是否是解除操作
    if (flag === false) {
        return;
    }

    info = {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        time: null,
    };
    //绑定控制
    $module
        .on(eventNameMap.downLink, '.J_photoGroup-a', downLink)
        .on(eventNameMap.clickLink, '.J_photoGroup-a', clickLink);

    function downLink(event) {
        var pos = getPosByEvent(event);
        info.startX = parseInt(pos.pageX);
        info.startY = parseInt(pos.pageY);

        info.time = new Date();
    }
    function clickLink(event) {
        var pos = getPosByEvent(event),
            time = new Date(),
            isDiff = false;

        info.endX = parseInt(pos.pageX);
        info.endY = parseInt(pos.pageY);

        /*解决手机浏览器下event.pageX小数点四舍五入取整，导致其实没移动，但是endX和startX会相差1px,导致点击跳转不灵敏，这里增加阀值 @ken*/
        if (Math.abs(info.endX - info.startX) > 1) {
            isDiff = true;
        }

        if (Math.abs(info.endY - info.startY) > 1) {
            isDiff = true;
        }

        if (time - info.time > 200 || isDiff) {
            event.preventDefault();
        }
    }
}

//控制编辑入口的交互 by jser 2017-6-19
function ctrlEditLayer($module, flag) {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }

    if (jm.isFunction(bindWithEditLayer)) {
        bindWithEditLayer($module, flag);
    }
}

//依据模块样式ID的不同，选择不同的交互方案，如果没有交互方案，那么create和destory都会是一个空函数
function getSliderByStyleId(styleId) {
    var sliderMap = {
            4: photoGroup.zoomSlider,
            5: photoGroup.pokerSlider,
        },
        noop = function () {};

    if (!styleId) {
        return {
            create: noop,
            destory: noop,
        };
    }

    return sliderMap[styleId];
}
//获取控制链接的事件名映射对象
function getEventNameMapForCtrlLink() {
    var touchMap, mouseMap;

    touchMap = {
        downLink: 'touchstart.ctrlLink',
        clickLink: 'click.ctrlLink',
    };
    mouseMap = {
        downLink: 'mousedown.ctrlLink',
        clickLink: 'click.ctrlLink',
    };

    return browser.isPc() ? mouseMap : touchMap;
}

/*下面是一些工具API*/
//true: x, false: y undefined:click
function getSliderDir(x0, y0, x1, y1) {
    var disX = Math.abs(x0 - x1);
    var disY = Math.abs(y0 - y1);

    if (disY === 0 && disY === 0) {
        return void 0;
    }

    if (disX > 0 && disY === 0) {
        return true;
    }

    if (disX === 0 && disY > 0) {
        return false;
    }

    return disX / disY > 1;
}
//获取触碰的手指
function getTouchByEvent(event) {
    //兼容自定义事件触发时，event对象异常
    if (!event.touches && !event.changedTouches) {
        return {};
    }

    return event.touches[0] || event.changedTouches[0];
}

//获取坐标信息
function getPosByEvent(event) {
    var pageX = event.pageX || getTouchByEvent(event).pageX,
        pageY = event.pageY || getTouchByEvent(event).pageY;

    return {
        pageX,
        pageY,
    };
}

//获取卡片上的图片(优先获取遮罩层)
function getImgByCard(card) {
    var $card = jm(card),
        $img = jm(card).find('.J_photoGroup-img-mask');

    if ($img.length > 0) {
        return $img;
    }

    return $card.find('.J_photoGroup-img');
}
//获取模块的样式类型
function getStyleType($module) {
    var $photoGroupAll = $module.find('.J_photoGroupAll'),
        type;

    if ($photoGroupAll.hasClass('J_photoGroupAll1')) {
        type = 1;
    } else if ($photoGroupAll.hasClass('J_photoGroupAll2')) {
        type = 2;
    } else if ($photoGroupAll.hasClass('J_photoGroupAll3')) {
        type = 3;
    } else if ($photoGroupAll.hasClass('J_photoGroupAll4')) {
        type = 4;
    } else if ($photoGroupAll.hasClass('J_photoGroupAll5')) {
        type = 5;
    }

    return type;
}
//判断是否在容器上

//判断当前坐标是否位于卡片中
function isInDom(dom, x, y) {
    var offset = jm(dom)[0].getBoundingClientRect(), //为了获取相对与视窗的距离，$dom.offset()是获取绝对距离
        isInX = false,
        isInY = false;

    if (x >= offset.left && x <= offset.right) {
        isInX = true;
    }
    if (y >= offset.top && y <= offset.bottom) {
        isInY = true;
    }

    return isInX && isInY;
}

function editLayerFactory() {
    /*
    desction:
        创建图册目录的editLayer的管理对象
        通过函数进行创建，editLayer只为了图册目录服务，不为其他模块服务，故用一个函数完成创建的过程
    author	:   jser
    time	:	2017-6-15
    */

    var editLayerStatus = {}, //记录模块编辑工具的使用状态
        editLayer = {
            add, //添加
            remove, //删除
            ctrl, //控制，是否允许添加、删除
        };

    return editLayer;

    //添加编辑入口
    function add(img, $module) {
        //index，所属卡片的索引
        var $img = getImgByTarget(img),
            objId = $img.attr('id'),
            itemsJson;
        if (!(VITE_APP_MODE !== 'visitor')) {
            return;
        } //访客态不开放

        if (getState($module) === false) {
            return;
        }

        itemsJson = getJson($img);
        Vue.prototype.$layer.addEditLayer(objId, itemsJson, 6, {
            extCls: 'editLayer_2',
        }); //存储新创键的editLayer
    }
    //删除编辑入口
    function remove(img, $module) {
        //访客态不开放
        if (!(VITE_APP_MODE !== 'visitor')) {
            return;
        }
        //判断是否能够删除
        if (getState($module) === false) {
            return;
        }

        var $img = getImgByTarget(img),
            objId = $img.attr('id');
        if (objId) {
            Vue.prototype.$layer.removeEditLayer(objId);
        }
    }
    //控制editLayer, flag:false是禁止添加和删除
    function ctrl($module, flag) {
        var id = $module.attr('id'),
            status = editLayerStatus[id];
        //禁止editLayer的添加、删除
        if (flag === false) {
            editLayerStatus[id] = false;
            return;
        }
        //恢复editLayer的添加和删除
        if (status !== void 0) {
            editLayerStatus[id] = void 0;
            delete editLayerStatus[id];
        }
    }
    //获取模块的editLayer状态(可获知是否允许添加和删除) return false代表不允许，其余允许
    function getState($module) {
        var id = $module.attr('id');
        return editLayerStatus[id];
    }
    //获取编辑工具的json数据
    function getJson(img) {
        var $img = jm(img).parent().find('.J_photoGroup-img'), //获取图片
            groupId = $img.attr('_groupid'), //获取组id
            $groupGroup = jm('#photoGroup' + groupId), //获取组对象
            $title = $groupGroup.find('.J_photoGroup-title'), //获取标题
            groupName = $title.text(), //获取标题文字
            fromMobi2 = $img.attr('fromMobi2'), // 2.0 图册模块
            moduleId = $img.attr('id').split('module')[1], // 2.0 图册模块id
            editJson,
            delJson;

        editJson = {
            operationText: '编辑图册',
            className: 'edit_2',
            onClick() {
                popupWindow({
                    title: '编辑图册',
                    frameSrcUrl: fromMobi2
                        ? `${getEditUrl(groupId)}&fromMobi2=true&_pmid=${moduleId}`
                        : getEditUrl(groupId),
                    width: 860,
                    height: 682,
                    version: 2,
                });
            },
        };
        delJson = {
            operationText: '删除图册',
            className: 'close_2',
            onClick() {
                if (fromMobi2) {
                    deletePhotoGroupManage(groupId, encodeHtmlJs(groupName), 0, moduleId);
                } else {
                    deletePhotoGroupManage(groupId, encodeHtmlJs(groupName), 0);
                }
            },
        };
        return [editJson, delJson];
    }

    //获取编辑入口的url
    function getEditUrl(groupId) {
        return `${getSiteMultiLanguageUrl()}/manage_v2/photoGroupEdit.jsp?fromManage=true&mobiSettingState=3&id=${groupId}`;
    }
    //获取图片对象（传入的可能是一个图片遮罩层）
    function getImgByTarget(img) {
        var $img = jm(img);
        if (!$img.hasClass('J_photoGroup-img')) {
            $img = $img.parent().find('.J_photoGroup-img');
        }
        return $img;
    }
}

//和开放编辑入口有关的业务代码 by jser 2017-6-12 图册目录的代码越来越多了。。。
var editLayer = editLayerFactory(),
    info = {
        moveX: 0,
        moveY: 0,
    };

//为所有图册目录初始化编辑入口(管理态)
function initEditLayer() {
    if (!(VITE_APP_MODE !== 'visitor')) {
        return;
    }

    jm('.formStyle34').forEach(function (module) {
        bindWithEditLayer(jm(module));
    });
}

/*设计思路：
    当鼠标enter模块时，为图片批量绑定事件，
    当鼠标leave模块是，为图片批量解绑事件
    因为我们需要每张卡片都能够相应enter和leave事件，来显示和隐藏编辑入口
    鼠标离开模块后，批量解除卡片上的事件，节省页面性能
*/
//绑定编辑工具 flag:false用于解绑
function bindWithEditLayer($module, flag) {
    var eventNameMap = getEventNameMap(),
        type = getStyleType($module); //获取模块样式类型
    //解绑事件
    $module.off(eventNameMap.moduleMouseLeave).off(eventNameMap.moduleMouseEnter);

    if (flag === false) {
        return;
    }

    //绑定事件
    $module
        .on(eventNameMap.moduleMouseEnter, function () {
            bindEventForCard($module); //为图片绑定事件
        })
        .on(eventNameMap.moduleMouseLeave, function () {
            bindEventForCard($module, false); //为图片解绑事件
        }); //为样式4和样式5修复EditLayer交互上的bug

    if ([4, 5].indexOf(type) > -1) {
        fixEditLayer($module, flag);
    }
}
//为模块所有卡片绑定自己的交互事件 isBind:false用于解绑
function bindEventForCard($module, isBind) {
    var $photoGroups = $module.find('.J_photoGroupStyle'),
        eventNameMap = getEventNameMap();

    //图片鼠标移入移出
    $photoGroups.off(eventNameMap.imgMouseLeave).off(eventNameMap.imgMouseEnter);

    if (isBind === false) {
        return;
    }

    //为每一张卡片添加交互事件
    $photoGroups
        .on(eventNameMap.imgMouseEnter, function () {
            var $photoGroup = jm(this),
                $img = getImgByCard($photoGroup),
                type = getStyleType($module),
                flag = $photoGroup.data('fk-is-enter');

            if (type === 4 && !$photoGroup.hasClass('f-active')) {
                return;
            }
            if (type === 5 && $photoGroup.index() !== 0) {
                return;
            }

            if (flag === 'true') {
                return;
            } //本身进入过，不执行

            editLayer.add($img, $module); //添加入口
            $photoGroup.data('fk-is-enter', 'true'); //标志进入
        })
        .on(eventNameMap.imgMouseLeave, function () {
            var $photoGroup = jm(this),
                $img = getImgByCard($photoGroup),
                flag = $photoGroup.data('fk-is-enter');

            if (flag !== 'true') {
                return;
            } //没有进入过

            editLayer.remove($img, $module); //删除入口
            $photoGroup.data('fk-is-enter', 'false'); //标志离开
        });
}

//修正不同模块样式，editLayer交互的bug
function fixEditLayer($module, flag) {
    var eventNameMap = getEventNameMap();

    $module.off(eventNameMap.transBefore).off(eventNameMap.transAfter);

    if (flag === false) {
        return;
    }

    //解决transition期间，editLayer忽隐忽现的bug
    $module
        .on(eventNameMap.transBefore, function () {
            transitionBefore($module);
        })
        .on(eventNameMap.transAfter, function () {
            transitionAfter($module);
        });
}
//过度前
function transitionBefore($module) {
    //获知当前的激活卡片
    var cardInfo = getCardInfo($module),
        $cards = cardInfo.all; //所有卡片		//监听鼠标移动

    listenMousePos($module, info);
    //把旧的接口删除
    $cards.each(function (index, card) {
        var $img = getImgByCard(card);
        editLayer.remove($img, $module);
    });
    //禁止添加和删除的操作
    editLayer.ctrl($module, false);
}
//过渡后
function transitionAfter($module) {
    //获知当前的激活卡片
    var $activeCard = getCardInfo($module).active, //激活的卡片
        $img = getImgByCard($activeCard);
    //解除鼠标移动监听
    listenMousePos($module, info, false);
    //判断坐标是否还在 卡片中
    if (!isInDom($activeCard, info.moveX, info.moveY)) {
        return;
    }

    editLayer.ctrl($module); //添加新的接口
    editLayer.add($img, $module);
}

//获取模块中的卡片信息
function getCardInfo($module) {
    var type = getStyleType($module),
        $all = $module.find('.J_photoGroupStyle'),
        $active;
    if (type === 4) {
        $active = $module.find('.f-active');
    }
    if (type === 5) {
        $active = $all.eq(0);
    }
    return {
        all: $all,
        active: $active,
    };
}
/*
@desc: 监听鼠标移动的坐标
@arguments:
    $module:模块
    info: 信息体,记录坐标等数据，解绑标志为
    flag: 用于解绑事件的标志位
*/
function listenMousePos($module, info, flag) {
    var $wraper = $module.find('.J_photoGroupAll-wrap'),
        eventNameMap = getEventNameMap();

    $wraper.off(eventNameMap.wrapMousemove);
    if (flag === false) {
        return;
    }
    //鼠标移动
    $wraper.on(eventNameMap.wrapMousemove, function (event) {
        info.moveX = event.clientX;
        info.moveY = event.clientY;
    });
}

function getEventNameMap() {
    return {
        moduleMouseEnter: 'mouseenter.bindEidtLayer', //移入模块
        moduleMouseLeave: 'mouseleave.bindEditLayer', //移出模块
        imgMouseEnter: 'mouseenter.eidtLayer', //移入卡片
        imgMouseLeave: 'mouseleave.eidtLayer', //移出卡片
        imgDwon: 'mousedown.editLayer', //卡片按下
        imgUp: 'mouseup.editLayer', //卡片松开
        wrapMousemove: 'mousemove.editLayer', //在卡片容器上移动
        transBefore: 'transitionBefore', //样式4、5过渡前
        transAfter: 'transitionAfter', //样式4、5过渡后
    };
}

//图册目录样式4 by jser 2017-6-6
function registerZoomSlider(photoGroup) {
    var zoomSlider = {
            create,
            destory,
        },
        transClass = {
            small: 'f-small',
            active: 'f-active',
        },
        utils = photoGroup.utils;

    //对外暴露样式4的管理对象
    photoGroup.zoomSlider = zoomSlider;
    //创建样式4
    function create($module) {
        init($module);
        bindEvent($module);
    }
    //销毁样式4
    function destory($module) {
        var $wraper = $module.find('.J_photoGroupAll-wrap'),
            eventNameMap = getEventNameMap();

        //off event
        $wraper.off(eventNameMap.dragStart).off(eventNameMap.selectStart).off(eventNameMap.dragOriginStart);
    }

    //初始化布局
    function init($module) {
        var $wraper = $module.find('.J_photoGroupAll-wrap'),
            $photos = $module.find('.J_photoGroupStyle'),
            width = 0,
            $first = $photos.eq(0);

        //为所有卡片添加特殊类名
        $photos.addClass(transClass.small);
        //第一个卡片添加激活类名
        if ($first.length > 0) {
            $first.addClass(transClass.active);
        }

        //扩大wraper的感应面面积
        $photos.each(function (index, photo) {
            var $photo = jm(photo); //border-box
            width += parseFloat($photo.width());
            width += parseFloat($photo.css('margin-left'));
            width += parseFloat($photo.css('margin-right'));
        });
        $wraper.width(width + 'px');
    }
    //计算当前卡片中线和页面中线的距离
    function getCurrentDisLeft($currCard) {
        //当前卡片中心线和容器中心线的距离
        var $web = jm('#g_web'),
            webOffset = $web.offset(),
            cardOffset = $currCard.offset(),
            webCenterLeft = webOffset.left + webOffset.width / 2,
            cardCenterLeft = cardOffset.left + cardOffset.width / 2,
            currDisLeft = cardCenterLeft - webCenterLeft;

        return currDisLeft;
    }
    //绑定交互事件
    function bindEvent($module) {
        var $wraper = $module.find('.J_photoGroupAll-wrap'),
            eventNameMap = getEventNameMap(),
            info = getDragInfo();

        //绑定交互事件
        $wraper
            .off(eventNameMap.dragStart)
            .off(eventNameMap.selectStart)
            .off(eventNameMap.dragOriginStart)
            .on(eventNameMap.dragStart, dragStart)
            .on(eventNameMap.selectStart, banDefault)
            .on(eventNameMap.dragOriginStart, banDefault); //禁止a标签的某些原生事件

        function banDefault(event) {
            event.preventDefault();
        }
        //拖拽开始
        function dragStart(event) {
            var pos = utils.getPosByEvent(event),
                eventNameMap = getEventNameMap();

            info.isStart = true;
            info.startX = pos.pageX;
            info.startY = pos.pageY;

            //动态绑定事件，优化性能
            $wraper
                .off(eventNameMap.dragMove)
                .on(eventNameMap.dragMove, dragMove)
                .off(eventNameMap.dragEnd)
                .on(eventNameMap.dragEnd, dragEnd);
        }
        //拖拽中
        function dragMove(event) {
            var dir = utils.getSliderDir(info.startX, info.startY, info.moveX, info.moveY),
                pos;

            if (!info.isStart) {
                return;
            }

            pos = utils.getPosByEvent(event);
            info.moveX = pos.pageX;
            info.moveY = pos.pageY;

            if (!info.isMove) {
                info.isMove = true;

                if (dir === void 0) {
                    return;
                }

                if (dir === true) {
                    info.isXMove = true;
                    lockDocScroll();
                } else {
                    openDocScroll();
                }
            }
        }
        // 拖拽结束
        function dragEnd(event) {
            var pos = utils.getPosByEvent(event),
                disX = pos.pageX - info.startX,
                eventNameMap = getEventNameMap(),
                canContinue = info.isStart && info.isMove && !info.isTrans; //是否是正常触发dragEnd：开始拖拽，有在移动中，没有在过渡中

            //开放文档滚动
            openDocScroll();
            //解除事件绑定
            $wraper.off(eventNameMap.dragMove).off(eventNameMap.dragEnd);

            if (canContinue && info.isXMove) {
                //正常触发 && 横向滑动
                //横向滑动的情况且非运动中下可以切换
                //处理边界情况
                if (disX < 0) {
                    //left
                    info.currIndex++;
                } else if (disX > 0) {
                    //right
                    info.currIndex--;
                }
                //切换卡片
                update($wraper, info, $module, event);
            }
            //清除标志位
            info.isXMove = info.isStart = info.isMove = void 0;
        }
    }
    //切换卡片
    function update($wraper, info, $module, event) {
        var $photos = $wraper.find('.J_photoGroupStyle'),
            $oldPhoto,
            eventNameMap = getEventNameMap(),
            prefixArr = ['-ms-', '', 'moz', '-webkit-'],
            $activePhoto;
        //修正数据
        if (info.currIndex < 0) {
            info.currIndex = 0;
        } else if (info.currIndex >= $photos.length) {
            info.currIndex = $photos.length - 1;
        }

        if (info.oldIndex === info.currIndex) {
            return;
        }

        //进行样式的过渡变化
        $activePhoto = $photos.eq(info.currIndex); //新卡片
        $oldPhoto = $photos.eq(info.oldIndex); //旧卡片
        $oldPhoto.removeClass(transClass.active);
        $activePhoto.addClass(transClass.active);

        info.isTrans = true; //标志过渡中
        $activePhoto.off(eventNameMap.transEnd).on(eventNameMap.transEnd, function (event) {
            //reset flag
            info.isTrans = false;
            //off event
            $activePhoto.off(eventNameMap.transEnd);
            //重置索引
            info.oldIndex = info.currIndex;
            //触发过渡回调;
            $module.trigger('transitionAfter', [event]);
        });

        info.disLeft -= getCurrentDisLeft($activePhoto);

        //触发过渡开始的回调
        $module.trigger('transitionBefore', [event]); //set transform
        jm(prefixArr).each(function (index, pre) {
            var transform = pre + 'transform',
                transformVal = 'translate(' + info.disLeft + 'px,0)';
            $wraper.css(transform, transformVal);
        });
    }

    //获取事件名映射体
    function getEventNameMap() {
        var touchMap, mouseMap;
        //获取事件名映射体
        mouseMap = {
            dragStart: 'mousedown.photoStyle4',
            dragMove: 'mousemove.photoStyle4',
            dragEnd: 'mouseup.photoStyle4',
            transEnd: 'transitionend.photoStyle4',
            selectStart: 'selectstart.photoStyle4',
            dragOriginStart: 'dragstart.photoStyle4',
        };
        touchMap = {
            dragStart: 'touchstart.photoStyle4',
            dragMove: 'touchmove.photoStyle4',
            dragEnd: 'touchend.photoStyle4',
            transEnd: 'transitionend.photoStyle4',
            selectStart: 'selectstart.photoStyle4',
            dragOriginStart: 'dragstart.photoStyle4',
        };

        if (VITE_APP_MODE !== 'visitor') {
            return mouseMap;
        } else {
            if (browser.isPc()) {
                return mouseMap;
            } else {
                return touchMap;
            }
        }
    }
    //获取与拖拽相关的信息
    function getDragInfo() {
        return {
            currIndex: 0, //当前卡片索引
            oldIndex: 0, //上一张看破索引
            isStart: false, //是否开始拖拽
            isXMove: false, //是否横向拖拽
            isMove: false, //是否拖拽中
            isTrans: false, //标志是否在过渡中
            startX: 0, //开始坐标
            startY: 0,
            moveX: 0, //移动坐标
            moveY: 0,
            endX: 0, //结束坐标
            endY: 0,
            time: 0, //用于记录时间戳
            disLeft: 0, //容器移动的距离
        }; //缓存信息
    }
}

/*图册目录样式5*/
function registerPokerSlider(photoGroup) {
    var pokerSlider = {
            create,
            destory,
        },
        utils = photoGroup.utils;

    photoGroup.pokerSlider = pokerSlider;

    //创建样式5
    function create($module) {
        init($module);
        bindEvent($module);
    }
    //销毁样式5
    function destory($module) {
        var $photoGroup = $module.find('.J_photoGroupAll'),
            eventNameMap = getEventMap();
        //解绑事件
        $photoGroup.off(eventNameMap.dragStart).off(eventNameMap.selectStart).off(eventNameMap.dragOrginStart);
    }
    //初始化
    function init($module) {
        var $wraper = $module.find('.J_photoGroupAll-wrap'),
            $first = $wraper.find('.J_photoGroupStyle').find('.J_photoGroup-a'),
            height = $first.height() + 'px';
        //图册详情的容器不存在，调整一下高度;
        if ($first.find('.J_photoGroup-detail').length === 0) {
            $wraper.css({
                height,
                transform: 'translateY(0.725rem)',
            }); //修正垂直距离
        }
    }
    //绑定事件
    function bindEvent($module) {
        var $photoGroup = $module.find('.J_photoGroupAll'),
            eventNameMap = getEventMap(),
            info = getInfo();
        //<=1张，不添加交互
        if ($photoGroup.find('.J_photoGroupStyle').length <= 1) {
            return;
        }

        $photoGroup
            .off(eventNameMap.dragStart)
            .off(eventNameMap.selectStart)
            .off(eventNameMap.dragOrginStart)
            .on(eventNameMap.dragStart, dragStart)
            .on(eventNameMap.dragOrginStart, '.J_photoGroup-a', banDefault) //fix 原生dragstart引起的bug
            .on(eventNameMap.selectStart, banDefault);

        function banDefault(event) {
            event.preventDefault();
        }

        function dragStart(event) {
            var pos = utils.getPosByEvent(event);

            info.startX = pos.pageX;
            info.startY = pos.pageY;
            info.isStart = true;

            $photoGroup
                .off(eventNameMap.dragMove)
                .off(eventNameMap.dragEnd)
                .on(eventNameMap.dragMove, '.J_photoGroupStyle', dragMove)
                .on(eventNameMap.dragEnd, '.J_photoGroupStyle', dragEnd);
        }

        function dragMove() {
            var dir;
            if (!info.isStart) {
                return;
            }

            if (!info.isMove) {
                info.isMove = true;

                dir = utils.getSliderDir(info.startX, info.startY, info.endX, info.endY);

                if (dir === void 0) {
                    return;
                }
                //横向滚动
                if (dir === true) {
                    info.isXMove = true;
                    lockDocScroll();
                } else {
                    openDocScroll();
                }
            }
        }

        function dragEnd(event) {
            var pos = utils.getPosByEvent(event),
                isStart = info.isStart,
                isMove = info.isMove;

            openDocScroll();

            //record pos
            info.endX = pos.pageX;
            info.endY = pos.pageY;
            //off event
            $photoGroup.off(eventNameMap.dragMove).off(eventNameMap.dragEnd);

            if (isStart && isMove && info.isXMove) {
                toNext($photoGroup, $module, info);
            }
            //reset flag
            info.isStart = info.isMove = info.isXMove = void 0;
        }
    }
    //下一张
    function toNext($photoGroup, $module, info) {
        var $photoStyles = $photoGroup.find('.J_photoGroupStyle'),
            $first = $photoStyles.eq(0),
            $second = $photoStyles.eq(1),
            $third = $photoStyles.eq(2),
            $fouth = $photoStyles.eq(3),
            eventMap = getEventMap(),
            disX = info.endX - info.startX,
            isToLeft = false,
            transCls; //和过渡相关的类名

        if (info.isAnimate) {
            return;
        } //是否处于过渡中

        if (disX === 0) {
            return;
        }

        if (disX < 0) {
            isToLeft = true;
        }

        transCls = {
            dir: isToLeft ? 'f-toLeft' : 'f-toRight',
            toOne: 'f-toOne',
            toTwo: 'f-toTwo',
            toThree: 'f-toThree',
        };
        //监听过渡
        if ($first.length > 0) {
            info.isAnimate = true; //标志运动
            $first.on(eventMap.transEnd, transitonEnd);
            $module.trigger('transitionBefore');
            $first.addClass(transCls.dir);
        }

        if ($second.length > 0) {
            $second.addClass(transCls.toOne);
        }

        if ($third.length > 0) {
            $third.addClass(transCls.toTwo);
        }

        if ($fouth.length > 0) {
            $fouth.addClass(transCls.toThree);
        }

        function transitonEnd() {
            var eventMap = getEventMap(),
                $parent = $first.parent(),
                $siblings = $first.siblings();
            //解绑事件，移除节点
            $first.off(eventMap.transEnd);
            $first.parent()[0].removeChild($first[0]); //原生删除，为了不丢失里面的事件
            //删除兄弟节点上的和过渡相关的类名
            $siblings.removeClass(transCls.toOne).removeClass(transCls.toTwo).removeClass(transCls.toThree);

            //删除自身类名，重新加入文档
            $first.removeClass(transCls.dir);
            $parent.append($first);
            info.isAnimate = false; //标志不运动
            $module.trigger('transitionAfter');
        }
    }

    //获取对象映射体
    function getEventMap() {
        var mouseMap, touchMap;

        mouseMap = {
            dragStart: 'mousedown.photoGroup5',
            dragMove: 'mousemove.photoGroup5',
            dragEnd: 'mouseup.photoGroup5',
            selectStart: 'selectstart.photoGroup5',
            transEnd: 'transitionend.photoGroup5',
            dragOrginStart: 'dragstart.photoGroup5',
        };

        touchMap = {
            dragStart: 'touchstart.photoGroup',
            dragMove: 'touchmove.photoGroup',
            dragEnd: 'touchend.photoGroup',
            selectStart: 'selectstart.photoGroup5',
            transEnd: 'transitionend.photoGroup5',
            dragOrginStart: 'dragstart.photoGroup5',
        };

        if (VITE_APP_MODE !== 'visitor') {
            return mouseMap;
        } else {
            //非管理态
            if (browser.isPc()) {
                return mouseMap;
            } else {
                return touchMap;
            }
        }
    }

    function getInfo() {
        return {
            startX: 0,
            startY: 0,
            endX: 0,
            endY: 0,
            isStart: false,
            isMove: false,
            isXMove: false,
            isAnimate: false,
        };
    }
}

export function deletePhotoGroupManage(id, name, refreshUrl, jumpMode, moduleId) {
    Vue.prototype.$layer.removeAllEditLayer();
    if (Vue.prototype.$designer.checkSaveBar()) {
        return;
    }
    if (name.length > 15) {
        name = name.slice(0, 15) + '...';
    }
    name = encodeHtml(name);
    new Vue().$createDialog(
        {
            confirmButton: { text: '确 定' },
            cancelButton: { text: '取 消' },
            content: '确定将该图册放入回收站吗？', // '将从网站后台中删除该图册，删除后无法找回，<p style="text-align: left;color:#333;">确定删除？</p>'
            onConfirm() {
                var delImgs = this.delImgs;
                top.Fai.ing('正在处理中，请稍候...', false);
                jm.ajax({
                    type: 'post',
                    url: genAjaxUrl('photoGroup_h.jsp'),
                    data: 'cmd=delWafCk_del&id=' + id + '&delImgs=' + delImgs,
                    error() {
                        top.Fai.ing(LS.js_systemError, false);
                    },
                    success(result) {
                        successHandle(result, '删除成功', '', refreshUrl, jumpMode, 1);
                        if (moduleId != null && moduleId != '') {
                            refreshPhotoGroup(moduleId, id);
                        } else {
                            reloadPage();
                        }
                    },
                });
            },
            data: {
                delImgs: false,
            },
        },
        function (createElement) {
            var self = this;
            return createElement(
                'div',
                {
                    slot: 'description',
                    style: 'height: 26px;text-align: left;',
                },
                [
                    createElement(
                        'checkbox-component',
                        {
                            props: {
                                value: this.delImgs,
                                trueValue: true,
                                falseValue: false,
                            },
                            on: {
                                input(e) {
                                    self.delImgs = e;
                                },
                            },
                        },
                        '包括图册图片'
                    ),
                ]
            );
        }
    );

    function refreshPhotoGroup(moduleId, id) {
        var moduleInfo = window.$store.getters.getModuleById(moduleId);
        if (moduleInfo != null) {
            moduleInfo.allGroupList = moduleInfo.allGroupList.filter((item) => item.id != id);
            moduleInfo.content.groupIdList = moduleInfo.content.groupIdList.filter((item) => item != id);
        }
    }
}

//开放控制文档滚动的接口 by jser
//监听文档移动
export function linstenDocTouchMove() {
    jm('#g_web').on('touchmove', ban);
    function ban() {
        if (jm('body').data('fk-scroll') === 'false') {
            return false;
        }
    }
}
//禁止文档滚动
function lockDocScroll() {
    jm('body').data('fk-scroll', 'false');
}
//允许文档滚动
function openDocScroll() {
    jm('body').data('fk-scroll', 'true');
}

export { photoGroup };
