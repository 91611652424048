<template>
    <div
        :id="'newsLine' + info.id"
        :topClassName="_topClassName"
        :topSwitch="_topSwitch"
        :newsId="info.id"
        :newsName="info.title"
        :class="wrapClasses"
    >
        <div :id="'lineBody' + info.id" class="lineBody" :class="isOnlyTitle">
            <a hidefocus="true" :href="_newsUrl" :target="_jumpTarget" :onclick="onclickStr">
                <div class="mixNewsStyleImgBox7">
                    <div class="mixNewsStyleImgBox7_left">
                        <div class="mixNewsStyleTime">
                            <top-component v-if="_topFlagClass != '' && isNewAddModule"></top-component>{{ newsDate }}
                        </div>
                        <div :class="[titleClasses, titleLine]" class="g_news_title" :style="news_title_style">
                            <div :class="_topFlagClass"></div>
                            {{ info.title }}
                        </div>
                    </div>
                    <div class="mixNewsStyleImgBox7_middleCicle"></div>
                    <div class="mixNewsStyleImgBox7_right">
                        <div :alt="altName" class="newsTitlePic J_newsTitlePic J_img_lazyload" :src-original="imgPath">
                            <svg xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <clipPath :id="'clippath' + module.id + '' + index">
                                        <polygon points=""></polygon>
                                    </clipPath>
                                </defs>
                                <image
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    :alt="altName"
                                    :xlink:href="noLazyLoad ? imgPath : options.loadingPath"
                                    preserveAspectRatio="none"
                                    :style="imgStyle"
                                ></image>
                            </svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import ListItem from '../../mixins/ListItem';
import { mapFlag } from '@/shared/flag';
import TopComponent from '../topComponent/top-component.vue';

export default {
    name: 'TimeListItem',
    components: { TopComponent },
    mixins: [ListItem],
    inject: ['module', 'options', 'altName', 'ndUrl'],
    props: {
        info: {
            type: Object,
            default: function () {
                return {};
            },
        },
        index: { type: Number, default: 0 },
    },
    data() {
        return {
            imgStyle: `clip-path:url(#clippath${this.module.id}${this.index}`,
        };
    },
    computed: {
        ...mapFlag(
            {
                isNewAddModule: 0x4,
            },
            'module.flag'
        ),
        wrapClasses() {
            return `${this._lineClass} timeAxisNewsStyle newsLine J_picNum`;
        },
        titleClasses() {
            return this.module.content.sl ? 'mixNewsStyleTitle1' : 'mixNewsStyleTitle2';
        },
        newsDate() {
            return this._dateFormate(new Date(this.info.date), 'yyyy/MM/dd');
        },
        imgPath() {
            return this.info.realPicId === '' ? this.module.extInfo.noPicPath : this.info.bigPicPath;
        },
        titleLine() {
            const descriptionLine = this.module.content.tsl;
            var desClass = '';
            switch (descriptionLine) {
                case 0:
                    desClass = 'defaultDescriptionLine';
                    break;
                case 1:
                    desClass = 'descriptionLine';
                    break;
                case 2:
                    desClass = 'noDescriptionLine';
                    break;
            }
            return ` ${desClass}`;
        },
        divSize() {
            let divWidth = 4.6;
            let divHeight = 4.4;
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                divWidth = tSize.picWidth / 20;
                divHeight = tSize.picHeight / 20;
            }
            return { divWidth, divHeight };
        },
        picStyle() {
            if (!this.isNewAddModule) return;
            let picStyle = {};
            const tSize = this.module.content.tSize;
            const tSizeType = tSize.picSizeType;
            if (tSizeType == 2) {
                picStyle.height = `${this.divSize.divHeight}rem`;
            } else {
                picStyle.height = `4.4rem`;
            }

            return picStyle;
        },
    },
};
</script>

<style></style>
