import { initPhotoSwipe } from '@/modules/shared/photo';

export const PhotoCarouselDomService = {
    INTERVAL: 4000,
    SPEED: 1500,
    /**
     *
     * @param {number} moduleId
     */
    init(moduleId) {
        initPhotoSwipe(moduleId, '', this.INTERVAL, this.SPEED);
    },
};
