export function getProductVideo() {
    jm('.productBullet').hide();
    jm('.productDetailHeader').hide();
    jm('.productDetailHeaderV3').hide();

    var videoMask = document.getElementById('productVideoMask');
    var productVideo = document.getElementById('productVideo');
    videoMask.style.display = 'block';
    productVideo.play();
}

export function initVideo(productId) {
    jm('.bullets').find('li').eq(0).css('height', '12px');
    jm('.bullets').find('li').eq(0).css('width', '12px');
    var html = "<span class='bfFont faisco-icons-'></span>";
    jm('.bullets').find('li').eq(0).append(html);
    jm('.bullets').css('margin-top', '-20px');
    jm(jm('.proDetailImg ')[0]).removeAttr('onclick');
    jm(jm('.proDetailImg ')[0]).click(function () {
        getProductVideo(productId);
    });
}
