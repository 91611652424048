<template>
    <div class="u_custom_search_entry--identity_wrap">
        <input
            v-model="formItem.value"
            class="u_custom_search_entry--input u_custom_search_entry--text"
            type="text"
            @input="handleInput"
        />
    </div>
</template>

<script>
export default {
    name: 'CustomSearchIdentity',
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {},
    methods: {
        handleInput(val) {
            this.$emit('on-input', val);
        },
    },
};
</script>

<style lang="scss" scoped></style>
