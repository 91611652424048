<template>
    <div class="news_list-pic-text">
        <template v-if="isNewModule">
            <template v-for="(info, index) in newsList">
                <pic-text-item-v2 :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
                <div :key="'s_' + info.id" class="g_separator separatorLine"></div>
            </template>
        </template>
        <template v-else>
            <template v-for="(info, index) in newsList">
                <pic-text-item-v1 :key="'n_' + info.id" :info="info" :index="index" :no-lazy-load="noLazyLoad" />
            </template>
        </template>
    </div>
</template>

<script>
import PicTextItemV2 from './pic-text-item-v2.vue';
import PicTextItemV1 from './pic-text-item-v1.vue';
import { mapFlag } from '@/shared/flag';

export default {
    name: 'TimeList',
    components: { PicTextItemV2, PicTextItemV1 },
    inject: ['module', 'options'],
    props: ['newsList', 'noLazyLoad'],
    computed: {
        ...mapFlag(
            {
                isNewModule: 0x2,
            },
            'module.flag'
        ),
    },
};
</script>

<style></style>
